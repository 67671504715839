import React, { memo } from 'react'
import { Box, Grid } from '@mui/material'

import BrandLogo from '../../shared/BrandLogo/BrandLogo'
import { toUpper } from '../../../util/string.helpers'
import CustomTooltip from '../../shared/CustomTooltip/CustomTooltip'
import Text from '../../shared/text/Text'
import { CSS_TRUNCATE_TEXT } from '../../../styles/common'

const BrandAndTokenInfo = ({
  brandName,
  tokenName,
  tokenSymbol,
  logoUrl,
  children,
  t
}) => {
  return (
    <Grid
      container
      spacing={0.5}
      alignItems='center'
      sx={{ pt: '6px', pb: '6px', textAlign: 'initial' }}
    >
      <Grid item xs={3}>
        <BrandLogo
          size={42}
          icon={
            <Box fontSize={22} fontWeight={600}>
              {toUpper(brandName && brandName[0])}
            </Box>
          }
          plainBg
          src={logoUrl}
        />
      </Grid>
      <Grid
        item
        xs={8}
        display='flex'
        flexDirection='column'
        justifyContent='center'
      >
        {tokenName && (
          <CustomTooltip label={tokenName} showIfLength={18}>
            <Text
              label={tokenName}
              variant='h5'
              color='textprimary'
              sx={CSS_TRUNCATE_TEXT}
              fontWeight={500}
            />
          </CustomTooltip>
        )}
        {tokenSymbol && (
          <Text
            label={t('sidebar.symbol', { symbol: tokenSymbol })}
            variant='subtitle2'
            sx={CSS_TRUNCATE_TEXT}
          />
        )}
        {brandName && (
          <CustomTooltip label={brandName} showIfLength={22}>
            <Text
              label={brandName}
              variant='subtitle2'
              sx={CSS_TRUNCATE_TEXT}
            />
          </CustomTooltip>
        )}
      </Grid>
      {children}
    </Grid>
  )
}

export default memo(BrandAndTokenInfo)
