import {
  MEMBER_ACTIONS,
  MEMBER_DOWNLOAD_ACTIONS,
  MEMBER_TRANSACTION_ACTIONS,
  MEMBER_SEARCH_ACTIONS,
  REWARD_MEMBER_ACTION
} from '../../constants/actions'

const getMembers = (payload) => {
  return {
    type: MEMBER_ACTIONS.ON_GET_MEMBERS,
    payload
  }
}

const getMembersForDownload = () => {
  return {
    type: MEMBER_DOWNLOAD_ACTIONS.ON_GET_MEMBERS_FOR_DOWNLOAD
  }
}

const resetMembersDownloadState = () => {
  return {
    type: MEMBER_DOWNLOAD_ACTIONS.ON_RESET_STATE
  }
}

const resetMembersState = () => {
  return {
    type: MEMBER_ACTIONS.ON_RESET_STATE
  }
}

// MEMBER TRANSACTION
const changeMemberTransactionState = (property, value) => {
  return {
    type: MEMBER_TRANSACTION_ACTIONS.ON_CHANGE_STATE,
    payload: {
      property,
      value
    }
  }
}

const resetMemberTransactionState = () => {
  return {
    type: MEMBER_TRANSACTION_ACTIONS.ON_RESET_STATE
  }
}

const memberTransaction = () => {
  return {
    type: MEMBER_TRANSACTION_ACTIONS.ON_CREDIT_DEBIT_POINTS
  }
}

const getMemberBalances = () => {
  return {
    type: MEMBER_TRANSACTION_ACTIONS.ON_GET_BALANCES
  }
}

const getMemberTransactions = () => {
  return {
    type: MEMBER_ACTIONS.ON_GET_RECENT_TRANSACTIONS
  }
}

// MEMBER SEARCH
const changeMemberSearchState = (property, value) => {
  return {
    type: MEMBER_SEARCH_ACTIONS.ON_CHANGE_STATE,
    payload: {
      property,
      value
    }
  }
}

const resetMemberSearchState = () => {
  return {
    type: MEMBER_SEARCH_ACTIONS.ON_RESET_STATE
  }
}

const searchMembers = (query) => {
  return {
    type: MEMBER_SEARCH_ACTIONS.ON_SEARCH,
    payload: { query }
  }
}

const changeRewardMemberState = (property, value) => {
  return {
    type: REWARD_MEMBER_ACTION.ON_CHANGE_STATE,
    payload: {
      property,
      value
    }
  }
}

const resetRewardMemberState = () => {
  return {
    type: REWARD_MEMBER_ACTION.ON_RESET_STATE
  }
}

const creditPointsBankMember = () => {
  return {
    type: REWARD_MEMBER_ACTION.ON_CREDIT_POINTS_BANK
  }
}

const creditPointsExchangeMember = (tokenBOnramp) => {
  return {
    type: REWARD_MEMBER_ACTION.ON_CREDIT_POINTS_EXCHANGE,
    payload: { tokenBOnramp }
  }
}

export {
  getMembers,
  getMembersForDownload,
  resetMembersDownloadState,
  resetMembersState,
  changeMemberTransactionState,
  resetMemberTransactionState,
  memberTransaction,
  getMemberBalances,
  changeMemberSearchState,
  resetMemberSearchState,
  searchMembers,
  getMemberTransactions,
  changeRewardMemberState,
  resetRewardMemberState,
  creditPointsBankMember,
  creditPointsExchangeMember
}
