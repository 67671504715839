import React, { memo } from 'react'
import { Grid } from '@mui/material'

import LoyaltyPointOverview from './LoyaltyPointOverview'
import LoyaltyPointEconomics from './LoyaltyPointEconomics'
import PageContainer from '../shared/PageContainer/PageContainer'
import DisplayAlerts from '../setup/common/DisplayAlerts'
import BlockchainPreference from './BlockchainPreference'

const TokenInfo = ({
  tokenProfile,
  dispatchAction,
  onInfoSubmit,
  onChainPreferenceSubmit,
  t,
  billingProfile
}) => {
  const title = t('token-setup.loyalty-point-details')
  return (
    <PageContainer title={title}>
      <DisplayAlerts
        isDashboardSetupReady
        tokenStatus={tokenProfile.status}
        subscribedPackage={billingProfile.subscribedPackage}
        t={t}
      />
      <Grid container spacing={4} padding={2}>
        <Grid item xs={12}>
          <LoyaltyPointOverview
            title={title}
            dispatchAction={dispatchAction}
            onFormSubmit={onInfoSubmit}
            tokenProfile={tokenProfile}
            t={t}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LoyaltyPointEconomics tokenProfile={tokenProfile} t={t} />
        </Grid>
        <Grid item xs={12} md={6}>
          <BlockchainPreference
            onFormSubmit={onChainPreferenceSubmit}
            tokenProfile={tokenProfile}
            dispatchAction={dispatchAction}
            t={t}
          />
        </Grid>
      </Grid>
    </PageContainer>
  )
}

export default memo(TokenInfo)
