import React, { memo } from 'react'
import { Grid } from '@mui/material'
import { Trans } from 'react-i18next'
import { faEnvelope } from '@fortawesome/pro-light-svg-icons'

import DefaultText from '../shared/text/DefaultText'
import CustomButton from '../shared/buttons/CustomButton'
import BookACall from '../shared/buttons/BookACall'
import FaIcon from '../shared/FaIcon/FaIcon'
import ButtonsGroup from '../shared/buttons/ButtonsGroup'
import Link from '../shared/link/Link'
import { DASHBOARD_EMAIL } from '../../constants/externalResources'
import RedemptionShopCarousel from './RedemptionShopCarousel'

const RedemptionShop = ({
  accountProfile: { userId, firstName, lastName },
  t
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4}>
        <RedemptionShopCarousel t={t} />
      </Grid>
      <Grid item xs={12} sm={6} md={8}>
        <DefaultText
          label={<Trans i18nKey='extensions.redemption-shop.description' />}
          component='div'
        />
        <ButtonsGroup sx={{ pt: 2 }}>
          <Link
            isEmail
            to={`${DASHBOARD_EMAIL}?subject=${encodeURIComponent(
              t('extensions.redemption-shop.email-subject')
            )}&body=${encodeURIComponent(
              t('extensions.redemption-shop.email-body', {
                userName: `${firstName} ${lastName}`
              })
            )}`}
            underline='none'
            label={
              <CustomButton
                variant='outlined'
                fullWidth={false}
                label={t('common.contact-us')}
                startIcon={<FaIcon width={15} icon={faEnvelope} />}
              />
            }
          />
          <BookACall userId={userId} t={t} />
        </ButtonsGroup>
      </Grid>
    </Grid>
  )
}

export default memo(RedemptionShop)
