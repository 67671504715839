import React, { memo } from 'react'
import { Checkbox, FormControlLabel, Grid } from '@mui/material'

import {
  SURVEY_FIELD_TYPES,
  SURVEY_QUESTION_EXCHANGE
} from '../../../constants/survey'
import DisplayLabel from '../DisplayLabel/DisplayLabel'
import RichText from '../text/RichText'
import {
  transWithArgs,
  validPointValueRule
} from '../../../util/validation.helpers'
import Input from '../inputs/Input'
import CustomRadioGroup from '../inputs/CustomRadioGroup'
import CustomFormControl from '../inputs/CustomFormControl'
import { CURRENCY_SYMBOL } from '../../../constants/app'

const SurveyFields = ({
  surveyQuestions,
  dispatchAction,
  fieldValuesErrors,
  t
}) => {
  return (
    <Grid container spacing={2}>
      {surveyQuestions?.map((question, index) => (
        <Grid item xs={12} key={index}>
          <DisplayLabel
            label={question?.title}
            value={
              <>
                {question?.description && (
                  <RichText content={question?.description} />
                )}
                <SurveyField
                  {...question}
                  t={t}
                  dispatchAction={dispatchAction}
                  fieldValuesErrors={fieldValuesErrors}
                />
              </>
            }
            noMaxHeight
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default memo(SurveyFields)

const SurveyField = ({
  id,
  type,
  options,
  dispatchAction,
  number,
  t,
  fieldValuesErrors: { error, formData }
}) => {
  const value = formData?.[id] || ''
  const inputParams = {
    errorMessage: transWithArgs(t, error[id])
  }
  if (id === SURVEY_QUESTION_EXCHANGE.EXCHANGE_VOLUME) {
    inputParams.startIcon = CURRENCY_SYMBOL.USD
  }
  switch (type) {
    case SURVEY_FIELD_TYPES.SMALL_TEXT:
      return (
        <Input
          id={id}
          size='small'
          value={value}
          onChange={(value) => dispatchAction(id, value)}
          sx={{ mt: 2 }}
          fullWidth={false}
          validate={number && [validPointValueRule]}
          {...inputParams}
        />
      )

    case SURVEY_FIELD_TYPES.CHECKBOX:
      return (
        <CustomFormControl sx={{ ml: 1.5 }} {...inputParams}>
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={Boolean(value)}
                  onChange={(e) => dispatchAction(id, e.target.checked)}
                  size='small'
                />
              }
              label={option}
              componentsProps={{ typography: { variant: 'caption' } }}
            />
          ))}
        </CustomFormControl>
      )

    case SURVEY_FIELD_TYPES.RADIO:
      return (
        <CustomFormControl sx={{ ml: 1.5 }} {...inputParams}>
          <CustomRadioGroup
            data={options.map((value) => ({ value, label: value }))}
            onChange={(e) => dispatchAction(id, e.target.value)}
            size='small'
            row
            componentsProps={{ typography: { variant: 'caption' } }}
          />
        </CustomFormControl>
      )

    default:
      return null
  }
}
