export const THEME_MODE_LIGHT = 'light'
export const THEME_MODE_DARK = 'dark'
export const THEME_INPUT_SIZE_SMALL = 'small'

export const COLOR_PALETTE = {
  SUCCESS: 'success',
  ERROR: 'error',
  PRIMARY: 'primary',
  INFO: 'info',
  SECONDARY: 'secondary',
  WARNING: 'warning',
  ORANGE: 'orange',
  GREY4: 'grey4'
}
