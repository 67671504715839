import React, { memo } from 'react'
import { Grid, Box } from '@mui/material'

import { CONTAINER_MIN_HEIGHT } from '../../../styles/common'

const CardGrid = ({ children }) => {
  return (
    <Grid
      container
      spacing={4}
      padding={2}
      sx={{
        display: 'flow-root',
        ...CONTAINER_MIN_HEIGHT
      }}
    >
      {children}
      <Box flexGrow={1} />
    </Grid>
  )
}

export default memo(CardGrid)
