import { Box } from '@mui/material'
import styled from '@emotion/styled'

const CustomStatusDot = styled(Box)`
  border-radius: 50%;
  width: 7px;
  height: 7px;
  ${({ theme: { palette }, color }) =>
    `background-color: ${palette[color].main};`}
`

export { CustomStatusDot }
