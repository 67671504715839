import React, { memo } from 'react'
import { Box } from '@mui/material'

import Text from '../shared/text/Text'
import CustomButton from '../shared/buttons/CustomButton'
import FaIcon from '../shared/FaIcon/FaIcon'
import DefaultText from '../shared/text/DefaultText'
import { CSS_FLEX_COLUMN } from '../../styles/common'
import BaseCardContent from '../shared/BaseCardContent/BaseCardContent'
import ShowCircleGuidance from '../shared/Chip/ShowCircleGuidance'
import { buildLinkParams } from '../../util/navigation.helpers'

const ActionCard = ({
  title,
  heading,
  description,
  children,
  buttonLabel,
  to,
  isOutside,
  icon,
  actionProps,
  noGapBeforeAction,
  cardCss,
  titleCss,
  actionButtonProps,
  isShowGuidance
}) => {
  return (
    <BaseCardContent
      cardCss={cardCss}
      contentCss={{ height: '100%', ...CSS_FLEX_COLUMN }}
    >
      <Box display='flex' alignItems='flex-start'>
        {title && (
          <Text
            label={title}
            variant='h4'
            sx={{
              marginBottom: '0',
              ...titleCss
            }}
            gutterBottom
            fontWeight={500}
          />
        )}
        {heading}
      </Box>

      <DefaultText label={description} />
      {children}
      {!noGapBeforeAction && <Box flexGrow={1} />}
      {buttonLabel && (
        <CustomButton
          label={
            <ShowCircleGuidance show={isShowGuidance} invert>
              {buttonLabel}
            </ShowCircleGuidance>
          }
          {...buildLinkParams({ isOutside, href: to })}
          {...actionButtonProps}
          sx={actionProps}
          startIcon={icon && <FaIcon icon={icon} />}
        />
      )}
    </BaseCardContent>
  )
}

export default memo(ActionCard)
