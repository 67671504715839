import React, { memo } from 'react'
import { Grid } from '@mui/material'

import { ACCOUNT_PROFILE_DISPATCH } from '../../store/reducers/account/account'
import Input from '../shared/inputs/Input'
import AccountForm from '../shared/AccountForm/AccountForm'
import { QB_INPUT_PASSWORD } from '../../constants/input'
import { transWithArgs } from '../../util/validation.helpers'

const ChangePassword = ({
  onFormSubmit,
  dispatchAction,
  accountProfile: { error, success, oldPassword, newPassword },
  t
}) => {
  return (
    <AccountForm
      title={t('account.change-password')}
      onFormSubmit={onFormSubmit}
      t={t}
      fields={
        <>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Input
              id={ACCOUNT_PROFILE_DISPATCH.OLD_PASSWORD}
              label={t('account.enter-old-password')}
              value={oldPassword}
              type={QB_INPUT_PASSWORD}
              errorMessage={transWithArgs(
                t,
                error[ACCOUNT_PROFILE_DISPATCH.OLD_PASSWORD]
              )}
              onChange={(value) =>
                dispatchAction(ACCOUNT_PROFILE_DISPATCH.OLD_PASSWORD, value)
              }
              successMsg={transWithArgs(
                t,
                success[ACCOUNT_PROFILE_DISPATCH.OLD_PASSWORD]
              )}
              size='small'
            />
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Input
              id={ACCOUNT_PROFILE_DISPATCH.NEW_PASSWORD}
              label={t('account.enter-new-password')}
              value={newPassword}
              type={QB_INPUT_PASSWORD}
              errorMessage={transWithArgs(
                t,
                error[ACCOUNT_PROFILE_DISPATCH.NEW_PASSWORD]
              )}
              onChange={(value) =>
                dispatchAction(ACCOUNT_PROFILE_DISPATCH.NEW_PASSWORD, value)
              }
              successMsg={transWithArgs(
                t,
                success[ACCOUNT_PROFILE_DISPATCH.NEW_PASSWORD]
              )}
              size='small'
            />
          </Grid>
        </>
      }
    />
  )
}

export default memo(ChangePassword)
