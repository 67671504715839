import React, { memo } from 'react'
import { Trans } from 'react-i18next'

import Link from '../link/Link'

const TransWithInAppLink = ({ labelKey, linkLabelKey, to }) => {
  return (
    <Trans i18nKey={labelKey}>
      <Link to={to} label={<Trans i18nKey={linkLabelKey} />} />
    </Trans>
  )
}

export default memo(TransWithInAppLink)
