import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'

import Signup from '../components/signup/Signup'
import SignupVerification from '../components/signup/SignupVerification'
import {
  changeSignupState,
  changeSignupInputAndErrorState,
  cleanSignupState,
  signUp,
  resendSignupEmail,
  cleanLoginState
} from '../store/actions/auth'
import requireUnauth from '../hoc/requireUnauth'
import { SIGN_UP_PROFILE_DISPATCH } from '../store/reducers/auth/signup'
import {
  DEFAULT_CONTAINER_STATE,
  SIGN_UP_PROCESS_STEP
} from '../constants/containerStates'
import {
  AUTH_FIRST_NAME_ATTR,
  AUTH_LAST_NAME_ATTR,
  AUTH_TERMS_ACCEPTED_ATTR,
  QB_AUTH_LANGUAGE_ATTR
} from '../constants/auth'
import i18n from '../i18n'
import { containsNoValues } from '../util/object.helpers'
import { trim } from '../util/string.helpers'
import {
  checkValidations,
  requiredRule,
  emailRule,
  passwordRule
} from '../util/validation.helpers'

const SignupContainer = ({
  signupProfile,
  changeSignupState,
  changeSignupInputAndErrorState,
  cleanSignupState,
  signUp,
  resendSignupEmail,
  cleanLoginState
}) => {
  const { t } = useTranslation()

  const { email, signupState } = signupProfile

  useEffect(() => {
    return () => {
      cleanSignupState()
      cleanLoginState()
    }
  }, [cleanSignupState])

  const handleSignupSubmit = () => {
    changeSignupState(SIGN_UP_PROFILE_DISPATCH.ERROR, {})
    const { firstName, lastName, email, password } = signupProfile
    const profileErrors = checkValidations({
      firstName: { value: firstName, rules: [requiredRule] },
      lastName: { value: lastName, rules: [requiredRule] },
      email: { value: email, rules: [requiredRule, emailRule] },
      password: { value: password, rules: [requiredRule, passwordRule] }
    })

    if (containsNoValues(profileErrors)) {
      const attributes = {
        [AUTH_LAST_NAME_ATTR]: trim(lastName),
        [AUTH_FIRST_NAME_ATTR]: trim(firstName),
        [QB_AUTH_LANGUAGE_ATTR]: i18n.language,
        [AUTH_TERMS_ACCEPTED_ATTR]: String(true)
      }
      signUp(email, password, attributes)
    } else {
      changeSignupState(SIGN_UP_PROFILE_DISPATCH.ERROR, profileErrors)
    }
  }

  const handleResendVerification = () => {
    resendSignupEmail(email)
  }

  const handleCancelVerification = () => {
    changeSignupState(
      SIGN_UP_PROFILE_DISPATCH.SIGN_UP_STATE,
      DEFAULT_CONTAINER_STATE.NONE
    )
  }

  const renderContent = () => {
    switch (signupState) {
      case SIGN_UP_PROCESS_STEP.SIGN_UP_SUCCESS:
      case SIGN_UP_PROCESS_STEP.RESEND_VERIFICATION_SUCCESS:
      case SIGN_UP_PROCESS_STEP.RESEND_VERIFICATION_FAILED:
        return (
          <SignupVerification
            signupState={signupState}
            onResendVerification={handleResendVerification}
            onCancelVerification={handleCancelVerification}
            t={t}
          />
        )
      default:
        return (
          <Signup
            signupProfile={signupProfile}
            dispatchAction={changeSignupInputAndErrorState}
            onSignupSubmit={handleSignupSubmit}
            t={t}
          />
        )
    }
  }

  return renderContent()
}

const mapStateToProps = ({ authReducer }) => {
  const { signupProfile } = authReducer
  return {
    signupProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeSignupState,
      changeSignupInputAndErrorState,
      cleanSignupState,
      signUp,
      resendSignupEmail,
      cleanLoginState
    },
    dispatch
  )

export default requireUnauth(
  connect(mapStateToProps, mapDispatchToProps)(SignupContainer)
)
