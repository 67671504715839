import React from 'react'
import { useTranslation } from 'react-i18next'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import RequestFeature from '../components/requestFeature/RequestFeature'

const RequestFeatureContainer = ({ ...restProps }) => {
  const { t } = useTranslation()

  return <RequestFeature {...restProps} t={t} />
}

const mapStateToProps = ({ accountReducer, requestFeatureReducer }) => {
  const { accountProfile } = accountReducer
  const { requestFeatureProfile } = requestFeatureReducer
  return {
    accountProfile,
    ssoToken: requestFeatureProfile.ssoToken
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestFeatureContainer)
