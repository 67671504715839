import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, map, mergeMap, tap } from 'rxjs/operators'

import {
  MAIN_PROFILE_ACTION,
  REQUEST_FEATURE_ACTIONS
} from '../../constants/actions'
import apiService from '../../services/api'
import { wrapUserAccessToken } from '../../util/auth.helpers'
import { logger } from '../../util/log.helpers'
import { extractCannySSOData } from '../../util/epics.helpers'

const handleGetCannySSOToken = (action$, state$) =>
  action$.pipe(
    ofType(MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getCannySSOToken(extractCannySSOData(state$), accessToken)
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: REQUEST_FEATURE_ACTIONS.ON_GET_SSO_TOKEN_SUCCESS,
              payload: response.data
            })),
            catchError((err) =>
              of({
                type: REQUEST_FEATURE_ACTIONS.ON_GET_SSO_TOKEN_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

export const requestFeatureEpic = combineEpics(handleGetCannySSOToken)
