const ADMIN_TAB_TYPES = {
  BRAND_DETAILS: 'brand-details',
  TEAM_MEMBERS: 'team-members',
  PACKAGES_AND_BILLING: 'packages-billing'
}

const ADMIN_TAB_CONTENT_ID = {
  BRAND_DETAILS: 0,
  TEAM_MEMBERS: 1,
  PACKAGES_AND_BILLING: 2
}

export { ADMIN_TAB_CONTENT_ID, ADMIN_TAB_TYPES }
