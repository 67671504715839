import React, { memo } from 'react'
import { Stack, TableContainer, TablePagination } from '@mui/material'

import { PAGE_NUMBER_OPTIONS } from '../../../constants/app'
import TableHeaderBody from './TableHeaderBody'
import Text from '../text/Text'

const DataTable = ({
  noRecordsMessage,
  onChangePage,
  onRowsPerPageChange,
  pagination: { totalTxCount, recordsPerPage, pageNumber },
  paginationProps,
  t,
  action,
  ...restProps
}) => {
  const handleChangePage = (event, newPage) => {
    onChangePage(newPage)
  }

  const handleChangeRowsPerPage = ({ target }) => {
    if (totalTxCount > 1) {
      onRowsPerPageChange(parseInt(target.value, 10))
    }
  }

  return (
    <>
      <TableContainer>
        <TableHeaderBody t={t} {...restProps} />
      </TableContainer>
      {totalTxCount > 0 ? (
        <TablePagination
          rowsPerPageOptions={PAGE_NUMBER_OPTIONS}
          labelRowsPerPage={t('common.rows-per-page')}
          component='div'
          count={totalTxCount}
          rowsPerPage={recordsPerPage}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ ...paginationProps }}
        />
      ) : (
        <Stack alignItems='center'>
          <Text label={noRecordsMessage} textAlign='center' p={2} />
          {action}
        </Stack>
      )}
    </>
  )
}

export default memo(DataTable)
