import React, { memo } from 'react'
import { Box } from '@mui/material'

import Google from './Google'
import Text from '../shared/text/Text'

const SocialMediaButtons = ({ action, onGoogleSignIn }) => {
  return (
    <Box mt={4} mb={1}>
      <Google onClick={onGoogleSignIn} />
      <Box
        sx={{
          position: 'relative',
          textAlign: 'center',
          mt: 3,
          '&::before': {
            content: '""',
            height: '1px',
            width: '100%',
            position: 'absolute',
            left: '0',
            top: '13px',
            background: (theme) => theme.palette.inputBorderColor
          }
        }}
      >
        <Text
          component='span'
          color='textSecondary'
          variant='h6'
          sx={{
            textTransform: 'lowercase',
            position: 'relative',
            padding: '0 12px',
            background: (theme) => theme.palette.background.default
          }}
          label={`or ${action} with`}
        />
      </Box>
    </Box>
  )
}

export default memo(SocialMediaButtons)
