import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Header from '../../components/dashboardLayout/header/Header'
import { updateUserAccount } from '../../store/actions/account'
import { buildAppNotifications } from '../../util/notification.helpers'

const HeaderContainer = ({
  updateUserAccount,
  metadata,
  exchangePartnersStats: { incomingRequests, activeCount },
  userId,
  tokenProfile,
  billingProfile,
  ...props
}) => {
  const { t } = useTranslation()
  const handleOnChangeMode = () => {
    updateUserAccount({
      metadata: { dark_mode: !metadata?.darkMode }
    })
  }

  const buildNotifications = useMemo(() => {
    return buildAppNotifications(
      incomingRequests,
      tokenProfile,
      billingProfile.subscribedPackage,
      t
    )
  }, [incomingRequests, tokenProfile, billingProfile.subscribedPackage])

  return (
    <Header
      t={t}
      onChangeMode={handleOnChangeMode}
      isDarkMode={metadata?.darkMode}
      showBookACall={!activeCount}
      notifications={buildNotifications}
      userId={userId}
      {...props}
    />
  )
}

const mapStateToProps = ({ accountReducer, tokensReducer, mainReducer }) => {
  const { userId, firstName, lastName, email, metadata } =
    accountReducer.accountProfile
  const { tokenProfile } = tokensReducer
  const { billingProfile, mainProfile } = mainReducer

  return {
    userId,
    firstName,
    lastName,
    email,
    metadata,
    exchangePartnersStats: mainProfile.exchangePartnersStats,
    tokenProfile,
    billingProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserAccount
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer)
