import { SURVEY_ACTION } from '../../constants/actions'

const submitUserSurvey = (surveyId, questionId, liked) => ({
  type: SURVEY_ACTION.ON_SUBMIT_USER_SURVEY,
  payload: { surveyId, questionId, liked }
})

const updateUserSurvey = (surveyId, id, questionId, liked) => ({
  type: SURVEY_ACTION.ON_UPDATE_USER_SURVEY,
  payload: { surveyId, id, questionId, liked }
})

const getUserSurveys = () => ({
  type: SURVEY_ACTION.ON_GET_USER_SURVEYS
})

const getBrandSurveys = () => ({
  type: SURVEY_ACTION.ON_GET_BRAND_SURVEYS
})

const getListUserSurveys = (partner) => ({
  type: SURVEY_ACTION.ON_GET_LIST_USER_SURVEYS,
  payload: { partner }
})

const changeSurveyState = (property, value) => {
  return {
    type: SURVEY_ACTION.CHANGE_STATE,
    payload: {
      property,
      value
    }
  }
}
export {
  submitUserSurvey,
  updateUserSurvey,
  getUserSurveys,
  getBrandSurveys,
  getListUserSurveys,
  changeSurveyState
}
