import React, { memo } from 'react'
import { useTheme } from '@mui/material/styles'

import FaIcon from '../FaIcon/FaIcon'

const StatusIcon = ({ icon, color }) => {
  const iconColor = useTheme().palette[color]?.main
  return <FaIcon icon={icon} color={iconColor} />
}

export default memo(StatusIcon)
