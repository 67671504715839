import React, { memo } from 'react'
import { Trans } from 'react-i18next'
import { Grid } from '@mui/material'

import {
  DASHBOARD_EMAIL,
  PRIVACY_POLICY_URL,
  QB_WEBSITE_DOMAIN,
  QB_WEBSITE_URL,
  TERMS_COND_URL
} from '../../constants/externalResources'
import Link from '../shared/link/Link'
import Text from '../shared/text/Text'
import AccountForm from '../shared/AccountForm/AccountForm'

const MoreInfo = ({ t }) => {
  return (
    <AccountForm
      title={t('account.more-information')}
      t={t}
      fields={
        <Grid item xs={12}>
          <Text
            label={
              <Trans i18nKey='account.info-support'>
                <Link isEmail to={DASHBOARD_EMAIL} />
              </Trans>
            }
            variant='body1'
            color='textprimary'
            sx={{ mb: 2, mt: 2 }}
          />
          <Text
            label={
              <Trans i18nKey='account.view-terms-privacy'>
                <Link isOutside to={TERMS_COND_URL} label={t('common.terms')} />
                <Link
                  isOutside
                  to={PRIVACY_POLICY_URL}
                  label={t('common.privacy')}
                />
              </Trans>
            }
            variant='body1'
            color='textprimary'
            sx={{ mb: 2 }}
          />

          <Text
            label={
              <Trans i18nKey='account.info-more'>
                <Link isOutside to={QB_WEBSITE_URL} label={QB_WEBSITE_DOMAIN} />
              </Trans>
            }
            variant='body1'
            color='textprimary'
            sx={{ mb: 2 }}
          />
        </Grid>
      }
      noActions
    />
  )
}

export default memo(MoreInfo)
