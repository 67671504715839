import {
  faHouse,
  faArrowRightArrowLeft,
  faSliders,
  faChartMixed,
  faCircleInfo,
  faCode,
  faCoin,
  faGear,
  faTrianglePersonDigging,
  faGauge,
  faPuzzle,
  faGrid2Plus,
  faHexagonVerticalNftSlanted
} from '@fortawesome/pro-light-svg-icons'

import {
  HOME_URL,
  SETUP_URL,
  NFT_URL,
  INTEGRATION_URL,
  LOYALTY_POINT_URL,
  REQUEST_FEATURE_URL,
  ANALYTICS_URL,
  EXTENSIONS_URL
} from '../../../constants/navigation'
import {
  HELP_CENTER_EXTERNAL_URL,
  PRODUCTION_URL,
  SANDBOX_URL
} from '../../../constants/externalResources'
import { isDashboardReady } from '../../../util/dashboard.helpers'
import { buildExchangePotentialUrl } from '../../../util/exchange.helpers'
import { adminBrandDetailsUrl } from '../../../util/admin.helpers'
import { isSandbox } from '../../../util/app/app.helpers'

const buildSidebarLinks = ({
  dashboardSetupState,
  isNewBrandCreation,
  isShowGuidanceForExchange,
  exchangeTxCount,
  isAPIKeyCreated
}) => {
  const setupReady = isDashboardReady(dashboardSetupState)
  const isSandboxEnv = isSandbox()
  return [
    {
      subHeader: 'sidebar.dashboard'
    },
    {
      href: HOME_URL,
      title: 'sidebar.home',
      icon: faHouse
    },
    {
      href: buildExchangePotentialUrl(),
      title: 'sidebar.marketplace',
      icon: faArrowRightArrowLeft,
      isShowGuidance: setupReady && isShowGuidanceForExchange,
      id: 'sttw-exchange'
    },
    setupReady && !isNewBrandCreation
      ? {
          href: LOYALTY_POINT_URL,
          title: 'sidebar.loyalty-point',
          icon: faCoin
        }
      : {
          href: SETUP_URL,
          title: 'sidebar.setup',
          icon: faSliders,
          isShowGuidance: !setupReady,
          id: 'sttw-setup'
        },
    {
      href: ANALYTICS_URL,
      title: 'sidebar.analytics',
      icon: faChartMixed,
      id: 'sttw-analytics'
    },
    {
      href: NFT_URL,
      title: 'sidebar.nft-launchpad',
      icon: faHexagonVerticalNftSlanted,
      id: 'sttw-nft'
    },
    {
      subHeader: 'sidebar.developers'
    },
    {
      href: INTEGRATION_URL,
      title: 'sidebar.integration',
      icon: faCode,
      isShowGuidance: setupReady && exchangeTxCount <= 0 && !isAPIKeyCreated,
      id: 'sttw-integration'
    },
    {
      href: EXTENSIONS_URL,
      title: 'extensions.extensions',
      icon: faGrid2Plus,
      id: 'sttw-extensions'
    },
    {
      isOutside: true,
      href: isSandboxEnv ? PRODUCTION_URL : SANDBOX_URL,
      title: isSandboxEnv
        ? 'sidebar.go-to-production'
        : 'sidebar.go-to-sandbox',
      icon: isSandboxEnv ? faGauge : faTrianglePersonDigging,
      id: 'sttw-sandbox'
    },
    {
      subHeader: 'sidebar.more'
    },
    {
      href: adminBrandDetailsUrl(),
      title: 'sidebar.admin',
      icon: faGear,
      id: 'sttw-admin'
    },
    {
      href: REQUEST_FEATURE_URL,
      title: 'sidebar.request-feature',
      icon: faPuzzle,
      id: 'sttw-request-feature'
    },
    {
      isOutside: true,
      href: HELP_CENTER_EXTERNAL_URL,
      title: 'sidebar.help-center',
      icon: faCircleInfo
    }
  ]
}

export { buildSidebarLinks }
