import React, { memo } from 'react'
import { Grid } from '@mui/material'

import { ACCOUNT_PROFILE_DISPATCH } from '../../store/reducers/account/account'
import CountryField from '../shared/CountryField/CountryField'
import Input from '../shared/inputs/Input'
import AccountForm from '../shared/AccountForm/AccountForm'
import { transWithArgs } from '../../util/validation.helpers'

const PersonalInfo = ({
  onFormSubmit,
  dispatchAction,
  accountProfile: {
    error,
    success,
    firstNameTemp,
    lastNameTemp,
    jobPositionTemp,
    countryTemp
  },
  t
}) => {
  return (
    <AccountForm
      title={t('account.personal-info')}
      onFormSubmit={onFormSubmit}
      t={t}
      fields={
        <>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Input
              id={ACCOUNT_PROFILE_DISPATCH.FIRST_NAME_TEMP}
              label={t('sign-up.first-name')}
              value={firstNameTemp}
              errorMessage={transWithArgs(
                t,
                error[ACCOUNT_PROFILE_DISPATCH.FIRST_NAME_TEMP]
              )}
              onChange={(value) =>
                dispatchAction(ACCOUNT_PROFILE_DISPATCH.FIRST_NAME_TEMP, value)
              }
              successMsg={transWithArgs(
                t,
                success[ACCOUNT_PROFILE_DISPATCH.FIRST_NAME_TEMP]
              )}
              size='small'
            />
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Input
              id={ACCOUNT_PROFILE_DISPATCH.LAST_NAME_TEMP}
              label={t('sign-up.last-name')}
              value={lastNameTemp}
              errorMessage={transWithArgs(
                t,
                error[ACCOUNT_PROFILE_DISPATCH.LAST_NAME_TEMP]
              )}
              onChange={(value) =>
                dispatchAction(ACCOUNT_PROFILE_DISPATCH.LAST_NAME_TEMP, value)
              }
              successMsg={transWithArgs(
                t,
                success[ACCOUNT_PROFILE_DISPATCH.LAST_NAME_TEMP]
              )}
              size='small'
            />
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Input
              id={ACCOUNT_PROFILE_DISPATCH.JOB_POSITION_TEMP}
              label={t('account.job-position')}
              value={jobPositionTemp}
              errorMessage={transWithArgs(
                t,
                error[ACCOUNT_PROFILE_DISPATCH.JOB_POSITION_TEMP]
              )}
              onChange={(value) =>
                dispatchAction(
                  ACCOUNT_PROFILE_DISPATCH.JOB_POSITION_TEMP,
                  value
                )
              }
              successMsg={transWithArgs(
                t,
                success[ACCOUNT_PROFILE_DISPATCH.JOB_POSITION_TEMP]
              )}
              size='small'
            />
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <CountryField
              id={ACCOUNT_PROFILE_DISPATCH.COUNTRY_TEMP}
              value={countryTemp}
              errorMessage={transWithArgs(
                t,
                error[ACCOUNT_PROFILE_DISPATCH.COUNTRY_TEMP]
              )}
              successMsg={transWithArgs(
                t,
                success[ACCOUNT_PROFILE_DISPATCH.COUNTRY_TEMP]
              )}
              onChange={(target) =>
                dispatchAction(
                  ACCOUNT_PROFILE_DISPATCH.COUNTRY_TEMP,
                  target?.value
                )
              }
              t={t}
            />
          </Grid>
        </>
      }
    />
  )
}

export default memo(PersonalInfo)
