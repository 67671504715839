import React, { memo } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import { faHeart } from '@fortawesome/pro-solid-svg-icons'
import { faHeart as faHeartLight } from '@fortawesome/pro-light-svg-icons'

import BaseCard from '../BaseCard/BaseCard'
import DisplayLabel from '../DisplayLabel/DisplayLabel'
import FaIcon from '../FaIcon/FaIcon'
import BrandCard from '../BrandCard/BrandCard'
import Text from '../text/Text'
import { CSS_TRUNCATE_TEXT, CSS_FLEX_COLUMN } from '../../../styles/common'
import SmallButton from '../buttons/SmallButton'
import CustomTooltip from '../CustomTooltip/CustomTooltip'
import Link from '../link/Link'
import RichText from '../text/RichText'
import { SURVEY_DESC_LENGTH } from '../../../constants/survey'

const LoveBrandCard = ({
  title,
  likesCount = 0,
  description,
  metadata,
  liked,
  gridSize = 4,
  xlGridSize = 3,
  t,
  onLikeUnlike,
  noTruncateDesc,
  icon,
  iconColor,
  plainBg
}) => {
  const type = metadata?.type
  const website = metadata?.websiteUrl
  const logoUrl = metadata?.imageUrl
  const isDescLengthMore = description.length > SURVEY_DESC_LENGTH
  return (
    <Grid
      item
      sm={6}
      md={gridSize}
      lg={gridSize}
      xl={xlGridSize}
      sx={{ width: '100%' }}
    >
      <BaseCard sx={{ p: 0, ...CSS_FLEX_COLUMN }}>
        <BrandCard
          brandIcon={icon && <FaIcon icon={icon} />}
          label={likesCount}
          icon={faHeart}
          logoUrl={logoUrl}
          hideDefaultBg={icon}
          iconColor={iconColor}
          plainBg={plainBg}
        />
        <Grid container spacing={1} p={2} pb={0}>
          <Grid item xs={12}>
            <Text
              label={title}
              variant='h5'
              fontWeight={700}
              sx={CSS_TRUNCATE_TEXT}
            />
            {description && (
              <CustomTooltip
                label={description}
                parentProps={{
                  sx: { overflow: 'hidden' }
                }}
                showIfLength={!noTruncateDesc && isDescLengthMore}
              >
                <Text
                  label={
                    <RichText
                      content={
                        noTruncateDesc || !isDescLengthMore
                          ? description
                          : `${description.substring(0, SURVEY_DESC_LENGTH)}...`
                      }
                    />
                  }
                  variant='caption'
                  component='div'
                  sx={{
                    lineHeight: '17px',
                    mb: 0.7
                  }}
                />
              </CustomTooltip>
            )}
          </Grid>

          {type && (
            <Grid item xs={12} sm={5}>
              <DisplayLabel label={t('common.type')} value={type} />
            </Grid>
          )}
          {website && (
            <Grid item xs={12} sm={7}>
              <DisplayLabel
                label={t('common.website')}
                value={
                  <Link
                    isOutside
                    label={website}
                    to={website}
                    sx={{ width: '100%', ...CSS_TRUNCATE_TEXT }}
                  />
                }
              />
            </Grid>
          )}
        </Grid>
        <Box flexGrow={1} />
        <Stack
          spacing={1}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ alignItems: 'center', m: 2, justifyContent: 'stretch' }}
        >
          <SmallButton
            label={t(`extensions.${liked ? 'liked' : 'like'}`)}
            color='error'
            variant={liked ? 'contained' : 'outlined'}
            onClick={onLikeUnlike}
            startIcon={
              <FaIcon icon={liked ? faHeart : faHeartLight} width={14} />
            }
            noPadding
          />
        </Stack>
      </BaseCard>
    </Grid>
  )
}

export default memo(LoveBrandCard)
