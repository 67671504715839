import { combineReducers } from 'redux'

import { membersProfile } from './members'
import { membersDownloadProfile } from './membersDownload'
import { memberTransactionProfile } from './memberTransaction'
import { memberSearchProfile } from './memberSearch'
import { rewardMemberProfile } from './rewardMember'

export const membersReducer = combineReducers({
  membersProfile,
  membersDownloadProfile,
  memberTransactionProfile,
  memberSearchProfile,
  rewardMemberProfile
})
