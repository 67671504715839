import React, { memo } from 'react'
import { Box } from '@mui/material'

import BrandLogo from '../BrandLogo/BrandLogo'
import { toUpper } from '../../../util/string.helpers'
import BrandCardLabelIcon from './BrandCardLabelIcon'

const BrandCard = ({
  label,
  icon,
  logoUrl,
  brandIcon,
  brandName,
  size = 150,
  brandLogoSize,
  hideDefaultBg,
  onClick,
  cryptoNav,
  logoStyles,
  logoProps,
  iconColor = 'white',
  plainBg
}) => {
  const bgStyle = logoUrl
    ? {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(${logoUrl})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }
    : !hideDefaultBg && {
        backgroundImage: ({ palette: { primary, secondary } }) =>
          `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), linear-gradient(145deg, ${primary.main} , ${secondary.main})`
      }

  return (
    <Box
      sx={{
        ...bgStyle,
        position: 'relative',
        height: size,
        width: '100%',
        borderRadius: '20px 20px 0 0',
        ...(onClick && { cursor: 'pointer' })
      }}
      display='flex'
      alignItems='center'
      justifyContent='center'
      onClick={onClick ? onClick : undefined}
    >
      <BrandLogo
        src={logoUrl}
        plainBg={plainBg}
        sx={{
          ...(plainBg && {
            color: 'common.black',
            backgroundColor: 'common.white',
            fontSize: 48
          }),
          ...logoStyles
        }}
        icon={
          brandIcon
            ? brandIcon
            : brandName && (
                <Box fontSize={40} fontWeight={800}>
                  {toUpper(brandName)}
                </Box>
              )
        }
        size={brandLogoSize}
        {...logoProps}
      />
      {cryptoNav?.brandIcon}
      {icon && (
        <BrandCardLabelIcon label={label} icon={icon} iconColor={iconColor} />
      )}
    </Box>
  )
}

export default memo(BrandCard)
