import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { SOCIAL_MEDIA } from '../../constants/app'
import SocialMediaButtons from '../../components/socialMediaButtons/SocialMediaButtons'

import { socialMediaLogIn } from '../../store/actions/auth'

const SocialMediaButtonsContainer = ({ socialMediaLogIn, ...restProps }) => {
  const handleGoogleSignIn = () => {
    socialMediaLogIn(SOCIAL_MEDIA.GOOGLE)
  }
  return (
    <SocialMediaButtons onGoogleSignIn={handleGoogleSignIn} {...restProps} />
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      socialMediaLogIn
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(SocialMediaButtonsContainer)
