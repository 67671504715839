import React, { memo } from 'react'
import { Grid, Stack } from '@mui/material'
import { Trans } from 'react-i18next'

import CustomDialog from '../../shared/CustomDialog/CustomDialog'
import Dropdown from '../../shared/inputs/Dropdown'
import { TXN_STATUS_DISPATCH } from '../../../store/reducers/transactions/changeStatus'
import { transWithArgs } from '../../../util/validation.helpers'
import { buildTxnStatusOptions } from '../../../util/transaction/transaction.helpers'
import Text from '../../shared/text/Text'

const ChangeTxnStatus = ({
  t,
  onClose,
  onSubmit,
  dispatchAction,
  changeStatusProfile: { isProcessing, status, error },
  gridSize = 6,
  ...restProps
}) => {
  return (
    <CustomDialog
      isForm
      title={t('analytics.change-future-miles-title')}
      description={t('analytics.you-can-change-manually-status')}
      open
      onClose={onClose}
      submitProps={{
        label: t('common.confirm'),
        disabled: isProcessing
      }}
      body={
        <>
          <Stack spacing={1}>
            <Text
              variant='body1'
              color='textprimary'
              label={<Trans i18nKey='analytics.success-txn-desc' />}
            />
            <Text
              variant='body1'
              color='textprimary'
              label={<Trans i18nKey='analytics.reverse-txn-desc' />}
            />
          </Stack>
          <Grid container spacing={2}>
            <Grid item md={gridSize} sm={gridSize} xs={12}>
              <Dropdown
                id={TXN_STATUS_DISPATCH.STATUS}
                label={t('transactions.transaction-status')}
                onChange={({ target }) => {
                  dispatchAction(TXN_STATUS_DISPATCH.STATUS, target.value)
                }}
                value={status}
                errorMessage={transWithArgs(
                  t,
                  error[TXN_STATUS_DISPATCH.STATUS]
                )}
                options={buildTxnStatusOptions(t)}
                size='small'
              />
            </Grid>
          </Grid>
        </>
      }
      onSubmit={onSubmit}
      t={t}
      {...restProps}
    />
  )
}

export default memo(ChangeTxnStatus)
