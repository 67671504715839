import { toLower, trim } from './string.helpers'

const elementIsInArray = (array, element) => {
  return array && array.indexOf(element) >= 0
}

const getLastElementFromArray = (list) => {
  return list && list.length > 0 && list[list.length - 1]
}

const elementIsInArrayCaseInsensitive = (list, val) => {
  const result = list.findIndex((item) => toLower(trim(val)) === toLower(item))
  return result !== -1 ? true : false
}

export {
  elementIsInArray,
  getLastElementFromArray,
  elementIsInArrayCaseInsensitive
}
