import { map, mapTo } from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable'

import { WELCOME_ACTION } from '../../constants/actions'
import { getWelcomePopup, setWelcomePopup } from '../../util/local.helpers'

const handleGetWelcomePopupState = (action$) =>
  action$.pipe(
    ofType(WELCOME_ACTION.ON_GET_WELCOME_STATE),
    map(() => {
      if (!getWelcomePopup()) {
        return {
          type: WELCOME_ACTION.ON_GET_WELCOME_STATE_SUCCESS,
          payload: true
        }
      }
      return {
        type: WELCOME_ACTION.ON_UPDATE_WELCOME_STATE_SUCCESS,
        payload: false
      }
    })
  )

const handleSetWelcomePopupState = (action$) =>
  action$.pipe(
    ofType(WELCOME_ACTION.ON_UPDATE_WELCOME_STATE),
    map(({ payload }) => setWelcomePopup(payload)),
    mapTo({
      type: WELCOME_ACTION.ON_UPDATE_WELCOME_STATE_SUCCESS,
      payload: false
    })
  )

export const welcomeEpic = combineEpics(
  handleGetWelcomePopupState,
  handleSetWelcomePopupState
)
