import { EXCHANGE_TRANSACTION_TYPES } from '../../constants/transactionTypes'
import { formatTimeToMoment, formatToIOSdate } from '../date.helpers'
import { getSortedKeysFromData } from '../object.helpers'
import { calculatePointValue } from '../token.helpers'
import { buildFormattedChartLabel } from './transactionsGraph.helpers'

const processTransactionsChartByFilter = (data, exchangeType) => {
  switch (exchangeType) {
    case EXCHANGE_TRANSACTION_TYPES.BUY:
      return { buy: data.buy }
    case EXCHANGE_TRANSACTION_TYPES.SELL:
      return { sell: data.sell }
    default:
      return {
        buy: data.buy,
        sell: data.sell
      }
  }
}

const processPointsTransferredChartByFilter = (data, exchangeType) => {
  switch (exchangeType) {
    case EXCHANGE_TRANSACTION_TYPES.BUY:
      return data.map((record) => record.buy)
    case EXCHANGE_TRANSACTION_TYPES.SELL:
      return data.map((record) => record.sell)
    default:
      return data.map((record) => record.buy + record.sell)
  }
}

const buildTransactionsChartData = ({
  tokenProfile: { offramp, onramp },
  chartData,
  analyticsFilter: { exchangeType, interval }
}) => {
  const txCountData = {
    buy: [],
    sell: []
  }
  const txTransferredData = []
  const txTransferredLabels = []
  for (let time of getSortedKeysFromData(chartData)) {
    const chartRecord = chartData[time]
    const x = formatToIOSdate(time)

    const momentTime = formatTimeToMoment(time)

    txTransferredLabels.push({
      formatted: buildFormattedChartLabel(momentTime, interval),
      unformatted: momentTime
    })
    txTransferredData.push({
      buy: calculatePointValue(chartRecord.exchangeOutTxVolume, offramp),
      sell: calculatePointValue(chartRecord.exchangeInTxVolume, onramp)
    })

    txCountData.buy.push({
      x,
      y: chartRecord?.exchangeOutTxCount,
      interval
    })
    txCountData.sell.push({
      x,
      y: chartRecord?.exchangeInTxCount,
      interval
    })
  }
  return {
    valueTransferredChart: {
      data: txTransferredData,
      filteredData: processPointsTransferredChartByFilter(
        txTransferredData,
        exchangeType
      ),
      labels: txTransferredLabels
    },
    transactionsChart: {
      data: txCountData,
      filteredData: processTransactionsChartByFilter(txCountData, exchangeType)
    }
  }
}

export {
  processTransactionsChartByFilter,
  processPointsTransferredChartByFilter,
  buildTransactionsChartData
}
