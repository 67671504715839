import { Box, Divider, MenuItem, Link as MuiLink } from '@mui/material'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'

import BrandLogo from '../../shared/BrandLogo/BrandLogo'
import Text from '../../shared/text/Text'
import { formatToLongDate } from '../../../util/date.helpers'

const Notification = ({ onClick, label, date, logoParams, linkTo }) => {
  return (
    <>
      <MuiLink
        component={Link}
        to={linkTo}
        sx={{ textDecoration: 'none' }}
        onClick={onClick}
      >
        <MenuItem
          sx={{
            pt: 3,
            pb: 3
          }}
        >
          <Box display='flex' alignItems='center'>
            <BrandLogo size={42} {...logoParams} />
            <Box
              sx={{
                ml: 2
              }}
            >
              <Text
                label={label}
                variant='h5'
                fontWeight='700'
                color='textPrimary'
                sx={{
                  lineHeight: '1.235',
                  display: 'inline-block',
                  whiteSpace: 'pre-line'
                }}
              />
              <Text
                label={formatToLongDate(date)}
                color='textSecondary'
                variant='h6'
              />
            </Box>
          </Box>
        </MenuItem>
      </MuiLink>
      <Divider sx={{ mt: 0, mb: 0 }} />
    </>
  )
}

export default memo(Notification)
