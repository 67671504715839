import { CUSTOM_BRAND_ID } from '../../constants/app'
import { ERROR_TEXT } from '../../constants/messages'
import { isExistsElementFromList } from '../app/app.helpers'
import { isBrandMM } from '../brand.helpers'
import { emailRule, formatValidator, requiredRule } from '../validation.helpers'
import {
  isValidEtihadCardNumber,
  isValidMileAndMoreNumber
} from './ffAccountValidation.helpers'

export const MIN_BRAND_ACCOUNT_NUMBER_LENGTH = 5

const EMAIL_TYPE_EXCHANGE_PROVIDERS = [
  CUSTOM_BRAND_ID.COINBASE,
  CUSTOM_BRAND_ID.BINANCE,
  CUSTOM_BRAND_ID.PAYPAL,
  CUSTOM_BRAND_ID.TROLLBEADS_MEMBERSHIP_CLUB,
  CUSTOM_BRAND_ID.XOXO_DAY,
  CUSTOM_BRAND_ID.GET_CHANGE
]

const MMAccountNumberRule = (number) => {
  return !isValidMileAndMoreNumber(number)
    ? formatValidator(ERROR_TEXT.INVALID_ACCOUNT_NUMBER)
    : ''
}

const EtihadAccountNumberRule = (number) => {
  return !isValidEtihadCardNumber(number)
    ? formatValidator(ERROR_TEXT.INVALID_ACCOUNT_NUMBER)
    : ''
}

const brandAccountNumberRule = (value) => {
  const valueLength = value.length
  return valueLength < MIN_BRAND_ACCOUNT_NUMBER_LENGTH
    ? formatValidator(ERROR_TEXT.INVALID_ACCOUNT_NUMBER)
    : ''
}

const isExchangeProviderEmailType = (brandId) => {
  return isExistsElementFromList(EMAIL_TYPE_EXCHANGE_PROVIDERS, brandId)
}

const validationRulesForAccountNumberSanity = (brandId) => {
  const rules = [requiredRule]
  if (isBrandMM(brandId)) {
    rules.push(MMAccountNumberRule)
  } else if (brandId === CUSTOM_BRAND_ID.ETIHAD) {
    rules.push(EtihadAccountNumberRule)
  } else if (isExchangeProviderEmailType(brandId)) {
    rules.push(emailRule)
  } else {
    rules.push(brandAccountNumberRule)
  }
  return rules
}

export { validationRulesForAccountNumberSanity }
