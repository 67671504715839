import React, { memo } from 'react'
import { FormControlLabel } from '@mui/material'

import CustomSwitch from '../forms/customElements/CustomSwitch'

const MonthlyYearlySwitch = ({ t, checked, onChange, disabledSwitch }) => {
  const handleChange = ({ target }) => {
    onChange(target.checked)
  }

  return (
    <>
      <FormControlLabel
        control={<></>}
        label={t('billing.monthly-payment')}
        sx={{ ml: 0 }}
      />
      <FormControlLabel
        control={
          <CustomSwitch
            checked={checked}
            color='primary'
            disabled={disabledSwitch}
            onChange={handleChange}
          />
        }
        label={t('billing.yearly-payment')}
        sx={{ mr: 0 }}
      />
    </>
  )
}

export default memo(MonthlyYearlySwitch)
