import React, { memo } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import { faCheck, faXmark } from '@fortawesome/pro-light-svg-icons'

import BaseCard from '../BaseCard/BaseCard'
import FaIcon from '../FaIcon/FaIcon'
import BrandCard from '../BrandCard/BrandCard'
import Text from '../text/Text'
import { CSS_TRUNCATE_TEXT, CSS_FLEX_COLUMN } from '../../../styles/common'
import CustomButton from '../buttons/CustomButton'
import CustomTooltip from '../CustomTooltip/CustomTooltip'

const EarnOptionCardActivation = ({
  id,
  title,
  description,
  activated,
  gridSize = 4,
  xlGridSize = 4,
  t,
  onToggleActivation,
  icon,
  iconColor = 'black',
  isProcessing,
  disabledMessage,
  additionalActions
}) => {
  const isDisabled = Boolean(disabledMessage)
  return (
    <Grid
      item
      sm={6}
      md={gridSize}
      lg={gridSize}
      xl={xlGridSize}
      sx={{ width: '100%' }}
    >
      <BaseCard sx={{ p: 0, ...CSS_FLEX_COLUMN }}>
        <BrandCard
          brandIcon={icon && <FaIcon icon={icon} />}
          hideDefaultBg
          iconColor={iconColor}
          plainBg
        />
        <Grid container spacing={1} p={2} pb={0}>
          <Grid item xs={12}>
            <Text
              label={title}
              variant='h5'
              fontWeight={700}
              sx={CSS_TRUNCATE_TEXT}
            />
            {description && (
              <Text
                label={description}
                variant='caption'
                component='div'
                sx={{
                  lineHeight: '17px',
                  mb: 0.7
                }}
              />
            )}
          </Grid>
        </Grid>
        <Box flexGrow={1} />
        <Stack
          spacing={1}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ alignItems: 'center', m: 2, justifyContent: 'end' }}
        >
          {additionalActions}
          <Box sx={{ flex: '1 1 auto' }} />
          <CustomTooltip
            label={disabledMessage}
            showIfLength={isDisabled && undefined}
          >
            <CustomButton
              label={t(`extensions.${activated ? 'deactivate' : 'activate'}`)}
              color={activated ? 'error' : 'primary'}
              variant={activated ? 'outlined' : 'contained'}
              fullWidth={false}
              onClick={() => onToggleActivation(id, activated)}
              startIcon={<FaIcon icon={activated ? faXmark : faCheck} />}
              disabled={isProcessing || isDisabled}
            />
          </CustomTooltip>
        </Stack>
      </BaseCard>
    </Grid>
  )
}

export default memo(EarnOptionCardActivation)
