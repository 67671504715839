import React, { memo } from 'react'
import { FormHelperText } from '@mui/material'

import { buildCustomHelperTextStyles } from '../../../util/style.helpers'

const InputHelperText = ({ bottomLabel, errorMessage, successMsg }) => {
  const error = Boolean(errorMessage)
  const message = errorMessage || successMsg || bottomLabel
  if (!message) {
    return null
  }
  return (
    <FormHelperText error={error} {...buildCustomHelperTextStyles(successMsg)}>
      {message}
    </FormHelperText>
  )
}

export default memo(InputHelperText)
