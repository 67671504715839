import { mergeToNewObject } from '../../../util/object.helpers'
import {
  ANALYTICS_FILTER_ACTION,
  ANALYTICS_POINTS_BANK_ACTION,
  ANALYTICS_POINTS_EXCHANGE_ACTION
} from '../../../constants/actions'
import {
  buildTransactionsRateData,
  buildTransactionsCountData,
  buildTransactionsValueData,
  buildTransferVolumeData,
  formatExchangePartners
} from '../../../util/analytics/pointsExchange.helpers'
import { buildIsProcessingState } from '../../../util/app/app.helpers'

export const pointsExchangeProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case ANALYTICS_POINTS_EXCHANGE_ACTION.ON_RESET_STATE:
      return INITIAL_STATE

    case ANALYTICS_FILTER_ACTION.ON_POINTS_EXCHANGE_TYPE_CHANGE:
      return mergeToNewObject(state, handleFilterChange(payload, state))

    case ANALYTICS_POINTS_EXCHANGE_ACTION.ON_GET_EXCHANGE_MEMBERS_SUCCESS:
      return mergeToNewObject(
        state,
        handleExchangeMembers(payload),
        buildIsProcessingState(POINTS_EXCHANGE_DISPATCH.MEMBERS, false, state)
      )

    case ANALYTICS_POINTS_EXCHANGE_ACTION.ON_GET_EXCHANGE_MEMBERS:
      return mergeToNewObject(
        state,
        buildIsProcessingState(POINTS_EXCHANGE_DISPATCH.MEMBERS, true, state)
      )

    case ANALYTICS_POINTS_EXCHANGE_ACTION.ON_GET_EXCHANGE_MEMBERS_FAILED:
      return mergeToNewObject(
        state,
        buildIsProcessingState(POINTS_EXCHANGE_DISPATCH.MEMBERS, false, state)
      )

    case ANALYTICS_POINTS_BANK_ACTION.ON_GET_TOKEN_STATS_SUCCESS:
      return mergeToNewObject(state, handleTokenStatsData(payload))

    case ANALYTICS_POINTS_EXCHANGE_ACTION.ON_GET_TOP_EXCHANGE_PARTNERS_SUCCESS:
      return mergeToNewObject(state, handleExchangePartnersData(payload))

    default:
      return state
  }
}

export const POINTS_EXCHANGE_DISPATCH = {
  MEMBERS: 'members'
}

const INITIAL_STATE = {
  tokenStats: {
    exchangeInTxVolume: 0,
    exchangeOutTxVolume: 0,
    exchangeInTxCount: 0,
    exchangeOutTxCount: 0
  },
  members: {
    buy: 0,
    sell: 0
  },
  transferVolume: {
    data: []
  },
  transactionsCount: {
    data: []
  },
  transactionsRate: {
    data: []
  },
  transactionsValue: {
    data: []
  },
  topExchangePartners: {
    data: [],
    filteredData: []
  },
  isProcessing: {
    [POINTS_EXCHANGE_DISPATCH.MEMBERS]: false
  }
}

const handleExchangeMembers = ({ exchangedInMembers, exchangedOutMembers }) => {
  return {
    members: {
      buy: exchangedOutMembers,
      sell: exchangedInMembers
    }
  }
}

const handleTokenStatsData = ({
  tokenStats: {
    exchangeInTxCount,
    exchangeOutTxCount,
    exchangeInTxVolume,
    exchangeOutTxVolume
  },
  tokenProfile,
  analyticsFilter
}) => {
  const tokenStats = {
    exchangeInTxVolume,
    exchangeOutTxVolume,
    exchangeInTxCount,
    exchangeOutTxCount
  }
  return {
    tokenStats,
    ...handleFilterChange({ tokenProfile, analyticsFilter }, { tokenStats })
  }
}

const handleFilterChange = (
  { tokenProfile, analyticsFilter: { exchangeType } },
  { tokenStats, topExchangePartners }
) => {
  const {
    exchangeInTxVolume,
    exchangeOutTxVolume,
    exchangeInTxCount,
    exchangeOutTxCount
  } = tokenStats
  return {
    transferVolume: buildTransferVolumeData(
      { exchangeInTxVolume, exchangeOutTxVolume },
      exchangeType
    ),
    transactionsCount: buildTransactionsCountData(
      {
        exchangeInTxCount,
        exchangeOutTxCount
      },
      exchangeType
    ),
    transactionsRate: buildTransactionsRateData(tokenStats, exchangeType),
    transactionsValue: buildTransactionsValueData(
      { exchangeInTxVolume, exchangeOutTxVolume },
      tokenProfile,
      exchangeType
    ),
    // @TODO Need to handle on change top reward partners
    ...(topExchangePartners && {
      topExchangePartners: {
        ...topExchangePartners,
        filteredData: formatExchangePartners(
          topExchangePartners.data,
          exchangeType
        )
      }
    })
  }
}

const handleExchangePartnersData = ({ data, analyticsFilter }) => {
  return {
    topExchangePartners: {
      data,
      filteredData: formatExchangePartners(data, analyticsFilter.exchangeType)
    }
  }
}
