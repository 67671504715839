import React from 'react'
import { Route, Switch } from 'react-router-dom'

import SignupContainer from './containers/SignupContainer'
import LoginContainer from './containers/LoginContainer'
import ForgotPasswordContainer from './containers/ForgotPasswordContainer'
import ResetPasswordContainer from './containers/ResetPasswordContainer'
import HomeContainer from './containers/HomeContainer'
import PageRedirectContainer from './containers/PageRedirectContainer'

import {
  SIGN_UP_URL,
  LOG_IN_URL,
  FORGOT_PASSWORD_URL,
  RESET_PASSWORD_URL,
  HOME_URL,
  REDIRECT_URL
} from './constants/navigation'

const AppRoutes = () => (
  <Switch>
    <Route path={`${REDIRECT_URL}/:path?`} component={PageRedirectContainer} />
    <Route path={SIGN_UP_URL} exact component={SignupContainer} />
    <Route path={LOG_IN_URL} exact component={LoginContainer} />
    <Route
      path={FORGOT_PASSWORD_URL}
      exact
      component={ForgotPasswordContainer}
    />
    <Route path={RESET_PASSWORD_URL} exact component={ResetPasswordContainer} />
    <Route path={HOME_URL} component={HomeContainer} />
  </Switch>
)

export default AppRoutes
