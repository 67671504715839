import React, { memo, useState } from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import {
  Box,
  Drawer,
  useMediaQuery,
  List,
  ListItem,
  Collapse,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import { faChevronDown, faChevronRight } from '@fortawesome/pro-light-svg-icons'

import { sidebarWidth, testingAlertHeight } from '../../global/Theme-variable'
import { buildSidebarLinks } from './sidebarLinks'
import LogoIcon from '../logo/LogoIcon'
import Scrollbar from '../../shared/scrollbar/Scrollbar'
import FaIcon from '../../shared/FaIcon/FaIcon'
import BrandInformation from './BrandInformation'
import Text from '../../shared/text/Text'
import { matchPartialUrl } from '../../../util/auth.helpers'
import { isProduction } from '../../../util/app/app.helpers'
import {
  CSS_FLEX_ALIGNED,
  CSS_FLEX_CENTERED,
  CSS_FLEX_COLUMN
} from '../../../styles/common'
import { getTopHeight } from '../../../util/style.helpers'
import ShowCircleGuidance from '../../shared/Chip/ShowCircleGuidance'
import { buildLinkParams } from '../../../util/navigation.helpers'

const Sidebar = ({
  t,
  isMobileSidebarOpen,
  onSidebarClose,
  isSidebarOpen,
  isNewBrandCreation,
  isShowGuidanceForExchange,
  exchangeTxCount,
  isAPIKeyCreated,
  ...restProps
}) => {
  const [open, setOpen] = useState(true)
  const { pathname } = useLocation()
  const pathDirect = pathname
  const pathWithoutLastPart = pathname.slice(0, pathname.lastIndexOf('/'))
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const isProductionEnv = isProduction()
  const handleClick = (index) => {
    if (open === index) {
      setOpen((prevOpen) => !prevOpen)
    } else {
      setOpen(index)
    }
  }

  const SidebarContent = ({ dashboardSetupState, ...restProps }) => (
    <Scrollbar
      style={{
        height: `calc(100vh - ${getTopHeight(5)}px)`
      }}
    >
      <Box
        sx={{
          height: '100%',
          p: 2,
          ...CSS_FLEX_COLUMN,
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <LogoIcon />
          <BrandInformation {...restProps} t={t} />
          <Box>
            <List>
              {buildSidebarLinks({
                dashboardSetupState,
                isNewBrandCreation,
                isShowGuidanceForExchange,
                exchangeTxCount,
                isAPIKeyCreated
              }).map((item, index) => {
                // {/********subHeader**********/}
                if (item.subHeader) {
                  return (
                    <li key={item.subHeader}>
                      <Text
                        variant='subtitle2'
                        fontWeight={500}
                        sx={{ my: 2, mt: 2, opacity: '0.4' }}
                        label={t(item.subHeader)}
                      />
                    </li>
                  )
                  // {/********If Sub Menu**********/}
                  /* eslint no-else-return: "off" */
                } else if (item.children) {
                  return (
                    <React.Fragment key={item.title}>
                      <ListItem
                        button
                        component='li'
                        onClick={() => handleClick(index)}
                        selected={pathWithoutLastPart === item.href}
                        sx={{
                          mb: 1,
                          ...(pathWithoutLastPart === item.href && {
                            color: 'white',
                            backgroundColor: (theme) =>
                              `${theme.palette.primary.main}!important`
                          })
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ...(pathWithoutLastPart === item.href && {
                              color: 'white'
                            })
                          }}
                        >
                          <FaIcon icon={item.icon} />
                        </ListItemIcon>
                        <ListItemText>{t(item.title)}</ListItemText>
                        {index === open || pathWithoutLastPart === item.href ? (
                          <FaIcon icon={faChevronDown} />
                        ) : (
                          <FaIcon icon={faChevronRight} />
                        )}
                      </ListItem>
                      <Collapse
                        in={index === open}
                        timeout='auto'
                        unmountOnExit
                      >
                        <List component='li' disablePadding>
                          {item.children.map((child) => {
                            return (
                              <ListItem
                                key={child.title}
                                button
                                component={NavLink}
                                to={child.href}
                                onClick={onSidebarClose}
                                selected={pathDirect === child.href}
                                sx={{
                                  mb: 1,
                                  ...(pathDirect === child.href && {
                                    color: 'primary.main',
                                    backgroundColor: 'transparent!important'
                                  })
                                }}
                              >
                                <ListItemIcon
                                  sx={{
                                    svg: { width: '14px', marginLeft: '3px' },
                                    ...(pathDirect === child.href && {
                                      color: 'primary.main'
                                    })
                                  }}
                                >
                                  <FaIcon icon={child.icon} />
                                </ListItemIcon>
                                <ListItemText>{t(child.title)}</ListItemText>
                              </ListItem>
                            )
                          })}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  )
                  // {/********If Sub No Menu**********/}
                } else {
                  const isPathSelected = matchPartialUrl(
                    pathDirect,
                    item.href,
                    item.isOutside
                  )
                  return (
                    <List
                      component='li'
                      disablePadding
                      key={item.title}
                      id={item.id}
                    >
                      <ListItem
                        {...buildLinkParams(item)}
                        onClick={() => handleClick(index)}
                        button
                        selected={isPathSelected}
                        sx={{
                          mb: 1,
                          ...(isPathSelected && {
                            color: 'white',
                            backgroundColor: (theme) =>
                              `${theme.palette.primary.main}!important`
                          })
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ...(isPathSelected && { color: 'white' })
                          }}
                        >
                          <FaIcon icon={item.icon} />
                        </ListItemIcon>
                        <ListItemText
                          onClick={onSidebarClose}
                          primaryTypographyProps={CSS_FLEX_ALIGNED}
                        >
                          <ShowCircleGuidance
                            show={item.isShowGuidance}
                            invert={isPathSelected}
                          >
                            {t(item.title)}
                          </ShowCircleGuidance>
                        </ListItemText>
                      </ListItem>
                    </List>
                  )
                }
              })}
            </List>
          </Box>
        </Box>
        <Box sx={CSS_FLEX_CENTERED}>
          <Text
            label={<>Copyright &copy; {new Date().getFullYear()} qiibee ag</>}
            variant='caption'
          />
        </Box>
      </Box>
    </Scrollbar>
  )
  const marginTop = !isProductionEnv && `${testingAlertHeight}px`
  if (lgUp) {
    return (
      <Drawer
        anchor='left'
        open={isSidebarOpen}
        variant='persistent'
        PaperProps={{
          sx: {
            marginTop,
            width: sidebarWidth,
            border: '0 !important',
            boxShadow: '0px 7px 30px 0px rgba(0, 0, 0, 0.11)'
          }
        }}
      >
        <SidebarContent {...restProps} />
      </Drawer>
    )
  }
  return (
    <Drawer
      anchor='left'
      open={isMobileSidebarOpen}
      onClose={onSidebarClose}
      PaperProps={{
        sx: {
          marginTop,
          width: sidebarWidth,
          border: '0 !important'
        }
      }}
      variant='temporary'
    >
      <SidebarContent {...restProps} />
    </Drawer>
  )
}

export default memo(Sidebar)
