import { map, mergeMap, catchError, tap, filter } from 'rxjs/operators'
import { forkJoin, of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'

import { QB_DEFAULT_COUNTRY_VALUE } from '../../constants/app'
import { MAIN_PROFILE_ACTION, TOKEN_ACTIONS } from '../../constants/actions'
import apiService from '../../services/api'
import { wrapUserAccessToken } from '../../util/auth.helpers'
import {
  extractBrandId,
  extractMainProfile,
  extractSubscribedPackage,
  extractTokenId,
  extractTokenInfo,
  formatTokens
} from '../../util/epics.helpers'
import { parseToken, commonParser } from '../../util/apiParser.helpers'
import {
  extractEstimatedMemberRange,
  formatTokenStats,
  getSelectedToken,
  isTokenActive,
  isTokenRejected,
  sortTokensByStatus,
  tokenMetaDescription
} from '../../util/token.helpers'
import {
  extractAllRegions,
  getCountriesIsoArray
} from '../../util/country.helpers'
import { logger } from '../../util/log.helpers'
import { trim } from '../../util/string.helpers'
import { setBrandAndTokenIds } from '../../util/local.helpers'
import { isObjectNotEmpty } from '../../util/object.helpers'
import {
  buildDefaultParamsWithFilters,
  getLastCurrentWeekDateRanges
} from '../../util/transaction/transaction.helpers'
import { buildDateRangeQueryParams } from '../../util/analytics/analytics.helpers'
import { ALL_TOKENS_EXCEPT_NFT } from '../../constants/token'

const handleCreateTokenEpic = (action$, state$) =>
  action$.pipe(
    ofType(TOKEN_ACTIONS.ON_CREATE_TOKEN),
    map(() => ({
      brandId: extractBrandId(state$),
      prevToken: extractTokenInfo(state$)
    })),
    mergeMap(({ brandId, prevToken }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .createToken(brandId, extractTokenCreationData(state$), accessToken)
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => {
              const token = parseToken(response.data)
              setBrandAndTokenIds({ brandId, tokenId: token.id })
              return {
                type: TOKEN_ACTIONS.ON_CREATE_TOKEN_SUCCESS,
                payload: {
                  token,
                  prevToken,
                  isPaidSubscription:
                    isTokenRejected(prevToken?.status) &&
                    extractSubscribedPackage(state$)?.id
                }
              }
            }),
            catchError((error) =>
              of({
                type: TOKEN_ACTIONS.ON_CREATE_TOKEN_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

const handleUpdateTokenEpic = (action$, state$) =>
  action$.pipe(
    ofType(TOKEN_ACTIONS.ON_UPDATE_TOKEN),
    mergeMap(({ payload }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .updateToken(
            extractBrandId(state$),
            extractTokenId(state$),
            {
              token: isObjectNotEmpty(payload)
                ? payload
                : extractTokenUpdateData(state$)
            },
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: TOKEN_ACTIONS.ON_UPDATE_TOKEN_SUCCESS,
              payload: parseToken(response.data)
            })),
            catchError((error) =>
              of({
                type: TOKEN_ACTIONS.ON_UPDATE_TOKEN_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

const handleGetTokensEpic = (action$, state$) =>
  action$.pipe(
    ofType(TOKEN_ACTIONS.ON_GET_TOKENS),
    map(({ payload: { fetchDetails } }) => ({
      fetchDetails,
      brandId: extractBrandId(state$),
      tokenId: extractTokenId(state$)
    })),
    filter(({ brandId }) => brandId),
    mergeMap(({ fetchDetails, brandId, tokenId }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getTokens(brandId, accessToken, { types: ALL_TOKENS_EXCEPT_NFT })
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => {
              const tokens = formatTokens(response.data)
              const currentToken = getSelectedToken(
                sortTokensByStatus(tokens),
                tokenId
              )
              const { packageSubscriptions } = extractMainProfile(state$)
              return {
                type: TOKEN_ACTIONS.ON_GET_TOKENS_SUCCESS,
                payload: {
                  packageSubscriptions,
                  currentToken,
                  tokens,
                  fetchDetails,
                  brandId,
                  tokenId
                }
              }
            }),
            catchError(() =>
              of({
                type: TOKEN_ACTIONS.ON_GET_TOKENS_FAILED
              })
            )
          )
      )
    )
  )

const handleGetTokenByIdEpic = (action$, state$) =>
  action$.pipe(
    ofType(TOKEN_ACTIONS.ON_GET_TOKEN_BY_ID),
    map(({ payload: { tokenId, ...restAttrs } }) => ({
      tokenId,
      brandId: extractBrandId(state$),
      ...restAttrs
    })),
    filter(({ tokenId, brandId }) => tokenId && brandId),
    mergeMap(({ tokenId, brandId, ...restAttrs }) =>
      wrapUserAccessToken((accessToken) =>
        apiService.getTokenById(brandId, tokenId, accessToken).pipe(
          tap((data) => logger(data)),
          map(({ response }) => ({
            type: TOKEN_ACTIONS.ON_GET_TOKEN_BY_ID_SUCCESS,
            payload: {
              token: parseToken(response.data),
              brandId,
              ...restAttrs
            }
          })),
          catchError(() =>
            of({
              type: TOKEN_ACTIONS.ON_GET_TOKEN_BY_ID_FAILED
            })
          )
        )
      )
    )
  )

// TOKEN STATS
const handleGetTokenStatsEpic = (action$) =>
  action$.pipe(
    ofType(MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS),
    filter(({ payload: { currentToken } }) =>
      isTokenActive(currentToken?.status)
    ),
    mergeMap(({ payload: { currentToken } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getTokenStats(
            {
              tokenId: currentToken.id
            },
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: TOKEN_ACTIONS.ON_GET_TOKEN_STATS_SUCCESS,
              payload: commonParser(response.data)
            })),
            catchError(() =>
              of({
                type: TOKEN_ACTIONS.ON_GET_TOKEN_STATS_FAILED
              })
            )
          )
      )
    )
  )

// TOKEN STATS FOR LAST WEEK AND THIS WEEK
const handleGetTokenStatsForLastAndThisWeekEpic = (action$, state$) =>
  action$.pipe(
    ofType(TOKEN_ACTIONS.ON_GET_TOKEN_STATS_FOR_LAST_AND_CURRENT_WEEK),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        getLastAndCurrentWeekTokenStats(state$, accessToken).pipe(
          map((response) => {
            return {
              type: TOKEN_ACTIONS.ON_GET_TOKEN_STATS_FOR_LAST_AND_CURRENT_WEEK_SUCCESS,
              payload: {
                tokenStats: formatTokenStats(response),
                tokenProfile: extractTokenInfo(state$)
              }
            }
          }),
          catchError((err) =>
            of({
              type: TOKEN_ACTIONS.ON_GET_TOKEN_STATS_FOR_LAST_AND_CURRENT_WEEK_FAILED,
              payload: err
            })
          )
        )
      )
    )
  )

const getLastAndCurrentWeekTokenStats = (state, accessToken) => {
  return forkJoin(
    getLastCurrentWeekDateRanges().map(({ filterBy, ...dateRange }) => {
      const { tokenId, exclude_token_to_types } =
        buildDefaultParamsWithFilters(state)
      return apiService
        .getTokenStats(
          {
            ...buildDateRangeQueryParams(dateRange),
            tokenId,
            exclude_token_to_types
          },
          accessToken
        )
        .pipe(
          map(({ response }) => ({
            tokenStat: commonParser(response.data),
            filterBy
          }))
        )
    })
  )
}

const handleGetTokenDetails = (action$) =>
  action$.pipe(
    ofType(TOKEN_ACTIONS.ON_GET_TOKENS_SUCCESS),
    filter(
      ({ payload: { tokens, fetchDetails } }) =>
        fetchDetails && tokens.length > 0
    ),
    map(({ payload: { currentToken } }) => {
      return { currentToken }
    }),
    filter(({ currentToken }) => Boolean(currentToken)),
    map(({ currentToken }) => {
      const tokenId = currentToken?.id
      return {
        type: TOKEN_ACTIONS.ON_GET_TOKEN_BY_ID,
        payload: { tokenId }
      }
    })
  )

export const tokenEpic = combineEpics(
  handleCreateTokenEpic,
  handleUpdateTokenEpic,
  handleGetTokensEpic,
  handleGetTokenByIdEpic,
  handleGetTokenStatsEpic,
  handleGetTokenDetails,
  handleGetTokenStatsForLastAndThisWeekEpic
)

const extractTokenCreationData = (state) => {
  const { tokenProfile } = state.value.tokensReducer
  const { symbolTemp, nameTemp, pointValueTemp, ...restAttributes } =
    tokenProfile
  const onramp = Number(pointValueTemp) || 0
  return {
    token: {
      name: trim(nameTemp),
      symbol: trim(symbolTemp),
      onramp,
      offramp: onramp,
      ...buildTempParams(restAttributes)
    }
  }
}

const extractTokenUpdateData = (state) => {
  const { tokenProfile } = state.value.tokensReducer
  return buildTempParams(tokenProfile)
}

const buildTempParams = ({
  regionsTemp,
  countriesTemp,
  descriptionTemp,
  amountOfMembersTemp,
  binanceMirrorTemp
}) => {
  const [estimatedMemberCountLower, estimatedMemberCountHigher] =
    extractEstimatedMemberRange(amountOfMembersTemp)
  const allCountriesSelected =
    regionsTemp.length === extractAllRegions().length &&
    countriesTemp.length === getCountriesIsoArray().length
  return {
    countries: allCountriesSelected
      ? [QB_DEFAULT_COUNTRY_VALUE]
      : countriesTemp,
    metadata: tokenMetaDescription(descriptionTemp),
    estimated_member_count_lower: estimatedMemberCountLower,
    estimated_member_count_higher: estimatedMemberCountHigher,
    binance_mirror: binanceMirrorTemp
  }
}
