import UAParser from 'ua-parser-js'

import {
  API_PROGRESS_STATE,
  HTML_DOCTYPE,
  MAX_EXCHANGE_AMOUNT_IN_USD,
  QB_PRODUCTION,
  QB_SANDBOX,
  QB_TESTING
} from '../../constants/app'

const packageJson = require('../../../package.json')

const getAppVersion = () => packageJson.version

const getFileExtension = (file) => {
  return file && file.name.split('.').pop().toLowerCase()
}

const copyToClipboard = (data) => {
  const apiKeySelection = document.createElement('textarea')
  apiKeySelection.value = data
  document.body?.appendChild(apiKeySelection)
  apiKeySelection.select()
  document.execCommand('copy')
  document.body?.removeChild(apiKeySelection)
}

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

const sumOfArrayElements = (elements) => {
  if (elements && elements.length > 0) {
    return elements.reduce((a, b) => a + b, 0)
  } else {
    return 0
  }
}

const parseJson = (input) => {
  return input ? JSON.parse(input) : {}
}

const stringifyJson = (input) => {
  return input ? JSON.stringify(input) : ''
}

const sortOptionsByASC = (options) => {
  return options?.sort((a, b) => a.label.localeCompare(b.label))
}

const sortLabelsByASC = (labels) => {
  return labels?.sort()
}

export const prependHttpsIfNotExists = (link) => {
  return link && link.indexOf('://') === -1 ? `https://${link}` : link
}

const isExistsElementFromList = (list, element) => {
  return list?.includes(element)
}

const hotReloadApp = () => {
  window.location.reload(true)
}

const openExternalUrl = (url, isSelf = false) => {
  window.open(
    isSelf ? url : prependHttpsIfNotExists(url),
    isSelf ? '_self' : '_blank'
  )
}

const openBlankRedirectPage = (url) => {
  window.open(url, '_blank')
}

const isProduction = () => {
  return process.env.REACT_APP_ENV === QB_PRODUCTION
}

const isTesting = () => {
  return process.env.REACT_APP_ENV === QB_TESTING
}

const isSandbox = () => {
  return process.env.REACT_APP_ENV === QB_SANDBOX
}

const getEnvModeLabelColor = () => {
  if (isSandbox()) {
    return { label: 'sidebar.sandbox', color: 'warning' }
  }
  return {
    label: 'common.testing',
    color: 'info'
  }
}

const buildEmailLinkParams = (subject, body) => {
  let params = subject || body ? '?' : ''
  if (subject) params += `subject=${encodeURIComponent(subject)}`
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`
  return params
}

const isAPICompleted = (state) => {
  return state === API_PROGRESS_STATE.COMPLETED
}

const isAPIInProgress = (state) => {
  return state === API_PROGRESS_STATE.IN_PROGRESS
}

const buildDropdownList = (dataKey, label, t) => {
  return Object.keys(dataKey).map((key) => ({
    value: dataKey[key],
    label: t(`${label}.${dataKey[key]}`)
  }))
}

const buildIsProcessingState = (key, value, state) => {
  return {
    isProcessing: {
      ...state.isProcessing,
      [key]: value
    }
  }
}

const formatUserDeviceDetails = () => {
  return {
    app_version: `${getAppVersion()}`,
    browser_details: new UAParser().getResult()
  }
}

const isValidHtmlContent = (text) => {
  return text.toLowerCase().includes(HTML_DOCTYPE)
}

const maxExchangeValue = (value) => {
  return value || MAX_EXCHANGE_AMOUNT_IN_USD
}

export {
  getAppVersion,
  getFileExtension,
  copyToClipboard,
  scrollToTop,
  sumOfArrayElements,
  parseJson,
  stringifyJson,
  sortOptionsByASC,
  sortLabelsByASC,
  isExistsElementFromList,
  hotReloadApp,
  openExternalUrl,
  openBlankRedirectPage,
  isProduction,
  isTesting,
  isSandbox,
  buildEmailLinkParams,
  getEnvModeLabelColor,
  isAPICompleted,
  isAPIInProgress,
  buildDropdownList,
  buildIsProcessingState,
  formatUserDeviceDetails,
  isValidHtmlContent,
  maxExchangeValue
}
