import React, { memo } from 'react'
import { Grid } from '@mui/material'

import { POINT_VALUE_MIN_LENGTH } from '../../constants/validation'
import { TOKEN_PROFILE_DISPATCH } from '../../store/reducers/tokens/token'
import Text from '../shared/text/Text'
import {
  transWithArgs,
  validPointValueRule
} from '../../util/validation.helpers'
import PointValueField from './PointValueField'
import DefaultText from '../shared/text/DefaultText'
import TextWithIntegrationGuide from '../shared/text/TextWithIntegrationGuide'

const LoyaltyPointEconomicsFields = ({
  pointValue,
  error,
  dispatchAction,
  isUpdatePoint,
  t
}) => {
  return (
    <>
      <Text
        variant='h2'
        label={t('token-setup.point-economics')}
        fontWeight={500}
      />
      <DefaultText
        label={
          <TextWithIntegrationGuide i18nKey='token-setup.important-note' />
        }
      />
      <Text
        variant='body1'
        color='textprimary'
        label={t('token-setup.value-estimated-desc')}
      />

      <Grid container>
        <Grid item xs={12} lg={6}>
          <PointValueField
            id={TOKEN_PROFILE_DISPATCH.POINT_VALUE_TEMP}
            value={pointValue}
            errorMessage={transWithArgs(
              t,
              error?.[TOKEN_PROFILE_DISPATCH.POINT_VALUE_TEMP]
            )}
            validate={[validPointValueRule]}
            onChange={(value) =>
              dispatchAction(TOKEN_PROFILE_DISPATCH.POINT_VALUE_TEMP, value)
            }
            inputProps={{
              maxLength: POINT_VALUE_MIN_LENGTH
            }}
            disabled={isUpdatePoint}
            t={t}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default memo(LoyaltyPointEconomicsFields)
