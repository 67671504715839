import React, { memo } from 'react'

import CustomTooltip from './CustomTooltip'

const ExchangeDisableTooltip = ({ t, children, disabled }) => {
  return disabled ? (
    <CustomTooltip
      label={t('exchange.exchange-partnership-disable-remove-desc')}
      parentProps={{
        sx: { width: '100%' }
      }}
    >
      {children}
    </CustomTooltip>
  ) : (
    children
  )
}

export default memo(ExchangeDisableTooltip)
