import { CUSTOM_BRAND_ID } from './app'
import { COMMON_TOKEN_TYPE } from './token'

const EXCHANGE_PARTNER_FILTER_OPTIONS = {
  ALL: 'all',
  POINTS: 'points',
  ...COMMON_TOKEN_TYPE,
  NFT: 'nft'
}

const EXCHANGE_PARTNER_TYPES = {
  POTENTIAL: 'potential',
  ACTIVE: 'active',
  REQUESTS: 'requests',
  BRANDS_YOU_LOVE: 'brands-you-love',
  SETTINGS: 'settings'
}

const EXCHANGE_CONNECTION_TYPES = {
  ALL: 'all',
  YOU_CONNECTED_WITH: 'you-connected-with',
  CONNECTED_TO_YOU: 'connected-to-you'
}

const EXCHANGE_REQUEST_TYPES = {
  ALL: 'all',
  REQUESTS_YOU_SENT: 'requests-you-sent',
  REQUESTS_YOU_RECEIVED: 'requests-you-received'
}

const EXCHANGE_TAB_CONTENT_ID = {
  POTENTIAL: 0,
  ACTIVE: 1,
  REQUESTS: 2,
  BRANDS_YOU_LOVE: 3,
  SETTINGS: 4
}

const EXCHANGE_FEEDBACK_OTHER = 'Other'

const EXCHANGE_FEEDBACK_FROM_OPTIONS = {
  0: 'Not enough of my members exchange into this partners loyalty currency',
  1: 'Too many of my members exchange into this partners loyalty currency',
  2: 'My members should get more of this partners loyalty currency whenever they exchange my loyalty currency into theirs',
  3: 'My members should get less of this partners loyalty currency whenever they exchange my loyalty currency into theirs',
  4: 'The partnership was only for a campaign which ended now',
  5: EXCHANGE_FEEDBACK_OTHER
}

const EXCHANGE_FEEDBACK_TO_OPTIONS = {
  0: 'Not enough of this partners members exchange into my loyalty currency',
  1: 'Too many of this partners members exchange into my loyalty currency',
  2: 'The partnership was only for a campaign which ended now',
  3: EXCHANGE_FEEDBACK_OTHER
}

const PARTNER_DOES_NOT_FIT_TO_MY_BRAND = 'The partner does not fit to my brand'
const PARTNER_TIMING_NOT_FIT =
  'The partner fits to my brand but the timing not. I want to activate the partnership later on'

const REJECT_PARTNER_FEEDBACK_OPTIONS = {
  0: PARTNER_DOES_NOT_FIT_TO_MY_BRAND,
  1: PARTNER_TIMING_NOT_FIT,
  2: 'I fear that not enough of this partners members exchange into my loyalty currency',
  3: 'I fear that too many of this partners members exchange into my loyalty currency',
  4: EXCHANGE_FEEDBACK_OTHER
}

const CANCEL_PARTNER_FEEDBACK_OPTIONS = {
  0: PARTNER_DOES_NOT_FIT_TO_MY_BRAND,
  1: PARTNER_TIMING_NOT_FIT,
  2: 'I fear that not enough of my members exchange into this partners loyalty currency',
  3: 'I fear that too many of my members exchange into this partners loyalty currency',
  4: 'Exchange rate: My members should get more of this partners loyalty currency whenever they exchange my loyalty currency into theirs',
  5: 'Exchange rate: My members should get less of this partners loyalty currency whenever they exchange my loyalty currency into theirs',
  6: EXCHANGE_FEEDBACK_OTHER
}

const EXCHANGE_CONNECTION_ACTION = {
  REMOVE_FROM: 'removeFrom',
  REMOVE_TO: 'removeTo',
  ACCEPT: 'accept',
  REJECT: 'reject',
  CANCEL: 'cancel',
  SENT_REQUEST: 'sentRequest',
  ERROR_REJECTED: 'errorRejected',
  ERROR_DISCONNECTED: 'errorDisconnected',
  CHANGE_MAX_LIMIT: 'changeMaxLimit'
}

const EXCHANGE_WHITELIST_STATUS = {
  ACCEPT: 'accept',
  REJECT: 'reject',
  CANCEL: 'cancel',
  STOP: 'stop'
}

const EXCHANGE_PROVIDERS_BRAND_IDS_IGNORE = [
  CUSTOM_BRAND_ID.QIIBEE_CONFIG_BRAND,
  CUSTOM_BRAND_ID.TROLLBEADS_MEMBERSHIP_CLUB,
  CUSTOM_BRAND_ID.ETH_ADDRESS
]

const EXCHANGE_DETAILS_TAB_TYPE = {
  REWARD_DETAILS: 'reward-details',
  REWARD_CONDITIONS: 'reward-conditions'
}

const EXCHANGE_DETAILS_TAB_CONTENT_ID = {
  REWARD_DETAILS: 0,
  REWARD_CONDITIONS: 1
}

export {
  EXCHANGE_PARTNER_FILTER_OPTIONS,
  EXCHANGE_PARTNER_TYPES,
  EXCHANGE_TAB_CONTENT_ID,
  EXCHANGE_FEEDBACK_OTHER,
  EXCHANGE_FEEDBACK_FROM_OPTIONS,
  EXCHANGE_FEEDBACK_TO_OPTIONS,
  EXCHANGE_CONNECTION_ACTION,
  REJECT_PARTNER_FEEDBACK_OPTIONS,
  CANCEL_PARTNER_FEEDBACK_OPTIONS,
  EXCHANGE_WHITELIST_STATUS,
  EXCHANGE_PROVIDERS_BRAND_IDS_IGNORE,
  EXCHANGE_CONNECTION_TYPES,
  EXCHANGE_REQUEST_TYPES,
  EXCHANGE_DETAILS_TAB_TYPE,
  EXCHANGE_DETAILS_TAB_CONTENT_ID
}
