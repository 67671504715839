import {
  EXTENSIONS_ACTION,
  LOVE_BRANDS_FILTERS_ACTION
} from '../../constants/actions'

const changeToolsFilterState = (value) => {
  return {
    type: EXTENSIONS_ACTION.CHANGE_TOOL_FILTER_STATE,
    payload: {
      value
    }
  }
}

const changeExtensionState = (property, value) => ({
  type: EXTENSIONS_ACTION.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

const changeLoveBrandFiltersState = (property, value) => ({
  type: LOVE_BRANDS_FILTERS_ACTION.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

export {
  changeToolsFilterState,
  changeExtensionState,
  changeLoveBrandFiltersState
}
