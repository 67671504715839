import React, { memo } from 'react'
import { TableCell } from '@mui/material'

import Text from '../text/Text'

const TableCellData = ({
  children,
  textProps,
  noTag,
  wordBreak,
  sx,
  ...restProps
}) => {
  return (
    <TableCell {...restProps} sx={{ pl: '8px', pr: '8px', ...sx }}>
      {!noTag ? (
        <Text
          variant='h6'
          label={children}
          sx={{
            lineHeight: 1.4,
            wordBreak: wordBreak ? 'break-word' : 'keep-all'
          }}
          {...textProps}
        />
      ) : (
        children
      )}
    </TableCell>
  )
}

TableCellData.defaultProps = {
  color: 'textSecondary',
  noTag: false,
  wordBreak: true
}

export default memo(TableCellData)
