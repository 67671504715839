import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import queryString from 'query-string'

import ResetPassword from '../components/resetPassword/ResetPassword'
import requireUnauth from '../hoc/requireUnauth'
import { RESET_PASSWORD_PROFILE_DISPATCH } from '../store/reducers/auth/resetPassword'
import { LOG_IN_URL } from '../constants/navigation'
import {
  changeResetPasswordState,
  changeResetPasswordInputAndErrorState,
  cleanResetPasswordState,
  resetPassword
} from '../store/actions/auth'
import { containsNoValues } from '../util/object.helpers'
import { extractEmailFromUrl } from '../util/string.helpers'
import {
  checkValidations,
  requiredRule,
  passwordRule
} from '../util/validation.helpers'

const ResetPasswordContainer = ({
  history,
  location,
  resetPasswordProfile,
  changeResetPasswordState,
  changeResetPasswordInputAndErrorState,
  cleanResetPasswordState,
  resetPassword
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      cleanResetPasswordState()
    }
  }, [cleanResetPasswordState])

  useEffect(() => {
    const queryParams = queryString.parse(location.search)
    if (!queryParams.resetCode) {
      handleGoToLogin()
    }
  }, [location.search])

  const handleResetPassword = () => {
    changeResetPasswordState(RESET_PASSWORD_PROFILE_DISPATCH.ERROR, {})
    const { newPassword, resetSuccess } = resetPasswordProfile
    if (resetSuccess) {
      handleGoToLogin()
    } else {
      const errors = checkValidations({
        newPassword: { value: newPassword, rules: [requiredRule, passwordRule] }
      })
      if (containsNoValues(errors)) {
        const queryParams = queryString.parse(location.search)
        if (queryParams) {
          const email = extractEmailFromUrl(location.search)
          resetPassword(email, queryParams.resetCode, newPassword)
        }
      } else {
        changeResetPasswordState(RESET_PASSWORD_PROFILE_DISPATCH.ERROR, errors)
      }
    }
  }

  const handleGoToLogin = () => {
    history.replace(LOG_IN_URL)
  }

  return (
    <ResetPassword
      resetPasswordProfile={resetPasswordProfile}
      dispatchAction={changeResetPasswordInputAndErrorState}
      onSubmitResetPassword={handleResetPassword}
      t={t}
    />
  )
}

const mapStateToProps = ({ authReducer }) => {
  const { resetPasswordProfile } = authReducer
  return {
    resetPasswordProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeResetPasswordState,
      changeResetPasswordInputAndErrorState,
      cleanResetPasswordState,
      resetPassword
    },
    dispatch
  )

export default requireUnauth(
  connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer)
)
