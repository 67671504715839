import React, { memo } from 'react'

import BrandSetupForm from '../../shared/BrandSetupForm/BrandSetupForm'
import PageContainer from '../../shared/PageContainer/PageContainer'

const Brand = ({ t, ...restProps }) => {
  const title = t('setup.brand-details')
  return (
    <PageContainer title={title}>
      <BrandSetupForm
        title={title}
        description={t('setup.brand-description')}
        t={t}
        {...restProps}
      />
    </PageContainer>
  )
}

export default memo(Brand)
