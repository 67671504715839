import React, { memo } from 'react'
import { Grid } from '@mui/material'

import AccountForm from '../shared/AccountForm/AccountForm'
import BlockchainPreferenceFields from './BlockchainPreferenceFields'

const BlockchainPreference = ({
  onFormSubmit,
  tokenProfile: { binanceMirrorTemp },
  dispatchAction,
  t
}) => {
  return (
    <AccountForm
      onFormSubmit={onFormSubmit}
      submitLabel='common.save'
      t={t}
      fields={
        <Grid item xs={12}>
          <BlockchainPreferenceFields
            t={t}
            binanceMirrorTemp={binanceMirrorTemp}
            dispatchAction={dispatchAction}
          />
        </Grid>
      }
    />
  )
}

export default memo(BlockchainPreference)
