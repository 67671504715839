import AppSignal from '@appsignal/javascript'

import { logger } from '../util/log.helpers'
import { parseJson } from '../util/app/app.helpers'
import { deepMerge } from '../util/object.helpers'
import { APP_SIGNAL_NAMESPACE } from './app'

const APP_CONFIG = {
  API_URL: getApiUrl(),
  SLS_URL: getSlsUrl(),
  COGNITO_CONFIG: getCognitoConfig(),
  RECAPTCHA_ID: getRecaptchaId(),
  HOTJAR_CONFIG: getHotjarConfig(),
  GOOGLE_TAG_MANAGER_ID: getGoogleTagManagerId(),
  STONLY_WIDGET_ID: getStonlyWidgetId()
}

const GOOGLE_RECAPTCHA_SCRIPT_URL =
  'https://www.google.com/recaptcha/api.js?render='

export { APP_CONFIG, GOOGLE_RECAPTCHA_SCRIPT_URL }

function getApiUrl() {
  return process.env.REACT_APP_API_URL
}

function getSlsUrl() {
  return process.env.REACT_APP_SLS_URL
}

function getCognitoConfig() {
  logger(`Current Environment is: ${process.env.REACT_APP_ENV}`)
  const cognitoEnv = process.env.REACT_APP_COGNITO
  if (cognitoEnv) {
    const { region, poolId, clientId, poolDomain, redirectUri } =
      parseJson(cognitoEnv)
    return wrapDefaultCognitoConfig({
      region,
      userPoolId: poolId,
      userPoolWebClientId: clientId,
      oauth: {
        domain: poolDomain,
        redirectSignIn: redirectUri,
        redirectSignOut: redirectUri
      }
    })
  }
  return wrapDefaultCognitoConfig({})
}

function wrapDefaultCognitoConfig(cognitoConfig) {
  return deepMerge(
    {
      mandatorySignIn: true,
      oauth: {
        scope: [
          'phone',
          'email',
          'profile',
          'aws.cognito.signin.user.admin',
          'openid'
        ],
        responseType: 'code',
        options: {
          AdvancedSecurityDataCollectionFlag: false
        }
      }
    },
    cognitoConfig
  )
}

function getRecaptchaId() {
  return process.env.REACT_APP_RECAPTCHA_ID
}

function getGoogleTagManagerId() {
  return process.env.REACT_APP_GTM_ID
}

function getHotjarConfig() {
  const hotjarEnv = process.env.REACT_APP_HOTJAR_CONFIG
  return hotjarEnv ? parseJson(hotjarEnv) : {}
}

function getStonlyWidgetId() {
  return process.env.REACT_APP_STONLY_WID
}

export function getAppSignalConfig(userId) {
  const instance = appSignalInstance()
  if (instance) {
    const tags = {}
    if (userId) {
      tags.user_id = userId
    }
    return {
      instance,
      tags
    }
  }
  return {}
}

export function appSignalInstance() {
  const key = process.env.REACT_APP_APP_SIGNAL_KEY
  if (!key) {
    return null
  }
  return new AppSignal({
    key,
    namespace: APP_SIGNAL_NAMESPACE
  })
}
