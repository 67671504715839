import { map, filter } from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable'

import {
  DASHBOARD_ACTIONS,
  MAIN_PROFILE_ACTION,
  TOKEN_ACTIONS
} from '../../constants/actions'
import {
  getMilestoneAnimationStatus,
  setMilestoneAnimationStatus
} from '../../util/local.helpers'
import { extractTokenId } from '../../util/epics.helpers'

const handleGetMilestoneAnimationEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS,
      TOKEN_ACTIONS.ON_GET_TOKENS_SUCCESS
    ),
    map(() => ({
      tokenId: extractTokenId(state$)
    })),
    filter(({ tokenId }) => tokenId),
    map(({ tokenId }) => ({
      type: DASHBOARD_ACTIONS.ON_MILESTONE_ANIMATION_COMPLETE_SUCCESS,
      payload: getMilestoneAnimationStatus()?.[tokenId]
    }))
  )

const handleSetMilestoneAnimationEpic = (action$, state$) =>
  action$.pipe(
    ofType(DASHBOARD_ACTIONS.ON_MILESTONE_ANIMATION_COMPLETE),
    map(({ payload }) => ({
      tokenId: extractTokenId(state$),
      data: payload
    })),
    filter(({ tokenId }) => tokenId),
    map(({ tokenId, data }) => {
      setMilestoneAnimationStatus(tokenId, data)
      return {
        type: DASHBOARD_ACTIONS.ON_MILESTONE_ANIMATION_COMPLETE_SUCCESS,
        payload: getMilestoneAnimationStatus()?.[tokenId]
      }
    })
  )

export const dashboardEpic = combineEpics(
  handleGetMilestoneAnimationEpic,
  handleSetMilestoneAnimationEpic
)
