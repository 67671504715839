import React from 'react'
import { styled } from '@mui/material/styles'
import { Select } from '@mui/material'

const CustomSelect = styled((props) => <Select {...props} />)(({ theme }) => ({
  '& .MuiSelect-select': {
    color: theme.palette.text.primary
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.inputBorderColor
  },
  '& .MuiSelect-select::-webkit-input-placeholder': {
    color: '#767e89',
    opacity: '1'
  }
}))

export default CustomSelect
