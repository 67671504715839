import React, { memo } from 'react'

import AccountForm from '../shared/AccountForm/AccountForm'
import { LETS_TALK_BRAND_API_URL } from '../../constants/externalResources'
import { openExternalUrl } from '../../util/app/app.helpers'

const BrandAPI = ({ t }) => {
  return (
    <AccountForm
      title={t('developers.brand-api')}
      description={t('developers.brand-api-description')}
      submitLabel='developers.lets-talk'
      onFormSubmit={() => {
        openExternalUrl(LETS_TALK_BRAND_API_URL)
      }}
      t={t}
    />
  )
}

export default memo(BrandAPI)
