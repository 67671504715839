export const ANALYTICS_TAB_TYPE = {
  POINTS_BANK: 'points-bank',
  POINTS_EXCHANGE: 'marketplace'
}

export const ANALYTICS_TAB_CONTENT_ID = {
  POINTS_EXCHANGE: 0,
  POINTS_BANK: 1
}

export const ANALYTICS_DATE_RANGE_FILTERS = {
  // @TODO We are disabling hourly for now, once we fix the backend issue to handle huge hourly data we will enable it again.
  //LAST_24_HOURS: 'last-24-hours',
  LAST_7_DAYS: 'last-7-days',
  LAST_15_DAYS: 'last-15-days',
  LAST_30_DAYS: 'last-30-days',
  LAST_3_MONTHS: 'last-3-months',
  LAST_6_MONTHS: 'last-6-months',
  LAST_12_MONTHS: 'last-12-months',
  CUSTOM: 'custom'
}

export const ANALYTICS_INTERVAL_FILTERS = {
  // @TODO We are disabling hourly for now, once we fix the backend issue to handle huge hourly data we will enable it again.
  //HOURLY: 'hourly',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  YEARLY: 'yearly'
}
