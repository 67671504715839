import { SETUP_URL } from '../constants/navigation'
import { DASHBOARD_SETUP_STATE } from '../store/reducers/dashboard/dashboardSetup'
import { DASHBOARD_SETUP_URL_TYPES } from '../constants/dashboard'

const extractInitials = (firstName, lastName) => {
  return firstName && lastName && `${firstName[0] || ''}${lastName[0] || ''}`
}

const isDashboardReady = (dashboardSetupState) => {
  return dashboardSetupState === DASHBOARD_SETUP_STATE.READY
}

const isDashboardStatus = (dashboardSetupState) => {
  return dashboardSetupState === DASHBOARD_SETUP_STATE.STATUS
}

const isSetupLoyaltyPoint = (dashboardSetupState) => {
  return (
    dashboardSetupState &&
    dashboardSetupState <= DASHBOARD_SETUP_STATE.LOYALTY_POINT
  )
}

const isSetupPackage = (dashboardSetupState) => {
  return (
    dashboardSetupState && dashboardSetupState === DASHBOARD_SETUP_STATE.PACKAGE
  )
}

const isDashboardLoading = (dashboardSetupState) => {
  return dashboardSetupState === DASHBOARD_SETUP_STATE.LOADING
}

const buildSetupUrl = (activeTab) => {
  return `${SETUP_URL}${activeTab ? `/${activeTab}` : ''}`
}

const buildBrandSetupUrl = () => {
  return buildSetupUrl(DASHBOARD_SETUP_URL_TYPES.BRAND)
}

const fromSetupTabIdToUrl = (tabId) => {
  switch (tabId) {
    case DASHBOARD_SETUP_STATE.BRAND:
      return DASHBOARD_SETUP_URL_TYPES.BRAND
    case DASHBOARD_SETUP_STATE.LOYALTY_POINT:
      return DASHBOARD_SETUP_URL_TYPES.LOYALTY_POINT
    case DASHBOARD_SETUP_STATE.PACKAGE:
      return DASHBOARD_SETUP_URL_TYPES.PACKAGE
    case DASHBOARD_SETUP_STATE.STATUS:
      return DASHBOARD_SETUP_URL_TYPES.STATUS
    default:
      return null
  }
}

const isMilestoneCompleted = (completed) => {
  return completed >= 100
}

const extractMilestonesAnimationStatus = (milestonesData) => {
  const animationStatus = {}
  if (milestonesData && milestonesData.length > 0) {
    milestonesData.forEach(({ id, completed }) => {
      if (isMilestoneCompleted(completed)) {
        animationStatus[id] = true
      }
    })
  }
  return animationStatus
}

export {
  extractInitials,
  isDashboardReady,
  isDashboardLoading,
  isSetupLoyaltyPoint,
  isSetupPackage,
  isDashboardStatus,
  buildSetupUrl,
  fromSetupTabIdToUrl,
  isMilestoneCompleted,
  extractMilestonesAnimationStatus,
  buildBrandSetupUrl
}
