import React, { memo } from 'react'
import { Box } from '@mui/material'

const FormActionsBox = ({ children, customCss }) => {
  return (
    <Box
      display='flex'
      sx={{ flexDirection: 'row', mt: 3, alignItems: 'end', ...customCss }}
    >
      {children}
    </Box>
  )
}

export default memo(FormActionsBox)
