import React, { memo, useEffect, useState } from 'react'
import { Grid } from '@mui/material'

import {
  QB_DEFAULT_COUNTRY_VALUE,
  QB_DEFAULT_REGION_VALUE
} from '../../../constants/app'
import { sortOptionsByASC } from '../../../util/app/app.helpers'
import MultiSelectWithCheckbox from '../MultiSelectWithCheckbox/MultiSelectWithCheckbox'
import { QB_LABEL_TEXT } from '../../../constants/messages'
import { elementIsInArray } from '../../../util/array.helpers'
import {
  fetchCountriesByRegion,
  fetchRegionsList,
  regionLabelOptionsByValues,
  countryLabelOptionsByValues
} from '../../../util/country.helpers'

const CountryRegionField = ({
  t,
  regions,
  countries,
  onChangeRegions,
  onChangeCountries,
  errorMessageRegions,
  errorMessageCountries,
  successMsgRegions,
  successMsgCountries
}) => {
  const [countriesList, setCountriesList] = useState([])

  useEffect(() => {
    setCountriesList(fetchCountriesByRegion(t, regions))
  }, [regions])

  const [defaultRegion, ...restRegions] = fetchRegionsList(t)
  const regionsList = [defaultRegion, ...sortOptionsByASC(restRegions)]

  const handleRegionChange = (value) => {
    const regions = value.map((region) => region.value)
    const allRegions = regionsList.map(({ value }) => value)
    if (regions.length !== allRegions.length) {
      allRegions.shift()
    }
    if (elementIsInArray(regions, QB_DEFAULT_REGION_VALUE)) {
      onChangeRegions(allRegions)
    } else {
      onChangeRegions(regions)
    }
    const countriesByRegions = fetchCountriesByRegion(t, regions)
    setCountriesList(countriesByRegions)
    onChangeCountries(
      preselectedExistingCountries(countriesByRegions, countries)
    )
  }

  const handleCountryChange = (value) => {
    const countries = value.map((country) => country.value)
    const allCountries = countriesList.map(({ value }) => value)
    if (countries.length !== allCountries.length) {
      allCountries.shift()
    }
    if (elementIsInArray(countries, QB_DEFAULT_COUNTRY_VALUE)) {
      onChangeCountries(allCountries)
    } else {
      onChangeCountries(countries)
    }
  }

  return (
    <>
      <Grid item lg={6} md={12} sm={12} xs={12} style={{ paddingTop: 0 }}>
        <MultiSelectWithCheckbox
          options={regionsList}
          id='regions'
          label={t(QB_LABEL_TEXT.SELECT_REGION)}
          value={sortOptionsByASC(regionLabelOptionsByValues(regions, t))}
          onChange={handleRegionChange}
          errorMessage={errorMessageRegions}
          successMsg={successMsgRegions}
        />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12} style={{ paddingTop: 0 }}>
        <MultiSelectWithCheckbox
          options={countriesList}
          id='countries'
          label={t(QB_LABEL_TEXT.SELECT_COUNTRY)}
          value={sortOptionsByASC(countryLabelOptionsByValues(countries, t))}
          onChange={handleCountryChange}
          errorMessage={errorMessageCountries}
          successMsg={successMsgCountries}
        />
      </Grid>
    </>
  )
}

export default memo(CountryRegionField)

const preselectedExistingCountries = (
  availableCountries = [],
  countries = []
) => {
  if (availableCountries && availableCountries.length > 0) {
    const updatedCountries = []
    const countriesHash = countries.reduce((map, current) => {
      map[current] = true
      return map
    }, {})
    availableCountries.forEach((country) => {
      const countryCode = country.value
      if (countriesHash[countryCode]) {
        updatedCountries.push(countryCode)
      }
    })
    return updatedCountries
  }
  return []
}
