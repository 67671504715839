import React, { memo } from 'react'
import { Box } from '@mui/material'

import CustomAutocomplete from '../inputs/CustomAutocomplete'
import { QB_LABEL_TEXT } from '../../../constants/messages'
import {
  countryLabelsByValues,
  fetchCountriesByRegion
} from '../../../util/country.helpers'

const CountryField = ({ id, value, t, ...restProps }) => {
  const countryLabel = value ? countryLabelsByValues([value])?.[0] : ''
  const countryValue = value ? { value: value, label: countryLabel } : null
  return (
    <CustomAutocomplete
      {...restProps}
      value={countryValue}
      //inputValue={countryLabel}
      options={fetchCountriesByRegion(t, null, false)}
      id={id}
      label={t(QB_LABEL_TEXT.COUNTRY)}
      renderOption={(props, option) => (
        <Box component='li' {...props}>
          {option.label}
        </Box>
      )}
    />
  )
}

CountryField.defaultProps = {
  size: 'small'
}

export default memo(CountryField)
