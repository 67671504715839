import { mergeToNewObject } from '../../../util/object.helpers'
import {
  ANALYTICS_FILTER_ACTION,
  TRANSACTION_ACTIONS
} from '../../../constants/actions'
import {
  buildExchangeActivityChartData,
  buildLiabilityTrendChartData,
  buildTransactionsChartData,
  processPointsTransferredChartByFilter,
  processTransactionsChartByFilter
} from '../../../util/chart/transactionsGraph.helpers'
import { ANALYTICS_INTERVAL_FILTERS } from '../../../constants/analytics'
import { buildIsProcessingState } from '../../../util/app/app.helpers'

export const transactionsGraphProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case TRANSACTION_ACTIONS.ON_GET_EXCHANGE_ACTIVITY_CHART_SUCCESS:
      return mergeToNewObject(state, {
        exchangeActivityChart: buildExchangeActivityChartData({
          chartData: payload,
          analyticsFilter: { interval: ANALYTICS_INTERVAL_FILTERS.MONTHLY }
        })
      })

    case TRANSACTION_ACTIONS.ON_RESET_CHART_STATE:
      return INITIAL_STATE

    case TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS_CHART:
      return mergeToNewObject(
        state,
        buildIsProcessingState(
          TRANSACTIONS_GRAPH_DISPATCH.TRANSACTIONS_CHART,
          true,
          state
        )
      )

    case TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS_CHART_SUCCESS:
      return mergeToNewObject(
        state,
        buildTransactionsChartData(payload),
        buildIsProcessingState(
          TRANSACTIONS_GRAPH_DISPATCH.TRANSACTIONS_CHART,
          false,
          state
        )
      )

    case TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS_CHART_FAILED:
      return mergeToNewObject(
        state,
        buildIsProcessingState(
          TRANSACTIONS_GRAPH_DISPATCH.TRANSACTIONS_CHART,
          false,
          state
        )
      )

    case ANALYTICS_FILTER_ACTION.ON_POINTS_BANK_TRANSACTION_TYPE_CHANGE:
      return mergeToNewObject(
        state,
        handleAnalyticsFilterChange(payload, state)
      )

    case TRANSACTION_ACTIONS.ON_GET_LIABILITY_TREND_CHART:
      return mergeToNewObject(
        state,
        buildIsProcessingState(
          TRANSACTIONS_GRAPH_DISPATCH.LIABILITY_TREND_CHART,
          true,
          state
        )
      )

    case TRANSACTION_ACTIONS.ON_GET_LIABILITY_TREND_CHART_SUCCESS:
      return mergeToNewObject(
        state,
        {
          liabilityTrendChart: buildLiabilityTrendChartData(payload)
        },
        buildIsProcessingState(
          TRANSACTIONS_GRAPH_DISPATCH.LIABILITY_TREND_CHART,
          false,
          state
        )
      )

    case TRANSACTION_ACTIONS.ON_GET_LIABILITY_TREND_CHART_FAILED:
      return mergeToNewObject(
        state,
        buildIsProcessingState(
          TRANSACTIONS_GRAPH_DISPATCH.LIABILITY_TREND_CHART,
          false,
          state
        )
      )

    default:
      return state
  }
}

export const TRANSACTIONS_GRAPH_DISPATCH = {
  TRANSACTIONS_CHART: 'transactionsChart',
  LIABILITY_TREND_CHART: 'liabilityTrendChart'
}

const INITIAL_STATE = {
  exchangeActivityChart: {
    labels: [],
    data: []
  },
  valueTransferredChart: {
    data: [],
    filteredData: [],
    labels: []
  },
  transactionsChart: {
    data: {},
    filteredData: {}
  },
  liabilityTrendChart: {
    labels: [],
    data: []
  },
  isProcessing: {
    [TRANSACTIONS_GRAPH_DISPATCH.TRANSACTIONS_CHART]: false,
    [TRANSACTIONS_GRAPH_DISPATCH.LIABILITY_TREND_CHART]: false
  }
}

const handleAnalyticsFilterChange = (
  { analyticsFilter: { transactionType } },
  state
) => {
  return {
    transactionsChart: {
      ...state.transactionsChart,
      filteredData: processTransactionsChartByFilter(
        state.transactionsChart.data,
        transactionType
      )
    },
    valueTransferredChart: {
      ...state.valueTransferredChart,
      filteredData: processPointsTransferredChartByFilter(
        state.valueTransferredChart.data,
        transactionType
      )
    }
  }
}
