import React, { memo } from 'react'
import { Grid } from '@mui/material'

import { ACCOUNT_PROFILE_DISPATCH } from '../../store/reducers/account/account'
import Input from '../shared/inputs/Input'
import AccountForm from '../shared/AccountForm/AccountForm'
import { transWithArgs } from '../../util/validation.helpers'
import { QB_INPUT_EMAIL } from '../../constants/input'

const ChangeEmail = ({
  onFormSubmit,
  dispatchAction,
  accountProfile: { error, success, emailTemp, email },
  t
}) => {
  return (
    <AccountForm
      title={t('account.change-email-address')}
      onFormSubmit={onFormSubmit}
      disabledSubmit={emailTemp === email}
      t={t}
      fields={
        <Grid item xs={12}>
          <Input
            id={ACCOUNT_PROFILE_DISPATCH.EMAIL_TEMP}
            label={t('account.email-input-title')}
            value={emailTemp}
            errorMessage={transWithArgs(
              t,
              error[ACCOUNT_PROFILE_DISPATCH.EMAIL_TEMP]
            )}
            onChange={(value) =>
              dispatchAction(ACCOUNT_PROFILE_DISPATCH.EMAIL_TEMP, value)
            }
            successMsg={transWithArgs(
              t,
              success[ACCOUNT_PROFILE_DISPATCH.EMAIL_TEMP]
            )}
            type={QB_INPUT_EMAIL}
            size='small'
          />
        </Grid>
      }
    />
  )
}

export default memo(ChangeEmail)
