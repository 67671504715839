import React, { useEffect, lazy, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { bindActionCreators } from 'redux'
import { useParams } from 'react-router-dom'

import {
  buildExtensionsUrl,
  fromExtensionsTabIdToType
} from '../util/extensions/extensions.helpers'
import Extensions from '../components/extensions/Extensions'
import { isDashboardReady } from '../util/dashboard.helpers'
import { REQUEST_FEATURE_URL, SETUP_URL } from '../constants/navigation'
import {
  getUserSurveys,
  submitUserSurvey,
  updateUserSurvey,
  getBrandSurveys
} from '../store/actions/survey'
import {
  changeToolsFilterState,
  changeExtensionState
} from '../store/actions/extensions'
import {
  EARN_OPTION_TOGGLE_IDS,
  EXTENSIONS_TAB_TYPE
} from '../constants/extensions'
import { updateBrandConfig } from '../store/actions/brand'
import { updateToken } from '../store/actions/token'
import {
  changeExchangeTermsFormDataState,
  resetExchangeTermsState,
  changeExchangeTermsState,
  updateExchangeConnection,
  submitExchangeTermsAndConnectExchange,
  connectExchangePartner,
  changePartnersState
} from '../store/actions/exchange'
import {
  DEFAULT_CONTAINER_STATE,
  EXCHANGE_MARKETPLACE_PROCESS_STEP,
  EXTENSION_PROCESS_STEP
} from '../constants/containerStates'
import { getTokens } from '../store/actions/token'
import ActivationConfirmed from '../components/popups/extension/ActivationConfirmed'
import DeactivationConfirmed from '../components/popups/extension/DeactivationConfirmed'
import { EXTENSIONS_PROFILE_DISPATCH } from '../store/reducers/extensions/extensions'
import {
  BUY_POINTS_HELP_CENTER,
  CASHBACK_HELP_CENTER
} from '../constants/externalResources'
import AcceptExchangeTermsPopup from '../components/popups/exchange/AcceptExchangeTerms'
import i18n from '../i18n'
import { EXCHANGE_TERMS_PROFILE_DISPATCH } from '../store/reducers/exchange/exchangeTerms'
import {
  buildCheckValidationRules,
  buildPointsPurchaseSurvey,
  buildResponseForExchangeTerms,
  extractSurveyQuestions
} from '../util/survey.helpers'
import { checkValidations } from '../util/validation.helpers'
import { containsNoValues } from '../util/object.helpers'
import {
  EXCHANGE_CONNECTION_ACTION,
  EXCHANGE_WHITELIST_STATUS
} from '../constants/exchange'
import ExtensionPartnerDetailsPopup from '../components/popups/extension/ExtensionPartnerDetails'
import { EXCHANGE_PARTNER_DISPATCH } from '../store/reducers/exchange/partners'

const ConfirmationPopup = lazy(() =>
  import('../components/popups/common/Confirmation')
)
const ConfirmedPopup = lazy(() =>
  import('../components/popups/common/Confirmed')
)
const ExtensionsContainer = ({
  history,
  getUserSurveys,
  getBrandSurveys,
  submitUserSurvey,
  updateUserSurvey,
  surveyProfile,
  updateBrandConfig,
  getTokens,
  isDashboardSetupReady,
  extensionsProfile,
  changeExtensionState,
  updateToken,
  tokenProfile,
  partnersProfile,
  exchangeTermsProfile,
  changeExchangeTermsFormDataState,
  resetExchangeTermsState,
  changeExchangeTermsState,
  updateExchangeConnection,
  mainProfile: { dashboardConfigs, exchangePartnersStats },
  submitExchangeTermsAndConnectExchange,
  connectExchangePartner,
  changePartnersState,
  ...restProps
}) => {
  const { t } = useTranslation()
  const pointsPurchaseSurveyId = buildPointsPurchaseSurvey(
    dashboardConfigs.milesAndMoreTokenId
  )
  useEffect(() => {
    getTokens()
    getUserSurveys()
    getBrandSurveys()
    return () => {
      handleNoPopup()
      changePartnersState(
        null,
        EXCHANGE_PARTNER_DISPATCH.EXCHANGE_CONNECTION_ACTION,
        DEFAULT_CONTAINER_STATE.NONE
      )
    }
  }, [])

  const [popupState, setPopupState] = useState(DEFAULT_CONTAINER_STATE.NONE)
  const [selectedModule, setSelectedModule] = useState(null)

  const { selectedTab } = useParams()

  const setSelectedTab = (selectedTab) => {
    history.push(buildExtensionsUrl(selectedTab))
  }

  useEffect(() => {
    if (!selectedTab) {
      setSelectedTab(EXTENSIONS_TAB_TYPE.THIRD_PARTY_TOOLS)
    }
  }, [selectedTab])

  useEffect(() => {
    if (selectedModule) {
      setPopupState(EXTENSION_PROCESS_STEP.ACTIVATION_CHANGED)
    }
  }, [tokenProfile.enablePurchasePoints])

  useEffect(() => {
    const action = extensionsProfile.popupState
    if (action) {
      setPopupState(action)
    }
  }, [extensionsProfile.popupState])

  const handleChangeTab = (_, selectedTabId) => {
    const newTab = fromExtensionsTabIdToType(selectedTabId)
    if (selectedTab !== newTab) {
      setSelectedTab(newTab)
    }
  }

  const handleClickSuggestFavorite = () => {
    history.push(REQUEST_FEATURE_URL)
  }

  const handleLikeUnlikeSurvey = (surveyId, questionId) => {
    const userSurvey = surveyProfile.userSurveys?.[surveyId]
    const userSurveyId = userSurvey?.id
    if (userSurveyId) {
      updateUserSurvey(
        surveyId,
        userSurveyId,
        questionId,
        !userSurvey.response?.[questionId]?.liked
      )
    } else {
      submitUserSurvey(surveyId, questionId, true)
    }
  }

  const handleToggleActivation = (id, activated) => {
    if (isDashboardSetupReady) {
      setSelectedModule({ id, activated: !activated })
      if (id === EARN_OPTION_TOGGLE_IDS.ENABLE_PURCHASE_POINTS) {
        updateToken({ [id]: !activated })
      }
      if (id === EARN_OPTION_TOGGLE_IDS.CASHBACK_EARN_OPTION) {
        updateBrandConfig({ [id]: !activated })
      }
      if (id === EARN_OPTION_TOGGLE_IDS.ENABLE_PURCHASE_POINTS_WITH_MILES) {
        if (activated) {
          handleDeactivateMilesAndMoreExtension()
        } else {
          if (surveyProfile.surveys[pointsPurchaseSurveyId]) {
            setPopupState(
              surveyProfile.brandSurveys[pointsPurchaseSurveyId]
                ? EXTENSION_PROCESS_STEP.READ_ONLY_EXTENSION_TERMS
                : EXCHANGE_MARKETPLACE_PROCESS_STEP.ACCEPT_EXCHANGE_TERMS
            )
          }
        }
      }
    } else {
      setPopupState(EXTENSION_PROCESS_STEP.SETUP_DASHBOARD)
    }
  }

  const handleOnDetailsMMExtension = () => {
    setPopupState(EXCHANGE_MARKETPLACE_PROCESS_STEP.EXCHANGE_CONNECTOR_DETAILS)
  }

  const handleDeactivateMilesAndMoreExtension = () => {
    if (exchangePartnersStats.milesAndMoreExchangePair) {
      updateExchangeConnection(
        EXCHANGE_CONNECTION_ACTION.REMOVE_TO,
        { status: EXCHANGE_WHITELIST_STATUS.STOP },
        exchangePartnersStats.milesAndMoreExchangePair
      )
    }
  }

  const handleNoPopup = () => {
    setPopupState(DEFAULT_CONTAINER_STATE.NONE)
    changeExtensionState(
      EXTENSIONS_PROFILE_DISPATCH.POPUP_STATE,
      DEFAULT_CONTAINER_STATE.NONE
    )
  }

  const handleOnCancelPopup = () => {
    switch (popupState) {
      case EXCHANGE_MARKETPLACE_PROCESS_STEP.ACCEPT_EXCHANGE_TERMS:
        resetExchangeTermsState()
        handleNoPopup()
        break

      default:
        handleNoPopup()
        break
    }
  }

  const handleOnSubmitPopup = () => {
    switch (popupState) {
      case EXTENSION_PROCESS_STEP.SETUP_DASHBOARD:
        history.push(SETUP_URL)
        break

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.ACCEPT_EXCHANGE_TERMS:
        changeExchangeTermsState(EXCHANGE_TERMS_PROFILE_DISPATCH.ERROR, {})
        const surveyQuestions = extractSurveyQuestions(
          surveyProfile.surveys[pointsPurchaseSurveyId],
          i18n.language
        )?.questions
        const errors = checkValidations(
          buildCheckValidationRules(
            surveyQuestions,
            exchangeTermsProfile.formData
          )
        )
        if (containsNoValues(errors)) {
          submitExchangeTermsAndConnectExchange(
            pointsPurchaseSurveyId,
            buildResponseForExchangeTerms(
              surveyQuestions,
              exchangeTermsProfile.formData
            ),
            dashboardConfigs.milesAndMoreTokenId,
            tokenProfile.id
          )
        } else {
          changeExchangeTermsState(
            EXCHANGE_TERMS_PROFILE_DISPATCH.ERROR,
            errors
          )
        }
        break

      case EXTENSION_PROCESS_STEP.READ_ONLY_EXTENSION_TERMS:
        connectExchangePartner(
          dashboardConfigs.milesAndMoreTokenId,
          tokenProfile.id
        )
        break

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.EXCHANGE_CONNECTOR_DETAILS:
        handleDeactivateMilesAndMoreExtension()
        break

      default:
        handleNoPopup()
        break
    }
  }

  const renderPopup = () => {
    switch (popupState) {
      case EXTENSION_PROCESS_STEP.SETUP_DASHBOARD:
        return (
          <ConfirmationPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            title='exchange.set-up-your-dashboard'
            description='extensions.dashboard-setup-desc'
            submitLabel='developers.go-to-setup'
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.ACCEPT_EXCHANGE_TERMS:
        return (
          <AcceptExchangeTermsPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            isProcessing={partnersProfile.isProcessing}
            exchangeTerms={surveyProfile.surveys[pointsPurchaseSurveyId]}
            dispatchAction={changeExchangeTermsFormDataState}
            exchangeTermsProfile={exchangeTermsProfile}
            submitLabel='common.activate'
          />
        )

      case EXTENSION_PROCESS_STEP.ACTIVATION_CHANGED:
        if (!selectedModule) {
          return
        }
        const description =
          selectedModule?.id === EARN_OPTION_TOGGLE_IDS.CASHBACK_EARN_OPTION
            ? {
                helpCenterUrl: CASHBACK_HELP_CENTER,
                descriptionId: selectedModule?.id
              }
            : {
                helpCenterUrl: BUY_POINTS_HELP_CENTER,
                descriptionId: EARN_OPTION_TOGGLE_IDS.ENABLE_PURCHASE_POINTS
              }

        return selectedModule?.activated ? (
          <ActivationConfirmed
            t={t}
            onSubmit={handleOnSubmitPopup}
            selectedModule={selectedModule}
            {...description}
          />
        ) : (
          <DeactivationConfirmed
            t={t}
            onSubmit={handleOnSubmitPopup}
            selectedModule={selectedModule}
            {...description}
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.ERROR_DISCONNECTED_EXCHANGE:
        return (
          <ConfirmedPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            title='exchange.connect-partner.unable-to-request-partnership'
            description='exchange.connect-partner.disconnected'
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.EXCHANGE_CONNECTOR_DETAILS:
        return (
          <ExtensionPartnerDetailsPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            isProcessing={partnersProfile.isProcessing}
            exchangeTerms={surveyProfile.surveys[pointsPurchaseSurveyId]}
            brandSurvey={surveyProfile.brandSurveys[pointsPurchaseSurveyId]}
          />
        )

      case EXTENSION_PROCESS_STEP.READ_ONLY_EXTENSION_TERMS:
        return (
          <ExtensionPartnerDetailsPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            isProcessing={partnersProfile.isProcessing}
            exchangeTerms={surveyProfile.surveys[pointsPurchaseSurveyId]}
            brandSurvey={surveyProfile.brandSurveys[pointsPurchaseSurveyId]}
            activate
          />
        )

      default:
        break
    }
  }
  return (
    <>
      <Extensions
        t={t}
        selectedTab={selectedTab}
        onChangeTab={handleChangeTab}
        onClickSuggestFavorite={handleClickSuggestFavorite}
        onLikeUnlikeSurvey={handleLikeUnlikeSurvey}
        surveyProfile={surveyProfile}
        onToggleActivation={handleToggleActivation}
        isDashboardSetupReady={isDashboardSetupReady}
        extensionsProfile={extensionsProfile}
        selectedModule={selectedModule}
        tokenProfile={tokenProfile}
        exchangePartnersStats={exchangePartnersStats}
        partnersProfile={partnersProfile}
        onDetailsMMExtension={handleOnDetailsMMExtension}
        milesAndMoreTokenId={dashboardConfigs.milesAndMoreTokenId}
        {...restProps}
      />
      {renderPopup()}
    </>
  )
}

const mapStateToProps = ({
  dashboardReducer,
  tokensReducer,
  mainReducer,
  surveyReducer,
  accountReducer,
  extensionsReducer,
  exchangeReducer
}) => {
  const { dashboardSetupProfile, brandProfile } = dashboardReducer
  const { tokenProfile } = tokensReducer
  const { billingProfile, mainProfile } = mainReducer
  const { surveyProfile } = surveyReducer
  const { accountProfile } = accountReducer
  const { extensionsProfile } = extensionsReducer
  const { partnersProfile, exchangeTermsProfile } = exchangeReducer
  return {
    isDashboardSetupReady: isDashboardReady(
      dashboardSetupProfile.dashboardSetupState
    ),
    tokenProfile,
    billingProfile,
    surveyProfile,
    extensionsProfile,
    brandConfig: brandProfile.brandConfig,
    accountProfile,
    mainProfile,
    partnersProfile,
    exchangeTermsProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserSurveys,
      getBrandSurveys,
      changeToolsFilterState,
      submitUserSurvey,
      updateUserSurvey,
      updateBrandConfig,
      getTokens,
      changeExtensionState,
      updateToken,
      changeExchangeTermsFormDataState,
      resetExchangeTermsState,
      changeExchangeTermsState,
      updateExchangeConnection,
      submitExchangeTermsAndConnectExchange,
      connectExchangePartner,
      changePartnersState
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ExtensionsContainer)
