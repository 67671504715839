import {
  MAIN_PROFILE_ACTION,
  LOVE_BRANDS_FILTERS_ACTION
} from '../../../constants/actions'
import { mergeToNewObject } from '../../../util/object.helpers'
import { LAD_SURVEY_IDS } from '../../../constants/survey'
import {
  QB_DEFAULT_REGION_VALUE,
  QB_DEFAULT_TYPE_VALUE
} from '../../../constants/app'
import { buildLoveBrandsData } from '../../../util/extensions/extensions.helpers'

export const loveBrandsFiltersProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS:
      const data =
        payload.surveys?.[LAD_SURVEY_IDS.BRANDS_YOU_LOVE]?.questions?.en
          ?.questions
      return mergeToNewObject(state, { data, filteredData: data })

    case LOVE_BRANDS_FILTERS_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, {
        [payload.property]: payload.value,
        ...handleToolsFilterChange({
          ...state,
          [payload.property]: payload.value
        })
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  data: [],
  filteredData: [],
  type: QB_DEFAULT_TYPE_VALUE,
  region: QB_DEFAULT_REGION_VALUE
}

export const LOVE_BRANDS_FILTERS_PROFILE_DISPATCH = {
  TYPE: 'type',
  REGION: 'region'
}

const handleToolsFilterChange = (state) => {
  return {
    filteredData: buildLoveBrandsData(state)
  }
}
