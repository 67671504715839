import { ADMIN_TAB_CONTENT_ID, ADMIN_TAB_TYPES } from '../constants/admin'
import { ADMIN_URL } from '../constants/navigation'

const buildTeamMemberBody = (email) => {
  return {
    access_control: {
      email: email
    }
  }
}

const isMyEmail = (memberEmail, userEmail) => {
  return memberEmail === userEmail
}

const fromAdminTabIdToType = (tabId) => {
  switch (tabId) {
    case ADMIN_TAB_CONTENT_ID.BRAND_DETAILS:
      return ADMIN_TAB_TYPES.BRAND_DETAILS
    case ADMIN_TAB_CONTENT_ID.TEAM_MEMBERS:
      return ADMIN_TAB_TYPES.TEAM_MEMBERS
    case ADMIN_TAB_CONTENT_ID.PACKAGES_AND_BILLING:
      return ADMIN_TAB_TYPES.PACKAGES_AND_BILLING
    default:
      return null
  }
}

const fromAdminTypeToTabId = (selectedTab) => {
  switch (selectedTab) {
    case ADMIN_TAB_TYPES.BRAND_DETAILS:
      return ADMIN_TAB_CONTENT_ID.BRAND_DETAILS
    case ADMIN_TAB_TYPES.TEAM_MEMBERS:
      return ADMIN_TAB_CONTENT_ID.TEAM_MEMBERS
    case ADMIN_TAB_TYPES.PACKAGES_AND_BILLING:
      return ADMIN_TAB_CONTENT_ID.PACKAGES_AND_BILLING
    default:
      return null
  }
}

const buildAdminUrl = (activeTab) => {
  return `${ADMIN_URL}${activeTab ? `/${activeTab}` : ''}`
}

const adminBrandDetailsUrl = () => {
  return buildAdminUrl(ADMIN_TAB_TYPES.BRAND_DETAILS)
}

const adminPackagesBillingUrl = () => {
  return buildAdminUrl(ADMIN_TAB_TYPES.PACKAGES_AND_BILLING)
}

export {
  buildTeamMemberBody,
  isMyEmail,
  buildAdminUrl,
  fromAdminTabIdToType,
  fromAdminTypeToTabId,
  adminBrandDetailsUrl,
  adminPackagesBillingUrl
}
