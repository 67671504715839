import React, { memo, useState } from 'react'
import { Box, Button, MobileStepper } from '@mui/material'
import SwipeableViews from 'react-swipeable-views'
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons'

import FaIcon from '../shared/FaIcon/FaIcon'
import CustomModal from '../shared/CustomDialog/CustomModal'

import img1 from '../../assets/images/redemption-shop/qiibee-storefront-1.png'
import img2 from '../../assets/images/redemption-shop/qiibee-storefront-2.png'
import img3 from '../../assets/images/redemption-shop/qiibee-storefront-3.png'

const AutoPlaySwipeableViews = SwipeableViews

const images = { 1: img1, 2: img2, 3: img3 }

const RedemptionShopCarousel = ({ t }) => {
  const [activeStep, setActiveStep] = useState(0)
  const [openPreview, setOpenPreview] = useState(false)
  const imagesKeys = Object.keys(images)
  const maxSteps = imagesKeys.length
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  const handleStepChange = (step) => {
    setActiveStep(step)
  }
  return (
    <Box
      sx={{
        flexGrow: 1,
        position: 'relative',
        margin: {
          sm: '0 auto',
          xs: '0 auto',
          lg: 'unset'
        }
      }}
    >
      <AutoPlaySwipeableViews
        axis='x-reverse'
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {imagesKeys.map((key, index) => (
          <Box key={key} mt={2}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component='img'
                sx={{
                  display: 'block',
                  maxWidth: '100%',
                  overflow: 'hidden',
                  width: '100%',
                  borderRadius: '10px',
                  cursor: 'pointer'
                }}
                src={images[key]}
                onClick={() => setOpenPreview(true)}
              />
            ) : null}
          </Box>
        ))}
      </AutoPlaySwipeableViews>

      <MobileStepper
        steps={maxSteps}
        position='static'
        activeStep={activeStep}
        nextButton={
          <Button
            size='small'
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {t('common.next')}
            <FaIcon icon={faArrowRight} width={18} />
          </Button>
        }
        backButton={
          <Button size='small' onClick={handleBack} disabled={activeStep === 0}>
            <FaIcon icon={faArrowLeft} width={18} />
            {t('common.back')}
          </Button>
        }
        sx={{ backgroundColor: 'transparent' }}
      />
      {openPreview && (
        <CustomModal
          open
          onClose={() => setOpenPreview(false)}
          content={
            <Box
              component='img'
              sx={{
                display: 'block',
                maxWidth: '100%',
                width: '100%'
              }}
              src={images[activeStep + 1]}
              onClick={() => setOpenPreview(true)}
            />
          }
          maxWidth='lg'
        />
      )}
    </Box>
  )
}

export default memo(RedemptionShopCarousel)
