import { API_KEY_ACTIONS } from '../../constants/actions'

// API KEYS
const createAPIKey = () => {
  return {
    type: API_KEY_ACTIONS.ON_CREATE_API_KEY
  }
}

const deleteAPIKey = (apiKeyId) => ({
  type: API_KEY_ACTIONS.ON_DELETE_API_KEY,
  payload: { apiKeyId }
})

const changeAPIKeyState = (property, value) => {
  return {
    type: API_KEY_ACTIONS.ON_CHANGE_STATE,
    payload: {
      property,
      value
    }
  }
}

const resetAPIKeyState = () => ({
  type: API_KEY_ACTIONS.ON_RESET_STATE
})

export { createAPIKey, deleteAPIKey, changeAPIKeyState, resetAPIKeyState }
