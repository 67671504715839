import React, { memo } from 'react'

import { LABEL_TEXT } from '../../../constants/messages'
import UserAlert from '../../shared/CustomAlert/UserAlert'
import { SETUP_URL } from '../../../constants/navigation'

const SetupInfo = ({ t }) => {
  return (
    <UserAlert
      label={t('setup.complete-your-setup')}
      to={SETUP_URL}
      i18nKey={LABEL_TEXT.GET_ACCESS_TO_DASHBOARD}
    />
  )
}

export default memo(SetupInfo)
