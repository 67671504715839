import {
  BRAND_ACTIONS,
  EXCHANGE_PARTNER_ACTION,
  EXTENSIONS_ACTION,
  MAIN_PROFILE_ACTION,
  SURVEY_ACTION,
  USER_SURVEY_ACTION
} from '../../../constants/actions'
import { mergeToNewObject } from '../../../util/object.helpers'
import { THIRD_PARTY_TOOLS_TYPE_FILTERS } from '../../../constants/extensions'
import { buildThirdPartyToolsData } from '../../../util/extensions/extensions.helpers'
import { LAD_SURVEY_IDS } from '../../../constants/survey'
import {
  DEFAULT_CONTAINER_STATE,
  EXCHANGE_MARKETPLACE_PROCESS_STEP,
  EXTENSION_PROCESS_STEP
} from '../../../constants/containerStates'

export const extensionsProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS:
      const thirdPartyTools =
        payload.surveys?.[LAD_SURVEY_IDS.THIRD_PARTY_TOOLS]?.questions?.en
          ?.questions
      return mergeToNewObject(
        state,
        { thirdPartyTools },
        handleToolsFilterChange(thirdPartyTools, state.toolsFilterType)
      )

    case EXTENSIONS_ACTION.CHANGE_TOOL_FILTER_STATE:
      return mergeToNewObject(state, {
        toolsFilterType: payload.value,
        ...handleToolsFilterChange(state.thirdPartyTools, payload.value)
      })

    case SURVEY_ACTION.ON_SUBMIT_USER_SURVEY:
    case SURVEY_ACTION.ON_UPDATE_USER_SURVEY:
      const { surveyId, questionId, liked } = payload
      const surveys = state.userSurveysLikesCount?.[surveyId]
      const likesCount = surveys?.[questionId] || 0
      return mergeToNewObject(state, {
        userSurveysLikesCount: {
          ...state.userSurveysLikesCount,
          [surveyId]: {
            ...surveys,
            [questionId]: liked
              ? likesCount + 1
              : likesCount > 0
              ? likesCount - 1
              : 0
          }
        }
      })

    case USER_SURVEY_ACTION.ON_GET_SURVEY_ANALYTICS_SUCCESS:
      return mergeToNewObject(state, { userSurveysLikesCount: payload })

    case EXTENSIONS_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case BRAND_ACTIONS.ON_UPDATE_BRAND_CONFIG:
      return mergeToNewObject(state, {
        popupState: DEFAULT_CONTAINER_STATE.NONE,
        isProcessing: true
      })

    case BRAND_ACTIONS.ON_UPDATE_BRAND_CONFIG_SUCCESS:
    case EXCHANGE_PARTNER_ACTION.ON_CONNECT_EXCHANGE_SUCCESS:
      return mergeToNewObject(state, {
        isProcessing: false,
        popupState: EXTENSION_PROCESS_STEP.ACTIVATION_CHANGED
      })

    case BRAND_ACTIONS.ON_UPDATE_BRAND_CONFIG_FAILED:
      return mergeToNewObject(state, {
        isProcessing: false
      })

    case EXCHANGE_PARTNER_ACTION.ON_CONNECT_EXCHANGE_FAILED:
      return mergeToNewObject(state, {
        popupState:
          EXCHANGE_MARKETPLACE_PROCESS_STEP.ERROR_DISCONNECTED_EXCHANGE,
        isProcessing: false
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  thirdPartyTools: [],
  filteredThirdPartyTools: [],
  toolsFilterType: THIRD_PARTY_TOOLS_TYPE_FILTERS.ALL,
  userSurveysLikesCount: {},
  popupState: DEFAULT_CONTAINER_STATE.NONE,
  isProcessing: false
}

export const EXTENSIONS_PROFILE_DISPATCH = {
  TOOLS_FILTER_TYPE: 'toolsFilterType',
  POPUP_STATE: 'popupState'
}

const handleToolsFilterChange = (thirdPartyTools, toolsFilterType) => {
  return {
    filteredThirdPartyTools: buildThirdPartyToolsData(
      thirdPartyTools,
      toolsFilterType
    )
  }
}
