import React, { memo } from 'react'
import { Trans } from 'react-i18next'

import Link from './link/Link'
import Text from './text/Text'

const ReminderText = ({ strongText, i18nKey, url, linkLabel, text }) => {
  return (
    <Text
      variant='h6'
      label={
        <>
          <strong>{strongText}</strong>
          {i18nKey && (
            <Trans i18nKey={i18nKey} i18nIsDynamicList>
              <Link isOutside to={url} label={linkLabel} color='inherit' />
            </Trans>
          )}
          {text && text}
        </>
      }
    />
  )
}

export default memo(ReminderText)
