import React, { memo } from 'react'
import { Grid, Box } from '@mui/material'

import ViewTermsPrivacyLinks from '../shared/Billing/ViewTermsPrivacyLinks'
import CustomButton from '../shared/buttons/CustomButton'
import AccountForm from '../shared/AccountForm/AccountForm'
import TestingCardDetailsNote from '../shared/ImportantNote/TestingCardDetailsNote'
import { isProduction } from '../../util/app/app.helpers'
import { isTokenStopped } from '../../util/token.helpers'

const PackageBillingActions = ({
  t,
  onChangePackage,
  onViewBilling,
  tokenStatus,
  onReactivatePackage
}) => {
  const tokenStopped = isTokenStopped(tokenStatus)
  return (
    <Grid item xs={12}>
      <AccountForm
        description={t(
          `billing.${tokenStopped ? 'reactivate' : 'change'}-package-desc`
        )}
        submitLabel={`billing.${
          tokenStopped ? 'select-package' : 'view-billing'
        }`}
        onFormSubmit={() => {
          tokenStopped ? onReactivatePackage() : onViewBilling()
        }}
        extraAction={
          !tokenStopped && (
            <CustomButton
              variant='outlined'
              fullWidth={false}
              label={t('billing.change-package')}
              onClick={onChangePackage}
            />
          )
        }
        backAction={!isProduction() && <TestingCardDetailsNote />}
        t={t}
      />
      <Box mt={2} mb={2}>
        <ViewTermsPrivacyLinks t={t} />
      </Box>
    </Grid>
  )
}

export default memo(PackageBillingActions)
