import React, { memo } from 'react'
import { Box } from '@mui/material'

import { extractInitials } from '../../../util/dashboard.helpers'
import UserProfileAvatar from '../UserProfileAvatar/UserProfileAvatar'

const UserInitials = ({ firstName, lastName, size, ...restProps }) => {
  const fontSize = size >= 70 ? 30 : 15
  return (
    <UserProfileAvatar size={size} {...restProps}>
      <Box fontSize={fontSize} fontWeight={600}>
        {extractInitials(firstName, lastName)}
      </Box>
    </UserProfileAvatar>
  )
}

UserInitials.defaultProps = {
  size: 30
}

export default memo(UserInitials)
