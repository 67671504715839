import {
  FILE_UPLOAD_ACTIONS,
  NFTS_PROFILE_ACTIONS
} from '../../../constants/actions'
import { mergeToNewObject } from '../../../util/object.helpers'
import {
  DEFAULT_CONTAINER_STATE,
  NFTS_PROCESS_STEP
} from '../../../constants/containerStates'
import { NFT_ERRORS } from '../../../constants/apiErrors'
import {
  NFT_EXCLUSIVE_CONTENT_MODE,
  NFT_MARKETPLACE_OPTIONS
} from '../../../constants/nfts'
import {
  convertToBooleanLabel,
  extractCreateNftErrors
} from '../../../util/nft.helpers'
import { formatCurrencyValue } from '../../../util/number.helpers'
import { isExistsElementFromList } from '../../../util/app/app.helpers'

export const nftProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case NFTS_PROFILE_ACTIONS.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case NFTS_PROFILE_ACTIONS.ON_CREATE_NFT:
      return mergeToNewObject(state, {
        isProcessing: true
      })

    case NFTS_PROFILE_ACTIONS.ON_CREATE_NFT_SUCCESS:
      return mergeToNewObject(state, {
        ...INITIAL_STATE,
        nftPopupState: NFTS_PROCESS_STEP.LIST_NFT,
        isProcessing: false,
        isCreatedNft: true
      })

    case NFTS_PROFILE_ACTIONS.ON_UPDATE_NFT:
      return mergeToNewObject(state, {
        isProcessing: true
      })

    case NFTS_PROFILE_ACTIONS.ON_UPDATE_NFT_SUCCESS:
      const isPopupUserPermissions = isExistsElementFromList(
        [NFTS_PROCESS_STEP.USER_PERMISSIONS, NFTS_PROCESS_STEP.NFT_DETAILS],
        state.nftPopupState
      )
      return mergeToNewObject(state, {
        isProcessing: false,
        pointValue: '',
        nftPopupState: isPopupUserPermissions
          ? NFTS_PROCESS_STEP.NFT_DETAILS
          : DEFAULT_CONTAINER_STATE.NONE
      })

    case NFTS_PROFILE_ACTIONS.ON_UPDATE_NFT_FAILED:
      return mergeToNewObject(state, {
        isProcessing: false
      })

    case NFTS_PROFILE_ACTIONS.ON_UPLOAD_FILES_FAILED:
    case NFTS_PROFILE_ACTIONS.ON_CREATE_NFT_FAILED:
      return mergeToNewObject(state, {
        isProcessing: false,
        error: extractCreateNftErrors(payload)
      })

    case FILE_UPLOAD_ACTIONS.ON_GET_PRESIGNED_URL_FAILED:
      return mergeToNewObject(state, handleCreateNftErrors(payload.response), {
        isProcessing: false
      })

    case NFTS_PROFILE_ACTIONS.ON_TRANSFER_NFT:
      return mergeToNewObject(state, {
        nftPopupState: DEFAULT_CONTAINER_STATE.NONE,
        transferId: '',
        error: ''
      })

    case NFTS_PROFILE_ACTIONS.ON_TRANSFER_NFT_SUCCESS:
      return mergeToNewObject(state, {
        nftPopupState: NFTS_PROCESS_STEP.NFT_TRANSFERRED
      })

    case NFTS_PROFILE_ACTIONS.ON_TRANSFER_NFT_FAILED:
      return mergeToNewObject(state, {
        nftPopupState: DEFAULT_CONTAINER_STATE.SOMETHING_WENT_WRONG
      })

    case NFTS_PROFILE_ACTIONS.ON_SELECT:
      const { canSell, canTransfer, salesFeePercentage } = payload
      return mergeToNewObject(state, {
        userSaleTemp: convertToBooleanLabel(canSell),
        userTransferTemp: convertToBooleanLabel(canTransfer),
        userSalesFeeTemp: formatCurrencyValue(salesFeePercentage),
        isCreatedNft: false
      })

    case NFTS_PROFILE_ACTIONS.ON_REDEEM_EXCLUSIVE_CONTENT_SUCCESS:
      return mergeToNewObject(state, {
        nftPopupState: NFTS_PROCESS_STEP.NFT_DETAILS
      })

    case NFTS_PROFILE_ACTIONS.RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  error: '',
  isProcessing: false,

  media: '',
  name: '',
  type: '',
  externalLink: '',
  description: '',
  exclusiveContent: '',
  exclusiveContentMode: NFT_EXCLUSIVE_CONTENT_MODE.SAME,
  exclusiveContentFile: '',
  supply: '',

  userSaleTemp: '',
  userTransferTemp: '',
  userSalesFeeTemp: '',

  pointValue: '',

  transferId: '',

  marketplace: NFT_MARKETPLACE_OPTIONS.LWA_LAD,

  nftPopupState: DEFAULT_CONTAINER_STATE.NONE,

  isCreatedNft: false
}

export const NFTS_PROFILE_DISPATCH = {
  ERROR: 'error',
  MEDIA: 'media',
  NAME: 'name',
  TYPE: 'type',
  EXTERNAL_LINK: 'externalLink',
  DESCRIPTION: 'description',
  EXCLUSIVE_CONTENT: 'exclusiveContent',
  EXCLUSIVE_CONTENT_MODE: 'exclusiveContentMode',
  EXCLUSIVE_CONTENT_FILE: 'exclusiveContentFile',
  POINT_VALUE: 'pointValue',
  SUPPLY: 'supply',
  NFT_POP_UP_STATE: 'nftPopupState',
  USER_SALE_TEMP: 'userSaleTemp',
  USER_TRANSFER_TEMP: 'userTransferTemp',
  USER_SALES_FEE_TEMP: 'userSalesFeeTemp',
  TRANSFER_ID: 'transferId',
  MARKETPLACE: 'marketplace'
}

const handleCreateNftErrors = ({ errors }) => {
  switch (errors.detail) {
    case NFT_ERRORS.FILE_TYPE_NOT_SUPPORTED:
      return {
        nftPopupState: NFTS_PROCESS_STEP.FILE_TYPE_NOT_SUPPORTED
      }
    default:
      return {
        nftPopupState: DEFAULT_CONTAINER_STATE.NONE
      }
  }
}
