import React, { memo } from 'react'
import { Tabs, Tab, Box, Stack } from '@mui/material'

import FaIcon from '../FaIcon/FaIcon'
import Text from '../text/Text'
import ShowCircleGuidance from '../Chip/ShowCircleGuidance'
import CustomTooltip from '../CustomTooltip/CustomTooltip'

const TabContent = ({
  selectedTab,
  onChangeTab,
  menuItems,
  rootProps,
  content,
  t,
  tabStyles
}) => {
  return (
    <Box sx={{ ...rootProps, width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={selectedTab}
          onChange={onChangeTab}
          variant='scrollable'
          scrollButtons='auto'
          {...tabStyles}
        >
          {menuItems &&
            menuItems.map(
              (
                {
                  label,
                  icon,
                  isShowGuidance,
                  disabled,
                  tooltipText,
                  noTranslation
                },
                index
              ) => {
                const tabContent = (
                  <Tab
                    key={index}
                    label={
                      <Text
                        label={
                          <Stack
                            direction='row'
                            spacing={1}
                            alignItems='center'
                          >
                            <FaIcon icon={icon} />
                            <ShowCircleGuidance show={isShowGuidance}>
                              <Text label={noTranslation ? label : t(label)} />
                            </ShowCircleGuidance>
                          </Stack>
                        }
                        sx={{ ml: 0.1, minWidth: 'fit-content' }}
                      />
                    }
                    sx={{ p: 0, mr: 3 }}
                    disabled={disabled}
                  />
                )
                return disabled ? (
                  <CustomTooltip key={index} label={tooltipText}>
                    {tabContent}
                  </CustomTooltip>
                ) : (
                  tabContent
                )
              }
            )}
        </Tabs>
      </Box>
      {content}
    </Box>
  )
}

export default memo(TabContent)
