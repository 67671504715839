import React, { memo } from 'react'

import { THEME_MODE_DARK } from '../../../constants/theme'
import Input from '../inputs/Input'

const Display = (props) => {
  return (
    <Input
      {...props}
      disabled
      size='small'
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: (theme) =>
            `${
              theme.palette.mode === THEME_MODE_DARK
                ? 'rgba(255, 255, 255, 0.12) !important'
                : '#dee3e9 !important'
            }`
        },
        '& .MuiInputBase-multiline': {
          padding: 0
        },
        '& .MuiInputBase-inputMultiline': {
          padding: '8.5px 14px'
        }
      }}
    />
  )
}

export default memo(Display)
