import React, { memo } from 'react'
import { Grid } from '@mui/material'

import Dropdown from '../inputs/Dropdown'

const FiltersDropdown = ({
  onChangeFilters,
  action,
  value,
  label,
  options,
  t
}) => {
  return (
    <Grid item sx={{ flex: 1, minWidth: '110px', pt: '0 !important' }}>
      <Dropdown
        id={action}
        label={t(label)}
        options={options}
        onChange={onChangeFilters}
        value={value}
        size='small'
      />
    </Grid>
  )
}

export default memo(FiltersDropdown)
