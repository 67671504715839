import React, { memo } from 'react'
import { Grid } from '@mui/material'
import {
  faFileInvoiceDollar,
  faStore,
  faUserGroup
} from '@fortawesome/pro-light-svg-icons'

import PageContainer from '../shared/PageContainer/PageContainer'
import DisplayAlerts from '../setup/common/DisplayAlerts'
import BaseCard from '../shared/BaseCard/BaseCard'
import PanelHeading from '../shared/PanelHeading/PanelHeading'
import TabContent from '../shared/TabContent/TabContent'
import CardGrid from '../shared/containers/CardGrid'
import { ADMIN_TAB_TYPES } from '../../constants/admin'
import BrandSetupForm from '../shared/BrandSetupForm/BrandSetupForm'
import SetupRequiredAction from '../shared/Dashboard/SetupRequiredAction'
import TeamMembersData from './TeamMembersData'
import PackageBillingActions from './PackageBillingActions'
import PackageBillingDetails from './billing/PackageBillingDetails'
import { fromAdminTypeToTabId } from '../../util/admin.helpers'
import { isBillingTabAndPackageSubscribedOrStoppedToken } from '../../util/billing.helpers'

const Admin = ({
  isDashboardSetupReady,
  t,
  selectedTab,
  onChangeTab,
  brandProfile,
  dispatchAction,
  onClickUpdate,
  onClickSetup,
  teamMembersTableProps,
  onChangePackage,
  onViewBilling,
  tokenProfile,
  billingProfile,
  onReactivatePackage
}) => {
  const title = t('sidebar.admin')

  const buildContent = () => {
    switch (selectedTab) {
      case ADMIN_TAB_TYPES.BRAND_DETAILS:
        return brandProfile.id ? (
          <BrandSetupForm
            brandProfile={brandProfile}
            dispatchAction={dispatchAction}
            onClickContinue={onClickUpdate}
            description={t('admin.change-brand-details')}
            customCss={{ p: 0 }}
            t={t}
          />
        ) : (
          <SetupRequiredAction
            description={t('admin.brand-not-created-details')}
            submitLabel={t('developers.start-setup')}
            onClickSetup={onClickSetup}
            t={t}
          />
        )
      case ADMIN_TAB_TYPES.TEAM_MEMBERS:
        return (
          <PanelHeading
            description={t('admin.team-members-desc')}
            customCss={{ p: 0 }}
          >
            <TeamMembersData
              t={t}
              teamMembersTableProps={teamMembersTableProps}
            />
          </PanelHeading>
        )
      case ADMIN_TAB_TYPES.PACKAGES_AND_BILLING:
        return !isDashboardSetupReady ? (
          <SetupRequiredAction
            description={t('admin.setup-required-for-billing')}
            submitLabel={t('developers.go-to-setup')}
            onClickSetup={onClickSetup}
            t={t}
          />
        ) : (
          <PackageBillingDetails
            t={t}
            billingProfile={billingProfile}
            onChangePackage={onChangePackage}
            tokenStatus={tokenProfile.status}
          />
        )
      default:
        return null
    }
  }

  return (
    <PageContainer title={title}>
      <DisplayAlerts
        isDashboardSetupReady={isDashboardSetupReady}
        tokenStatus={tokenProfile.status}
        subscribedPackage={billingProfile.subscribedPackage}
        t={t}
      />
      <CardGrid>
        <Grid item xs={12}>
          <BaseCard>
            <PanelHeading title={title}>
              <TabContent
                selectedTab={fromAdminTypeToTabId(selectedTab)}
                onChangeTab={onChangeTab}
                menuItems={tabMenuItems}
                t={t}
                rootProps={{ mt: 1 }}
                content={buildContent()}
              />
            </PanelHeading>
          </BaseCard>
        </Grid>
        {isDashboardSetupReady &&
          isBillingTabAndPackageSubscribedOrStoppedToken(
            selectedTab,
            billingProfile,
            tokenProfile
          ) && (
            <PackageBillingActions
              t={t}
              onChangePackage={onChangePackage}
              onViewBilling={onViewBilling}
              tokenStatus={tokenProfile.status}
              onReactivatePackage={onReactivatePackage}
            />
          )}
      </CardGrid>
    </PageContainer>
  )
}

export default memo(Admin)

const tabMenuItems = [
  {
    value: ADMIN_TAB_TYPES.BRAND_DETAILS,
    label: 'setup.brand-details',
    icon: faStore
  },
  {
    value: ADMIN_TAB_TYPES.TEAM_MEMBERS,
    label: 'admin.team-members',
    icon: faUserGroup
  },
  {
    value: ADMIN_TAB_TYPES.PACKAGES_AND_BILLING,
    label: 'admin.packages-and-billing',
    icon: faFileInvoiceDollar
  }
]
