import { MAIN_PROFILE_ACTION, TOKEN_ACTIONS } from '../../../constants/actions'
import {
  extractAllRegions,
  extractAllRegionsByCountries,
  getCountriesIsoArray
} from '../../../util/country.helpers'
import { mergeToNewObject } from '../../../util/object.helpers'
import { buildSuccessMessages } from '../../../util/reducer.helpers'
import {
  formatPointValue,
  isAllCountriesExists,
  isTokenActive,
  parseEstimatedMemberRange
} from '../../../util/token.helpers'
import { extractErrorMessage } from '../../../util/brand.helpers'

export const DEFAULT_TOKEN_NAME = 'Loyalty Point Name'
export const DEFAULT_TOKEN_SYMBOL = 'Loyalty Point Symbol'

export const tokenProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TOKEN_ACTIONS.ON_CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case TOKEN_ACTIONS.ON_CREATE_TOKEN_SUCCESS:
      return mergeToNewObject(state, {
        ...extractTokenData(payload.token),
        isProcessing: false
      })

    case TOKEN_ACTIONS.ON_CREATE_TOKEN_FAILED:
      return mergeToNewObject(state, {
        ...extractErrorMessage(payload),
        isProcessing: false
      })

    case TOKEN_ACTIONS.ON_RESET_STATE:
    case MAIN_PROFILE_ACTION.ON_NEW_BRAND_CREATION:
      return INITIAL_STATE

    case TOKEN_ACTIONS.ON_CREATE_TOKEN:
    case TOKEN_ACTIONS.ON_UPDATE_TOKEN:
      return mergeToNewObject(state, { loading: true, isProcessing: true })

    case TOKEN_ACTIONS.ON_UPDATE_TOKEN_SUCCESS:
      return mergeToNewObject(state, {
        ...handleTokenUpdateResponse(payload, state),
        loading: false,
        isProcessing: false
      })

    case TOKEN_ACTIONS.ON_UPDATE_TOKEN_FAILED:
      return mergeToNewObject(state, {
        isProcessing: false
      })

    case TOKEN_ACTIONS.ON_GET_TOKEN_BY_ID_SUCCESS:
      const token = payload.token
      return mergeToNewObject(state, extractTokenData(token), {
        noRedirectOnFirstRefresh: state.status !== token.status
      })

    case MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS:
      return mergeToNewObject(state, extractDataFromUser(payload))

    default:
      return state
  }
}

export const TOKEN_PROFILE_DISPATCH = {
  NAME_TEMP: 'nameTemp',

  SYMBOL_TEMP: 'symbolTemp',

  REGIONS_TEMP: 'regionsTemp',

  COUNTRIES_TEMP: 'countriesTemp',

  DESCRIPTION_TEMP: 'descriptionTemp',

  AMOUNT_OF_MEMBERS_TEMP: 'amountOfMembersTemp',

  TYPE_TEMP: 'typeTemp',

  POINT_VALUE_TEMP: 'pointValueTemp',

  BINANCE_MIRROR_TEMP: 'binanceMirrorTemp',

  ERROR: 'error',
  SUCCESS: 'success'
}

const INITIAL_STATE = {
  id: '',
  name: '',
  nameTemp: DEFAULT_TOKEN_NAME,

  symbol: '',
  symbolTemp: DEFAULT_TOKEN_SYMBOL,

  status: null,

  regions: [],
  regionsTemp: [],

  countries: [],
  countriesTemp: [],

  description: '',
  descriptionTemp: '',

  amountOfMembers: '',
  amountOfMembersTemp: '',

  pointValue: '',
  pointValueTemp: '',

  type: '',
  typeTemp: '',

  binanceMirror: false,
  binanceMirrorTemp: false,

  onramp: 0,
  offramp: 0,

  error: {},
  success: {},
  loading: false,
  isProcessing: false,

  todaysDate: new Date(),

  noRedirectOnFirstRefresh: false,

  enablePurchasePoints: false,

  stripeCustomerId: ''
}

const handleTokenUpdateResponse = (token, state) => {
  if (token) {
    const {
      regions,
      description,
      amountOfMembers,
      countries,
      type,
      binanceMirror,
      enablePurchasePoints
    } = extractTempParams(token)
    const {
      regions: regionsTemp,
      countries: countriesTemp,
      description: descriptionTemp,
      amountOfMembers: amountOfMembersTemp,
      type: typeTemp
    } = state
    const prevDetails = {
      regionsTemp,
      countriesTemp,
      descriptionTemp,
      amountOfMembersTemp,
      typeTemp
    }
    const updatedDetails = {
      ...(regions && { regions, regionsTemp: regions }),
      ...(countries && { countries, countriesTemp: countries }),
      descriptionTemp: description,
      amountOfMembersTemp: amountOfMembers,
      typeTemp: type
    }
    return {
      regions,
      countries,
      description,
      amountOfMembers,
      type,
      binanceMirror,
      binanceMirrorTemp: binanceMirror,
      ...updatedDetails,
      enablePurchasePoints,
      success: buildSuccessMessages(prevDetails, updatedDetails),
      error: {},
      stripeCustomerId: token?.stripeCustomerId
    }
  } else {
    return INITIAL_STATE
  }
}

const extractTokenData = (token) => {
  const { id, name, symbol, onramp, offramp, status, type, stripeCustomerId } =
    token
  const {
    regions,
    description,
    amountOfMembers,
    countries,
    binanceMirror,
    enablePurchasePoints
  } = extractTempParams(token)
  const pointValue = formatPointValue(onramp)
  return {
    id,
    status,
    ...(isTokenActive(status) ? { name, symbol } : { name: '', symbol: '' }),
    ...(pointValue && { pointValue, pointValueTemp: pointValue }),
    ...(countries?.length > 0 && { countries, countriesTemp: countries }),
    ...(regions?.length > 0 && { regions, regionsTemp: regions }),
    ...(amountOfMembers && {
      amountOfMembers,
      amountOfMembersTemp: amountOfMembers
    }),
    ...(description && { description, descriptionTemp: description }),
    onramp,
    offramp,
    nameTemp: name,
    symbolTemp: symbol,
    type,
    typeTemp: type,
    binanceMirror,
    binanceMirrorTemp: binanceMirror,
    enablePurchasePoints,
    error: {},
    stripeCustomerId // @TODO: Need to change key as per invoiceNinja
  }
}

const extractTempParams = (token) => {
  const {
    countries: countriesTemp,
    metadata,
    estimatedMemberCountLower,
    estimatedMemberCountHigher,
    type
  } = token
  const binanceMirror = token?.binanceMirror || false
  const enablePurchasePoints = token?.enablePurchasePoints || false
  const isAllCountriesSelect = isAllCountriesExists(countriesTemp)
  const amountOfMembers = parseEstimatedMemberRange(
    estimatedMemberCountLower,
    estimatedMemberCountHigher
  )
  const description = metadata?.description?.data
  const regions =
    countriesTemp &&
    (isAllCountriesSelect
      ? extractAllRegions()
      : extractAllRegionsByCountries(countriesTemp)
    ).map(({ value }) => value)
  const countries =
    countriesTemp && isAllCountriesSelect
      ? getCountriesIsoArray()
      : countriesTemp
  return {
    regions,
    description,
    amountOfMembers,
    countries,
    type,
    binanceMirror,
    enablePurchasePoints
  }
}

const extractDataFromUser = (payload) => {
  if (payload?.currentToken) {
    return extractTokenData(payload?.currentToken)
  } else {
    return {}
  }
}
