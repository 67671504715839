import React, { memo } from 'react'
import { CannyProvider, CannyChangelog } from 'react-canny'
import { faSparkles } from '@fortawesome/pro-light-svg-icons'
import { IconButton } from '@mui/material'

import FaIcon from '../../shared/FaIcon/FaIcon'

const ProductUpdates = ({ iconStyles }) => {
  const appId = process.env.REACT_APP_CANNY_APP_ID

  return (
    <CannyProvider appId={appId}>
      <CannyChangelog type='button' align='top' position='left' component='div'>
        <IconButton size='large' color='inherit' sx={{ ...iconStyles, p: 1 }}>
          <FaIcon icon={faSparkles} size='lg' width={20} />
        </IconButton>
      </CannyChangelog>
    </CannyProvider>
  )
}

export default memo(ProductUpdates)
