import { mergeToNewObject } from '../../../util/object.helpers'
import { EXCHANGE_SETTINGS_ACTION } from '../../../constants/actions'
import { buildSuccessMessages } from '../../../util/reducer.helpers'

export const exchangeSettingsProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case EXCHANGE_SETTINGS_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case EXCHANGE_SETTINGS_ACTION.ON_SAVE_SETTINGS_SUCCESS:
      return mergeToNewObject(state, handleSaveSettingsResponse(payload, state))

    default:
      return state
  }
}

const INITIAL_STATE = {
  exchangeValue: '',
  programUrl: '',
  catalogueUrl: '',
  loginIdType: '',
  brandRequirements: '',
  additionalServices: '',

  exchangeValueTemp: '',
  programUrlTemp: '',
  catalogueUrlTemp: '',
  loginIdTypeTemp: '',
  brandRequirementsTemp: '',
  additionalServicesTemp: '',

  error: {},
  success: {},

  loading: false
}

export const EXCHANGE_SETTINGS_DISPATCH = {
  EXCHANGE_VALUE_TEMP: 'exchangeValueTemp',

  PROGRAM_URL_TEMP: 'programUrlTemp',

  CATALOGUE_URL_TEMP: 'catalogueUrlTemp',

  LOGIN_ID_TYPE_TEMP: 'loginIdTypeTemp',

  BRAND_REQUIREMENTS_TEMP: 'brandRequirementsTemp',

  ADDITIONAL_SERVICES_TEMP: 'additionalServicesTemp',

  ERROR: 'error',
  SUCCESS: 'success',
  LOADING: 'loading'
}

const handleSaveSettingsResponse = (payload, state) => {
  const {
    exchangeValue,
    programUrl,
    catalogueUrl,
    loginIdType,
    brandRequirements,
    additionalServices
  } = payload
  const {
    exchangeValue: exchangeValueTemp,
    programUrl: programUrlTemp,
    catalogueUrl: catalogueUrlTemp,
    loginIdType: loginIdTypeTemp,
    brandRequirements: brandRequirementsTemp,
    additionalServices: additionalServicesTemp
  } = state
  const prevDetails = {
    exchangeValueTemp,
    programUrlTemp,
    catalogueUrlTemp,
    loginIdTypeTemp,
    brandRequirementsTemp,
    additionalServicesTemp
  }
  const updatedDetails = {
    exchangeValueTemp: exchangeValue,
    programUrlTemp: programUrl,
    catalogueUrlTemp: catalogueUrl,
    loginIdTypeTemp: loginIdType,
    brandRequirementsTemp: brandRequirements,
    additionalServicesTemp: additionalServices
  }
  return {
    ...updatedDetails,
    exchangeValue,
    programUrl,
    catalogueUrl,
    loginIdType,
    brandRequirements,
    additionalServices,

    success: buildSuccessMessages(prevDetails, updatedDetails),
    error: {}
  }
}
