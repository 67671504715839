import React, { memo } from 'react'
import { FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material'

import { mergeToNewObject } from '../../../util/object.helpers'

const Checkbox = ({
  errorMessage,
  checked,
  onChange,
  checkBoxStyles,
  sx,
  ...restProps
}) => {
  const error = Boolean(errorMessage)
  return (
    <FormControlLabel
      {...restProps}
      control={
        <MuiCheckbox
          checked={checked}
          onChange={onChange}
          sx={{
            color: (theme) => error && theme.palette.error.main,
            ...checkBoxStyles
          }}
        />
      }
      sx={mergeToNewObject(
        {
          mb: 2,
          mt: 2,
          color: (theme) => error && theme.palette.error.main
        },
        sx
      )}
    />
  )
}

Checkbox.defaultProps = {
  color: 'secondary',
  variant: 'contained',
  size: 'large'
}

export default memo(Checkbox)
