import React from 'react'

import { ANALYTICS_URL, NFT_URL } from '../constants/navigation'
import { buildExchangeActiveUrl } from '../util/exchange.helpers'
import { percentageOfCompletion } from '../util/number.helpers'
import { MILESTONE_ANIMATION_KEYS } from '../constants/dashboard'

const MEMBERS_OPTION_DATA = {
  '1_10000': "1 - 10'000",
  '10000_100000': "10'000 - 100'000",
  '100000_1000000': "100'000 - 1M",
  '1000000_10000000': '1M - 10M',
  '10000000_0': '10M+'
}

const MEMBERS_OPTION_DATA_FILTER = {
  ALL: 'All',
  ...MEMBERS_OPTION_DATA
}

const buildMemberOptions = () => {
  return Object.keys(MEMBERS_OPTION_DATA).map((key) => ({
    value: key,
    label: MEMBERS_OPTION_DATA[key]
  }))
}

const buildMemberOptionsFilter = () => {
  return Object.keys(MEMBERS_OPTION_DATA_FILTER).map((key) => ({
    value: key,
    label: MEMBERS_OPTION_DATA_FILTER[key]
  }))
}

const getMemberLabelByValue = (value) => {
  const memberOption = MEMBERS_OPTION_DATA[value]
  return memberOption ? memberOption : ''
}

const calculateMemberMilestones = (
  activeMembershipsCount,
  exchangedCount,
  exchangedValue,
  nftsCount,
  t
) => {
  const ACTIVE_EXCHANGE_PARTNERSHIPS_3 = 3
  const RECORD_EXCHANGE_TRANSACTIONS_100 = 100
  const ACTIVE_EXCHANGE_PARTNERSHIPS_10 = 10
  const TOTAL_VALUE_EXCHANGED_1000 = 1000
  const TOTAL_VALUE_EXCHANGED_5000 = 5000
  const TOTAL_VALUE_EXCHANGED_20000 = 20000
  const TOTAL_NFTS_SUPPLY_100 = 100
  return [
    {
      id: MILESTONE_ANIMATION_KEYS.ACTIVE_EXCHANGE_PARTNERSHIPS_3,
      label: (
        <>
          <strong>{`${t('dashboard.mandatory')}: `}</strong>
          {t('dashboard.have-active-exchange-partnerships', {
            count: ACTIVE_EXCHANGE_PARTNERSHIPS_3
          })}
        </>
      ),
      mandatory: true,
      link: buildExchangeActiveUrl(),
      points: 50,
      completed: percentageOfCompletion(
        activeMembershipsCount,
        ACTIVE_EXCHANGE_PARTNERSHIPS_3
      )
    },
    {
      id: MILESTONE_ANIMATION_KEYS.RECORD_EXCHANGE_TRANSACTIONS_100,
      label: 'dashboard.record-exchange-transactions',
      link: ANALYTICS_URL,
      count: RECORD_EXCHANGE_TRANSACTIONS_100,
      points: 50,
      completed: percentageOfCompletion(
        exchangedCount,
        RECORD_EXCHANGE_TRANSACTIONS_100
      )
    },
    {
      id: MILESTONE_ANIMATION_KEYS.ACTIVE_EXCHANGE_PARTNERSHIPS_10,
      label: 'dashboard.have-active-exchange-partnerships',
      link: buildExchangeActiveUrl(),
      count: ACTIVE_EXCHANGE_PARTNERSHIPS_10,
      points: 50,
      completed: percentageOfCompletion(
        activeMembershipsCount,
        ACTIVE_EXCHANGE_PARTNERSHIPS_10
      )
    },
    {
      id: MILESTONE_ANIMATION_KEYS.TOTAL_NFTS_SUPPLY_100,
      label: 'dashboard.total-nfts-supply',
      link: NFT_URL,
      count: TOTAL_NFTS_SUPPLY_100,
      points: 50,
      completed: percentageOfCompletion(nftsCount, TOTAL_NFTS_SUPPLY_100)
    },
    {
      id: MILESTONE_ANIMATION_KEYS.TOTAL_VALUE_EXCHANGED_1000,
      label: 'dashboard.total-value-exchanged',
      link: ANALYTICS_URL,
      count: TOTAL_VALUE_EXCHANGED_1000,
      points: 50,
      completed: percentageOfCompletion(
        exchangedValue,
        TOTAL_VALUE_EXCHANGED_1000
      )
    },
    {
      id: MILESTONE_ANIMATION_KEYS.TOTAL_VALUE_EXCHANGED_5000,
      label: 'dashboard.total-value-exchanged',
      link: ANALYTICS_URL,
      count: TOTAL_VALUE_EXCHANGED_5000,
      points: 100,
      completed: percentageOfCompletion(
        exchangedValue,
        TOTAL_VALUE_EXCHANGED_5000
      )
    },
    {
      id: MILESTONE_ANIMATION_KEYS.TOTAL_VALUE_EXCHANGED_20000,
      label: 'dashboard.total-value-exchanged',
      link: ANALYTICS_URL,
      count: TOTAL_VALUE_EXCHANGED_20000,
      points: 200,
      completed: percentageOfCompletion(
        exchangedValue,
        TOTAL_VALUE_EXCHANGED_20000
      )
    }
  ]
}

export {
  buildMemberOptions,
  buildMemberOptionsFilter,
  getMemberLabelByValue,
  calculateMemberMilestones
}
