import React, { memo } from 'react'
import { Trans } from 'react-i18next'

import { EXCHANGE_MARKETPLACE_GUIDE_URL } from '../../../util/support.helpers'
import Link from '../link/Link'

const TextWithIntegrationGuide = ({ i18nKey }) => {
  return (
    <Trans i18nKey={i18nKey}>
      <Link
        isOutside
        label={<Trans i18nKey='exchange.this-guide' />}
        to={EXCHANGE_MARKETPLACE_GUIDE_URL}
      />
    </Trans>
  )
}

export default memo(TextWithIntegrationGuide)
