import { map, mergeMap, catchError, tap, filter } from 'rxjs/operators'
import { forkJoin, of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'

import { logger } from '../../util/log.helpers'
import {
  BRAND_ACTIONS,
  EXCHANGE_NFT_ACTION,
  EXCHANGE_PARTNER_ACTION,
  EXCHANGE_SETTINGS_ACTION,
  EXCHANGE_TERMS_ACTION,
  TOKEN_ACTIONS
} from '../../constants/actions'
import apiService from '../../services/api'
import { wrapUserAccessToken } from '../../util/auth.helpers'
import { commonParser } from '../../util/apiParser.helpers'
import {
  buildBuyNftParams,
  buildConnectExchangePartnerBody,
  buildPartnersQueryParams,
  buildWhitelistTokenTypesExchange,
  formatPartners,
  isExchangeFilterRefreshData
} from '../../util/exchange.helpers'
import {
  extractBrandId,
  extractMainProfile,
  extractPartnersProfile,
  extractTokenId
} from '../../util/epics.helpers'
import { trim } from '../../util/string.helpers'
import {
  extractTokenIds,
  extractBrandTokensFromMainData
} from '../../util/token.helpers'
import { TOKEN_TYPE } from '../../constants/token'
import { EXCHANGE_PARTNER_DISPATCH } from '../reducers/exchange/partners'
import { EXCHANGE_PARTNER_FILTER_OPTIONS } from '../../constants/exchange'
import {
  buildCreateUserSurveyData,
  formatUserSurveyToObject
} from '../../util/survey.helpers'

const handleSaveExchangeSettingsEpic = (action$, state$) =>
  action$.pipe(
    ofType(EXCHANGE_SETTINGS_ACTION.ON_SAVE_SETTINGS),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .saveExchangeSettings(
            extractSaveExchangeSettingsData(state$),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: EXCHANGE_SETTINGS_ACTION.ON_SAVE_SETTINGS_SUCCESS,
              payload: commonParser(response.data)
            })),
            catchError((error) =>
              of({
                type: EXCHANGE_SETTINGS_ACTION.ON_SAVE_SETTINGS_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

const handleOnChangePartnersFiltersEpic = (action$) =>
  action$.pipe(
    ofType(EXCHANGE_PARTNER_ACTION.CHANGE_STATE),
    filter(({ payload: { property } }) =>
      isExchangeFilterRefreshData(property)
    ),
    mergeMap(({ payload }) => {
      const canGetNfts =
        payload.property === EXCHANGE_PARTNER_DISPATCH.REWARD_TYPE &&
        payload.value === EXCHANGE_PARTNER_FILTER_OPTIONS.NFT
      const actions = [
        {
          type: EXCHANGE_PARTNER_ACTION.ON_GET_PARTNERS
        }
      ]
      if (!canGetNfts) {
        actions.push({ type: EXCHANGE_PARTNER_ACTION.ON_GET_WHITELIST })
      }
      return actions
    })
  )

const handleGetPartnersByFilterEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      EXCHANGE_PARTNER_ACTION.ON_GET_PARTNERS,
      TOKEN_ACTIONS.ON_CREATE_TOKEN_SUCCESS,
      BRAND_ACTIONS.ON_CREATE_BRAND_SUCCESS,
      EXCHANGE_PARTNER_ACTION.ON_CONNECT_EXCHANGE_SUCCESS,
      EXCHANGE_PARTNER_ACTION.ON_BULK_CONNECT_EXCHANGE_SUCCESS,
      EXCHANGE_PARTNER_ACTION.ON_BULK_CONNECT_EXCHANGE_FAILED,
      EXCHANGE_PARTNER_ACTION.ON_UPDATE_EXCHANGE_CONNECTION_SUCCESS
    ),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        getPartnersAndNftsData(state$, accessToken).pipe(
          map((response) => ({
            type: EXCHANGE_PARTNER_ACTION.ON_GET_PARTNERS_SUCCESS,
            payload: {
              exchangePartners: response,
              currentTokenId: extractTokenId(state$)
            }
          })),
          catchError((error) =>
            of({
              type: EXCHANGE_PARTNER_ACTION.ON_GET_PARTNERS_FAILED,
              payload: error
            })
          )
        )
      )
    )
  )

const getPartnersAndNftsData = (state, accessToken) => {
  const { rewardType } = extractPartnersProfile(state)
  const canGetAll = rewardType === EXCHANGE_PARTNER_FILTER_OPTIONS.ALL
  const canGetNfts = rewardType === EXCHANGE_PARTNER_FILTER_OPTIONS.NFT
  const brandId = extractBrandId(state)
  const sources = []
  if (!canGetNfts) {
    sources.push(
      apiService.getPotentialPartners(
        buildPartnersQueryParams(state),
        accessToken
      )
    )
  }
  if ((canGetAll || canGetNfts) && brandId) {
    sources.push(
      apiService.getTokens(brandId, accessToken, {
        can_sell_on_lad: true,
        type: TOKEN_TYPE.ERC_721
      })
    )
  }
  return forkJoin(sources).pipe(
    map((responseArray) =>
      responseArray.flatMap(({ response }) => commonParser(response.data))
    )
  )
}

const handleGetWhitelistByFilterEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      EXCHANGE_PARTNER_ACTION.ON_UPDATE_EXCHANGE_CONNECTION_SUCCESS,
      EXCHANGE_PARTNER_ACTION.ON_GET_WHITELIST,
      EXCHANGE_PARTNER_ACTION.ON_CONNECT_EXCHANGE_SUCCESS,
      EXCHANGE_PARTNER_ACTION.ON_BULK_CONNECT_EXCHANGE_SUCCESS,
      EXCHANGE_PARTNER_ACTION.ON_BULK_CONNECT_EXCHANGE_FAILED
    ),
    map(() => ({
      brandId: extractBrandId(state$),
      tokenId: extractTokenId(state$)
    })),
    filter(({ brandId, tokenId }) => brandId && tokenId),
    mergeMap(({ brandId }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getExchangeWhitelist(
            brandId,
            buildPartnersQueryParams(state$),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: EXCHANGE_PARTNER_ACTION.ON_GET_WHITELIST_SUCCESS,
              payload: {
                exchangePartners: formatPartners(response.data),
                ownTokenIds: extractTokenIds(
                  extractBrandTokensFromMainData(
                    extractMainProfile(state$),
                    brandId
                  )
                )
              }
            })),
            catchError((error) =>
              of({
                type: EXCHANGE_PARTNER_ACTION.ON_GET_WHITELIST_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

const handleGetAllPartnersEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      EXCHANGE_PARTNER_ACTION.ON_UPDATE_EXCHANGE_CONNECTION_SUCCESS,
      EXCHANGE_PARTNER_ACTION.ON_CONNECT_EXCHANGE_SUCCESS,
      EXCHANGE_PARTNER_ACTION.ON_BULK_CONNECT_EXCHANGE_SUCCESS,
      EXCHANGE_PARTNER_ACTION.ON_BULK_CONNECT_EXCHANGE_FAILED
    ),
    map(() => ({
      brandId: extractBrandId(state$),
      tokenId: extractTokenId(state$)
    })),
    filter(({ brandId, tokenId }) => brandId && tokenId),
    mergeMap(({ brandId, tokenId }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getExchangeWhitelist(
            brandId,
            buildWhitelistTokenTypesExchange({
              tokenId
            }),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: EXCHANGE_PARTNER_ACTION.ON_GET_ALL_PARTNERS_SUCCESS,
              payload: {
                exchangePartners: formatPartners(response.data),
                ownTokenIds: extractTokenIds(
                  extractBrandTokensFromMainData(
                    extractMainProfile(state$),
                    brandId
                  )
                )
              }
            })),
            catchError((error) =>
              of({
                type: EXCHANGE_PARTNER_ACTION.ON_GET_ALL_PARTNERS_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

const handleConnectExchangePartnerEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      EXCHANGE_PARTNER_ACTION.ON_CONNECT_EXCHANGE,
      EXCHANGE_TERMS_ACTION.ON_SUBMIT_TERMS_SUCCESS
    ),
    mergeMap(({ payload }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .createExchangeConnection(
            extractBrandId(state$),
            buildConnectExchangePartnerBody(payload),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: EXCHANGE_PARTNER_ACTION.ON_CONNECT_EXCHANGE_SUCCESS,
              payload: response.data
            })),
            catchError((error) =>
              of({
                type: EXCHANGE_PARTNER_ACTION.ON_CONNECT_EXCHANGE_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

const handleBulkConnectExchangePartnersEpic = (action$, state$) =>
  action$.pipe(
    ofType(EXCHANGE_PARTNER_ACTION.ON_BULK_CONNECT_EXCHANGE),
    mergeMap(({ payload: { toTokenIds } }) =>
      wrapUserAccessToken((accessToken) =>
        bulkConnectExchangePartners(toTokenIds, state$, accessToken).pipe(
          tap((data) => logger(data)),
          map((response) => ({
            type: EXCHANGE_PARTNER_ACTION.ON_BULK_CONNECT_EXCHANGE_SUCCESS,
            payload: commonParser(response)
          })),
          catchError((error) =>
            of({
              type: EXCHANGE_PARTNER_ACTION.ON_BULK_CONNECT_EXCHANGE_FAILED,
              payload: error
            })
          )
        )
      )
    )
  )

const bulkConnectExchangePartners = (toTokenIds, state, accessToken) => {
  const brandId = extractBrandId(state)
  const fromTokenId = extractTokenId(state)
  const sources = toTokenIds.map((toTokenId) =>
    apiService.createExchangeConnection(
      brandId,
      buildConnectExchangePartnerBody({ fromTokenId, toTokenId }),
      accessToken
    )
  )
  return forkJoin(sources).pipe(
    map((responseArray) =>
      responseArray.flatMap(({ response }) => commonParser(response.data))
    )
  )
}

const handleUpdateExchangeConnectionEpic = (action$, state$) =>
  action$.pipe(
    ofType(EXCHANGE_PARTNER_ACTION.ON_UPDATE_EXCHANGE_CONNECTION),
    mergeMap(({ payload: { action, data, connection } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .updateExchangeConnection(
            extractBrandId(state$),
            connection?.tokenExchangeWhitelist?.id,
            data,
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: EXCHANGE_PARTNER_ACTION.ON_UPDATE_EXCHANGE_CONNECTION_SUCCESS,
              payload: { action, connection, data: commonParser(response.data) }
            })),
            catchError((error) =>
              of({
                type: EXCHANGE_PARTNER_ACTION.ON_UPDATE_EXCHANGE_CONNECTION_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

const handleBuyNftExchangeEpic = (action$, state$) =>
  action$.pipe(
    ofType(EXCHANGE_NFT_ACTION.ON_BUY),
    mergeMap(({ payload }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .buyNft(
            extractBrandId(state$),
            buildBuyNftParams(payload, extractTokenId(state$)),
            accessToken
          )
          .pipe(
            map(() => ({
              type: EXCHANGE_NFT_ACTION.ON_BUY_SUCCESS,
              payload
            })),
            catchError((error) =>
              of({
                type: EXCHANGE_NFT_ACTION.ON_BUY_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

const handleSubmitExchangeTermsEpic = (action$, state$) =>
  action$.pipe(
    ofType(EXCHANGE_TERMS_ACTION.ON_SUBMIT_TERMS),
    mergeMap(({ payload: { surveyId, response, ...restProps } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .submitUserSurvey(
            buildCreateUserSurveyData(surveyId, response, state$),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: EXCHANGE_TERMS_ACTION.ON_SUBMIT_TERMS_SUCCESS,
              payload: {
                data: formatUserSurveyToObject(response.data),
                ...restProps
              }
            })),
            catchError((err) =>
              of({
                type: EXCHANGE_TERMS_ACTION.ON_SUBMIT_TERMS_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

export const exchangeEpic = combineEpics(
  handleSaveExchangeSettingsEpic,
  handleOnChangePartnersFiltersEpic,
  handleGetPartnersByFilterEpic,
  handleGetWhitelistByFilterEpic,
  handleGetAllPartnersEpic,
  handleConnectExchangePartnerEpic,
  handleBulkConnectExchangePartnersEpic,
  handleUpdateExchangeConnectionEpic,
  handleBuyNftExchangeEpic,
  handleSubmitExchangeTermsEpic
)

const extractSaveExchangeSettingsData = (state) => {
  const exchangeReducer = state.value.exchangeReducer
  const {
    exchangeValueTemp,
    programUrlTemp,
    catalogueUrlTemp,
    loginIdTypeTemp,
    brandRequirementsTemp,
    additionalServicesTemp
  } = exchangeReducer.exchangeSettingsProfile
  return {
    exchange_value: exchangeValueTemp,
    program_url: trim(programUrlTemp),
    catalogue_url: trim(catalogueUrlTemp),
    login_id_type: loginIdTypeTemp,
    brand_requirements: trim(brandRequirementsTemp),
    additional_services: trim(additionalServicesTemp)
  }
}
