import React, { memo } from 'react'
import { AlertTitle, Stack } from '@mui/material'
import { Trans } from 'react-i18next'
import { faCreditCard } from '@fortawesome/pro-light-svg-icons'

import CustomAlert from '../CustomAlert/CustomAlert'

const TestingCardDetailsNote = () => {
  return (
    <CustomAlert icon={faCreditCard} sx={{ m: 0 }}>
      <>
        <AlertTitle>
          <strong>
            <Trans i18nKey='billing.testing-credit-card-info'></Trans>
          </strong>
        </AlertTitle>
        <Stack spacing={0.5} direction='column'>
          <span>
            <Trans i18nKey='billing.testing-credit-card-number'></Trans>
          </span>
          <span>
            <Trans i18nKey='billing.testing-credit-card-expiry'></Trans>
          </span>
          <span>
            <Trans i18nKey='billing.testing-credit-card-cvc'></Trans>
          </span>
        </Stack>
      </>
    </CustomAlert>
  )
}

export default memo(TestingCardDetailsNote)
