import {
  ANALYTICS_DATE_RANGE_FILTERS,
  ANALYTICS_INTERVAL_FILTERS,
  ANALYTICS_TAB_CONTENT_ID,
  ANALYTICS_TAB_TYPE
} from '../../constants/analytics'
import {
  EXCHANGE_TRANSACTION_TYPES,
  EXCHANGE_TRANSACTION_TYPE_COLORS,
  TRANSACTION_TYPES,
  TRANSACTION_TYPE_COLORS
} from '../../constants/transactionTypes'
import { extractAnalyticsFilterProfile } from '../epics.helpers'
import { buildDropdownList } from '../app/app.helpers'
import {
  getCleanedEndDate,
  getCleanedStartDate
  // getCurrentISOTime,
  // getDateSecondsSubtractedBy
} from '../date.helpers'
import { ANALYTICS_FILTER_DISPATCH } from '../../store/reducers/analytics/analyticsFilter'
import { COLOR_PALETTE } from '../../constants/theme'
import { ANALYTICS_URL } from '../../constants/navigation'

const buildDateRangeOptions = (t) => {
  return buildDropdownList(
    ANALYTICS_DATE_RANGE_FILTERS,
    'analytics.date-range-filters',
    t
  )
}

const buildPointsBankTransactionTypeOptions = (t) => {
  return buildDropdownList(
    TRANSACTION_TYPES,
    'analytics.points-bank-txn-type-filters',
    t
  )
}

const buildPointsExchangeTransactionTypeOptions = (t) => {
  return buildDropdownList(
    EXCHANGE_TRANSACTION_TYPES,
    'analytics.marketplace-txn-type-filters',
    t
  )
}

const buildGraphIntervalOptions = (t) => {
  return buildDropdownList(
    ANALYTICS_INTERVAL_FILTERS,
    'analytics.graph-interval-filters',
    t
  )
}

const analyticsFilterData = (state) => {
  return extractAnalyticsFilterProfile(state)
}

const fromAnalyticsTabIdToType = (tabId) => {
  switch (tabId) {
    case ANALYTICS_TAB_CONTENT_ID.POINTS_BANK:
      return ANALYTICS_TAB_TYPE.POINTS_BANK
    default:
      return ANALYTICS_TAB_TYPE.POINTS_EXCHANGE
  }
}

const fromAnalyticsTypeToTabId = (selectedTab) => {
  switch (selectedTab) {
    case ANALYTICS_TAB_TYPE.POINTS_BANK:
      return ANALYTICS_TAB_CONTENT_ID.POINTS_BANK
    default:
      return ANALYTICS_TAB_CONTENT_ID.POINTS_EXCHANGE
  }
}

const buildDateRangeQueryParams = ({ startDate, endDate }) => {
  //const buildDateRangeQueryParams = ({ startDate, endDate, dateRange }) => {
  // @TODO We are disabling hourly for now, once we fix the backend issue to handle huge hourly data we will enable it again.
  // if (dateRange === ANALYTICS_DATE_RANGE_FILTERS.LAST_24_HOURS) {
  //   return {
  //     from_time: getDateSecondsSubtractedBy(86400).toISOString(),
  //     to_time: getCurrentISOTime()
  //   }
  // }
  return {
    from_time: getCleanedStartDate(startDate),
    to_time: getCleanedEndDate(endDate)
  }
}

const isAnalyticsPointsBank = (type) => {
  return type === ANALYTICS_TAB_TYPE.POINTS_BANK
}

const isAnalyticsPointsExchange = (type) => {
  return type === ANALYTICS_TAB_TYPE.POINTS_EXCHANGE
}

const isPointsBankDateRangeFilter = ({ property }) => {
  return [
    ANALYTICS_FILTER_DISPATCH.DATE_RANGE,
    ANALYTICS_FILTER_DISPATCH.START_DATE,
    ANALYTICS_FILTER_DISPATCH.END_DATE
  ].includes(property)
}

const isPointsBankTransactionTypeFilter = ({ property }) => {
  return property === ANALYTICS_FILTER_DISPATCH.TRANSACTION_TYPE
}

const isPointsBankIntervalFilter = ({ property }) => {
  return property === ANALYTICS_FILTER_DISPATCH.INTERVAL
}

const isPointsExchangeTypeFilter = ({ property }) => {
  return property === ANALYTICS_FILTER_DISPATCH.EXCHANGE_TYPE
}

const isPointsTypeChangeFilter = ({ property }) => {
  return property === ANALYTICS_FILTER_DISPATCH.TYPE
}

const buildColorByTransactionTypeFilter =
  (selectedTransactionType) => (type) =>
    selectedTransactionType === TRANSACTION_TYPES.ALL ||
    selectedTransactionType === type
      ? TRANSACTION_TYPE_COLORS[type]
      : COLOR_PALETTE.GREY4

const buildColorByExchangeTypeFilter = (selectedTransactionType) => (type) =>
  selectedTransactionType === EXCHANGE_TRANSACTION_TYPES.ALL ||
  selectedTransactionType === type
    ? EXCHANGE_TRANSACTION_TYPE_COLORS[type]
    : COLOR_PALETTE.GREY4

const buildAnalyticsUrl = (activeTab) => {
  return `${ANALYTICS_URL}/${activeTab}`
}
export {
  buildDateRangeOptions,
  buildPointsBankTransactionTypeOptions,
  buildPointsExchangeTransactionTypeOptions,
  buildGraphIntervalOptions,
  analyticsFilterData,
  fromAnalyticsTabIdToType,
  fromAnalyticsTypeToTabId,
  buildDateRangeQueryParams,
  isPointsBankDateRangeFilter,
  isPointsBankTransactionTypeFilter,
  isPointsBankIntervalFilter,
  buildColorByTransactionTypeFilter,
  isAnalyticsPointsBank,
  isAnalyticsPointsExchange,
  isPointsTypeChangeFilter,
  buildColorByExchangeTypeFilter,
  isPointsExchangeTypeFilter,
  buildAnalyticsUrl
}
