import {
  BRAND_ACTIONS,
  FILE_UPLOAD_ACTIONS,
  NFTS_PROFILE_ACTIONS
} from '../../../constants/actions'
import { mergeToNewObject } from '../../../util/object.helpers'
import { FILE_UPLOAD_TYPE } from '../../../constants/input'

export const fileUploadProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FILE_UPLOAD_ACTIONS.ON_GET_PRESIGNED_URL:
      const { action } = payload
      return mergeToNewObject(state, {
        type: action
      })

    case BRAND_ACTIONS.ON_CREATE_BRAND_SUCCESS:
    case BRAND_ACTIONS.ON_UPDATE_BRAND_SUCCESS:
    case NFTS_PROFILE_ACTIONS.ON_CREATE_NFT_SUCCESS:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  type: FILE_UPLOAD_TYPE.UPDATE_BRAND
}
