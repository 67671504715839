import React, { memo } from 'react'
import { Box } from '@mui/material'

import Text from '../text/Text'

const StatusLabel = ({ label, color, size = 7, labelStyles }) => {
  const dotSize = `${size}px`
  return (
    <Box display='flex' alignItems='center'>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette[color]?.main,
          borderRadius: '100%',
          height: dotSize,
          width: dotSize
        }}
      />
      <Text
        label={label}
        variant='h6'
        color='textSecondary'
        noWrap
        sx={{ pl: 1 }}
        {...labelStyles}
      />
    </Box>
  )
}

export default memo(StatusLabel)
