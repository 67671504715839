import React, { memo } from 'react'
import { Grid } from '@mui/material'

import Input from '../inputs/Input'
import { LABEL_TEXT } from '../../../constants/messages'

const TokenDetails = ({
  tokenProfile: { name, symbol, id },
  t,
  isLoyaltyPoint
}) => {
  return (
    <>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <Input
          label={t(LABEL_TEXT.POINT_NAME)}
          value={name}
          size='small'
          disabled
        />
      </Grid>
      <Grid item lg={isLoyaltyPoint ? 3 : 4} md={12} sm={12} xs={12}>
        <Input
          label={t(LABEL_TEXT.POINT_SYMBOL)}
          value={symbol}
          size='small'
          disabled
        />
      </Grid>
      <Grid item lg={isLoyaltyPoint ? 2 : 4} md={12} sm={12} xs={12}>
        <Input
          label={t('developers.token-id')}
          value={id}
          size='small'
          disabled
        />
      </Grid>
    </>
  )
}

export default memo(TokenDetails)
