import { parseJson, stringifyJson } from './app/app.helpers'
import { mergeToNewObject } from './object.helpers'

const IS_WELCOME_SHOWN = 'isWelcomeShown'
const CURRENT_BRAND_AND_TOKEN_ID = 'currentBrandAndTokenId'
const MILESTONE_ANIMATION_STATUS = 'milestoneAnimationStatus'
const TXN_COLUMNS = 'txnColumns'

const setWelcomePopup = (isWelcomeShown) => {
  try {
    localStorage.setItem(IS_WELCOME_SHOWN, isWelcomeShown)
  } catch (e) {
    console.error(e)
  }
}

const getWelcomePopup = () => {
  return localStorage.getItem(IS_WELCOME_SHOWN) || false
}

const setBrandAndTokenIds = (brandAndTokenIds) => {
  try {
    localStorage.setItem(
      CURRENT_BRAND_AND_TOKEN_ID,
      stringifyJson(brandAndTokenIds)
    )
  } catch (e) {
    console.error(e)
  }
}

const getBrandAndTokenIds = () => {
  const data = localStorage.getItem(CURRENT_BRAND_AND_TOKEN_ID)
  return data ? parseJson(data) : ''
}

const removeBrandAndTokenIds = () => {
  localStorage.removeItem(CURRENT_BRAND_AND_TOKEN_ID)
}

const setMilestoneAnimationStatus = (tokenId, data) => {
  try {
    const updatedData = getMilestoneAnimationStatus()
    localStorage.setItem(
      MILESTONE_ANIMATION_STATUS,
      stringifyJson({
        ...updatedData,
        [tokenId]: mergeToNewObject(updatedData?.[tokenId], data)
      })
    )
  } catch (e) {
    console.error(e)
  }
}

const getMilestoneAnimationStatus = () => {
  const data = localStorage.getItem(MILESTONE_ANIMATION_STATUS)
  return data ? parseJson(data) : {}
}

const setTxnColumns = (columns) => {
  try {
    localStorage.setItem(TXN_COLUMNS, stringifyJson(columns))
  } catch (e) {
    console.error(e)
  }
}

const getTxnColumns = () => {
  const data = localStorage.getItem(TXN_COLUMNS)
  return data ? parseJson(data) : {}
}

export {
  setWelcomePopup,
  getWelcomePopup,
  setBrandAndTokenIds,
  getBrandAndTokenIds,
  setMilestoneAnimationStatus,
  getMilestoneAnimationStatus,
  removeBrandAndTokenIds,
  setTxnColumns,
  getTxnColumns
}
