const CONTAINER_MIN_HEIGHT = { minHeight: 'calc(100vh - 170px)' }

const CSS_TRUNCATE_TEXT = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
}

const TRANSPARENT_BLACK = 'rgba(0,0,0,0.1)'

const CSS_FLEX_ALIGNED = {
  display: 'flex',
  alignItems: 'center'
}

const CSS_FLEX_JUSTIFIED = {
  display: 'flex',
  justifyContent: 'center'
}

const CSS_FLEX_CENTERED = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const CSS_FLEX_COLUMN = {
  display: 'flex',
  flexDirection: 'column'
}

export {
  CONTAINER_MIN_HEIGHT,
  CSS_TRUNCATE_TEXT,
  TRANSPARENT_BLACK,
  CSS_FLEX_COLUMN,
  CSS_FLEX_ALIGNED,
  CSS_FLEX_CENTERED,
  CSS_FLEX_JUSTIFIED
}
