import React, { memo } from 'react'
import { Box, Grid } from '@mui/material'
import { faLandmark, faCoins } from '@fortawesome/pro-light-svg-icons'
import { Trans } from 'react-i18next'

import PageContainer from '../shared/PageContainer/PageContainer'
import ImportantNote from '../shared/ImportantNote/ImportantNote'
import { ANALYTICS_GUIDE_URL } from '../../util/support.helpers'
import DisplayAlerts from '../setup/common/DisplayAlerts'
import AnalyticsFilter from './AnalyticsFilter'
import {
  fromAnalyticsTypeToTabId,
  isAnalyticsPointsExchange
} from '../../util/analytics/analytics.helpers'
import CallToAction from './CallToAction'
import BaseCard from '../shared/BaseCard/BaseCard'
import PanelHeading from '../shared/PanelHeading/PanelHeading'
import TabContent from '../shared/TabContent/TabContent'
import { ANALYTICS_TAB_TYPE } from '../../constants/analytics'
import PointsBank from './PointsBank'
import DefaultText from '../shared/text/DefaultText'
import { INTEGRATION_TYPES_URL } from '../../constants/externalResources'
import Link from '../shared/link/Link'
import TransactionsData from './TransactionsData'
import MembersData from './MembersData'
import PointsExchange from './PointsExchange'
import { CSS_FLEX_JUSTIFIED } from '../../styles/common'
import Text from '../shared/text/Text'
import { buildExchangePotentialUrl } from '../../util/exchange.helpers'
import analyticsBlurred from '../../assets/images/backgrounds/LAD-analytics-blurred.png'

const Analytics = ({
  t,
  isDashboardSetupReady,
  hideAnalyticsNote,
  onCloseImportantNote,
  tokenProfile,
  analyticsFilterProfile,
  onChangeTab,
  onChangeAnalyticsFilter,
  weeksExchangeValuesDiff,
  billingProfile,
  brandProfile,
  isFullIntegration,
  transactionsTableProps,
  membersTableProps,
  pointsBankProfile,
  transactionsGraphProfile,
  exchangeTransactionsGraphProfile,
  pointsExchangeProfile,
  isPointsBank,
  activePartners
}) => {
  const { symbol, id } = tokenProfile

  const selectedTab = analyticsFilterProfile.type
  const showImportantNote = (
    <ImportantNote
      t={t}
      title='analytics.important-note'
      guideUrl={ANALYTICS_GUIDE_URL}
      onClose={onCloseImportantNote}
      closedImportantNote={hideAnalyticsNote}
    />
  )

  const title = t('sidebar.analytics')

  const buildHeadContent = () => {
    switch (selectedTab) {
      case ANALYTICS_TAB_TYPE.POINTS_EXCHANGE:
        return (
          <DefaultText
            label={t('analytics.marketplace-desc', {
              brandName: brandProfile.name
            })}
          />
        )

      case ANALYTICS_TAB_TYPE.POINTS_BANK:
        return (
          isFullIntegration && (
            <>
              <DefaultText
                label={
                  <Trans i18nKey='analytics.points-bank-desc'>
                    <Link
                      isOutside
                      label={t('integration.full-integration')}
                      to={INTEGRATION_TYPES_URL}
                    />
                  </Trans>
                }
              />
              <DefaultText
                label={t('analytics.points-bank-provides-view', {
                  brandName: brandProfile.name
                })}
              />
            </>
          )
        )

      default:
        return null
    }
  }

  const buildMainContent = () => {
    switch (selectedTab) {
      case ANALYTICS_TAB_TYPE.POINTS_EXCHANGE:
        return (
          <PointsExchange
            tokenProfile={tokenProfile}
            analyticsFilterProfile={analyticsFilterProfile}
            exchangeTransactionsGraphProfile={exchangeTransactionsGraphProfile}
            pointsExchangeProfile={pointsExchangeProfile}
            t={t}
          />
        )

      case ANALYTICS_TAB_TYPE.POINTS_BANK:
        return (
          isFullIntegration && (
            <PointsBank
              tokenProfile={tokenProfile}
              analyticsFilterProfile={analyticsFilterProfile}
              transactionsGraphProfile={transactionsGraphProfile}
              pointsBankProfile={pointsBankProfile}
              t={t}
            />
          )
        )

      default:
        return null
    }
  }

  return (
    <PageContainer title={title}>
      <DisplayAlerts
        isDashboardSetupReady={isDashboardSetupReady}
        tokenStatus={tokenProfile.status}
        subscribedPackage={billingProfile.subscribedPackage}
        t={t}
      />
      {!hideAnalyticsNote && showImportantNote}
      <Grid container spacing={4} padding={2}>
        <Grid item xs={12} sm={8}>
          <BaseCard>
            <PanelHeading title={title}>
              <TabContent
                selectedTab={fromAnalyticsTypeToTabId(selectedTab)}
                onChangeTab={onChangeTab}
                menuItems={tabMenuItems(t, isFullIntegration)}
                t={t}
                rootProps={{ mt: 1 }}
                content={buildHeadContent()}
              />
            </PanelHeading>
          </BaseCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <CallToAction
            weeksExchangeValuesDiff={weeksExchangeValuesDiff}
            t={t}
          />
        </Grid>
        {selectedTab === ANALYTICS_TAB_TYPE.POINTS_EXCHANGE &&
        activePartners === 0 ? (
          <Grid item xs={12} sx={{ position: 'relative' }}>
            <Box
              sx={{
                ...CSS_FLEX_JUSTIFIED,
                backgroundImage: `url(${analyticsBlurred})`,
                backgroundSize: 'contain',
                px: 40,
                pt: '84%',
                pb: '100%',
                textAlign: 'center',
                backgroundRepeat: 'no-repeat',
                width: '100%'
              }}
            />
            <Text
              label={
                <Trans i18nKey='analytics.no-active-partnership'>
                  <Link
                    label={t('analytics.connect-to-rewards-now')}
                    to={buildExchangePotentialUrl()}
                  />
                </Trans>
              }
              color='textprimary'
              variant='h4'
              sx={{
                position: 'absolute',
                top: '200px',
                textAlign: 'center',
                maxWidth: '600px',
                left: '50%',
                transform: 'translate(-50%, 0)'
              }}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <AnalyticsFilter
                {...analyticsFilterProfile}
                dispatchAction={onChangeAnalyticsFilter}
                onResetMemberSearch={membersTableProps.onResetSearchClick}
                onResetTransactionSearch={
                  transactionsTableProps.onResetSearchClick
                }
                t={t}
              />
            </Grid>
            <Grid item xs={12}>
              {buildMainContent()}
            </Grid>
            <Grid item xs={12}>
              <TransactionsData
                t={t}
                transactionsTableProps={transactionsTableProps}
                currentTokenId={id}
                currentBrandId={brandProfile.id}
                customTittle={
                  isAnalyticsPointsExchange(selectedTab) &&
                  t('analytics.exchange-transactions-tx')
                }
                showTxnForExchange={!isPointsBank}
              />
            </Grid>
            <Grid item xs={12}>
              <MembersData
                t={t}
                symbol={symbol}
                membersTableProps={membersTableProps}
                isFullIntegration={isFullIntegration}
              />
            </Grid>
          </>
        )}
      </Grid>
      {hideAnalyticsNote && showImportantNote}
    </PageContainer>
  )
}

export default memo(Analytics)

const tabMenuItems = (t, isFullIntegration) => [
  {
    value: ANALYTICS_TAB_TYPE.POINTS_EXCHANGE,
    label: 'sidebar.marketplace',
    icon: faCoins
  },
  {
    value: ANALYTICS_TAB_TYPE.POINTS_BANK,
    label: 'analytics.points-bank',
    icon: faLandmark,
    ...(!isFullIntegration && {
      disabled: true,
      tooltipText: t('analytics.not-accessible-to-light-integrations')
    })
  }
]
