import React, { memo } from 'react'
import { Box } from '@mui/material'

import { CSS_TRUNCATE_TEXT } from '../../../styles/common'
import Text from './Text'

const TitleCaptionText = ({
  title,
  caption,
  titleStyles,
  sx,
  ...restProps
}) => {
  return (
    <Box {...restProps}>
      <Text
        label={title}
        variant='h5'
        fontWeight={700}
        sx={{ ...CSS_TRUNCATE_TEXT, ...titleStyles }}
      />
      <Text
        label={caption}
        variant='caption'
        component='div'
        sx={{ mb: 0.7, ...CSS_TRUNCATE_TEXT, ...sx }}
      />
    </Box>
  )
}

export default memo(TitleCaptionText)
