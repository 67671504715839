import React, { memo } from 'react'
import { Typography, Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Chart from 'react-apexcharts'
import { faCoins } from '@fortawesome/pro-light-svg-icons'

import { formatTicks } from '../../util/string.helpers'
import DashboardCard from '../shared/DashboardCard/DashboardCard'
import FaIcon from '../shared/FaIcon/FaIcon'
import Text from '../shared/text/Text'
import ChartLabels from '../shared/ChartLabels/ChartLabels'
import TitleWithPopover from '../shared/TitleWithPopover/TitleWithPopover'
import {
  buildChartOptions,
  buildDonutChartOptions
} from '../../util/chart/chart.helpers'
import { sumOfArrayElements } from '../../util/app/app.helpers'
import { CSS_FLEX_CENTERED } from '../../styles/common'
import { formatCurrencyValue } from '../../util/number.helpers'

const TransferVolumeChart = ({
  title,
  helpText,
  data,
  colorRefMethod,
  chartLabels,
  customColors = []
}) => {
  const sumOfData = sumOfArrayElements(data)

  const hasNoData = sumOfData === 0

  const chartOptions = buildChartOptions(
    { ...useTheme(), customColors },
    {
      labels: Object.values(chartLabels),
      ...buildDonutChartOptions()
    },
    { hasNoData }
  )
  const chartData = hasNoData ? [100] : data
  return (
    <DashboardCard>
      <Box display='flex' alignItems='center'>
        <TitleWithPopover
          color='textSecondary'
          variant='body1'
          sx={{
            fontSize: 'h5.fontSize'
          }}
          title={title}
          helpText={helpText}
        />

        <Box
          sx={{
            ml: 'auto'
          }}
        >
          <Text
            variant='h2'
            fontWeight={700}
            sx={{
              marginBottom: '0'
            }}
            gutterBottom
            label={formatTicks(formatCurrencyValue(sumOfData))}
          />
        </Box>
      </Box>

      <Box
        sx={{
          mt: 1,
          position: 'relative',
          ...CSS_FLEX_CENTERED
        }}
      >
        <Chart
          options={chartOptions}
          series={chartData}
          type='donut'
          height={280}
        />
        <Typography
          color='textSecondary'
          sx={{
            position: 'absolute',
            left: '46%',
            top: '45%'
          }}
        >
          <FaIcon icon={faCoins} size='2x' />
        </Typography>
      </Box>
      <ChartLabels data={chartLabels} colorRefMethod={colorRefMethod} />
    </DashboardCard>
  )
}

export default memo(TransferVolumeChart)
