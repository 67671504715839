import React, { useState, memo } from 'react'
import { FormControl, InputAdornment, IconButton } from '@mui/material'
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons'

import CustomTextField from '../forms/customElements/CustomTextField'
import CustomFormLabel from '../forms/customElements/CustomFormLabel'
import FaIcon from '../FaIcon/FaIcon'
import {
  buildCustomHelperTextStyles,
  isInputSizeSmall
} from '../../../util/style.helpers'
import { QB_INPUT_PASSWORD, QB_INPUT_TEXT } from '../../../constants/input'
import { checkRulesAgainstValue } from '../../../util/validation.helpers'
import PopoverHelpText from '../PopoverHelpText/PopoverHelpText'

const Input = ({
  label,
  id,
  type,
  hide,
  bottomLabel,
  errorMessage,
  successMsg,
  onChange,
  inputProps,
  size,
  validate,
  startIcon,
  endIcon,
  helpText,
  fullWidth = true,
  ...restProps
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleOnChangeInput = ({ target }) => {
    const value = target.value
    if (onChange) {
      if (validate && validate.length > 0) {
        if (value) {
          const isValid = checkRulesAgainstValue(validate, value).length === 0
          isValid && onChange(value)
        } else {
          onChange('')
        }
      } else {
        onChange(value)
      }
    }
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const getCorrectType = () => {
    if (type === QB_INPUT_PASSWORD) {
      return showPassword ? QB_INPUT_TEXT : QB_INPUT_PASSWORD
    } else if (hide) {
      return QB_INPUT_PASSWORD
    }
    return type || QB_INPUT_TEXT
  }
  const error = Boolean(errorMessage)
  const iconSize = isInputSizeSmall(size) ? 'sm' : '1x'
  const endIconAction =
    type === QB_INPUT_PASSWORD ? (
      <IconButton
        aria-label='toggle password visibility'
        onClick={handleShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge='end'
      >
        {showPassword ? (
          <FaIcon icon={faEyeSlash} size={iconSize} />
        ) : (
          <FaIcon icon={faEye} size={iconSize} />
        )}
      </IconButton>
    ) : (
      endIcon
    )
  return (
    <FormControl fullWidth={fullWidth} error={error}>
      {label && (
        <CustomFormLabel htmlFor={id}>
          {label}
          {helpText && <PopoverHelpText label={helpText} />}
        </CustomFormLabel>
      )}
      <CustomTextField
        id={id}
        type={getCorrectType()}
        fullWidth={fullWidth}
        required
        error={error}
        helperText={errorMessage || successMsg || bottomLabel}
        FormHelperTextProps={buildCustomHelperTextStyles(successMsg)}
        inputProps={inputProps}
        InputProps={{
          startAdornment: startIcon && (
            <InputAdornment position='start'>{startIcon}</InputAdornment>
          ),
          endAdornment: endIconAction && (
            <InputAdornment position='end'>{endIconAction}</InputAdornment>
          )
        }}
        onChange={handleOnChangeInput}
        size={size}
        {...restProps}
      />
    </FormControl>
  )
}

Input.defaultProps = {
  autoComplete: 'off',
  required: true,
  size: '',
  variant: 'outlined'
}

export default memo(Input)
