import { mergeToNewObject } from '../../../util/object.helpers'
import { QB_AUTH_STATE } from '../../../constants/auth'
import { AUTH_ACTION, AUTH_LOGIN_ACTION } from './../../../constants/actions'

export const authProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case AUTH_ACTION.ON_GET_CURRENT_USER:
      return mergeToNewObject(state, { authState: QB_AUTH_STATE.CHECKING })

    case AUTH_ACTION.ON_GET_CURRENT_USER_SUCCESS:
    case AUTH_LOGIN_ACTION.ON_LOG_IN_SUCCESS:
      return mergeToNewObject(state, {
        authState: QB_AUTH_STATE.AUTHENTICATED,
        cognitoUser: payload
      })

    case AUTH_ACTION.ON_GET_CURRENT_USER_AFTER_UPDATE_SUCCESS:
      return mergeToNewObject(state, { cognitoUser: payload })

    case AUTH_ACTION.ON_GET_CURRENT_USER_FAILED:
    case AUTH_ACTION.ON_SIGN_OUT_SUCCESS:
      return mergeToNewObject(state, {
        authState: QB_AUTH_STATE.NOT_AUTHENTICATED,
        cognitoUser: {}
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  authState: QB_AUTH_STATE.NOT_AUTHENTICATED,
  cognitoUser: {},
  error: {}
}

export const AUTH_PROFILE_DISPATCH = {
  QB_AUTH_STATE: 'authState',
  COGNITO_USER: 'cognitoUser',
  ERROR: 'error'
}
