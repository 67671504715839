import { mergeToNewObject } from '../../../util/object.helpers'
import {
  ANALYTICS_FILTER_ACTION,
  ANALYTICS_POINTS_BANK_ACTION
} from '../../../constants/actions'
import {
  buildTransactionsRateData,
  buildTransactionsCountData,
  buildTransactionsValueData,
  buildTransferVolumeData
} from '../../../util/analytics/pointsBank.helpers'
import { buildIsProcessingState } from '../../../util/app/app.helpers'
import { addNumbers } from '../../../util/number.helpers'

export const pointsBankProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ANALYTICS_POINTS_BANK_ACTION.ON_RESET_STATE:
      return INITIAL_STATE

    case ANALYTICS_POINTS_BANK_ACTION.ON_GET_ACTIVE_TOTAL_MEMBERS_SUCCESS:
      return mergeToNewObject(
        state,
        handleActiveTotalMembers(payload),
        buildIsProcessingState(POINTS_BANK_DISPATCH.MEMBERS, false, state)
      )

    case ANALYTICS_POINTS_BANK_ACTION.ON_GET_ACTIVE_TOTAL_MEMBERS:
      return mergeToNewObject(
        state,
        buildIsProcessingState(POINTS_BANK_DISPATCH.MEMBERS, true, state)
      )

    case ANALYTICS_POINTS_BANK_ACTION.ON_GET_ACTIVE_TOTAL_MEMBERS_FAILED:
      return mergeToNewObject(
        state,
        buildIsProcessingState(POINTS_BANK_DISPATCH.MEMBERS, false, state)
      )

    case ANALYTICS_FILTER_ACTION.ON_POINTS_BANK_TRANSACTION_TYPE_CHANGE:
      return mergeToNewObject(state, handleFilterChange(payload, state))

    case ANALYTICS_POINTS_BANK_ACTION.ON_GET_TOKEN_STATS_SUCCESS:
      return mergeToNewObject(state, handleTokenStatsData(payload))

    default:
      return state
  }
}

export const POINTS_BANK_DISPATCH = {
  MEMBERS: 'members'
}

const INITIAL_STATE = {
  tokenStats: {
    rewardTxVolume: 0,
    redeemTxVolume: 0,
    exchangeInTxVolume: 0,
    exchangeOutTxVolume: 0,
    rewardTxCount: 0,
    redeemTxCount: 0,
    exchangeTxCount: 0
  },
  members: {
    active: 0,
    total: 0
  },
  transferVolume: {
    data: []
  },
  transactionsCount: {
    data: []
  },
  transactionsRate: {
    data: []
  },
  transactionsValue: {
    data: []
  },
  isProcessing: {
    [POINTS_BANK_DISPATCH.MEMBERS]: false
  }
}

const handleActiveTotalMembers = ({ activeMembers, totalMembers }) => {
  return {
    members: {
      active: activeMembers,
      total: totalMembers
    }
  }
}

const handleTokenStatsData = ({
  tokenStats: { exchangeInTxCount, exchangeOutTxCount, ...restStats },
  tokenProfile,
  analyticsFilter
}) => {
  const tokenStats = {
    ...restStats,
    exchangeTxCount: addNumbers(exchangeInTxCount, exchangeOutTxCount)
  }
  return {
    tokenStats,
    ...handleFilterChange({ tokenProfile, analyticsFilter }, { tokenStats })
  }
}

const handleFilterChange = (
  { tokenProfile, analyticsFilter: { transactionType } },
  { tokenStats }
) => {
  const {
    exchangeInTxVolume,
    exchangeOutTxVolume,
    rewardTxVolume,
    redeemTxVolume,
    exchangeTxCount,
    rewardTxCount,
    redeemTxCount
  } = tokenStats
  return {
    transferVolume: buildTransferVolumeData(
      {
        exchangeInTxVolume,
        exchangeOutTxVolume,
        rewardTxVolume,
        redeemTxVolume
      },
      transactionType
    ),
    transactionsCount: buildTransactionsCountData(
      {
        exchangeTxCount,
        rewardTxCount,
        redeemTxCount
      },
      transactionType
    ),
    transactionsRate: buildTransactionsRateData(tokenStats, transactionType),
    transactionsValue: buildTransactionsValueData(
      {
        exchangeInTxVolume,
        exchangeOutTxVolume,
        rewardTxVolume,
        redeemTxVolume
      },
      tokenProfile,
      transactionType
    )
  }
}
