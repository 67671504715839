import React, { memo } from 'react'

import CustomDialog from '../../shared/CustomDialog/CustomDialog'

const Confirmed = ({
  t,
  title = 'exchange.thank-you-for-your-feedback',
  description,
  rawDescription,
  onSubmit,
  submitLabel = 'common.close',
  isProcessing,
  additionalDesc
}) => {
  return (
    <CustomDialog
      title={t(title)}
      description={rawDescription ? rawDescription : t(description)}
      additionalDesc={additionalDesc}
      open
      submitProps={{ label: t(submitLabel), disabled: isProcessing }}
      onSubmit={onSubmit}
      t={t}
    />
  )
}

export default memo(Confirmed)
