import {
  faCircleCheck,
  faCirclePause,
  faClock
} from '@fortawesome/free-solid-svg-icons'
import { faCircleMinus } from '@fortawesome/pro-solid-svg-icons'

import { QB_DEFAULT_COUNTRY_VALUE } from '../constants/app'
import { QB_TOKEN_STATUS, TOKEN_TYPE } from '../constants/token'
import { getMemberLabelByValue } from '../data/members'
import { isExistsElementFromList, sortLabelsByASC } from './app/app.helpers'
import { countryLabelsByValues, getCountriesIsoArray } from './country.helpers'
import { formatCurrencyValue, floorNumber } from './number.helpers'
import { formatArrayToString, formatTicks, trim } from './string.helpers'
import { QB_LABEL_TEXT } from '../constants/messages'
import { deepEqualObjects } from './object.helpers'

const extractEstimatedMemberRange = (rangeString) => {
  if (rangeString) {
    return rangeString.split('_')
  }
  return [0, 0]
}

const parseEstimatedMemberRange = (
  estimatedMemberCountLower,
  estimatedMemberCountHigher
) => {
  return `${estimatedMemberCountLower}_${estimatedMemberCountHigher}`
}

const formatPointValue = (pointValue) => {
  return formatCurrencyValue(pointValue, 4)
}

const getSelectedToken = (tokens, tokenId) => {
  return tokens.filter((token) => token.id === tokenId)[0] || tokens?.[0]
}

const calculatePointValue = (value, rate) => {
  return formatCurrencyValue(value * rate) || 0
}

const compareLastWeekWithThisWeek = ({
  lastWeekExchangeTxVolume,
  thisWeekExchangeTxVolume
}) => {
  const calculation =
    ((thisWeekExchangeTxVolume - lastWeekExchangeTxVolume) /
      lastWeekExchangeTxVolume) *
    100
  return calculation !== Infinity ? calculation || 0 : 100
}

const isTokenActive = (status) => {
  return isExistsElementFromList(
    [QB_TOKEN_STATUS.ACTIVE, QB_TOKEN_STATUS.PAUSED, QB_TOKEN_STATUS.STOPPED],
    status
  )
}

const isTokenStatusActive = (tokenProfile) => {
  return tokenProfile?.status === QB_TOKEN_STATUS.ACTIVE
}

const isTokenPending = (status) => {
  return status === QB_TOKEN_STATUS.PENDING
}

const isTokenPaused = (status) => {
  return status === QB_TOKEN_STATUS.PAUSED
}

const isTokenRejected = (status) => {
  return status === QB_TOKEN_STATUS.REJECTED
}

const isTokenStopped = (status) => {
  return status === QB_TOKEN_STATUS.STOPPED
}

const displayMembersLabel = ({
  estimatedMemberCountLower,
  estimatedMemberCountHigher
}) => {
  return getMemberLabelByValue(
    parseEstimatedMemberRange(
      estimatedMemberCountLower,
      estimatedMemberCountHigher
    )
  )
}

const displayCountryLabels = (countries, t) => {
  return formatArrayToString(
    sortLabelsByASC(countryLabelsByValues(countries, t))
  )
}

const displayCustomCountryList = (countries, t) => {
  return (
    countries?.length > 0 &&
    displayCountryLabels(
      isAllCountriesExists(countries) ? getCountriesIsoArray() : countries,
      t
    )
  )
}

const calculateExchangeRateValue = (
  { offramp: offrampA, onramp: onrampA },
  { offramp: offrampB, onramp: onrampB, isFrom, isTypeMarket },
  { symbol, targetSymbol },
  t
) => {
  const exchangeRate =
    isFrom === false
      ? {
          rate:
            offrampB && onrampA ? formatCurrencyValue(offrampB / onrampA) : 0,
          symbol: targetSymbol,
          targetSymbol: symbol
        }
      : {
          rate:
            offrampA && onrampB ? formatCurrencyValue(offrampA / onrampB) : 0,
          symbol,
          targetSymbol
        }
  return `1 ${exchangeRate.symbol} ${
    isTypeMarket
      ? `= ${t('exchange.market')}`
      : showConditionalExchangeRate(
          formatTicks(exchangeRate.rate),
          exchangeRate.targetSymbol
        )
  }`
}

const calculateMaxPointsValue = (
  maxValue,
  partner,
  { symbol, onramp, offramp, type },
  t
) => {
  if (isTokenTypeCryptoFiat(type)) {
    return `= ${t('exchange.market')}`
  }
  const pointsValue = formatCurrencyValue(
    maxValue / (partner?.isFrom === false ? onramp : offramp)
  )
  return `= ${formatTicks(floorNumber(pointsValue))} ${symbol}`
}

const showConditionalExchangeRate = (exchangeRate, symbol) => {
  const minExchangeRate = 0.01
  return `${
    exchangeRate < minExchangeRate
      ? `< ${minExchangeRate}`
      : `= ${exchangeRate}`
  } ${symbol || ''}`
}

const isAllCountriesExists = (countries) => {
  return countries?.length > 0 && countries[0] === QB_DEFAULT_COUNTRY_VALUE
}

const isTokenTypeCryptoFiat = (type) => {
  return isExistsElementFromList([TOKEN_TYPE.CRYPTO, TOKEN_TYPE.FIAT], type)
}

const isTokenTypeHasStaticUrl = (type) => {
  return isExistsElementFromList(
    [
      TOKEN_TYPE.CRYPTO,
      TOKEN_TYPE.FIAT,
      TOKEN_TYPE.DONATION,
      TOKEN_TYPE.MERCHANDISE
    ],
    type
  )
}

const isTokenTypeNft = (type) => {
  return type === TOKEN_TYPE.ERC_721
}

const isTokenTypeDonation = (type) => {
  return type === TOKEN_TYPE.DONATION
}

const buildTokenNameSymbol = ({ name, symbol }) => {
  return name ? `${name} ${symbol ? `(${symbol})` : ''}` : ''
}

const formatBrandTokens = (response) => {
  const brandTokens = {}
  response.forEach(
    ({ brand, tokens }) =>
      (brandTokens[brand.id] = { tokens: sortTokensByStatus(tokens), brand })
  )
  return brandTokens
}

const buildTokenLabelByStatus = (status) => {
  switch (status) {
    case QB_TOKEN_STATUS.ACTIVE:
      return QB_LABEL_TEXT.TOKEN_ACTIVE
    case QB_TOKEN_STATUS.REJECTED:
      return QB_LABEL_TEXT.TOKEN_REJECTED
    case QB_TOKEN_STATUS.PAUSED:
      return QB_LABEL_TEXT.TOKEN_PAUSED
    case QB_TOKEN_STATUS.STOPPED:
      return QB_LABEL_TEXT.TOKEN_STOPPED
    default:
      return QB_LABEL_TEXT.TOKEN_PENDING
  }
}

const buildTokenChipColor = (status) => {
  switch (status) {
    case QB_TOKEN_STATUS.ACTIVE:
      return 'success'
    case QB_TOKEN_STATUS.REJECTED:
    case QB_TOKEN_STATUS.STOPPED:
      return 'error'
    case QB_TOKEN_STATUS.PAUSED:
      return 'orange'
    default:
      return 'warning'
  }
}

const buildTokenIconByStatus = (status) => {
  switch (status) {
    case QB_TOKEN_STATUS.ACTIVE:
      return faCircleCheck
    case QB_TOKEN_STATUS.REJECTED:
    case QB_TOKEN_STATUS.STOPPED:
      return faCircleMinus
    case QB_TOKEN_STATUS.PAUSED:
      return faCirclePause
    default:
      return faClock
  }
}

const extractTokenIds = (tokens) => {
  return tokens?.map((token) => token.id) || []
}

const extractBrandTokensFromMainData = (payload, brandId) => {
  if (brandId) {
    return payload?.brandTokens?.[brandId]?.tokens || []
  }
  return []
}

const isTokenInfoChanged = ({
  regions,
  countries,
  description,
  amountOfMembers,
  pointValue,
  binanceMirror,
  regionsTemp,
  countriesTemp,
  descriptionTemp,
  amountOfMembersTemp,
  pointValueTemp,
  binanceMirrorTemp
}) => {
  return !deepEqualObjects(
    {
      regionsTemp,
      countriesTemp,
      descriptionTemp,
      amountOfMembersTemp,
      pointValueTemp,
      binanceMirrorTemp
    },
    {
      regionsTemp: regions,
      countriesTemp: countries,
      descriptionTemp: description,
      amountOfMembersTemp: amountOfMembers,
      pointValueTemp: pointValue,
      binanceMirrorTemp: binanceMirror
    }
  )
}

const isCheckTokenCreated = (tokenProfile) => {
  return Boolean(tokenProfile?.id) && !isTokenRejected(tokenProfile?.status)
}

const getTokensAvailableBrandId = (data) => {
  if (data) {
    const brandTokens = Object.values(data)
    if (brandTokens.length > 0) {
      for (let i = brandTokens.length - 1; i >= 0; i--) {
        if (brandTokens[i]?.tokens?.length > 0) {
          return brandTokens[i]?.brand?.id
        }
      }
    }
  }
  return null
}

const formatTokenStats = (response) => {
  const tokenStats = {}
  response.forEach(
    ({ filterBy, tokenStat }) => (tokenStats[filterBy] = { ...tokenStat })
  )
  return tokenStats
}

const sortTokensByStatus = (tokens) => {
  if (!tokens) {
    return []
  }
  const statuses = {
    [QB_TOKEN_STATUS.ACTIVE]: 1,
    [QB_TOKEN_STATUS.PENDING]: 2,
    [QB_TOKEN_STATUS.PAUSED]: 3,
    [QB_TOKEN_STATUS.STOPPED]: 4,
    [QB_TOKEN_STATUS.REJECTED]: 5
  }
  return tokens?.sort((a, b) => statuses[a.status] - statuses[b.status])
}

const tokenMetaDescription = (description) => {
  return {
    description: {
      encoding: 'text',
      data: trim(description)
    }
  }
}

const isTokenTypeCrypto = (type) => {
  return type === TOKEN_TYPE.CRYPTO
}

export {
  extractEstimatedMemberRange,
  parseEstimatedMemberRange,
  formatPointValue,
  calculatePointValue,
  isTokenActive,
  displayMembersLabel,
  displayCountryLabels,
  calculateExchangeRateValue,
  isAllCountriesExists,
  isTokenTypeCryptoFiat,
  isTokenTypeHasStaticUrl,
  isTokenTypeNft,
  isTokenTypeDonation,
  displayCustomCountryList,
  buildTokenNameSymbol,
  buildTokenLabelByStatus,
  buildTokenChipColor,
  buildTokenIconByStatus,
  isTokenPending,
  isTokenRejected,
  isTokenPaused,
  isTokenStopped,
  getSelectedToken,
  formatBrandTokens,
  extractTokenIds,
  extractBrandTokensFromMainData,
  isTokenStatusActive,
  isTokenInfoChanged,
  isCheckTokenCreated,
  getTokensAvailableBrandId,
  compareLastWeekWithThisWeek,
  formatTokenStats,
  calculateMaxPointsValue,
  sortTokensByStatus,
  tokenMetaDescription,
  isTokenTypeCrypto
}
