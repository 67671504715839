import React, { memo } from 'react'

import CustomRadioGroup from '../shared/inputs/CustomRadioGroup'
import { BNB_SMART_CHAIN_COST_PER_TXN } from '../../constants/app'
import { LABEL_TEXT } from '../../constants/messages'

const MirrorTransactionsField = ({ t, ...restFields }) => {
  return (
    <CustomRadioGroup
      fieldLabel={t(LABEL_TEXT.SELECT_PREFERRED_BLOCKCHAIN)}
      data={[
        {
          label: t('token-setup.qiibee-rewards-chain'),
          value: false
        },
        {
          label: t('token-setup.bnb-smart-chain', {
            cost: BNB_SMART_CHAIN_COST_PER_TXN
          }),
          value: true
        }
      ]}
      {...restFields}
    />
  )
}

export default memo(MirrorTransactionsField)
