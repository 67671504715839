import React, { memo } from 'react'
import { faQrcode } from '@fortawesome/pro-light-svg-icons'

import RewardMember from './RewardMember'
import { Grid, IconButton } from '@mui/material'
import Dropdown from '../../shared/inputs/Dropdown'
import { REWARD_MEMBER_DISPATCH } from '../../../store/reducers/members/rewardMember'
import { transWithArgs } from '../../../util/validation.helpers'
import { buildOptionsForLoyaltyPoint } from '../../../util/members/members.helpers'
import { formatTicks } from '../../../util/string.helpers'
import { calculatePointValue } from '../../../util/token.helpers'
import FaIcon from '../../shared/FaIcon/FaIcon'

const RewardExchangeMember = ({
  t,
  dispatchAction,
  rewardMemberProfile,
  activeToExchanges,
  onQrCodeClick,
  maxExchangeUSDValue,
  ...restProps
}) => {
  const { loyaltyPoint, error, amount } = rewardMemberProfile
  const getBottomLabel = () => {
    if (loyaltyPoint && amount) {
      return t('nft.usd-value', {
        value: formatTicks(
          calculatePointValue(amount, activeToExchanges[loyaltyPoint].onramp)
        )
      })
    }
    if (loyaltyPoint) {
      return t('exchange.config.max-value-may-not-exceed', {
        value: maxExchangeUSDValue
      })
    }
    return ''
  }
  return (
    <RewardMember
      title='reward.reward-member'
      description='reward.reward-member-desc'
      gridSize={4}
      loyaltyPoint={
        <Grid item md={4} sm={4} xs={12}>
          <Dropdown
            id={REWARD_MEMBER_DISPATCH.LOYALTY_POINT}
            label={t('sidebar.loyalty-point')}
            helpText={t('reward.loyalty-point-help-text')}
            options={buildOptionsForLoyaltyPoint(activeToExchanges)}
            onChange={({ target }) =>
              dispatchAction(REWARD_MEMBER_DISPATCH.LOYALTY_POINT, target.value)
            }
            value={loyaltyPoint}
            size='small'
            errorMessage={transWithArgs(
              t,
              error[REWARD_MEMBER_DISPATCH.LOYALTY_POINT]
            )}
          />
        </Grid>
      }
      amountProps={{
        helpText: t('reward.amount-of-points-help-text'),
        bottomLabel: getBottomLabel()
      }}
      accountProps={{
        helpText: t('reward.member-account-help-text'),
        endIcon: (
          <IconButton onClick={onQrCodeClick} edge='end'>
            <FaIcon icon={faQrcode} width={18} />
          </IconButton>
        )
      }}
      t={t}
      rewardMemberProfile={rewardMemberProfile}
      dispatchAction={dispatchAction}
      maxWidth='md'
      {...restProps}
    />
  )
}

export default memo(RewardExchangeMember)
