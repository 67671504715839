import React, { memo } from 'react'
import { Grid } from '@mui/material'

import { SIGN_UP_PROFILE_DISPATCH } from '../../store/reducers/auth/signup'
import Input from '../shared/inputs/Input'
import { QB_INPUT_EMAIL, QB_INPUT_PASSWORD } from '../../constants/input'
import { transWithArgs } from '../../util/validation.helpers'

const SignupInputContent = ({
  t,
  signupProfile: { firstName, lastName, email, error, password },
  dispatchAction
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Input
          id={SIGN_UP_PROFILE_DISPATCH.FIRST_NAME}
          label={t('sign-up.first-name')}
          errorMessage={transWithArgs(
            t,
            error[SIGN_UP_PROFILE_DISPATCH.FIRST_NAME]
          )}
          value={firstName}
          onChange={(value) =>
            dispatchAction(SIGN_UP_PROFILE_DISPATCH.FIRST_NAME, value)
          }
        />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Input
          id={SIGN_UP_PROFILE_DISPATCH.LAST_NAME}
          label={t('sign-up.last-name')}
          errorMessage={transWithArgs(
            t,
            error[SIGN_UP_PROFILE_DISPATCH.LAST_NAME]
          )}
          value={lastName}
          onChange={(value) =>
            dispatchAction(SIGN_UP_PROFILE_DISPATCH.LAST_NAME, value)
          }
        />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Input
          id={SIGN_UP_PROFILE_DISPATCH.EMAIL_ADDRESS}
          label={t('sign-up.email')}
          errorMessage={transWithArgs(
            t,
            error[SIGN_UP_PROFILE_DISPATCH.EMAIL_ADDRESS]
          )}
          value={email}
          onChange={(value) =>
            dispatchAction(SIGN_UP_PROFILE_DISPATCH.EMAIL_ADDRESS, value)
          }
          type={QB_INPUT_EMAIL}
        />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Input
          id={SIGN_UP_PROFILE_DISPATCH.PASSWORD}
          label={t('sign-up.password')}
          type={QB_INPUT_PASSWORD}
          errorMessage={transWithArgs(
            t,
            error[SIGN_UP_PROFILE_DISPATCH.PASSWORD]
          )}
          value={password}
          onChange={(value) =>
            dispatchAction(SIGN_UP_PROFILE_DISPATCH.PASSWORD, value)
          }
        />
      </Grid>
    </Grid>
  )
}

export default memo(SignupInputContent)
