import commaNumber from 'comma-number'

import { CURRENCY_DELIMITER } from '../constants/app'

const toLower = (text) => {
  return text ? text.toLowerCase() : ''
}

const toUpperFirstLetter = (text) => {
  if (!text) {
    return ''
  }
  const arr = text ? text.split(' ') : ''
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
  }
  const capText = arr ? arr.join(' ') : ''
  return capText ? capText : ''
}

const removeTicks = (str) => {
  if (!str) {
    return ''
  }
  return str
    .split('')
    .filter((el) => el !== "'")
    .join('')
}

const formatTicks = (num) => {
  if (num === undefined) {
    return ''
  }
  return commaNumber(num, CURRENCY_DELIMITER)
}

const titleCase = (str) => {
  if (!str) {
    return ''
  }
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.replace(word[0], word[0].toUpperCase()))
    .join(' ')
}

const toUpper = (text) => {
  return text ? text.toUpperCase() : ''
}

const extractEmailFromUrl = (searchString) => {
  const searchDropFirstChar = searchString.slice(1)
  const options = searchDropFirstChar.split('&')

  return options.reduce((curr, next) => {
    const [key, value] = next.split('=')
    if (key === 'email') {
      return decodeURIComponent(value)
    } else {
      return curr
    }
  }, '')
}

const extractRewardCodeFromQR = (text) => {
  if (!text) {
    return ''
  }
  try {
    const url = new URL(text)
    return url.searchParams.get('code')
  } catch (e) {
    return text
  }
}

const removeProtocolFromURL = (url) => {
  return url ? url.replace(/(^\w+:|^)\/\//, '') : ''
}

const formatArrayToString = (data) => {
  if (data && data.length > 0) {
    return data.join(', ')
  }
  return ''
}

const toLowerCamelCase = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

const toKebabCase = (s) => {
  return s
    ? s
        .match(
          /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
        )
        .map((x) => toLower(x))
        .join('-')
    : ''
}

const stringToBool = (label) => {
  return label === 'true'
}

const trim = (string) => {
  return string ? string.trim() : ''
}

const truncateTextForHash = (text) => {
  if (!text) {
    return ''
  }
  return `${text.substring(0, 5)}...${text.substring(text.length - 3)}`
}

const truncateText = (text, length = 62) => {
  if (!text) {
    return ''
  }
  return length && text.length > length
    ? `${text.substring(0, length)}...`
    : text
}

const formatToCSVText = (text) => {
  return text ? `"${text}"` : ''
}

export {
  toLower,
  formatTicks,
  removeTicks,
  titleCase,
  toUpper,
  extractEmailFromUrl,
  extractRewardCodeFromQR,
  toLowerCamelCase,
  toKebabCase,
  removeProtocolFromURL,
  formatArrayToString,
  stringToBool,
  trim,
  toUpperFirstLetter,
  truncateTextForHash,
  formatToCSVText,
  truncateText
}
