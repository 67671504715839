import React, { memo } from 'react'

import SetupText from '../SetupText/SetupText'
import CustomAlert from './CustomAlert'

const UserAlert = ({ i18nKey, label, to, linkProps, ...restProps }) => {
  return (
    <CustomAlert {...restProps}>
      <SetupText
        i18nKey={i18nKey}
        label={label}
        to={to}
        linkProps={{
          ...linkProps,
          color: 'inherit',
          fontSize: 'inherit !important',
          fontWeight: 'inherit !important'
        }}
      />
    </CustomAlert>
  )
}

UserAlert.defaultProps = {
  variant: 'filled',
  severity: 'info'
}

export default memo(UserAlert)
