import React, { memo } from 'react'
import { Grid } from '@mui/material'

import PersonalInfo from './PersonalInfo'
import ChangeEmail from './ChangeEmail'
import ChangePassword from './ChangePassword'
import MoreInfo from './MoreInfo'
import PageContainer from '../shared/PageContainer/PageContainer'
import { SOCIAL_MEDIA } from '../../constants/app'
import DisplayEmailAddress from './DisplayEmailAddress'

const MyAccount = ({
  accountProfile,
  dispatchAction,
  updatePersonalInfo,
  changeEmailAddress,
  changePassword,
  signedUpVia,
  t
}) => {
  return (
    <PageContainer title={t('header.account-settings')}>
      <Grid container>
        <Grid item xs={12} padding={2}>
          <Grid container spacing={0} justifyContent='center'>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <PersonalInfo
                    accountProfile={accountProfile}
                    dispatchAction={dispatchAction}
                    onFormSubmit={updatePersonalInfo}
                    t={t}
                  />
                </Grid>
                <Grid item xs={12}>
                  {signedUpVia === SOCIAL_MEDIA.GOOGLE ? (
                    <DisplayEmailAddress
                      email={accountProfile.email}
                      signedUpVia={signedUpVia}
                      t={t}
                    />
                  ) : (
                    <ChangeEmail
                      accountProfile={accountProfile}
                      dispatchAction={dispatchAction}
                      onFormSubmit={changeEmailAddress}
                      t={t}
                    />
                  )}
                </Grid>
                {!signedUpVia && (
                  <Grid item xs={12}>
                    <ChangePassword
                      accountProfile={accountProfile}
                      dispatchAction={dispatchAction}
                      onFormSubmit={changePassword}
                      t={t}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <MoreInfo t={t} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  )
}

export default memo(MyAccount)
