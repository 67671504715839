import { of } from 'rxjs'

import { containsValues, toUrlQueryParams } from '../util/object.helpers'
import { APP_CONFIG } from '../constants/appConfig'
import { HTTP_METHOD } from '../constants/api'
import { withIntercept } from '../util/app/api.helpers'

const USERS = '/users'
const TOKENS = '/tokens'
const TRANSACTIONS = '/transactions'
const BRANDS = '/brands'
const NFTS = '/nfts'
const TOKEN_EXCHANGE_WHITELIST = '/token-exchange-whitelist'
const API_KEYS = '/api-keys'
const ACCESS_CONTROL = '/access-control'
const CHECKOUT_SESSION = '/checkout-session'
const BILLING_PORTAL = '/billing-portal'
const SUBSCRIPTIONS = '/subscriptions'
const INVOICENINJA = '/invoiceninja'
const ANALYTICS = '/analytics'
const DOWNLOAD_REPORT = '/csv-email'
const PREFETCH = '/prefetch'
const CANNY_SSO = '/canny-sso/sso-token'
const CLAIMS = '/claims'
const SURVEYS = '/surveys'
const USER_SURVEYS = '/user-surveys'
const BRAND_USER_SURVEYS = '/brand-user-surveys'
const LIST_USER_SURVEYS = '/list-user-surveys'
const CODES = '/codes'

class ApiService {
  constructor() {
    this.usersUrl = APP_CONFIG.API_URL + USERS
    this.brandsUrl = APP_CONFIG.API_URL + BRANDS
    this.nftUrl = APP_CONFIG.API_URL + NFTS
    this.paymentGatewayUrl = APP_CONFIG.SLS_URL + INVOICENINJA
    this.analyticsUrl = APP_CONFIG.SLS_URL + ANALYTICS
    this.downloadReportEmail = APP_CONFIG.SLS_URL + DOWNLOAD_REPORT
  }

  // USERS
  getUserByClientId = (id, accessToken) =>
    withIntercept(HTTP_METHOD.GET, this.usersUrl + formatToParam(id), {
      accessToken
    })

  createUser = (userData, accessToken, recaptchaToken) =>
    withIntercept(
      HTTP_METHOD.POST,
      this.usersUrl,
      { accessToken, recaptchaToken },
      userData
    )

  updateUser = (id, updatedUserData, accessToken) => {
    return withIntercept(
      HTTP_METHOD.PATCH,
      this.usersUrl + formatToParam(id),
      { accessToken },
      { dashboard_user: updatedUserData }
    )
  }

  // BRANDS
  getBrands = (accessToken) => {
    return withIntercept(HTTP_METHOD.GET, this.brandsUrl, { accessToken })
  }

  createBrand = (data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.POST,
      this.brandsUrl,
      {
        accessToken
      },
      data
    )
  }

  updateBrand = ({ id, data }, accessToken) => {
    return withIntercept(
      HTTP_METHOD.PATCH,
      brandsPath(id),
      {
        accessToken
      },
      data
    )
  }

  // TOKENS
  getTokens = (brandId, accessToken, params) => {
    return withIntercept(
      HTTP_METHOD.GET,
      buildTokensUrl(brandId) + buildTypesQueryParams(params, true),
      { accessToken }
    )
  }

  getTokenById = (brandId, id, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      buildTokensUrl(brandId) + formatToParam(id),
      { accessToken }
    )
  }

  createToken = (brandId, data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.POST,
      buildTokensUrl(brandId),
      {
        accessToken
      },
      data
    )
  }

  updateToken = (brandId, tokenId, data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.PATCH,
      buildTokensUrl(brandId) + formatToParam(tokenId),
      { accessToken },
      data
    )
  }

  getTokenStats = ({ tokenId, ...restAttr }, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      `${getAnalyticsPath(
        'transactions-overview',
        tokenId
      )}${buildTypesQueryParams(restAttr, true)}`,
      { accessToken }
    )
  }

  getNftUsers = (brandId, tokenId, accessToken, params) => {
    return withIntercept(
      HTTP_METHOD.GET,
      brandsPath(brandId) +
        NFTS +
        formatToParam(tokenId) +
        USERS +
        `?${toUrlQueryParams(params)}`,
      { accessToken }
    )
  }

  redeemNftExclusiveContent = (brandId, tokenId, data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.POST,
      brandsPath(brandId) + TOKENS + formatToParam(tokenId) + CLAIMS,
      { accessToken },
      data,
      true
    )
  }

  transferNft = (brandId, data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.POST,
      getBrandTransactionsPath(brandId),
      { accessToken },
      data,
      true
    )
  }

  // TRANSACTIONS
  getTransactions = ({ brandId, tokenId, ...restAttr }, accessToken) => {
    // @TODO: passing token_id query param need to recheck once we have multiple tokens handler in the system.
    // token_id=${tokenId}&
    console.log(tokenId)
    return withIntercept(
      HTTP_METHOD.GET,
      `${getBrandTransactionsPath(brandId)}${buildTypesQueryParams(
        restAttr,
        true
      )}`,
      { accessToken }
    )
  }

  updateTransaction = (brandId, transactionId, data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.PATCH,
      `${getBrandTransactionsPath(brandId)}/${transactionId}`,
      { accessToken },
      data
    )
  }

  refundMiles = (brandId, transactionId, data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.PATCH,
      `${brandsPath(brandId)}/payments/miles-and-more/${transactionId}`,
      { accessToken },
      data
    )
  }

  deleteTransaction = (brandId, transactionId, accessToken) => {
    return withIntercept(
      HTTP_METHOD.DELETE,
      `${getBrandTransactionsPath(brandId)}/${transactionId}`,
      { accessToken }
    )
  }

  getTransactionsChart = ({ tokenId, ...restAttr }, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      `${getAnalyticsPath(
        'transactions-graph',
        tokenId
      )}${buildTypesQueryParams(restAttr, true)}`,
      { accessToken }
    )
  }

  getLiabilityTrendChart = ({ tokenId, ...restAttr }, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      `${getAnalyticsPath('liability-trend', tokenId)}${buildTypesQueryParams(
        restAttr,
        true
      )}`,
      { accessToken }
    )
  }

  // MEMBERS
  getMembers = (params, brandId, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      `${getBrandUsersPath(brandId)}?${toUrlQueryParams(params)}`,
      { accessToken }
    )
  }

  getMemberDetails = (userId, brandId, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      `${getBrandUsersPath(brandId)}${formatToParam(userId)}`,
      { accessToken }
    )
  }

  getMemberBalances = (brandId, authId, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      `${getBrandUsersPath(brandId)}${formatToParam(authId)}${formatToParam(
        'balances'
      )}`,
      { accessToken }
    )
  }

  memberTransaction = (brandId, data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.POST,
      getBrandTransactionsPath(brandId),
      { accessToken },
      data,
      true
    )
  }

  rewardPointsBank = (brandId, data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.POST,
      codesPath(brandId),
      {
        accessToken
      },
      data,
      true
    )
  }

  // TEAM MEMBERS
  getTeamMembers = (brandId, accessToken) => {
    return withIntercept(HTTP_METHOD.GET, getBrandAccessControlPath(brandId), {
      accessToken
    })
  }

  addTeamMember = (brandId, data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.POST,
      getBrandAccessControlPath(brandId),
      { accessToken },
      data,
      true
    )
  }

  removeTeamMember = (brandId, accessControlId, accessToken) => {
    return withIntercept(
      HTTP_METHOD.DELETE,
      getBrandAccessControlPath(brandId) + formatToParam(accessControlId),
      { accessToken }
    )
  }

  // FILE UPLOAD
  getFileUploadPreSignedUrl = (fileExtension, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      APP_CONFIG.API_URL +
        formatToParam(
          `file-uploads/presigned-url?file_extension=${fileExtension}`
        ),
      { accessToken }
    )
  }

  uploadFile = (url, fileContent) => {
    return withIntercept(HTTP_METHOD.PUT, url, { isFile: true }, fileContent)
  }

  // EXCHANGE
  saveExchangeSettings = (data, accessToken) => {
    console.log(accessToken)
    return of({ response: { data } })
    // return withIntercept(HTTP_METHOD.PATCH,
    //   this.brandsUrl,
    //   data,
    //   { accessToken }
    // )
  }

  getPotentialPartners = ({ countries, ...restParams }, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      `${APP_CONFIG.API_URL}/potential-partners${buildTypesQueryParams(
        restParams,
        true
      )}${formatListToQuery(countries, 'countries')}`,
      { accessToken }
    )
  }

  getExchangeWhitelist = (
    brandId,
    { countries, ...restParams },
    accessToken
  ) => {
    return withIntercept(
      HTTP_METHOD.GET,
      `${brandsPath(brandId)}${TOKEN_EXCHANGE_WHITELIST}${buildTypesQueryParams(
        restParams,
        true
      )}${formatListToQuery(countries, 'countries')}`,
      { accessToken }
    )
  }

  createExchangeConnection = (brandId, data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.POST,
      `${brandsPath(brandId)}${TOKEN_EXCHANGE_WHITELIST}`,
      { accessToken },
      data
    )
  }

  updateExchangeConnection = (brandId, tokenWhitelistId, data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.PATCH,
      `${brandsPath(brandId)}${TOKEN_EXCHANGE_WHITELIST}${formatToParam(
        tokenWhitelistId
      )}`,
      { accessToken },
      data,
      true
    )
  }

  buyNft = (brandId, data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.POST,
      buildTokensUrl(brandId),
      {
        accessToken
      },
      data,
      true
    )
  }

  // API KEYS
  getAPIKeys = (brandId, accessToken) => {
    return withIntercept(HTTP_METHOD.GET, buildAPIKeysUrl(brandId), {
      accessToken
    })
  }

  createAPIKey = (brandId, data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.POST,
      buildAPIKeysUrl(brandId),
      {
        accessToken
      },
      data,
      true
    )
  }

  deleteAPIKey = (brandId, apiKeyId, accessToken) => {
    return withIntercept(
      HTTP_METHOD.DELETE,
      buildAPIKeysUrl(brandId) + formatToParam(apiKeyId),
      { accessToken }
    )
  }

  // API CONFIGS
  getDashboardConfigs = (accessToken) => {
    return withIntercept(HTTP_METHOD.GET, APP_CONFIG.API_URL + PREFETCH, {
      accessToken
    })
  }

  // CANNY SSO
  getCannySSOToken = (data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.POST,
      APP_CONFIG.SLS_URL + CANNY_SSO,
      {
        accessToken
      },
      data,
      true
    )
  }

  // DOWNLOAD REPORT TO EMAIL
  downloadReportToEmail = (reportType, data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.POST,
      `${this.downloadReportEmail}${formatToParam(reportType)}`,
      { accessToken },
      data
    )
  }

  // BILLING
  generateCheckoutSession = (tokenId, params, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      `${this.paymentGatewayUrl}${CHECKOUT_SESSION}${formatToParam(
        tokenId
      )}?${toUrlQueryParams(params)}`,
      { accessToken }
    )
  }

  getPackageSubscriptions = (tokenId, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      `${this.paymentGatewayUrl}${SUBSCRIPTIONS}${formatToParam(tokenId)}`,
      { accessToken }
    )
  }

  updatePackageSubscription = (tokenId, subscriptionId, data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.PATCH,
      `${this.paymentGatewayUrl}${SUBSCRIPTIONS}${formatToParam(
        tokenId
      )}${formatToParam(subscriptionId)}`,
      { accessToken },
      data
    )
  }

  generateViewBillingSession = (tokenId, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      `${this.paymentGatewayUrl}${BILLING_PORTAL}${formatToParam(tokenId)}`,
      { accessToken }
    )
  }

  // ANALYTICS
  getAnalyticsData = (path, tokenId, params, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      `${getAnalyticsPath(path, tokenId)}${buildTypesQueryParams(
        params,
        true
      )}`,
      { accessToken }
    )
  }

  // SURVEYS
  getSurveys = (accessToken) => {
    return withIntercept(HTTP_METHOD.GET, surveysPath(), { accessToken })
  }

  submitUserSurvey = (data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.POST,
      userSurveysPath(),
      {
        accessToken
      },
      data
    )
  }

  updateUserSurvey = (userSurveyId, data, accessToken) => {
    return withIntercept(
      HTTP_METHOD.PATCH,
      userSurveysPath() + formatToParam(userSurveyId),
      { accessToken },
      data
    )
  }

  getUserSurveys = (accessToken) => {
    return withIntercept(HTTP_METHOD.GET, userSurveysPath(), { accessToken })
  }

  getBrandSurveys = (brandId, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      brandsPath(brandId) + BRAND_USER_SURVEYS,
      { accessToken }
    )
  }

  getListUserSurveys = (brandId, params, accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      `${brandsPath(brandId)}${LIST_USER_SURVEYS}?${toUrlQueryParams(params)}`,
      { accessToken }
    )
  }

  getUserSurveyAnalytics = (accessToken) => {
    return withIntercept(
      HTTP_METHOD.GET,
      getAnalyticsPath('lad-user-survey-responses'),
      { accessToken }
    )
  }
}

const apiService = new ApiService()

export default apiService

const formatToParam = (value) => {
  return value ? `/${value}` : ''
}

const buildTokensUrl = (brandId) => {
  return brandsPath(brandId) + TOKENS
}

const buildAPIKeysUrl = (brandId) => {
  return brandsPath(brandId) + API_KEYS
}

export const formatToQuery = (label, value) => {
  return `?${label}=${value}`
}

export const formatListToQuery = (
  list,
  paramName = 'types',
  startDelimiter = '&'
) => {
  if (!list) {
    return ''
  }
  let url = ''
  if (list.length > 0) {
    url += startDelimiter
    const separator = '&'
    const listLastIndex = list.length - 1
    list.forEach((type, index) => {
      url += `${paramName}[]=${type}${listLastIndex !== index ? separator : ''}`
    })
  }
  return url
}

const buildTypesQueryParams = (params, isPositionStarting = false) => {
  if (containsValues(params)) {
    const { types, exclude_token_to_types, ...restAttr } = params
    return `${isPositionStarting ? '?' : '&'}${toUrlQueryParams(
      restAttr
    )}${formatListToQuery(types)}${formatListToQuery(
      exclude_token_to_types,
      'exclude_token_to_types'
    )}`
  }
  return ''
}

const brandsPath = (brandId) => {
  return APP_CONFIG.API_URL + BRANDS + formatToParam(brandId)
}

const getBrandTransactionsPath = (brandId) => {
  return brandsPath(brandId) + TRANSACTIONS
}

const getBrandUsersPath = (brandId) => {
  return brandsPath(brandId) + USERS
}

const getBrandAccessControlPath = (brandId) => {
  return brandsPath(brandId) + ACCESS_CONTROL
}

const getAnalyticsPath = (path, tokenId = null) => {
  return `${APP_CONFIG.SLS_URL}${ANALYTICS}/${path}` + formatToParam(tokenId)
}

const userSurveysPath = () => {
  return APP_CONFIG.API_URL + USER_SURVEYS
}

const surveysPath = () => {
  return APP_CONFIG.API_URL + SURVEYS
}

const codesPath = (brandId) => {
  return brandsPath(brandId) + CODES
}
