import { API_KEY_ACTIONS } from '../../../constants/actions'
import { mergeToNewObject } from '../../../util/object.helpers'
import { extractCreateApiKeyErrors } from '../../../util/brand.helpers'
import { INTEGRATION_PROCESS_STEP } from '../../../constants/containerStates'

export const apiKeyFormProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case API_KEY_ACTIONS.ON_CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case API_KEY_ACTIONS.ON_CREATE_API_KEY_SUCCESS:
      return mergeToNewObject(state, {
        nameTemp: '',
        isProcessing: false,
        manageAction: INTEGRATION_PROCESS_STEP.CREATE_API_KEY
      })

    case API_KEY_ACTIONS.ON_CREATE_API_KEY_FAILED:
      return mergeToNewObject(state, {
        error: extractCreateApiKeyErrors(payload),
        isProcessing: false
      })

    case API_KEY_ACTIONS.ON_RESET_STATE:
      return INITIAL_STATE

    case API_KEY_ACTIONS.ON_CREATE_API_KEY:
    case API_KEY_ACTIONS.ON_DELETE_API_KEY:
      return mergeToNewObject(state, { isProcessing: true })

    case API_KEY_ACTIONS.ON_DELETE_API_KEY_SUCCESS:
      return mergeToNewObject(state, {
        nameTemp: '',
        isProcessing: false,
        manageAction: INTEGRATION_PROCESS_STEP.DELETE_API_KEY
      })

    case API_KEY_ACTIONS.ON_DELETE_API_KEY_FAILED:
      return mergeToNewObject(state, {
        isProcessing: false
      })

    default:
      return state
  }
}

export const API_KEY_PROFILE_DISPATCH = {
  NAME_TEMP: 'nameTemp',

  ERROR: 'error',
  SUCCESS: 'success',
  MANAGE_ACTION: 'manageAction'
}

const INITIAL_STATE = {
  nameTemp: '',

  error: {},
  success: {},
  isProcessing: false
}
