import React, { memo } from 'react'

import PanelHeading from '../PanelHeading/PanelHeading'
import FormWrapper from './FormWrapper'

const BaseForm = ({ title, description, customCss, ...restProps }) => {
  return (
    <PanelHeading title={title} description={description} customCss={customCss}>
      <FormWrapper {...restProps} />
    </PanelHeading>
  )
}

export default memo(BaseForm)
