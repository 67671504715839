import React, { memo } from 'react'

import FaIcon from '../FaIcon/FaIcon'
import CustomButton from './CustomButton'

const NextActionButton = ({ icon, iconStyles, sx, ...restProps }) => {
  return (
    <CustomButton
      {...restProps}
      sx={{
        position: 'absolute',
        minWidth: 32,
        width: 32,
        height: 32,
        borderRadius: '50%',
        top: 56,
        zIndex: 1,
        ...sx
      }}
      color='lightGrey'
      label={<FaIcon icon={icon} {...iconStyles} />}
    />
  )
}

export default memo(NextActionButton)
