import React, { memo } from 'react'
import { Grid } from '@mui/material'

import Brand from './Brand'
import { DASHBOARD_SETUP_STATE } from '../../../store/reducers/dashboard/dashboardSetup'
import Loading from '../../shared/Loading/Loading'
import FormWizard from '../../shared/FormWizard/FormWizard'
import LoyaltyPoint from './LoyaltyPoint'
import Status from './Status'
import DisplayAlerts from '../common/DisplayAlerts'
import Package from './Package'
import PageContainer from '../../shared/PageContainer/PageContainer'
import {
  isDashboardReady,
  isSetupPackage
} from '../../../util/dashboard.helpers'
import PackageCards from '../../shared/Billing/PackageCards'
import ViewTermsPrivacyLinks from '../../shared/Billing/ViewTermsPrivacyLinks'
import ReminderInfo from './ReminderInfo'

const DashboardSetup = ({
  brandProfile,
  changeBrandState,
  changeTokenState,
  onClickContinue,
  onClickLeftButton,
  dashboardSetupState,
  tokenProfile,
  billingProfile,
  changeBillingState,
  onSelectBillingPackage,
  isTokenCreated,
  t
}) => {
  const renderForm = () => {
    switch (dashboardSetupState) {
      case DASHBOARD_SETUP_STATE.LOADING:
        return <Loading />
      case DASHBOARD_SETUP_STATE.BRAND:
        return (
          <Brand
            brandProfile={brandProfile}
            dispatchAction={changeBrandState}
            onClickContinue={onClickContinue}
            t={t}
          />
        )
      case DASHBOARD_SETUP_STATE.LOYALTY_POINT:
        return (
          <LoyaltyPoint
            tokenProfile={tokenProfile}
            dispatchAction={changeTokenState}
            onClickContinue={onClickContinue}
            onClickLeftButton={onClickLeftButton}
            isTokenCreated={isTokenCreated}
            t={t}
          />
        )
      case DASHBOARD_SETUP_STATE.PACKAGE:
        return (
          <Package
            billingProfile={billingProfile}
            dispatchAction={changeBillingState}
            onClickLeftButton={onClickLeftButton}
            t={t}
          />
        )
      case DASHBOARD_SETUP_STATE.STATUS:
      case DASHBOARD_SETUP_STATE.READY:
        return (
          <Status
            tokenProfile={tokenProfile}
            billingProfile={billingProfile}
            onClickContinue={onClickContinue}
            t={t}
          />
        )
      default:
        return null
    }
  }
  return (
    <PageContainer title={t('sidebar.setup')}>
      <DisplayAlerts
        isDashboardSetupReady={isDashboardReady(dashboardSetupState)}
        tokenStatus={tokenProfile.status}
        subscribedPackage={billingProfile.subscribedPackage}
        t={t}
      />
      <ReminderInfo t={t} />
      <Grid container>
        <Grid item xs={12} padding={2}>
          <FormWizard
            selectedTrack={dashboardSetupState - 1}
            data={[
              { id: DASHBOARD_SETUP_STATE.BRAND, label: t('setup.brand') },
              {
                id: DASHBOARD_SETUP_STATE.LOYALTY_POINT,
                label: t('sidebar.loyalty-point')
              },
              {
                id: DASHBOARD_SETUP_STATE.PACKAGE,
                label: t('billing.package')
              },
              { id: DASHBOARD_SETUP_STATE.STATUS, label: t('common.status') }
            ]}
            body={renderForm()}
          />
        </Grid>
        {isSetupPackage(dashboardSetupState) && (
          <>
            <Grid item xs={12} p={2}>
              <PackageCards
                onSelectBillingPackage={onSelectBillingPackage}
                billingProfile={billingProfile}
                t={t}
              />
            </Grid>
            <Grid item xs={12} mt={2} mb={2}>
              <ViewTermsPrivacyLinks t={t} />
            </Grid>
          </>
        )}
      </Grid>
    </PageContainer>
  )
}

export default memo(DashboardSetup)
