import React, { memo } from 'react'

import { QB_LABEL_TEXT } from '../../../constants/messages'
import CustomButton from '../buttons/CustomButton'
import Information from '../Information/Information'

const Verification = ({
  verified,
  onSubmit,
  onCancel,
  message,
  t,
  submitBtnStyles
}) => {
  const title = verified
    ? QB_LABEL_TEXT.VERIFIED_TITLE
    : QB_LABEL_TEXT.VERIFY_TITLE
  const description = verified
    ? QB_LABEL_TEXT.VERIFIED_DESC
    : QB_LABEL_TEXT.VERIFY_DESC
  const submitLabel = verified
    ? QB_LABEL_TEXT.VERIFIED_BUTTON
    : QB_LABEL_TEXT.VERIFY_BUTTON
  return (
    <Information
      title={t(title)}
      description={t(description)}
      sx={{ minHeight: '100vh' }}
    >
      {message}
      <CustomButton
        {...submitBtnStyles}
        sx={{ mt: 2 }}
        onClick={onSubmit}
        label={t(submitLabel)}
      />
      {!verified && (
        <CustomButton
          sx={{ mt: 2 }}
          label={t(QB_LABEL_TEXT.CANCEL)}
          onClick={onCancel}
          variant=''
        />
      )}
    </Information>
  )
}

export default memo(Verification)
