import React, { memo } from 'react'
import { Box } from '@mui/material'

import FaIcon from '../shared/FaIcon/FaIcon'
import BaseCard from '../shared/BaseCard/BaseCard'
import Text from '../shared/text/Text'
import TitleWithPopover from '../shared/TitleWithPopover/TitleWithPopover'
import CustomFab from '../shared/inputs/CustomFab'
import { formatToDecimals } from '../../util/number.helpers'
import { formatTicks } from '../../util/string.helpers'

const TransactionRates = ({ data, ...restProps }) => {
  return (
    <BaseCard sx={{ p: 3, display: 'grid', gap: 1.5 }}>
      {data.map((rateValue) => (
        <RateCard key={rateValue.type} {...rateValue} {...restProps} />
      ))}
    </BaseCard>
  )
}

export default memo(TransactionRates)

const RateCard = ({
  t,
  value,
  icon,
  type,
  title,
  helpText,
  colorRefMethod
}) => {
  const color = colorRefMethod(type)
  return (
    <Box display='flex' alignItems='center'>
      <CustomFab
        sx={{
          backgroundColor: `${color}.light`,
          color: `${color}.main`,
          boxShadow: 'none',
          height: '45px',
          width: '45px',
          borderRadius: '10px',
          '&:hover': {
            backgroundColor: `${color}.light`
          }
        }}
      >
        <FaIcon icon={icon} size='lg' />
      </CustomFab>
      <Box
        sx={{
          ml: 2
        }}
      >
        <TitleWithPopover
          variant='h5'
          title={<>∅ {title}</>}
          helpText={helpText}
        />
        <Text
          color='textSecondary'
          variant='h6'
          label={t('analytics.average-per-transaction')}
        />
      </Box>
      <Box
        sx={{
          ml: 'auto'
        }}
      >
        <Text
          color={`${color}.main`}
          variant='h6'
          label={formatTicks(formatToDecimals(value || 0, 2))}
          fontWeight={500}
        />
      </Box>
    </Box>
  )
}
