import React, { forwardRef, memo } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

const Scrollbar = forwardRef(({ children, ...restProps }, ref) => {
  return (
    <PerfectScrollbar ref={ref} {...restProps}>
      {children}
    </PerfectScrollbar>
  )
})

Scrollbar.displayName = 'Scrollbar'

export default memo(Scrollbar)
