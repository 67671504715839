import React, { memo } from 'react'
import { Grid } from '@mui/material'

import { LABEL_TEXT } from '../../constants/messages'
import { TOKEN_PROFILE_DISPATCH } from '../../store/reducers/tokens/token'
import Text from '../shared/text/Text'
import { buildMemberOptions } from '../../data/members'
import Dropdown from '../shared/inputs/Dropdown'
import CountryRegionField from '../shared/CountryRegionField/CountryRegionField'
import MultilineField from '../shared/MultilineField/MultilineField'
import { transWithArgs } from '../../util/validation.helpers'

const LoyaltyPointOverviewFields = ({
  regionsTemp,
  countriesTemp,
  descriptionTemp,
  amountOfMembersTemp,
  success,
  error,
  dispatchAction,
  t
}) => {
  return (
    <>
      <Grid item xs={12}>
        <Text
          variant='body1'
          color='textprimary'
          label={t('token-setup.where-can-your-loyalty-point-be-used')}
        />
      </Grid>
      <CountryRegionField
        t={t}
        regions={regionsTemp}
        countries={countriesTemp}
        onChangeRegions={(regionsTemp) => {
          dispatchAction(TOKEN_PROFILE_DISPATCH.REGIONS_TEMP, regionsTemp)
        }}
        onChangeCountries={(countriesTemp) =>
          dispatchAction(TOKEN_PROFILE_DISPATCH.COUNTRIES_TEMP, countriesTemp)
        }
        errorMessageRegions={transWithArgs(
          t,
          error[TOKEN_PROFILE_DISPATCH.REGIONS_TEMP]
        )}
        errorMessageCountries={transWithArgs(
          t,
          error[TOKEN_PROFILE_DISPATCH.COUNTRIES_TEMP]
        )}
        successMsgRegions={transWithArgs(
          t,
          success[TOKEN_PROFILE_DISPATCH.REGIONS_TEMP]
        )}
        successMsgCountries={transWithArgs(
          t,
          success[TOKEN_PROFILE_DISPATCH.COUNTRIES_TEMP]
        )}
      />
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Text
          variant='body1'
          color='textprimary'
          label={t('token-setup.estimated-number-of-members')}
        />
        <Dropdown
          id={TOKEN_PROFILE_DISPATCH.AMOUNT_OF_MEMBERS_TEMP}
          label={t(LABEL_TEXT.AMOUNT_OF_MEMBERS)}
          options={buildMemberOptions()}
          onChange={({ target }) =>
            dispatchAction(
              TOKEN_PROFILE_DISPATCH.AMOUNT_OF_MEMBERS_TEMP,
              target.value
            )
          }
          value={amountOfMembersTemp}
          errorMessage={transWithArgs(
            t,
            error[TOKEN_PROFILE_DISPATCH.AMOUNT_OF_MEMBERS_TEMP]
          )}
          successMsg={transWithArgs(
            t,
            success[TOKEN_PROFILE_DISPATCH.AMOUNT_OF_MEMBERS_TEMP]
          )}
          size='small'
        />
      </Grid>
      <Grid item xs={12}>
        <Text
          variant='body1'
          color='textprimary'
          label={t('token-setup.loyalty-point-exchange-desc')}
        />
        <MultilineField
          id={TOKEN_PROFILE_DISPATCH.DESCRIPTION_TEMP}
          label={t(LABEL_TEXT.PROGRAM_DESCRIPTION)}
          value={descriptionTemp}
          errorMessage={transWithArgs(
            t,
            error[TOKEN_PROFILE_DISPATCH.DESCRIPTION_TEMP]
          )}
          successMsg={transWithArgs(
            t,
            success[TOKEN_PROFILE_DISPATCH.DESCRIPTION_TEMP]
          )}
          onChange={(value) =>
            dispatchAction(TOKEN_PROFILE_DISPATCH.DESCRIPTION_TEMP, value)
          }
          t={t}
        />
      </Grid>
    </>
  )
}

export default memo(LoyaltyPointOverviewFields)
