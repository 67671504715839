import React, { memo } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import {
  faInfoCircle,
  faSquareDollar,
  faSquareXmark
} from '@fortawesome/pro-light-svg-icons'

import BaseCard from '../shared/BaseCard/BaseCard'
import {
  CSS_FLEX_ALIGNED,
  CSS_FLEX_COLUMN,
  CSS_TRUNCATE_TEXT
} from '../../styles/common'
import Text from '../shared/text/Text'
import { formatTicks } from '../../util/string.helpers'
import CustomTooltip from '../shared/CustomTooltip/CustomTooltip'
import NftMedia from './NftMedia'
import SmallButton from '../shared/buttons/SmallButton'
import { CustomStatusDot } from '../shared/containers/shared'
import { isNftListedListed } from '../../util/nft.helpers'

const NftCard = ({ nft, brandName, onNftDetailsClick, onNftListAction, t }) => {
  const { name, supply, metadata } = nft
  const isListed = isNftListedListed(nft)
  return (
    <Grid item xs={6} sm={4} md={3} lg={3} xl={2} sx={{ width: '100%' }}>
      <BaseCard
        sx={{
          p: 0,
          width: '100%',
          ...CSS_FLEX_COLUMN
        }}
      >
        <NftMedia
          media={metadata?.media}
          coverImage={metadata?.legacy?.coverImage}
          onNftDetailsClick={() => onNftDetailsClick(nft)}
          sx={{
            borderRadius: '20px 20px 0 0',
            cursor: 'pointer'
          }}
        />
        <Grid container spacing={1} p={2}>
          <Grid item xs={12} sx={CSS_FLEX_COLUMN}>
            <Box sx={{ justifyContent: 'space-between', ...CSS_FLEX_ALIGNED }}>
              <Text
                label={name}
                variant='h5'
                fontWeight={700}
                sx={{
                  ...CSS_TRUNCATE_TEXT,
                  paddingRight: '5px'
                }}
              />
              <CustomTooltip
                label={t(`nft.${isListed ? 'listed' : 'created'}`)}
              >
                <CustomStatusDot color={isListed ? 'success' : 'primary'} />
              </CustomTooltip>
            </Box>
            <Text label={brandName} variant='caption' />
            <Text
              label={`${t('nft.total-supply')}: ${formatTicks(supply)}`}
              variant='caption'
            />
          </Grid>
        </Grid>
        <Box flexGrow={1} />
        <Stack
          spacing={1}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ alignItems: 'center', m: 2, mt: 0, justifyContent: 'stretch' }}
        >
          <SmallButton
            label={t('common.details')}
            onClick={() => onNftDetailsClick(nft)}
            icon={faInfoCircle}
          />
          <SmallButton
            label={t(`nft.${isListed ? 'delist' : 'list'}`)}
            onClick={() => onNftListAction(nft, isListed)}
            icon={isListed ? faSquareXmark : faSquareDollar}
            variant='outlined'
            color={isListed ? 'error' : 'success'}
          />
        </Stack>
      </BaseCard>
    </Grid>
  )
}

export default memo(NftCard)
