import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'

import ForgotPassword from '../components/forgotPassword/ForgotPassword'
import {
  changeForgotPasswordState,
  changeForgotPasswordInputAndErrorState,
  cleanForgotPasswordState,
  requestPasswordChange
} from '../store/actions/auth'
import requireUnauth from '../hoc/requireUnauth'
import { FORGOT_PASSWORD_PROFILE_DISPATCH } from '../store/reducers/auth/forgotPassword'
import { containsNoValues } from '../util/object.helpers'
import {
  checkValidations,
  requiredRule,
  emailRule
} from '../util/validation.helpers'

const ForgotPasswordContainer = ({
  forgotPasswordProfile,
  changeForgotPasswordState,
  changeForgotPasswordInputAndErrorState,
  cleanForgotPasswordState,
  requestPasswordChange
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      cleanForgotPasswordState()
    }
  }, [cleanForgotPasswordState])

  const handleSendLinkClick = () => {
    changeForgotPasswordState(FORGOT_PASSWORD_PROFILE_DISPATCH.ERROR, {})
    const { email } = forgotPasswordProfile
    const errors = checkValidations({
      email: { value: email, rules: [requiredRule, emailRule] }
    })
    if (containsNoValues(errors)) {
      requestPasswordChange(email)
    } else {
      changeForgotPasswordState(FORGOT_PASSWORD_PROFILE_DISPATCH.ERROR, errors)
    }
  }

  return (
    <ForgotPassword
      forgotPasswordProfile={forgotPasswordProfile}
      dispatchAction={changeForgotPasswordInputAndErrorState}
      onSendLinkClick={handleSendLinkClick}
      t={t}
    />
  )
}

const mapStateToProps = ({ authReducer }) => {
  const { forgotPasswordProfile } = authReducer
  return {
    forgotPasswordProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeForgotPasswordState,
      changeForgotPasswordInputAndErrorState,
      cleanForgotPasswordState,
      requestPasswordChange
    },
    dispatch
  )

export default requireUnauth(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer)
)
