import React, { memo } from 'react'
import { Grid } from '@mui/material'
import {
  faArrowRightArrowLeft,
  faMinus,
  faPlus
} from '@fortawesome/pro-light-svg-icons'

import TransactionsChart from './TransactionsChart'
import TransactionsUSDCards from './TransactionsUSDCards'
import TransferVolumeChart from './TransferVolumeChart'
import TransactionNumberChart from './TransactionNumberChart'
import TransactionRates from './TransactionRates'
import { displayCurrencyValue } from '../../util/number.helpers'
import { sumOfArrayElements } from '../../util/app/app.helpers'
import { formatTicks } from '../../util/string.helpers'
import UserStatistics from './UserStatistics'
import ValueTransferredChart from './ValueTransferredChart'
import { buildColorByTransactionTypeFilter } from '../../util/analytics/analytics.helpers'
import {
  TRANSACTION_TYPES,
  TRANSACTION_TYPE_COLORS
} from '../../constants/transactionTypes'
import LiabilityTrendChart from './LiabilityTrendChart'

const PointsBank = ({
  t,
  tokenProfile,
  transactionsGraphProfile: {
    transactionsChart,
    valueTransferredChart,
    liabilityTrendChart,
    isProcessing: transactionsGraphProcessing
  },
  pointsBankProfile: {
    members,
    transferVolume,
    transactionsCount,
    transactionsRate,
    transactionsValue,
    isProcessing: pointsBankProcessing
  },
  analyticsFilterProfile: { interval, transactionType }
}) => {
  const { name, symbol } = tokenProfile
  const valueTransferredChartData = valueTransferredChart.filteredData
  const sumOfTransferred = sumOfArrayElements(valueTransferredChartData)
  const colorRefMethod = buildColorByTransactionTypeFilter(transactionType)
  const chartLabels = {
    exchange: t('analytics.point-exchanged', { symbol }),
    reward: t('analytics.point-rewarded', { symbol }),
    redeem: t('analytics.point-redeemed', { symbol })
  }
  const transactionsValueData = transactionsValue.data
  const transactionsRateData = transactionsRate.data
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={8}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} lg={6}>
                <UserStatistics
                  title={t('analytics.active-all-members')}
                  helpText={t('analytics.members-desc')}
                  isProcessing={pointsBankProcessing.members}
                  value={`${formatTicks(members.active)} / ${formatTicks(
                    members.total
                  )}`}
                  label={t('analytics.active-members-total-members')}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <ValueTransferredChart
                  title={`${t('analytics.point-value-transferred', {
                    symbol
                  })}`}
                  helpText={t('analytics.point-value-transferred-desc', {
                    symbol,
                    name
                  })}
                  tooltipText={t('analytics.transferred-value')}
                  data={valueTransferredChartData}
                  labels={valueTransferredChart.labels}
                  heading={displayCurrencyValue(sumOfTransferred)}
                  hasNoData={sumOfTransferred === 0}
                  interval={interval}
                  isProcessing={transactionsGraphProcessing.transactionsChart}
                  t={t}
                />
              </Grid>
              <Grid item xs={12}>
                <TransactionsChart
                  filteredData={transactionsChart.filteredData}
                  isProcessing={transactionsGraphProcessing.transactionsChart}
                  title={t('analytics.transactions')}
                  helpText={t('analytics.point-transactions-desc', {
                    symbol,
                    name
                  })}
                  chartLabels={chartLabels}
                  colorRefMethod={colorRefMethod}
                  colorConfig={TRANSACTION_TYPE_COLORS}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container spacing={4} sx={{ height: 'calc(100% + 32px)' }}>
              <Grid item xs={12}>
                <TransferVolumeChart
                  data={transferVolume.data}
                  colorRefMethod={colorRefMethod}
                  chartLabels={chartLabels}
                  title={t('analytics.point-transfer-vol', { symbol })}
                  helpText={t('analytics.point-transfer-vol-desc', {
                    symbol,
                    name
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <TransactionNumberChart
                  data={transactionsCount.data}
                  colorRefMethod={colorRefMethod}
                  chartLabels={chartLabels}
                  title={t('analytics.point-transactions', { symbol })}
                  helpText={t('analytics.point-transactions-desc', {
                    symbol,
                    name
                  })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={8}>
            <TransactionsUSDCards
              colorRefMethod={colorRefMethod}
              data={[
                {
                  type: TRANSACTION_TYPES.EXCHANGE,
                  value: transactionsValueData[0],
                  icon: faArrowRightArrowLeft,
                  title: t(`analytics.point-value-exchange`, { symbol }),
                  helpText: t(`analytics.point-value-exchange-desc`, {
                    name,
                    symbol
                  })
                },
                {
                  type: TRANSACTION_TYPES.REWARD,
                  value: transactionsValueData[1],
                  icon: faPlus,
                  title: t(`analytics.point-value-reward`, { symbol }),
                  helpText: t(`analytics.point-value-reward-desc`, {
                    name,
                    symbol
                  })
                },
                {
                  type: TRANSACTION_TYPES.REDEEM,
                  value: transactionsValueData[2],
                  icon: faMinus,
                  title: t(`analytics.point-value-redeem`, { symbol }),
                  helpText: t(`analytics.point-value-redeem-desc`, {
                    name,
                    symbol
                  }),
                  noBorder: true
                }
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TransactionRates
              t={t}
              colorRefMethod={colorRefMethod}
              data={[
                {
                  type: TRANSACTION_TYPES.EXCHANGE,
                  value: transactionsRateData[0],
                  icon: faArrowRightArrowLeft,
                  title: t(`analytics.point-rate-exchange`, { symbol }),
                  helpText: t(`analytics.point-rate-exchange-desc`, {
                    name,
                    symbol
                  })
                },
                {
                  type: TRANSACTION_TYPES.REWARD,
                  value: transactionsRateData[1],
                  icon: faPlus,
                  title: t(`analytics.point-rate-reward`, { symbol }),
                  helpText: t(`analytics.point-rate-reward-desc`, {
                    name,
                    symbol
                  })
                },
                {
                  type: TRANSACTION_TYPES.REDEEM,
                  value: transactionsRateData[2],
                  icon: faMinus,
                  title: t(`analytics.point-rate-redeem`, { symbol }),
                  helpText: t(`analytics.point-rate-redeem-desc`, {
                    name,
                    symbol
                  })
                }
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <LiabilityTrendChart
          isProcessing={transactionsGraphProcessing.liabilityTrendChart}
          title={t('analytics.liability-trend')}
          helpText={t('analytics.liability-trend-desc')}
          yAxisLabel={t('analytics.program-liability')}
          {...liabilityTrendChart}
        />
      </Grid>
    </Grid>
  )
}

export default memo(PointsBank)
