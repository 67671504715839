import React, { memo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Link as MuiLink } from '@mui/material'

const Link = ({
  label,
  to,
  fontWeight,
  onClick,
  isButton,
  isOutside,
  isEmail,
  ...restProps
}) => {
  const defaultLinksAttrs = { underline: 'always' }
  if (isOutside || isEmail) {
    return (
      <MuiLink
        href={isEmail ? `mailto:${to}` : to}
        target='_blank'
        rel='noopener noreferrer'
        fontWeight={fontWeight}
        {...defaultLinksAttrs}
        {...restProps}
      >
        {label || to}
      </MuiLink>
    )
  } else {
    if (isButton) {
      return (
        <MuiLink
          component='button'
          onClick={onClick}
          fontWeight={fontWeight}
          {...defaultLinksAttrs}
          {...restProps}
        >
          {label}
        </MuiLink>
      )
    } else {
      return (
        <MuiLink
          component={RouterLink}
          to={to}
          fontWeight={fontWeight}
          {...defaultLinksAttrs}
          {...restProps}
        >
          {label}
        </MuiLink>
      )
    }
  }
}

Link.defaultProps = {
  fontWeight: 400,
  display: 'inline-block',
  color: 'primary.main'
}

export default memo(Link)
