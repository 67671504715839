import React, { memo } from 'react'
import { Trans } from 'react-i18next'
import { Grid } from '@mui/material'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import PageContainer from '../shared/PageContainer/PageContainer'
import { isDashboardReady } from '../../util/dashboard.helpers'
import DisplayAlerts from '../setup/common/DisplayAlerts'
import BaseCard from '../shared/BaseCard/BaseCard'
import PanelHeading from '../shared/PanelHeading/PanelHeading'
import DefaultText from '../shared/text/DefaultText'
import CustomButton from '../shared/buttons/CustomButton'
import FaIcon from '../shared/FaIcon/FaIcon'
import { LABEL_TEXT } from '../../constants/messages'
import Dropdown from '../shared/inputs/Dropdown'
import SetupRequiredAction from '../shared/Dashboard/SetupRequiredAction'
import { buildNftSortingOptions, buildNftStatusOptions } from '../../data/nfts'
import ImportantNote from '../shared/ImportantNote/ImportantNote'
import { NFT_GUIDE_URL } from '../../util/support.helpers'
import Link from '../shared/link/Link'
import { LWA_API_URL } from '../../constants/externalResources'
import NftCard from './NftCard'
import { NFTS_LIST_PROFILE_DISPATCH } from '../../store/reducers/nfts/nftsList'
import CardListGrid from '../shared/containers/CardListGrid'
import CardGrid from '../shared/containers/CardGrid'
import { openExternalUrl } from '../../util/app/app.helpers'
import ButtonsGroup from '../shared/buttons/ButtonsGroup'

const Nfts = ({
  tokenProfile,
  dashboardSetupState,
  onCreateNewNft,
  dispatchAction,
  onClickSetup,
  onCloseImportantNote,
  hideNftNote,
  brandName,
  nftsListProfile,
  onNftDetailsClick,
  onNftListAction,
  t
}) => {
  const title = t('nft.nft-title')
  const { filteredNfts, status, sorting } = nftsListProfile
  const setupReady = isDashboardReady(dashboardSetupState)
  const showImportantNote = (
    <ImportantNote
      t={t}
      title='nft.important-note'
      guideUrl={NFT_GUIDE_URL}
      onClose={onCloseImportantNote}
      closedImportantNote={hideNftNote}
    />
  )
  return (
    <PageContainer title={title}>
      <DisplayAlerts
        isDashboardSetupReady={setupReady}
        tokenStatus={tokenProfile.status}
        t={t}
      />
      {!hideNftNote && showImportantNote}
      <CardGrid>
        <Grid item xs={12}>
          <BaseCard>
            <PanelHeading title={title}>
              {!setupReady ? (
                <SetupRequiredAction
                  description={t('nft.not-completed-setup')}
                  submitLabel={t('developers.start-setup')}
                  onClickSetup={onClickSetup}
                  t={t}
                />
              ) : (
                <>
                  <DefaultText label={t('nft.create-manage-your-nfts')} />
                  <DefaultText
                    sx={{ mt: 0, mb: '5px' }}
                    label={
                      <Trans i18nKey='nft.list-shows-your-nfts'>
                        <Link
                          isOutside
                          label={t('nft.qiibee-api')}
                          to={LWA_API_URL}
                        />
                      </Trans>
                    }
                  />
                  <Grid
                    container
                    sx={{ display: 'flex', alignItems: 'flex-end' }}
                  >
                    <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
                      <Grid item xs={12} md={4} sx={{ pr: 2 }}>
                        <Dropdown
                          id={NFTS_LIST_PROFILE_DISPATCH.SORTING}
                          label={t(LABEL_TEXT.SORTING)}
                          options={buildNftSortingOptions(t)}
                          onChange={({ target }) =>
                            dispatchAction(
                              NFTS_LIST_PROFILE_DISPATCH.SORTING,
                              target.value
                            )
                          }
                          value={sorting}
                          size='small'
                        />
                      </Grid>
                      <Grid item xs={12} md={4} sx={{ pr: 2 }}>
                        <Dropdown
                          id={NFTS_LIST_PROFILE_DISPATCH.STATUS}
                          label={t(LABEL_TEXT.STATUS)}
                          options={buildNftStatusOptions(t)}
                          onChange={({ target }) =>
                            dispatchAction(
                              NFTS_LIST_PROFILE_DISPATCH.STATUS,
                              target.value
                            )
                          }
                          value={status}
                          size='small'
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ButtonsGroup>
                        <CustomButton
                          fullWidth={false}
                          label={t('nft.nft-guide')}
                          onClick={() => {
                            openExternalUrl(NFT_GUIDE_URL)
                          }}
                        />
                        <CustomButton
                          fullWidth={false}
                          label={t('nft.create-new-nft')}
                          startIcon={<FaIcon icon={faPlus} />}
                          onClick={onCreateNewNft}
                        />
                      </ButtonsGroup>
                    </Grid>
                  </Grid>
                </>
              )}
            </PanelHeading>
          </BaseCard>
        </Grid>
        <CardListGrid>
          {filteredNfts.map((nft, index) => (
            <NftCard
              key={index}
              nft={nft}
              brandName={brandName}
              onNftDetailsClick={onNftDetailsClick}
              onNftListAction={onNftListAction}
              t={t}
            />
          ))}
        </CardListGrid>
      </CardGrid>
      {hideNftNote && showImportantNote}
    </PageContainer>
  )
}

export default memo(Nfts)
