import { mergeToNewObject } from '../../../util/object.helpers'
import { TXN_REFUND_MILES_ACTION } from '../../../constants/actions'
import { buildErrorMessage } from '../../../util/reducer.helpers'

export const refundMilesProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case TXN_REFUND_MILES_ACTION.ON_REFUND:
      return mergeToNewObject(state, {
        isProcessing: true,
        isChangedStatus: false
      })

    case TXN_REFUND_MILES_ACTION.ON_REFUND_SUCCESS:
      return mergeToNewObject(state, {
        isProcessing: false,
        isChangedStatus: true
      })

    case TXN_REFUND_MILES_ACTION.ON_REFUND_FAILED:
      return mergeToNewObject(state, {
        error: {
          status: buildErrorMessage(payload, payload?.response?.errors?.detail)
        },
        isProcessing: false,
        isChangedStatus: false
      })

    case TXN_REFUND_MILES_ACTION.ON_RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

export const TXN_REFUND_DISPATCH = {
  STATUS: 'status',
  ERROR: 'error'
}

const INITIAL_STATE = {
  isProcessing: false,
  isChangedStatus: false,
  error: {}
}
