import React, { lazy, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Trans, useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'

import Nfts from '../components/nft/Nfts'
import {
  changeNftsState,
  createNft,
  getNfts,
  getNftUsers,
  redeemNftExclusiveContent,
  resetNftsState,
  transferNft,
  updateNft,
  updateSelectedNft,
  resetNftsList
} from '../store/actions/nfts'
import {
  DEFAULT_CONTAINER_STATE,
  NFTS_PROCESS_STEP
} from '../constants/containerStates'
import CreateNftForm from '../components/nft/CreateNftForm'
import { SETUP_URL } from '../constants/navigation'
import { buildMetadataAttributes } from '../util/account.helpers'
import { updateUserAccount } from '../store/actions/account'
import {
  checkValidations,
  requiredRule,
  transWithArgs
} from '../util/validation.helpers'
import { containsNoValues, containsValues } from '../util/object.helpers'
import {
  buildMarketplaceParams,
  buildListMarketplaceParams,
  buildNftValidationRules,
  buildUserPermissionsValidationRules,
  updateUserPermissionsParams
} from '../util/nft.helpers'
import { NFTS_PROFILE_DISPATCH } from '../store/reducers/nfts/nft'
import { NFT_YES_NO_OPTIONS } from '../data/nfts'
import NftDetails from '../components/popups/nfts/NftDetails'
import ListNftPopup from '../components/popups/nfts/ListNft'
import UserPermissionsPopup from '../components/popups/nfts/UserPermissions'
import { QB_INPUT_EMAIL } from '../constants/input'
import Input from '../components/shared/inputs/Input'
import { getTokens } from '../store/actions/token'
import { isTokenStatusActive } from '../util/token.helpers'
import { DASHBOARD_EMAIL } from '../constants/externalResources'
import Link from '../components/shared/link/Link'
import { NFTS_LIST_PROFILE_DISPATCH } from '../store/reducers/nfts/nftsList'

const ConfirmationPopup = lazy(() =>
  import('../components/popups/common/Confirmation')
)

const ConfirmedPopup = lazy(() =>
  import('../components/popups/common/Confirmed')
)

const NftsContainer = ({
  tokenProfile,
  nftProfile,
  dashboardSetupState,
  changeNftsState,
  updateUserAccount,
  createNft,
  resetNftsState,
  history,
  brandName,
  getNfts,
  nftsListProfile,
  getNftUsers,
  nftUsersProfile,
  redeemNftExclusiveContent,
  updateSelectedNft,
  transferNft,
  getTokens,
  updateNft,
  resetNftsList,
  ...restProps
}) => {
  const { t } = useTranslation()
  const { error, transferId } = nftProfile
  const [popupState, setPopupState] = useState(DEFAULT_CONTAINER_STATE.NONE)
  const [nftRedeemDetails, setNftRedeemDetails] = useState({})
  const { selectedNft } = nftsListProfile

  useEffect(() => {
    setPopupState(nftProfile.nftPopupState)
  }, [nftProfile.nftPopupState])

  useEffect(() => {
    if (nftProfile.userSale === NFT_YES_NO_OPTIONS.NO) {
      changeNftsState(NFTS_PROFILE_DISPATCH.USER_SALES_FEE_TEMP, '')
    }
  }, [nftProfile.userSale])

  useEffect(() => {
    getNfts()
    getTokens()
    return () => {
      resetNftsList()
    }
  }, [])

  const handleGoToSetup = () => {
    history.replace(SETUP_URL)
  }

  const handleCloseImportantNote = () => {
    updateUserAccount(buildMetadataAttributes({ hide_nft_note: true }))
  }

  const handleCreateNewNft = () => {
    changeNftPopupState(NFTS_PROCESS_STEP.CREATE_NEW_NFT)
  }

  const handleNftDetailsClick = (nft) => {
    getNftUsers({ tokenId: nft.id, pageNumber: 0 })
    updateSelectedNft(nft)
    changeNftPopupState(NFTS_PROCESS_STEP.NFT_DETAILS)
  }

  const handleChangeNftUsersPage = (pageNumber) => {
    getNftUsers({ tokenId: selectedNft?.id, pageNumber })
  }

  const handleNftUsersPerPageChange = (recordsPerPage) => {
    getNftUsers({ tokenId: selectedNft?.id, pageNumber: 0, recordsPerPage })
  }

  const handleRedeemNftExclusiveContent = (
    authId,
    tokenId,
    nftId,
    claimCount
  ) => {
    changeNftPopupState(NFTS_PROCESS_STEP.REDEEM_EXCLUSIVE_CONTENT)
    setNftRedeemDetails({ authId, tokenId, nftId, claimCount })
  }

  const handleSubmitRedeemExclCnt = () => {
    const { authId, tokenId, nftId, claimCount } = nftRedeemDetails
    redeemNftExclusiveContent(authId, tokenId, nftId, claimCount)
    handleOnCancelPopup()
  }

  const handleTransferNft = () => {
    changeNftPopupState(NFTS_PROCESS_STEP.TRANSFER_NFT)
  }

  const handleTransferNftSubmit = () => {
    const errors = checkValidations({
      transferId: { value: transferId, rules: [requiredRule] }
    })
    if (containsNoValues(errors)) {
      transferNft(transferId, selectedNft.id)
    } else {
      changeNftsState(NFTS_PROFILE_DISPATCH.ERROR, errors)
    }
  }

  const handleNoPopup = () => {
    resetNftsState()
    changeNftPopupState(DEFAULT_CONTAINER_STATE.NONE)
    setPopupState(DEFAULT_CONTAINER_STATE.NONE)
  }

  const handleNftListAction = (selectedNft, isListed) => {
    if (isTokenStatusActive(selectedNft)) {
      updateSelectedNft(selectedNft)
      if (isListed) {
        updateNft(selectedNft.id, buildMarketplaceParams())
      } else {
        changeNftPopupState(NFTS_PROCESS_STEP.LIST_NFT)
      }
    } else {
      changeNftPopupState(NFTS_PROCESS_STEP.PENDING_NFT)
    }
  }

  const changeNftPopupState = (popupState) => {
    changeNftsState(NFTS_PROFILE_DISPATCH.NFT_POP_UP_STATE, popupState)
  }

  const handleChangeUserPermissions = (nft) => {
    updateSelectedNft(nft)
    changeNftPopupState(NFTS_PROCESS_STEP.USER_PERMISSIONS)
  }

  const handleOnCancelPopup = () => {
    switch (popupState) {
      case NFTS_PROCESS_STEP.TRANSFER_NFT:
      case NFTS_PROCESS_STEP.REDEEM_EXCLUSIVE_CONTENT:
      case NFTS_PROCESS_STEP.USER_PERMISSIONS:
        changeNftPopupState(NFTS_PROCESS_STEP.NFT_DETAILS)
        break

      case NFTS_PROCESS_STEP.NFT_DETAILS:
        handleNoPopup()
        changeNftsState(NFTS_LIST_PROFILE_DISPATCH.SELECTED_NFT, null)
        break

      default:
        handleNoPopup()
    }
  }

  const handleOnSubmitPopup = () => {
    switch (popupState) {
      case NFTS_PROCESS_STEP.CREATE_NEW_NFT:
        const { media, exclusiveContentFile } = nftProfile
        changeNftsState(NFTS_PROFILE_DISPATCH.ERROR, {})
        const nftErrors = checkValidations(buildNftValidationRules(nftProfile))
        if (containsValues(nftErrors)) {
          changeNftsState(NFTS_PROFILE_DISPATCH.ERROR, nftErrors)
        } else {
          createNft(media, exclusiveContentFile)
        }
        break

      case NFTS_PROCESS_STEP.FILE_TYPE_NOT_SUPPORTED:
      case DEFAULT_CONTAINER_STATE.SOMETHING_WENT_WRONG:
        handleNoPopup()
        break

      case NFTS_PROCESS_STEP.NFT_TRANSFERRED:
        changeNftPopupState(NFTS_PROCESS_STEP.NFT_DETAILS)
        break

      case NFTS_PROCESS_STEP.LIST_NFT:
        const { pointValue, marketplace } = nftProfile
        changeNftsState(NFTS_PROFILE_DISPATCH.ERROR, {})
        const listNftErrors = checkValidations({
          pointValue: {
            value: pointValue,
            rules: [requiredRule]
          }
        })
        if (containsNoValues(listNftErrors)) {
          updateNft(
            selectedNft.id,
            buildListMarketplaceParams(
              { pointValue, marketplace },
              tokenProfile.onramp
            )
          )
        } else {
          changeNftsState(NFTS_PROFILE_DISPATCH.ERROR, listNftErrors)
        }
        break

      case NFTS_PROCESS_STEP.USER_PERMISSIONS:
        const permissionErrors = checkValidations(
          buildUserPermissionsValidationRules(nftProfile)
        )
        if (containsNoValues(permissionErrors)) {
          updateNft(selectedNft.id, updateUserPermissionsParams(nftProfile))
        } else {
          changeNftsState(NFTS_PROFILE_DISPATCH.ERROR, permissionErrors)
        }
        break

      case NFTS_PROCESS_STEP.PENDING_NFT:
        if (selectedNft) {
          changeNftPopupState(NFTS_PROCESS_STEP.NFT_DETAILS)
        } else {
          handleNoPopup()
        }
        break

      default:
        break
    }
  }

  const renderPopup = () => {
    switch (popupState) {
      case NFTS_PROCESS_STEP.CREATE_NEW_NFT:
        return (
          <CreateNftForm
            t={t}
            nftProfile={nftProfile}
            tokenProfile={tokenProfile}
            onClose={handleOnCancelPopup}
            onSubmit={handleOnSubmitPopup}
            dispatchAction={changeNftsState}
            title={t('nft.create-new-nft')}
          />
        )

      case NFTS_PROCESS_STEP.FILE_TYPE_NOT_SUPPORTED:
        return (
          <ConfirmedPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            title='nft.file-type-not-supported'
            description='nft.try-again-types-list'
          />
        )

      case NFTS_PROCESS_STEP.NFT_DETAILS:
        return (
          <NftDetails
            onClose={handleOnCancelPopup}
            onSubmit={handleTransferNft}
            nft={selectedNft}
            brandName={brandName}
            tokenProfile={tokenProfile}
            nftUsersProfile={nftUsersProfile}
            onChangeNftUsersPage={handleChangeNftUsersPage}
            onNftUsersPerPageChange={handleNftUsersPerPageChange}
            onRedeemNftExclusiveContent={handleRedeemNftExclusiveContent}
            onNftListAction={handleNftListAction}
            onChangeUserPermissions={handleChangeUserPermissions}
            t={t}
          />
        )

      case NFTS_PROCESS_STEP.LIST_NFT:
        return (
          <ListNftPopup
            onClose={handleOnCancelPopup}
            onSubmit={handleOnSubmitPopup}
            nftProfile={nftProfile}
            tokenProfile={tokenProfile}
            dispatchAction={changeNftsState}
            t={t}
          />
        )

      case NFTS_PROCESS_STEP.USER_PERMISSIONS:
        return (
          <UserPermissionsPopup
            onClose={handleOnCancelPopup}
            onSubmit={handleOnSubmitPopup}
            nftProfile={nftProfile}
            tokenProfile={tokenProfile}
            dispatchAction={changeNftsState}
            t={t}
          />
        )

      case NFTS_PROCESS_STEP.TRANSFER_NFT:
        return (
          <ConfirmationPopup
            t={t}
            onSubmit={handleTransferNftSubmit}
            onClose={handleOnCancelPopup}
            title='nft.transfer-nft'
            description='nft.transfer-nft-desc'
            cancelLabel='common.cancel'
            submitLabel='common.transfer'
            banner={
              <Grid item xs={12} sx={{ px: 4 }}>
                <Input
                  id={NFTS_PROFILE_DISPATCH.TRANSFER_ID}
                  label={t('nft.receiver-auth-id')}
                  value={transferId}
                  errorMessage={transWithArgs(
                    t,
                    error[NFTS_PROFILE_DISPATCH.TRANSFER_ID]
                  )}
                  onChange={(value) =>
                    changeNftsState(NFTS_PROFILE_DISPATCH.TRANSFER_ID, value)
                  }
                  type={QB_INPUT_EMAIL}
                  size='small'
                />
              </Grid>
            }
          />
        )

      case NFTS_PROCESS_STEP.REDEEM_EXCLUSIVE_CONTENT:
        return (
          <ConfirmationPopup
            t={t}
            onSubmit={handleSubmitRedeemExclCnt}
            title='nft.change-excl-cnt-status'
            description='nft.change-excl-cnt-status-desc'
            onClose={handleOnCancelPopup}
            cancelLabel='common.cancel'
            submitLabel='common.confirm'
          />
        )

      case DEFAULT_CONTAINER_STATE.SOMETHING_WENT_WRONG:
        return (
          <ConfirmedPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            title='error.ooops'
            description='error.it-looks-like-something-wrong'
          />
        )

      case NFTS_PROCESS_STEP.NFT_TRANSFERRED:
        return (
          <ConfirmedPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            title='common.congratulations'
            description='nft.transfer-successful-desc'
          />
        )

      case NFTS_PROCESS_STEP.PENDING_NFT:
        return (
          <ConfirmedPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            title='nft.pending-nft'
            rawDescription={
              <Trans i18nKey='nft.pending-nft-desc'>
                <Link isEmail to={DASHBOARD_EMAIL} />
              </Trans>
            }
          />
        )

      default:
        break
    }
  }

  return (
    <>
      <Nfts
        {...restProps}
        onCloseImportantNote={handleCloseImportantNote}
        t={t}
        tokenProfile={tokenProfile}
        nftsListProfile={nftsListProfile}
        dashboardSetupState={dashboardSetupState}
        dispatchAction={changeNftsState}
        onCreateNewNft={handleCreateNewNft}
        onClickSetup={handleGoToSetup}
        brandName={brandName}
        onNftDetailsClick={handleNftDetailsClick}
        onNftListAction={handleNftListAction}
      />
      {renderPopup()}
    </>
  )
}

const mapStateToProps = ({
  dashboardReducer,
  tokensReducer,
  nftsReducer,
  accountReducer
}) => {
  const { dashboardSetupProfile, brandProfile } = dashboardReducer
  const { tokenProfile } = tokensReducer
  const { nftProfile, nftsListProfile, nftUsersProfile } = nftsReducer
  return {
    dashboardSetupState: dashboardSetupProfile.dashboardSetupState,
    hideNftNote: accountReducer.accountProfile.metadata?.hideNftNote,
    tokenProfile,
    nftProfile,
    nftsListProfile,
    nftUsersProfile,
    brandName: brandProfile.name
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeNftsState,
      updateUserAccount,
      createNft,
      resetNftsState,
      getNfts,
      updateNft,
      getNftUsers,
      redeemNftExclusiveContent,
      updateSelectedNft,
      transferNft,
      getTokens,
      resetNftsList
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(NftsContainer)
