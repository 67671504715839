import { combineEpics } from 'redux-observable'

import { authEpic } from './auth'
import { tokenEpic } from './token'
import { transactionEpic } from './transaction'
import { brandEpic } from './brand'
import { mainEpic } from './main'
import { accountEpic } from './account'
import { memberEpic } from './member'
import { fileUploadEpic } from './fileUpload'
import { exchangeEpic } from './exchange'
import { welcomeEpic } from './welcome'
import { adminEpic } from './admin'
import { apiKeyEpic } from './apiKey'
import { dashboardEpic } from './dashboard'
import { analyticsEpic } from './analytics'
import { billingEpic } from './billing'
import { nftEpic } from './nfts'
import { requestFeatureEpic } from './requestFeature'
import { surveyEpic } from './survey'

export const rootEpic = combineEpics(
  authEpic,
  tokenEpic,
  transactionEpic,
  brandEpic,
  mainEpic,
  accountEpic,
  memberEpic,
  fileUploadEpic,
  exchangeEpic,
  welcomeEpic,
  adminEpic,
  apiKeyEpic,
  dashboardEpic,
  analyticsEpic,
  billingEpic,
  nftEpic,
  requestFeatureEpic,
  surveyEpic
)
