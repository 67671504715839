import { API_RESPONSE } from '../constants/api'
import { ERROR_TEXT } from '../constants/messages'
import { trim } from './string.helpers'
import { formatValidator } from './validation.helpers'

export const extractUserFields = (user) => {
  const { firstName, lastName, jobPosition, country } = user
  return {
    firstName,
    lastName,
    jobPosition,
    country
  }
}

export const buildErrorMessage = (payload, customMessage) => {
  if (customMessage) {
    return formatValidator(customMessage)
  }
  if (payload?.response?.status === API_RESPONSE.BAD_REQUEST) {
    return formatValidator(ERROR_TEXT.CHECK_FIELDS_CORRECT)
  } else {
    return formatValidator(ERROR_TEXT.SOMETHING_WENT_WRONG)
  }
}

export const buildSuccessMessages = (prevDetails, updatedDetails) => {
  return Object.getOwnPropertyNames(updatedDetails).reduce(
    (curr, nextProperty) => {
      if (
        trim(JSON.stringify(updatedDetails[nextProperty])) !==
        trim(JSON.stringify(prevDetails[nextProperty]))
      ) {
        return {
          ...curr,
          [nextProperty]: formatValidator(buildSuccessText(nextProperty))
        }
      } else {
        return curr
      }
    },
    {}
  )
}

export const buildSuccessText = (field) => {
  return `message.field-${field}-changed`
}
