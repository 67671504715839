import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import TokenInfo from '../components/tokenInfo/TokenInfo'
import {
  changeTokenState,
  getTokenById,
  resetTokenState,
  updateToken
} from '../store/actions/token'
import { bindActionCreators } from 'redux'
import { TOKEN_PROFILE_DISPATCH } from '../store/reducers/tokens/token'
import { isDashboardReady } from '../util/dashboard.helpers'
import { containsValues } from '../util/object.helpers'
import { checkValidations, requiredRule } from '../util/validation.helpers'
import { SETUP_URL } from '../constants/navigation'
import { isTokenInfoChanged } from '../util/token.helpers'
import {
  DASHBOARD_SETUP_PROCESS_STEP,
  DEFAULT_CONTAINER_STATE
} from '../constants/containerStates'
import MirrorTransactionsOnChainPopup from '../components/popups/setup/MirrorTransactionsOnChain'

const TokenInfoContainer = ({
  changeTokenState,
  updateToken,
  tokenProfile,
  dashboardSetupState,
  getTokenById,
  history,
  ...props
}) => {
  const { t } = useTranslation()

  const [popupState, setPopupState] = useState(DEFAULT_CONTAINER_STATE.NONE)

  useEffect(() => {
    return () => {
      resetErrorSuccess()
    }
  }, [])

  useEffect(() => {
    if (!isDashboardReady(dashboardSetupState)) {
      history.push(SETUP_URL)
    }
  }, [dashboardSetupState])

  useEffect(() => {
    if (tokenProfile.id && isDashboardReady(dashboardSetupState)) {
      getTokenById({ tokenId: tokenProfile.id })
    }
  }, [tokenProfile.id, dashboardSetupState])

  const handleUpdateTokenSetup = () => {
    resetErrorSuccess()
    const { regionsTemp, countriesTemp, descriptionTemp, amountOfMembersTemp } =
      tokenProfile

    const tokenErrors = checkValidations({
      regionsTemp: { value: regionsTemp, rules: [requiredRule] },
      countriesTemp: { value: countriesTemp, rules: [requiredRule] },
      amountOfMembersTemp: {
        value: amountOfMembersTemp,
        rules: [requiredRule]
      },
      descriptionTemp: {
        value: descriptionTemp,
        rules: [requiredRule]
      }
    })
    if (containsValues(tokenErrors)) {
      changeTokenState(TOKEN_PROFILE_DISPATCH.ERROR, tokenErrors)
    } else {
      tokenSaveAction()
    }
  }

  const handleUpdateChainPreference = () => {
    if (isTokenInfoChanged(tokenProfile)) {
      if (tokenProfile.binanceMirrorTemp) {
        setPopupState(DASHBOARD_SETUP_PROCESS_STEP.MIRROR_TRANSACTIONS_ON_CHAIN)
      } else {
        tokenSaveAction()
      }
    }
  }

  const tokenSaveAction = () => {
    if (isTokenInfoChanged(tokenProfile)) {
      updateToken()
    }
  }

  const resetErrorSuccess = () => {
    changeTokenState(TOKEN_PROFILE_DISPATCH.ERROR, {})
    changeTokenState(TOKEN_PROFILE_DISPATCH.SUCCESS, {})
  }

  const handleOnSubmitPopup = () => {
    switch (popupState) {
      case DASHBOARD_SETUP_PROCESS_STEP.MIRROR_TRANSACTIONS_ON_CHAIN:
        tokenSaveAction()
        handleNoPopup()
        break

      default:
        handleNoPopup()
        break
    }
  }

  const handleOnCancelPopup = () => {
    switch (popupState) {
      default:
        handleNoPopup()
    }
  }

  const handleNoPopup = () => {
    setPopupState(DEFAULT_CONTAINER_STATE.NONE)
  }

  const renderPopup = () => {
    switch (popupState) {
      case DASHBOARD_SETUP_PROCESS_STEP.MIRROR_TRANSACTIONS_ON_CHAIN:
        return (
          <MirrorTransactionsOnChainPopup
            onClose={handleOnCancelPopup}
            onSubmit={handleOnSubmitPopup}
            isTokenCreated
            t={t}
          />
        )

      default:
        break
    }
  }

  return (
    isDashboardReady(dashboardSetupState) && (
      <>
        <TokenInfo
          {...props}
          dispatchAction={changeTokenState}
          onInfoSubmit={handleUpdateTokenSetup}
          onChainPreferenceSubmit={handleUpdateChainPreference}
          tokenProfile={tokenProfile}
          t={t}
        />
        {renderPopup()}
      </>
    )
  )
}

const mapStateToProps = ({ tokensReducer, dashboardReducer, mainReducer }) => {
  const { tokenProfile } = tokensReducer
  const { dashboardSetupProfile } = dashboardReducer
  const { billingProfile } = mainReducer
  return {
    tokenProfile,
    dashboardSetupState: dashboardSetupProfile.dashboardSetupState,
    billingProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeTokenState,
      resetTokenState,
      updateToken,
      getTokenById
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(TokenInfoContainer)
