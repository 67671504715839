import React, { memo } from 'react'

import './spinner.css'

const Loading = ({ noFullHeight }) => (
  <div
    className='fallback-spinner'
    style={!noFullHeight ? { height: '100vh' } : {}}
  >
    <div className='loading component-loader'>
      <div className='effect-1 effects' />
      <div className='effect-2 effects' />
      <div className='effect-3 effects' />
    </div>
  </div>
)
export default memo(Loading)
