import React, { memo } from 'react'
import { Box, Grid } from '@mui/material'

import CustomButton from '../../shared/buttons/CustomButton'
import PageContainer from '../../shared/PageContainer/PageContainer'
import { LABEL_TEXT, QB_LABEL_TEXT } from '../../../constants/messages'
import { getMemberLabelByValue } from '../../../data/members'
import Display from '../../shared/Display/Display'
import TokenStatus from '../../shared/TokenStatus/TokenStatus'
import { DASHBOARD_EMAIL } from '../../../constants/externalResources'
import BaseForm from '../../shared/BaseForm/BaseForm'
import { sortLabelsByASC } from '../../../util/app/app.helpers'
import { displayCountryLabels } from '../../../util/token.helpers'
import { QB_ACTION_SUBMIT } from '../../../constants/input'
import { QB_TOKEN_STATUS } from '../../../constants/token'
import { regionLabelsByValues } from '../../../util/country.helpers'
import { formatArrayToString } from '../../../util/string.helpers'
import MirrorTransactionsField from '../../tokenInfo/MirrorTransactionsField'

const Status = ({
  tokenProfile: {
    nameTemp,
    symbolTemp,
    regionsTemp,
    countriesTemp,
    descriptionTemp,
    amountOfMembersTemp,
    pointValueTemp,
    status,
    id,
    binanceMirrorTemp
  },
  onClickContinue,
  t
}) => {
  const title = t('token-setup.congratulations-for-creating-point')
  return (
    <PageContainer title={title}>
      <BaseForm
        onFormSubmit={onClickContinue}
        title={title}
        fields={
          <Grid container spacing={3}>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <Display label={t(LABEL_TEXT.POINT_NAME)} value={nameTemp} />
            </Grid>
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <Display label={t(LABEL_TEXT.POINT_SYMBOL)} value={symbolTemp} />
            </Grid>

            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Display
                label={t(QB_LABEL_TEXT.SELECT_REGION)}
                value={formatArrayToString(
                  sortLabelsByASC(regionLabelsByValues(regionsTemp, t))
                )}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Display
                label={t(QB_LABEL_TEXT.SELECT_COUNTRY)}
                value={displayCountryLabels(countriesTemp, t)}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Display
                label={t(LABEL_TEXT.AMOUNT_OF_MEMBERS)}
                value={getMemberLabelByValue(amountOfMembersTemp)}
              />
            </Grid>
            <Grid item xs={12}>
              <Display
                label={t(LABEL_TEXT.PROGRAM_DESCRIPTION)}
                value={descriptionTemp}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Display
                label={t(LABEL_TEXT.POINT_VALUE)}
                value={pointValueTemp}
              />
            </Grid>
            <Grid item xs={12}>
              <MirrorTransactionsField
                t={t}
                value={binanceMirrorTemp}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TokenStatus
                t={t}
                status={status}
                id={id}
                email={DASHBOARD_EMAIL}
              />
            </Grid>
          </Grid>
        }
        actions={
          <>
            <Box sx={{ flex: '1 1 auto' }} />
            <CustomButton
              type={QB_ACTION_SUBMIT}
              variant='contained'
              label={t(getButtonLabelByStatus(status))}
              fullWidth={false}
            />
          </>
        }
      />
    </PageContainer>
  )
}

export default memo(Status)

const getButtonLabelByStatus = (status) => {
  switch (status) {
    case QB_TOKEN_STATUS.ACTIVE:
    case QB_TOKEN_STATUS.PAUSED:
    case QB_TOKEN_STATUS.STOPPED:
      return 'common.view-loyalty-point'

    case QB_TOKEN_STATUS.REJECTED:
      return 'common.create-new-point'

    default:
      return 'common.refresh'
  }
}
