import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  experimentalStyled,
  useMediaQuery,
  Container,
  Box
} from '@mui/material'
import { useSelector } from 'react-redux'

import {
  LOYALTY_POINT_URL,
  HOME_URL,
  ACCOUNT_URL,
  SETUP_URL,
  ANALYTICS_URL,
  INTEGRATION_URL,
  NFT_URL,
  ADMIN_URL,
  MARKETPLACE_URL,
  REQUEST_FEATURE_URL,
  EXTENSIONS_URL
} from '../../constants/navigation'
import TokenInfoContainer from '../../containers/TokenInfoContainer'
import DashboardContainer from '../../containers/DashboardContainer'
import AccountContainer from '../../containers/AccountContainer'
import SidebarContainer from '../../containers/dashboard/SidebarContainer'
import ExchangeContainer from '../../containers/ExchangeContainer'

import { testingAlertHeight, topBarHeight } from '../global/Theme-variable'
import HeaderContainer from '../../containers/dashboard/HeaderContainer'
import SetupContainer from '../../containers/setup/SetupContainer'
import AnalyticsContainer from '../../containers/AnalyticsContainer'
import IntegrationContainer from '../../containers/IntegrationContainer'
import ExtensionsContainer from '../../containers/ExtensionsContainer'
import AdminContainer from '../../containers/AdminContainer'
import NftsContainer from '../../containers/NftsContainer'
import RequestFeatureContainer from '../../containers/RequestFeatureContainer'
import { CONTAINER_MIN_HEIGHT } from '../../styles/common'
import { isProduction } from '../../util/app/app.helpers'

const MainWrapper = experimentalStyled('div')(() => ({
  display: 'flex',
  minHeight: '100vh',
  overflow: 'hidden',
  width: '100%'
}))

const PageWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',

  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('lg')]: {
    paddingTop: topBarHeight
  },
  [theme.breakpoints.down('lg')]: {
    paddingTop: '64px'
  }
}))

const DashboardLayout = ({ signOut, history }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true)
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false)
  const accountProfile = useSelector(
    (state) => state.accountReducer.accountProfile
  )
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const isDarkMode = accountProfile.metadata?.darkMode
  const isProductionEnv = isProduction()
  return (
    <MainWrapper className={isDarkMode ? 'darkbg' : ''}>
      <HeaderContainer
        sx={{
          marginTop: !isProductionEnv && `${testingAlertHeight}px`,
          paddingLeft: isSidebarOpen && lgUp ? '265px' : '',
          backgroundColor: isDarkMode ? '#20232a' : '#fafbfb'
        }}
        toggleSidebar={() => setSidebarOpen(!isSidebarOpen)}
        toggleMobileSidebar={() => setMobileSidebarOpen(true)}
        onLogout={signOut}
      />

      <SidebarContainer
        isSidebarOpen={isSidebarOpen}
        isMobileSidebarOpen={isMobileSidebarOpen}
        onSidebarClose={() => setMobileSidebarOpen(false)}
        history={history}
      />

      <PageWrapper>
        <Container
          maxWidth={false}
          sx={{
            paddingTop: `${isProductionEnv ? 20 : testingAlertHeight}px`,
            paddingBottom: '15px',
            paddingLeft: isSidebarOpen && lgUp ? '280px!important' : ''
          }}
        >
          <Box sx={CONTAINER_MIN_HEIGHT}>
            <Switch>
              <Route exact path={HOME_URL} component={DashboardContainer} />
              <Route
                exact
                path={LOYALTY_POINT_URL}
                component={TokenInfoContainer}
              />
              <Route
                path={`${MARKETPLACE_URL}/:selectedTab?`}
                component={ExchangeContainer}
              />
              <Route
                exact
                path={`${SETUP_URL}/:selectedTab?`}
                component={SetupContainer}
              />
              <Route
                exact
                path={`${ANALYTICS_URL}/:selectedTab?`}
                component={AnalyticsContainer}
              />
              <Route exact path={NFT_URL} component={NftsContainer} />
              <Route exact path={ACCOUNT_URL} component={AccountContainer} />
              <Route
                exact
                path={INTEGRATION_URL}
                component={IntegrationContainer}
              />
              <Route
                exact
                path={`${EXTENSIONS_URL}/:selectedTab?`}
                component={ExtensionsContainer}
              />

              <Route
                exact
                path={`${ADMIN_URL}/:selectedTab?`}
                component={AdminContainer}
              />
              <Route
                exact
                path={REQUEST_FEATURE_URL}
                component={RequestFeatureContainer}
              />
            </Switch>
          </Box>
        </Container>
      </PageWrapper>
    </MainWrapper>
  )
}

export default DashboardLayout
