import React, { memo } from 'react'
import { Fab } from '@mui/material'

const CustomFab = ({ children, sx, ...restProps }) => {
  return (
    <Fab
      sx={{ cursor: 'auto', zIndex: 0, ...sx }}
      {...restProps}
      disableRipple
      disableFocusRipple
    >
      {children}
    </Fab>
  )
}

export default memo(CustomFab)
