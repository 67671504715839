import { map, mergeMap, catchError, tap, filter, mapTo } from 'rxjs/operators'
import { forkJoin, of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'

import { logger } from '../../util/log.helpers'
import {
  DOWNLOAD_ACTION,
  TRANSACTION_ACTIONS,
  TRANSACTION_DOWNLOAD_ACTIONS,
  TXN_COLUMNS_ACTION,
  TXN_REFUND_MILES_ACTION,
  TXN_STATUS_ACTION
} from '../../constants/actions'
import apiService from '../../services/api'
import {
  buildOffsetRangeForDownload,
  buildPaginationFilters,
  extractBrandId,
  extractTokenId,
  extractTokenInfo,
  extractTransactionsPagination,
  extractTransactionsProfile,
  extractTxnChangeStatusProfile,
  formatTransactions
} from '../../util/epics.helpers'
import {
  commonParser,
  parseTransactionsResponse
} from '../../util/apiParser.helpers'
import { wrapUserAccessToken } from '../../util/auth.helpers'
import {
  ALLOW_MAX_RECORDS_FOR_DOWNLOAD,
  DOWNLOAD_REPORT_TYPES,
  MAX_NUMBER_OF_TRANSACTIONS_FOR_DOWNLOAD
} from '../../constants/app'
import {
  buildDefaultParamsWithFilters,
  buildTxnSearchParams,
  buildUpdateTxnStatusParams,
  defaultTxnColumns
} from '../../util/transaction/transaction.helpers'
import { buildDownloadFileName } from '../../util/download.helpers'
import { ANALYTICS_INTERVAL_FILTERS } from '../../constants/analytics'
import {
  analyticsFilterData,
  buildDateRangeQueryParams,
  isAnalyticsPointsBank
} from '../../util/analytics/analytics.helpers'
import { FUTURE_TRANSACTION_STATUS } from '../../constants/transactionTypes'
import { getTxnColumns, setTxnColumns } from '../../util/local.helpers'
import { formatValidator } from '../../util/validation.helpers'

const handleGetTransactionsHistoryEpic = (action$, state$) =>
  action$.pipe(
    ofType(TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS),
    filter(() => extractBrandId(state$)),
    mergeMap(({ payload }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getTransactions(
            buildTransactionPaginationFilters(payload, state$),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS_SUCCESS,
              payload: parseTransactionsResponse(response.data)
            })),
            catchError((err) =>
              of({
                type: TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleGetTransactionsChartEpic = (action$, state$) =>
  action$.pipe(
    ofType(TRANSACTION_ACTIONS.ON_GET_EXCHANGE_ACTIVITY_CHART),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getTransactionsChart(
            buildExchangeActivityChartParams(state$),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: TRANSACTION_ACTIONS.ON_GET_EXCHANGE_ACTIVITY_CHART_SUCCESS,
              payload: commonParser(response.data)
            })),
            catchError((err) =>
              of({
                type: TRANSACTION_ACTIONS.ON_GET_EXCHANGE_ACTIVITY_CHART_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleGetTransactionsChartByFilterEpic = (action$, state$) =>
  action$.pipe(
    ofType(TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS_CHART),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) => {
        const { tokenId, from_time, to_time, exclude_token_to_types } =
          buildDefaultParamsWithFilters(state$)
        const analyticsFilter = analyticsFilterData(state$)
        return apiService
          .getTransactionsChart(
            {
              tokenId,
              from_time,
              to_time,
              exclude_token_to_types,
              time_interval: analyticsFilter.interval
            },
            accessToken
          )
          .pipe(
            map(({ response }) => ({
              type: TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS_CHART_SUCCESS,
              payload: {
                chartData: commonParser(response.data),
                tokenProfile: extractTokenInfo(state$),
                analyticsFilter: analyticsFilterData(state$)
              }
            })),
            catchError((err) =>
              of({
                type: TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS_CHART_FAILED,
                payload: err
              })
            )
          )
      })
    )
  )

const handleTransactionsForDownloadEpic = (action$, state$) =>
  action$.pipe(
    ofType(TRANSACTION_DOWNLOAD_ACTIONS.ON_GET_TRANSACTIONS_FOR_DOWNLOAD),
    map(() => extractTransactionsPagination(state$)),
    filter(
      ({ totalTxCount, recordsPerPage }) =>
        totalTxCount <= ALLOW_MAX_RECORDS_FOR_DOWNLOAD &&
        totalTxCount > recordsPerPage
    ),
    mergeMap(({ totalTxCount }) =>
      wrapUserAccessToken((accessToken) =>
        getAllTransactionsForDownload(totalTxCount, state$, accessToken).pipe(
          map((response) => {
            return {
              type: TRANSACTION_DOWNLOAD_ACTIONS.ON_GET_TRANSACTIONS_FOR_DOWNLOAD_SUCCESS,
              payload: response
            }
          }),
          catchError((err) =>
            of({
              type: TRANSACTION_DOWNLOAD_ACTIONS.ON_GET_TRANSACTIONS_FOR_DOWNLOAD_FAILED,
              payload: err
            })
          )
        )
      )
    )
  )

const handleTransactionsForDownloadBelowThresholdEpic = (action$, state$) =>
  action$.pipe(
    ofType(TRANSACTION_DOWNLOAD_ACTIONS.ON_GET_TRANSACTIONS_FOR_DOWNLOAD),
    map(() => extractTransactionsProfile(state$)),
    filter(
      ({ pagination: { totalTxCount, recordsPerPage } }) =>
        totalTxCount <= recordsPerPage
    ),
    map(({ transactions }) => {
      return {
        type: TRANSACTION_DOWNLOAD_ACTIONS.ON_GET_TRANSACTIONS_FOR_DOWNLOAD_SUCCESS,
        payload: transactions
      }
    })
  )

const getAllTransactionsForDownload = (totalTxCount, state, accessToken) => {
  const { limit, ...restQueryAttributes } =
    buildTransactionsQueriesForDownload(state)
  return forkJoin(
    buildOffsetRangeForDownload(totalTxCount, limit).map((offset) =>
      apiService.getTransactions(
        { offset, limit, ...restQueryAttributes },
        accessToken
      )
    )
  ).pipe(
    map((responseArray) =>
      responseArray.flatMap(({ response }) =>
        formatTransactions(response.data.transactions)
      )
    )
  )
}

const handleCheckConditionForReportEmailEpic = (action$, state$) =>
  action$.pipe(
    ofType(TRANSACTION_DOWNLOAD_ACTIONS.ON_GET_TRANSACTIONS_FOR_DOWNLOAD),
    map(() => extractTransactionsPagination(state$)),
    filter(({ totalTxCount }) => totalTxCount > ALLOW_MAX_RECORDS_FOR_DOWNLOAD),
    mapTo({
      type: TRANSACTION_DOWNLOAD_ACTIONS.ON_SEND_REPORT_IN_EMAIL
    })
  )

const handleTransactionsReportEmailEpic = (action$, state$) =>
  action$.pipe(
    ofType(TRANSACTION_DOWNLOAD_ACTIONS.ON_SEND_REPORT_IN_EMAIL),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .downloadReportToEmail(
            DOWNLOAD_REPORT_TYPES.TRANSACTIONS,
            buildTransactionsQueriesForReportEmail(state$),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: DOWNLOAD_ACTION.ON_SENT_REPORT_IN_EMAIL_SUCCESS,
              payload: response
            })),
            catchError((err) =>
              of({
                type: DOWNLOAD_ACTION.ON_SENT_REPORT_IN_EMAIL_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleGetLiabilityTrendChartByFilterEpic = (action$, state$) =>
  action$.pipe(
    ofType(TRANSACTION_ACTIONS.ON_GET_LIABILITY_TREND_CHART),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getLiabilityTrendChart(
            buildTransactionChartParams(state$),
            accessToken
          )
          .pipe(
            map(({ response }) => ({
              type: TRANSACTION_ACTIONS.ON_GET_LIABILITY_TREND_CHART_SUCCESS,
              payload: {
                chartData: commonParser(response.data),
                tokenProfile: extractTokenInfo(state$),
                analyticsFilter: analyticsFilterData(state$)
              }
            })),
            catchError((err) =>
              of({
                type: TRANSACTION_ACTIONS.ON_GET_LIABILITY_TREND_CHART_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleUpdateTransactionStatusWhenSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(TXN_STATUS_ACTION.ON_CHANGE_STATUS),
    map(({ payload: { transactionId } }) => ({
      status: extractTxnChangeStatusProfile(state$).status,
      transactionId,
      brandId: extractBrandId(state$)
    })),
    filter(
      ({ status, brandId }) =>
        status === FUTURE_TRANSACTION_STATUS.SUCCESS && brandId
    ),
    mergeMap(({ transactionId, brandId }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .updateTransaction(
            brandId,
            transactionId,
            buildUpdateTxnStatusParams(),
            accessToken
          )
          .pipe(
            map(({ response }) => ({
              type: TXN_STATUS_ACTION.ON_CHANGE_STATUS_SUCCESS,
              payload: commonParser(response.data)
            })),
            catchError((data) =>
              of({
                type: TXN_STATUS_ACTION.ON_CHANGE_STATUS_FAILED,
                payload: data
              })
            )
          )
      )
    )
  )

const handleUpdateTransactionStatusWhenReverseEpic = (action$, state$) =>
  action$.pipe(
    ofType(TXN_STATUS_ACTION.ON_CHANGE_STATUS),
    map(({ payload: { transactionId } }) => ({
      status: extractTxnChangeStatusProfile(state$).status,
      transactionId,
      brandId: extractBrandId(state$)
    })),
    filter(
      ({ status, brandId }) =>
        status === FUTURE_TRANSACTION_STATUS.REVERSE && brandId
    ),
    mergeMap(({ transactionId, brandId }) =>
      wrapUserAccessToken((accessToken) =>
        apiService.deleteTransaction(brandId, transactionId, accessToken).pipe(
          map(({ response }) => ({
            type: TXN_STATUS_ACTION.ON_CHANGE_STATUS_SUCCESS,
            payload: commonParser(response.data)
          })),
          catchError((data) =>
            of({
              type: TXN_STATUS_ACTION.ON_CHANGE_STATUS_FAILED,
              payload: data
            })
          )
        )
      )
    )
  )

const handleRefundMilesTransactionEpic = (action$, state$) =>
  action$.pipe(
    ofType(TXN_REFUND_MILES_ACTION.ON_REFUND),
    map(({ payload: { transactionId } }) => ({
      transactionId,
      brandId: extractBrandId(state$)
    })),
    mergeMap(({ transactionId, brandId }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .refundMiles(
            brandId,
            transactionId,
            {
              action: 'revert'
            },
            accessToken
          )
          .pipe(
            map(({ response }) => ({
              type: TXN_REFUND_MILES_ACTION.ON_REFUND_SUCCESS,
              payload: commonParser(response.data)
            })),
            catchError((data) =>
              of({
                type: TXN_REFUND_MILES_ACTION.ON_REFUND_FAILED,
                payload: data
              })
            )
          )
      )
    )
  )

const handleSelectTxnColumn = (action$) =>
  action$.pipe(
    ofType(TXN_COLUMNS_ACTION.ON_SELECT),
    map(({ payload: { field } }) => {
      const columns = defaultTxnColumns()
      const fieldExists = columns[field]
      if (fieldExists) {
        delete columns[field]
      } else {
        columns[field] = true
      }
      const columnsLength = Object.keys(columns).length
      let error = null
      if (columnsLength < 5 || columnsLength > 9) {
        error =
          columnsLength < 5
            ? formatValidator('transactions.min-columns-error')
            : formatValidator('transactions.max-columns-error')
      } else {
        setTxnColumns(columns)
      }

      return {
        type: TXN_COLUMNS_ACTION.ON_SELECTED,
        payload: {
          error,
          txnColumns: getTxnColumns()
        }
      }
    })
  )

export const transactionEpic = combineEpics(
  handleGetTransactionsHistoryEpic,
  handleGetTransactionsChartEpic,
  handleTransactionsForDownloadEpic,
  handleTransactionsForDownloadBelowThresholdEpic,
  handleCheckConditionForReportEmailEpic,
  handleTransactionsReportEmailEpic,
  handleGetTransactionsChartByFilterEpic,
  handleGetLiabilityTrendChartByFilterEpic,
  handleUpdateTransactionStatusWhenSuccessEpic,
  handleUpdateTransactionStatusWhenReverseEpic,
  handleRefundMilesTransactionEpic,
  handleSelectTxnColumn
)

const buildTransactionPaginationFilters = (payload, state) => {
  const pagination = extractTransactionsPagination(state)
  const defaultParams = buildDefaultParamsWithFilters(state)
  return {
    ...buildPaginationFilters(payload, pagination),
    ...defaultParams,
    ...buildTxnSearchParams(state, defaultParams)
  }
}

const buildTransactionsQueriesForDownload = (state) => {
  const limit = MAX_NUMBER_OF_TRANSACTIONS_FOR_DOWNLOAD
  const defaultParams = buildDefaultParamsWithFilters(state)
  return {
    limit,
    ...defaultParams,
    ...buildTxnSearchParams(state, defaultParams)
  }
}

const buildTransactionsQueriesForReportEmail = (state) => {
  const {
    types,
    brandId,
    from_time,
    to_time,
    token_id,
    token_to_id,
    exclude_token_to_types
  } = buildDefaultParamsWithFilters(state)
  const tokenProfile = extractTokenInfo(state)
  const analyticsFilter = analyticsFilterData(state)
  const isExchangeAnalytics = !isAnalyticsPointsBank(analyticsFilter.type)
  return {
    brand_id: brandId,
    ...(types.length > 0 && { types }),
    ...(token_id && { token_id }),
    ...(token_to_id && { token_to_id }),
    ...(isExchangeAnalytics && { exclude_token_to_types }),
    from_time,
    to_time,
    filename: buildDownloadFileName(
      tokenProfile.name,
      DOWNLOAD_REPORT_TYPES.TRANSACTIONS
    ),
    show_txn_for_exchange: isExchangeAnalytics
  }
}

const buildExchangeActivityChartParams = (state) => {
  const tokenId = extractTokenId(state)
  return {
    tokenId,
    time_interval: ANALYTICS_INTERVAL_FILTERS.MONTHLY
  }
}

const buildTransactionChartParams = (state) => {
  const tokenId = extractTokenId(state)
  const analyticsFilter = analyticsFilterData(state)
  return {
    tokenId,
    ...buildDateRangeQueryParams(analyticsFilter),
    time_interval: analyticsFilter.interval
  }
}
