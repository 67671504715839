import React, { memo } from 'react'

import BrandAndTokenInfo from './BrandAndTokenInfo'
import CustomButton from '../../shared/buttons/CustomButton'

const TokensButton = ({ onClickButton, icon, t, ...restProps }) => (
  <CustomButton
    aria-controls='tokens-menu'
    aria-owns='tokens-menu'
    aria-haspopup='true'
    color='grey'
    label={
      <BrandAndTokenInfo {...restProps} t={t}>
        {icon}
      </BrandAndTokenInfo>
    }
    onClick={onClickButton}
    sx={{
      p: 0
    }}
  />
)

export default memo(TokensButton)
