export const exportCsv = (headers, body, documentName) => {
  const csvString = getCSVString(headers, body)
  const csvBlob = getCSVBlob(csvString)
  serveCSVBlob(csvBlob, documentName)
}

const getCSVString = (headers, body) => {
  const csvData = [headers, ...body]
  return csvData.join('\n')
}

const getCSVBlob = (csvString) => {
  return new Blob([csvString], { type: 'text/csv' })
}

const serveCSVBlob = (csvBlob, documentName) => {
  const url = URL.createObjectURL(csvBlob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${documentName}.csv`)
  link.setAttribute('target', '_blank')
  document.body.appendChild(link)
  link.click()
  const parentNode = link.parentNode
  if (parentNode) {
    parentNode.removeChild(link)
  }
}

export const buildTableHeaders = (t, headers) => {
  return headers.map(({ label, ...restAttrs }) => ({
    label: t(label),
    ...restAttrs
  }))
}
