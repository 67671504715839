import { map, mergeMap, catchError, filter } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'

import apiService from '../../services/api'
import { wrapUserAccessToken } from '../../util/auth.helpers'
import {
  ANALYTICS_FILTER_ACTION,
  MEMBER_ACTIONS,
  TRANSACTION_ACTIONS,
  ANALYTICS_POINTS_BANK_ACTION,
  ANALYTICS_POINTS_EXCHANGE_ACTION
} from '../../constants/actions'
import {
  analyticsFilterData,
  isAnalyticsPointsBank,
  isPointsBankDateRangeFilter,
  isPointsBankIntervalFilter,
  isPointsBankTransactionTypeFilter,
  isPointsExchangeTypeFilter,
  isPointsTypeChangeFilter
} from '../../util/analytics/analytics.helpers'
import { commonParser } from '../../util/apiParser.helpers'
import { buildDefaultParamsWithFilters } from '../../util/transaction/transaction.helpers'
import { extractTokenInfo } from '../../util/epics.helpers'
import {
  EXCHANGE_MEMBERS_STAT_PATH,
  TOP_EXCHANGE_PARTNERS_PATH,
  TOTAL_ACTIVE_MEMBERS_PATH
} from '../../constants/api'

const handleGetTokenStatsForAnalyticsEpic = (action$, state$) =>
  action$.pipe(
    ofType(ANALYTICS_POINTS_BANK_ACTION.ON_GET_TOKEN_STATS),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getTokenStats(buildTokenStatParams(state$), accessToken)
          .pipe(
            map(({ response }) => ({
              type: ANALYTICS_POINTS_BANK_ACTION.ON_GET_TOKEN_STATS_SUCCESS,
              payload: {
                tokenStats: commonParser(response.data),
                tokenProfile: extractTokenInfo(state$),
                analyticsFilter: analyticsFilterData(state$)
              }
            })),
            catchError(() =>
              of({
                type: ANALYTICS_POINTS_BANK_ACTION.ON_GET_TOKEN_STATS_FAILED
              })
            )
          )
      )
    )
  )

const handleGetActiveAndTotalMembersEpic = (action$, state$) =>
  action$.pipe(
    ofType(ANALYTICS_POINTS_BANK_ACTION.ON_GET_ACTIVE_TOTAL_MEMBERS),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) => {
        const { tokenId, from_time, to_time, types } =
          buildDefaultParamsWithFilters(state$)
        return apiService
          .getAnalyticsData(
            TOTAL_ACTIVE_MEMBERS_PATH,
            tokenId,
            { from_time, to_time, types },
            accessToken
          )
          .pipe(
            map(({ response }) => ({
              type: ANALYTICS_POINTS_BANK_ACTION.ON_GET_ACTIVE_TOTAL_MEMBERS_SUCCESS,
              payload: commonParser(response.data)
            })),
            catchError((error) =>
              of({
                type: ANALYTICS_POINTS_BANK_ACTION.ON_GET_ACTIVE_TOTAL_MEMBERS_FAILED,
                payload: error
              })
            )
          )
      })
    )
  )

const handleGetExchangeMembersEpic = (action$, state$) =>
  action$.pipe(
    ofType(ANALYTICS_POINTS_EXCHANGE_ACTION.ON_GET_EXCHANGE_MEMBERS),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) => {
        const { tokenId, from_time, to_time } =
          buildDefaultParamsWithFilters(state$)
        return apiService
          .getAnalyticsData(
            EXCHANGE_MEMBERS_STAT_PATH,
            tokenId,
            { from_time, to_time },
            accessToken
          )
          .pipe(
            map(({ response }) => ({
              type: ANALYTICS_POINTS_EXCHANGE_ACTION.ON_GET_EXCHANGE_MEMBERS_SUCCESS,
              payload: commonParser(response.data)
            })),
            catchError((error) =>
              of({
                type: ANALYTICS_POINTS_EXCHANGE_ACTION.ON_GET_EXCHANGE_MEMBERS_FAILED,
                payload: error
              })
            )
          )
      })
    )
  )

const handleGetTopExchangePartnersEpic = (action$, state$) =>
  action$.pipe(
    ofType(ANALYTICS_POINTS_EXCHANGE_ACTION.ON_GET_TOP_EXCHANGE_PARTNERS),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) => {
        const { tokenId, from_time, to_time } =
          buildDefaultParamsWithFilters(state$)
        return apiService
          .getAnalyticsData(
            TOP_EXCHANGE_PARTNERS_PATH,
            tokenId,
            { from_time, to_time },
            accessToken
          )
          .pipe(
            map(({ response }) => ({
              type: ANALYTICS_POINTS_EXCHANGE_ACTION.ON_GET_TOP_EXCHANGE_PARTNERS_SUCCESS,
              payload: {
                data: commonParser(response.data),
                analyticsFilter: analyticsFilterData(state$)
              }
            })),
            catchError((error) =>
              of({
                type: ANALYTICS_POINTS_EXCHANGE_ACTION.ON_GET_TOP_EXCHANGE_PARTNERS_FAILED,
                payload: error
              })
            )
          )
      })
    )
  )

const handleOnPointsBankDateRangeChangeEpic = (action$, state$) =>
  action$.pipe(
    ofType(ANALYTICS_FILTER_ACTION.ON_CHANGE_STATE),
    filter(({ payload }) => isPointsBankDateRangeFilter(payload)),
    map(() => isAnalyticsPointsBank(analyticsFilterData(state$).type)),
    mergeMap((isPointsBank) => {
      const actionTypes = [
        { type: ANALYTICS_POINTS_BANK_ACTION.ON_GET_TOKEN_STATS },
        { type: TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS_CHART },
        { type: TRANSACTION_ACTIONS.ON_GET_LIABILITY_TREND_CHART },
        {
          type: TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS,
          payload: { pageNumber: 0 }
        },
        { type: MEMBER_ACTIONS.ON_GET_MEMBERS, payload: { pageNumber: 0 } }
      ]
      if (isPointsBank) {
        actionTypes.push({
          type: ANALYTICS_POINTS_BANK_ACTION.ON_GET_ACTIVE_TOTAL_MEMBERS
        })
      } else {
        actionTypes.push(
          ...[
            { type: ANALYTICS_POINTS_EXCHANGE_ACTION.ON_GET_EXCHANGE_MEMBERS },
            {
              type: ANALYTICS_POINTS_EXCHANGE_ACTION.ON_GET_TOP_EXCHANGE_PARTNERS
            }
          ]
        )
      }
      return actionTypes
    })
  )

const handleOnAnalyticsTypeChangeEpic = (action$) =>
  action$.pipe(
    ofType(ANALYTICS_FILTER_ACTION.ON_CHANGE_STATE),
    filter(
      ({ payload }) =>
        isPointsTypeChangeFilter(payload) || isPointsExchangeTypeFilter(payload)
    ),
    mergeMap(() => {
      return [
        {
          type: TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS,
          payload: { pageNumber: 0 }
        }
      ]
    })
  )

const handleOnPointsBankTransactionTypeChangeEpic = (action$, state$) =>
  action$.pipe(
    ofType(ANALYTICS_FILTER_ACTION.ON_CHANGE_STATE),
    filter(({ payload }) => isPointsBankTransactionTypeFilter(payload)),
    mergeMap(({ payload }) => {
      return [
        { type: ANALYTICS_POINTS_BANK_ACTION.ON_GET_ACTIVE_TOTAL_MEMBERS },
        {
          type: TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS,
          payload: { pageNumber: 0 }
        },
        {
          type: ANALYTICS_FILTER_ACTION.ON_POINTS_BANK_TRANSACTION_TYPE_CHANGE,
          payload: {
            tokenProfile: extractTokenInfo(state$),
            analyticsFilter: { transactionType: payload.value }
          }
        }
      ]
    })
  )

const handleOnPointsBankIntervalChangeEpic = (action$) =>
  action$.pipe(
    ofType(ANALYTICS_FILTER_ACTION.ON_CHANGE_STATE),
    filter(({ payload }) => isPointsBankIntervalFilter(payload)),
    mergeMap(() => [
      { type: TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS_CHART },
      { type: TRANSACTION_ACTIONS.ON_GET_LIABILITY_TREND_CHART }
    ])
  )

const handleOnPointsExchangeTypeChangeEpic = (action$, state$) =>
  action$.pipe(
    ofType(ANALYTICS_FILTER_ACTION.ON_CHANGE_STATE),
    filter(({ payload }) => isPointsExchangeTypeFilter(payload)),
    mergeMap(({ payload }) => [
      {
        type: ANALYTICS_FILTER_ACTION.ON_POINTS_EXCHANGE_TYPE_CHANGE,
        payload: {
          tokenProfile: extractTokenInfo(state$),
          analyticsFilter: { exchangeType: payload.value }
        }
      }
    ])
  )

export const analyticsEpic = combineEpics(
  handleGetTokenStatsForAnalyticsEpic,
  handleGetActiveAndTotalMembersEpic,
  handleGetExchangeMembersEpic,
  handleGetTopExchangePartnersEpic,
  handleOnPointsBankDateRangeChangeEpic,
  handleOnPointsBankTransactionTypeChangeEpic,
  handleOnPointsBankIntervalChangeEpic,
  handleOnPointsExchangeTypeChangeEpic,
  handleOnAnalyticsTypeChangeEpic
)

const buildTokenStatParams = (state) => {
  const { tokenId, from_time, to_time, exclude_token_to_types } =
    buildDefaultParamsWithFilters(state)
  return {
    tokenId,
    from_time,
    to_time,
    exclude_token_to_types
  }
}
