import { QB_AUTH_STATE } from '../constants/auth'

const isAuthenticated = (authState) => {
  return authState === QB_AUTH_STATE.AUTHENTICATED
}

const isNotAuthenticated = (authState) => {
  return authState === QB_AUTH_STATE.NOT_AUTHENTICATED
}

const isCheckingAuth = (authState) => {
  return authState === QB_AUTH_STATE.CHECKING
}

const buildMetadataAttributes = (metadata) => {
  return {
    metadata
  }
}

const cannySSOUserFormat = (accountProfile) => {
  const { userId, email, firstName, lastName } = accountProfile
  return {
    user: {
      id: userId,
      email,
      name: firstName + ' ' + lastName
    }
  }
}

export {
  isAuthenticated,
  isNotAuthenticated,
  isCheckingAuth,
  buildMetadataAttributes,
  cannySSOUserFormat
}
