export const NFT_MEDIA_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio'
}

export const NFT_MARKETPLACE_OPTIONS = {
  LWA: 'lwa',
  LAD: 'lad',
  LWA_LAD: 'lwa_lad'
}

export const NFT_EXCLUSIVE_CONTENT_MODE = {
  NO: 'no',
  SAME: 'same',
  UNIQUE: 'unique'
}

export const EXCLUSIVE_CONTENT_SAMPLE_CSV_PATH =
  '/data/NFT-Exclusive-Content-Sample-CSV.csv'
