import { mergeToNewObject } from '../../../util/object.helpers'
import {
  ANALYTICS_FILTER_ACTION,
  EXCHANGE_TRANSACTION_ACTIONS,
  TRANSACTION_ACTIONS
} from '../../../constants/actions'
import {
  buildTransactionsChartData,
  processPointsTransferredChartByFilter,
  processTransactionsChartByFilter
} from '../../../util/chart/exchangeTransactionsGraph.helpers'
import { buildIsProcessingState } from '../../../util/app/app.helpers'

export const exchangeTransactionsGraphProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case EXCHANGE_TRANSACTION_ACTIONS.ON_RESET_CHART_STATE:
      return INITIAL_STATE

    case TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS_CHART_SUCCESS:
      return mergeToNewObject(
        state,
        buildTransactionsChartData(payload),
        buildIsProcessingState(
          EXCHANGE_TRANSACTIONS_GRAPH_DISPATCH.TRANSACTIONS_CHART,
          false,
          state
        )
      )

    case TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS_CHART:
      return mergeToNewObject(
        state,
        buildIsProcessingState(
          EXCHANGE_TRANSACTIONS_GRAPH_DISPATCH.TRANSACTIONS_CHART,
          true,
          state
        )
      )

    case TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS_CHART_FAILED:
      return mergeToNewObject(
        state,
        buildIsProcessingState(
          EXCHANGE_TRANSACTIONS_GRAPH_DISPATCH.TRANSACTIONS_CHART,
          false,
          state
        )
      )

    case ANALYTICS_FILTER_ACTION.ON_POINTS_EXCHANGE_TYPE_CHANGE:
      return mergeToNewObject(
        state,
        handleAnalyticsFilterChange(payload, state)
      )

    default:
      return state
  }
}

export const EXCHANGE_TRANSACTIONS_GRAPH_DISPATCH = {
  TRANSACTIONS_CHART: 'transactionsChart'
}

const INITIAL_STATE = {
  valueTransferredChart: {
    data: [],
    filteredData: [],
    labels: []
  },
  transactionsChart: {
    data: {},
    filteredData: {}
  },
  isProcessing: {
    [EXCHANGE_TRANSACTIONS_GRAPH_DISPATCH.TRANSACTIONS_CHART]: false
  }
}

const handleAnalyticsFilterChange = (
  { analyticsFilter: { exchangeType } },
  state
) => {
  return {
    transactionsChart: {
      ...state.transactionsChart,
      filteredData: processTransactionsChartByFilter(
        state.transactionsChart.data,
        exchangeType
      )
    },
    valueTransferredChart: {
      ...state.valueTransferredChart,
      filteredData: processPointsTransferredChartByFilter(
        state.valueTransferredChart.data,
        exchangeType
      )
    }
  }
}
