import React, { memo } from 'react'
import { faLockKeyhole } from '@fortawesome/pro-light-svg-icons'
import { useHistory } from 'react-router-dom'
import { Grid } from '@mui/material'
import styled from '@emotion/styled'

import AccountForm from '../AccountForm/AccountForm'
import Text from '../text/Text'
import { SETUP_URL } from '../../../constants/navigation'
import FaIcon from '../FaIcon/FaIcon'

const Locked = ({ t, title, description, lockedLabel }) => {
  const history = useHistory()
  return (
    <AccountForm
      onFormSubmit={() => history.push(SETUP_URL)}
      t={t}
      title={
        <>
          {title} <CustomLockIcon icon={faLockKeyhole} />
        </>
      }
      description={description}
      submitLabel='developers.go-to-setup'
      fields={
        <Grid item xs={12}>
          <Text
            label={t(lockedLabel)}
            variant='body1'
            color='textprimary'
            fontStyle='italic'
          />
        </Grid>
      }
    />
  )
}

export default memo(Locked)

const CustomLockIcon = styled(FaIcon)`
  margin-left: 8px;
`
