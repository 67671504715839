import React from 'react'

import Loading from '../components/shared/Loading/Loading'
import DashboardLayout from '../components/dashboardLayout/DashboardLayout'
import requireAuth from '../hoc/requireAuth'
import { useStonlyWidget } from '../hooks/useStonlyWidget'

const HomeContainer = (props) => {
  useStonlyWidget()
  const { loading } = props
  return loading ? <Loading /> : <DashboardLayout {...props} />
}

export default requireAuth(HomeContainer)
