import { mergeToNewObject } from '../../../util/object.helpers'
import {
  MEMBER_ACTIONS,
  MEMBER_TRANSACTION_ACTIONS,
  MEMBER_SEARCH_ACTIONS
} from '../../../constants/actions'
import { NUMBER_OF_RECORDS_PER_PAGE } from '../../../constants/app'

export const membersProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case MEMBER_ACTIONS.ON_GET_MEMBERS:
      return mergeToNewObject(state, {
        pagination: {
          ...state.pagination,
          ...payload
        }
      })

    case MEMBER_SEARCH_ACTIONS.ON_SEARCH:
      return mergeToNewObject(state, {
        pagination: {
          ...state.pagination,
          pageNumber: 0
        }
      })

    case MEMBER_ACTIONS.ON_GET_MEMBERS_SUCCESS:
    case MEMBER_SEARCH_ACTIONS.ON_SEARCH_SUCCESS:
      const { totalCount, users } = payload
      return mergeToNewObject(state, {
        members: users,
        pagination: {
          ...state.pagination,
          totalTxCount: totalCount
        }
      })

    case MEMBER_ACTIONS.ON_GET_RECENT_TRANSACTIONS:
    case MEMBER_TRANSACTION_ACTIONS.ON_GET_BALANCES:
    case MEMBER_TRANSACTION_ACTIONS.ON_CREDIT_DEBIT_POINTS_SUCCESS:
      return mergeToNewObject(state, {
        recentTransactions: [],
        recentTransactionsLoading: true
      })

    case MEMBER_ACTIONS.ON_GET_RECENT_TRANSACTIONS_SUCCESS:
      return mergeToNewObject(state, {
        recentTransactions: payload,
        recentTransactionsLoading: false
      })

    case MEMBER_ACTIONS.ON_GET_RECENT_TRANSACTIONS_FAILED:
      return mergeToNewObject(state, {
        recentTransactionsLoading: false
      })

    case MEMBER_ACTIONS.ON_RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  members: [],
  recentTransactions: [],
  recentTransactionsLoading: false,
  pagination: {
    recordsPerPage: NUMBER_OF_RECORDS_PER_PAGE,
    pageNumber: 0,
    totalTxCount: 0
  }
}
