import React, { lazy, useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Admin from '../components/admin/Admin'
import { buildSetupUrl, isDashboardReady } from '../util/dashboard.helpers'
import { ADMIN_TAB_TYPES } from '../constants/admin'
import { changeBrandState, updateBrand } from '../store/actions/brand'
import { BRAND_DISPATCH } from '../store/reducers/dashboard/brand'
import {
  buildBrandValidationRules,
  isBrandInfoChanged
} from '../util/brand.helpers'
import { getTokens } from '../store/actions/token'
import { SETUP_URL } from '../constants/navigation'
import { containsNoValues, containsValues } from '../util/object.helpers'
import {
  checkValidations,
  emailRule,
  requiredRule,
  transWithArgs
} from '../util/validation.helpers'
import {
  addTeamMember,
  changeAdminState,
  changeTeamMembersInputAndErrorState,
  getTeamMembers,
  removeTeamMember
} from '../store/actions/admin'
import { buildTableHeaders } from '../util/csv.helpers'
import { TEAM_MEMBERS_TABLE_HEADER } from '../constants/table'
import {
  DEFAULT_CONTAINER_STATE,
  PACKAGE_BILLING_PROCESS_STEP,
  TEAM_MEMBERS_PROCESS_STEP
} from '../constants/containerStates'
import Input from '../components/shared/inputs/Input'
import { ADMIN_DISPATCH } from '../store/reducers/admin/teamMembers'
import { QB_INPUT_EMAIL } from '../constants/input'
import { openBlankRedirectPage } from '../util/app/app.helpers'
import {
  changeBillingState,
  getExchangePerformance,
  reactivateSubscription
} from '../store/actions/billing'
import {
  buildPackageActions,
  isBillingTabAndPackageSubscribed
} from '../util/billing.helpers'
import { buildAdminUrl, fromAdminTabIdToType } from '../util/admin.helpers'
import { BILLING_PROFILE_DISPATCH } from '../store/reducers/main/billing'
import {
  billingSessionUrl,
  checkoutSessionUrl
} from '../util/navigation.helpers'
import Text from '../components/shared/text/Text'
import { DASHBOARD_SETUP_URL_TYPES } from '../constants/dashboard'

const ConfirmationPopup = lazy(() =>
  import('../components/popups/common/Confirmation')
)
const ConfirmedPopup = lazy(() =>
  import('../components/popups/common/Confirmed')
)

const ChangePackagePopup = lazy(() =>
  import('../components/popups/admin/ChangePackage')
)

const AdminContainer = ({
  dashboardSetupState,
  brandProfile,
  changeBrandState,
  updateBrand,
  getTokens,
  getTeamMembers,
  addTeamMember,
  removeTeamMember,
  teamMembersProfile,
  changeTeamMembersInputAndErrorState,
  changeAdminState,
  userEmail,
  history,
  billingProfile,
  changeBillingState,
  getExchangePerformance,
  tokenProfile,
  reactivateSubscription
}) => {
  const isDashboardSetupReady = isDashboardReady(dashboardSetupState)
  const { t } = useTranslation()
  const { selectedTab } = useParams()

  const [member, setMember] = useState()
  const teamMembersHeaders = buildTableHeaders(t, TEAM_MEMBERS_TABLE_HEADER)
  const {
    teamMemberEmail,
    adminPopupState,
    error,
    success,
    isProcessing,
    manageAction
  } = teamMembersProfile

  useEffect(() => {
    getTokens()
    changeBrandState(BRAND_DISPATCH.ERROR, {})
  }, [])

  useEffect(() => {
    getTeamMembers()
  }, [])

  useEffect(() => {
    if (manageAction) {
      buildSuccessPopup(manageAction)
    }
  }, [manageAction])

  useEffect(() => {
    if (!selectedTab) {
      setSelectedTab(ADMIN_TAB_TYPES.BRAND_DETAILS)
    }
  }, [selectedTab])

  useEffect(() => {
    if (
      isDashboardSetupReady &&
      isBillingTabAndPackageSubscribed(selectedTab, billingProfile)
    ) {
      getExchangePerformance()
    }
  }, [selectedTab, billingProfile.subscribedPackage])

  useEffect(() => {
    return () => {
      resetBillingSessionUrls()
    }
  }, [])

  const resetBillingSessionUrls = () => {
    changeBillingState(BILLING_PROFILE_DISPATCH.SIGNED_URL, '')
  }

  const billingPackageActions = useMemo(
    () => buildPackageActions(billingProfile),
    [billingProfile]
  )

  const handleChangeTab = (event, selectedTabId) => {
    const newTab = fromAdminTabIdToType(selectedTabId)
    if (selectedTab !== newTab) {
      setSelectedTab(newTab)
    }
  }

  const setSelectedTab = (selectedTab) => {
    history.push(buildAdminUrl(selectedTab))
  }

  const handleGoToSetup = () => {
    history.replace(SETUP_URL)
  }

  const handleInviteTeamMember = () => {
    if (brandProfile.id) {
      changeAdminState(
        ADMIN_DISPATCH.ADMIN_POPUP_STATE,
        TEAM_MEMBERS_PROCESS_STEP.ADD_TEAM_MEMBER
      )
    } else {
      changeAdminState(
        ADMIN_DISPATCH.ADMIN_POPUP_STATE,
        TEAM_MEMBERS_PROCESS_STEP.NO_BRAND_CREATED
      )
    }
  }

  const handleRemoveTeamMember = (member) => {
    setMember(member)
    changeAdminState(
      ADMIN_DISPATCH.ADMIN_POPUP_STATE,
      TEAM_MEMBERS_PROCESS_STEP.REMOVE_TEAM_MEMBER
    )
  }

  const onClickUpdate = (e) => {
    e.preventDefault()
    changeBrandState(BRAND_DISPATCH.ERROR, {})
    const brandErrors = checkValidations(
      buildBrandValidationRules(brandProfile)
    )
    if (containsValues(brandErrors)) {
      changeBrandState(BRAND_DISPATCH.ERROR, brandErrors)
    } else {
      if (isBrandInfoChanged(brandProfile)) {
        updateBrand(brandProfile.logo)
      }
    }
  }

  const buildSuccessPopup = (action) => {
    switch (action) {
      case TEAM_MEMBERS_PROCESS_STEP.ADD_TEAM_MEMBER:
      case TEAM_MEMBERS_PROCESS_STEP.REMOVE_TEAM_MEMBER:
        handleNoPopup()
        break

      default:
        break
    }
  }

  const handleOnSubmitPopup = () => {
    switch (adminPopupState) {
      case TEAM_MEMBERS_PROCESS_STEP.ADD_TEAM_MEMBER:
        const { teamMemberEmail } = teamMembersProfile
        const errors = checkValidations({
          teamMemberEmail: {
            value: teamMemberEmail,
            rules: [requiredRule, emailRule]
          }
        })
        if (containsNoValues(errors)) {
          addTeamMember(teamMemberEmail)
        } else {
          changeAdminState(ADMIN_DISPATCH.ERROR, errors)
        }
        break
      case TEAM_MEMBERS_PROCESS_STEP.NO_BRAND_CREATED:
        history.replace(SETUP_URL)
        break
      case TEAM_MEMBERS_PROCESS_STEP.USER_HAS_NO_ACCOUNT:
      case TEAM_MEMBERS_PROCESS_STEP.USER_ALREADY_EXISTS:
        handleNoPopup()
        break
      case TEAM_MEMBERS_PROCESS_STEP.REMOVE_TEAM_MEMBER:
        removeTeamMember(member.id)
        break

      default:
        break
    }
  }

  const handleOnCancelPopup = () => {
    switch (adminPopupState) {
      case TEAM_MEMBERS_PROCESS_STEP.ADD_TEAM_MEMBER:
        handleNoPopup()
        break

      default:
        handleNoPopup()
    }
  }

  const handleNoPopup = () => {
    changeAdminState(
      ADMIN_DISPATCH.ADMIN_POPUP_STATE,
      DEFAULT_CONTAINER_STATE.NONE
    )
  }

  const handleChangePackage = () => {
    changeAdminState(
      ADMIN_DISPATCH.ADMIN_POPUP_STATE,
      PACKAGE_BILLING_PROCESS_STEP.CHANGE_PACKAGE
    )
  }

  const handleReactivatePackage = () => {
    changeBillingState(BILLING_PROFILE_DISPATCH.IS_YEARLY, true)
    changeAdminState(
      ADMIN_DISPATCH.ADMIN_POPUP_STATE,
      PACKAGE_BILLING_PROCESS_STEP.REACTIVATE_PACKAGE
    )
  }

  const handleViewBilling = () => {
    openBlankRedirectPage(billingSessionUrl(tokenProfile.id))
  }

  const handleReactivateSubscription = (plan) => {
    openBlankRedirectPage(checkoutSessionUrl(tokenProfile.id, plan.priceId))
    handleNoPopup()
    reactivateSubscription()
    history.push(buildSetupUrl(DASHBOARD_SETUP_URL_TYPES.STATUS))
  }

  const renderPopup = () => {
    switch (adminPopupState) {
      case TEAM_MEMBERS_PROCESS_STEP.ADD_TEAM_MEMBER:
        return (
          <ConfirmationPopup
            isForm
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            title='admin.invite-team-member'
            description='admin.add-team-member-desc'
            cancelLabel='common.cancel'
            submitLabel='admin.invite'
            isProcessing={isProcessing}
            body={
              <Input
                id={ADMIN_DISPATCH.TEAM_MEMBER_EMAIL}
                label={t('admin.email-address')}
                value={teamMemberEmail}
                errorMessage={transWithArgs(
                  t,
                  error[ADMIN_DISPATCH.TEAM_MEMBER_EMAIL]
                )}
                onChange={(value) =>
                  changeTeamMembersInputAndErrorState(
                    ADMIN_DISPATCH.TEAM_MEMBER_EMAIL,
                    value
                  )
                }
                successMsg={transWithArgs(
                  t,
                  success[ADMIN_DISPATCH.TEAM_MEMBER_EMAIL]
                )}
                type={QB_INPUT_EMAIL}
              />
            }
          />
        )

      case TEAM_MEMBERS_PROCESS_STEP.NO_BRAND_CREATED:
        return (
          <ConfirmationPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            title='admin.brand-details-needed'
            description='admin.brand-details-needed-desc'
            cancelLabel='common.close'
            submitLabel='developers.start-setup'
          />
        )

      case TEAM_MEMBERS_PROCESS_STEP.USER_HAS_NO_ACCOUNT:
        return (
          <ConfirmedPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            title='admin.member-has-no-account'
            description={t('admin.member-has-no-account-desc', {
              brandName: brandProfile.name
            })}
          />
        )

      case TEAM_MEMBERS_PROCESS_STEP.USER_ALREADY_EXISTS:
        return (
          <ConfirmedPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            title='admin.member-already-exists'
            description='admin.member-already-exists-desc'
          />
        )

      case TEAM_MEMBERS_PROCESS_STEP.REMOVE_TEAM_MEMBER:
        const user = member?.user
        return (
          <ConfirmationPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            title='admin.remove-team-member'
            description='admin.remove-team-member-desc'
            cancelLabel='common.close'
            submitLabel='admin.remove-team-member'
            isProcessing={isProcessing}
            submitProps={{ color: 'error', variant: 'outlined' }}
            body={
              <Text
                variant='body1'
                color='textprimary'
                label={
                  <>
                    <strong>
                      {user?.firstName} {user?.secondName},{' '}
                    </strong>
                    {user?.email}
                  </>
                }
              />
            }
          />
        )

      case PACKAGE_BILLING_PROCESS_STEP.CHANGE_PACKAGE:
        return (
          <ChangePackagePopup
            t={t}
            onClose={handleOnCancelPopup}
            billingProfile={billingProfile}
            dispatchAction={changeBillingState}
            billingPackageActions={billingPackageActions}
            onSelectBillingPackage={handleViewBilling}
          />
        )

      case PACKAGE_BILLING_PROCESS_STEP.REACTIVATE_PACKAGE:
        return (
          <ChangePackagePopup
            t={t}
            onClose={handleOnCancelPopup}
            billingProfile={billingProfile}
            dispatchAction={changeBillingState}
            onSelectBillingPackage={handleReactivateSubscription}
          />
        )

      default:
        break
    }
  }

  return (
    <>
      <Admin
        t={t}
        isDashboardSetupReady={isDashboardSetupReady}
        selectedTab={selectedTab}
        onChangeTab={handleChangeTab}
        brandProfile={brandProfile}
        dispatchAction={changeBrandState}
        onClickUpdate={onClickUpdate}
        onClickSetup={handleGoToSetup}
        teamMembersTableProps={{
          teamMembersHeaders: teamMembersHeaders,
          teamMembersProfile: teamMembersProfile,
          onInviteTeamMember: handleInviteTeamMember,
          onRemoveTeamMember: handleRemoveTeamMember,
          userEmail: userEmail
        }}
        onChangePackage={handleChangePackage}
        onReactivatePackage={handleReactivatePackage}
        onViewBilling={handleViewBilling}
        billingProfile={billingProfile}
        tokenProfile={tokenProfile}
      />
      {renderPopup()}
    </>
  )
}

const mapStateToProps = ({
  dashboardReducer,
  adminReducer,
  accountReducer,
  tokensReducer,
  mainReducer
}) => {
  const { dashboardSetupProfile, brandProfile } = dashboardReducer
  const { billingProfile } = mainReducer
  const { email } = accountReducer.accountProfile
  const { teamMembersProfile } = adminReducer
  const { tokenProfile } = tokensReducer
  return {
    dashboardSetupState: dashboardSetupProfile.dashboardSetupState,
    brandProfile,
    teamMembersProfile,
    userEmail: email,
    billingProfile,
    tokenProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeBrandState,
      updateBrand,
      getTokens,
      getTeamMembers,
      addTeamMember,
      removeTeamMember,
      changeTeamMembersInputAndErrorState,
      changeAdminState,
      changeBillingState,
      getExchangePerformance,
      reactivateSubscription
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(AdminContainer)
