import React, { memo } from 'react'
import {
  buildDateRangeOptions,
  buildGraphIntervalOptions,
  buildPointsBankTransactionTypeOptions,
  buildPointsExchangeTransactionTypeOptions,
  isAnalyticsPointsExchange
} from '../../util/analytics/analytics.helpers'
import { Box, Grid } from '@mui/material'

import Dropdown from '../shared/inputs/Dropdown'
import CustomDatePicker from '../shared/inputs/CustomDatePicker'
import DashboardCard from '../shared/DashboardCard/DashboardCard'
import { ANALYTICS_FILTER_DISPATCH } from '../../store/reducers/analytics/analyticsFilter'
import { getMaxDaysForFilter } from '../../util/date.helpers'
import { TRANSPARENT_BLACK } from '../../styles/common'
import FixedTop from '../shared/FixedTop/FixedTop'

const AnalyticsFilter = ({
  dateRange,
  startDate,
  endDate,
  transactionType,
  exchangeType,
  interval,
  dispatchAction,
  type,
  t,
  onResetMemberSearch,
  onResetTransactionSearch
}) => {
  return (
    <FixedTop>
      <DashboardCard sx={{ pt: 0, pb: 0 }} contentProps={{ pt: 2 }}>
        <Grid container spacing={2}>
          <Grid item sm={2} xs={12}>
            <Dropdown
              id={ANALYTICS_FILTER_DISPATCH.DATE_RANGE}
              label={t('analytics.date-range')}
              options={buildDateRangeOptions(t)}
              onChange={({ target }) => {
                onResetMemberSearch()
                onResetTransactionSearch()
                dispatchAction(
                  ANALYTICS_FILTER_DISPATCH.DATE_RANGE,
                  target.value
                )
              }}
              value={dateRange}
              size='small'
            />
          </Grid>
          <Grid item sm={2} xs={12}>
            <CustomDatePicker
              id={ANALYTICS_FILTER_DISPATCH.START_DATE}
              label={t('analytics.from')}
              onChange={(value) => {
                onResetMemberSearch()
                onResetTransactionSearch()
                dispatchAction(ANALYTICS_FILTER_DISPATCH.START_DATE, value)
              }}
              value={startDate}
              size='small'
            />
          </Grid>
          <Grid item sm={2} xs={12}>
            <CustomDatePicker
              id={ANALYTICS_FILTER_DISPATCH.END_DATE}
              label={t('analytics.to')}
              onChange={(value) => {
                onResetMemberSearch()
                onResetTransactionSearch()
                dispatchAction(ANALYTICS_FILTER_DISPATCH.END_DATE, value)
              }}
              value={endDate}
              size='small'
              minDate={startDate}
              maxDate={getMaxDaysForFilter(startDate)}
            />
          </Grid>
          <Grid item sm={2} xs={12}>
            <Dropdown
              id={ANALYTICS_FILTER_DISPATCH.INTERVAL}
              label={t('analytics.interval')}
              helpText={t('analytics.graph-interval-help-text')}
              options={buildGraphIntervalOptions(t)}
              onChange={({ target }) =>
                dispatchAction(ANALYTICS_FILTER_DISPATCH.INTERVAL, target.value)
              }
              value={interval}
              size='small'
            />
          </Grid>
          <Grid
            item
            sm={4}
            md={3}
            xs={12}
            sx={{ display: 'flex', alignItems: 'self-end' }}
          >
            <Box
              sx={{
                height: 70,
                mr: {
                  sm: 2
                },
                borderLeft: {
                  xs: '0',
                  sm: `1px solid ${TRANSPARENT_BLACK}`
                }
              }}
            ></Box>
            {isAnalyticsPointsExchange(type) ? (
              <Dropdown
                id={ANALYTICS_FILTER_DISPATCH.EXCHANGE_TYPE}
                label={t('analytics.transaction-type')}
                options={buildPointsExchangeTransactionTypeOptions(t)}
                onChange={({ target }) => {
                  onResetTransactionSearch()
                  dispatchAction(
                    ANALYTICS_FILTER_DISPATCH.EXCHANGE_TYPE,
                    target.value
                  )
                }}
                value={exchangeType}
                size='small'
              />
            ) : (
              <Dropdown
                id={ANALYTICS_FILTER_DISPATCH.TRANSACTION_TYPE}
                label={t('analytics.transaction-type')}
                options={buildPointsBankTransactionTypeOptions(t)}
                onChange={({ target }) => {
                  onResetTransactionSearch()
                  dispatchAction(
                    ANALYTICS_FILTER_DISPATCH.TRANSACTION_TYPE,
                    target.value
                  )
                }}
                value={transactionType}
                size='small'
              />
            )}
          </Grid>
        </Grid>
      </DashboardCard>
    </FixedTop>
  )
}

export default memo(AnalyticsFilter)
