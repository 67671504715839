import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import UnauthorizedLayout from '../components/layouts/UnauthorizedLayout/UnauthorizedLayout'
import Welcome from '../components/popups/welcome/Welcome'
import { getWelcomeState, setWelcomeState } from '../store/actions/welcome'

const UnauthorizedLayoutContainer = ({
  welcomeProfile,
  getWelcomeState,
  setWelcomeState,
  ...props
}) => {
  const { t } = useTranslation()
  const [isWelcomePopupOpen, setIsWelcomePopupOpen] = useState(false)

  useEffect(() => {
    getWelcomeState()
  }, [])

  const handleClose = () => {
    setWelcomeState(true)
    setIsWelcomePopupOpen(false)
  }

  const handleOpenWelcomePopup = () => {
    setIsWelcomePopupOpen(true)
  }

  const renderWelcome = () => {
    return <Welcome onGetStartedClick={handleClose} t={t} />
  }

  return (
    <>
      <UnauthorizedLayout
        t={t}
        onWelcomePopupOpen={handleOpenWelcomePopup}
        {...props}
      />
      {(welcomeProfile.isWelcomeOpen || isWelcomePopupOpen) && renderWelcome()}
    </>
  )
}

const mapStateToProps = ({ welcomeReducer }) => {
  const { welcomeProfile } = welcomeReducer
  return {
    welcomeProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getWelcomeState,
      setWelcomeState
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnauthorizedLayoutContainer)
