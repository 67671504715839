import React, { memo } from 'react'
import { Grid } from '@mui/material'
import { faMoneyBillSimple } from '@fortawesome/pro-light-svg-icons'

import { CSS_FLEX_COLUMN, CSS_TRUNCATE_TEXT } from '../../../styles/common'
import TitleCaptionText from '../../shared/text/TitleCaptionText'
import { formatToShortDate } from '../../../util/date.helpers'
import { LABEL_TEXT } from '../../../constants/messages'
import DisplayLabelWithPopover from '../../shared/DisplayLabel/DisplayLabelWithPopover'
import DisplayLabelWithRow from '../../shared/DisplayLabel/DisplayLabelWithRow'
import { formatTicks, toUpperFirstLetter } from '../../../util/string.helpers'
import StatusLabel from '../../shared/StatusLabel/StatusLabel'
import {
  convertToYesNo,
  buildListedPlace,
  isNftListedListed
} from '../../../util/nft.helpers'
import Link from '../../shared/link/Link'
import NftDetailsMedia from '../../nft/NftDetailsMedia'
import { prependHttpsIfNotExists } from '../../../util/app/app.helpers'
import {
  formatCurrencyValue,
  formatToDecimals
} from '../../../util/number.helpers'
import RichText from '../../shared/text/RichText'
import FaIcon from '../../shared/FaIcon/FaIcon'
import ExclusiveContentHelpText from '../../shared/common/ExclusiveContentHelpText'

const NftInfo = ({
  nft,
  brandName,
  t,
  userSupply = 0,
  isSale,
  tokenProfile: { name }
}) => {
  const {
    metadata,
    nftType,
    supply,
    insertedAt,
    canSell,
    canTransfer,
    salesFeePercentage,
    canRedeem,
    canSellOnLad
  } = nft
  const {
    media,
    externalLink,
    description: { data: descriptionData },
    legacy
  } = metadata
  const isListed = isNftListedListed(nft)
  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <NftDetailsMedia
              media={media}
              coverImage={legacy?.coverImage}
              sx={{
                borderRadius: '7px'
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              ...CSS_FLEX_COLUMN,
              justifyContent: 'space-between',
              pl: '20px'
            }}
          >
            <TitleCaptionText
              title={nft.name}
              caption={brandName}
              titleStyles={{ whiteSpace: 'normal' }}
              sx={{ mb: 1 }}
            />
            <DisplayLabelWithRow
              label={`${t('common.status')}:`}
              value={
                <StatusLabel
                  label={`${t(`nft.${isListed ? 'listed' : 'created'}`)} ${
                    isListed
                      ? `(${buildListedPlace({ canRedeem, canSellOnLad })})`
                      : ''
                  }`}
                  color={isListed ? 'success' : 'primary'}
                  labelStyles={{
                    variant: 'caption',
                    color: 'textPrimary'
                  }}
                />
              }
            />
            {isListed && (
              <DisplayLabelWithRow
                label={t('exchange.price-in-credits')}
                helpText={t('exchange.nft-price-in-credits-help-text')}
                value={
                  <>
                    <FaIcon icon={faMoneyBillSimple} />
                    &nbsp;
                    {formatTicks(formatCurrencyValue(nft.onramp))} /&nbsp;
                    {t('sidebar.nft')}
                  </>
                }
              />
            )}
            <DisplayLabelWithRow
              label={`${t('nft.creation-date')}:`}
              value={formatToShortDate(insertedAt)}
            />
            <DisplayLabelWithRow
              label={t('common.blockchain')}
              value={t('nft.qiibee-rewards-chain')}
            />
            <DisplayLabelWithRow
              label={t(LABEL_TEXT.TYPE)}
              helpText={t('nft.type-desc')}
              value={toUpperFirstLetter(nftType)}
            />
            <DisplayLabelWithRow
              label={t('nft.total-supply')}
              helpText={t('nft.supply-desc')}
              value={formatTicks(supply)}
            />
            <DisplayLabelWithRow
              label={t(`nft.your-supply${isSale ? '-on-sale' : ''}`)}
              helpText={t('nft.supply-desc-users', { brandName })}
              value={formatTicks(userSupply)}
            />
            <DisplayLabelWithRow
              label={t('nft.user-transfer')}
              helpText={t('nft.user-transfer-desc')}
              value={convertToYesNo(canTransfer, t)}
            />
            <DisplayLabelWithRow
              label={t('nft.user-sale')}
              helpText={t('nft.user-sale-desc')}
              value={convertToYesNo(canSell, t)}
            />
            {canSell && (
              <DisplayLabelWithRow
                label={t('nft.user-sales-fee')}
                helpText={t('nft.user-sales-fee-desc', { name })}
                value={
                  salesFeePercentage
                    ? `${formatToDecimals(Number(salesFeePercentage), 2)}%`
                    : ''
                }
              />
            )}
            {externalLink && (
              <DisplayLabelWithRow
                label={t('nft.external-link')}
                helpText={t('nft.external-link-desc')}
                value={
                  <Link
                    isOutside
                    label={externalLink}
                    to={prependHttpsIfNotExists(externalLink)}
                    sx={{
                      width: '100%',
                      ...CSS_TRUNCATE_TEXT
                    }}
                  />
                }
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {descriptionData && (
        <Grid item xs={12}>
          <DisplayLabelWithPopover
            label={t('common.description')}
            helpText={
              <ExclusiveContentHelpText t={t} i18nKey='nft.description-desc' />
            }
            value={<RichText content={descriptionData} />}
          />
        </Grid>
      )}
    </>
  )
}

export default memo(NftInfo)
