import React, { memo } from 'react'

import LoveBrandCard from '../shared/LoveBrandCard/LoveBrandCard'

const EarnOptionCard = ({ t, ...restProps }) => {
  return (
    <LoveBrandCard
      t={t}
      xlGridSize={4}
      noTruncateDesc
      iconColor='black'
      plainBg
      {...restProps}
    />
  )
}

export default memo(EarnOptionCard)
