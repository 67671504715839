import React, { memo } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

const GoogleIconSvg = ({ disabled }) => {
  return (
    <GoogleIconContainer
      disabled={disabled}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 533.5 544.3'
    >
      <path
        className='st0'
        d='M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7C503.9 431.2 533.5 361.2 533.5 278.4L533.5 278.4z'
      />
      <path
        className='st1'
        d='M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26 -71 0-131.2-47.9-152.8-112.3H28.9v70.1C75.1 486.3 169.2 544.3 272.1 544.3z'
      />
      <path
        className='st2'
        d='M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4L119.3 324.3z'
      />
      <path
        className='st3'
        d='M272.1 107.7c38.8-0.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-0.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1C140.8 155.6 201.1 107.7 272.1 107.7z'
      />
    </GoogleIconContainer>
  )
}

export default memo(GoogleIconSvg)

const SOCIAL_MEDIA_ICON_STYLES = css`
  width: 22px;
  height: 22px;
  margin-right: 10px;
`

const GoogleIconContainer = styled.svg`
  ${({ theme: { palette }, disabled }) =>
    disabled
      ? iconPathStyleForDisable(palette.lightGrey.main)
      : iconPathStyles()};
  ${SOCIAL_MEDIA_ICON_STYLES};
`

const iconPathStyles = () => css`
  .st0 {
    fill: #4285f4;
  }
  .st1 {
    fill: #34a853;
  }
  .st2 {
    fill: #fbbc04;
  }
  .st3 {
    fill: #ea4335;
  }
`

const iconPathStyleForDisable = (color) => css`
  path {
    fill: ${color};
  }
`
