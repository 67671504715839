import React, { memo } from 'react'
import { Box } from '@mui/material'

import CustomForm from '../forms/customForm/CustomForm'
import FormActionsBox from './FormActionsBox'

const FormWrapper = ({ fields, actions, actionsCss, onFormSubmit }) => {
  return (
    <CustomForm onFormSubmit={onFormSubmit}>
      <Box>{fields}</Box>
      <Box flexGrow={1} />
      {actions && (
        <FormActionsBox customCss={actionsCss}>{actions}</FormActionsBox>
      )}
    </CustomForm>
  )
}

export default memo(FormWrapper)
