import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'

import { ACCOUNT_PROFILE_DISPATCH } from '../store/reducers/account/account'
import {
  changeAccountState,
  changeAccountInputAndErrorState,
  resetAccountState
} from '../store/actions/account'
import { changePassword, updateUserAttributes } from '../store/actions/auth'
import { AUTH_FIRST_NAME_ATTR, AUTH_LAST_NAME_ATTR } from '../constants/auth'
import { ERROR_TEXT } from '../constants/messages'
import Account from '../components/account/Account'
import Loading from '../components/shared/Loading/Loading'
import { containsNoValues, deepEqualObjects } from '../util/object.helpers'
import { trim } from '../util/string.helpers'
import {
  checkValidations,
  requiredRule,
  emailRule,
  passwordRule
} from '../util/validation.helpers'
import { extractCognitoUserSocialIdentity } from '../util/auth.helpers'

const AccountContainer = ({
  accountProfile,
  changeAccountState,
  changeAccountInputAndErrorState,
  resetAccountState,
  updateUserAttributes,
  changePassword,
  authProfile
}) => {
  const { t } = useTranslation()

  const signedUpVia = useMemo(() => {
    return extractCognitoUserSocialIdentity(authProfile)
  }, [authProfile])

  useEffect(() => {
    return () => {
      resetAccountState()
    }
  }, [resetAccountState])

  const handleChangePersonalInfo = () => {
    resetErrorSuccess()
    let {
      firstNameTemp,
      lastNameTemp,
      firstName,
      lastName,
      jobPosition,
      jobPositionTemp,
      country,
      countryTemp
    } = accountProfile

    firstNameTemp = trim(firstNameTemp)
    firstName = trim(firstName)
    lastNameTemp = trim(lastNameTemp)
    lastName = trim(lastName)
    jobPositionTemp = trim(jobPositionTemp)
    jobPosition = trim(jobPosition)
    countryTemp = trim(countryTemp)
    country = trim(country)

    const errors = checkValidations({
      firstNameTemp: { value: firstNameTemp, rules: [requiredRule] },
      lastNameTemp: { value: lastNameTemp, rules: [requiredRule] }
    })
    if (containsNoValues(errors)) {
      const isUserInfoChanged = !deepEqualObjects(
        { firstNameTemp, lastNameTemp, jobPositionTemp, countryTemp },
        {
          firstNameTemp: firstName,
          lastNameTemp: lastName,
          jobPositionTemp: jobPosition,
          countryTemp: country
        }
      )
      if (isUserInfoChanged) {
        const attributes = {
          [AUTH_LAST_NAME_ATTR]: lastNameTemp,
          [AUTH_FIRST_NAME_ATTR]: firstNameTemp
        }
        updateUserAttributes(attributes)
      }
    } else {
      changeAccountState(ACCOUNT_PROFILE_DISPATCH.ERROR, errors)
    }
  }

  const handleChangeEmailAddress = () => {
    resetErrorSuccess()
    const { emailTemp, email } = accountProfile
    const errors = checkValidations({
      emailTemp: { value: emailTemp, rules: [requiredRule, emailRule] }
    })
    if (containsNoValues(errors)) {
      if (emailTemp !== email) {
        updateUserAttributes({ email: emailTemp })
      }
    } else {
      changeAccountState(ACCOUNT_PROFILE_DISPATCH.ERROR, errors)
    }
  }

  const handleChangePassword = () => {
    resetErrorSuccess()
    const { oldPassword, newPassword } = accountProfile
    const errors = checkValidations({
      oldPassword: {
        value: oldPassword,
        rules: [requiredRule, passwordRule]
      },
      newPassword: { value: newPassword, rules: [requiredRule, passwordRule] }
    })
    if (containsNoValues(errors)) {
      if (oldPassword !== newPassword) {
        changePassword(oldPassword, newPassword)
      } else {
        changeAccountState(ACCOUNT_PROFILE_DISPATCH.ERROR, {
          newPassword: { message: ERROR_TEXT.OLD_NEW_PASSWORDS_SAME }
        })
      }
    } else {
      changeAccountState(ACCOUNT_PROFILE_DISPATCH.ERROR, errors)
    }
  }

  const resetErrorSuccess = () => {
    changeAccountState(ACCOUNT_PROFILE_DISPATCH.ERROR, {})
    changeAccountState(ACCOUNT_PROFILE_DISPATCH.SUCCESS, {})
  }

  return accountProfile.loading ? (
    <Loading />
  ) : (
    <Account
      accountProfile={accountProfile}
      dispatchAction={changeAccountInputAndErrorState}
      updatePersonalInfo={handleChangePersonalInfo}
      changeEmailAddress={handleChangeEmailAddress}
      changePassword={handleChangePassword}
      t={t}
      signedUpVia={signedUpVia}
    />
  )
}

const mapStateToProps = ({ accountReducer, authReducer }) => {
  const { accountProfile } = accountReducer
  const { authProfile } = authReducer
  return {
    accountProfile,
    authProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeAccountState,
      changeAccountInputAndErrorState,
      resetAccountState,
      updateUserAttributes,
      changePassword
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(AccountContainer)
