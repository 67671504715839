import React, { memo } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import Text from '../text/Text'
import PopoverHelpText from '../PopoverHelpText/PopoverHelpText'

const EnhancedTableHead = ({ headers, t, ...restProps }) => {
  return (
    <TableHead>
      <TableRow>
        {headers.map((header, index) => (
          <TableCell
            key={index}
            align={header.numeric ? 'right' : 'left'}
            padding={header.disablePadding ? 'none' : 'normal'}
            {...restProps}
            sx={{ ...header.sx }}
            // sortDirection={orderBy === header.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === header.id}
              direction={orderBy === header.id ? order : 'asc'}
              onClick={createSortHandler(header.id)}
            >
              <Text variant='subtitle1' fontWeight={500} label={header.label} />
              {orderBy === header.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel> */}
            {header.label && (
              <Text
                variant='subtitle1'
                fontSize={header.smallFont && '10px'}
                fontWeight={header.smallFont ? 700 : 500}
                sx={{ lineHeight: 1.4 }}
                label={
                  <>
                    {header.label}
                    {header.helpText && (
                      <PopoverHelpText label={t(header.helpText)} />
                    )}
                  </>
                }
              />
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const TableHeaderBody = ({ size, headers, data, sx, t }) => {
  return (
    <Table sx={{ minWidth: 750, ...sx }} size={size}>
      <EnhancedTableHead headers={headers} t={t} />
      <TableBody>{data}</TableBody>
    </Table>
  )
}

export default memo(TableHeaderBody)
