import React, { memo } from 'react'
import { Grid } from '@mui/material'

import CustomButton from '../../shared/buttons/CustomButton'
import Input from '../../shared/inputs/Input'
import { POINT_VALUE_MIN_LENGTH } from '../../../constants/validation'
import { QB_ACTION_SUBMIT } from '../../../constants/input'
import {
  transWithArgs,
  validPointValueRule
} from '../../../util/validation.helpers'
import CustomForm from '../../shared/forms/customForm/CustomForm'
import { formatTicks } from '../../../util/string.helpers'
import { EXCHANGE_CONFIG_DISPATCH } from '../../../store/reducers/exchange/exchangeConfig'
import PopoverHelpText from '../../shared/PopoverHelpText/PopoverHelpText'
import Text from '../../shared/text/Text'
import { CSS_FLEX_ALIGNED } from '../../../styles/common'
import { calculateMaxPointsValue } from '../../../util/token.helpers'
import { getMaxThresholdValue } from '../../../util/exchange.helpers'

const MaxValueConfigForm = ({
  exchangeConfigProfile: { maxValueTemp, error, success },
  dispatchAction,
  onFormSubmit,
  tokenProfile,
  partner,
  dashboardConfigs,
  isProcessing,
  t
}) => {
  const maxThresholdValue = getMaxThresholdValue(partner, dashboardConfigs)
  const maxPointsValue = calculateMaxPointsValue(
    maxValueTemp || maxThresholdValue,
    partner,
    tokenProfile,
    t
  )

  return (
    <Grid item xs={12}>
      <Text
        label={
          <>
            {t('exchange.config.max-exchange-value-per-transaction')}
            <PopoverHelpText label={t('exchange.config.help-text')} />
          </>
        }
        fontWeight={700}
        variant='caption'
        component='div'
        sx={{ mb: 0.5 }}
      />
      <CustomForm onFormSubmit={onFormSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Input
              id={EXCHANGE_CONFIG_DISPATCH.MAX_VALUE_TEMP}
              value={maxValueTemp}
              validate={[validPointValueRule]}
              errorMessage={transWithArgs(
                t,
                error[EXCHANGE_CONFIG_DISPATCH.MAX_VALUE_TEMP]
              )}
              successMsg={transWithArgs(
                t,
                success[EXCHANGE_CONFIG_DISPATCH.MAX_VALUE_TEMP]
              )}
              onChange={(value) =>
                dispatchAction(EXCHANGE_CONFIG_DISPATCH.MAX_VALUE_TEMP, value)
              }
              bottomLabel={t('exchange.config.max-value-may-not-exceed', {
                value: formatTicks(maxThresholdValue)
              })}
              size='small'
              inputProps={{ maxLength: POINT_VALUE_MIN_LENGTH }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              ...CSS_FLEX_ALIGNED,
              justifyContent: 'space-between',
              gap: 1,
              pt: '0 !important',
              pb: 1
            }}
          >
            <Text label={maxPointsValue} variant='h5' fontWeight={700} />
            <CustomButton
              type={QB_ACTION_SUBMIT}
              label={t('exchange.config.confirm-max')}
              fullWidth={false}
              disabled={isProcessing}
            />
          </Grid>
        </Grid>
      </CustomForm>
    </Grid>
  )
}

export default memo(MaxValueConfigForm)
