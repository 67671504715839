import { mergeToNewObject } from '../../../util/object.helpers'
import { MAIN_PROFILE_ACTION, BRAND_ACTIONS } from '../../../constants/actions'
import {
  extractBrandData,
  extractErrorMessage,
  extractLocation
} from '../../../util/brand.helpers'
import { buildSuccessMessages } from '../../../util/reducer.helpers'

const DEFAULT_BRAND_NAME = 'Brand Name'

export const brandProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS:
      return mergeToNewObject(state, extractDataFromUser(payload))

    case BRAND_ACTIONS.ON_CHANGE_BRAND_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case BRAND_ACTIONS.ON_CREATE_BRAND:
    case BRAND_ACTIONS.ON_UPDATE_BRAND:
      return mergeToNewObject(state, {
        isProcessing: true
      })

    case BRAND_ACTIONS.ON_CREATE_BRAND_SUCCESS:
      return mergeToNewObject(state, handleUpdateBrandData(payload, state), {
        isProcessing: false,
        error: {},
        success: {}
      })

    case BRAND_ACTIONS.ON_UPDATE_BRAND_SUCCESS:
      const { brand } = payload
      return mergeToNewObject(state, handleUpdateBrandData(brand, state), {
        isProcessing: false
      })

    case BRAND_ACTIONS.ON_UPDATE_BRAND_CONFIG_SUCCESS:
      return mergeToNewObject(state, extractBrandData(payload.brand))

    case BRAND_ACTIONS.ON_CREATE_BRAND_FAILED:
    case BRAND_ACTIONS.ON_UPDATE_BRAND_FAILED:
      return mergeToNewObject(state, {
        ...extractErrorMessage(payload),
        isProcessing: false
      })

    case BRAND_ACTIONS.ON_RESET_BRAND_STATE:
      return mergeToNewObject(state, extractBrandData(payload))

    case MAIN_PROFILE_ACTION.ON_NEW_BRAND_CREATION:
    case BRAND_ACTIONS.ON_RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  id: '',
  locationId: '',
  name: DEFAULT_BRAND_NAME,

  nameTemp: '',

  website: '',
  websiteTemp: '',

  address: '',
  addressTemp: '',

  zip: '',
  zipTemp: '',

  city: '',
  cityTemp: '',

  country: '',
  countryTemp: '',

  logo: '',
  logoUrl: '',
  logoUrlTemp: '',

  brandConfig: {},
  success: {},
  error: {},
  isProcessing: false
}

export const BRAND_DISPATCH = {
  NAME: 'name',
  NAME_TEMP: 'nameTemp',
  WEBSITE: 'website',
  WEBSITE_TEMP: 'websiteTemp',
  ZIP: 'zip',
  ZIP_TEMP: 'zipTemp',
  ADDRESS: 'address',
  ADDRESS_TEMP: 'addressTemp',
  CITY: 'city',
  CITY_TEMP: 'cityTemp',
  LOGO: 'logo',
  LOGO_URL_TEMP: 'logoUrlTemp',
  COUNTRY: 'country',
  COUNTRY_TEMP: 'countryTemp',
  ERROR: 'error',
  SUCCESS: 'success'
}

const extractDataFromUser = (payload) => {
  if (payload?.currentBrand) {
    return extractBrandData(payload?.currentBrand)
  } else {
    return {}
  }
}

const handleUpdateBrandData = (brand, state) => {
  const { id, name, brandConfig, locations } = brand
  const { street, zip, city, country } = locations[0]
  const {
    address: addressTemp,
    city: cityTemp,
    country: countryTemp,
    name: nameTemp,
    website: websiteTemp,
    zip: zipTemp,
    logoUrl: logoUrlTemp
  } = state
  const prevDetails = {
    addressTemp,
    cityTemp,
    countryTemp,
    nameTemp,
    websiteTemp,
    zipTemp,
    logoUrlTemp
  }
  const updatedDetails = {
    addressTemp: street,
    cityTemp: city,
    countryTemp: country,
    nameTemp: name,
    websiteTemp: brandConfig?.websiteUrl,
    zipTemp: zip,
    logoUrlTemp: brandConfig?.logoUrl
  }
  return {
    id,
    name,
    website: brandConfig?.websiteUrl,
    logoUrl: brandConfig?.logoUrl,
    logo: '',
    ...updatedDetails,
    ...(locations && locations.length > 0 && extractLocation(locations[0])),
    success: buildSuccessMessages(prevDetails, updatedDetails),
    brandConfig,
    error: {}
  }
}
