import React, { memo } from 'react'
import { Grid } from '@mui/material'

import MilestoneProgress from '../../shared/ProgressBar/MilestoneProgress'
import { displayCurrencyValue } from '../../../util/number.helpers'
import {
  calculateExchangeElapsedDays,
  calculateBuyerFee,
  displayExchangeVolume
} from '../../../util/billing.helpers'

const ExchangeProgress = ({
  subscribedPackage,
  exchangePerformance: { exchangeOutTxVolume },
  t
}) => {
  const exchangeVolume = calculateBuyerFee(
    exchangeOutTxVolume,
    subscribedPackage
  )
  const { elapsedDays, elapsedPercent } =
    calculateExchangeElapsedDays(subscribedPackage)
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={8}>
        <MilestoneProgress
          title={displayCurrencyValue(exchangeVolume)}
          description={t('billing.exchange-volume-used')}
          completed={displayExchangeVolume(subscribedPackage, exchangeVolume)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MilestoneProgress
          title={elapsedDays}
          description={t('billing.days-elapsed-in-current-exchange-period')}
          completed={elapsedPercent}
        />
      </Grid>
    </Grid>
  )
}

export default memo(ExchangeProgress)
