import React, { memo } from 'react'

import FaIcon from '../FaIcon/FaIcon'
import CustomButton from './CustomButton'

const SmallButton = ({ icon, sx, ...restProps }) => {
  return (
    <CustomButton
      size='small'
      startIcon={icon && <FaIcon icon={icon} width={14} />}
      noPadding
      sx={{ fontSize: 12, p: '3px', height: 28, ...sx }}
      {...restProps}
    />
  )
}

SmallButton.defaultProps = {
  color: 'primary'
}

export default memo(SmallButton)
