import React, { memo } from 'react'
import { Checkbox } from '@mui/material'
import { faSquare, faSquareCheck } from '@fortawesome/pro-light-svg-icons'

import CustomAutocomplete from '../inputs/CustomAutocomplete'
import FaIcon from '../FaIcon/FaIcon'

const MultiSelectWithCheckbox = (props) => {
  return (
    <CustomAutocomplete
      {...props}
      size='small'
      multiple
      disableCloseOnSelect
      limitTags={6}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props}>
            <Checkbox
              icon={<FaIcon icon={faSquare} size='lg' />}
              checkedIcon={<FaIcon icon={faSquareCheck} size='lg' />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        )
      }}
    />
  )
}

export default memo(MultiSelectWithCheckbox)
