import { combineReducers } from 'redux'

import { nftProfile } from './nft'
import { nftsListProfile } from './nftsList'
import { nftUsersProfile } from './nftUsers'

export const nftsReducer = combineReducers({
  nftProfile,
  nftsListProfile,
  nftUsersProfile
})
