import React, { memo } from 'react'
import { Chip } from '@mui/material'

const SquareChip = ({ sx, color, ...restProps }) => {
  return (
    <Chip
      sx={{
        backgroundColor: (theme) => theme.palette[color]?.light,
        color: (theme) => theme.palette[color]?.main,
        borderRadius: '6px',
        pl: 1,
        pr: 1,

        ...sx
      }}
      {...restProps}
    />
  )
}

SquareChip.defaultProps = {
  size: 'small'
}

export default memo(SquareChip)
