import React, { memo } from 'react'
import { Box, Checkbox, Grid, Stack } from '@mui/material'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'

import BaseCard from '../shared/BaseCard/BaseCard'
import ExchangeBrandCard from './ExchangeBrandCard'
import DisplayLabel from '../shared/DisplayLabel/DisplayLabel'
import {
  displayMembersLabel,
  isTokenTypeCrypto
} from '../../util/token.helpers'
import ExchangeStatusChip from './ExchangeStatusChip'
import ExchangeRateLabel from './ExchangeRateLabel'
import BrandTokenLabel from './BrandTokenLabel'
import RegionsLabel from './RegionsLabel'
import { CSS_FLEX_COLUMN } from '../../styles/common'
import { buildExchangeTypeLabel } from '../../util/exchange.helpers'
import BrandLogo from '../shared/BrandLogo/BrandLogo'
import NextActionButton from '../shared/buttons/NextActionButton'
import { TOKEN_TYPE } from '../../constants/token'

const ExchangePartnerCard = ({
  t,
  tokenProfile,
  actions,
  onDetailsClick,
  partner,
  setActiveStep,
  activeStep,
  isCryptoActionsAvailable,
  id,
  onSelectBulkPartners,
  bulkPartnersIds,
  disableMultiSelect
}) => {
  const handleNext = (e) => {
    e.stopPropagation()
    setActiveStep((prevActiveStep) => {
      return {
        ...prevActiveStep,
        [id]: prevActiveStep[id] || 0 + 1
      }
    })
  }

  const handleBack = (e) => {
    e.stopPropagation()
    setActiveStep((prevActiveStep) => {
      return {
        ...prevActiveStep,
        [id]: prevActiveStep[id] - 1
      }
    })
  }
  const {
    tokenExchangeWhitelist,
    name,
    symbol,
    countries,
    offramp,
    onramp,
    estimatedMemberCountLower,
    estimatedMemberCountHigher,
    brand,
    isActiveExchange,
    type,
    isFrom
  } = partner
  const isCrypto = isTokenTypeCrypto(type)
  return (
    <Grid item sm={6} md={4} lg={4} xl={3} sx={{ width: '100%' }}>
      <BaseCard sx={{ p: 0, position: 'relative', ...CSS_FLEX_COLUMN }}>
        {onSelectBulkPartners && !disableMultiSelect && (
          <Checkbox
            checked={bulkPartnersIds[partner.id] || false}
            onChange={() => onSelectBulkPartners(partner)}
            disableRipple
            size='small'
            sx={{
              position: 'absolute',
              top: 5,
              left: 5,
              zIndex: 2,
              p: 0.5,
              color: (theme) => theme.palette.lightGrey.main
            }}
            color='lightGrey'
          />
        )}
        {isCryptoActionsAvailable ? (
          <NextActionButton
            icon={faChevronLeft}
            onClick={handleBack}
            sx={{ left: 20 }}
            disabled={activeStep === 0}
          />
        ) : (
          ''
        )}
        <ExchangeBrandCard
          type={type}
          symbol={symbol}
          onClick={() => onDetailsClick(partner)}
          partner={partner}
          logoStyles={isCrypto && { left: 16 }}
          cryptoNav={{
            brandIcon: isCrypto && (
              <BrandLogo
                size={32}
                src={partner.brand?.brandConfig?.logoUrl}
                sx={{ position: 'relative', top: 38, right: 16 }}
              />
            )
          }}
          {...brand}
        />
        {isCryptoActionsAvailable ? (
          <NextActionButton
            icon={faChevronRight}
            onClick={handleNext}
            sx={{ right: 20 }}
            disabled={activeStep === isCryptoActionsAvailable}
          />
        ) : (
          ''
        )}
        <Grid container spacing={1} p={2} pb={0}>
          <Grid item xs={12}>
            <BrandTokenLabel
              details={{ brandName: brand?.name, tokenName: name, symbol }}
            />
            {/* <Chip size='small' label={brand?.brandConfig?.industrySector} /> */}
            {isActiveExchange && (
              <ExchangeStatusChip
                status={tokenExchangeWhitelist?.status}
                t={t}
              />
            )}
          </Grid>
          {type !== TOKEN_TYPE.SHOPIFY && (
            <Grid item xs={12} sm={6}>
              <RegionsLabel isCrypto={isCrypto} countries={countries} t={t} />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <DisplayLabel
              label={t('common.type')}
              value={t(
                `exchange.filter-options.${buildExchangeTypeLabel(type)}`
              )}
            />
          </Grid>
          <Grid item xs={12} sm={tokenProfile?.symbol ? 6 : 12}>
            <DisplayLabel
              label={t('sidebar.members')}
              value={displayMembersLabel({
                estimatedMemberCountLower,
                estimatedMemberCountHigher
              })}
            />
          </Grid>
          {tokenProfile?.symbol && (
            <Grid item xs={12} sm={6}>
              <ExchangeRateLabel
                tokenProfile={tokenProfile}
                targetToken={{ onramp, offramp, symbol, isFrom }}
                t={t}
                type={type}
              />
            </Grid>
          )}
        </Grid>
        <Box flexGrow={1} />
        {actions && (
          <Stack
            spacing={1}
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ alignItems: 'center', m: 2, justifyContent: 'stretch' }}
          >
            {actions}
          </Stack>
        )}
      </BaseCard>
    </Grid>
  )
}

export default memo(ExchangePartnerCard)
