import React, { memo } from 'react'

import Text from '../text/Text'

const DisplayLabelFormat = ({ label, value, ...restProps }) => {
  return (
    <>
      <Text
        label={label}
        fontWeight={700}
        variant='caption'
        component='div'
        {...restProps}
      />
      {value}
    </>
  )
}

export default memo(DisplayLabelFormat)
