import React, { memo } from 'react'
import { Grid, Box } from '@mui/material'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'

import CustomButton from '../shared/buttons/CustomButton'
import FaIcon from '../shared/FaIcon/FaIcon'
import Text from '../shared/text/Text'

const ConnectBulkPartners = ({
  t,
  onClear,
  onConnectAll,
  selectedPartnersLength,
  isProcessing
}) => {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={8}
      sx={{
        padding: '0 !important',
        position: 'fixed',
        bottom: 32,
        zIndex: 5,
        width: 500,
        left: '50%',
        marginLeft: '-116px'
      }}
    >
      <Box
        display='flex'
        justifyContent='center'
        sx={{
          p: 3,
          width: 'inherit',
          background: (theme) => theme.palette.common.white,
          borderRadius: '20px',
          boxShadow: '0px 7px 30px 0px rgba(0, 0, 0, 0.11)'
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Grid item xs={12} sm={6}>
            <Text
              label={t('exchange.reward-selected', {
                count: selectedPartnersLength
              })}
              fontWeight={700}
              variant='body1'
            />
          </Grid>
          <Grid item xs={12} sm={6} textAlign='right'>
            <CustomButton
              label={t('common.clear')}
              onClick={onClear}
              color='inherit'
              fullWidth={false}
              variant=''
              sx={{ mr: 1 }}
            />
            <CustomButton
              label={t('common.connect-all')}
              fullWidth={false}
              onClick={onConnectAll}
              startIcon={<FaIcon icon={faPlusCircle} />}
              color='success'
              disabled={isProcessing}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

export default memo(ConnectBulkPartners)
