import React, { memo } from 'react'
import { Grid } from '@mui/material'
import { faQrcode } from '@fortawesome/pro-light-svg-icons'

import { MEMBER_SEARCH_PROFILE_DISPATCH } from '../../store/reducers/members/memberSearch'
import SearchInput from '../shared/inputs/SearchInput'
import CustomIconButton from '../shared/buttons/CustomIconButton'

const MembersSearch = ({
  t,
  memberSearchProfile: { query },
  dispatchAction,
  onQrCodeClick,
  onResetSearchClick,
  action
}) => {
  return (
    <>
      {action && <Grid item>{action}</Grid>}
      <Grid item>
        <CustomIconButton
          onClick={onQrCodeClick}
          icon={faQrcode}
          variant='outlined'
          color='primary'
          iconStyles={{ width: 18 }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SearchInput
          id={MEMBER_SEARCH_PROFILE_DISPATCH.QUERY}
          placeholder={t('member.search-member-by-email-or-auth-id')}
          value={query}
          onChange={(value) =>
            dispatchAction(MEMBER_SEARCH_PROFILE_DISPATCH.QUERY, value)
          }
          onResetSearchClick={onResetSearchClick}
        />
      </Grid>
    </>
  )
}

export default memo(MembersSearch)
