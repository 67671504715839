import React, { memo } from 'react'
import { Grid } from '@mui/material'

import { QB_LABEL_TEXT } from '../../constants/messages'
import Dropdown from '../shared/inputs/Dropdown'
import { fetchRegionsList } from '../../util/country.helpers'
import { extractAllLoveBrandTypeOptions } from '../../util/extensions/extensions.helpers'
import { LOVE_BRANDS_FILTERS_PROFILE_DISPATCH } from '../../store/reducers/extensions/loveBrandsFilters'

const BrandsYouLoveFilters = ({
  t,
  dispatchAction,
  loveBrandsFiltersProfile: { region, type, data }
}) => {
  return (
    <Grid
      container
      spacing={3}
      sx={{ display: 'flex', alignItems: 'flex-end' }}
    >
      <Grid item xs={12} md={3} sm={6}>
        <Dropdown
          id={LOVE_BRANDS_FILTERS_PROFILE_DISPATCH.TYPE}
          label={t('common.type')}
          options={extractAllLoveBrandTypeOptions(data, t)}
          onChange={({ target }) => {
            dispatchAction(
              LOVE_BRANDS_FILTERS_PROFILE_DISPATCH.TYPE,
              target.value
            )
          }}
          value={type}
          size='small'
        />
      </Grid>
      <Grid item xs={12} md={3} sm={6}>
        <Dropdown
          id={LOVE_BRANDS_FILTERS_PROFILE_DISPATCH.REGION}
          label={t(QB_LABEL_TEXT.REGION)}
          options={fetchRegionsList(t)}
          value={region}
          onChange={({ target }) => {
            dispatchAction(
              LOVE_BRANDS_FILTERS_PROFILE_DISPATCH.REGION,
              target.value
            )
          }}
          size='small'
        />
      </Grid>
    </Grid>
  )
}

export default memo(BrandsYouLoveFilters)
