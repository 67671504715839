import React, { memo } from 'react'
import { Box } from '@mui/material'

import Text from '../text/Text'
import FaIcon from '../FaIcon/FaIcon'

const BrandCardLabelIcon = ({ label, icon, iconColor = 'white' }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        top: '10px',
        right: '10px',
        color: `common.${iconColor}`,
        alignItems: 'center'
      }}
    >
      <Text label={label} variant='h4' sx={{ mr: '5px', fontWeight: '500' }} />
      <FaIcon icon={icon} />
    </Box>
  )
}

export default memo(BrandCardLabelIcon)
