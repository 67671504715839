const INTEGRATION_OPTION_DATA = {
  erc20: 'integration.full-integration',
  external: 'integration.light-integration'
}

export const buildIntegrationOptions = (t) => {
  return Object.keys(INTEGRATION_OPTION_DATA).map((key) => ({
    value: key,
    label: t(INTEGRATION_OPTION_DATA[key])
  }))
}
