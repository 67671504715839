import React, { memo } from 'react'

import Confirmed from './Confirmed'

const SomethingWentWrong = (props) => {
  return (
    <Confirmed
      title='error.ooops'
      description='error.it-looks-like-something-wrong'
      {...props}
    />
  )
}

export default memo(SomethingWentWrong)
