import { memo } from 'react'
import { CardContent } from '@mui/material'

import BaseCard from '../BaseCard/BaseCard'

const BaseCardContent = ({ cardCss, contentCss, children, chart }) => {
  return (
    <BaseCard
      sx={{
        ...cardCss
      }}
    >
      <CardContent
        sx={{
          ...contentCss
        }}
      >
        {children}
      </CardContent>
      {chart}
    </BaseCard>
  )
}

export default memo(BaseCardContent)
