import { TOKEN_STATS_FILTER } from '../../../constants/token'
import { calculateExchangeInOutValue } from '../../../util/chart/transactionsGraph.helpers'
import { buildExchangePartnersStats } from '../../../util/exchange.helpers'
import { mergeToNewObject } from '../../../util/object.helpers'
import {
  extractBrandTokensFromMainData,
  extractTokenIds
} from '../../../util/token.helpers'
import {
  BILLING_ACTION,
  BRAND_ACTIONS,
  EXCHANGE_PARTNER_ACTION,
  MAIN_PROFILE_ACTION,
  TOKEN_ACTIONS
} from './../../../constants/actions'

export const mainProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case MAIN_PROFILE_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA:
      return mergeToNewObject(state, { loading: true })

    case MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS:
      const { brands, brandTokens, packageSubscriptions, dashboardConfigs } =
        payload
      return mergeToNewObject(
        state,
        {
          loading: false,
          brands,
          brandTokens,
          packageSubscriptions,
          dashboardConfigs
        },
        handleExchangePartnersData(payload)
      )

    case MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_FAILED:
      return mergeToNewObject(state, { loading: false })

    case BRAND_ACTIONS.ON_CREATE_BRAND_SUCCESS:
      return mergeToNewObject(state, {
        brands: [...state.brands, payload],
        brandTokens: {
          ...state.brandTokens,
          [payload.id]: { tokens: [], brand: payload }
        },
        isNewBrandCreation: false
      })

    case BRAND_ACTIONS.ON_UPDATE_BRAND_SUCCESS:
      const { brand } = payload
      return mergeToNewObject(state, {
        brands: state.brands.map((item) =>
          item.id === brand.id ? brand : item
        ),
        brandTokens: {
          ...state.brandTokens,
          [brand.id]: { ...state.brandTokens[brand?.id], brand }
        }
      })

    case TOKEN_ACTIONS.ON_GET_TOKENS_SUCCESS:
      const { tokens, brandId } = payload
      return mergeToNewObject(state, {
        brandTokens: {
          ...state.brandTokens,
          [brandId]: { ...state.brandTokens[brandId], tokens }
        }
      })

    case BILLING_ACTION.ON_UPDATE_PACKAGE_SUBSCRIPTION_SUCCESS:
      return mergeToNewObject(state, updateSubscriptionState(payload, state))

    case MAIN_PROFILE_ACTION.ON_NEW_BRAND_CREATION:
      return mergeToNewObject(state, { isNewBrandCreation: true })

    case MAIN_PROFILE_ACTION.ON_SWITCH_BRAND_AND_TOKEN:
      return mergeToNewObject(state, { loading: true })

    case TOKEN_ACTIONS.ON_GET_TOKEN_BY_ID_SUCCESS:
      const { token } = payload
      const brandTokensState = state.brandTokens[payload.brandId]
      return mergeToNewObject(state, {
        brandTokens: {
          ...state.brandTokens,
          [payload.brandId]: {
            ...brandTokensState,
            tokens: brandTokensState.tokens.map((tokenData) =>
              tokenData.id === token.id ? token : tokenData
            )
          }
        }
      })

    case TOKEN_ACTIONS.ON_GET_TOKEN_STATS_FOR_LAST_AND_CURRENT_WEEK_SUCCESS:
      return mergeToNewObject(state, extractTokenStatsData(payload))

    case EXCHANGE_PARTNER_ACTION.ON_GET_ALL_PARTNERS_SUCCESS:
      return mergeToNewObject(state, {
        exchangePartnersStats: buildExchangePartnersStats(payload)
      })

    case BILLING_ACTION.ON_GET_PACKAGE_SUBSCRIPTIONS_SUCCESS:
      return mergeToNewObject(state, {
        packageSubscriptions: payload.packageSubscriptions
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  brands: [],
  loading: true,
  error: {},
  brandTokens: {},
  isNewBrandCreation: false,
  lastWeekExchangeTxVolume: 0,
  thisWeekExchangeTxVolume: 0,
  packageSubscriptions: {},
  dashboardConfigs: {},
  exchangePartnersStats: {
    incomingRequests: [],
    activeCount: 0,
    isCryptoFiatConnectionExists: false,
    isMilesAndMoreConnectionExists: false,
    milesAndMoreExchangePair: null
  }
}

export const MAIN_PROFILE_DISPATCH = {
  IS_NEW_TOKEN_CREATION: 'isNewBrandCreation'
}

const extractTokenStatsData = (payload) => {
  const tokenStats = payload?.tokenStats
  const tokenProfile = payload?.tokenProfile
  const currentWeek = tokenStats[TOKEN_STATS_FILTER.THIS_WEEK]
  const lastWeek = tokenStats[TOKEN_STATS_FILTER.LAST_WEEK]
  const state = {}
  if (lastWeek) {
    state.lastWeekExchangeTxVolume = calculateExchangeInOutValue(
      lastWeek,
      tokenProfile
    )
  }
  if (currentWeek) {
    state.thisWeekExchangeTxVolume = calculateExchangeInOutValue(
      currentWeek,
      tokenProfile
    )
  }
  return state
}

const updateSubscriptionState = (updatedSubscription, state) => {
  const invoice = updatedSubscription
  const packageSubscriptions = {
    ...state.packageSubscriptions,
    invoice
  }
  return {
    packageSubscriptions
  }
}

const handleExchangePartnersData = (payload) => {
  const exchangePartners = payload?.exchangePartners
  const ownTokenIds = extractTokenIds(
    extractBrandTokensFromMainData(payload, payload?.currentBrand?.id)
  )
  if (
    exchangePartners &&
    exchangePartners.length > 0 &&
    ownTokenIds.length > 0
  ) {
    return {
      exchangePartnersStats: buildExchangePartnersStats({
        exchangePartners,
        ownTokenIds
      })
    }
  }
}
