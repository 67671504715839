import React, { useEffect, useState, lazy } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { bindActionCreators } from 'redux'

import Integration from '../components/integration/Integration'
import { isDashboardReady } from '../util/dashboard.helpers'
import { copyToClipboard, openExternalUrl } from '../util/app/app.helpers'
import { updateUserAccount } from '../store/actions/account'
import {
  changeTokenState,
  getTokens,
  updateToken
} from '../store/actions/token'
import { buildMetadataAttributes } from '../util/account.helpers'
import {
  deleteAPIKey,
  resetAPIKeyState,
  changeAPIKeyState,
  createAPIKey
} from '../store/actions/apiKey'
import {
  DEFAULT_CONTAINER_STATE,
  INTEGRATION_PROCESS_STEP,
  PACKAGE_BILLING_PROCESS_STEP
} from '../constants/containerStates'
import { containsNoValues } from '../util/object.helpers'
import {
  checkValidations,
  checkValueExistsInListRule,
  formatValidator,
  requiredRule
} from '../util/validation.helpers'
import { API_KEY_PROFILE_DISPATCH } from '../store/reducers/apiKeys/apiKeyForm'
import { isStarterPackagePlan } from '../util/billing.helpers'
import { LWA_SETUP_URL } from '../constants/externalResources'
import { isTokenStatusActive } from '../util/token.helpers'
import ChangeIntegrationType from '../components/popups/integration/ChangeIntegrationType'
import {
  getUserSurveys,
  submitUserSurvey,
  updateUserSurvey
} from '../store/actions/survey'

const ManageAPIKeyPopup = lazy(() =>
  import('../components/popups/integration/ManageAPIKey')
)
const NoPackageSubscriptionPopup = lazy(() =>
  import('../components/popups/billing/NoPackageSubscription')
)

const IntegrationContainer = ({
  updateUserAccount,
  getTokens,
  deleteAPIKey,
  changeAPIKeyState,
  createAPIKey,
  resetAPIKeyState,
  apiKeyFormProfile,
  billingProfile,
  dashboardSetupState,
  tokenProfile,
  updateToken,
  apiKeysProfile,
  surveyProfile,
  getUserSurveys,
  submitUserSurvey,
  updateUserSurvey,
  ...restProps
}) => {
  const isDashboardSetupReady = isDashboardReady(dashboardSetupState)
  const isAllowAccessForActiveToken =
    isTokenStatusActive(tokenProfile) && isDashboardSetupReady
  const { t } = useTranslation()

  useEffect(() => {
    getTokens()
    getUserSurveys()
  }, [])

  useEffect(() => {
    const action = apiKeyFormProfile.manageAction
    if (action) {
      buildSuccessPopup(action)
    }
  }, [apiKeyFormProfile.manageAction])

  const [popupState, setPopupState] = useState(DEFAULT_CONTAINER_STATE.NONE)
  const [apiKeyState, setAPIKeyState] = useState({})
  const [selectedKey, setSelectedKey] = useState(null)

  const buildSuccessPopup = (action) => {
    switch (action) {
      case INTEGRATION_PROCESS_STEP.CREATE_API_KEY:
      case INTEGRATION_PROCESS_STEP.DELETE_API_KEY:
        handleNoPopup()
        break

      default:
        break
    }
  }

  const handleToggleDisplayKey = ({ id }) => {
    setAPIKeyState({
      ...apiKeyState,
      [id]: {
        displayKey: !apiKeyState?.[id]?.displayKey,
        isKeyCopied: false
      }
    })
  }

  const handleDeleteAPIKey = (apiKey) => {
    if (isAllowAccessForActiveToken) {
      changeAPIKeyState(API_KEY_PROFILE_DISPATCH.NAME_TEMP, apiKey.name)
      setSelectedKey(apiKey)
      setPopupState(INTEGRATION_PROCESS_STEP.DELETE_API_KEY)
    } else {
      noPackageSubscribedPopupState()
    }
  }

  const handleCopyAPIKey = ({ id, apiKey }) => {
    copyToClipboard(apiKey)
    setAPIKeyState({
      ...apiKeyState,
      [id]: {
        ...apiKeyState?.[id],
        isKeyCopied: true
      }
    })
  }

  const handleGetAPIKey = () => {
    if (isAllowAccessForActiveToken) {
      setPopupState(INTEGRATION_PROCESS_STEP.CREATE_API_KEY)
    } else {
      noPackageSubscribedPopupState()
    }
  }

  const handleCloseImportantNote = () => {
    updateUserAccount(buildMetadataAttributes({ hide_integration_note: true }))
  }

  const handleLikeUnlikeSurvey = (surveyId, questionId) => {
    const userSurvey = surveyProfile.userSurveys?.[surveyId]
    const userSurveyId = userSurvey?.id
    if (userSurveyId) {
      updateUserSurvey(
        surveyId,
        userSurveyId,
        questionId,
        !userSurvey.response?.[questionId]?.liked
      )
    } else {
      submitUserSurvey(surveyId, questionId, true)
    }
  }

  const handleOnSubmitPopup = () => {
    switch (popupState) {
      case INTEGRATION_PROCESS_STEP.CREATE_API_KEY:
        changeAPIKeyState(API_KEY_PROFILE_DISPATCH.ERROR, {})
        const { nameTemp } = apiKeyFormProfile
        const errors = checkValidations({
          nameTemp: {
            value: nameTemp,
            rules: [
              requiredRule,
              checkValueExistsInListRule(
                apiKeysProfile.apiKeys.map((key) => key.name),
                formatValidator('error.api-key-name-already-exists')
              )
            ]
          }
        })
        if (containsNoValues(errors)) {
          createAPIKey()
        } else {
          changeAPIKeyState(API_KEY_PROFILE_DISPATCH.ERROR, errors)
        }

        break

      case INTEGRATION_PROCESS_STEP.DELETE_API_KEY:
        deleteAPIKey(selectedKey.id)
        break

      case INTEGRATION_PROCESS_STEP.SAVE_TOKEN_TYPE:
        const { type, typeTemp } = tokenProfile
        const tokenTypeChanged = type && typeTemp && type !== typeTemp
        if (tokenTypeChanged) {
          const { typeTemp } = tokenProfile
          updateToken({ type: typeTemp })
          handleNoPopup()
        }
        break

      default:
        break
    }
  }

  const handleOnCancelPopup = () => {
    switch (popupState) {
      case INTEGRATION_PROCESS_STEP.CREATE_API_KEY:
      case INTEGRATION_PROCESS_STEP.DELETE_API_KEY:
        resetAPIKeyState()
        handleNoPopup()
        break

      default:
        handleNoPopup()
    }
  }

  const handleNoPopup = () => {
    setPopupState(DEFAULT_CONTAINER_STATE.NONE)
    changeAPIKeyState(
      API_KEY_PROFILE_DISPATCH.MANAGE_ACTION,
      DEFAULT_CONTAINER_STATE.NONE
    )
  }

  const noPackageSubscribedPopupState = () => {
    setPopupState(PACKAGE_BILLING_PROCESS_STEP.NO_PACKAGE_SUBSCRIBED)
  }

  const handleClickLWASetup = () => {
    const paymentDetails = billingProfile.subscribedPackage?.details
    if (
      !isAllowAccessForActiveToken ||
      isStarterPackagePlan(paymentDetails?.name)
      // || !paymentDetails?.isYearly // @TODO: Need to be decided on yearly/monthly
    ) {
      noPackageSubscribedPopupState()
    } else {
      openExternalUrl(LWA_SETUP_URL)
    }
  }

  const handleFormSubmit = () => {
    setPopupState(INTEGRATION_PROCESS_STEP.SAVE_TOKEN_TYPE)
  }

  const renderPopup = () => {
    switch (popupState) {
      case INTEGRATION_PROCESS_STEP.CREATE_API_KEY:
        return (
          <ManageAPIKeyPopup
            t={t}
            onClose={handleOnCancelPopup}
            onSubmit={handleOnSubmitPopup}
            apiKeyFormProfile={apiKeyFormProfile}
            dispatchAction={changeAPIKeyState}
          />
        )

      case INTEGRATION_PROCESS_STEP.DELETE_API_KEY:
        return (
          <ManageAPIKeyPopup
            t={t}
            onClose={handleOnCancelPopup}
            onSubmit={handleOnSubmitPopup}
            apiKeyFormProfile={apiKeyFormProfile}
            isDelete
          />
        )

      case PACKAGE_BILLING_PROCESS_STEP.NO_PACKAGE_SUBSCRIBED:
        return (
          <NoPackageSubscriptionPopup
            t={t}
            isDashboardSetupReady={isDashboardSetupReady}
          />
        )

      case INTEGRATION_PROCESS_STEP.SAVE_TOKEN_TYPE:
        return (
          <ChangeIntegrationType
            t={t}
            onClose={handleOnCancelPopup}
            onSubmit={handleOnSubmitPopup}
          />
        )

      default:
        break
    }
  }

  return (
    <>
      <Integration
        {...restProps}
        apiKeysProfile={apiKeysProfile}
        tokenProfile={tokenProfile}
        onToggleDisplayKey={handleToggleDisplayKey}
        onDeleteAPIKey={handleDeleteAPIKey}
        onCopyAPIKey={handleCopyAPIKey}
        onGetAPIKey={handleGetAPIKey}
        onCloseImportantNote={handleCloseImportantNote}
        apiKeyState={apiKeyState}
        onClickLWASetup={handleClickLWASetup}
        isDashboardSetupReady={isDashboardSetupReady}
        billingProfile={billingProfile}
        onFormSubmit={handleFormSubmit}
        surveyProfile={surveyProfile}
        onLikeUnlikeSurvey={handleLikeUnlikeSurvey}
        t={t}
      />
      {renderPopup()}
    </>
  )
}

const mapStateToProps = ({
  dashboardReducer,
  tokensReducer,
  accountReducer,
  apiKeysReducer,
  mainReducer,
  surveyReducer,
  extensionsReducer
}) => {
  const { billingProfile } = mainReducer
  const { dashboardSetupProfile } = dashboardReducer
  const { tokenProfile, tokenStatsProfile } = tokensReducer
  const { apiKeysProfile, apiKeyFormProfile } = apiKeysReducer
  const { surveyProfile } = surveyReducer
  const { extensionsProfile } = extensionsReducer
  return {
    dashboardSetupState: dashboardSetupProfile.dashboardSetupState,
    tokenProfile,
    hideIntegrationNote:
      accountReducer.accountProfile.metadata?.hideIntegrationNote,
    apiKeysProfile,
    apiKeyFormProfile,
    billingProfile,
    exchangeTxCount: tokenStatsProfile.exchangeTxCount,
    surveyProfile,
    extensionsProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserAccount,
      getTokens,
      deleteAPIKey,
      changeAPIKeyState,
      createAPIKey,
      resetAPIKeyState,
      changeTokenState,
      updateToken,
      getUserSurveys,
      submitUserSurvey,
      updateUserSurvey
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegrationContainer)
