import { TXN_COLUMNS_ACTION } from '../../../constants/actions'
import { mergeToNewObject } from '../../../util/object.helpers'
import { defaultTxnColumns } from '../../../util/transaction/transaction.helpers'

export const transactionColumnsProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case TXN_COLUMNS_ACTION.ON_SELECTED:
      const { txnColumns, error } = payload
      return mergeToNewObject(state, {
        ...(Object.keys(txnColumns).length && { txnColumns }),
        error
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  txnColumns: defaultTxnColumns(),
  error: {}
}
