import { combineReducers } from 'redux'

import { exchangeSettingsProfile } from './exchangeSettings'
import { partnersProfile } from './partners'
import { exchangeFeedbackProfile } from './exchangeFeedback'
import { exchangeConfigProfile } from './exchangeConfig'
import { buyNftProfile } from './buyNft'
import { exchangeTermsProfile } from './exchangeTerms'

export const exchangeReducer = combineReducers({
  exchangeSettingsProfile,
  partnersProfile,
  exchangeFeedbackProfile,
  exchangeConfigProfile,
  buyNftProfile,
  exchangeTermsProfile
})
