import React, { memo } from 'react'
import { Grid } from '@mui/material'

import Text from '../text/Text'
import TitleWithPopover from '../TitleWithPopover/TitleWithPopover'

const DisplayLabelWithRow = ({ label, helpText, value }) => {
  return (
    <Grid container alignItems='center'>
      <Grid item xs={12} sm={6}>
        <TitleWithPopover
          fontWeight={700}
          variant='caption'
          component='div'
          title={label}
          helpText={helpText}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Text
          label={value}
          variant='caption'
          component='div'
          sx={{
            maxHeight: 96,
            overflow: 'auto',
            lineHeight: 1.3
          }}
        />
      </Grid>
    </Grid>
  )
}

export default memo(DisplayLabelWithRow)
