import { QB_COGNITO_ERRORS } from '../../../constants/apiErrors'
import { ERROR_TEXT, QB_ERROR_TEXT } from '../../../constants/messages'
import {
  mergeToNewObject,
  extractInputValueAndError
} from '../../../util/object.helpers'
import { formatValidator } from '../../../util/validation.helpers'
import { AUTH_LOGIN_ACTION } from './../../../constants/actions'

export const loginProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case AUTH_LOGIN_ACTION.CHANGE_STATE:
      const { property, value } = payload
      return mergeToNewObject(state, { [property]: value })

    case AUTH_LOGIN_ACTION.CHANGE_INPUT_AND_ERROR_STATE:
      return mergeToNewObject(
        state,
        extractInputValueAndError(payload, state.error)
      )

    case AUTH_LOGIN_ACTION.RESET_STATE:
      return INITIAL_STATE

    case AUTH_LOGIN_ACTION.ON_LOG_IN:
      return mergeToNewObject(state, {
        isProcessing: true
      })

    case AUTH_LOGIN_ACTION.ON_LOG_IN_SUCCESS:
      return mergeToNewObject(state, {
        isProcessing: false
      })

    case AUTH_LOGIN_ACTION.ON_LOG_IN_FAILED:
      return mergeToNewObject(state, {
        error: extractLoginErrors(payload),
        isProcessing: false
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  email: '',
  password: '',
  error: {},
  isProcessing: false
}

const extractLoginErrors = ({ code }) => {
  switch (code) {
    case QB_COGNITO_ERRORS.USER_NOT_CONFIRMED_EXCEPTION:
      return {
        email: formatValidator(ERROR_TEXT.EMAIL_VERIFICATION_FAILED)
      }
    case QB_COGNITO_ERRORS.NOT_AUTHORIZED_EXCEPTION:
      return {
        email: true,
        password: formatValidator(QB_ERROR_TEXT.ACCESS_DENIED)
      }
    default:
      return {
        email: true,
        password: formatValidator(QB_ERROR_TEXT.SOMETHING_WENT_WRONG)
      }
  }
}

export const LOGIN_PROFILE_DISPATCH = {
  EMAIL: 'email',
  PASSWORD: 'password',
  ERROR: 'error'
}
