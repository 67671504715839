import React, { memo } from 'react'
import { Box, Grid } from '@mui/material'

import CustomButton from '../../shared/buttons/CustomButton'
import Text from '../../shared/text/Text'
import PageContainer from '../../shared/PageContainer/PageContainer'
import { TOKEN_PROFILE_DISPATCH } from '../../../store/reducers/tokens/token'
import Input from '../../shared/inputs/Input'
import {
  POINT_NAME_MAX_LENGTH,
  POINT_SYMBOL_MIN_LENGTH,
  POINT_SYMBOL_MAX_LENGTH
} from '../../../constants/validation'
import {
  LABEL_TEXT,
  QB_ERROR_TEXT,
  QB_LABEL_TEXT
} from '../../../constants/messages'
import BaseForm from '../../shared/BaseForm/BaseForm'
import LoyaltyPointEconomicsFields from '../../tokenInfo/LoyaltyPointEconomicsFields'
import LoyaltyPointOverviewFields from '../../tokenInfo/LoyaltyPointOverviewFields'
import BlockchainPreferenceFields from '../../tokenInfo/BlockchainPreferenceFields'
import { QB_ACTION_SUBMIT } from '../../../constants/input'
import { transWithArgs } from '../../../util/validation.helpers'

const LoyaltyPoint = ({
  tokenProfile: {
    nameTemp,
    symbolTemp,
    pointValueTemp,
    error,
    isProcessing,
    binanceMirrorTemp,
    ...restTokenFields
  },
  dispatchAction,
  onClickContinue,
  onClickLeftButton,
  isTokenCreated,
  t
}) => {
  const title = t('token-setup.loyalty-point-details')
  return (
    <PageContainer title={title}>
      <BaseForm
        onFormSubmit={onClickContinue}
        title={title}
        description={t('token-setup.loyalty-point-desc')}
        fields={
          <Grid container spacing={3}>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <Input
                id={TOKEN_PROFILE_DISPATCH.NAME_TEMP}
                label={t(LABEL_TEXT.POINT_NAME)}
                value={nameTemp}
                errorMessage={transWithArgs(
                  t,
                  error[TOKEN_PROFILE_DISPATCH.NAME_TEMP]
                )}
                onChange={(value) =>
                  dispatchAction(TOKEN_PROFILE_DISPATCH.NAME_TEMP, value)
                }
                bottomLabel={t(QB_ERROR_TEXT.CHARACTERS_MIN_MAX, {
                  min: POINT_SYMBOL_MIN_LENGTH,
                  max: POINT_NAME_MAX_LENGTH
                })}
                inputProps={{ maxLength: POINT_NAME_MAX_LENGTH }}
                size='small'
                disabled={isTokenCreated}
              />
            </Grid>
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <Input
                id={TOKEN_PROFILE_DISPATCH.SYMBOL_TEMP}
                label={t(LABEL_TEXT.POINT_SYMBOL)}
                value={symbolTemp}
                errorMessage={transWithArgs(
                  t,
                  error[TOKEN_PROFILE_DISPATCH.SYMBOL_TEMP]
                )}
                onChange={(value) =>
                  dispatchAction(TOKEN_PROFILE_DISPATCH.SYMBOL_TEMP, value)
                }
                bottomLabel={t(QB_ERROR_TEXT.CHARACTERS_MIN_MAX, {
                  min: POINT_SYMBOL_MIN_LENGTH,
                  max: POINT_SYMBOL_MAX_LENGTH
                })}
                inputProps={{ maxLength: POINT_SYMBOL_MAX_LENGTH }}
                size='small'
                disabled={isTokenCreated}
              />
            </Grid>
            <LoyaltyPointOverviewFields
              t={t}
              dispatchAction={dispatchAction}
              error={error}
              {...restTokenFields}
            />

            <Grid item xs={12}>
              <LoyaltyPointEconomicsFields
                t={t}
                pointValue={pointValueTemp}
                error={error}
                dispatchAction={dispatchAction}
              />
              <Text
                variant='body1'
                color='textprimary'
                label={t('token-setup.value-estimated-approve')}
                sx={{ mt: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <BlockchainPreferenceFields
                t={t}
                binanceMirrorTemp={binanceMirrorTemp}
                dispatchAction={dispatchAction}
              />
            </Grid>
          </Grid>
        }
        actions={
          <>
            <CustomButton
              label={t(QB_LABEL_TEXT.BACK)}
              onClick={onClickLeftButton}
              sx={{ mr: 1 }}
              color='inherit'
              fullWidth={false}
              variant=''
            />
            <Box sx={{ flex: '1 1 auto' }} />
            <CustomButton
              type={QB_ACTION_SUBMIT}
              variant='contained'
              label={t(
                `token-setup.${isTokenCreated ? 'update' : 'create'}-point`
              )}
              fullWidth={false}
              disabled={isProcessing}
            />
          </>
        }
      />
    </PageContainer>
  )
}

export default memo(LoyaltyPoint)
