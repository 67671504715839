import { QB_COGNITO_ERRORS } from '../../../constants/apiErrors'
import {
  ERROR_TEXT,
  MESSAGE_TEXT,
  QB_ERROR_TEXT
} from '../../../constants/messages'
import {
  mergeToNewObject,
  extractInputValueAndError
} from '../../../util/object.helpers'
import { formatValidator } from '../../../util/validation.helpers'
import { AUTH_FORGOT_PASSWORD_ACTION } from './../../../constants/actions'

export const forgotPasswordProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case AUTH_FORGOT_PASSWORD_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case AUTH_FORGOT_PASSWORD_ACTION.CHANGE_INPUT_AND_ERROR_STATE:
      return mergeToNewObject(
        state,
        extractInputValueAndError(payload, state.error)
      )

    case AUTH_FORGOT_PASSWORD_ACTION.RESET_STATE:
      return INITIAL_STATE

    case AUTH_FORGOT_PASSWORD_ACTION.ON_REQUEST_PASSWORD_CHANGE:
      return mergeToNewObject(state, {
        isProcessing: true
      })

    case AUTH_FORGOT_PASSWORD_ACTION.ON_REQUEST_PASSWORD_CHANGE_SUCCESS:
      return mergeToNewObject(state, {
        success: {
          email: formatValidator(MESSAGE_TEXT.FORGOT_PASSWORD_SUCCESS)
        },
        isProcessing: false
      })

    case AUTH_FORGOT_PASSWORD_ACTION.ON_REQUEST_PASSWORD_CHANGE_FAILED:
      return mergeToNewObject(state, {
        error: extractErrors(payload),
        isProcessing: false
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  email: '',
  error: {},
  success: {},
  isProcessing: false
}

export const FORGOT_PASSWORD_PROFILE_DISPATCH = {
  EMAIL: 'email',
  ERROR: 'error'
}

const extractErrors = ({ code }) => {
  switch (code) {
    case QB_COGNITO_ERRORS.RESOURCE_NOT_FOUND_EXCEPTION:
      return {
        email: formatValidator(ERROR_TEXT.FORGOT_PASSWORD_ERROR)
      }
    case QB_COGNITO_ERRORS.LIMIT_EXCEEDED_EXCEPTION:
      return {
        email: formatValidator(QB_ERROR_TEXT.ATTEMPT_LIMIT_EXCEEDED)
      }
    default:
      return {
        email: formatValidator(QB_ERROR_TEXT.SOMETHING_WENT_WRONG)
      }
  }
}
