import React, { memo } from 'react'
import { faFileCsv } from '@fortawesome/pro-light-svg-icons'

import CustomButton from '../buttons/CustomButton'
import FaIcon from '../FaIcon/FaIcon'

const DownloadButton = (props) => {
  return (
    <CustomButton
      startIcon={<FaIcon icon={faFileCsv} />}
      variant='outlined'
      noPadding
      sx={{ lineHeight: 2, height: 40 }}
      {...props}
    />
  )
}

DownloadButton.defaultProps = {
  fullWidth: false
}

export default memo(DownloadButton)
