import React, { memo } from 'react'
import { CSS_FLEX_COLUMN } from '../../../../styles/common'

const CustomForm = ({ children, onFormSubmit }) => {
  const handleOnSubmit = (e) => {
    e.preventDefault()
    onFormSubmit(e)
  }
  return (
    <form
      onSubmit={handleOnSubmit}
      noValidate
      style={{ ...CSS_FLEX_COLUMN, height: '100%' }}
    >
      {children}
    </form>
  )
}

export default memo(CustomForm)
