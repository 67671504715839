import { mergeToNewObject } from '../../../util/object.helpers'
import {
  BILLING_ACTION,
  MAIN_PROFILE_ACTION,
  TOKEN_ACTIONS
} from '../../../constants/actions'
import { selectSubscribedPackage } from '../../../util/billing.helpers'

export const billingProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case BILLING_ACTION.ON_CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case BILLING_ACTION.ON_GENERATE_CHECKOUT_SESSION:
    case BILLING_ACTION.ON_GENERATE_VIEW_BILLING_SESSION:
      return mergeToNewObject(state, {
        signedUrl: ''
      })

    case BILLING_ACTION.ON_GENERATE_CHECKOUT_SESSION_FAILED:
    case BILLING_ACTION.ON_GENERATE_VIEW_BILLING_SESSION_FAILED:
      return mergeToNewObject(state, {
        signedUrl: ''
      })

    case BILLING_ACTION.ON_GENERATE_CHECKOUT_SESSION_SUCCESS:
    case BILLING_ACTION.ON_GENERATE_VIEW_BILLING_SESSION_SUCCESS:
      return mergeToNewObject(state, {
        signedUrl: payload.url
      })

    case MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS:
      const { packageSubscriptions, currentToken } = payload
      const subscribedPackage = selectSubscribedPackage(
        packageSubscriptions,
        currentToken
      )
      const isYearly =
        subscribedPackage?.details?.isYearly !== undefined
          ? subscribedPackage?.details?.isYearly
          : state.isYearly
      return mergeToNewObject(state, {
        subscribedPackage,
        isYearly
      })

    case BILLING_ACTION.ON_GET_EXCHANGE_PERFORMANCE_SUCCESS:
      const { exchangeInTxVolume, exchangeOutTxVolume } = payload
      return mergeToNewObject(state, {
        exchangePerformance: {
          exchangeInTxVolume,
          exchangeOutTxVolume
        }
      })

    case BILLING_ACTION.ON_UPDATE_PACKAGE_SUBSCRIPTION_SUCCESS:
      return mergeToNewObject(state, updateSubscriptionState(payload, state))

    case TOKEN_ACTIONS.ON_CREATE_TOKEN_SUCCESS:
      return mergeToNewObject(state, { isYearly: true })

    case BILLING_ACTION.ON_GET_PACKAGE_SUBSCRIPTIONS_SUCCESS:
      return mergeToNewObject(state, {
        subscribedPackage: getSubscribedPackage(payload)
      })

    case BILLING_ACTION.ON_REACTIVATE_SUBSCRIPTION:
      return mergeToNewObject(state, {
        reactivationStarted: true
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  isYearly: true,
  subscribedPackage: null,
  exchangePerformance: {
    exchangeInTxVolume: 0,
    exchangeOutTxVolume: 0
  },
  signedUrl: '',
  reactivationStarted: false
}

export const BILLING_PROFILE_DISPATCH = {
  IS_YEARLY: 'isYearly',
  SIGNED_URL: 'signedUrl'
}

const updateSubscriptionState = (subscription, state) => {
  return {
    subscribedPackage: { ...state.subscribedPackage, ...subscription }
  }
}

const getSubscribedPackage = (payload) => {
  const { packageSubscriptions, currentToken } = payload
  const subscribedPackage = selectSubscribedPackage(
    packageSubscriptions,
    currentToken
  )
  return subscribedPackage
}
