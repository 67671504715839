const LOGIN_ID_TYPE_OPTION_DATA = {
  email: 'email',
  other: 'other',
  phone: 'phone'
}

const buildLoginIdTypeOptions = (t) => {
  return Object.keys(LOGIN_ID_TYPE_OPTION_DATA).map((key) => ({
    value: key,
    label: t(`exchange.login-id-types.${LOGIN_ID_TYPE_OPTION_DATA[key]}`)
  }))
}

const buildStaticFeedbackReasons = (data) => {
  return Object.values(data).map((value) => ({ value, label: value }))
}

export { buildLoginIdTypeOptions, buildStaticFeedbackReasons }
