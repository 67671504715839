export const EXCHANGE_CONNECTION_API_ERRORS = {
  REJECTED: 'rejected',
  CANCELLED: 'cancelled',
  STOPPED: 'stopped'
}

export const QB_COGNITO_ERRORS = {
  USERNAME_EXISTS_EXCEPTION: 'UsernameExistsException',
  USER_NOT_CONFIRMED_EXCEPTION: 'UserNotConfirmedException',
  EXPIRED_CODE_EXCEPTION: 'ExpiredCodeException',
  CODE_MISMATCH_EXCEPTION: 'CodeMismatchException',
  ALIAS_EXISTS_EXCEPTION: 'AliasExistsException',
  NOT_AUTHORIZED_EXCEPTION: 'NotAuthorizedException',
  LIMIT_EXCEEDED_EXCEPTION: 'LimitExceededException',
  RESOURCE_NOT_FOUND_EXCEPTION: 'ResourceNotFoundException'
}

export const TEAM_MEMBERS_ERRORS = {
  TEAM_MEMBER_HAS_NO_ACCOUNT: 'The nominated user does not exist',
  TEAM_MEMBER_ALREADY_EXISTS: 'Access control already exists for this user'
}

export const MEMBER_TRANSACTION_ERRORS = {
  INSUFFICIENT_USER_BALANCE: 'Insufficient user balance'
}

export const NFT_ERRORS = {
  FILE_TYPE_NOT_SUPPORTED: 'Unsupported file extension'
}

export const REWARD_EXCHANGE_MEMBER_ERRORS = {
  USER_TO_EXCHANGE_NOT_EXISTS: "The user to exchange to doesn't exist",
  AMOUNT_IS_NOT_SUFFICIENT:
    "The amount to exchange isn't sufficient. Try with a higher amount.",
  MAXIMUM_ALLOWED_LIMIT_EXCEEDED:
    'Maximum allowed limit to exchange for `exchange` exceeded'
}
