import React, { memo } from 'react'
import { FormControl, MenuItem } from '@mui/material'

import CustomFormLabel from '../forms/customElements/CustomFormLabel'
import CustomSelect from '../forms/customElements/CustomSelect'
import InputHelperText from './InputHelperText'
import PopoverHelpText from '../PopoverHelpText/PopoverHelpText'

const Dropdown = ({
  label,
  id,
  bottomLabel,
  errorMessage,
  successMsg,
  onChange,
  options,
  fullWidth,
  helpText,
  ...restProps
}) => {
  const error = Boolean(errorMessage)
  return (
    <FormControl fullWidth={fullWidth} error={error}>
      {label && (
        <CustomFormLabel htmlFor={id}>
          {label}
          {helpText && <PopoverHelpText label={helpText} />}
        </CustomFormLabel>
      )}
      <CustomSelect
        id={id}
        fullWidth={fullWidth}
        required
        error={error}
        onChange={onChange}
        {...restProps}
      >
        {options &&
          options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </CustomSelect>
      <InputHelperText
        successMsg={successMsg}
        errorMessage={errorMessage}
        bottomLabel={bottomLabel}
      />
    </FormControl>
  )
}

Dropdown.defaultProps = {
  required: true,
  size: '',
  fullWidth: true
}

export default memo(Dropdown)
