import React, { memo } from 'react'
import { Grid } from '@mui/material'

import { LABEL_TEXT, QB_LABEL_TEXT } from '../../constants/messages'
import { EXCHANGE_PARTNER_DISPATCH } from '../../store/reducers/exchange/partners'
import { buildMemberOptionsFilter } from '../../data/members'
import {
  fetchCountriesByRegion,
  fetchRegionsList
} from '../../util/country.helpers'
import {
  buildExchangeRewardTypeOptions,
  buildSortingOptions
} from '../../util/exchange.helpers'
import { QB_DEFAULT_COUNTRY_VALUE } from '../../constants/app'
import FiltersDropdown from '../shared/FiltersDropdown/FiltersDropdown'

const ExchangeFilters = ({ onChangeFilters, partnersProfile, filters, t }) => {
  const { region, country, members, rewardType, sorting } = partnersProfile
  const countriesList = fetchCountriesByRegion(t, [region])
  return (
    <>
      <Grid container spacing={3} sx={{ display: 'flex' }}>
        <FiltersDropdown
          action={EXCHANGE_PARTNER_DISPATCH.SORTING}
          label={LABEL_TEXT.SORTING}
          options={buildSortingOptions(t)}
          value={sorting}
          onChangeFilters={({ target }) =>
            onChangeFilters(EXCHANGE_PARTNER_DISPATCH.SORTING, target.value)
          }
          t={t}
        />
        <FiltersDropdown
          action={EXCHANGE_PARTNER_DISPATCH.REWARD_TYPE}
          label={LABEL_TEXT.REWARD_TYPE}
          options={buildExchangeRewardTypeOptions(t)}
          value={rewardType}
          onChangeFilters={({ target }) =>
            onChangeFilters(EXCHANGE_PARTNER_DISPATCH.REWARD_TYPE, target.value)
          }
          t={t}
        />
        <FiltersDropdown
          action={EXCHANGE_PARTNER_DISPATCH.REGION}
          label={QB_LABEL_TEXT.REGION}
          options={fetchRegionsList(t)}
          value={region}
          onChangeFilters={({ target }) => {
            onChangeFilters(EXCHANGE_PARTNER_DISPATCH.REGION, target.value)
          }}
          t={t}
        />
        <FiltersDropdown
          action={EXCHANGE_PARTNER_DISPATCH.COUNTRY}
          label={QB_LABEL_TEXT.COUNTRY}
          options={countriesList}
          value={
            countriesList.map((country) => country.value).includes(country)
              ? country
              : QB_DEFAULT_COUNTRY_VALUE
          }
          onChangeFilters={({ target }) =>
            onChangeFilters(EXCHANGE_PARTNER_DISPATCH.COUNTRY, target.value)
          }
          t={t}
        />
        <FiltersDropdown
          action={EXCHANGE_PARTNER_DISPATCH.MEMBERS}
          label={QB_LABEL_TEXT.MEMBERS}
          options={buildMemberOptionsFilter(t)}
          value={members}
          onChangeFilters={({ target }) =>
            onChangeFilters(EXCHANGE_PARTNER_DISPATCH.MEMBERS, target.value)
          }
          t={t}
        />
        {filters}
      </Grid>
    </>
  )
}

export default memo(ExchangeFilters)
