import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Sidebar from '../../components/dashboardLayout/sidebar/Sidebar'
import { buildBrandSetupUrl } from '../../util/dashboard.helpers'
import { newBrandCreation, switchBrandAndToken } from '../../store/actions/main'
import { buildBrandAndTokensMenu } from '../../util/brand.helpers'
import { checkToShowGuidanceForExchange } from '../../util/exchange.helpers'

const SidebarContainer = ({
  switchBrandAndToken,
  tokenProfile,
  mainProfile,
  brandProfile,
  history,
  dashboardSetupState,
  newBrandCreation,
  ...restProps
}) => {
  const { t } = useTranslation()

  const [isExtendedList, setIsExtendedList] = useState(null)

  const brandAndTokensMenuData = useMemo(
    () => buildBrandAndTokensMenu(mainProfile.brandTokens, brandProfile.id),
    [mainProfile.brandTokens, brandProfile.id]
  )

  const isShowGuidanceForExchange = useMemo(
    () => checkToShowGuidanceForExchange(mainProfile.exchangePartnersStats),
    [mainProfile.exchangePartnersStats]
  )

  const handleCreateNewPoint = () => {
    setIsExtendedList(null)
    newBrandCreation()
    history.push(buildBrandSetupUrl())
  }

  const handleChangeToken = (brandId, tokenId) => {
    switchBrandAndToken(brandId, tokenId)
    window.location.reload()
    setIsExtendedList(null)
  }

  const handleOpenTokens = (event) => {
    setIsExtendedList(event.currentTarget)
  }

  const handleCloseTokens = () => {
    setIsExtendedList(null)
  }

  return (
    <Sidebar
      t={t}
      tokenProfile={tokenProfile}
      brandProfile={brandProfile}
      isNewBrandCreation={mainProfile.isNewBrandCreation}
      onCreateNewPoint={handleCreateNewPoint}
      onChangeToken={handleChangeToken}
      dashboardSetupState={dashboardSetupState}
      isExtendedList={isExtendedList}
      onOpenTokens={handleOpenTokens}
      onCloseTokens={handleCloseTokens}
      brandAndTokensMenuData={brandAndTokensMenuData}
      isShowGuidanceForExchange={isShowGuidanceForExchange}
      {...restProps}
    />
  )
}

const mapStateToProps = ({
  dashboardReducer,
  tokensReducer,
  mainReducer,
  accountReducer,
  apiKeysReducer
}) => {
  const { mainProfile } = mainReducer
  const { dashboardSetupProfile, brandProfile } = dashboardReducer
  const { tokenProfile, tokenStatsProfile } = tokensReducer
  const { metadata } = accountReducer.accountProfile
  const { apiKeysProfile } = apiKeysReducer
  return {
    dashboardSetupState: dashboardSetupProfile.dashboardSetupState,
    brandProfile,
    tokenProfile,
    mainProfile,
    isDarkMode: metadata?.darkMode,
    exchangeTxCount: tokenStatsProfile.exchangeTxCount,
    isAPIKeyCreated: apiKeysProfile.apiKeys.length > 0
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      switchBrandAndToken,
      newBrandCreation
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContainer)
