import { testingAlertHeight } from '../components/global/Theme-variable'
import { THEME_INPUT_SIZE_SMALL } from '../constants/theme'
import { isProduction } from './app/app.helpers'

const buildCustomHelperTextStyles = (successMsg) => {
  if (successMsg) {
    const color = ({ palette: { primary } }) => primary.main
    return {
      sx: {
        color,
        '&.MuiFormHelperText-root.Mui-disabled': { color }
      }
    }
  }
  return {}
}

const isInputSizeSmall = (size) => {
  return size === THEME_INPUT_SIZE_SMALL
}

const buildCustomFabStyles = (color) => {
  return color
    ? {
        backgroundColor: `${color}.main`,
        ':hover': {
          backgroundColor: `${color}.main`
        }
      }
    : {}
}

const getTopHeight = (height) => {
  return height + (!isProduction() ? testingAlertHeight : 0)
}

const minHeightCalc = (height) => {
  return { minHeight: `calc(100vh - ${height}px)` }
}

export {
  buildCustomHelperTextStyles,
  isInputSizeSmall,
  buildCustomFabStyles,
  getTopHeight,
  minHeightCalc
}
