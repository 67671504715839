import { TRANSACTION_TYPES } from '../../constants/transactionTypes'
import { calculatePointValue } from '../token.helpers'
import { TOKEN_TYPE } from '../../constants/token'
import { addNumbers } from '../number.helpers'
import { calculateExchangeInOutValue } from '../chart/transactionsGraph.helpers'

const buildTransferVolumeData = (
  { exchangeInTxVolume, exchangeOutTxVolume, rewardTxVolume, redeemTxVolume },
  transactionType
) => {
  switch (transactionType) {
    case TRANSACTION_TYPES.EXCHANGE:
      return {
        data: [addNumbers(exchangeInTxVolume, exchangeOutTxVolume), 0, 0]
      }
    case TRANSACTION_TYPES.REWARD:
      return {
        data: [0, rewardTxVolume, 0]
      }
    case TRANSACTION_TYPES.REDEEM:
      return {
        data: [0, 0, redeemTxVolume]
      }
    default:
      return {
        data: [
          addNumbers(exchangeInTxVolume, exchangeOutTxVolume),
          rewardTxVolume,
          redeemTxVolume
        ]
      }
  }
}

const buildTransactionsCountData = (
  { exchangeTxCount, rewardTxCount, redeemTxCount },
  transactionType
) => {
  switch (transactionType) {
    case TRANSACTION_TYPES.EXCHANGE:
      return {
        data: [exchangeTxCount, 0, 0]
      }
    case TRANSACTION_TYPES.REWARD:
      return {
        data: [0, rewardTxCount, 0]
      }
    case TRANSACTION_TYPES.REDEEM:
      return {
        data: [0, 0, redeemTxCount]
      }
    default:
      return {
        data: [exchangeTxCount, rewardTxCount, redeemTxCount]
      }
  }
}

const buildTransactionsRateData = (
  {
    exchangeInTxVolume,
    exchangeOutTxVolume,
    rewardTxVolume,
    redeemTxVolume,
    exchangeTxCount,
    rewardTxCount,
    redeemTxCount
  },
  transactionType
) => {
  switch (transactionType) {
    case TRANSACTION_TYPES.EXCHANGE:
      return {
        data: [
          addNumbers(exchangeInTxVolume, exchangeOutTxVolume) / exchangeTxCount,
          0,
          0
        ]
      }
    case TRANSACTION_TYPES.REWARD:
      return {
        data: [0, rewardTxVolume / rewardTxCount, 0]
      }
    case TRANSACTION_TYPES.REDEEM:
      return {
        data: [0, 0, redeemTxVolume / redeemTxCount]
      }
    default:
      return {
        data: [
          addNumbers(exchangeInTxVolume, exchangeOutTxVolume) / exchangeTxCount,
          rewardTxVolume / rewardTxCount,
          redeemTxVolume / redeemTxCount
        ]
      }
  }
}

const buildTransactionsValueData = (
  { exchangeInTxVolume, exchangeOutTxVolume, rewardTxVolume, redeemTxVolume },
  { offramp, onramp },
  transactionType
) => {
  switch (transactionType) {
    case TRANSACTION_TYPES.EXCHANGE:
      return {
        data: [
          calculateExchangeInOutValue(
            { exchangeInTxVolume, exchangeOutTxVolume },
            { offramp, onramp }
          ),
          0,
          0
        ]
      }
    case TRANSACTION_TYPES.REWARD:
      return {
        data: [0, calculatePointValue(rewardTxVolume, onramp), 0]
      }
    case TRANSACTION_TYPES.REDEEM:
      return {
        data: [0, 0, calculatePointValue(redeemTxVolume, onramp)]
      }
    default:
      return {
        data: [
          calculateExchangeInOutValue(
            { exchangeInTxVolume, exchangeOutTxVolume },
            { offramp, onramp }
          ),
          calculatePointValue(rewardTxVolume, onramp),
          calculatePointValue(redeemTxVolume, onramp)
        ]
      }
  }
}

const isPointsToken = (type) => {
  return [TOKEN_TYPE.ERC_20, TOKEN_TYPE.EXTERNAL].includes(type)
}

const isTokenFullIntegration = (type) => {
  return type === TOKEN_TYPE.ERC_20
}

const extractUserPointsBalance = (balances) => {
  if (balances && balances.length > 0) {
    const filterPoints = balances.filter((balance) =>
      isPointsToken(balance?.token?.type)
    )
    return filterPoints.length > 0 ? filterPoints[0].balance : 0
  }
  return 0
}

export {
  buildTransferVolumeData,
  buildTransactionsCountData,
  buildTransactionsRateData,
  buildTransactionsValueData,
  extractUserPointsBalance,
  isTokenFullIntegration
}
