import React, { memo } from 'react'
import { CardContent, Box } from '@mui/material'

import Text from '../text/Text'
import FaIcon from '../FaIcon/FaIcon'
import BaseCard from '../BaseCard/BaseCard'
import TitleWithPopover from '../TitleWithPopover/TitleWithPopover'
import CustomFab from '../inputs/CustomFab'
import { buildCustomFabStyles } from '../../../util/style.helpers'

const ActivityCard = ({ title, color, helpText, heading, label, icon }) => {
  return (
    <BaseCard>
      <CardContent>
        <Box display='flex' alignItems='flex-start'>
          <TitleWithPopover
            title={title}
            helpText={helpText}
            variant='h4'
            sx={{
              marginBottom: '0'
            }}
            gutterBottom
          />
          <Box
            sx={{
              marginLeft: 'auto'
            }}
          >
            <CustomFab
              size='medium'
              color='primary'
              elevation='0'
              sx={{
                boxShadow: 'none',
                ...buildCustomFabStyles(color)
              }}
            >
              <FaIcon icon={icon} size='lg' />
            </CustomFab>
          </Box>
        </Box>
        <Text
          label={heading}
          variant='h1'
          fontWeight={500}
          sx={{
            marginBottom: '0',
            marginTop: '20px'
          }}
          gutterBottom
          color={`${color}.main`}
        />
        <Text
          label={label}
          variant='h6'
          color='textSecondary'
          sx={{
            marginBottom: '0',
            opacity: '0.6'
          }}
          gutterBottom
        />
      </CardContent>
    </BaseCard>
  )
}

export default memo(ActivityCard)
