import React, { memo } from 'react'
import { CannyProvider, CannyWidget } from 'react-canny'

import { REQUEST_FEATURE_URL } from '../../constants/navigation'
import Loading from '../shared/Loading/Loading'

const RequestFeature = ({ accountProfile, ssoToken }) => {
  const appId = process.env.REACT_APP_CANNY_APP_ID
  const ladBoardToken = process.env.REACT_APP_CANNY_LAD_BOARD_TOKEN
  const { userId, email } = accountProfile

  return ssoToken ? (
    <CannyProvider appId={appId}>
      <CannyWidget
        basePath={REQUEST_FEATURE_URL}
        user={{
          id: userId,
          email
        }}
        boardToken={ladBoardToken}
        ssoToken={ssoToken}
      />
    </CannyProvider>
  ) : (
    <Loading />
  )
}

export default memo(RequestFeature)
