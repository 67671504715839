import {
  TRANSACTION_ACTIONS,
  TXN_REFUND_MILES_ACTION,
  TXN_STATUS_ACTION
} from '../../../constants/actions'
import { NUMBER_OF_RECORDS_PER_PAGE } from '../../../constants/app'
import { mergeToNewObject } from '../../../util/object.helpers'

export const transactionsProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS:
      return mergeToNewObject(state, {
        pagination: {
          ...state.pagination,
          ...payload
        }
      })

    case TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS_SUCCESS:
      const { totalTxCount, transactions } = payload
      return mergeToNewObject(state, {
        transactions,
        pagination: {
          ...state.pagination,
          totalTxCount
        }
      })

    case TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS_FAILED:
      return mergeToNewObject(state, {
        transactions: [],
        pagination: {
          ...state.pagination,
          totalTxCount: 0
        }
      })

    case TXN_STATUS_ACTION.ON_CHANGE_STATUS_SUCCESS:
    case TXN_REFUND_MILES_ACTION.ON_REFUND_SUCCESS:
      const { id, status, effectiveAt } = payload
      return mergeToNewObject(state, {
        transactions: state.transactions.map((transaction) =>
          transaction.id === id
            ? {
                ...transaction,
                effectiveAt,
                status
              }
            : transaction
        )
      })

    case TRANSACTION_ACTIONS.ON_RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  transactions: [],
  pagination: {
    recordsPerPage: NUMBER_OF_RECORDS_PER_PAGE,
    pageNumber: 0,
    totalTxCount: 0
  }
}
