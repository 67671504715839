import React, { memo } from 'react'
import { Grid } from '@mui/material'

import { NFTS_PROFILE_DISPATCH } from '../../../store/reducers/nfts/nft'
import CustomDialog from '../../shared/CustomDialog/CustomDialog'
import Input from '../../shared/inputs/Input'
import {
  transWithArgs,
  validNftSalesFeeRule
} from '../../../util/validation.helpers'
import { QB_LABEL_TEXT } from '../../../constants/messages'
import Dropdown from '../../shared/inputs/Dropdown'
import { NFT_YES_NO_OPTIONS, buildYesNoOptions } from '../../../data/nfts'
import { SALES_FEE_MAX_LENGTH } from '../../../constants/validation'
import { QB_INPUT_NUMBER } from '../../../constants/input'

const UserPermissions = ({
  t,
  onClose,
  onSubmit,
  dispatchAction,
  nftProfile: {
    error,
    isProcessing,
    userTransferTemp,
    userSaleTemp,
    userSalesFeeTemp
  },
  tokenProfile: { name }
}) => {
  return (
    <CustomDialog
      isForm
      title={t('nft.edit-user-permissions')}
      description={t('nft.edit-user-permissions-desc')}
      open
      onClose={onClose}
      cancelProps={{ label: t(QB_LABEL_TEXT.CANCEL) }}
      submitProps={{
        label: t('common.save-changes'),
        disabled: isProcessing
      }}
      body={
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <Dropdown
              id={NFTS_PROFILE_DISPATCH.USER_TRANSFER_TEMP}
              label={t('nft.user-transfer')}
              helpText={t('nft.user-transfer-desc')}
              options={buildYesNoOptions(t)}
              value={userTransferTemp}
              errorMessage={transWithArgs(
                t,
                error[NFTS_PROFILE_DISPATCH.USER_TRANSFER_TEMP]
              )}
              onChange={({ target }) =>
                dispatchAction(
                  NFTS_PROFILE_DISPATCH.USER_TRANSFER_TEMP,
                  target.value
                )
              }
              size='small'
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Dropdown
              id={NFTS_PROFILE_DISPATCH.USER_SALE_TEMP}
              label={t('nft.user-sale')}
              helpText={t('nft.user-sale-desc')}
              value={userSaleTemp}
              options={buildYesNoOptions(t)}
              errorMessage={transWithArgs(
                t,
                error[NFTS_PROFILE_DISPATCH.USER_SALE_TEMP]
              )}
              onChange={({ target }) =>
                dispatchAction(
                  NFTS_PROFILE_DISPATCH.USER_SALE_TEMP,
                  target.value
                )
              }
              size='small'
            />
          </Grid>
          {userSaleTemp === NFT_YES_NO_OPTIONS.YES && (
            <Grid item md={4} xs={12}>
              <Input
                id={NFTS_PROFILE_DISPATCH.USER_SALES_FEE_TEMP}
                label={`${t('nft.user-sales-fee')} (%)`}
                helpText={t('nft.user-sales-fee-desc', { name })}
                endIcon='%'
                type={QB_INPUT_NUMBER}
                value={userSalesFeeTemp}
                validate={[validNftSalesFeeRule]}
                errorMessage={transWithArgs(
                  t,
                  error[NFTS_PROFILE_DISPATCH.USER_SALES_FEE_TEMP]
                )}
                onChange={(value) =>
                  dispatchAction(
                    NFTS_PROFILE_DISPATCH.USER_SALES_FEE_TEMP,
                    value
                  )
                }
                inputProps={{ maxLength: SALES_FEE_MAX_LENGTH }}
                size='small'
              />
            </Grid>
          )}
        </Grid>
      }
      onSubmit={onSubmit}
      t={t}
    />
  )
}

export default memo(UserPermissions)
