import React, { memo } from 'react'
import { Box } from '@mui/material'

import { TRANSPARENT_BLACK } from '../../../styles/common'

const SidebarDivider = () => {
  return (
    <Box
      sx={{
        height: '1px',
        backgroundColor: TRANSPARENT_BLACK,
        width: '100%',
        m: '10px 0'
      }}
    />
  )
}

export default memo(SidebarDivider)
