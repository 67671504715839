import React, { memo } from 'react'
import { Grid } from '@mui/material'

import { TOKEN_PROFILE_DISPATCH } from '../../store/reducers/tokens/token'
import Text from '../shared/text/Text'
import MirrorTransactionsField from './MirrorTransactionsField'
import { stringToBool } from '../../util/string.helpers'

const BlockchainPreferenceFields = ({
  binanceMirrorTemp,
  dispatchAction,
  t
}) => {
  return (
    <>
      <Text variant='h2' label={t('token-setup.blockchain')} fontWeight={500} />
      <Text
        variant='body1'
        color='textprimary'
        label={t('token-setup.blockchain-preference-desc')}
        sx={{ mt: 2 }}
      />
      <Grid container>
        <Grid item xs={12}>
          <MirrorTransactionsField
            t={t}
            value={binanceMirrorTemp}
            onChange={(e) =>
              dispatchAction(
                TOKEN_PROFILE_DISPATCH.BINANCE_MIRROR_TEMP,
                stringToBool(e.target.value)
              )
            }
          />
        </Grid>
      </Grid>
    </>
  )
}

export default memo(BlockchainPreferenceFields)
