import React, { memo } from 'react'
import { Box } from '@mui/material'

import CustomDialog from './CustomDialog'
import CustomButton from '../buttons/CustomButton'

const DialogWithoutCard = ({
  body,
  noAction,
  actionProps: { sx, ...restActionProps },
  onClickAction
}) => {
  return (
    <CustomDialog
      open
      banner={
        <>
          {body}
          {!noAction && (
            <Box sx={{ textAlign: 'center', mt: 4, ...sx }}>
              <CustomButton
                onClick={onClickAction}
                fullWidth={false}
                sx={{ pl: 4, pr: 4 }}
                color='lightGrey'
                {...restActionProps}
              />
            </Box>
          )}
        </>
      }
      maxWidth='lg'
      paperProps={{
        background: 'transparent',
        boxShadow: 'none',
        borderRadius: '10px'
      }}
    ></CustomDialog>
  )
}

export default memo(DialogWithoutCard)
