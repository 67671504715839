import React from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'

import ThemeSettings from './components/dashboardLayout/customizer/ThemeSettings'

const ThemeWrapper = ({ children }) => {
  const theme = ThemeSettings()
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default ThemeWrapper
