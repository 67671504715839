import { QB_LABEL_TEXT } from '../constants/messages'
import {
  QB_DEFAULT_REGION_VALUE,
  QB_DEFAULT_COUNTRY_VALUE
} from '../constants/app'
import { countriesWithContinent } from '../data/countries'
import { elementIsInArray } from './array.helpers'

const fetchRegionsList = (t) => {
  const regions = extractAllRegions()
  regions.unshift({
    label: t(QB_LABEL_TEXT.WORLDWIDE),
    value: QB_DEFAULT_REGION_VALUE
  })
  return regions
}

const extractAllRegions = () => {
  return extractAllRegionsByCountries(getCountriesIsoArray())
}

const extractAllRegionsByCountries = (countries) => {
  const unique = {}
  const regions = []
  countries.forEach((countryIso) => {
    const countryWithContent = countriesWithContinent[countryIso]
    const continent = countryWithContent?.continent
    if (continent && !unique[continent]) {
      regions.push({ label: continent, value: continent })
      unique[continent] = true
    }
  })
  return regions
}

const fetchCountriesByRegion = (t, regions, needAllCountries = true) => {
  let countries = []
  if (regions && regions.length > 0 && regions[0] !== QB_DEFAULT_REGION_VALUE) {
    countries = getCountriesIsoArray().reduce((filtered, countryIso) => {
      const { continent, country } = countriesWithContinent[countryIso]
      if (elementIsInArray(regions, continent)) {
        filtered.push({ label: country, value: countryIso })
      }
      return filtered
    }, [])
  } else {
    countries = getCountriesIsoArray().map((countryIso) => {
      const countryWithContent = countriesWithContinent[countryIso]
      return {
        label: countryWithContent.country,
        value: countryIso
      }
    })
  }
  if (needAllCountries) {
    countries.unshift({
      label: t(QB_LABEL_TEXT.ALL_COUNTRIES_SELECTED_REGION),
      value: QB_DEFAULT_COUNTRY_VALUE
    })
  }
  return countries
}

const getCountriesIsoArray = () => {
  return Object.keys(countriesWithContinent)
}

const extractMultipleOptions = (target) => {
  return Array.from(target, (option) => option.value)
}

const countryLabelsByValues = (countries, t) => {
  if (countries && countries.length > 0) {
    const countryLabels = []
    if (countries[0] === QB_DEFAULT_COUNTRY_VALUE) {
      countryLabels.push(t(QB_LABEL_TEXT.ALL_COUNTRIES_SELECTED_REGION))
    } else {
      countries.forEach((countryIso) => {
        const countryWithContent = countriesWithContinent[countryIso]
        if (countryWithContent) {
          countryLabels.push(countryWithContent.country)
        }
      })
    }
    return countryLabels
  }
  return []
}

const regionLabelsByValues = (regionValues, t) => {
  if (regionValues && regionValues.length > 0) {
    if (regionValues[0] === QB_DEFAULT_REGION_VALUE) {
      return [t(QB_LABEL_TEXT.WORLDWIDE)]
    } else {
      return regionValues
    }
  }
  return []
}

const countryLabelOptionsByValues = (countries, t) => {
  if (countries && countries.length > 0) {
    const countryLabels = []
    if (countries[0] === QB_DEFAULT_COUNTRY_VALUE) {
      countryLabels.push({
        label: t(QB_LABEL_TEXT.ALL_COUNTRIES_SELECTED_REGION),
        value: QB_DEFAULT_COUNTRY_VALUE
      })
    } else {
      countries.forEach((countryIso) => {
        const countryWithContent = countriesWithContinent[countryIso]
        if (countryWithContent) {
          countryLabels.push({
            label: countryWithContent.country,
            value: countryIso
          })
        }
      })
    }
    return countryLabels
  }
  return []
}

const regionLabelOptionsByValues = (regionValues, t) => {
  if (regionValues && regionValues.length > 0) {
    if (regionValues[0] === QB_DEFAULT_REGION_VALUE) {
      return [
        { label: t(QB_LABEL_TEXT.WORLDWIDE), value: QB_DEFAULT_REGION_VALUE }
      ]
    } else {
      return regionValues.map((region) => ({ label: region, value: region }))
    }
  }
  return []
}

const extractRegions = (regions) => {
  if (regions && regions.length > 0) {
    if (regions.length === extractAllRegions().length) {
      return [QB_DEFAULT_REGION_VALUE]
    } else {
      return regions
    }
  }
  return []
}

const extractCountries = (countries, regions) => {
  if (countries && countries.length > 0) {
    if (
      countries.length === fetchCountriesByRegion(null, regions, false).length
    ) {
      return [QB_DEFAULT_COUNTRY_VALUE]
    } else {
      return countries
    }
  }
  return []
}

export {
  fetchRegionsList,
  fetchCountriesByRegion,
  extractMultipleOptions,
  countryLabelsByValues,
  regionLabelsByValues,
  extractRegions,
  extractCountries,
  extractAllRegionsByCountries,
  extractAllRegions,
  getCountriesIsoArray,
  countryLabelOptionsByValues,
  regionLabelOptionsByValues
}
