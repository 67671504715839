import { formatTicks } from './string.helpers'

export const formatToUSD = (number, isValueNegative = false) => {
  if (isValueNegative) {
    return `-$${number}`
  }
  return `$${number}`
}

export const formatToDecimals = (n, m) => n.toFixed(m)

export const formatCurrencyValue = (value, maxDigits = 2) => {
  return value ? parseFloat(formatToDecimals(parseFloat(value), maxDigits)) : 0
}

export function isValidNumber(number) {
  if (number) {
    const noCommaNumber = number.split(',').join('.')
    return noCommaNumber.length > 0 && !Number.isNaN(Number(noCommaNumber))
  }

  return false
}

export const displayCurrencyValue = (number) => {
  const value = formatCurrencyValue(number) || 0
  const isValueNegative = Math.sign(value) === -1
  return value !== undefined
    ? formatToUSD(
        formatTicks(isValueNegative ? Math.abs(value) : value),
        isValueNegative
      )
    : ''
}

export const percentageOfCompletion = (current, total) => {
  const completed = (current / total) * 100
  return Math.round(completed > 100 ? 100 : completed)
}

export const formatPercentage = (n) => n * 100

export const ceilNumber = (n) => Math.ceil(n)
export const floorNumber = (n) => Math.floor(n)

export const addNumbers = (n1, n2) => n1 + n2
