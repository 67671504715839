import { mergeToNewObject } from '../../../util/object.helpers'
import { TXN_STATUS_ACTION } from '../../../constants/actions'
import { TRANSACTION_STATUS } from '../../../constants/transactionTypes'
import { buildErrorMessage } from '../../../util/reducer.helpers'

export const changeStatusProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case TXN_STATUS_ACTION.ON_CHANGE_STATUS:
      return mergeToNewObject(state, {
        isProcessing: true,
        isChangedStatus: false
      })

    case TXN_STATUS_ACTION.ON_CHANGE_STATE:
      return mergeToNewObject(state, {
        [payload.property]: payload.value,
        ...resetErrors(payload, state)
      })

    case TXN_STATUS_ACTION.ON_CHANGE_STATUS_SUCCESS:
      return mergeToNewObject(state, {
        isProcessing: false,
        isChangedStatus: true
      })

    case TXN_STATUS_ACTION.ON_CHANGE_STATUS_FAILED:
      return mergeToNewObject(state, {
        error: {
          status: buildErrorMessage(payload, payload?.response?.errors?.detail)
        },
        isProcessing: false,
        isChangedStatus: false
      })

    case TXN_STATUS_ACTION.ON_RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

export const TXN_STATUS_DISPATCH = {
  STATUS: 'status',
  ERROR: 'error'
}

const INITIAL_STATE = {
  status: TRANSACTION_STATUS.SUCCESS,
  isProcessing: false,
  isChangedStatus: false,
  error: {}
}

const resetErrors = ({ property }, state) => {
  if (property !== TXN_STATUS_DISPATCH.ERROR) {
    return {
      error: {
        ...state.error,
        [property]: ''
      }
    }
  }
  return {}
}
