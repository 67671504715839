import React, { memo } from 'react'
import { Grid, TableRow } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { faTable } from '@fortawesome/pro-light-svg-icons'

import DashboardCard from '../shared/DashboardCard/DashboardCard'
import DownloadButton from '../shared/inputs/DownloadButton'
import DataTable from '../shared/DataTable/DataTable'
import TransactionDataRow from '../shared/TransactionDataRow/TransactionDataRow'
import SmallButton from '../shared/buttons/SmallButton'
import { isRefundActionAvailable } from '../../util/transaction/transaction.helpers'
import CustomIconButton from '../shared/buttons/CustomIconButton'
import HeaderMenu from '../dashboardLayout/header/HeaderMenu'
import TxnColumnsMenu from './TxnColumnsMenu'
import TransactionsSearch from './TransactionsSearch'

const TransactionsData = ({
  t,
  transactionsTableProps: {
    transactionsProfile,
    onChangeTransactionsPage,
    transactionsHeaders,
    onDownloadTransactionsReport,
    onTransactionsPerPageChange,
    transactionsDownloadProfile: { isProcessing },
    onChangeTxnStatus,
    onRefundAction,
    onColumnMenuClick,
    columnMenuOpen,
    onCloseColumnMenu,
    txnTableFullHeaders,
    onSelectTxnColumn,
    transactionColumnsProfile,
    sortedTxnColumns,
    transactionSearchProfile,
    changeTxnSearchState,
    onResetSearchClick
  },
  currentTokenId,
  currentBrandId,
  customTittle,
  showTxnForExchange
}) => {
  const theme = useTheme()
  const { transactions, pagination } = transactionsProfile
  const txTableData = transactions.map((transaction, index) => (
    <DataTableRow
      key={index}
      currentTokenId={currentTokenId}
      currentBrandId={currentBrandId}
      {...transaction}
      t={t}
      theme={theme}
      showTxnForExchange={showTxnForExchange}
      changeStatus={
        <SmallButton
          label={t('nft.change-status')}
          onClick={() => onChangeTxnStatus(transaction.id)}
          sx={{ minWidth: 88 }}
        />
      }
      refundAction={
        isRefundActionAvailable(transaction, currentBrandId) && (
          <SmallButton
            label={t('refund.refund')}
            onClick={() => onRefundAction(transaction.id)}
            sx={{ minWidth: 60 }}
          />
        )
      }
      sortedTxnColumns={sortedTxnColumns}
    />
  ))
  return (
    <DashboardCard
      title={customTittle || t('analytics.transactions-tx')}
      action={
        <Grid container spacing={1} justifyContent='flex-end'>
          <TransactionsSearch
            t={t}
            transactionSearchProfile={transactionSearchProfile}
            dispatchAction={changeTxnSearchState}
            onResetSearchClick={onResetSearchClick}
          />
          <Grid item>
            <CustomIconButton
              onClick={onColumnMenuClick}
              icon={faTable}
              variant='outlined'
              color='primary'
              iconStyles={{ width: 18 }}
              sx={{ height: 40 }}
            />
            <HeaderMenu
              id='txn-columns-menu'
              open={columnMenuOpen}
              onClose={onCloseColumnMenu}
              body={
                <TxnColumnsMenu
                  t={t}
                  onSelectTxnColumn={onSelectTxnColumn}
                  txnTableFullHeaders={txnTableFullHeaders}
                  transactionColumnsProfile={transactionColumnsProfile}
                />
              }
              width={270}
              position='left'
              contentPadding='12px'
              noTop
            />
          </Grid>
          <Grid item>
            <DownloadButton
              label={t('analytics.download-csv')}
              onClick={onDownloadTransactionsReport}
              disabled={transactions.length === 0 || isProcessing}
            />
          </Grid>
        </Grid>
      }
      noPadding
    >
      <DataTable
        headers={[
          ...transactionsHeaders,
          {
            label: t('analytics.more-actions')
          }
        ]}
        data={txTableData}
        onChangePage={onChangeTransactionsPage}
        onRowsPerPageChange={onTransactionsPerPageChange}
        pagination={pagination}
        size='small'
        t={t}
        noRecordsMessage={t('transactions.no-transactions-found')}
      />
    </DashboardCard>
  )
}

export default memo(TransactionsData)

const DataTableRow = ({ ...restProps }) => {
  return (
    <TableRow hover>
      <TransactionDataRow {...restProps} showUserDetails showMoreActions />
    </TableRow>
  )
}
