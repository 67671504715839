import React, { memo } from 'react'

import { LABEL_TEXT } from '../../constants/messages'
import Input from '../shared/inputs/Input'

const PointValueField = ({ t, ...restProps }) => {
  return (
    <Input
      label={t(LABEL_TEXT.POINT_VALUE)}
      bottomLabel={t(LABEL_TEXT.EXCHANGE_CURRENCY_DESC)}
      size='small'
      {...restProps}
    />
  )
}

export default memo(PointValueField)
