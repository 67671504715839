import React, { memo } from 'react'
import { IconButton } from '@mui/material'
import { faXmark } from '@fortawesome/pro-light-svg-icons'
import { Trans } from 'react-i18next'

import FaIcon from '../FaIcon/FaIcon'
import Link from '../link/Link'
import CustomAlert from '../CustomAlert/CustomAlert'

const ImportantNote = ({
  title,
  guideUrl,
  closedImportantNote,
  onClose,
  t
}) => {
  return (
    <CustomAlert
      severity='info'
      action={
        !closedImportantNote && (
          <IconButton
            aria-label='close'
            color='inherit'
            size='small'
            onClick={onClose}
          >
            <FaIcon icon={faXmark} />
          </IconButton>
        )
      }
    >
      <Trans i18nKey={title}>
        <Link isOutside label={t('exchange.this-guide')} to={guideUrl} />
      </Trans>
    </CustomAlert>
  )
}

export default memo(ImportantNote)
