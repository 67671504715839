import { REQUEST_FEATURE_ACTIONS } from '../../../constants/actions'
import { mergeToNewObject } from '../../../util/object.helpers'

export const requestFeatureProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case REQUEST_FEATURE_ACTIONS.ON_GET_SSO_TOKEN_SUCCESS:
      return mergeToNewObject(state, { ssoToken: payload.ssoToken })

    default:
      return state
  }
}

const INITIAL_STATE = {
  ssoToken: ''
}
