import React, { memo } from 'react'
import { Trans } from 'react-i18next'

import { MARKDOWN_URL } from '../../../constants/externalResources'
import Link from '../link/Link'

const ExclusiveContentHelpText = ({ i18nKey, t }) => {
  return (
    <Trans i18nKey={i18nKey}>
      <Link isOutside label={t('common.markdown')} to={MARKDOWN_URL} />
    </Trans>
  )
}

export default memo(ExclusiveContentHelpText)
