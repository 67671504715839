import { BRAND_ACTIONS } from '../../constants/actions'

// BRAND
const createBrand = (file) => {
  return {
    type: BRAND_ACTIONS.ON_CREATE_BRAND,
    payload: { file }
  }
}

const updateBrand = (file) => {
  return {
    type: BRAND_ACTIONS.ON_INIT_UPDATE_BRAND,
    payload: { file }
  }
}

const updateBrandConfig = (payload) => {
  return {
    type: BRAND_ACTIONS.ON_UPDATE_BRAND_CONFIG,
    payload
  }
}

const changeBrandState = (property, value) => {
  return {
    type: BRAND_ACTIONS.ON_CHANGE_BRAND_STATE,
    payload: {
      property,
      value
    }
  }
}

const resetBrandState = (brand) => {
  return {
    type: BRAND_ACTIONS.ON_RESET_BRAND_STATE,
    payload: brand
  }
}

export {
  createBrand,
  updateBrand,
  updateBrandConfig,
  changeBrandState,
  resetBrandState
}
