import React, { memo } from 'react'

import UserAlert from '../../shared/CustomAlert/UserAlert'
import { DASHBOARD_EMAIL } from '../../../constants/externalResources'

const PausedTokenAlert = () => {
  return (
    <UserAlert
      label={DASHBOARD_EMAIL}
      to={DASHBOARD_EMAIL}
      linkProps={{
        isEmail: true
      }}
      i18nKey='setup.your-loyalty-points-paused'
      severity='warning'
    />
  )
}

export default memo(PausedTokenAlert)
