import React, { memo } from 'react'
import { Grid } from '@mui/material'
import {
  faDownToBracket,
  faUpFromBracket
} from '@fortawesome/pro-light-svg-icons'

import TransactionsChart from './TransactionsChart'
import TransactionsUSDCards from './TransactionsUSDCards'
import TransferVolumeChart from './TransferVolumeChart'
import TransactionNumberChart from './TransactionNumberChart'
import TransactionRates from './TransactionRates'
import { displayCurrencyValue } from '../../util/number.helpers'
import { sumOfArrayElements } from '../../util/app/app.helpers'
import { formatTicks } from '../../util/string.helpers'
import UserStatistics from './UserStatistics'
import ValueTransferredChart from './ValueTransferredChart'
import { buildColorByExchangeTypeFilter } from '../../util/analytics/analytics.helpers'
import {
  EXCHANGE_TRANSACTION_TYPE_COLORS,
  EXCHANGE_TRANSACTION_TYPES
} from '../../constants/transactionTypes'
import { calculateExchangeMembers } from '../../util/analytics/pointsExchange.helpers'
import { COLOR_PALETTE } from '../../constants/theme'
import TopExchangePartners from './TopExchangePartners'

const PointsExchange = ({
  t,
  tokenProfile,
  exchangeTransactionsGraphProfile: {
    transactionsChart,
    valueTransferredChart,
    isProcessing: transactionsGraphProcessing
  },
  pointsExchangeProfile: {
    members,
    transferVolume,
    transactionsCount,
    transactionsRate,
    transactionsValue,
    topExchangePartners,
    isProcessing: pointsBankProcessing
  },
  analyticsFilterProfile: { interval, exchangeType }
}) => {
  const { name, symbol } = tokenProfile
  const valueTransferredChartData = valueTransferredChart.filteredData
  const sumOfTransferred = sumOfArrayElements(valueTransferredChartData)
  const colorRefMethod = buildColorByExchangeTypeFilter(exchangeType)
  const chartLabels = {
    buy: t('analytics.buy-exchanges', { symbol }),
    sell: t('analytics.sell-exchanges', { symbol })
  }
  const transactionsValueData = transactionsValue.data
  const transactionsRateData = transactionsRate.data
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={8}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} lg={6}>
                <UserStatistics
                  title={t('analytics.recently-active-members')}
                  helpText={t('analytics.exchange-members-desc')}
                  isProcessing={pointsBankProcessing.members}
                  value={formatTicks(
                    calculateExchangeMembers(members, exchangeType)
                  )}
                  label={t('analytics.number-of-members')}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <ValueTransferredChart
                  title={`${t('analytics.total-point-value-exchanged', {
                    symbol
                  })}`}
                  helpText={t('analytics.point-value-exchanged-desc', {
                    symbol,
                    name
                  })}
                  data={valueTransferredChartData}
                  labels={valueTransferredChart.labels}
                  heading={displayCurrencyValue(sumOfTransferred)}
                  hasNoData={sumOfTransferred === 0}
                  interval={interval}
                  isProcessing={transactionsGraphProcessing.transactionsChart}
                  t={t}
                />
              </Grid>
              <Grid item xs={12}>
                <TransactionsChart
                  filteredData={transactionsChart.filteredData}
                  isProcessing={transactionsGraphProcessing.transactionsChart}
                  title={t('analytics.exchange-transactions')}
                  helpText={t('analytics.exchange-transactions-desc', {
                    symbol,
                    name
                  })}
                  chartLabels={chartLabels}
                  colorRefMethod={colorRefMethod}
                  colorConfig={EXCHANGE_TRANSACTION_TYPE_COLORS}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container spacing={4} sx={{ height: 'calc(100% + 32px)' }}>
              <Grid item xs={12}>
                <TransferVolumeChart
                  data={transferVolume.data}
                  colorRefMethod={colorRefMethod}
                  chartLabels={chartLabels}
                  title={t('analytics.point-exchange-vol', { symbol })}
                  helpText={t('analytics.point-exchange-vol-desc', {
                    symbol,
                    name
                  })}
                  customColors={[
                    COLOR_PALETTE.PRIMARY,
                    COLOR_PALETTE.SECONDARY
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <TransactionNumberChart
                  data={transactionsCount.data}
                  colorRefMethod={colorRefMethod}
                  chartLabels={chartLabels}
                  title={t('analytics.point-transactions', { symbol })}
                  helpText={t('analytics.point-exchange-transactions-desc', {
                    symbol,
                    name
                  })}
                  customColors={[
                    COLOR_PALETTE.PRIMARY,
                    COLOR_PALETTE.SECONDARY
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TransactionsUSDCards
                  colorRefMethod={colorRefMethod}
                  borderDirection='borderBottom'
                  gridSize={12}
                  flexDirection='column'
                  data={[
                    {
                      type: EXCHANGE_TRANSACTION_TYPES.BUY,
                      value: transactionsValueData[0],
                      icon: faUpFromBracket,
                      title: t(`analytics.point-value-exchanged-buy`, {
                        symbol
                      }),
                      helpText: t(`analytics.point-value-exchanged-buy-desc`, {
                        name,
                        symbol
                      })
                    },
                    {
                      type: EXCHANGE_TRANSACTION_TYPES.SELL,
                      value: transactionsValueData[1],
                      icon: faDownToBracket,
                      title: t(`analytics.point-value-exchanged-sell`, {
                        symbol
                      }),
                      helpText: t(`analytics.point-value-exchanged-sell-desc`, {
                        name,
                        symbol
                      }),
                      noBorder: true
                    }
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <TransactionRates
                  t={t}
                  colorRefMethod={colorRefMethod}
                  data={[
                    {
                      type: EXCHANGE_TRANSACTION_TYPES.BUY,
                      value: transactionsRateData[0],
                      icon: faUpFromBracket,
                      title: t(`analytics.point-rate-exchanged-buy`, {
                        symbol
                      }),
                      helpText: t(`analytics.point-rate-exchanged-buy-desc`, {
                        name,
                        symbol
                      })
                    },
                    {
                      type: EXCHANGE_TRANSACTION_TYPES.SELL,
                      value: transactionsRateData[1],
                      icon: faDownToBracket,
                      title: t(`analytics.point-rate-exchanged-sell`, {
                        symbol
                      }),
                      helpText: t(`analytics.point-rate-exchanged-sell-desc`, {
                        name,
                        symbol
                      })
                    }
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={8}>
            <TopExchangePartners
              t={t}
              data={topExchangePartners.filteredData}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(PointsExchange)
