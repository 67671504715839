import React, { memo } from 'react'

import CustomDialog from '../../shared/CustomDialog/CustomDialog'
import Text from '../../shared/text/Text'

const MirrorTransactionsOnChain = ({ t, isTokenCreated, ...restProps }) => {
  return (
    <CustomDialog
      title={t('token-setup.mirror-transactions-title')}
      description={t('token-setup.mirror-transactions-desc')}
      additionalDesc={
        <Text
          variant='body1'
          color='textprimary'
          label={t('token-setup.mirror-transactions-once-confirmed')}
          sx={{ pl: 4, pr: 4, pt: 4 }}
        />
      }
      open
      submitProps={{
        label: t(`token-setup.${isTokenCreated ? 'update' : 'create'}-point`)
      }}
      t={t}
      {...restProps}
    />
  )
}

export default memo(MirrorTransactionsOnChain)
