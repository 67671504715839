import React, { memo, useState } from 'react'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { faCalendar } from '@fortawesome/pro-light-svg-icons'

import { buildCustomHelperTextStyles } from '../../../util/style.helpers'
import CustomFormLabel from '../forms/customElements/CustomFormLabel'
import { DATE_PICKER_FORMAT } from '../../../constants/date'
import FaIcon from '../FaIcon/FaIcon'
import { minStartDate } from '../../../util/date.helpers'
import CustomTextField from '../forms/customElements/CustomTextField'

const CustomDatePicker = ({
  bottomLabel,
  errorMessage,
  successMsg,
  size,
  id,
  label,
  minDate,
  ...restProps
}) => {
  const error = Boolean(errorMessage)
  const handleKeyEvent = (e) => {
    e.preventDefault()
  }
  const [open, setOpen] = useState(false)
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CustomFormLabel htmlFor={id}>{label}</CustomFormLabel>
      <DatePicker
        {...restProps}
        minDate={minDate ? minDate : minStartDate()}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disableFuture
        components={{
          OpenPickerIcon: () => <FaIcon icon={faCalendar} size='xs' />
        }}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            inputProps={{
              ...params.inputProps
            }}
            error={error}
            size={size}
            fullWidth
            helperText={errorMessage || successMsg || bottomLabel}
            FormHelperTextProps={buildCustomHelperTextStyles(successMsg)}
            onKeyPress={handleKeyEvent}
            onKeyDown={handleKeyEvent}
            onKeyUp={handleKeyEvent}
            onCut={handleKeyEvent}
            onCopy={handleKeyEvent}
            onPaste={handleKeyEvent}
            onClick={() => setOpen(true)}
            id={id}
          />
        )}
        disableMaskedInput
        inputFormat={DATE_PICKER_FORMAT}
      />
    </LocalizationProvider>
  )
}

CustomDatePicker.defaultProps = {
  size: 'large'
}

export default memo(CustomDatePicker)
