import { ANALYTICS_INTERVAL_FILTERS } from '../../constants/analytics'
import { COLOR_PALETTE } from '../../constants/theme'
import { formatCurrencyValue } from '../number.helpers'
import { deepMerge } from '../object.helpers'
import { formatTicks } from '../string.helpers'

const buildChartOptions = (
  { palette, customColors = [] },
  config = {},
  otherConfig = { hasNoData: false }
) => {
  const { chartColors } = buildChartColors(palette, customColors)
  const { hasNoData } = otherConfig
  const colors = hasNoData ? [palette.grey.A100] : chartColors
  return deepMerge(
    {
      chart: {
        toolbar: {
          show: false
        },
        foreColor: '#adb0bb',
        fontFamily: "'DM Sans',sans-serif"
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      ...(hasNoData && {
        states: {
          hover: {
            filter: {
              type: 'none'
            }
          },
          active: {
            filter: {
              type: 'none'
            }
          }
        }
      }),
      tooltip: {
        enabled: !hasNoData,
        theme: 'dark',
        fillSeriesColor: false
      },
      colors
    },
    config
  )
}

const buildChartColors = (palette, customColors = []) => {
  const colorSlugs =
    customColors && customColors.length > 0
      ? customColors
      : [COLOR_PALETTE.PRIMARY, COLOR_PALETTE.INFO, COLOR_PALETTE.SECONDARY]
  const colors = {
    chartColors: []
  }
  colorSlugs.forEach((colorSlug) => {
    colors.chartColors.push(palette[colorSlug].main)
  })
  return colors
}

const buildDonutChartOptions = (size = 78) => {
  return {
    stroke: {
      colors: ['transparent']
    },
    plotOptions: {
      pie: {
        donut: {
          size: `${size}%`,
          background: 'transparent'
        }
      }
    },
    tooltip: {
      y: {
        formatter: (value) => {
          return formatTicks(value)
        },
        title: {
          formatter: () => {
            return ''
          }
        }
      }
    }
  }
}

const buildCustomTooltip = (data, label) => {
  return `
  <div class'apexcharts-tooltip-text' style='padding: 5px 15px'>
    <div class='apexcharts-tooltip-y-group'>
      <div class='apexcharts-tooltip-text-label'>${
        label ? `${label}: ` : ''
      }<strong>${formatTicks(formatCurrencyValue(data)) || 0}</strong>
      </div>
    </div>
  </div>`
}

const buildCategoryLabels = (t, labels, interval) => {
  if (interval === ANALYTICS_INTERVAL_FILTERS.WEEKLY) {
    return labels.map(({ formatted }) => `${t('analytics.week')} ${formatted}`)
  }
  return labels.map(({ formatted }) => formatted)
}

export {
  buildChartOptions,
  buildDonutChartOptions,
  buildCustomTooltip,
  buildCategoryLabels
}
