import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable'

import { ADMIN_ACTION } from '../../constants/actions'
import { wrapUserAccessToken } from '../../util/auth.helpers'
import apiService from '../../services/api'
import { extractBrandId, formatTeamMembers } from '../../util/epics.helpers'
import { of } from 'rxjs'
import { buildTeamMemberBody } from '../../util/admin.helpers'
import { logger } from '../../util/log.helpers'

const handleGetTeamMembers = (action$, state$) =>
  action$.pipe(
    ofType(
      ADMIN_ACTION.ON_GET_TEAM_MEMBERS,
      ADMIN_ACTION.ON_ADD_TEAM_MEMBER_SUCCESS,
      ADMIN_ACTION.ON_REMOVE_TEAM_MEMBER_SUCCESS
    ),
    map(() => ({
      brandId: extractBrandId(state$)
    })),
    filter(({ brandId }) => Boolean(brandId)),
    mergeMap(({ brandId }) =>
      wrapUserAccessToken((accessToken) =>
        apiService.getTeamMembers(brandId, accessToken).pipe(
          tap((data) => logger(data)),
          map(({ response }) => ({
            type: ADMIN_ACTION.ON_GET_TEAM_MEMBERS_SUCCESS,
            payload: formatTeamMembers(response.data)
          })),
          catchError((err) =>
            of({
              type: ADMIN_ACTION.ON_GET_TEAM_MEMBERS_FAILED,
              payload: err
            })
          )
        )
      )
    )
  )

const handleAddTeamMember = (action$, state$) =>
  action$.pipe(
    ofType(ADMIN_ACTION.ON_ADD_TEAM_MEMBER),
    mergeMap(({ payload }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .addTeamMember(
            extractBrandId(state$),
            buildTeamMemberBody(payload),
            accessToken
          )
          .pipe(
            map(({ response }) => ({
              type: ADMIN_ACTION.ON_ADD_TEAM_MEMBER_SUCCESS,
              payload: response
            })),
            catchError(({ response }) =>
              of({
                type: ADMIN_ACTION.ON_ADD_TEAM_MEMBER_FAILED,
                payload: response
              })
            )
          )
      )
    )
  )

const handleRemoveTeamMember = (action$, state$) =>
  action$.pipe(
    ofType(ADMIN_ACTION.ON_REMOVE_TEAM_MEMBER),
    mergeMap(({ payload }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .removeTeamMember(extractBrandId(state$), payload, accessToken)
          .pipe(
            map(({ response }) => ({
              type: ADMIN_ACTION.ON_REMOVE_TEAM_MEMBER_SUCCESS,
              payload: response
            })),
            catchError((err) =>
              of({
                type: ADMIN_ACTION.ON_REMOVE_TEAM_MEMBER_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

export const adminEpic = combineEpics(
  handleGetTeamMembers,
  handleAddTeamMember,
  handleRemoveTeamMember
)
