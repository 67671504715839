import React, { memo } from 'react'

import CustomDialog from '../../shared/CustomDialog/CustomDialog'
import { TXN_REFUND_DISPATCH } from '../../../store/reducers/transactions/refundMiles'
import { transWithArgs } from '../../../util/validation.helpers'
import InputHelperText from '../../shared/inputs/InputHelperText'

const RefundMiles = ({
  t,
  onClose,
  onSubmit,
  refundMilesProfile: { isProcessing, error },
  ...restProps
}) => {
  return (
    <CustomDialog
      isForm
      title={t('refund.refund-miles-title')}
      description={t('refund.refund-miles-desc')}
      open
      onClose={onClose}
      submitProps={{
        label: t('refund.refund-miles'),
        disabled: isProcessing
      }}
      body={
        <InputHelperText
          errorMessage={transWithArgs(t, error[TXN_REFUND_DISPATCH.STATUS])}
        />
      }
      onSubmit={onSubmit}
      t={t}
      {...restProps}
    />
  )
}

export default memo(RefundMiles)
