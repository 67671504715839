export const EXTENSIONS_TAB_TYPE = {
  THIRD_PARTY_TOOLS: 'third-party-tools',
  EARN_OPTIONS: 'earn-options',
  REDEMPTION_SHOP: 'redemption-shop'
}

export const EXTENSIONS_TAB_CONTENT_ID = {
  THIRD_PARTY_TOOLS: 0,
  EARN_OPTIONS: 1,
  REDEMPTION_SHOP: 2
}

export const THIRD_PARTY_TOOLS_TYPE_FILTERS = {
  ALL: 'all',
  ECOMMERCE: 'ecommerce',
  CRM: 'crm',
  ANALYTICS: 'analytics',
  PAYMENT: 'payment',
  LOYALTY_PROGRAM_PROVIDERS: 'loyalty-program-providers',
  POS_SOFTWARE: 'pos-software'
}

export const EARN_OPTION_TOGGLE_IDS = {
  ENABLE_PURCHASE_POINTS: 'enable_purchase_points',
  ENABLE_PURCHASE_POINTS_WITH_MILES: 'enable_purchase_points_with_miles',
  CASHBACK_EARN_OPTION: 'cashback_earn_option'
}
