import { MAIN_PROFILE_ACTION } from '../../constants/actions'

const changeMainState = (property, value) => ({
  type: MAIN_PROFILE_ACTION.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

const newBrandCreation = () => ({
  type: MAIN_PROFILE_ACTION.ON_NEW_BRAND_CREATION
})

const switchBrandAndToken = (brandId, tokenId) => ({
  type: MAIN_PROFILE_ACTION.ON_SWITCH_BRAND_AND_TOKEN,
  payload: { brandId, tokenId }
})

export { changeMainState, newBrandCreation, switchBrandAndToken }
