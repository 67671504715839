import React, { memo } from 'react'

import SetupInfo from '../dashboardSetup/SetupInfo'
import SubscriptionAlert from '../common/SubscriptionAlert'
import { isTokenPaused, isTokenStopped } from '../../../util/token.helpers'
import PausedTokenAlert from './PausedTokenAlert'

const DisplayAlerts = ({
  t,
  isDashboardSetupReady,
  tokenStatus,
  subscribedPackage
}) => {
  return (
    <>
      {!isDashboardSetupReady && <SetupInfo t={t} />}
      {isTokenPaused(tokenStatus) && <PausedTokenAlert />}
      {isTokenStopped(tokenStatus) && (
        <SubscriptionAlert
          severity='error'
          label='active-your-subscription'
          t={t}
        />
      )}
      {subscribedPackage?.cancelAtPeriodEnd && ( // @TODO: Need to handle this
        <SubscriptionAlert
          severity='error'
          label='extend-your-subscription-here'
          i18nKey='setup.subscription-cancelled-message'
          t={t}
        />
      )}
    </>
  )
}

export default memo(DisplayAlerts)
