import React, { memo } from 'react'

import { adminPackagesBillingUrl } from '../../util/admin.helpers'
import ActionCard from './ActionCard'
import qbLadUpgradeBg from '../../assets/images/backgrounds/LAD-upgrade-graphic.jpg'

const UpgradeFromStarter = ({ t }) => {
  return (
    <ActionCard
      title={t('billing.upgrade-description')}
      buttonLabel={t('billing.upgrade')}
      to={adminPackagesBillingUrl()}
      titleCss={{
        color: 'common.white',
        pr: '36%',
        fontWeight: 800
      }}
      cardCss={{
        backgroundImage: `url(${qbLadUpgradeBg})`,
        backgroundSize: 'cover',
        minHeight: 100,
        backgroundPosition: 'right bottom'
      }}
      actionProps={{
        width: 'max-content'
      }}
      actionButtonProps={{
        color: 'lightGrey'
      }}
    />
  )
}

export default memo(UpgradeFromStarter)
