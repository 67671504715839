import React, { memo } from 'react'
import { Chip } from '@mui/material'

import { extractColorByWhitelistStatus } from '../../util/exchange.helpers'

const ExchangeStatusChip = ({ status, t }) => {
  return (
    <Chip
      label={`${t('common.status')}: ${t(
        `exchange.whitelist-status.${status}`
      )}`}
      size='small'
      color={extractColorByWhitelistStatus(status)}
    />
  )
}

export default memo(ExchangeStatusChip)
