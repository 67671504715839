import React, { memo } from 'react'
import { Avatar, Box, Grid } from '@mui/material'
import { faImage } from '@fortawesome/pro-thin-svg-icons'
import { useTheme } from '@mui/material/styles'
import { Trans } from 'react-i18next'

import { NFTS_PROFILE_DISPATCH } from '../../store/reducers/nfts/nft'
import CustomDialog from '../shared/CustomDialog/CustomDialog'
import BaseForm from '../shared/BaseForm/BaseForm'
import FileField from '../shared/inputs/FileField'
import {
  transWithArgs,
  validNaturalNumberRule,
  validNftSalesFeeRule
} from '../../util/validation.helpers'
import Input from '../shared/inputs/Input'
import { LABEL_TEXT, QB_ERROR_TEXT } from '../../constants/messages'
import Dropdown from '../shared/inputs/Dropdown'
import MultilineField from '../shared/MultilineField/MultilineField'
import {
  MULTILINE_FIELD_MAX_LENGTH,
  NFT_NAME_MAX_LENGTH,
  SALES_FEE_MAX_LENGTH
} from '../../constants/validation'
import {
  buildExclusiveContentModeOptions,
  buildNftTypeOptions,
  buildYesNoOptions,
  NFT_YES_NO_OPTIONS
} from '../../data/nfts'
import { formatTicks } from '../../util/string.helpers'
import CustomFormLabel from '../shared/forms/customElements/CustomFormLabel'
import { CSS_FLEX_CENTERED, CSS_FLEX_COLUMN } from '../../styles/common'
import FaIcon from '../shared/FaIcon/FaIcon'
import { QB_INPUT_NUMBER } from '../../constants/input'
import Link from '../shared/link/Link'
import {
  isSameExclusiveContent,
  isUniqueExclusiveContent
} from '../../util/nft.helpers'
import { EXCLUSIVE_CONTENT_SAMPLE_CSV_PATH } from '../../constants/nfts'
import ExclusiveContentHelpText from '../shared/common/ExclusiveContentHelpText'

const CreateNftForm = ({
  nftProfile: {
    error,
    isProcessing,
    media,
    mediaUrl,
    name: nftName,
    type,
    externalLink,
    description,
    exclusiveContent,
    exclusiveContentMode,
    supply,
    userSaleTemp,
    userTransferTemp,
    userSalesFeeTemp
  },
  tokenProfile: { name },
  dispatchAction,
  onClose,
  onSubmit,
  title,
  t
}) => {
  const theme = useTheme()
  return (
    <CustomDialog
      open
      submitProps={{
        label: t('nft.create-nft'),
        disabled: isProcessing
      }}
      onSubmit={onSubmit}
      onClose={onClose}
      customCss={{
        pl: 2,
        pr: 2
      }}
      paperProps={{
        maxWidth: '650px'
      }}
      body={
        <BaseForm
          title={title}
          customCss={{ p: 2 }}
          fields={
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} sx={CSS_FLEX_COLUMN}>
                    <FileField
                      id={NFTS_PROFILE_DISPATCH.MEDIA}
                      accept='image/*, audio/*, video/*'
                      label={t('nft.image-video-audio')}
                      errorMessage={transWithArgs(
                        t,
                        error[NFTS_PROFILE_DISPATCH.MEDIA]
                      )}
                      onChange={(e) => {
                        if (e.target.files && e.target.files.length > 0) {
                          dispatchAction(
                            NFTS_PROFILE_DISPATCH.MEDIA,
                            e.target.files[0]
                          )
                        }
                      }}
                    />
                    <Input
                      id={NFTS_PROFILE_DISPATCH.NAME}
                      label={t(`nft.name-title`)}
                      value={nftName}
                      errorMessage={transWithArgs(
                        t,
                        error[NFTS_PROFILE_DISPATCH.NAME]
                      )}
                      onChange={(value) =>
                        dispatchAction(NFTS_PROFILE_DISPATCH.NAME, value)
                      }
                      bottomLabel={t(QB_ERROR_TEXT.CHARACTERS_MAX, {
                        max: formatTicks(NFT_NAME_MAX_LENGTH)
                      })}
                      inputProps={{ maxLength: NFT_NAME_MAX_LENGTH }}
                      size='small'
                    />
                    <Dropdown
                      id={NFTS_PROFILE_DISPATCH.TYPE}
                      label={t(LABEL_TEXT.TYPE)}
                      helpText={t('nft.type-desc')}
                      options={buildNftTypeOptions(t)}
                      onChange={({ target }) =>
                        dispatchAction(NFTS_PROFILE_DISPATCH.TYPE, target.value)
                      }
                      value={type}
                      errorMessage={transWithArgs(
                        t,
                        error[NFTS_PROFILE_DISPATCH.TYPE]
                      )}
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sx={CSS_FLEX_COLUMN}>
                    <CustomFormLabel>{t('nft.preview')}</CustomFormLabel>
                    {media || mediaUrl ? (
                      <Avatar
                        src={media ? URL.createObjectURL(media) : mediaUrl}
                        sx={{ width: 230, height: 230, borderRadius: '7px' }}
                        variant='square'
                      />
                    ) : (
                      <Box
                        sx={{
                          ...CSS_FLEX_CENTERED,
                          width: '230px',
                          height: '230px',
                          border: '1px solid',
                          borderColor: theme.palette.grey.A400,
                          borderRadius: '7px'
                        }}
                      >
                        <FaIcon
                          icon={faImage}
                          fontSize={120}
                          color={theme.palette.lightGrey.dark}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={4} xs={12}>
                    <Input
                      id={NFTS_PROFILE_DISPATCH.SUPPLY}
                      label={t('nft.supply')}
                      helpText={t('nft.supply-desc')}
                      type={QB_INPUT_NUMBER}
                      value={supply}
                      errorMessage={transWithArgs(
                        t,
                        error[NFTS_PROFILE_DISPATCH.SUPPLY]
                      )}
                      onChange={(value) =>
                        dispatchAction(NFTS_PROFILE_DISPATCH.SUPPLY, value)
                      }
                      placeholder={t('nft.supply-placeholder')}
                      size='small'
                      validate={[validNaturalNumberRule]}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Dropdown
                      id={NFTS_PROFILE_DISPATCH.USER_TRANSFER_TEMP}
                      label={t('nft.user-transfer')}
                      helpText={t('nft.user-transfer-desc')}
                      options={buildYesNoOptions(t)}
                      value={userTransferTemp}
                      errorMessage={transWithArgs(
                        t,
                        error[NFTS_PROFILE_DISPATCH.USER_TRANSFER_TEMP]
                      )}
                      onChange={({ target }) =>
                        dispatchAction(
                          NFTS_PROFILE_DISPATCH.USER_TRANSFER_TEMP,
                          target.value
                        )
                      }
                      size='small'
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Dropdown
                      id={NFTS_PROFILE_DISPATCH.USER_SALE_TEMP}
                      label={t('nft.user-sale')}
                      helpText={t('nft.user-sale-desc')}
                      value={userSaleTemp}
                      options={buildYesNoOptions(t)}
                      errorMessage={transWithArgs(
                        t,
                        error[NFTS_PROFILE_DISPATCH.USER_SALE_TEMP]
                      )}
                      onChange={({ target }) =>
                        dispatchAction(
                          NFTS_PROFILE_DISPATCH.USER_SALE_TEMP,
                          target.value
                        )
                      }
                      size='small'
                    />
                  </Grid>
                </Grid>
                {userSaleTemp === NFT_YES_NO_OPTIONS.YES && (
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <Input
                        id={NFTS_PROFILE_DISPATCH.USER_SALES_FEE_TEMP}
                        label={`${t('nft.user-sales-fee')} (%)`}
                        helpText={t('nft.user-sales-fee-desc', { name })}
                        type={QB_INPUT_NUMBER}
                        value={userSalesFeeTemp}
                        validate={[validNftSalesFeeRule]}
                        errorMessage={transWithArgs(
                          t,
                          error[NFTS_PROFILE_DISPATCH.USER_SALES_FEE_TEMP]
                        )}
                        onChange={(value) =>
                          dispatchAction(
                            NFTS_PROFILE_DISPATCH.USER_SALES_FEE_TEMP,
                            value
                          )
                        }
                        inputProps={{ maxLength: SALES_FEE_MAX_LENGTH }}
                        size='small'
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <MultilineField
                      id={NFTS_PROFILE_DISPATCH.DESCRIPTION}
                      label={`${t(LABEL_TEXT.DESCRIPTION)} (${t(
                        'common.optional'
                      )})`}
                      helpText={
                        <ExclusiveContentHelpText
                          t={t}
                          i18nKey='nft.description-desc'
                        />
                      }
                      value={description}
                      errorMessage={transWithArgs(
                        t,
                        error[NFTS_PROFILE_DISPATCH.DESCRIPTION]
                      )}
                      onChange={(value) =>
                        dispatchAction(NFTS_PROFILE_DISPATCH.DESCRIPTION, value)
                      }
                      inputProps={{ maxLength: MULTILINE_FIELD_MAX_LENGTH }}
                      t={t}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Dropdown
                      id={NFTS_PROFILE_DISPATCH.EXCLUSIVE_CONTENT_MODE}
                      label={t('nft.exclusive-content-mode')}
                      helpText={t('nft.exclusive-content-mode-desc')}
                      options={buildExclusiveContentModeOptions(t)}
                      value={exclusiveContentMode}
                      onChange={({ target }) =>
                        dispatchAction(
                          NFTS_PROFILE_DISPATCH.EXCLUSIVE_CONTENT_MODE,
                          target.value
                        )
                      }
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {isSameExclusiveContent(exclusiveContentMode) && (
                      <MultilineField
                        id={NFTS_PROFILE_DISPATCH.EXCLUSIVE_CONTENT}
                        label={t('nft.exclusive-content-text')}
                        helpText={
                          <ExclusiveContentHelpText
                            t={t}
                            i18nKey='nft.exclusive-content-text-desc'
                          />
                        }
                        value={exclusiveContent}
                        errorMessage={transWithArgs(
                          t,
                          error[NFTS_PROFILE_DISPATCH.EXCLUSIVE_CONTENT]
                        )}
                        onChange={(value) =>
                          dispatchAction(
                            NFTS_PROFILE_DISPATCH.EXCLUSIVE_CONTENT,
                            value
                          )
                        }
                        placeholder={t('nft.exclusive-content-placeholder')}
                        inputProps={{ maxLength: MULTILINE_FIELD_MAX_LENGTH }}
                        t={t}
                      />
                    )}
                    {isUniqueExclusiveContent(exclusiveContentMode) && (
                      <FileField
                        id={NFTS_PROFILE_DISPATCH.EXCLUSIVE_CONTENT_FILE}
                        accept='.csv, text/csv'
                        label={t('nft.upload-csv-file')}
                        helpText={
                          <Trans i18nKey='nft.exclusive-dynamic-content-desc'>
                            <Link
                              isOutside
                              label={t('nft.csv-sample')}
                              to={EXCLUSIVE_CONTENT_SAMPLE_CSV_PATH}
                              download
                            />
                          </Trans>
                        }
                        errorMessage={transWithArgs(
                          t,
                          error[NFTS_PROFILE_DISPATCH.EXCLUSIVE_CONTENT_FILE]
                        )}
                        onChange={(e) => {
                          if (e.target.files && e.target.files.length > 0) {
                            dispatchAction(
                              NFTS_PROFILE_DISPATCH.EXCLUSIVE_CONTENT_FILE,
                              e.target.files[0]
                            )
                          }
                        }}
                        bottomLabel={
                          <Trans i18nKey='nft.download-sample-csv-here'>
                            <Link
                              isOutside
                              label={t('common.here')}
                              to={EXCLUSIVE_CONTENT_SAMPLE_CSV_PATH}
                              download
                            />
                          </Trans>
                        }
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Input
                      id={NFTS_PROFILE_DISPATCH.EXTERNAL_LINK}
                      label={`${t('nft.external-link')} (${t(
                        'common.optional'
                      )})`}
                      helpText={t('nft.external-link-desc')}
                      value={externalLink}
                      errorMessage={transWithArgs(
                        t,
                        error[NFTS_PROFILE_DISPATCH.EXTERNAL_LINK]
                      )}
                      onChange={(value) =>
                        dispatchAction(
                          NFTS_PROFILE_DISPATCH.EXTERNAL_LINK,
                          value
                        )
                      }
                      size='small'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      }
      t={t}
    />
  )
}

export default memo(CreateNftForm)
