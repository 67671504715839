import React, { memo } from 'react'
import { Card, Chip, Box, List, ListItem, ListItemText } from '@mui/material'
import { Trans } from 'react-i18next'

import CustomButton from '../buttons/CustomButton'
import { formatTicks, toUpper } from '../../../util/string.helpers'
import Text from '../text/Text'
import {
  isStarterPackagePlan,
  isPremiumPackagePlan
} from '../../../util/billing.helpers'
import { CSS_FLEX_COLUMN } from '../../../styles/common'
import { openExternalUrl } from '../../../util/app/app.helpers'
import { STARTER_PACKAGE_CALENDLY_URL } from '../../../constants/billing'
import { formatPercentage } from '../../../util/number.helpers'

const PricingCard = ({
  isMostPopular,
  name,
  monthlyPrice,
  yearlyPrice,
  monthlyCredits,
  yearlyCredits,
  nfts,
  fee,
  isYearly,
  onSelectPlan,
  disabled,
  billingPackageAction,
  isActionDisabled,
  t
}) => {
  const starterPlan = isStarterPackagePlan(name)
  const premiumPlan = isPremiumPackagePlan(name)
  const planLabel = toUpper(name)
  return (
    <Card
      sx={{
        m: 0,
        textAlign: 'center',
        overflow: 'unset',
        position: 'relative',
        height: '100%',
        ...CSS_FLEX_COLUMN
      }}
    >
      {isMostPopular && (
        <Chip
          sx={{
            bgcolor: (theme) => theme.palette.primary.main,
            mt: -4,
            color: '#fff',
            fontSize: '14px',
            maxWidth: '120px',
            position: 'absolute',
            left: '0',
            right: '0',
            ml: 'auto',
            mr: 'auto'
          }}
          label={t('billing.most-popular')}
        />
      )}
      <Text label={planLabel} sx={{ mt: 3 }} fontWeight={500} />
      <Box sx={{ mt: 3 }}>
        <Text
          label={
            <>&euro; {formatTicks(isYearly ? yearlyPrice : monthlyPrice)}</>
          }
          sx={{ fontSize: '40px' }}
          fontWeight={600}
        />
        <Text
          sx={{
            fontSize: '15px',
            color: 'grey.A200',
            ml: 1
          }}
          label={t(`billing.per-${isYearly ? 'year' : 'month'}`)}
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <List>
          <CustomListItem
            primary={
              <Trans
                i18nKey={`billing.package-details.bonus-credits${
                  starterPlan ? '-starter' : ''
                }`}
                values={{
                  credits: formatTicks(
                    isYearly ? yearlyCredits : monthlyCredits
                  ),
                  label: t(`billing.${isYearly ? 'year' : 'month'}`)
                }}
              />
            }
          />
          {['marketplace', 'analytics', 'api', 'lwa'].map((feature) => (
            <CustomListItem
              key={feature}
              primary={t(`billing.package-details.${feature}`)}
              isDisabled={disabled?.[feature]}
            />
          ))}
          <CustomListItem
            primary={
              <Trans
                i18nKey={`billing.package-details.nfts${
                  starterPlan ? '-starter' : ''
                }`}
                values={{
                  nfts: premiumPlan ? t('billing.unlimited') : nfts
                }}
              />
            }
          />
          <CustomListItem
            primary={t('billing.package-details.csm')}
            isDisabled={disabled?.csm}
          />
          <CustomListItem
            primary={t('billing.package-details.redemption-shop', {
              label: premiumPlan ? t('billing.incl') : '**'
            })}
          />
          <CustomListItem
            primary={t('billing.package-details.support')}
            isDisabled={disabled?.support}
          />
          <CustomListItem
            primary={
              <Trans
                i18nKey='billing.package-details.fee'
                values={{
                  fee: formatPercentage(fee)
                }}
              />
            }
          />
        </List>
      </Box>
      <Box flexGrow={1} />
      <CustomButton
        sx={{ pl: 1, pr: 1, whiteSpace: 'nowrap' }}
        label={buildButtonLabel({ billingPackageAction, planLabel, t })}
        onClick={() =>
          starterPlan
            ? openExternalUrl(STARTER_PACKAGE_CALENDLY_URL)
            : onSelectPlan()
        }
        disabled={
          billingPackageAction?.isSubscribed ||
          isActionDisabled ||
          (isYearly && starterPlan)
        }
      />
    </Card>
  )
}

export default memo(PricingCard)

const CustomListItem = ({ isDisabled, ...restProps }) => {
  return (
    <ListItem sx={{ pl: 0, pr: 0, pt: 0 }}>
      <ListItemText
        sx={{
          textAlign: 'center',
          lineHeight: 'normal',
          ...(isDisabled && { opacity: 0.3 })
        }}
        {...restProps}
      />
    </ListItem>
  )
}

const buildButtonLabel = ({ billingPackageAction, plan, t }) => {
  if (billingPackageAction) {
    if (billingPackageAction.isSubscribed) {
      return t('billing.your-current-package')
    } else if (billingPackageAction.isToUpgrade) {
      return t('billing.upgrade-package', { plan })
    } else {
      return t('billing.downgrade-package', { plan })
    }
  }
  return t('billing.select-package-plan', { plan })
}
