import {
  map,
  mergeMap,
  catchError,
  tap,
  filter,
  concatMap
} from 'rxjs/operators'
import { forkJoin, from, of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'

import { logger } from '../../util/log.helpers'
import { NFTS_PROFILE_ACTIONS } from '../../constants/actions'
import apiService from '../../services/api'
import { wrapUserAccessToken } from '../../util/auth.helpers'
import { buildFileUrl } from '../../util/brand.helpers'
import { commonParser } from '../../util/apiParser.helpers'
import {
  buildRedeemExclCntData,
  buildUpdateNftData,
  filterNftsWithSupply,
  nftFieldsFormat
} from '../../util/nft.helpers'
import {
  buildPaginationFilters,
  extractBrandId,
  extractNftUsersPagination,
  formatTokens,
  parseNftUsersResponse
} from '../../util/epics.helpers'
import { TOKEN_TYPE } from '../../constants/token'
import { LOYALTY_EVENT_TYPES } from '../../constants/transactionTypes'
import { getFileExtension } from '../../util/app/app.helpers'

const uploadMediaFilesForNft = (
  { media, exclusiveContentFile },
  accessToken
) => {
  const sources = {}
  if (media) {
    sources.mediaUrl = fileUploadSteps(media, accessToken)
  }
  if (exclusiveContentFile) {
    sources.exclusiveContentFileUrl = fileUploadSteps(
      exclusiveContentFile,
      accessToken
    )
  }
  return forkJoin(sources).pipe(map((response) => response))
}

const fileUploadSteps = (file, accessToken) => {
  return from([1]).pipe(
    concatMap(() => {
      return apiService.getFileUploadPreSignedUrl(
        getFileExtension(file),
        accessToken
      )
    }),
    concatMap(({ response }) => {
      const url = response.data.url
      return apiService.uploadFile(url, file).pipe(
        map(() => {
          return buildFileUrl(url)
        })
      )
    })
  )
}

const handleFilesUploadForNftCreateEpic = (action$) =>
  action$.pipe(
    ofType(NFTS_PROFILE_ACTIONS.ON_CREATE_NFT),
    mergeMap(({ payload }) =>
      wrapUserAccessToken((accessToken) =>
        uploadMediaFilesForNft(payload, accessToken).pipe(
          map((response) => ({
            type: NFTS_PROFILE_ACTIONS.ON_UPLOAD_FILES_SUCCESS,
            payload: response
          })),
          catchError((error) =>
            of({
              type: NFTS_PROFILE_ACTIONS.ON_UPLOAD_FILES_FAILED,
              payload: error
            })
          )
        )
      )
    )
  )

const handleCreateNftEpic = (action$, state$) =>
  action$.pipe(
    ofType(NFTS_PROFILE_ACTIONS.ON_UPLOAD_FILES_SUCCESS),
    mergeMap(({ payload }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .createToken(
            extractBrandId(state$),
            extractCreateNftData(state$, payload),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => {
              return {
                type: NFTS_PROFILE_ACTIONS.ON_CREATE_NFT_SUCCESS,
                payload: commonParser(response.data)
              }
            }),
            catchError((error) =>
              of({
                type: NFTS_PROFILE_ACTIONS.ON_CREATE_NFT_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

const handleUpdateNftEpic = (action$, state$) =>
  action$.pipe(
    ofType(NFTS_PROFILE_ACTIONS.ON_UPDATE_NFT),
    mergeMap(({ payload: { tokenId, data } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .updateToken(
            extractBrandId(state$),
            tokenId,
            buildUpdateNftData(data),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => {
              return {
                type: NFTS_PROFILE_ACTIONS.ON_UPDATE_NFT_SUCCESS,
                payload: commonParser(response.data)
              }
            }),
            catchError((error) =>
              of({
                type: NFTS_PROFILE_ACTIONS.ON_UPDATE_NFT_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

const handleTransferNftEpic = (action$, state$) =>
  action$.pipe(
    ofType(NFTS_PROFILE_ACTIONS.ON_TRANSFER_NFT),
    mergeMap(({ payload: { authId, tokenNftId } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .transferNft(
            extractBrandId(state$),
            buildTransferNftData(authId, tokenNftId),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(() => {
              return {
                type: NFTS_PROFILE_ACTIONS.ON_TRANSFER_NFT_SUCCESS,
                payload: { tokenId: tokenNftId, pageNumber: 0 }
              }
            }),
            catchError((error) =>
              of({
                type: NFTS_PROFILE_ACTIONS.ON_TRANSFER_NFT_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

const handleGetNftsEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      NFTS_PROFILE_ACTIONS.ON_GET_NFTS,
      NFTS_PROFILE_ACTIONS.ON_CREATE_NFT_SUCCESS
    ),
    map(() => ({
      brandId: extractBrandId(state$)
    })),
    filter(({ brandId }) => brandId),
    mergeMap(({ brandId }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getTokens(brandId, accessToken, {
            types: [TOKEN_TYPE.ERC_721]
          })
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => {
              return {
                type: NFTS_PROFILE_ACTIONS.ON_GET_NFTS_SUCCESS,
                payload: filterNftsWithSupply(formatTokens(response.data))
              }
            }),
            catchError(() =>
              of({
                type: NFTS_PROFILE_ACTIONS.ON_GET_NFTS_FAILED
              })
            )
          )
      )
    )
  )

const handleGetNftUsers = (action$, state$) =>
  action$.pipe(
    ofType(
      NFTS_PROFILE_ACTIONS.ON_GET_NFT_USERS,
      NFTS_PROFILE_ACTIONS.ON_REDEEM_EXCLUSIVE_CONTENT_SUCCESS,
      NFTS_PROFILE_ACTIONS.ON_TRANSFER_NFT_SUCCESS
    ),
    mergeMap(({ payload }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getNftUsers(
            extractBrandId(state$),
            payload.tokenId,
            accessToken,
            buildNftUsersPaginationFilters(payload, state$)
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => {
              return {
                type: NFTS_PROFILE_ACTIONS.ON_GET_NFT_USERS_SUCCESS,
                payload: parseNftUsersResponse(response.data)
              }
            }),
            catchError(() =>
              of({
                type: NFTS_PROFILE_ACTIONS.ON_GET_NFT_USERS_FAILED
              })
            )
          )
      )
    )
  )

const handleRedeemNftExclusiveContent = (action$, state$) =>
  action$.pipe(
    ofType(NFTS_PROFILE_ACTIONS.ON_REDEEM_EXCLUSIVE_CONTENT),
    mergeMap(({ payload: { tokenId, nftId, userAuthId, claimCount } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .redeemNftExclusiveContent(
            extractBrandId(state$),
            tokenId,
            buildRedeemExclCntData(userAuthId, nftId, claimCount),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(() => {
              return {
                type: NFTS_PROFILE_ACTIONS.ON_REDEEM_EXCLUSIVE_CONTENT_SUCCESS,
                payload: { tokenId: tokenId }
              }
            }),
            catchError(() =>
              of({
                type: NFTS_PROFILE_ACTIONS.ON_REDEEM_EXCLUSIVE_CONTENT_FAILED
              })
            )
          )
      )
    )
  )

export const nftEpic = combineEpics(
  handleFilesUploadForNftCreateEpic,
  handleCreateNftEpic,
  handleGetNftsEpic,
  handleUpdateNftEpic,
  handleGetNftUsers,
  handleRedeemNftExclusiveContent,
  handleTransferNftEpic
)

const extractCreateNftData = (state, payload) => {
  const nftsReducer = state.value.nftsReducer
  return nftFieldsFormat(nftsReducer.nftProfile, payload)
}

const buildNftUsersPaginationFilters = (payload, state) => {
  const pagination = extractNftUsersPagination(state)
  return buildPaginationFilters(payload, pagination)
}

const buildTransferNftData = (authId, tokenNftId) => {
  return {
    transaction: {
      type: LOYALTY_EVENT_TYPES.CREDIT_NFT,
      token_id: tokenNftId,
      user_auth_id: authId
    }
  }
}
