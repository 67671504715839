import { mergeToNewObject } from '../../../util/object.helpers'
import { TXN_SEARCH_ACTIONS } from '../../../constants/actions'
import { TRANSACTION_SEARCH_FILTERS } from '../../../constants/members'

export const transactionSearchProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case TXN_SEARCH_ACTIONS.ON_CHANGE_STATE:
      return mergeToNewObject(
        state,
        { [payload.property]: payload.value },
        handleSearchChange(payload)
      )

    case TXN_SEARCH_ACTIONS.ON_RESET_STATE:
      // return INITIAL_STATE
      return mergeToNewObject(state, { query: '' })

    default:
      return state
  }
}

const INITIAL_STATE = {
  type: TRANSACTION_SEARCH_FILTERS.EMAIL,
  query: ''
}

export const TXN_SEARCH_PROFILE_DISPATCH = {
  TYPE: 'type',
  QUERY: 'query'
}

const handleSearchChange = ({ property }) => {
  if (property === TXN_SEARCH_PROFILE_DISPATCH.TYPE) {
    return {
      query: ''
    }
  }
  return {}
}
