import {
  MARKETPLACE_SETTINGS_SURVEY_ID,
  POINTS_PURCHASE_SURVEY_ID,
  SURVEY_QUESTION_EXCHANGE
} from '../constants/survey'
import { commonParser } from './apiParser.helpers'
import { extractBrandId } from './epics.helpers'
import { getSupportedLanguages } from './translation.helpers'
import { requiredRule } from './validation.helpers'

const formatSurveysToObject = (surveys) => {
  if (surveys && surveys.length) {
    return surveys.reduce((obj, data) => {
      const survey = commonParser(data)
      return survey && survey.id
        ? {
            ...obj,
            [survey.id]: survey
          }
        : {}
    }, {})
  }
  return {}
}

const formatUserSurveysToObject = (userSurveys) => {
  if (userSurveys && userSurveys.length) {
    return userSurveys.reduce((obj, userSurvey) => {
      const surveyId = userSurvey?.survey_id
      return surveyId
        ? {
            ...obj,
            [surveyId]: {
              id: userSurvey?.id,
              response: { ...obj?.[surveyId]?.response, ...userSurvey.response }
            }
          }
        : {}
    }, {})
  }
  return {}
}

const formatUserSurveyToObject = (userSurvey) => {
  if (userSurvey) {
    const { id, survey_id, response } = userSurvey
    return {
      surveyId: survey_id,
      id,
      response
    }
  }
  return {}
}

const extractSurveyQuestions = (survey, language) => {
  const supportedLanguages = getSupportedLanguages()
  return survey?.questions[language] || survey?.questions[supportedLanguages[0]]
}

const buildCreateUserSurveyData = (surveyId, response, state) => {
  const brandId = extractBrandId(state)
  return {
    user_survey: {
      response,
      survey_id: surveyId,
      ...(brandId && { brand_id: brandId })
    }
  }
}

const buildUpdateUserSurveyData = ({ surveyId, response }, state) => {
  const userSurvey = extractSurveyProfileState(state).userSurveys?.[surveyId]
  const brandId = extractBrandId(state)
  return {
    user_survey: {
      response: {
        ...userSurvey.response,
        ...response
      },
      ...(brandId && { brand_id: brandId })
    }
  }
}

const buildCheckValidationRules = (surveyFields, formData) => {
  const validations = {}
  if (surveyFields && surveyFields.length > 0) {
    surveyFields.forEach(({ type, id, required }) => {
      if (type) {
        const value = formData?.[id] || ''
        validations[id] = {
          value,
          rules: [required && requiredRule]
        }
      }
    })
  }
  return validations
}

const extractSurveyReducer = (state) => state.value.surveyReducer

const extractSurveyProfileState = (state) => {
  return extractSurveyReducer(state).surveyProfile
}

const filterEarnOptionsData = (data) => {
  return data?.filter((q) => q.id === 'co_branded_credit_card')
}

const buildResponseForExchangeTerms = (questions, formData) => {
  const response = {}
  const dataKeys = Object.keys(formData)
  dataKeys.forEach((key) => {
    if (key === SURVEY_QUESTION_EXCHANGE.TERMS) {
      const questionForResponse = questions?.filter(
        (q) => q.id === SURVEY_QUESTION_EXCHANGE.TERMS
      )?.[0]
      response[key] = {
        description: questionForResponse?.description,
        selected: formData[key]
      }
    } else {
      response[key] = {
        selected: formData[key]
      }
    }
  })
  return response
}

const buildMarketplaceSurvey = (partner) => {
  return `${partner?.id}${MARKETPLACE_SETTINGS_SURVEY_ID}`
}

const buildPointsPurchaseSurvey = (id) => {
  return `${id}${POINTS_PURCHASE_SURVEY_ID}`
}

export {
  formatSurveysToObject,
  formatUserSurveysToObject,
  formatUserSurveyToObject,
  extractSurveyQuestions,
  buildCheckValidationRules,
  buildCreateUserSurveyData,
  buildUpdateUserSurveyData,
  extractSurveyProfileState,
  filterEarnOptionsData,
  buildResponseForExchangeTerms,
  buildMarketplaceSurvey,
  buildPointsPurchaseSurvey
}
