import React, { memo } from 'react'
import { FormControl } from '@mui/material'

import CustomFormLabel from '../forms/customElements/CustomFormLabel'
import InputHelperText from './InputHelperText'
import PopoverHelpText from '../PopoverHelpText/PopoverHelpText'

const FileField = ({
  label,
  id,
  errorMessage,
  onChange,
  accept,
  helpText,
  multiple = false,
  ...restProps
}) => {
  const error = Boolean(errorMessage)
  return (
    <FormControl fullWidth error={error}>
      <CustomFormLabel htmlFor={id}>
        {label}
        {helpText && <PopoverHelpText label={helpText} />}
      </CustomFormLabel>
      <input
        type='file'
        accept={accept}
        id={id}
        onChange={onChange}
        multiple={multiple}
      />
      <InputHelperText {...restProps} errorMessage={errorMessage} />
    </FormControl>
  )
}

export default memo(FileField)
