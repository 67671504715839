import { isProduction } from './app/app.helpers'

export function logger(value) {
  if (isProduction()) return
  console.log(value)
}

export function logAppVersion(packageJson) {
  logger(`App Version is ${packageJson.version}`)
}
