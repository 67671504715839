const EXCHANGE_PERFORMANCE_PATH = 'exchange-in-out'
const EXCHANGE_MEMBERS_STAT_PATH = 'members-by-exchange'
const TOP_EXCHANGE_PARTNERS_PATH = 'top-exchange-partners'
const TOTAL_ACTIVE_MEMBERS_PATH = 'total-active-members'

const HTTP_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  PUT: 'PUT',
  DELETE: 'DELETE'
}

const API_RESPONSE = {
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  CONFLICT: 409
}

export {
  EXCHANGE_PERFORMANCE_PATH,
  EXCHANGE_MEMBERS_STAT_PATH,
  TOP_EXCHANGE_PARTNERS_PATH,
  TOTAL_ACTIVE_MEMBERS_PATH,
  HTTP_METHOD,
  API_RESPONSE
}
