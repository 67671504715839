import { map, mergeMap, catchError, tap, filter } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'

import { logger } from '../../util/log.helpers'

import { BRAND_ACTIONS, FILE_UPLOAD_ACTIONS } from '../../constants/actions'
import apiService from '../../services/api'
import { wrapUserAccessToken } from '../../util/auth.helpers'
import { getFileExtension } from '../../util/app/app.helpers'
import { FILE_UPLOAD_TYPE } from '../../constants/input'

const handleCallPresignedUrlAfterOnCreateBrandEpic = (action$) =>
  action$.pipe(
    ofType(BRAND_ACTIONS.ON_CREATE_BRAND),
    map(({ payload: { file } }) => ({
      type: FILE_UPLOAD_ACTIONS.ON_GET_PRESIGNED_URL,
      payload: { file, action: FILE_UPLOAD_TYPE.CREATE_BRAND }
    }))
  )

const handleGetPresignedUrlEpic = (action$) =>
  action$.pipe(
    ofType(FILE_UPLOAD_ACTIONS.ON_GET_PRESIGNED_URL),
    filter(({ payload: { file } }) => file),
    mergeMap(({ payload: { file } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getFileUploadPreSignedUrl(getFileExtension(file), accessToken)
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: FILE_UPLOAD_ACTIONS.ON_GET_PRESIGNED_URL_SUCCESS,
              payload: { data: response.data, file }
            })),
            catchError((error) =>
              of({
                type: FILE_UPLOAD_ACTIONS.ON_GET_PRESIGNED_URL_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

const handleFileUploadEpic = (action$) =>
  action$.pipe(
    ofType(FILE_UPLOAD_ACTIONS.ON_GET_PRESIGNED_URL_SUCCESS),
    mergeMap(({ payload: { data, file } }) =>
      apiService.uploadFile(data.url, file).pipe(
        tap((data) => logger(data)),
        map(() => ({
          type: FILE_UPLOAD_ACTIONS.ON_UPLOAD_FILE_SUCCESS,
          payload: data.url
        })),
        catchError((error) =>
          of({
            type: FILE_UPLOAD_ACTIONS.ON_UPLOAD_FILE_FAILED,
            payload: error
          })
        )
      )
    )
  )

export const fileUploadEpic = combineEpics(
  handleCallPresignedUrlAfterOnCreateBrandEpic,
  handleGetPresignedUrlEpic,
  handleFileUploadEpic
)
