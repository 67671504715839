import React, { memo } from 'react'
import { Grid } from '@mui/material'

import SearchInput from '../shared/inputs/SearchInput'
import Dropdown from '../shared/inputs/Dropdown'
import { TXN_SEARCH_PROFILE_DISPATCH } from '../../store/reducers/transactions/transactionSearch'
import { buildTxnSearchTypeOptions } from '../../util/transaction/transaction.helpers'

const TransactionsSearch = ({
  t,
  transactionSearchProfile: { type, query },
  dispatchAction,
  onResetSearchClick
}) => {
  return (
    <>
      <Grid item>
        <Dropdown
          id={TXN_SEARCH_PROFILE_DISPATCH.TYPE}
          options={buildTxnSearchTypeOptions(t)}
          onChange={({ target }) => {
            onResetSearchClick()
            dispatchAction(TXN_SEARCH_PROFILE_DISPATCH.TYPE, target.value)
          }}
          value={type}
          size='small'
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <SearchInput
          id={TXN_SEARCH_PROFILE_DISPATCH.QUERY}
          placeholder={t('transactions.search-transactions')}
          value={query}
          onChange={(value) =>
            dispatchAction(TXN_SEARCH_PROFILE_DISPATCH.QUERY, value)
          }
          onResetSearchClick={onResetSearchClick}
        />
      </Grid>
    </>
  )
}

export default memo(TransactionsSearch)
