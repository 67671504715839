import {
  EXCHANGE_CONNECTION_ACTION,
  EXCHANGE_CONNECTION_TYPES,
  EXCHANGE_DETAILS_TAB_CONTENT_ID,
  EXCHANGE_DETAILS_TAB_TYPE,
  EXCHANGE_FEEDBACK_OTHER,
  EXCHANGE_PARTNER_FILTER_OPTIONS,
  EXCHANGE_PARTNER_TYPES,
  EXCHANGE_PROVIDERS_BRAND_IDS_IGNORE,
  EXCHANGE_REQUEST_TYPES,
  EXCHANGE_TAB_CONTENT_ID
} from '../constants/exchange'
import { TOKEN_TYPE, TOKEN_WHITELIST_STATUS } from '../constants/token'
import { commonParser } from './apiParser.helpers'
import {
  buildDropdownList,
  isExistsElementFromList,
  sortLabelsByASC
} from './app/app.helpers'
import {
  isAllCountriesExists,
  isTokenActive,
  isTokenPending,
  isTokenTypeCrypto,
  isTokenTypeCryptoFiat
} from './token.helpers'
import { MARKETPLACE_URL } from '../constants/navigation'
import {
  extractAllRegionsByCountries,
  fetchCountriesByRegion
} from './country.helpers'
import { formatArrayToString, toLower } from './string.helpers'
import { formatCurrencyValue } from './number.helpers'
import { EXCHANGE_PARTNER_DISPATCH } from '../store/reducers/exchange/partners'
import {
  QB_DEFAULT_COUNTRY_VALUE,
  QB_DEFAULT_MEMBERS_VALUE,
  QB_DEFAULT_REGION_VALUE,
  QB_DEFAULT_TYPE_VALUE
} from '../constants/app'
import { SORTING_OPTIONS } from '../constants/dashboard'
import { extractPartnersProfile, extractTokenId } from './epics.helpers'
import { sortDataByDateDESC } from './nft.helpers'
import { isBrandMM } from './brand.helpers'

const formatPartners = (partners) => {
  return partners.map((partner) => commonParser(partner))
}

const reduceExchangePartners = (partners) => {
  const partnersObj = partners?.reduce((acc, partner) => {
    const groupKey = toLower(partner.symbol)
    if (isTokenTypeCrypto(partner.type)) {
      if (!acc[groupKey]) {
        acc[groupKey] = partner
        acc[groupKey].additionalList = []
      } else {
        acc[groupKey].additionalList.push(partner)
      }
    } else {
      acc[partner.id + (partner?.tokenExchangeWhitelist?.id || 0)] = partner
    }
    return acc
  }, {})
  return Object.values(partnersObj)
}

const filterPartnersByType = (partners, filterType) => {
  switch (filterType) {
    case EXCHANGE_PARTNER_FILTER_OPTIONS.ALL:
      return partners
    default:
      return partners.filter(({ type }) =>
        filterPartnersConditionMapping(type, filterType)
      )
  }
}

const filterPartnersConditionMapping = (type, filterType) => {
  switch (filterType) {
    case EXCHANGE_PARTNER_FILTER_OPTIONS.POINTS:
      return [TOKEN_TYPE.ERC_20, TOKEN_TYPE.EXTERNAL].includes(type)
    default:
      return type === filterType
  }
}

const filterPartnersByFromOrTo = (partners, fromFilter) => {
  return partners?.filter(({ isFrom }) =>
    fromFilter === undefined ? partners : isFrom === fromFilter
  )
}

const fromExchangeTabIdToType = (tabId) => {
  switch (tabId) {
    case EXCHANGE_TAB_CONTENT_ID.ACTIVE:
      return EXCHANGE_PARTNER_TYPES.ACTIVE
    case EXCHANGE_TAB_CONTENT_ID.REQUESTS:
      return EXCHANGE_PARTNER_TYPES.REQUESTS
    case EXCHANGE_TAB_CONTENT_ID.BRANDS_YOU_LOVE:
      return EXCHANGE_PARTNER_TYPES.BRANDS_YOU_LOVE
    case EXCHANGE_TAB_CONTENT_ID.SETTINGS:
      return EXCHANGE_PARTNER_TYPES.SETTINGS
    default:
      return EXCHANGE_PARTNER_TYPES.POTENTIAL
  }
}

const fromExchangeTypeToTabId = (selectedTab) => {
  switch (selectedTab) {
    case EXCHANGE_PARTNER_TYPES.ACTIVE:
      return EXCHANGE_TAB_CONTENT_ID.ACTIVE
    case EXCHANGE_PARTNER_TYPES.REQUESTS:
      return EXCHANGE_TAB_CONTENT_ID.REQUESTS
    case EXCHANGE_PARTNER_TYPES.BRANDS_YOU_LOVE:
      return EXCHANGE_TAB_CONTENT_ID.BRANDS_YOU_LOVE
    case EXCHANGE_PARTNER_TYPES.SETTINGS:
      return EXCHANGE_TAB_CONTENT_ID.SETTINGS
    default:
      return EXCHANGE_TAB_CONTENT_ID.POTENTIAL
  }
}

const extractColorByWhitelistStatus = (status) => {
  switch (status) {
    case TOKEN_WHITELIST_STATUS.ACTIVE:
      return 'success'
    case TOKEN_WHITELIST_STATUS.PAUSED:
      return 'orange'
    default:
      return 'warning'
  }
}

const checkOtherExistsInReasons = (reasons) => {
  return reasons?.some((reason) => reason.value === EXCHANGE_FEEDBACK_OTHER)
}

const showRegionsLabel = (countries, t) => {
  if (countries?.length > 0) {
    const regionsByCountries = extractAllRegionsByCountries(countries || [])
    return isAllCountriesExists(countries) || regionsByCountries?.length >= 6
      ? t('common.worldwide')
      : formatArrayToString(
          sortLabelsByASC(regionsByCountries?.map(({ label }) => label))
        )
  }
  return ''
}

const buildConnectExchangePartnerBody = ({ fromTokenId, toTokenId }) => {
  return {
    token_exchange_whitelist: {
      from_token_id: parseInt(fromTokenId),
      to_token_id: parseInt(toTokenId)
    }
  }
}

const buildBuyNftParams = ({ nft: { id }, supply }, tokenId) => {
  return {
    nft_purchase: {
      nft_token_id: id,
      supply: parseInt(supply),
      buyer_token_id_to_debit: tokenId
    }
  }
}

const buildPotentialPartnersData = (
  { exchangePartners, currentTokenId },
  state
) => {
  const result = {
    ranking: 0,
    potential: {
      ...state.potential,
      data: [],
      filteredData: []
    }
  }
  if (exchangePartners && exchangePartners.length > 0) {
    exchangePartners.forEach((dataSet) => {
      if (dataSet.id === currentTokenId) {
        result.ranking = dataSet.rank
      } else {
        if (
          !isExistsElementFromList(
            EXCHANGE_PROVIDERS_BRAND_IDS_IGNORE,
            dataSet?.brand?.id
          )
        ) {
          result.potential.data.push(dataSet)
        }
      }
    })
  }
  result.potential.filteredData = sortPotentialPartnersData(
    result.potential.data,
    state
  )
  return result
}

const buildConnectionPartnersData = (
  { exchangePartners, ownTokenIds },
  state
) => {
  const result = {
    active: {
      ...state.active,
      data: [],
      filteredData: []
    },
    requests: {
      ...state.requests,
      data: [],
      filteredData: []
    },
    rejectedPartners: {}
  }
  if (exchangePartners && exchangePartners.length > 0) {
    if (state.rewardType !== QB_DEFAULT_TYPE_VALUE) {
      exchangePartners = exchangePartners.filter(({ type }) =>
        isExistsElementFromList(buildTokenTypes(state.rewardType), type)
      )
    }
    exchangePartners.forEach((dataSet) => {
      const { fromTokenId, toTokenId, status, updatedAt } =
        dataSet.tokenExchangeWhitelist
      if (
        isTokenActive(dataSet.status) ||
        (isTokenPending(dataSet.status) && dataSet.type === TOKEN_TYPE.SHOPIFY)
      ) {
        const isRequestsFrom = isExistsElementFromList(ownTokenIds, fromTokenId)
        const isRequestsTo = isExistsElementFromList(ownTokenIds, toTokenId)
        // Ignore active partnerships count for miles_and_more brandId/any other brand which meant for extension activation
        if (!(isRequestsTo && isBrandMM(dataSet.brand.id))) {
          if (status === TOKEN_WHITELIST_STATUS.PENDING) {
            if (isRequestsFrom || isRequestsTo) {
              result.requests.data.push({
                ...dataSet,
                isFrom: isRequestsFrom,
                insertedAt: updatedAt
              })
            }
          }

          if (
            isExistsElementFromList(
              [
                TOKEN_WHITELIST_STATUS.ACTIVE,
                TOKEN_WHITELIST_STATUS.SETUP,
                TOKEN_WHITELIST_STATUS.PAUSED
              ],
              status
            )
          ) {
            const activeDataSet = {
              ...dataSet,
              isActiveExchange: true
            }
            if (isRequestsFrom || isRequestsTo) {
              result.active.data.push({
                ...activeDataSet,
                isFrom: isRequestsFrom,
                ...(isRequestsFrom && {
                  sellerMaxValue:
                    dataSet?.tokenExchangeWhitelist?.toTokenMaxUsd || 0
                }),
                insertedAt: updatedAt
              })
            }
          } else if (
            isExistsElementFromList(
              [
                TOKEN_WHITELIST_STATUS.REJECTED,
                TOKEN_WHITELIST_STATUS.CANCELLED
              ],
              status
            ) &&
            isRequestsFrom
          ) {
            result.rejectedPartners[toTokenId] = true
          }
        }
      }
    })
  }
  return sortAndFilterExchangePartnersData(result, state)
}

const buildExchangePartnersStats = ({ exchangePartners, ownTokenIds }) => {
  const exchangeStats = {
    incomingRequests: [],
    activeCount: 0,
    isCryptoFiatConnectionExists: false,
    isMilesAndMoreConnectionExists: false,
    milesAndMoreExchangePair: null
  }
  exchangePartners.forEach((dataSet) => {
    if (isTokenActive(dataSet.status)) {
      const { fromTokenId, toTokenId, status } = dataSet.tokenExchangeWhitelist
      const isActiveTo = isExistsElementFromList(ownTokenIds, toTokenId)
      const isMilesAndMoreBrandId = isBrandMM(dataSet.brand.id)
      if (status === TOKEN_WHITELIST_STATUS.PENDING) {
        if (isTokenTypeCryptoFiat(dataSet.type)) {
          exchangeStats.isCryptoFiatConnectionExists = true
        }
        if (isActiveTo) {
          // Ignore active partnerships count for miles_and_more brandId which meant for extension activation
          if (isMilesAndMoreBrandId) {
            exchangeStats.milesAndMoreExchangePair = dataSet
          } else {
            exchangeStats.incomingRequests.push(dataSet)
          }
        } else {
          if (isMilesAndMoreBrandId) {
            exchangeStats.isMilesAndMoreConnectionExists = true
          }
        }
      }
      const isActiveFrom = isExistsElementFromList(ownTokenIds, fromTokenId)
      if (isExchangeWhitelistActive(status) && (isActiveFrom || isActiveTo)) {
        let ignoreActiveCount = false
        if (isTokenTypeCryptoFiat(dataSet.type)) {
          exchangeStats.isCryptoFiatConnectionExists = true
        }
        if (isMilesAndMoreBrandId) {
          if (isActiveTo) {
            // Ignore active partnerships count for miles_and_more brandId which meant for extension activation
            ignoreActiveCount = true
            exchangeStats.milesAndMoreExchangePair = dataSet
          } else {
            exchangeStats.isMilesAndMoreConnectionExists = true
          }
        }
        if (!ignoreActiveCount) {
          exchangeStats.activeCount += 1
        }
      }
    }
  })
  return exchangeStats
}

const sortPotentialPartnersData = (filteredData, state) => {
  filteredData = reduceExchangePartners(filteredData)
  switch (state.sorting) {
    case SORTING_OPTIONS.ALPHABETICAL:
      return sortRecordsByNameASC(filteredData)

    case SORTING_OPTIONS.RECENTLY_ADDED:
      return sortDataByDateDESC(filteredData)

    default:
      return sortPartnersByRankASC(filteredData)
  }
}

const sortAndFilterExchangePartnersData = (result, state) => {
  const tabs = [EXCHANGE_PARTNER_TYPES.ACTIVE, EXCHANGE_PARTNER_TYPES.REQUESTS]
  tabs.forEach((tab) => {
    const data = result[tab].data
    if (data && data.length > 0) {
      let filteredData = data
      if (tab === EXCHANGE_PARTNER_TYPES.ACTIVE) {
        filteredData =
          state.connectionType === QB_DEFAULT_TYPE_VALUE
            ? data
            : filterPartnersByFromOrTo(
                data,
                state.connectionType ===
                  EXCHANGE_CONNECTION_TYPES.YOU_CONNECTED_WITH
              )
      }

      if (tab === EXCHANGE_PARTNER_TYPES.REQUESTS) {
        filteredData =
          state.requestType === QB_DEFAULT_TYPE_VALUE
            ? data
            : filterPartnersByFromOrTo(
                data,
                state.requestType === EXCHANGE_REQUEST_TYPES.REQUESTS_YOU_SENT
              )
      }
      result[tab].filteredData = sortPotentialPartnersData(filteredData, state)
    }
  })
  return result
}

const sortRecordsByNameASC = (records) => {
  if (records && records.length > 0) {
    return records.sort((a, b) => a.name.localeCompare(b.name))
  }
  return []
}

const sortPartnersByRankASC = (partners) => {
  if (partners && partners.length > 0) {
    return partners.sort((a, b) => a.rank - b.rank)
  }
  return []
}

const getFromToLabel = (isFrom) => {
  return isFrom ? 'from' : 'to'
}

const isExchangeWhitelistActive = (status) => {
  return status === TOKEN_WHITELIST_STATUS.ACTIVE
}

const buildExchangeUrl = (activeTab) => {
  return `${MARKETPLACE_URL}/${activeTab}`
}

const buildExchangeActiveUrl = () => {
  return buildExchangeUrl(EXCHANGE_PARTNER_TYPES.ACTIVE)
}

const buildExchangePotentialUrl = () => {
  return buildExchangeUrl(EXCHANGE_PARTNER_TYPES.POTENTIAL)
}

const isExchangeActionMaxLimitChange = (action) => {
  return action === EXCHANGE_CONNECTION_ACTION.CHANGE_MAX_LIMIT
}

const getMaxThresholdValue = (partner, dashboardConfigs) => {
  const qiibeeMaxExchange = dashboardConfigs.qiibeeMaxExchangeAmount
  if (partner?.isFrom === false) {
    return formatCurrencyValue(qiibeeMaxExchange, 4)
  }
  return formatCurrencyValue(
    partner?.sellerMaxValue ? partner?.sellerMaxValue : qiibeeMaxExchange,
    4
  )
}

const buildDataForMaxValueUpdate = (isFrom, maxValueLimit) => {
  if (isFrom) {
    return { from_token_max_usd: maxValueLimit }
  }
  return { to_token_max_usd: maxValueLimit }
}

export const isExchangeFilterRefreshData = (field) => {
  return [
    EXCHANGE_PARTNER_DISPATCH.REWARD_TYPE,
    EXCHANGE_PARTNER_DISPATCH.REGION,
    EXCHANGE_PARTNER_DISPATCH.COUNTRY,
    EXCHANGE_PARTNER_DISPATCH.MEMBERS
  ].includes(field)
}

export const buildConnectionTypesOptions = (t) => {
  return buildDropdownList(
    EXCHANGE_CONNECTION_TYPES,
    'exchange.connection-types-options',
    t
  )
}

export const buildRequestTypesOptions = (t) => {
  return buildDropdownList(
    EXCHANGE_REQUEST_TYPES,
    'exchange.request-types-options',
    t
  )
}

const buildExchangeRewardTypeOptions = (t) => {
  return buildDropdownList(
    EXCHANGE_PARTNER_FILTER_OPTIONS,
    'exchange.filter-options',
    t
  )
}

const buildSortingOptions = (t) => {
  return buildDropdownList(SORTING_OPTIONS, 'exchange.sorting-options', t)
}

const checkToShowGuidanceForExchange = ({ activeCount, incomingRequests }) => {
  if (activeCount <= 0 || incomingRequests.length > 0) {
    return true
  }
  return false
}

const buildTokenTypes = (rewardType) => {
  switch (rewardType) {
    case EXCHANGE_PARTNER_FILTER_OPTIONS.MILES:
    case EXCHANGE_PARTNER_FILTER_OPTIONS.CRYPTO:
    case EXCHANGE_PARTNER_FILTER_OPTIONS.FIAT:
    case EXCHANGE_PARTNER_FILTER_OPTIONS.GIFT_CARD:
    case EXCHANGE_PARTNER_FILTER_OPTIONS.DONATION:
    case EXCHANGE_PARTNER_FILTER_OPTIONS.MERCHANDISE:
      return [rewardType]
    case EXCHANGE_PARTNER_FILTER_OPTIONS.POINTS:
      return [TOKEN_TYPE.ERC_20, TOKEN_TYPE.EXTERNAL]
    default:
      return [
        TOKEN_TYPE.ERC_20,
        TOKEN_TYPE.EXTERNAL,
        TOKEN_TYPE.MILES,
        TOKEN_TYPE.CRYPTO,
        TOKEN_TYPE.FIAT,
        TOKEN_TYPE.GIFT_CARD,
        TOKEN_TYPE.DONATION,
        TOKEN_TYPE.MERCHANDISE
      ]
  }
}

const buildExchangeTypeLabel = (type) => {
  if (isExistsElementFromList([TOKEN_TYPE.ERC_20, TOKEN_TYPE.EXTERNAL], type)) {
    return EXCHANGE_PARTNER_FILTER_OPTIONS.POINTS
  }
  return type
}

const buildWhitelistTokenTypesExchange = ({ tokenId, rewardType }) => {
  return {
    token_id: tokenId,
    types: buildTokenTypes(rewardType)
  }
}

const buildPartnersQueryParams = (state) => {
  const { rewardType, region, country, members } = extractPartnersProfile(state)
  let countries = []
  if (country !== QB_DEFAULT_COUNTRY_VALUE) {
    countries.push(country)
  } else {
    countries = fetchCountriesByRegion(null, [region], false).map(
      (country) => country?.value
    )
  }
  countries.push(QB_DEFAULT_COUNTRY_VALUE)
  const tokenId = extractTokenId(state)
  return {
    types: buildTokenTypes(rewardType),
    ...(!(
      region === QB_DEFAULT_REGION_VALUE && country === QB_DEFAULT_COUNTRY_VALUE
    ) && { countries }),
    ...(tokenId && { token_id: tokenId }),
    ...(members !== QB_DEFAULT_MEMBERS_VALUE && buildMembersParams(members))
  }
}

const buildMembersParams = (members) => {
  const membersCount = members?.split('_')
  return {
    members_count_lower: membersCount[0],
    members_count_higher: membersCount[1]
  }
}

const isBrandsYouLoveSelected = (selectedTab) => {
  return selectedTab === EXCHANGE_PARTNER_TYPES.BRANDS_YOU_LOVE
}

const sortExchangesByNameASC = (exchanges) => {
  return exchanges?.sort((a, b) =>
    a?.brand?.name?.localeCompare(b?.brand?.name)
  )
}

const selectExchangePartner = (data, activeStep) => {
  const isCryptoActionsAvailable = data?.additionalList?.length
  const partner = isCryptoActionsAvailable
    ? sortExchangesByNameASC([data, ...data.additionalList])[activeStep]
    : data
  return {
    partner,
    isCryptoActionsAvailable
  }
}

const fromExchangeDetailsTabIdToType = (tabId) => {
  switch (tabId) {
    case EXCHANGE_DETAILS_TAB_CONTENT_ID.REWARD_CONDITIONS:
      return EXCHANGE_DETAILS_TAB_TYPE.REWARD_CONDITIONS
    default:
      return EXCHANGE_DETAILS_TAB_TYPE.REWARD_DETAILS
  }
}

const fromExchangeDetailsTypeToTabId = (selectedTab) => {
  switch (selectedTab) {
    case EXCHANGE_DETAILS_TAB_TYPE.REWARD_CONDITIONS:
      return EXCHANGE_DETAILS_TAB_CONTENT_ID.REWARD_CONDITIONS
    default:
      return EXCHANGE_DETAILS_TAB_CONTENT_ID.REWARD_DETAILS
  }
}

const isExchangeWhitelistActiveOrPending = (status) => {
  return isExistsElementFromList(
    [TOKEN_WHITELIST_STATUS.ACTIVE, TOKEN_WHITELIST_STATUS.PENDING],
    status
  )
}

const getMinExchangeUSDValue = (
  { toTokenMaxUsd, fromTokenMaxUsd },
  qiibeeMaxExchangeAmount
) => {
  if (!(toTokenMaxUsd && fromTokenMaxUsd)) {
    return qiibeeMaxExchangeAmount
  }
  const toMaxUSD = parseFloat(toTokenMaxUsd)
  const fromMaxUSD = parseFloat(fromTokenMaxUsd)
  return toMaxUSD < fromMaxUSD ? toMaxUSD : fromMaxUSD
}

export {
  formatPartners,
  filterPartnersByType,
  fromExchangeTabIdToType,
  fromExchangeTypeToTabId,
  filterPartnersByFromOrTo,
  extractColorByWhitelistStatus,
  checkOtherExistsInReasons,
  showRegionsLabel,
  buildConnectExchangePartnerBody,
  buildBuyNftParams,
  buildPotentialPartnersData,
  buildConnectionPartnersData,
  sortRecordsByNameASC,
  sortPartnersByRankASC,
  getFromToLabel,
  isExchangeWhitelistActive,
  buildExchangeUrl,
  buildExchangeActiveUrl,
  buildExchangePotentialUrl,
  isExchangeActionMaxLimitChange,
  getMaxThresholdValue,
  buildDataForMaxValueUpdate,
  buildExchangeRewardTypeOptions,
  buildSortingOptions,
  checkToShowGuidanceForExchange,
  buildWhitelistTokenTypesExchange,
  buildExchangePartnersStats,
  buildPartnersQueryParams,
  sortPotentialPartnersData,
  isBrandsYouLoveSelected,
  buildExchangeTypeLabel,
  buildMembersParams,
  reduceExchangePartners,
  selectExchangePartner,
  fromExchangeDetailsTabIdToType,
  fromExchangeDetailsTypeToTabId,
  isExchangeWhitelistActiveOrPending,
  getMinExchangeUSDValue
}
