import { NFTS_PROFILE_ACTIONS } from '../../../constants/actions'
import { mergeToNewObject } from '../../../util/object.helpers'
import { NUMBER_OF_RECORDS_PER_PAGE } from '../../../constants/app'

export const nftUsersProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case NFTS_PROFILE_ACTIONS.ON_GET_NFT_USERS:
      return mergeToNewObject(state, {
        isProcessing: true,
        users: [],
        pagination: {
          ...state.pagination,
          ...payload
        }
      })

    case NFTS_PROFILE_ACTIONS.ON_GET_NFT_USERS_SUCCESS:
      return mergeToNewObject(state, {
        isProcessing: false,
        users: payload.nftDetails,
        pagination: {
          ...state.pagination,
          totalTxCount: payload.totalCount
        }
      })

    case NFTS_PROFILE_ACTIONS.ON_GET_NFT_USERS_FAILED:
      return mergeToNewObject(state, {
        isProcessing: false
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  users: [],

  pagination: {
    recordsPerPage: NUMBER_OF_RECORDS_PER_PAGE,
    pageNumber: 0,
    totalTxCount: 0
  },

  isProcessing: false
}
