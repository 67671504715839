import React, { memo } from 'react'
import { Box } from '@mui/material'
import { faMusic } from '@fortawesome/pro-light-svg-icons'

import { CSS_FLEX_CENTERED, CSS_FLEX_COLUMN } from '../../styles/common'
import { NFT_MEDIA_TYPES } from '../../constants/nfts'
import FaIcon from '../shared/FaIcon/FaIcon'

const NftDetailsMedia = ({ media, coverImage, sx }) => {
  const commonSx = {
    ...sx,
    ...CSS_FLEX_CENTERED,
    position: 'relative',
    width: '100%',
    '&:after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%'
    }
  }

  switch (media?.type) {
    case NFT_MEDIA_TYPES.IMAGE:
      return (
        <Box
          sx={{
            backgroundImage: `url(${media?.url})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            ...commonSx
          }}
        />
      )
    case NFT_MEDIA_TYPES.VIDEO:
      return (
        <Box
          sx={{
            ...commonSx,
            background: 'black'
          }}
        >
          <video width='100%' controls autoPlay muted controlsList='nodownload'>
            <source src={media.url} />
          </video>
        </Box>
      )
    case NFT_MEDIA_TYPES.AUDIO:
      return (
        <Box
          sx={{
            backgroundImage: ({ palette: { primary, secondary } }) =>
              `linear-gradient(145deg, ${primary.main} , ${secondary.main})`,
            ...commonSx
          }}
        >
          <Box
            sx={{
              ...CSS_FLEX_COLUMN,
              px: '12px',
              pt: '12px',
              width: '100%',
              gap: '40px'
            }}
            color='white'
            fontSize='70px'
          >
            <FaIcon icon={faMusic} size='lg' />
            <audio controls style={{ width: '100%' }} controlsList='nodownload'>
              <source src={media.url} />
            </audio>
          </Box>
        </Box>
      )
    default:
      return coverImage ? (
        <Box
          sx={{
            backgroundImage: `url(${coverImage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            ...commonSx
          }}
        />
      ) : (
        <Box
          sx={{
            backgroundImage: ({ palette: { primary, secondary } }) =>
              `linear-gradient(145deg, ${primary.main} , ${secondary.main})`,
            ...commonSx
          }}
        />
      )
  }
}

export default memo(NftDetailsMedia)
