import React, { memo } from 'react'
import { Box } from '@mui/material'
import {
  faCircleInfo,
  faCode,
  faPuzzle,
  faSignsPost
} from '@fortawesome/pro-light-svg-icons'

import {
  API_URL,
  HELP_CENTER_EXTERNAL_URL
} from '../../../constants/externalResources'
import { buildLinkParams } from '../../../util/navigation.helpers'
import { REQUEST_FEATURE_URL } from '../../../constants/navigation'
import CustomButton from '../../shared/buttons/CustomButton'
import FaIcon from '../../shared/FaIcon/FaIcon'
import { COLOR_PALETTE } from '../../../constants/theme'
import { isMobile } from '../../../util/media.helpers'

const HelpDropdownMenu = ({ t, onClick }) => {
  const links = [
    {
      href: HELP_CENTER_EXTERNAL_URL,
      title: 'sidebar.help-center',
      isOutside: true,
      icon: faCircleInfo
    },
    ...(!isMobile()
      ? [
          {
            title: 'header.take-a-tour',
            id: 'sttw-start',
            icon: faSignsPost
          }
        ]
      : []),
    {
      href: API_URL,
      title: 'developers.qiibee-documentation',
      isOutside: true,
      icon: faCode
    },
    {
      href: REQUEST_FEATURE_URL,
      title: 'sidebar.request-feature',
      icon: faPuzzle
    }
  ]
  return (
    <Box>
      {links.map(({ title, icon, id, ...restProps }, index) => (
        <CustomButton
          key={index}
          onClick={onClick}
          id={id}
          noPadding
          sx={{ mb: 0.5, pl: 2, justifyContent: 'start' }}
          color={COLOR_PALETTE.PRIMARY}
          startIcon={<FaIcon icon={icon} width={18} />}
          label={t(title)}
          variant='text'
          {...buildLinkParams(restProps)}
        />
      ))}
    </Box>
  )
}

export default memo(HelpDropdownMenu)
