import React, { memo } from 'react'
import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

import CustomFormLabel from '../forms/customElements/CustomFormLabel'
import { buildCustomHelperTextStyles } from '../../../util/style.helpers'

const CustomAutocomplete = ({
  id,
  onChange,
  label,
  bottomLabel,
  errorMessage,
  successMsg,
  size,
  ...restProps
}) => {
  const handleOnChange = (event, selectedOption) => {
    onChange(selectedOption)
  }
  const error = Boolean(errorMessage)
  return (
    <>
      <CustomFormLabel htmlFor={id}>{label}</CustomFormLabel>
      <Autocomplete
        id={id}
        fullWidth
        {...restProps}
        autoHighlight
        getOptionLabel={(option) => option.label}
        onChange={handleOnChange}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password'
            }}
            error={error}
            size={size}
            placeholder={label}
            helperText={errorMessage || successMsg || bottomLabel}
            FormHelperTextProps={buildCustomHelperTextStyles(successMsg)}
          />
        )}
      />
    </>
  )
}

CustomAutocomplete.defaultProps = {
  multiple: false,
  size: 'large'
}

export default memo(CustomAutocomplete)
