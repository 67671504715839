import React, { memo } from 'react'
import { Alert, useTheme } from '@mui/material'

import { ALERT_SEVERITY_TYPE } from '../../../constants/alert'
import FaIcon from '../FaIcon/FaIcon'

const CustomAlert = ({
  severity,
  children,
  variant,
  sx,
  icon,
  ...restProps
}) => {
  const { palette } = useTheme()
  const color = palette?.[severity].main
  const isVariantOutlined = variant === 'outlined'
  return (
    <Alert
      severity={severity}
      variant={variant}
      icon={icon && <FaIcon icon={icon} color={color} />}
      sx={{
        mt: 0,
        m: 2,
        ...sx,
        borderColor: color,
        ...(severity === ALERT_SEVERITY_TYPE.WARNING && {
          color: palette.darkGrey.main
        }),
        ...(isVariantOutlined && { '& .MuiSvgIcon-root': { color } })
      }}
      {...restProps}
    >
      {children}
    </Alert>
  )
}

CustomAlert.defaultProps = {
  severity: 'info',
  variant: 'outlined'
}

export default memo(CustomAlert)
