import React, { memo } from 'react'
import { Grid, Box } from '@mui/material'

import qbLadHero from '../../../assets/images/backgrounds/qiibee-LAD-hero.jpg'
import Text from '../../shared/text/Text'
import CustomButton from '../../shared/buttons/CustomButton'
import FaIcon from '../../shared/FaIcon/FaIcon'
import { faDesktop } from '@fortawesome/pro-light-svg-icons'
import { CSS_FLEX_COLUMN } from '../../../styles/common'

const UnauthorizedLayout = ({ title, children, t, onWelcomePopupOpen }) => {
  return (
    <Grid
      container
      spacing={0}
      sx={{ height: '100vh', justifyContent: 'center' }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        lg={6}
        sx={{
          backgroundImage: `url(${qbLadHero})`,
          backgroundSize: 'cover',
          minHeight: 200,
          backgroundPosition: 'center'
        }}
      >
        <Box
          sx={{
            ...CSS_FLEX_COLUMN,
            justifyContent: 'flex-end',
            height: '100%',
            pb: 5
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <CustomButton
              label={t('welcome.what-is-the-lad')}
              onClick={onWelcomePopupOpen}
              fullWidth={false}
              color='lightGrey'
              startIcon={<FaIcon width={15} icon={faDesktop} />}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} lg={6} display='flex' alignItems='center'>
        <Grid container spacing={0} display='flex' justifyContent='center'>
          <Grid item xs={12} lg={10} xl={8}>
            <Box
              sx={{
                p: 4
              }}
            >
              {title && <Text label={title} fontWeight={700} variant='h2' />}
              {children}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(UnauthorizedLayout)
