import React, { memo } from 'react'
import { Avatar, Grid, Box } from '@mui/material'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'

import Text from '../text/Text'
import qbxLogo from '../../../assets/images/logos/qbx.png'
import FaIcon from '../FaIcon/FaIcon'
import BaseCard from '../BaseCard/BaseCard'
import { CONTAINER_MIN_HEIGHT } from '../../../styles/common'

const Information = ({
  title,
  description,
  children,
  size = 4,
  hideLogo,
  sx
}) => {
  return (
    <Grid container spacing={0} display='flex' justifyContent='center'>
      <Grid item lg={size} md={size + 2} sm={size * 2} xs={12} padding={2}>
        <Box
          display='flex'
          alignItems='center'
          sx={{ ...CONTAINER_MIN_HEIGHT, width: '100%', ...sx }}
          justifyContent='center'
        >
          <BaseCard sx={{ p: 3, textAlign: 'center', width: '100%' }}>
            {hideLogo ? (
              <Box
                sx={{
                  color: 'info.main',
                  margin: 'auto'
                }}
              >
                <FaIcon icon={faInfoCircle} size='3x' />
              </Box>
            ) : (
              <Avatar
                src={qbxLogo}
                sx={{ width: 60, height: 60, margin: 'auto' }}
              />
            )}
            <Text variant='h2' sx={{ mt: 2 }} label={title} fontWeight={500} />
            <Text variant='body2' label={description} sx={{ mt: 2, mb: 2 }} />
            {children}
          </BaseCard>
        </Box>
      </Grid>
    </Grid>
  )
}

export default memo(Information)
