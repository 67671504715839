import React, { memo } from 'react'

import CustomDialog from '../../shared/CustomDialog/CustomDialog'
import { QB_LABEL_TEXT } from '../../../constants/messages'
import { extractSurveyQuestions } from '../../../util/survey.helpers'
import i18n from '../../../i18n'
import SurveyFields from '../../shared/surveyFields/SurveyFields'

const AcceptExchangeTerms = ({
  t,
  exchangeTerms,
  isProcessing,
  dispatchAction,
  exchangeTermsProfile,
  submitLabel,
  ...restProps
}) => {
  const survey = extractSurveyQuestions(exchangeTerms, i18n.language)
  return (
    <CustomDialog
      title={survey?.title}
      description={survey?.description}
      open
      cancelProps={{ label: t(QB_LABEL_TEXT.CANCEL) }}
      submitProps={{
        label: t(submitLabel || 'common.connect'),
        disabled: isProcessing
      }}
      t={t}
      body={
        <SurveyFields
          t={t}
          surveyQuestions={survey?.questions}
          dispatchAction={dispatchAction}
          fieldValuesErrors={exchangeTermsProfile}
        />
      }
      {...restProps}
    />
  )
}

export default memo(AcceptExchangeTerms)
