import React, { memo } from 'react'
import { Box, Button } from '@mui/material'

import FaIcon from '../../shared/FaIcon/FaIcon'
import Text from '../../shared/text/Text'

const DropdownMenuItem = ({ title, description, icon, color }) => {
  return (
    <Box display='flex' alignItems='center'>
      <Button
        sx={{
          backgroundColor: (theme) => theme.palette.primary.light,
          color: (theme) => theme.palette.primary.main,
          boxShadow: 'none',
          minWidth: '50px',
          width: '45px',
          height: '40px',
          borderRadius: '10px'
        }}
      >
        <FaIcon icon={icon} size='lg' width={18} />
      </Button>
      <Box
        sx={{
          ml: 2
        }}
      >
        <Text
          variant='h5'
          sx={{
            lineHeight: '1.235'
          }}
          label={title}
          color={color}
        />

        {description && (
          <Text color='textSecondary' variant='h6' label={description} />
        )}
      </Box>
    </Box>
  )
}

export default memo(DropdownMenuItem)
