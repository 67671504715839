import React, { memo } from 'react'
import { faPhone } from '@fortawesome/pro-light-svg-icons'

import CustomButton from './CustomButton'
import FaIcon from '../FaIcon/FaIcon'
import { openExternalUrl } from '../../../util/app/app.helpers'
import { buildCalendarUrl } from '../../../util/externalResource.helpers'

const BookACall = ({ t, userId, ...restProps }) => {
  return (
    <CustomButton
      fullWidth={false}
      label={t('extensions.book-a-call')}
      startIcon={<FaIcon width={15} icon={faPhone} />}
      onClick={() => {
        openExternalUrl(buildCalendarUrl(userId))
      }}
      {...restProps}
    />
  )
}

export default memo(BookACall)
