import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import DashboardSetup from '../../components/setup/dashboardSetup/DashboardSetup'
import {
  changeDashboardSetupState,
  changeDashboardState
} from '../../store/actions/dashboard'
import {
  changeBrandState,
  createBrand,
  updateBrand
} from '../../store/actions/brand'
import {
  DASHBOARD_SETUP_DISPATCH,
  DASHBOARD_SETUP_STATE
} from '../../store/reducers/dashboard/dashboardSetup'
import { BRAND_DISPATCH } from '../../store/reducers/dashboard/brand'
import {
  buildBrandValidationRules,
  isBrandInfoChanged
} from '../../util/brand.helpers'
import {
  changeTokenState,
  createToken,
  updateToken,
  resetTokenState,
  getTokenById
} from '../../store/actions/token'
import { TOKEN_PROFILE_DISPATCH } from '../../store/reducers/tokens/token'
import {
  POINT_NAME_MAX_LENGTH,
  POINT_SYMBOL_MIN_LENGTH,
  POINT_SYMBOL_MAX_LENGTH
} from '../../constants/validation'
import { LOYALTY_POINT_URL } from '../../constants/navigation'
import { openBlankRedirectPage, scrollToTop } from '../../util/app/app.helpers'
import {
  checkValidations,
  minMaxRule,
  positiveNumberRule,
  requiredRule,
  validPointValueRule
} from '../../util/validation.helpers'
import { QB_TOKEN_STATUS } from '../../constants/token'
import { containsValues } from '../../util/object.helpers'
import { changeBillingState, startCheckout } from '../../store/actions/billing'
import {
  buildBrandSetupUrl,
  buildSetupUrl,
  fromSetupTabIdToUrl,
  isDashboardReady
} from '../../util/dashboard.helpers'
import { BILLING_PROFILE_DISPATCH } from '../../store/reducers/main/billing'
import {
  isCheckTokenCreated,
  isTokenInfoChanged
} from '../../util/token.helpers'
import { DASHBOARD_SETUP_URL_TYPES } from '../../constants/dashboard'
import {
  DASHBOARD_SETUP_PROCESS_STEP,
  DEFAULT_CONTAINER_STATE
} from '../../constants/containerStates'
import MirrorTransactionsOnChainPopup from '../../components/popups/setup/MirrorTransactionsOnChain'
import { checkoutSessionUrl } from '../../util/navigation.helpers'

const DashboardSetupContainer = ({
  brandProfile,
  changeBrandState,
  changeTokenState,
  dashboardSetupState,
  changeDashboardSetupState,
  changeDashboardState,
  createBrand,
  updateBrand,
  tokenProfile,
  createToken,
  updateToken,
  getTokenById,
  history,
  billingProfile,
  changeBillingState,
  mainProfile,
  startCheckout
}) => {
  const { t } = useTranslation()
  const isTokenCreated = isCheckTokenCreated(tokenProfile)

  const { selectedTab } = useParams()
  const [popupState, setPopupState] = useState(DEFAULT_CONTAINER_STATE.NONE)

  useEffect(() => {
    return () => {
      resetBillingSessionUrls()
    }
  }, [])

  useEffect(() => {
    changeDashboardState(DASHBOARD_SETUP_DISPATCH.IS_DASHBOARD_SETUP, false)
  }, [dashboardSetupState])

  useEffect(() => {
    if (mainProfile.isNewBrandCreation) {
      history.push(buildBrandSetupUrl())
    } else {
      if (
        isDashboardReady(dashboardSetupState) &&
        !tokenProfile.noRedirectOnFirstRefresh
      ) {
        history.push(LOYALTY_POINT_URL)
      } else {
        if (dashboardSetupState === DASHBOARD_SETUP_STATE.LOYALTY_POINT) {
          scrollToTop()
        }
        const newTab = fromSetupTabIdToUrl(dashboardSetupState)
        if (selectedTab !== newTab) {
          setSelectedTab(newTab)
        }
      }
    }
  }, [
    dashboardSetupState,
    mainProfile.isNewBrandCreation,
    tokenProfile.noRedirectOnFirstRefresh
  ])

  useEffect(() => {
    if (selectedTab === DASHBOARD_SETUP_URL_TYPES.BRAND) {
      resetBrandErrorSuccess()
    }

    if (selectedTab === DASHBOARD_SETUP_URL_TYPES.LOYALTY_POINT) {
      resetTokenErrorSuccess()
    }
  }, [selectedTab])

  const setSelectedTab = (selectedTab) => {
    if (selectedTab) {
      history.push(buildSetupUrl(selectedTab))
    }
  }

  const resetBillingSessionUrls = () => {
    changeBillingState(BILLING_PROFILE_DISPATCH.SIGNED_URL, '')
  }

  const onClickContinue = (e) => {
    e.preventDefault()
    switch (dashboardSetupState) {
      case DASHBOARD_SETUP_STATE.BRAND:
        const brandErrors = checkValidations(
          buildBrandValidationRules(brandProfile)
        )
        if (containsValues(brandErrors)) {
          changeBrandState(BRAND_DISPATCH.ERROR, brandErrors)
        } else {
          handleSetupChange()
          if (brandProfile.id) {
            if (isBrandInfoChanged(brandProfile)) {
              updateBrand(brandProfile.logo)
            } else {
              changeDashboardSetupState(DASHBOARD_SETUP_STATE.LOYALTY_POINT)
            }
          } else {
            createBrand(brandProfile.logo)
          }
        }
        break

      case DASHBOARD_SETUP_STATE.LOYALTY_POINT:
        const {
          nameTemp,
          symbolTemp,
          regionsTemp,
          countriesTemp,
          descriptionTemp,
          amountOfMembersTemp,
          pointValueTemp,
          binanceMirrorTemp
        } = tokenProfile
        const tokenErrors = checkValidations({
          nameTemp: {
            value: nameTemp,
            rules: [
              requiredRule,
              minMaxRule(POINT_SYMBOL_MIN_LENGTH, POINT_NAME_MAX_LENGTH)
            ]
          },
          symbolTemp: {
            value: symbolTemp,
            rules: [
              requiredRule,
              minMaxRule(POINT_SYMBOL_MIN_LENGTH, POINT_SYMBOL_MAX_LENGTH)
            ]
          },
          regionsTemp: { value: regionsTemp, rules: [requiredRule] },
          countriesTemp: { value: countriesTemp, rules: [requiredRule] },
          amountOfMembersTemp: {
            value: amountOfMembersTemp,
            rules: [requiredRule]
          },
          descriptionTemp: {
            value: descriptionTemp,
            rules: [requiredRule]
          },
          pointValueTemp: {
            value: pointValueTemp,
            rules: [requiredRule, validPointValueRule, positiveNumberRule]
          }
        })
        if (containsValues(tokenErrors)) {
          changeTokenState(TOKEN_PROFILE_DISPATCH.ERROR, tokenErrors)
        } else {
          if (binanceMirrorTemp) {
            setPopupState(
              DASHBOARD_SETUP_PROCESS_STEP.MIRROR_TRANSACTIONS_ON_CHAIN
            )
          } else {
            tokenSaveAction()
          }
        }
        break

      case DASHBOARD_SETUP_STATE.STATUS:
      case DASHBOARD_SETUP_STATE.READY:
        const { status, id } = tokenProfile
        switch (status) {
          case QB_TOKEN_STATUS.PENDING:
            getTokenById({ tokenId: id, shouldUpdateSubscriptions: true })
            break

          case QB_TOKEN_STATUS.ACTIVE:
          case QB_TOKEN_STATUS.PAUSED:
          case QB_TOKEN_STATUS.STOPPED:
            history.replace(LOYALTY_POINT_URL)
            break

          case QB_TOKEN_STATUS.REJECTED:
            changeDashboardSetupState(DASHBOARD_SETUP_STATE.LOYALTY_POINT)
            // resetTokenState()
            break

          default:
            break
        }
        break

      default:
        break
    }
  }

  const tokenSaveAction = () => {
    handleSetupChange()
    if (isTokenCreated) {
      if (isTokenInfoChanged(tokenProfile)) {
        updateToken()
      } else {
        changeDashboardSetupState(DASHBOARD_SETUP_STATE.PACKAGE)
      }
    } else {
      createToken()
    }
  }

  const onClickLeftButton = () => {
    switch (dashboardSetupState) {
      // back()
      case DASHBOARD_SETUP_STATE.LOYALTY_POINT:
        changeDashboardSetupState(DASHBOARD_SETUP_STATE.BRAND)
        break

      case DASHBOARD_SETUP_STATE.PACKAGE:
        changeDashboardSetupState(DASHBOARD_SETUP_STATE.LOYALTY_POINT)
        break

      default:
        break
    }
  }

  const handleOnSubmitPopup = () => {
    switch (popupState) {
      case DASHBOARD_SETUP_PROCESS_STEP.MIRROR_TRANSACTIONS_ON_CHAIN:
        tokenSaveAction()
        handleNoPopup()
        break

      default:
        handleNoPopup()
        break
    }
  }

  const handleOnCancelPopup = () => {
    switch (popupState) {
      default:
        handleNoPopup()
    }
  }

  const handleNoPopup = () => {
    setPopupState(DEFAULT_CONTAINER_STATE.NONE)
  }

  const renderPopup = () => {
    switch (popupState) {
      case DASHBOARD_SETUP_PROCESS_STEP.MIRROR_TRANSACTIONS_ON_CHAIN:
        return (
          <MirrorTransactionsOnChainPopup
            onClose={handleOnCancelPopup}
            onSubmit={handleOnSubmitPopup}
            isTokenCreated={isTokenCreated}
            t={t}
          />
        )

      default:
        break
    }
  }

  const handleSetupChange = () => {
    changeDashboardSetupState(DASHBOARD_SETUP_STATE.LOADING)
    changeDashboardState(DASHBOARD_SETUP_DISPATCH.IS_DASHBOARD_SETUP, true)
  }

  const resetBrandErrorSuccess = () => {
    changeBrandState(BRAND_DISPATCH.ERROR, {})
    changeBrandState(BRAND_DISPATCH.SUCCESS, {})
  }

  const resetTokenErrorSuccess = () => {
    changeTokenState(TOKEN_PROFILE_DISPATCH.ERROR, {})
    changeTokenState(TOKEN_PROFILE_DISPATCH.SUCCESS, {})
  }

  const handleSelectBillingPackage = (plan) => {
    startCheckout()
    openBlankRedirectPage(checkoutSessionUrl(tokenProfile.id, plan.priceId))
  }

  return (
    <>
      <DashboardSetup
        dashboardSetupState={dashboardSetupState}
        brandProfile={brandProfile}
        changeBrandState={changeBrandState}
        changeTokenState={changeTokenState}
        onClickContinue={onClickContinue}
        onClickLeftButton={onClickLeftButton}
        tokenProfile={tokenProfile}
        billingProfile={billingProfile}
        changeBillingState={changeBillingState}
        onSelectBillingPackage={handleSelectBillingPackage}
        isTokenCreated={isTokenCreated}
        t={t}
      />
      {renderPopup()}
    </>
  )
}

const mapStateToProps = ({ dashboardReducer, mainReducer, tokensReducer }) => {
  const { brandProfile, dashboardSetupProfile } = dashboardReducer
  const { billingProfile, mainProfile } = mainReducer
  const { tokenProfile } = tokensReducer
  return {
    brandProfile,
    dashboardSetupState: dashboardSetupProfile.dashboardSetupState,
    tokenProfile,
    billingProfile,
    mainProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeBrandState,
      changeTokenState,
      changeDashboardSetupState,
      createBrand,
      updateBrand,
      createToken,
      updateToken,
      resetTokenState,
      changeDashboardState,
      changeBillingState,
      getTokenById,
      startCheckout
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardSetupContainer)
