import React, { memo } from 'react'
import { Grid, Menu } from '@mui/material'
import { faAngleUp, faPlus } from '@fortawesome/pro-light-svg-icons'

import BrandAndTokenInfo from './BrandAndTokenInfo'
import FaIcon from '../../shared/FaIcon/FaIcon'
import CustomButton from '../../shared/buttons/CustomButton'
import SidebarDivider from './SidebarDivider'
import TokensButton from './TokensButton'
import Scrollbar from '../../shared/scrollbar/Scrollbar'
import { getTopHeight } from '../../../util/style.helpers'

const TokensList = ({
  onCreateNewPoint,
  onChangeToken,
  isExtendedList,
  onCloseTokens,
  isDarkMode,
  disableCreateNewBrand,
  brandTokens,
  t,
  ...restProps
}) => {
  return (
    <Menu
      id='tokens-menu'
      open={Boolean(isExtendedList)}
      anchorEl={isExtendedList}
      onClose={onCloseTokens}
      transitionDuration={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      sx={{
        '& .MuiMenu-paper': {
          width: '233px',
          right: 0,
          top: `${getTopHeight(76.6)}px !important`,
          backgroundColor: !isDarkMode && 'rgb(247 247 247)',
          borderRadius: '15px'
        },
        '& .MuiList-padding': {
          p: 2
        }
      }}
    >
      <Grid
        container
        spacing={0}
        alignItems='center'
        sx={{ m: 0, p: 0, width: '100%' }}
      >
        <TokensButton
          {...restProps}
          onClickButton={onCloseTokens}
          icon={
            <Grid item xs={1}>
              <FaIcon icon={faAngleUp} size='lg' />
            </Grid>
          }
          t={t}
        />
        {brandTokens.length > 0 && (
          <>
            <SidebarDivider />
            <Scrollbar
              options={{ suppressScrollX: true, useBothWheelAxes: false }}
              style={{ width: '100%', maxHeight: '400px' }}
            >
              {brandTokens.map((brandToken, index) => {
                const brand = brandToken?.brand
                const token = brandToken?.token
                return (
                  <CustomButton
                    label={
                      <BrandAndTokenInfo
                        brandName={brand?.name}
                        tokenName={token?.name}
                        tokenSymbol={token?.symbol}
                        logoUrl={brand?.brandConfig?.logoUrl}
                        t={t}
                      />
                    }
                    color='grey'
                    onClick={() => onChangeToken(brand?.id, token?.id)}
                    sx={{ p: 0 }}
                    key={index}
                  />
                )
              })}
            </Scrollbar>
          </>
        )}
        {!disableCreateNewBrand && (
          <>
            <SidebarDivider />
            <CustomButton
              label={t('common.create-new-point')}
              onClick={onCreateNewPoint}
              startIcon={<FaIcon icon={faPlus} />}
              variant='outlined'
              color='primary'
              noPadding
              sx={{ lineHeight: 2, m: '10px 0' }}
            />
          </>
        )}
      </Grid>
    </Menu>
  )
}

export default memo(TokensList)
