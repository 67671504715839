import { NFTS_PROFILE_ACTIONS } from '../../constants/actions'

const changeNftsState = (property, value) => ({
  type: NFTS_PROFILE_ACTIONS.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

const createNft = (media, exclusiveContentFile) => {
  return {
    type: NFTS_PROFILE_ACTIONS.ON_CREATE_NFT,
    payload: { media, exclusiveContentFile }
  }
}

const updateNft = (tokenId, data) => {
  return {
    type: NFTS_PROFILE_ACTIONS.ON_UPDATE_NFT,
    payload: { tokenId, data }
  }
}

const resetNftsState = () => ({
  type: NFTS_PROFILE_ACTIONS.RESET_STATE
})

const getNfts = () => ({
  type: NFTS_PROFILE_ACTIONS.ON_GET_NFTS
})

const getNftUsers = (payload) => ({
  type: NFTS_PROFILE_ACTIONS.ON_GET_NFT_USERS,
  payload
})

const redeemNftExclusiveContent = (userAuthId, tokenId, nftId, claimCount) => ({
  type: NFTS_PROFILE_ACTIONS.ON_REDEEM_EXCLUSIVE_CONTENT,
  payload: { userAuthId, tokenId, nftId, claimCount }
})

const updateSelectedNft = (nft) => ({
  type: NFTS_PROFILE_ACTIONS.ON_SELECT,
  payload: nft
})

const transferNft = (authId, tokenNftId) => ({
  type: NFTS_PROFILE_ACTIONS.ON_TRANSFER_NFT,
  payload: { authId, tokenNftId }
})

const resetNftsList = () => ({
  type: NFTS_PROFILE_ACTIONS.ON_RESET_LIST
})

export {
  changeNftsState,
  createNft,
  resetNftsState,
  getNfts,
  updateNft,
  getNftUsers,
  redeemNftExclusiveContent,
  updateSelectedNft,
  transferNft,
  resetNftsList
}
