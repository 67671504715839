import { map, mergeMap, catchError, tap, filter, mapTo } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'

import { logger } from '../../util/log.helpers'
import { BRAND_ACTIONS, FILE_UPLOAD_ACTIONS } from '../../constants/actions'
import apiService from '../../services/api'
import { wrapUserAccessToken } from '../../util/auth.helpers'
import {
  brandFieldsFormat,
  brandFieldsFormatForConfig,
  buildFileUrl
} from '../../util/brand.helpers'
import { commonParser } from '../../util/apiParser.helpers'
import {
  compareFileUploadType,
  extractBrandId,
  extractBrandLogo,
  extractBrandProfile,
  extractFileUploadType
} from '../../util/epics.helpers'
import { setBrandAndTokenIds } from '../../util/local.helpers'
import { FILE_UPLOAD_TYPE } from '../../constants/input'

const handleCreateBrandEpic = (action$, state$) =>
  action$.pipe(
    ofType(FILE_UPLOAD_ACTIONS.ON_UPLOAD_FILE_SUCCESS),
    filter(() => !extractBrandId(state$)),
    filter(() =>
      compareFileUploadType(
        extractFileUploadType(state$),
        FILE_UPLOAD_TYPE.CREATE_BRAND
      )
    ),
    mergeMap(({ payload }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .createBrand(
            extractCreateBrandData(state$, buildFileUrl(payload)),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => {
              const brand = commonParser(response.data)
              setBrandAndTokenIds({ brandId: brand.id, tokenId: null })
              return {
                type: BRAND_ACTIONS.ON_CREATE_BRAND_SUCCESS,
                payload: brand
              }
            }),
            catchError((error) =>
              of({
                type: BRAND_ACTIONS.ON_CREATE_BRAND_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

const handleUpdateBrandIfNoLogoChange = (action$, state$) =>
  action$.pipe(
    ofType(BRAND_ACTIONS.ON_INIT_UPDATE_BRAND),
    filter(() => !extractBrandLogo(state$)),
    mapTo({
      type: BRAND_ACTIONS.ON_UPDATE_BRAND
    })
  )

const handleUpdateBrandIfLogoChange = (action$, state$) =>
  action$.pipe(
    ofType(BRAND_ACTIONS.ON_INIT_UPDATE_BRAND),
    filter(() => extractBrandLogo(state$)),
    map(({ payload: { file } }) => ({
      type: FILE_UPLOAD_ACTIONS.ON_GET_PRESIGNED_URL,
      payload: { file, action: FILE_UPLOAD_TYPE.UPDATE_BRAND }
    }))
  )

const handleUpdateBrandEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      BRAND_ACTIONS.ON_UPDATE_BRAND,
      FILE_UPLOAD_ACTIONS.ON_UPLOAD_FILE_SUCCESS
    ),
    filter(() => extractBrandId(state$)),
    filter(() =>
      compareFileUploadType(
        extractFileUploadType(state$),
        FILE_UPLOAD_TYPE.UPDATE_BRAND
      )
    ),
    mergeMap(({ payload }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .updateBrand(
            extractUpdateBrandData(state$, buildFileUrl(payload)),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: BRAND_ACTIONS.ON_UPDATE_BRAND_SUCCESS,
              payload: {
                brand: commonParser(response.data)
              }
            })),
            catchError((error) =>
              of({
                type: BRAND_ACTIONS.ON_UPDATE_BRAND_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

const handleUpdateBrandWithoutFileEpic = (action$, state$) =>
  action$.pipe(
    ofType(BRAND_ACTIONS.ON_UPDATE_BRAND_CONFIG),
    map(({ payload }) => ({ payload, brandId: extractBrandId(state$) })),
    filter(({ brandId }) => brandId),
    mergeMap(({ payload, brandId }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .updateBrand(
            {
              id: brandId,
              data: brandFieldsFormatForConfig(
                extractBrandProfile(state$),
                payload
              )
            },
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: BRAND_ACTIONS.ON_UPDATE_BRAND_CONFIG_SUCCESS,
              payload: {
                brand: commonParser(response.data)
              }
            })),
            catchError((error) =>
              of({
                type: BRAND_ACTIONS.ON_UPDATE_BRAND_CONFIG_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

export const brandEpic = combineEpics(
  handleCreateBrandEpic,
  handleUpdateBrandEpic,
  handleUpdateBrandWithoutFileEpic,
  handleUpdateBrandIfNoLogoChange,
  handleUpdateBrandIfLogoChange
)

const extractCreateBrandData = (state, logoUrl) => {
  const dashboardReducer = state.value.dashboardReducer
  const brandData = brandFieldsFormat(dashboardReducer.brandProfile, logoUrl)
  return brandData
}

const extractUpdateBrandData = (state, logoUrl) => {
  const { id, ...data } = brandFieldsFormat(
    state.value.dashboardReducer.brandProfile,
    logoUrl
  )
  return { id, data }
}
