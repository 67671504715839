import { LOYALTY_EVENT_TYPES } from '../../constants/transactionTypes'
import { extractRewardMemberProfile, extractTokenInfo } from '../epics.helpers'
import { sortRecordsByNameASC } from '../exchange.helpers'
import { floorNumber } from '../number.helpers'

const buildParamsForRewardPointsBank = (state) => {
  const rewardMemberProfile = extractRewardMemberProfile(state)
  const { amount, account } = rewardMemberProfile
  return {
    code: {
      email: account,
      value: amount,
      language: 'en'
    }
  }
}

const buildParamsForRewardExchange = (state, payload) => {
  const rewardMemberProfile = extractRewardMemberProfile(state)
  const token = extractTokenInfo(state)
  const { amount, account, loyaltyPoint } = rewardMemberProfile
  const exchangeRate = token.offramp / payload?.tokenBOnramp
  return {
    transaction: {
      user_auth_id: '00000000-0000-0000-0000-000000000000',
      type: LOYALTY_EVENT_TYPES.EXCHANGE,
      amount: floorNumber(amount / exchangeRate),
      brandA_token_id: token.id,
      brandB_token_id: loyaltyPoint,
      brandB_membership_number: account
    }
  }
}

const formatExchangePartnersForReward = (partners) => {
  if (partners && partners.length) {
    return partners.reduce((acc, partner) => {
      if (partner.isFrom) {
        acc[partner.id] = partner
      }
      return acc
    }, {})
  }
  return {}
}

const buildOptionsForLoyaltyPoint = (data) => {
  return sortRecordsByNameASC(Object.values(data)).map((i) => ({
    value: i.id,
    label: i.name
  }))
}

export {
  buildParamsForRewardPointsBank,
  buildParamsForRewardExchange,
  formatExchangePartnersForReward,
  buildOptionsForLoyaltyPoint
}
