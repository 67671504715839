import React, { memo } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import htmlParser from 'html-react-parser'
import styled from '@emotion/styled'

import { isValidHtmlContent } from '../../../util/app/app.helpers'

const RichText = ({ content, elemRef }) => {
  return (
    <RichTextContainer ref={elemRef}>
      {isValidHtmlContent(content) ? (
        htmlParser(content)
      ) : (
        <MarkdownParser content={content} />
      )}
    </RichTextContainer>
  )
}

export default memo(RichText)

const MarkdownParser = ({ content }) => {
  return (
    <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
      {content}
    </ReactMarkdown>
  )
}

const RichTextContainer = styled.div`
  width: 100%;
  height: auto;
  img,
  div {
    width: 100% !important;
    max-width: 100% !important;
  }
  p {
    margin: 0;
    padding: 0;
  }
`
