import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'

import Loading from '../components/shared/Loading/Loading'
import { openExternalUrl } from '../util/app/app.helpers'
import { DYNAMIC_PAGE_REDIRECT_PATHS } from '../constants/externalResources'
import {
  generateViewBillingSession,
  generateCheckoutSession
} from '../store/actions/billing'

const PageRedirectContainer = ({
  match,
  signedUrl,
  history,
  generateViewBillingSession,
  generateCheckoutSession
}) => {
  useEffect(() => {
    if (signedUrl) {
      openExternalUrl(signedUrl, true)
    }
  }, [signedUrl])

  useEffect(() => {
    const path = match?.params?.path
    const queryParams = queryString.parse(history.location.search)
    if (queryParams.tid) {
      if (path === DYNAMIC_PAGE_REDIRECT_PATHS.BILLING_SESSION) {
        generateViewBillingSession(queryParams.tid)
      } else if (path === DYNAMIC_PAGE_REDIRECT_PATHS.CHECKOUT_SESSION) {
        generateCheckoutSession(queryParams.tid, queryParams.pid)
      }
    }
  }, [match.params])

  return <Loading />
}

const mapStateToProps = ({ mainReducer }) => {
  const { billingProfile } = mainReducer
  return {
    signedUrl: billingProfile.signedUrl
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      generateViewBillingSession,
      generateCheckoutSession
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageRedirectContainer)
