import { API_RESPONSE } from '../constants/api'
import { CUSTOM_BRAND_ID, S3_BASE_URL, STATIC_LOGO_URL } from '../constants/app'
import {
  DEFAULT_TOKEN_NAME,
  DEFAULT_TOKEN_SYMBOL
} from '../store/reducers/tokens/token'
import { deepEqualObjects } from './object.helpers'
import { buildErrorMessage } from './reducer.helpers'
import { toLower, trim } from './string.helpers'
import { isTokenActive } from './token.helpers'
import {
  formatValidator,
  requiredRule,
  validUrlRule
} from './validation.helpers'

const brandFieldsFormat = (brand, logoUrl) => {
  const { id, websiteTemp, ...restAttributes } = brand
  return {
    ...(id && { id }),
    brand: {
      brand_config: {
        website_url: trim(websiteTemp),
        ...(logoUrl && { logo_url: logoUrl })
      },
      ...formatNameLocationAttributes(restAttributes)
    }
  }
}

const brandFieldsFormatForConfig = (brand, data) => {
  const { websiteTemp, ...restAttributes } = brand
  return {
    brand: {
      brand_config: {
        website_url: trim(websiteTemp),
        ...data
      },
      ...formatNameLocationAttributes(restAttributes)
    }
  }
}

const hasBrandFieldsStateChanged = (oldData, newData) => {
  const {
    name,
    website,
    location: { street, city, country, zip }
  } = oldData
  return (
    name !== newData.nameTemp ||
    website !== newData.website ||
    street !== newData.address ||
    city !== newData.city ||
    country !== newData.country ||
    zip !== newData.zip
  )
}

const extractBrandData = (brand) => {
  const { id, name, brandConfig, locations } = brand
  const websiteUrl = brandConfig?.websiteUrl
  return {
    id,
    name,
    nameTemp: name,
    website: websiteUrl,
    websiteTemp: websiteUrl,
    logoUrl: brandConfig?.logoUrl,
    logo: '',
    brandConfig,
    ...(locations && locations.length > 0 && extractLocation(locations[0])),
    error: {},
    success: {}
  }
}

const extractLocation = ({
  id: locationId,
  street: address,
  zip,
  city,
  country
}) => {
  return {
    locationId,
    address,
    addressTemp: address,
    zip,
    zipTemp: zip,
    city,
    cityTemp: city,
    country,
    countryTemp: country
  }
}

const buildBrandValidationRules = ({ logoUrl, logo, ...restAttributes }) => {
  return {
    ...buildLocationValidationRules(restAttributes),
    ...(!logoUrl && { logoUrlTemp: { value: logo, rules: [requiredRule] } })
  }
}

const buildFileUrl = (s3Url) => {
  if (s3Url) {
    try {
      const fileName = new URL(s3Url).pathname.split('/').pop()
      return `${S3_BASE_URL}${fileName}`
    } catch (e) {
      console.error(e)
    }
  }
}

const formatNameLocationAttributes = ({
  locationId,
  nameTemp,
  zipTemp,
  countryTemp,
  cityTemp,
  addressTemp
}) => {
  return {
    name: trim(nameTemp),
    location: {
      ...(locationId && { id: locationId }),
      street: trim(addressTemp),
      zip: trim(zipTemp),
      country: trim(countryTemp),
      city: trim(cityTemp)
    }
  }
}

const extractErrorMessage = (payload) => {
  return {
    error: {
      nameTemp: buildErrorMessage(payload)
    }
  }
}

const extractCreateApiKeyErrors = ({ status }) => {
  return {
    nameTemp:
      status === API_RESPONSE.CONFLICT
        ? formatValidator('error.api-key-already-exists')
        : buildErrorMessage({ status })
  }
}

const buildLocationValidationRules = ({
  nameTemp,
  websiteTemp,
  addressTemp,
  zipTemp,
  cityTemp,
  countryTemp
}) => {
  return {
    nameTemp: { value: nameTemp, rules: [requiredRule] },
    websiteTemp: { value: websiteTemp, rules: [requiredRule, validUrlRule] },
    addressTemp: { value: addressTemp, rules: [requiredRule] },
    zipTemp: { value: zipTemp, rules: [requiredRule] },
    cityTemp: { value: cityTemp, rules: [requiredRule] },
    countryTemp: { value: countryTemp, rules: [requiredRule] }
  }
}

const isExistsAtLeastOneBrandToken = (brandTokens) => {
  if (brandTokens && brandTokens.length > 0) {
    let isExists = false
    for (let data of brandTokens) {
      if (data.tokens.length > 0) {
        isExists = true
        break
      }
    }
    return isExists
  }
  return false
}

const buildBrandAndTokensMenu = (brandTokens, currentBrandId) => {
  let menuData = {
    disableCreateNewBrand: false,
    brandTokens: []
  }
  const brandIds = Object.values(brandTokens)
  if (brandIds && brandIds.length > 0) {
    const tokenBrands = []
    brandIds.forEach((brandToken) => {
      const brand = brandToken?.brand
      const tokens = brandToken?.tokens
      const token = tokens[0]
      if (!isTokenActive(token?.status)) {
        menuData.disableCreateNewBrand = true
      }
      if (currentBrandId !== brand?.id) {
        tokenBrands.push({
          brand,
          token: {
            ...token,
            name: token?.name || DEFAULT_TOKEN_NAME,
            symbol: token?.symbol || DEFAULT_TOKEN_SYMBOL
          }
        })
      }
    })
    menuData.brandTokens = tokenBrands.sort((a, b) =>
      a?.token?.name?.localeCompare(b?.token?.name)
    )
  } else {
    menuData.disableCreateNewBrand = true
  }
  return menuData
}

const isBrandInfoChanged = ({
  website,
  address,
  zip,
  city,
  country,
  logo,
  websiteTemp,
  addressTemp,
  zipTemp,
  cityTemp,
  countryTemp
}) => {
  const logoTemp = ''
  return !deepEqualObjects(
    {
      websiteTemp,
      addressTemp,
      zipTemp,
      cityTemp,
      countryTemp,
      logoTemp
    },
    {
      websiteTemp: website,
      addressTemp: address,
      zipTemp: zip,
      cityTemp: city,
      countryTemp: country,
      logoTemp: logo
    }
  )
}

const getStaticLogoPath = (symbol) => {
  return symbol ? `${STATIC_LOGO_URL}${toLower(symbol)}.png` : ''
}

const isBrandMM = (brandId) => {
  return brandId === CUSTOM_BRAND_ID.MILES_AND_MORE
}

export {
  brandFieldsFormat,
  brandFieldsFormatForConfig,
  hasBrandFieldsStateChanged,
  extractBrandData,
  extractLocation,
  buildBrandValidationRules,
  buildFileUrl,
  extractErrorMessage,
  extractCreateApiKeyErrors,
  buildLocationValidationRules,
  formatNameLocationAttributes,
  isExistsAtLeastOneBrandToken,
  buildBrandAndTokensMenu,
  isBrandInfoChanged,
  getStaticLogoPath,
  isBrandMM
}
