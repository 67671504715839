import React, { memo } from 'react'
import { Grid } from '@mui/material'
import { Trans } from 'react-i18next'

import AccountForm from '../shared/AccountForm/AccountForm'
import Locked from '../shared/Locked/Locked'
import TokenDetails from '../shared/TokenDetails/TokenDetails'
import Dropdown from '../shared/inputs/Dropdown'
import { TOKEN_PROFILE_DISPATCH } from '../../store/reducers/tokens/token'
import { LABEL_TEXT } from '../../constants/messages'
import { buildIntegrationOptions } from '../../data/integration'
import { transWithArgs } from '../../util/validation.helpers'
import Text from '../shared/text/Text'
import Link from '../shared/link/Link'
import { INTEGRATION_TYPES_URL } from '../../constants/externalResources'

const TokenInfo = ({
  t,
  lockContent,
  tokenProfile,
  dispatchAction,
  onFormSubmit
}) => {
  const title = t('developers.token-info')
  const description = t('developers.token-info-description')
  const { type, typeTemp, error, success } = tokenProfile
  const tokenTypeChanged = type && typeTemp && type !== typeTemp
  return lockContent ? (
    <Locked
      title={title}
      description={description}
      t={t}
      lockedLabel='developers.token-info-locked-text'
    />
  ) : (
    <AccountForm
      title={title}
      description={description}
      fields={
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <TokenDetails tokenProfile={tokenProfile} t={t} />
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <Dropdown
                id={TOKEN_PROFILE_DISPATCH.TYPE_TEMP}
                label={t(LABEL_TEXT.TYPE_OF_INTEGRATION)}
                options={buildIntegrationOptions(t)}
                onChange={({ target }) =>
                  dispatchAction(TOKEN_PROFILE_DISPATCH.TYPE_TEMP, target.value)
                }
                value={typeTemp}
                errorMessage={transWithArgs(
                  t,
                  error[TOKEN_PROFILE_DISPATCH.TYPE_TEMP]
                )}
                successMsg={transWithArgs(
                  t,
                  success[TOKEN_PROFILE_DISPATCH.TYPE_TEMP]
                )}
                size='small'
              />
            </Grid>
          </Grid>
          <Text
            variant='body1'
            color='textprimary'
            label={
              <Trans i18nKey='integration.important-note-guide'>
                <Link
                  isOutside
                  label={t('exchange.this-guide')}
                  to={INTEGRATION_TYPES_URL}
                />
              </Trans>
            }
            sx={{ pt: 3 }}
          />
          <Text
            variant='body1'
            color='textprimary'
            label={t('integration.if-you-have-own-loyalty-program')}
            sx={{ pt: 3 }}
          />
        </Grid>
      }
      t={t}
      noActions={!tokenTypeChanged}
      submitLabel={t('integration.save-integration-type')}
      onFormSubmit={onFormSubmit}
    />
  )
}

export default memo(TokenInfo)
