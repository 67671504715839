import React, { memo } from 'react'
import { Grid } from '@mui/material'
import { faSquareDollar } from '@fortawesome/pro-light-svg-icons'

import CustomDialog from '../../shared/CustomDialog/CustomDialog'
import DisplayLabelWithPopover from '../../shared/DisplayLabel/DisplayLabelWithPopover'
import FaIcon from '../../shared/FaIcon/FaIcon'
import { exclusiveContentLabel } from '../../../util/nft.helpers'
import NftInfo from '../nfts/NftInfo'

const ExchangeNftDetails = ({
  onClose,
  onSubmit,
  nft,
  brandName,
  t,
  tokenProfile
}) => {
  return (
    <CustomDialog
      open
      onClose={onClose}
      onSubmit={onSubmit}
      submitProps={{
        startIcon: <FaIcon icon={faSquareDollar} />,
        label: t('exchange.buy-nft'),
        color: 'success'
      }}
      actionStyles={{ pt: 0, p: 4 }}
      paperProps={{ maxWidth: '650px' }}
      t={t}
      banner={
        <Grid container spacing={3} p={4}>
          <NftInfo
            t={t}
            nft={nft}
            brandName={brandName}
            userSupply={nft?.availableSupply}
            tokenProfile={tokenProfile}
            isSale
          />
          <Grid item xs={12} md={6}>
            <DisplayLabelWithPopover
              label={t('nft.exclusive-content')}
              helpText={t('nft.exclusive-content-mode-desc')}
              value={exclusiveContentLabel(nft?.metadata, t)}
            />
          </Grid>
        </Grid>
      }
    />
  )
}

export default memo(ExchangeNftDetails)
