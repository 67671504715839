import React, { memo } from 'react'
import { Box, Grid, TableRow } from '@mui/material'
import {
  faCircleDot,
  faSquareDollar,
  faSquareXmark,
  faUpload
} from '@fortawesome/pro-light-svg-icons'
import { Trans } from 'react-i18next'

import CustomDialog from '../../shared/CustomDialog/CustomDialog'
import { CSS_FLEX_COLUMN } from '../../../styles/common'
import DisplayLabelWithPopover from '../../shared/DisplayLabel/DisplayLabelWithPopover'
import DataTable from '../../shared/DataTable/DataTable'
import { buildTableHeaders } from '../../../util/csv.helpers'
import { NFT_TABLE_HEADER } from '../../../constants/table'
import Text from '../../shared/text/Text'
import FaIcon from '../../shared/FaIcon/FaIcon'
import TableCellData from '../../shared/DataTable/TableCellData'
import StatusLabel from '../../shared/StatusLabel/StatusLabel'
import SmallButton from '../../shared/buttons/SmallButton'
import {
  buildStatusLabelColor,
  isNftListedListed
} from '../../../util/nft.helpers'
import Loading from '../../shared/Loading/Loading'
import RichText from '../../shared/text/RichText'
import CustomButton from '../../shared/buttons/CustomButton'
import NftInfo from './NftInfo'
import Link from '../../shared/link/Link'
import { isTokenStatusActive } from '../../../util/token.helpers'

const NftDetails = ({
  onClose,
  onSubmit,
  nft,
  brandName,
  nftUsersProfile,
  onChangeNftUsersPage,
  onNftUsersPerPageChange,
  onRedeemNftExclusiveContent,
  onNftListAction,
  onChangeUserPermissions,
  tokenProfile,
  t
}) => {
  const { id, metadata } = nft
  const { exclusiveContent, links } = metadata
  let { users, pagination, isProcessing } = nftUsersProfile
  const { pageNumber, recordsPerPage, totalTxCount } = pagination
  const nftTableHeaders = buildTableHeaders(t, NFT_TABLE_HEADER)
  const isListed = isNftListedListed(nft)
  const userSupply = nft?.supply - totalTxCount
  return (
    <CustomDialog
      open
      onClose={onClose}
      onSubmit={onSubmit}
      submitProps={{
        startIcon: <FaIcon icon={faUpload} />,
        label: t('nft.transfer-nft'),
        disabled: !isTokenStatusActive(nft) || userSupply <= 0
      }}
      actionStyles={{ pt: 0, p: '20px' }}
      paperProps={{ maxWidth: '650px' }}
      t={t}
      additionalActions={
        <>
          <CustomButton
            label={`${t(`nft.${isListed ? 'delist' : 'list'}`)} ${t(
              'sidebar.nft'
            )}`}
            onClick={() => onNftListAction(nft, isListed)}
            variant='outlined'
            color={isListed ? 'error' : 'success'}
            startIcon={
              <FaIcon icon={isListed ? faSquareXmark : faSquareDollar} />
            }
            fullWidth={false}
            sx={{ mr: 1 }}
          />
          <CustomButton
            label={t('nft.user-permissions')}
            onClick={() => onChangeUserPermissions(nft)}
            variant='outlined'
            color='primary'
            fullWidth={false}
            sx={{ mr: 1 }}
          />
        </>
      }
      banner={
        <Grid container spacing={3} p={2}>
          <NftInfo
            t={t}
            nft={nft}
            brandName={brandName}
            userSupply={userSupply}
            tokenProfile={tokenProfile}
          />
          {(exclusiveContent || links?.exclusiveContentFile) && (
            <Grid item xs={12}>
              <DisplayLabelWithPopover
                label={t('nft.exclusive-content')}
                helpText={t('nft.exclusive-content-mode-desc')}
                value={
                  links?.exclusiveContentFile ? (
                    <Trans i18nKey='nft.view-dynamic-exclusive-content'>
                      <Link
                        isOutside
                        label={t('nft.click-to-view-uploaded-file')}
                        to={links?.exclusiveContentFile}
                        download
                      />
                    </Trans>
                  ) : (
                    <RichText content={exclusiveContent} />
                  )
                }
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Text
              label={t('nft.nfts-owned-by-members')}
              variant='caption'
              fontWeight={700}
            />
            {isProcessing ? (
              <Loading noFullHeight />
            ) : users.length ? (
              <DataTable
                headers={nftTableHeaders}
                data={users?.map((item, index) => (
                  <DataTableRow
                    key={index}
                    number={pageNumber * recordsPerPage + index + 1}
                    {...item}
                    t={t}
                    tokenId={id}
                    metadata={metadata}
                    onRedeemNftExclusiveContent={onRedeemNftExclusiveContent}
                  />
                ))}
                pagination={pagination}
                onChangePage={onChangeNftUsersPage}
                onRowsPerPageChange={onNftUsersPerPageChange}
                t={t}
                sx={{
                  minWidth: 0
                }}
                paginationProps={{
                  '&.MuiTablePagination-root, .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiSvgIcon-root':
                    {
                      fontSize: '0.6rem'
                    }
                }}
                size='small'
              />
            ) : (
              <Text
                label={t('nft.no-member-owns-this-nft')}
                variant='caption'
                component='div'
                sx={{ maxHeight: 96, overflow: 'auto', lineHeight: 1.3 }}
              />
            )}
          </Grid>
        </Grid>
      }
    />
  )
}

export default memo(NftDetails)

const DataTableRow = ({
  number,
  user,
  t,
  claimCount,
  metadata,
  tokenId,
  nftId,
  onRedeemNftExclusiveContent
}) => {
  const exclusiveContent =
    metadata?.exclusiveContent || metadata?.links?.exclusiveContentFile
  const { authId, firstName, secondName, email } = user
  const { label, color } = buildStatusLabelColor(exclusiveContent, claimCount)
  return (
    <TableRow>
      <TableCellData sx={{ pl: 0.5, pr: 0.5 }}>
        <Box fontSize={10}>{number}</Box>
      </TableCellData>
      <TableCellData sx={{ pl: 0.5, pr: 0.5 }}>
        <Box sx={{ pl: 1.5, ...CSS_FLEX_COLUMN }}>
          <Box fontSize={10} fontWeight={600}>
            {authId}
          </Box>
          <Box fontSize={10} sx={{ color: '', fontWeight: 500 }}>
            {firstName} {secondName} - {email}
          </Box>
        </Box>
      </TableCellData>
      <TableCellData noTag sx={{ pl: 0.5, pr: 0.5 }}>
        <StatusLabel
          label={t(`nft.excl-cnt-status-label.${label}`)}
          color={color}
          labelStyles={{
            variant: 'caption'
          }}
        />
      </TableCellData>
      <TableCellData sx={{ pl: 0.5, pr: 0.5 }}>
        {exclusiveContent && (
          <SmallButton
            onClick={() =>
              onRedeemNftExclusiveContent(authId, tokenId, nftId, claimCount)
            }
            label={t('nft.change-status')}
            startIcon={<FaIcon width={10} icon={faCircleDot} />}
            sx={{ fontSize: '10px' }}
            fullWidth={false}
          />
        )}
      </TableCellData>
    </TableRow>
  )
}
