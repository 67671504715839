import React, { memo } from 'react'

import Text from './Text'

const DefaultText = ({ sx, ...restProps }) => {
  return (
    <Text
      variant='body1'
      color='textprimary'
      component='div'
      sx={{ mt: 2, mb: 2, ...sx }}
      {...restProps}
    />
  )
}

export default memo(DefaultText)
