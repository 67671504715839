import React, { memo } from 'react'

import AccountForm from '../shared/AccountForm/AccountForm'

const LWA = ({ t, onClickLWASetup }) => {
  return (
    <AccountForm
      title={t('developers.lwa')}
      description={t('developers.lwa-description')}
      submitLabel='developers.start-setup'
      onFormSubmit={onClickLWASetup}
      t={t}
    />
  )
}

export default memo(LWA)
