import React, { memo, useState } from 'react'
import { Box, Tooltip } from '@mui/material'

const CustomTooltip = ({ label, parentProps, children, showIfLength }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  return showIfLength === undefined ||
    (showIfLength && label?.length > showIfLength) ? (
    <Tooltip
      title={label || ''}
      arrow
      componentsProps={{ tooltip: { sx: { textAlign: 'center ' } } }}
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      leaveTouchDelay={5000}
    >
      <Box
        component='span'
        onClick={() => setShowTooltip(!showTooltip)}
        {...parentProps}
      >
        {children}
      </Box>
    </Tooltip>
  ) : (
    children
  )
}

export default memo(CustomTooltip)
