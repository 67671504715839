import {
  EXCHANGE_FEEDBACK_ACTION,
  EXCHANGE_PARTNER_ACTION,
  EXCHANGE_SETTINGS_ACTION,
  EXCHANGE_CONFIG_ACTION,
  EXCHANGE_NFT_ACTION,
  EXCHANGE_TERMS_ACTION
} from '../../constants/actions'

// EXCHANGE SETTINGS
const changeExchangeSettingsState = (property, value) => {
  return {
    type: EXCHANGE_SETTINGS_ACTION.CHANGE_STATE,
    payload: {
      property,
      value
    }
  }
}

const saveExchangeSettings = () => {
  return {
    type: EXCHANGE_SETTINGS_ACTION.ON_SAVE_SETTINGS
  }
}

// PARTNERS
const changePartnersState = (tab, property, value) => {
  return {
    type: EXCHANGE_PARTNER_ACTION.CHANGE_STATE,
    payload: { tab, property, value }
  }
}

const resetExchangeFilters = () => {
  return {
    type: EXCHANGE_PARTNER_ACTION.ON_RESET_FILTERS
  }
}

const getPotentialPartners = () => {
  return {
    type: EXCHANGE_PARTNER_ACTION.ON_GET_PARTNERS
  }
}

const getExchangeWhitelist = () => {
  return {
    type: EXCHANGE_PARTNER_ACTION.ON_GET_WHITELIST
  }
}

const connectExchangePartner = (fromTokenId, toTokenId) => {
  return {
    type: EXCHANGE_PARTNER_ACTION.ON_CONNECT_EXCHANGE,
    payload: { fromTokenId, toTokenId }
  }
}

const bulkConnectExchangePartners = (toTokenIds) => {
  return {
    type: EXCHANGE_PARTNER_ACTION.ON_BULK_CONNECT_EXCHANGE,
    payload: { toTokenIds }
  }
}

const updateExchangeConnection = (action, data, connection) => {
  return {
    type: EXCHANGE_PARTNER_ACTION.ON_UPDATE_EXCHANGE_CONNECTION,
    payload: { action, data, connection }
  }
}

const changeExchangeFeedback = (property, value) => {
  return {
    type: EXCHANGE_FEEDBACK_ACTION.CHANGE_STATE,
    payload: {
      property,
      value
    }
  }
}

const resetExchangeFeedback = () => {
  return {
    type: EXCHANGE_FEEDBACK_ACTION.ON_RESET_STATE
  }
}

const changeExchangeConfig = (property, value) => {
  return {
    type: EXCHANGE_CONFIG_ACTION.CHANGE_STATE,
    payload: {
      property,
      value
    }
  }
}

const resetExchangeConfig = () => {
  return {
    type: EXCHANGE_CONFIG_ACTION.ON_RESET_STATE
  }
}

// BUY NFT
const changeBuyNftState = (property, value) => ({
  type: EXCHANGE_NFT_ACTION.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

const buyNft = (nft, supply) => ({
  type: EXCHANGE_NFT_ACTION.ON_BUY,
  payload: {
    nft,
    supply
  }
})

// EXCHANGE TERMS
const changeExchangeTermsState = (property, value) => ({
  type: EXCHANGE_TERMS_ACTION.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

const changeExchangeTermsFormDataState = (property, value) => ({
  type: EXCHANGE_TERMS_ACTION.CHANGE_FORM_DATA_STATE,
  payload: {
    property,
    value
  }
})

const resetExchangeTermsState = () => ({
  type: EXCHANGE_TERMS_ACTION.RESET_STATE
})

const submitExchangeTermsAndConnectExchange = (
  surveyId,
  response,
  fromTokenId,
  toTokenId
) => ({
  type: EXCHANGE_TERMS_ACTION.ON_SUBMIT_TERMS,
  payload: {
    surveyId,
    response,
    fromTokenId,
    toTokenId
  }
})

export {
  changeExchangeSettingsState,
  saveExchangeSettings,
  getPotentialPartners,
  getExchangeWhitelist,
  connectExchangePartner,
  bulkConnectExchangePartners,
  changePartnersState,
  updateExchangeConnection,
  changeExchangeFeedback,
  resetExchangeFeedback,
  changeExchangeConfig,
  resetExchangeConfig,
  resetExchangeFilters,
  changeBuyNftState,
  buyNft,
  changeExchangeTermsState,
  changeExchangeTermsFormDataState,
  resetExchangeTermsState,
  submitExchangeTermsAndConnectExchange
}
