export const DATE_FORMATS = {
  HOURLY: 'MMM.DD - HH:mm',
  DAILY: 'MMM.DD - YYYY',
  WEEKLY: 'W',
  MONTHLY: 'MMM. - YYYY',
  YEARLY: 'YYYY'
}
export const SHORT_DATE_FORMAT = 'DD.MM.YYYY'
export const DATE_PICKER_FORMAT = 'dd.MM.yyyy'
export const LONG_DATE_FORMAT = 'DD.MM.YYYY / HH:mm:ss'
export const HOUR = 'hour'
export const HOURS = 'hours'
export const SECOND = 'second'
export const SECONDS = 'seconds'
export const MINUTES = 'minutes'
export const WEEK = 'week'
export const WEEKS = 'weeks'
export const DAY = 'day'
export const DAYS = 'days'
export const MONTH = 'month'
export const MONTHS = 'months'
export const ISO_WEEK = 'isoWeek'
export const IOS_DATE_FORMAT = 'YYYY/MM/DD HH:mm'
export const BILLING_ISO_DATE_FORMAT = 'MM/DD/YYYY'
export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
