import { QB_LABEL_TEXT } from './messages'

export const MEMBER_AUTH_ID_MIN_LENGTH = 5

export const QB_MEMBERS_FILTER_OPTIONS = [
  {
    name: QB_LABEL_TEXT.ALL_MEMBERS,
    id: 0
  },
  {
    name: QB_LABEL_TEXT.ALL_TRANSACTIONS,
    id: 1
  }
]

export const QB_MEMBERS_TABLE_FIELDS = {
  NAME: 'name',
  EMAIL: 'email',
  AUTH_ID: 'authId',
  USER_CREATED: 'userCreated'
}

export const DEFAULT_TXN_TABLE_FIELDS = {
  NAME: 'name',
  EMAIL: 'email',
  AUTH: 'auth',
  STATUS: 'status',
  DATE: 'date',
  HASH: 'hash',
  TYPE: 'type',
  AMOUNT: 'amount',
  EXCHANGE: 'exchange'
}

export const TRANSACTIONS_TABLE_FIELDS = {
  ...DEFAULT_TXN_TABLE_FIELDS,
  ID: 'id',
  EVENT_TYPE: 'eventType',
  CODE: 'code',
  REWARD: 'reward',
  FEES: 'fees',
  TRIGGERED_BY: 'triggeredBy',
  BRAND_REF: 'brandRef',
  MM_ACCOUNT: 'mmAccount'
}

export const TRANSACTION_SEARCH_FILTERS = {
  EMAIL: 'email',
  AUTH: 'auth',
  HASH: 'hash',
  BRAND_REF: 'brandRef',
  MM_ACCOUNT: 'mmAccount'
}

export const MEMBER_POINTS_TRANSACTION_TYPES = {
  CREDIT_POINTS: 'credit_points',
  DEBIT_POINTS: 'debit_points'
}
