import { faMusic, faVideo } from '@fortawesome/pro-light-svg-icons'

import {
  formatValidator,
  minMaxRule,
  requiredRule,
  validNftSalesFeeRule,
  validUrlRule,
  checkFileExtensionRule
} from './validation.helpers'
import { COLOR_PALETTE } from '../constants/theme'
import {
  NFT_EXCLUSIVE_CONTENT_MODE,
  NFT_MARKETPLACE_OPTIONS,
  NFT_MEDIA_TYPES
} from '../constants/nfts'
import { NFT_YES_NO_OPTIONS } from '../data/nfts'
import { calculatePointValue, tokenMetaDescription } from './token.helpers'
import {
  NFT_NAME_MAX_LENGTH,
  POINT_SYMBOL_MIN_LENGTH
} from '../constants/validation'
import { buildDropdownList } from './app/app.helpers'
import { formatArrayToString, toUpper } from './string.helpers'
import { isTokenStatusActive } from './token.helpers'
import { API_RESPONSE } from '../constants/api'
import { ERROR_TEXT } from '../constants/messages'
import { buildErrorMessage } from './reducer.helpers'
import { FILE_TYPES } from '../constants/app'

const buildNftValidationRules = ({
  media,
  mediaUrl,
  name,
  type,
  supply,
  externalLink,
  userSaleTemp,
  userTransferTemp,
  userSalesFeeTemp,
  exclusiveContentMode,
  exclusiveContent,
  exclusiveContentFile
}) => {
  return {
    ...(!mediaUrl && { media: { value: media, rules: [requiredRule] } }),
    name: {
      value: name,
      rules: [
        requiredRule,
        minMaxRule(POINT_SYMBOL_MIN_LENGTH, NFT_NAME_MAX_LENGTH)
      ]
    },
    type: { value: type, rules: [requiredRule] },
    supply: { value: supply, rules: [requiredRule] },
    externalLink: { value: externalLink, rules: [validUrlRule] },
    ...buildUserPermissionsValidationRules({
      userSaleTemp,
      userTransferTemp,
      userSalesFeeTemp
    }),
    ...(isSameExclusiveContent(exclusiveContentMode) && {
      exclusiveContent: {
        value: exclusiveContent,
        rules: [requiredRule]
      }
    }),
    ...(isUniqueExclusiveContent(exclusiveContentMode) && {
      exclusiveContentFile: {
        value: exclusiveContentFile,
        rules: [requiredRule, checkFileExtensionRule([FILE_TYPES.CSV])]
      }
    })
  }
}

const buildUserPermissionsValidationRules = ({
  userSaleTemp,
  userTransferTemp,
  userSalesFeeTemp
}) => {
  return {
    userSaleTemp: { value: userSaleTemp, rules: [requiredRule] },
    userTransferTemp: { value: userTransferTemp, rules: [requiredRule] },
    ...(convertToBoolean(userSaleTemp) && {
      userSalesFeeTemp: {
        value: userSalesFeeTemp,
        rules: [requiredRule, validNftSalesFeeRule]
      }
    })
  }
}

const nftFieldsFormat = (nft, { mediaUrl, exclusiveContentFileUrl }) => {
  const {
    name,
    type,
    supply,
    description,
    exclusiveContent,
    externalLink,
    userSaleTemp,
    userTransferTemp,
    userSalesFeeTemp,
    media
  } = nft
  const mediaType = media?.type.split('/')[0]
  return {
    nft: {
      name,
      supply,
      nft_type: type,
      can_redeem: false,
      onramp: 0,
      metadata: {
        ...tokenMetaDescription(description),
        external_link: externalLink,
        exclusive_content: exclusiveContent,
        media: {
          type: mediaType,
          url: mediaUrl
        },
        ...(exclusiveContentFileUrl && {
          links: {
            exclusive_content_file: exclusiveContentFileUrl
          }
        })
      },
      ...updateUserPermissionsParams({
        userSaleTemp,
        userTransferTemp,
        userSalesFeeTemp
      })
    }
  }
}

const updateUserPermissionsParams = ({
  userSaleTemp,
  userTransferTemp,
  userSalesFeeTemp
}) => {
  return {
    sales_fee_percentage: parseFloat(userSalesFeeTemp),
    can_transfer: convertToBoolean(userTransferTemp),
    can_sell: convertToBoolean(userSaleTemp)
  }
}

const convertToBoolean = (response) => {
  if (response === NFT_YES_NO_OPTIONS.YES) {
    return true
  }
  return false
}

const convertToBooleanLabel = (response) => {
  if (response) {
    return NFT_YES_NO_OPTIONS.YES
  }
  return NFT_YES_NO_OPTIONS.NO
}

const convertToYesNo = (value, t) => {
  return t(`nft.yes-no-options.${value ? 'yes' : 'no'}`)
}

const buildNftMediaIcons = (type) => {
  switch (type) {
    case NFT_MEDIA_TYPES.AUDIO:
      return faMusic
    case NFT_MEDIA_TYPES.VIDEO:
      return faVideo
  }
}

const sortDataByDateDESC = (data) => {
  if (data && data.length) {
    return data.sort((a, b) => new Date(b.insertedAt) - new Date(a.insertedAt))
  }
  return []
}

const buildUpdateNftData = (data) => {
  return {
    token: {
      ...data
    }
  }
}

const buildMarketplaceParams = (canRedeem = false, canSellOnLad = false) => {
  return {
    can_redeem: canRedeem,
    can_sell_on_lad: canSellOnLad
  }
}

const buildListMarketplaceParams = (
  { pointValue, marketplace },
  tokenOnramp
) => {
  const onramp = calculatePointValue(pointValue, tokenOnramp)
  return {
    onramp,
    ...buildMarketplaceParamsByOption(marketplace)
  }
}

const buildMarketplaceParamsByOption = (marketplace) => {
  switch (marketplace) {
    case NFT_MARKETPLACE_OPTIONS.LWA:
      return buildMarketplaceParams(true, false)
    case NFT_MARKETPLACE_OPTIONS.LAD:
      return buildMarketplaceParams(false, true)
    default:
      return buildMarketplaceParams(true, true)
  }
}

const buildRedeemExclCntData = (userAuthId, nftId, claimCount) => {
  return {
    user_auth_id: userAuthId,
    claim: claimCount > 0 ? -1 : 1,
    nft_id: nftId
  }
}

const buildStatusLabelColor = (exclusiveContent, claimCount) => {
  if (exclusiveContent) {
    if (claimCount > 0) {
      return {
        label: 'redeemed',
        color: COLOR_PALETTE.ERROR
      }
    } else {
      return {
        label: 'available',
        color: COLOR_PALETTE.SUCCESS
      }
    }
  }
  return {
    label: 'none',
    color: COLOR_PALETTE.GREY4
  }
}

const buildMarketplaceOptions = (t) => {
  return buildDropdownList(
    NFT_MARKETPLACE_OPTIONS,
    'nft.marketplace-options',
    t
  )
}

const buildListedPlace = ({ canRedeem, canSellOnLad }) => {
  const listedIn = []
  if (canRedeem) {
    listedIn.push(NFT_MARKETPLACE_OPTIONS.LWA)
  }
  if (canSellOnLad) {
    listedIn.push(NFT_MARKETPLACE_OPTIONS.LAD)
  }
  return toUpper(formatArrayToString(listedIn))
}

const isNftListedListed = ({ status, canRedeem, canSellOnLad }) => {
  return isTokenStatusActive({ status }) && (canRedeem || canSellOnLad)
}

const calculateNftSupply = (nftsListProfile) => {
  return nftsListProfile.nfts.reduce((accumulator, nft) => {
    return accumulator + nft.supply
  }, 0)
}

const extractCreateNftErrors = ({ status }) => {
  const conflictError = formatValidator(ERROR_TEXT.NFT_NAM_DESCRIPTION_CONFLICT)
  return status === API_RESPONSE.CONFLICT
    ? {
        name: conflictError,
        description: conflictError
      }
    : {
        name: buildErrorMessage({ status })
      }
}

const filterNftsWithSupply = (data) => {
  return data.filter((nft) => nft.supply > 0)
}

const isSameExclusiveContent = (exclusiveContentMode) => {
  return exclusiveContentMode === NFT_EXCLUSIVE_CONTENT_MODE.SAME
}

const isUniqueExclusiveContent = (exclusiveContentMode) => {
  return exclusiveContentMode === NFT_EXCLUSIVE_CONTENT_MODE.UNIQUE
}

const exclusiveContentLabel = (metadata, t) => {
  return convertToYesNo(
    metadata?.exclusiveContent || metadata?.links?.exclusiveContentFile,
    t
  )
}

export {
  buildNftValidationRules,
  buildUserPermissionsValidationRules,
  nftFieldsFormat,
  buildNftMediaIcons,
  sortDataByDateDESC,
  buildUpdateNftData,
  buildMarketplaceParams,
  buildListMarketplaceParams,
  updateUserPermissionsParams,
  buildRedeemExclCntData,
  buildStatusLabelColor,
  convertToYesNo,
  convertToBooleanLabel,
  buildMarketplaceOptions,
  buildListedPlace,
  isNftListedListed,
  calculateNftSupply,
  extractCreateNftErrors,
  filterNftsWithSupply,
  isSameExclusiveContent,
  isUniqueExclusiveContent,
  exclusiveContentLabel
}
