import React, { memo } from 'react'

import RewardedMember from './RewardedMember'

const TransactionConfirmation = ({
  t,
  transaction: { type, amount, isProcessing },
  tokenProfile: { name, symbol },
  ...restProps
}) => {
  return (
    <RewardedMember
      title={`${t(`member.transaction-filters.${type}`)} ${t(
        'member.confirmation'
      )}`}
      description={t('member.points-credit-debit-confirmation-msg', {
        name,
        action: t(`member.points-action-${type}`)
      })}
      submitProps={{
        label: t('common.confirm'),
        disabled: isProcessing
      }}
      t={t}
      type={type}
      value={amount}
      symbol={symbol}
      {...restProps}
    />
  )
}

export default memo(TransactionConfirmation)
