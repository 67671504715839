import _ from 'lodash'
import { createTheme } from '@mui/material/styles'
import { useSelector } from 'react-redux'

import typography from './Typography'
import components from './Override'
import shadows from './Shadows'

const sidebarWidth = 265
const topBarHeight = 70
const testingAlertHeight = 45

const baseTheme = {
  direction: 'ltr',
  palette: {
    primary: {
      main: '#00a0e3',
      light: '#e5f7ff',
      dark: '#007eb3',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#d82a90',
      light: '#fbe9f4',
      dark: '#c22480',
      contrastText: '#ffffff'
    },
    success: {
      main: '#00c292',
      light: '#ebfaf2',
      dark: '#00964b',
      contrastText: '#ffffff'
    },
    danger: {
      main: '#e46a76',
      light: '#fdf3f5',
      contrastText: '#ffffff'
    },
    info: {
      main: '#6c65ba',
      light: '#eeedf7'
    },
    error: {
      main: '#e46a76',
      light: '#fdf3f5',
      dark: '#e45a68'
    },
    warning: {
      main: '#fec90f',
      light: '#fff4e5',
      //dark: '#dcb014', @TODO: Changed As suggested by Gianluca
      dark: '#fec90f',
      contrastText: '#ffffff'
    },
    text: {
      secondary: '#777e89',
      danger: '#fc4b6c'
    },
    grey: {
      A100: '#ecf0f2',
      A200: '#99abb4',
      A400: '#767e89',
      A700: '#e6f4ff'
    },
    lightGrey: {
      main: '#F5F5F5',
      light: '#FFFFFF',
      dark: '#E5E5E5',
      contrastText: '#2D2D2D'
    },
    darkGrey: {
      main: '#2D2D2D',
      light: '#5F5F5F',
      dark: '#000000'
    },
    grey4: {
      main: '#E5E5E5',
      light: '#F5F5F5'
    },
    orange: {
      main: '#ED6C02',
      contrastText: '#ffffff'
    },
    action: {
      disabledBackground: 'rgba(73,82,88,0.12)',
      hoverOpacity: 0.02,
      hover: 'rgba(0, 0, 0, 0.03)'
    },
    qb: {
      main: '#00A0E3',
      accent: '#D82A90'
    }
  },
  shape: {
    borderRadius: 5
  },
  mixins: {
    toolbar: {
      color: '#949db2',
      '@media(min-width:1280px)': {
        minHeight: topBarHeight,
        padding: '0 30px'
      },
      '@media(max-width:1280px)': {
        minHeight: '64px'
      }
    }
  },
  status: {
    danger: '#e53e3e'
  },
  components,
  typography,
  shadows
}

export const BuildTheme = (config = {}) => {
  const accountProfile = useSelector(
    (state) => state.accountReducer.accountProfile
  )
  const isDarkMode = accountProfile.metadata?.darkMode
  const baseMode = {
    palette: {
      mode: accountProfile.activeMode,
      background: {
        default: isDarkMode ? '#20232a' : '#fafbfb',
        dark: isDarkMode ? '#1c2025' : '#ffffff',
        paper: isDarkMode ? '#282C34' : '#ffffff'
      },
      text: {
        primary: isDarkMode ? '#e6e5e8' : 'rgba(0, 0, 0, 0.87)',
        secondary: isDarkMode ? '#adb0bb' : '#777e89'
      },
      inputBorderColor: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : '#dee3e9',
      ssoBorderColor: isDarkMode ? '#42464d' : '#dde3e8'
    }
  }
  const theme = createTheme(
    _.merge({}, baseTheme, baseMode, {
      direction: config.direction
    })
  )
  return theme
}

export { topBarHeight, sidebarWidth, baseTheme, testingAlertHeight }
