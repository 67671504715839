import {
  EXCHANGE_TERMS_ACTION,
  MAIN_PROFILE_ACTION,
  SURVEY_ACTION
} from '../../../constants/actions'
import { mergeToNewObject } from '../../../util/object.helpers'

export const surveyProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SURVEY_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case SURVEY_ACTION.ON_SUBMIT_USER_SURVEY:
    case SURVEY_ACTION.ON_UPDATE_USER_SURVEY:
      const { surveyId, id, questionId, liked } = payload
      return mergeToNewObject(
        state,
        handleSurveyResponse(SURVEY_PROFILE_DISPATCH.USER_SURVEYS, state, {
          surveyId,
          id,
          response: { [questionId]: { liked } }
        })
      )

    case SURVEY_ACTION.ON_SUBMIT_USER_SURVEY_SUCCESS:
    case SURVEY_ACTION.ON_UPDATE_USER_SURVEY_SUCCESS:
      return mergeToNewObject(
        state,
        handleSurveyResponse(
          SURVEY_PROFILE_DISPATCH.USER_SURVEYS,
          state,
          payload
        )
      )

    case EXCHANGE_TERMS_ACTION.ON_SUBMIT_TERMS_SUCCESS:
      return mergeToNewObject(
        state,
        handleSurveyResponse(
          SURVEY_PROFILE_DISPATCH.BRAND_SURVEYS,
          state,
          payload.data
        )
      )

    case MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS:
      return mergeToNewObject(state, { surveys: payload.surveys })

    case SURVEY_ACTION.ON_GET_USER_SURVEYS_SUCCESS:
      return mergeToNewObject(state, {
        userSurveys: payload
      })

    case SURVEY_ACTION.ON_GET_BRAND_SURVEYS_SUCCESS:
      return mergeToNewObject(state, {
        brandSurveys: payload
      })

    case SURVEY_ACTION.ON_GET_LIST_USER_SURVEYS:
      return mergeToNewObject(state, {
        sellerExchangeTerms: null,
        isProcessing: true
      })

    case SURVEY_ACTION.ON_GET_LIST_USER_SURVEYS_SUCCESS:
      const brandSurvey = payload?.[0]
      return mergeToNewObject(state, {
        isProcessing: false,
        sellerExchangeTerms: brandSurvey
          ? {
              brandSurvey,
              exchangeTerms: state.surveys[brandSurvey.survey_id]
            }
          : null
      })

    case SURVEY_ACTION.ON_GET_LIST_USER_SURVEYS_FAILED:
      return mergeToNewObject(state, {
        isProcessing: false
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  surveys: {},
  userSurveys: {},
  brandSurveys: {},
  sellerExchangeTerms: null,
  isProcessing: false
}

export const SURVEY_PROFILE_DISPATCH = {
  USER_SURVEYS: 'userSurveys',
  BRAND_SURVEYS: 'brandSurveys',
  SELLER_EXCHANGE_TERMS: 'sellerExchangeTerms'
}

const handleSurveyResponse = (key, state, { surveyId, id, response }) => {
  return {
    [key]: {
      ...state[key],
      [surveyId]: {
        ...state[key]?.[surveyId],
        id,
        response: {
          ...state[key]?.[surveyId]?.response,
          ...response
        }
      }
    }
  }
}
