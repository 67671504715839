import { combineReducers } from 'redux'

import { transactionsGraphProfile } from './transactionsGraph'
import { transactionsProfile } from './transactions'
import { transactionsDownloadProfile } from './transactionsDownload'
import { exchangeTransactionsGraphProfile } from './exchangeTransactionsGraph'
import { changeStatusProfile } from './changeStatus'
import { refundMilesProfile } from './refundMiles'
import { transactionColumnsProfile } from './transactionColumns'
import { transactionSearchProfile } from './transactionSearch'

export const transactionsReducer = combineReducers({
  transactionsProfile,
  transactionsGraphProfile,
  transactionsDownloadProfile,
  exchangeTransactionsGraphProfile,
  changeStatusProfile,
  refundMilesProfile,
  transactionColumnsProfile,
  transactionSearchProfile
})
