import React, { memo } from 'react'
import styled from '@emotion/styled'
import { Box } from '@mui/material'

const ShowCircleGuidance = ({ show, children, invert, ...restProps }) => {
  return show ? (
    <>
      {children}
      <CustomFaIcon component='span' invert={+invert} {...restProps} />
    </>
  ) : (
    children
  )
}

export default memo(ShowCircleGuidance)

ShowCircleGuidance.defaultProps = {
  invert: false
}

const CustomFaIcon = styled(Box)`
  z-index: 5;
  margin-left: 6px;
  position: relative;
  border-radius: 50%;
  bottom: 4px;
  width: 8px;
  height: 8px;
  ${({ theme: { palette }, invert }) => [
    `background-color: ${palette.danger.main};`,
    invert &&
      `border: solid 1px ${palette.common.white}; box-sizing: content-box;`
  ]};
  ${({ circlecss }) => circlecss};
`
