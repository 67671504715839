import React, { memo } from 'react'

import { showRegionsLabel } from '../../util/exchange.helpers'
import DisplayLabel from '../shared/DisplayLabel/DisplayLabel'

const RegionsLabel = ({ isCrypto, countries, t }) => {
  return (
    <DisplayLabel
      label={t('common.regions')}
      value={isCrypto ? t('common.worldwide') : showRegionsLabel(countries, t)}
    />
  )
}

export default memo(RegionsLabel)
