import React, { memo } from 'react'

import RewardMember from './RewardMember'

const RewardPointsBankMember = (props) => {
  return (
    <RewardMember
      title='reward.reward-new-member'
      description='reward.reward-new-member-desc'
      accountLabel='reward.member-email'
      {...props}
    />
  )
}

export default memo(RewardPointsBankMember)
