import React, { memo } from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Chart from 'react-apexcharts'

import DashboardCard from '../shared/DashboardCard/DashboardCard'
import { buildChartOptions } from '../../util/chart/chart.helpers'
import Loading from '../shared/Loading/Loading'
import { CSS_FLEX_ALIGNED } from '../../styles/common'
import { CHART_TRANSACTIONS_HEIGHT } from '../../constants/chart'
import { transactionChartTooltip } from '../../util/chart/transactionsGraph.helpers'
import { formatTicks } from '../../util/string.helpers'
import { CURRENCY_SYMBOL } from '../../constants/app'

const LiabilityTrendChart = ({
  title,
  helpText,
  data,
  isProcessing,
  yAxisLabel
}) => {
  const theme = useTheme()
  const chartOptions = buildChartOptions(theme, {
    yaxis: {
      min: 0,
      forceNiceScale: true,
      tickAmount: 4,
      labels: {
        formatter: (value) => {
          return `${CURRENCY_SYMBOL.USD} ${formatTicks(value)}`
        }
      }
    },
    stroke: {
      curve: 'smooth',
      width: 3
    },
    ...transactionChartTooltip()
  })
  const chartData = [
    {
      name: yAxisLabel,
      data
    }
  ]
  return (
    <DashboardCard
      title={title}
      helpText={helpText}
      contentProps={{ p: 0 }}
      noPadding
    >
      <Box>
        {isProcessing ? (
          <Box sx={{ height: CHART_TRANSACTIONS_HEIGHT, ...CSS_FLEX_ALIGNED }}>
            <Loading noFullHeight />
          </Box>
        ) : (
          <Chart
            options={{
              ...chartOptions
            }}
            series={chartData}
            type='line'
            height={CHART_TRANSACTIONS_HEIGHT}
          />
        )}
      </Box>
    </DashboardCard>
  )
}

export default memo(LiabilityTrendChart)
