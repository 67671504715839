import React, { memo } from 'react'
import { Box, useTheme } from '@mui/material'

import { formatTicks } from '../../../util/string.helpers'

import CustomDialog from '../../shared/CustomDialog/CustomDialog'
import Text from '../../shared/text/Text'
import FaIcon from '../../shared/FaIcon/FaIcon'
import { buildMemberTransactionTypeIconColor } from '../../../util/transaction/transaction.helpers'
import { CSS_FLEX_ALIGNED } from '../../../styles/common'

const RewardedMember = ({ type, value, symbol, ...restProps }) => {
  const theme = useTheme()
  const { icon, color } = buildMemberTransactionTypeIconColor(type)
  return (
    <CustomDialog
      open
      body={
        <Box sx={{ gap: 1, ...CSS_FLEX_ALIGNED }}>
          <FaIcon icon={icon} color={theme.palette[color].main} width={20} />
          <Text
            variant='h2'
            fontWeight={700}
            label={`${formatTicks(value)} ${symbol}`}
          />
        </Box>
      }
      {...restProps}
    />
  )
}

export default memo(RewardedMember)
