import React, { memo } from 'react'
import { Box } from '@mui/material'
import Chart from 'react-apexcharts'
import { useTheme } from '@mui/material/styles'

import FaIcon from '../FaIcon/FaIcon'
import Text from '../text/Text'
import TitleWithPopover from '../TitleWithPopover/TitleWithPopover'
import {
  buildCategoryLabels,
  buildChartOptions,
  buildCustomTooltip
} from '../../../util/chart/chart.helpers'
import CustomFab from '../inputs/CustomFab'
import { buildCustomFabStyles } from '../../../util/style.helpers'
import BaseCardContent from '../BaseCardContent/BaseCardContent'
import CustomSkeleton from '../CustomSkeleton/CustomSkeleton'
import { CHART_ACTIVITY_HEIGHT } from '../../../constants/chart'
import { CSS_FLEX_COLUMN } from '../../../styles/common'

const ActivityChart = ({
  title,
  helpText,
  heading,
  data,
  labels,
  tooltipText,
  icon,
  colors,
  hasNoData,
  customPaddingColor,
  t,
  interval,
  isProcessing
}) => {
  const categories = buildCategoryLabels(t, labels, interval)
  const chartOptions = buildChartOptions(
    { ...useTheme(), customColors: colors },
    {
      grid: {
        show: true,
        borderColor: 'transparent',
        strokeDashArray: 2,
        padding: {
          top: 8,
          left: 0,
          right: 0,
          bottom: 8
        }
      },
      chart: {
        sparkline: {
          enabled: true
        }
      },
      stroke: {
        show: true,
        width: 2,
        curve: 'smooth'
      },
      xaxis: {
        categories
      },
      tooltip: {
        custom: ({ dataPointIndex }) => {
          return buildCustomTooltip(
            data[dataPointIndex],
            categories[dataPointIndex]
          )
        }
      }
    }
  )
  const chartData = [
    {
      name: tooltipText,
      data
    }
  ]

  const labelColor = colors[0]

  return (
    <BaseCardContent
      cardCss={{ pb: 0, pl: 0, pr: 0, height: '100%', ...CSS_FLEX_COLUMN }}
      contentCss={{
        paddingLeft: '30px',
        paddingRight: '30px'
      }}
      chart={
        isProcessing ? (
          <CustomSkeleton
            sx={{ height: CHART_ACTIVITY_HEIGHT }}
            variant='rectangular'
          />
        ) : (
          <>
            <Box flexGrow={1} />
            <Box sx={{ position: 'relative', top: !hasNoData && '8px' }}>
              <Chart
                options={chartOptions}
                series={chartData}
                type='area'
                height={CHART_ACTIVITY_HEIGHT}
              />
            </Box>
            {!hasNoData && (
              <Box
                sx={{
                  height: '8px',
                  ...(customPaddingColor && {
                    backgroundColor: `rgba(${customPaddingColor}, 1)`,
                    opacity: '25%'
                  })
                }}
              ></Box>
            )}
          </>
        )
      }
    >
      <Box display='flex' alignItems='flex-start'>
        <Box>
          <TitleWithPopover
            variant='h5'
            color='textSecondary'
            sx={{
              marginBottom: '0'
            }}
            gutterBottom
            title={title}
            helpText={helpText}
          />

          <Text
            variant='h2'
            sx={{
              mt: '1px',
              mb: '0px'
            }}
            gutterBottom
            label={
              isProcessing ? <CustomSkeleton sx={{ height: 44 }} /> : heading
            }
            fontWeight={500}
            color={`${labelColor}.main`}
          />
        </Box>
        <Box
          sx={{
            marginLeft: 'auto'
          }}
        >
          <CustomFab
            size='medium'
            elevation={0}
            color='primary'
            sx={{
              boxShadow: 'none',
              ...buildCustomFabStyles(labelColor)
            }}
          >
            <FaIcon icon={icon} size='lg' />
          </CustomFab>
        </Box>
      </Box>
    </BaseCardContent>
  )
}

export default memo(ActivityChart)
