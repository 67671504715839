import React, { memo } from 'react'
import { getFromToLabel } from '../../util/exchange.helpers'

import {
  calculateExchangeRateValue,
  isTokenTypeDonation,
  isTokenTypeCryptoFiat
} from '../../util/token.helpers'
import DisplayLabel from '../shared/DisplayLabel/DisplayLabel'

const ExchangeRateLabel = ({
  tokenProfile,
  targetToken: { offramp, onramp, symbol, isFrom },
  type,
  t
}) => {
  const brandTokenSymbol = tokenProfile?.symbol
  const isTypeMarket = isTokenTypeCryptoFiat(type)
  const exchangeRateValue = calculateExchangeRateValue(
    tokenProfile,
    { offramp, onramp, isFrom, isTypeMarket },
    { symbol: brandTokenSymbol, targetSymbol: symbol },
    t
  )
  return (
    <DisplayLabel
      label={t('exchange.exchange-rate')}
      helpText={
        isTypeMarket
          ? t('exchange.external-exchange-rate-desc', {
              type: t(`exchange.exchange-types.${type}`)
            })
          : isTokenTypeDonation(type)
          ? t('exchange.donation-exchange-rate-desc', {
              symbolA: brandTokenSymbol,
              symbolB: symbol
            })
          : t(`exchange.target-point-exchange-rate-${getFromToLabel(isFrom)}`, {
              exchangeRateValue
            })
      }
      value={exchangeRateValue}
    />
  )
}

export default memo(ExchangeRateLabel)
