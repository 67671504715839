import React, { memo } from 'react'
import { Chip } from '@mui/material'

import { mergeToNewObject } from '../../../util/object.helpers'
import FaIcon from '../FaIcon/FaIcon'

const ChipWithIcon = ({ label, icon, color, sx }) => {
  return (
    <Chip
      label={label}
      icon={<FaIcon icon={icon} width={16} />}
      sx={mergeToNewObject(
        {
          borderRadius: '5px',
          height: '40px',
          pr: '3px',
          pl: '3px'
        },
        sx
      )}
      color={color}
    />
  )
}

export default memo(ChipWithIcon)
