import React, { memo } from 'react'
import { Grid } from '@mui/material'
import {
  faCartCircleArrowUp,
  faCartCircleArrowDown,
  faEnvelopeOpenDollar
} from '@fortawesome/pro-light-svg-icons'

import { displayCurrencyValue } from '../../../util/number.helpers'
import UserAlert from '../../shared/CustomAlert/UserAlert'
import {
  calculateBuyerFee,
  calculateSellerFee,
  isStarterPackagePlan
} from '../../../util/billing.helpers'
import {
  PERFORMANCE_FEE_TYPES,
  PERFORMANCE_FEE_COLORS
} from '../../../constants/billing'
import StatCard from '../../shared/Analytics/StatCard'

const BuyerSellerPerformanceFee = ({
  subscribedPackage,
  exchangePerformance: { exchangeInTxVolume, exchangeOutTxVolume },
  onChangePackage,
  t
}) => {
  const totalExchangeVolume = subscribedPackage?.details?.volume
  const exchangeOutVolume = calculateBuyerFee(
    exchangeOutTxVolume,
    subscribedPackage
  )
  const buyersFee =
    exchangeOutVolume > totalExchangeVolume
      ? exchangeOutVolume - totalExchangeVolume
      : 0
  const sellersFee = calculateSellerFee(exchangeInTxVolume, subscribedPackage)
  const statsData = [
    {
      type: PERFORMANCE_FEE_TYPES.BUYERS,
      value: buyersFee,
      icon: faCartCircleArrowUp
    },
    {
      type: PERFORMANCE_FEE_TYPES.SELLERS,
      value: sellersFee,
      icon: faCartCircleArrowDown
    },
    {
      type: PERFORMANCE_FEE_TYPES.TOTAL,
      value: buyersFee - sellersFee,
      icon: faEnvelopeOpenDollar,
      noBorder: true
    }
  ]
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Grid
          container
          spacing={0}
          sx={{ alignItems: 'start', height: '100%' }}
        >
          {statsData.map(({ type, value, ...restProps }) => (
            <StatCard
              key={type}
              header={displayCurrencyValue(value)}
              title={t(`billing.performance-fee-${type}`)}
              helpText={t(`billing.performance-fee-help-text-${type}`)}
              color={PERFORMANCE_FEE_COLORS[type]}
              cardStyles={{ p: 1 }}
              {...restProps}
            />
          ))}
        </Grid>
      </Grid>
      {isStarterPackagePlan(subscribedPackage?.details?.name) && (
        <Grid item xs={12} md={4}>
          <UserAlert
            label={t('billing.view-package-options-to-upgrade')}
            i18nKey='billing.starter-package-selected-alert'
            severity='warning'
            sx={{ m: 0 }}
            linkProps={{ isButton: true, onClick: onChangePackage }}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default memo(BuyerSellerPerformanceFee)
