import React, { memo } from 'react'
import { Trans } from 'react-i18next'

import CustomDialog from '../../shared/CustomDialog/CustomDialog'
import Link from '../../shared/link/Link'
import { INTEGRATION_TYPES_URL } from '../../../constants/externalResources'

const ChangeIntegrationType = ({ t, onClose, onSubmit }) => {
  return (
    <CustomDialog
      open
      t={t}
      onSubmit={onSubmit}
      onClose={onClose}
      title={t('integration.change-integration-title')}
      description={
        <Trans i18nKey='integration.change-integration-desc'>
          <Link
            isOutside
            label={t('integration.integration-difference')}
            to={INTEGRATION_TYPES_URL}
          />
        </Trans>
      }
      submitProps={{ label: t('common.confirm') }}
    />
  )
}

export default memo(ChangeIntegrationType)
