import React, { memo } from 'react'

import PopoverHelpText from '../PopoverHelpText/PopoverHelpText'
import Text from '../text/Text'

const TitleWithPopover = ({ title, helpText, ...restProps }) => {
  return (
    <Text
      {...restProps}
      label={
        <>
          {title}
          {helpText && <PopoverHelpText label={helpText} />}
        </>
      }
    />
  )
}

TitleWithPopover.defaultProps = {
  fontWeight: 500
}

export default memo(TitleWithPopover)
