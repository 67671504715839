import { calculatePointValue } from '../token.helpers'
import { EXCHANGE_TRANSACTION_TYPES } from '../../constants/transactionTypes'
import { addNumbers, percentageOfCompletion } from '../number.helpers'

const buildTransferVolumeData = (
  { exchangeInTxVolume, exchangeOutTxVolume },
  exchangeType
) => {
  switch (exchangeType) {
    case EXCHANGE_TRANSACTION_TYPES.BUY:
      return {
        data: [exchangeOutTxVolume, 0]
      }
    case EXCHANGE_TRANSACTION_TYPES.SELL:
      return {
        data: [0, exchangeInTxVolume]
      }
    default:
      return {
        data: [exchangeOutTxVolume, exchangeInTxVolume]
      }
  }
}

const buildTransactionsCountData = (
  { exchangeInTxCount, exchangeOutTxCount },
  exchangeType
) => {
  switch (exchangeType) {
    case EXCHANGE_TRANSACTION_TYPES.BUY:
      return {
        data: [exchangeOutTxCount, 0]
      }

    case EXCHANGE_TRANSACTION_TYPES.SELL:
      return {
        data: [0, exchangeInTxCount]
      }

    default:
      return {
        data: [exchangeOutTxCount, exchangeInTxCount]
      }
  }
}

const buildTransactionsRateData = (
  {
    exchangeInTxVolume,
    exchangeOutTxVolume,
    exchangeInTxCount,
    exchangeOutTxCount
  },
  exchangeType
) => {
  switch (exchangeType) {
    case EXCHANGE_TRANSACTION_TYPES.BUY:
      return {
        data: [exchangeOutTxVolume / exchangeOutTxCount, 0]
      }

    case EXCHANGE_TRANSACTION_TYPES.SELL:
      return {
        data: [0, exchangeInTxVolume / exchangeInTxCount]
      }

    default:
      return {
        data: [
          exchangeOutTxVolume / exchangeOutTxCount,
          exchangeInTxVolume / exchangeInTxCount
        ]
      }
  }
}

const buildTransactionsValueData = (
  { exchangeInTxVolume, exchangeOutTxVolume },
  { offramp, onramp },
  exchangeType
) => {
  switch (exchangeType) {
    case EXCHANGE_TRANSACTION_TYPES.BUY:
      return {
        data: [calculatePointValue(exchangeOutTxVolume, offramp), 0]
      }
    case EXCHANGE_TRANSACTION_TYPES.SELL:
      return {
        data: [0, calculatePointValue(exchangeInTxVolume, onramp)]
      }

    default:
      return {
        data: [
          calculatePointValue(exchangeOutTxVolume, offramp),
          calculatePointValue(exchangeInTxVolume, onramp)
        ]
      }
  }
}

const calculateExchangeMembers = (members, exchangeType) => {
  if (exchangeType === EXCHANGE_TRANSACTION_TYPES.ALL) {
    return members.buy + members.sell
  }
  return members[exchangeType]
}

const formatExchangePartners = (data, exchangeType) => {
  let firstExchangeValue
  return data
    .map(({ brand, token, ...restAttrs }) => {
      const exchangeData = formatExchangePartnerData(restAttrs, exchangeType)
      return { ...exchangeData, brand, token }
    })
    .filter(({ value }) => value > 0)
    .sort((a, b) => b?.value - a?.value)
    .slice(0, 10)
    .map(({ value, ...restAttrs }, index) => {
      let percentage = 0
      if (index === 0) {
        firstExchangeValue = value
        percentage = 100
      } else {
        percentage = value && percentageOfCompletion(value, firstExchangeValue)
      }
      return { ...restAttrs, value, percentage }
    })
}

const formatExchangePartnerData = (
  {
    exchangeInTxVolume,
    exchangeOutTxVolume,
    exchangeInTxCount,
    exchangeOutTxCount
  },
  exchangeType
) => {
  switch (exchangeType) {
    case EXCHANGE_TRANSACTION_TYPES.BUY:
      return {
        value: exchangeOutTxVolume,
        count: exchangeOutTxCount
      }
    case EXCHANGE_TRANSACTION_TYPES.SELL:
      return {
        value: exchangeInTxVolume,
        count: exchangeInTxCount
      }

    default:
      return {
        value: addNumbers(exchangeOutTxVolume, exchangeInTxVolume),
        count: addNumbers(exchangeOutTxCount, exchangeInTxCount)
      }
  }
}

export {
  buildTransferVolumeData,
  buildTransactionsCountData,
  buildTransactionsRateData,
  buildTransactionsValueData,
  calculateExchangeMembers,
  formatExchangePartners,
  formatExchangePartnerData
}
