import { from, Observable } from 'rxjs'
import { mergeMap } from 'rxjs/operators'

import { logger } from '../util/log.helpers'
import { APP_CONFIG } from '../constants/appConfig'

class RecaptchaService {
  ready$ = () => {
    return new Observable((observer) => {
      window.grecaptcha.ready(() => {
        observer.next()
        observer.complete()
      })
      return () => logger('unsubscribing from RecaptchaService.ready$()')
    })
  }

  execute$ = () =>
    this.ready$().pipe(
      mergeMap((_) => from(window.grecaptcha.execute(APP_CONFIG.RECAPTCHA_ID)))
    )
}

const recaptchaService = new RecaptchaService()

export default recaptchaService
