import React, { memo } from 'react'
import { Box } from '@mui/material'

import FaIcon from '../shared/FaIcon/FaIcon'
import { buildNftMediaIcons } from '../../util/nft.helpers'
import { isMediaTypeImage } from '../../util/media.helpers'
import { CSS_FLEX_CENTERED } from '../../styles/common'

const NftMedia = ({
  media,
  onNftDetailsClick,
  coverImage,
  sx,
  iconSize = 90
}) => {
  const imageMediaType = isMediaTypeImage(media?.type)
  const url = media?.url || coverImage
  const bgStyle =
    (media?.url && imageMediaType) || coverImage
      ? {
          backgroundImage: `url(${url})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }
      : {
          backgroundImage: ({ palette: { primary, secondary } }) =>
            `linear-gradient(145deg, ${primary.main} , ${secondary.main})`
        }
  return (
    <Box
      sx={{
        ...bgStyle,
        ...sx,
        ...CSS_FLEX_CENTERED,
        position: 'relative',
        width: '100%',
        '&:after': {
          content: '""',
          display: 'block',
          paddingBottom: '100%'
        }
      }}
      color='common.white'
      fontSize={iconSize}
      onClick={onNftDetailsClick}
    >
      {media && !imageMediaType && (
        <FaIcon icon={buildNftMediaIcons(media?.type)} size='lg' />
      )}
    </Box>
  )
}

export default memo(NftMedia)
