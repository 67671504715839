import { NavLink } from 'react-router-dom'

import {
  DYNAMIC_PAGE_REDIRECT_PATHS,
  NAVIGATION_TARGET_BLANK
} from '../constants/externalResources'
import { REDIRECT_URL } from '../constants/navigation'

const buildLinkParams = ({ isOutside, href }) => {
  if (href) {
    return isOutside
      ? {
          href,
          target: NAVIGATION_TARGET_BLANK,
          rel: 'noopener noreferrer',
          component: 'a'
        }
      : { component: NavLink, to: href }
  }
  return ''
}

const checkoutSessionUrl = (tokenId, priceId) => {
  return `${REDIRECT_URL}/${DYNAMIC_PAGE_REDIRECT_PATHS.CHECKOUT_SESSION}?tid=${tokenId}&pid=${priceId}`
}

const billingSessionUrl = (tokenId) => {
  return `${REDIRECT_URL}/${DYNAMIC_PAGE_REDIRECT_PATHS.BILLING_SESSION}?tid=${tokenId}`
}

export { buildLinkParams, checkoutSessionUrl, billingSessionUrl }
