import React, { memo } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import {
  faInfoCircle,
  faMoneyBillSimple,
  faSquareDollar
} from '@fortawesome/pro-light-svg-icons'
import { faRankingStar } from '@fortawesome/free-solid-svg-icons'

import BaseCard from '../shared/BaseCard/BaseCard'
import DisplayLabel from '../shared/DisplayLabel/DisplayLabel'
import BrandTokenLabel from './BrandTokenLabel'
import { CSS_FLEX_COLUMN } from '../../styles/common'
import SmallButton from '../shared/buttons/SmallButton'
import {
  buildNftMediaIcons,
  exclusiveContentLabel
} from '../../util/nft.helpers'
import BrandCard from '../shared/BrandCard/BrandCard'
import { formatTicks } from '../../util/string.helpers'
import FaIcon from '../shared/FaIcon/FaIcon'
import { isMediaTypeImage } from '../../util/media.helpers'
import { formatCurrencyValue } from '../../util/number.helpers'

const ExchangeNftCard = ({ t, partner, onDetailsClick, onBuyNftClick }) => {
  const { supply, name, metadata, brand } = partner
  const media = metadata?.media
  const mediaType = media?.type
  const isShowMediaIcon = mediaType && !isMediaTypeImage(mediaType)
  const logoUrl = !isShowMediaIcon
    ? media?.url || metadata?.legacy?.coverImage
    : ''
  return (
    <Grid item sm={6} md={4} lg={4} xl={3} sx={{ width: '100%' }}>
      <BaseCard sx={{ p: 0, ...CSS_FLEX_COLUMN }}>
        <BrandCard
          onClick={() => onDetailsClick(partner)}
          label={partner?.rank}
          icon={faRankingStar}
          logoProps={{ variant: 'rounded' }}
          logoUrl={logoUrl}
          brandIcon={
            isShowMediaIcon && (
              <FaIcon icon={buildNftMediaIcons(mediaType)} size='3x' />
            )
          }
        />
        <Grid container spacing={1} p={2} pb={0}>
          <Grid item xs={12}>
            <BrandTokenLabel
              details={{ brandName: brand?.name, tokenName: name }}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <DisplayLabel label={t('common.type')} value={t('sidebar.nft')} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <DisplayLabel
              label={t('nft.exclusive-content')}
              value={exclusiveContentLabel(metadata, t)}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <DisplayLabel label={t('nft.supply')} value={formatTicks(supply)} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <DisplayLabel
              label={t('exchange.price-in-credits')}
              helpText={t('exchange.nft-price-in-credits-help-text')}
              value={
                <>
                  <FaIcon icon={faMoneyBillSimple} />
                  &nbsp;
                  {formatTicks(formatCurrencyValue(partner.onramp))} /&nbsp;
                  {t('sidebar.nft')}
                </>
              }
            />
          </Grid>
        </Grid>
        <Box flexGrow={1} />
        <Stack
          spacing={1}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ alignItems: 'center', m: 2, justifyContent: 'stretch' }}
        >
          <SmallButton
            label={t('common.details')}
            icon={faInfoCircle}
            onClick={() => onDetailsClick(partner)}
            variant='outlined'
          />
          <SmallButton
            label={t('exchange.buy-nft')}
            icon={faSquareDollar}
            color='success'
            onClick={() => onBuyNftClick(partner)}
          />
        </Stack>
      </BaseCard>
    </Grid>
  )
}

export default memo(ExchangeNftCard)
