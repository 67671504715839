import React, { memo } from 'react'
import { faDollarSign } from '@fortawesome/pro-light-svg-icons'

import ActivityChart from '../shared/ActivityChart/ActivityChart'
import { COLOR_PALETTE } from '../../constants/theme'

const ValueTransferredChart = ({ ...restProps }) => {
  return (
    <ActivityChart
      icon={faDollarSign}
      customPaddingColor='128,225,201'
      colors={[COLOR_PALETTE.SUCCESS]}
      {...restProps}
    />
  )
}

export default memo(ValueTransferredChart)
