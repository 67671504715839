import React, { memo } from 'react'
import { Box, Grid } from '@mui/material'

import BaseCard from '../shared/BaseCard/BaseCard'
import BaseForm from '../shared/BaseForm/BaseForm'
import Display from '../shared/Display/Display'
import { SOCIAL_MEDIA } from '../../constants/app'
import Google from '../socialMediaButtons/Google'
import { LABEL_TEXT } from '../../constants/messages'

const DisplayEmailAddress = ({ signedUpVia, email, t }) => {
  return (
    <BaseCard>
      <BaseForm
        title={t('account.logged-in-with', {
          provider: signedUpVia
        })}
        t={t}
        fields={
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Display label={t('admin.email-address')} value={email} />
            </Grid>
          </Grid>
        }
        actions={
          <>
            <Box sx={{ flex: '1 1 auto' }} />
            <SocialMediaButton
              disabled
              type={signedUpVia}
              label={LABEL_TEXT.SIGNUP}
              fullWidth={false}
            />
          </>
        }
      />
    </BaseCard>
  )
}

export default memo(DisplayEmailAddress)

const SocialMediaButton = ({ type, ...restProps }) => {
  switch (type) {
    case SOCIAL_MEDIA.GOOGLE:
      return <Google {...restProps} />
    default:
      return null
  }
}
