import { mergeMap, catchError, map, tap, filter } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'

import apiService from '../../services/api'
import {
  extractAccountProfile,
  hasUserAccountStateChanged
} from '../../util/epics.helpers'

import {
  AUTH_ACTION,
  ACCOUNT_ACTION,
  MAIN_PROFILE_ACTION
} from '../../constants/actions'

import {
  extractCognitoUserUsername,
  formatUserFieldsForCreation,
  extractCognitoUserAttributes,
  wrapUserAccessToken
} from '../../util/auth.helpers'
import { parseAccountDetails } from '../../util/apiParser.helpers'
import recaptchaService from '../../services/recaptcha'
import { QB_AUTH_LANGUAGE_ATTR } from '../../constants/auth'
import { API_RESPONSE } from '../../constants/api'
import { logger } from '../../util/log.helpers'
import { trim } from '../../util/string.helpers'

// USER ACCOUNT
const handleLoadUserAccountOnMainLoad = (action$, state$) =>
  action$.pipe(
    ofType(MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA),
    mergeMap((_) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getUserByClientId(extractCognitoUserUsername(state$), accessToken)
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: ACCOUNT_ACTION.ON_GET_ACCOUNT_DETAILS_SUCCESS,
              payload: parseAccountDetails(response.data)
            })),
            catchError((err) =>
              of({
                type: ACCOUNT_ACTION.ON_GET_ACCOUNT_DETAILS_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleCreateUserAccountIfDoesNotExist = (action$, state$) =>
  action$.pipe(
    ofType(ACCOUNT_ACTION.ON_GET_ACCOUNT_DETAILS_FAILED),
    tap((data) => logger(data)),
    filter(({ payload }) => payload.status === API_RESPONSE.NOT_FOUND),
    mergeMap((_) =>
      wrapUserAccessToken((accessToken) =>
        recaptchaService.execute$().pipe(
          mergeMap((recaptchaToken) => {
            return apiService
              .createUser(
                formatUserFieldsForCreation(state$),
                accessToken,
                recaptchaToken
              )
              .pipe(
                tap((data) => logger(data)),
                map(({ response }) => ({
                  type: ACCOUNT_ACTION.ON_CREATE_ACCOUNT_SUCCESS,
                  payload: parseAccountDetails(response.data)
                })),
                catchError((err) =>
                  of({
                    type: ACCOUNT_ACTION.ON_CREATE_ACCOUNT_FAILED,
                    payload: err
                  })
                )
              )
          })
        )
      )
    )
  )

// UPDATE USER ACCOUNT ON COGNITO ATTRIBUTES UPDATE
const handleUpdateUserAccountAfterCognitoUserUpdate = (action$, state$) =>
  action$.pipe(
    ofType(AUTH_ACTION.ON_GET_CURRENT_USER_AFTER_UPDATE_SUCCESS),
    filter(({ payload }) =>
      hasUserAccountStateChanged(payload, extractAccountProfile(state$))
    ),
    map(({ payload }) => extractCognitoUserAttributes(payload)),
    map((payload) =>
      buildUserDataToUpdate(payload, extractAccountProfile(state$))
    ),
    mergeMap((updatedUserData) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .updateUser(updatedUserData.userId, updatedUserData.user, accessToken)
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: ACCOUNT_ACTION.ON_UPDATE_USER_ACCOUNT_SUCCESS,
              payload: parseAccountDetails(response.data)
            })),
            catchError((err) =>
              of({
                type: ACCOUNT_ACTION.ON_UPDATE_USER_ACCOUNT_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleUpdateUserAccount = (action$, state$) =>
  action$.pipe(
    ofType(ACCOUNT_ACTION.ON_UPDATE_USER_ACCOUNT),
    mergeMap(({ payload }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .updateUser(extractCognitoUserUsername(state$), payload, accessToken)
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: ACCOUNT_ACTION.ON_UPDATE_USER_ACCOUNT_SUCCESS,
              payload: parseAccountDetails(response.data)
            })),
            catchError((err) =>
              of({
                type: ACCOUNT_ACTION.ON_UPDATE_USER_ACCOUNT_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

export const accountEpic = combineEpics(
  handleLoadUserAccountOnMainLoad,
  handleCreateUserAccountIfDoesNotExist,
  handleUpdateUserAccountAfterCognitoUserUpdate,
  handleUpdateUserAccount
)

const buildUserDataToUpdate = (
  { sub, email, family_name, given_name, [QB_AUTH_LANGUAGE_ATTR]: language },
  { jobPositionTemp, countryTemp }
) => {
  return {
    userId: sub,
    user: {
      email,
      first_name: trim(given_name),
      second_name: trim(family_name),
      language,
      job_position: trim(jobPositionTemp) || null,
      country_ISO: trim(countryTemp) || null
    }
  }
}
