export const SIGN_UP_URL = '/signup'
export const LOG_IN_URL = '/login'
export const FORGOT_PASSWORD_URL = '/forgot-password'
export const RESET_PASSWORD_URL = '/reset-password'
export const REDIRECT_URL = '/redirect'

export const HOME_URL = '/'
export const SETUP_URL = '/setup'
export const ANALYTICS_URL = '/analytics'
export const NFT_URL = '/nft'
export const LOYALTY_POINT_URL = '/loyalty-point'
export const MARKETPLACE_URL = '/marketplace'
export const INTEGRATION_URL = '/integration'
export const EXTENSIONS_URL = '/extensions'
export const ACCOUNT_URL = '/account'
export const ADMIN_URL = '/admin'
export const REQUEST_FEATURE_URL = '/request-feature'

export const UNAUTHORIZED_URLS = [
  LOG_IN_URL,
  FORGOT_PASSWORD_URL,
  RESET_PASSWORD_URL,
  SIGN_UP_URL,
  REDIRECT_URL
]

export const AUTHORIZED_URLS = [
  HOME_URL,
  LOYALTY_POINT_URL,
  SETUP_URL,
  ACCOUNT_URL,
  ANALYTICS_URL,
  INTEGRATION_URL,
  EXTENSIONS_URL,
  NFT_URL,
  MARKETPLACE_URL,
  ADMIN_URL,
  REQUEST_FEATURE_URL,
  NFT_URL,
  REDIRECT_URL
]
