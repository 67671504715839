import React, { memo } from 'react'
import { Box, Grid } from '@mui/material'

import BrandLogo from '../shared/BrandLogo/BrandLogo'
import { toUpper } from '../../util/string.helpers'
import MilestoneProgress from '../shared/ProgressBar/MilestoneProgress'
import { buildTokenNameSymbol } from '../../util/token.helpers'
import { displayCurrencyValue } from '../../util/number.helpers'
import BaseCard from '../shared/BaseCard/BaseCard'
import TitleWithPopover from '../shared/TitleWithPopover/TitleWithPopover'

const TopExchangePartners = ({ data, t }) => {
  return (
    <BaseCard sx={{ p: 3 }}>
      <TitleWithPopover
        title={t('analytics.top-reward-partners')}
        helpText={t('analytics.top-reward-partners-help-text')}
        variant='h4'
        sx={{
          marginBottom: '0'
        }}
        gutterBottom
      />
      {data.map(({ brand, token, value, count, percentage }, index) => {
        const { name, symbol } = token
        return (
          <Grid
            key={index}
            container
            alignItems='center'
            sx={{ pt: '6px', pb: '6px', textAlign: 'initial' }}
          >
            <Grid item xs={3} sm={1}>
              <BrandLogo
                size={42}
                icon={
                  <Box fontSize={22} fontWeight={600}>
                    {toUpper(name && name[0])}
                  </Box>
                }
                plainBg
                src={brand?.brandConfig?.logoUrl}
              />
            </Grid>
            <Grid
              item
              xs={8}
              sm={11}
              display='flex'
              flexDirection='column'
              justifyContent='center'
            >
              <MilestoneProgress
                title={buildTokenNameSymbol({ name, symbol })}
                description={t('analytics.total-exchange-value-number')}
                completed={percentage}
                value={displayCurrencyValue(value)}
                count={count}
              />
            </Grid>
          </Grid>
        )
      })}
    </BaseCard>
  )
}

export default memo(TopExchangePartners)
