import { faFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons'
import { Box } from '@mui/material'

import { buildExchangeUrl } from './exchange.helpers'
import { EXCHANGE_PARTNER_TYPES } from '../constants/exchange'
import { QB_TOKEN_STATUS } from '../constants/token'
import { toUpper } from './string.helpers'
import { adminPackagesBillingUrl } from './admin.helpers'
import FaIcon from '../components/shared/FaIcon/FaIcon'

const buildExchangeRequestNotifications = (partnersRequests, t) => {
  if (partnersRequests && partnersRequests.length > 0) {
    return partnersRequests
      .slice(0, 5)
      .map(({ brand, tokenExchangeWhitelist }) => {
        const { brandConfig, name } = brand
        return {
          label: `${name} ${t('notifications.sent-a-request')}`,
          logoParams: {
            icon: (
              <Box fontSize={22} fontWeight={600}>
                {toUpper(name[0])}
              </Box>
            ),
            src: brandConfig?.logoUrl
          },
          linkTo: {
            pathname: buildExchangeUrl(EXCHANGE_PARTNER_TYPES.REQUESTS),
            state: { requestsRouteStateIsTo: true }
          },
          date: tokenExchangeWhitelist?.insertedAt
        }
      })
  }
  return []
}

const buildTokenStatusNotifications = (tokenProfile, subscribedPackage, t) => {
  const tokenStatus = tokenProfile?.status
  const notifications = []
  if (subscribedPackage?.cancelAtPeriodEnd) {
    // @TODO: Need to handle this
    notifications.push(
      tokenStatusNotificationFormat({
        label: t('setup.subscription-cancelled'),
        color: 'danger',
        tokenProfile
      })
    )
  }
  if ([QB_TOKEN_STATUS.PAUSED, QB_TOKEN_STATUS.STOPPED].includes(tokenStatus)) {
    const { label, color } = buildColorAndLabelForTokenStatus()[tokenStatus]
    notifications.push(
      tokenStatusNotificationFormat({
        label: t(label),
        color,
        tokenProfile
      })
    )
  }
  return notifications
}

const tokenStatusNotificationFormat = ({ tokenProfile, color, label }) => {
  return {
    label,
    logoParams: {
      icon: <FaIcon icon={faFileInvoiceDollar} />,
      sx: {
        border: (theme) => `1px solid ${theme.palette[color].main}`,
        background: (theme) => `${theme.palette[color].main}`
      }
    },
    linkTo: adminPackagesBillingUrl(),
    date: tokenProfile?.todaysDate
  }
}

const buildColorAndLabelForTokenStatus = () => {
  return {
    [QB_TOKEN_STATUS.PAUSED]: {
      label: 'setup.payment-overdue',
      color: 'warning'
    },
    [QB_TOKEN_STATUS.STOPPED]: {
      label: 'setup.subscription-cancelled',
      color: 'danger'
    }
  }
}

const buildAppNotifications = (
  partnersRequests,
  tokenProfile,
  subscribedPackage,
  t
) => {
  return [
    ...buildTokenStatusNotifications(tokenProfile, subscribedPackage, t),
    ...buildExchangeRequestNotifications(partnersRequests, t)
  ]
}

export { buildAppNotifications }
