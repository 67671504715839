import React, { memo } from 'react'
import { Grid } from '@mui/material'

import { QB_DOCS_URL } from '../../constants/externalResources'
import Text from '../shared/text/Text'
import AccountForm from '../shared/AccountForm/AccountForm'
import { openExternalUrl } from '../../util/app/app.helpers'

const Docs = ({ t }) => {
  return (
    <AccountForm
      title={t('developers.qiibee-documentation')}
      description={t('developers.docs-text')}
      submitLabel='developers.view-docs'
      onFormSubmit={() => {
        openExternalUrl(QB_DOCS_URL)
      }}
      fields={
        <Grid item xs={12}>
          <Text
            label={t('developers.qiibee-doc-text')}
            variant='body1'
            color='textprimary'
          />
        </Grid>
      }
      t={t}
    />
  )
}

export default memo(Docs)
