import { deepMerge } from '../util/object.helpers'

const PACKAGE_DETAILS = {
  STARTER: 'starter',
  LITE: 'lite',
  PLUS: 'plus',
  PREMIUM: 'premium'
}

const PACKAGE_FEATURES_LIST = {
  LWA: 'lwa',
  CSM: 'csm',
  SUPPORT: 'support'
}

const allPricingPlans = () => {
  return deepMerge(
    {
      [PACKAGE_DETAILS.STARTER]: {
        monthlyPrice: 0,
        yearlyPrice: 0,
        volume: 0,
        fee: 0.2,
        nfts: 1,
        disabled: {
          lwa: true,
          csm: true,
          support: true
        }
      },
      [PACKAGE_DETAILS.LITE]: {
        monthlyPrice: 990,
        yearlyPrice: 11880,
        monthlyCredits: 50,
        yearlyCredits: 600,
        volume: 1100,
        fee: 0.2,
        nfts: 100,
        disabled: {
          csm: true,
          support: true
        }
      },
      [PACKAGE_DETAILS.PLUS]: {
        monthlyPrice: 2490,
        yearlyPrice: 29880,
        monthlyCredits: 200,
        yearlyCredits: 2400,
        volume: 2750,
        fee: 0.2,
        nfts: 600,
        isMostPopular: true,
        disabled: {
          support: true
        }
      },
      [PACKAGE_DETAILS.PREMIUM]: {
        monthlyPrice: 4990,
        yearlyPrice: 59880,
        monthlyCredits: 600,
        yearlyCredits: 7200,
        volume: 5500,
        fee: 0.2
      }
    },
    getStripePriceIds()
  )
}

const getStripePriceIds = () => {
  return {
    [PACKAGE_DETAILS.STARTER]: {
      priceId: 'STARTER'
    },
    [PACKAGE_DETAILS.LITE]: {
      priceId: 'LITE'
    },
    [PACKAGE_DETAILS.PLUS]: {
      priceId: 'PLUS'
    },
    [PACKAGE_DETAILS.PREMIUM]: {
      priceId: 'PREMIUM'
    }
  }
}

const getPricingPlanKeys = () => {
  return Object.keys(allPricingPlans())
}

const buildPricingPlans = () => {
  return getPricingPlanKeys().map((key) => ({
    name: key,
    ...allPricingPlans()[key]
  }))
}

const buildPackageFeatures = (isYearly) => {
  return Object.values(PACKAGE_FEATURES_LIST).filter((feature) =>
    isYearly ? true : feature !== PACKAGE_FEATURES_LIST.LWA
  )
}

const getPackageDetails = (plan) => {
  return allPricingPlans()[plan]
}

export {
  PACKAGE_DETAILS,
  buildPricingPlans,
  buildPackageFeatures,
  getPricingPlanKeys,
  getPackageDetails
}
