import { mergeToNewObject } from '../../../util/object.helpers'
import { API_KEY_ACTIONS } from '../../../constants/actions'

export const apiKeysProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case API_KEY_ACTIONS.ON_GET_API_KEYS_SUCCESS:
      return mergeToNewObject(state, { apiKeys: payload })

    case API_KEY_ACTIONS.ON_CREATE_API_KEY_SUCCESS:
      return mergeToNewObject(state, { apiKeys: [...state.apiKeys, payload] })

    case API_KEY_ACTIONS.ON_DELETE_API_KEY_SUCCESS:
      return mergeToNewObject(state, {
        apiKeys: state.apiKeys.filter((apiKey) => apiKey.id !== payload)
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  apiKeys: []
}
