// AUTH SIGN UP ACTION
export const AUTH_SIGN_UP_ACTION = {
  CHANGE_STATE: 'AUTH_SIGN_UP_ACTION_CHANGE_STATE',
  CHANGE_INPUT_AND_ERROR_STATE:
    'AUTH_SIGN_UP_ACTION_CHANGE_INPUT_AND_ERROR_STATE',
  RESET_STATE: 'AUTH_SIGN_UP_ACTION_RESET_STATE',

  ON_SIGN_UP: 'AUTH_SIGN_UP_ACTION_ON_SIGN_UP',
  ON_SIGN_UP_SUCCESS: 'AUTH_SIGN_UP_ACTION_ON_SIGN_UP_SUCCESS',
  ON_SIGN_UP_FAILED: 'AUTH_SIGN_UP_ACTION_ON_SIGN_UP_FAILED',

  ON_RESEND_EMAIL: 'AUTH_SIGN_UP_ACTION_ON_RESEND_EMAIL',
  ON_RESEND_EMAIL_SUCCESS: 'AUTH_SIGN_UP_ACTION_ON_RESEND_EMAIL_SUCCESS',
  ON_RESEND_EMAIL_FAILED: 'AUTH_SIGN_UP_ACTION_ON_RESEND_EMAIL_FAILED',

  ON_CONFIRM: 'AUTH_SIGN_UP_ACTION_ON_CONFIRM',
  ON_CONFIRM_SUCCESS: 'AUTH_SIGN_UP_ACTION_ON_CONFIRM_SUCCESS',
  ON_CONFIRM_FAILED: 'AUTH_SIGN_UP_ACTION_ON_CONFIRM_FAILED'
}

// AUTH LOGIN ACTION
export const AUTH_LOGIN_ACTION = {
  CHANGE_STATE: 'AUTH_LOGIN_ACTION_CHANGE_STATE',
  CHANGE_INPUT_AND_ERROR_STATE:
    'AUTH_LOGIN_ACTION_CHANGE_INPUT_AND_ERROR_STATE',
  RESET_STATE: 'AUTH_LOGIN_ACTION_RESET_STATE',

  ON_LOG_IN: 'AUTH_LOGIN_ACTION_ON_LOG_IN',
  ON_LOG_IN_SUCCESS: 'AUTH_LOGIN_ACTION_ON_LOG_IN_SUCCESS',
  ON_LOG_IN_FAILED: 'AUTH_LOGIN_ACTION_ON_LOG_IN_FAILED',

  ON_SOCIAL_MEDIA_LOG_IN: 'AUTH_LOGIN_ACTION_ON_SOCIAL_MEDIA_LOG_IN',
  ON_SOCIAL_MEDIA_LOG_IN_SUCCESS:
    'AUTH_LOGIN_ACTION_ON_SOCIAL_MEDIA_LOG_IN_SUCCESS',
  ON_SOCIAL_MEDIA_LOG_IN_FAILED:
    'AUTH_LOGIN_ACTION_ON_SOCIAL_MEDIA_LOG_IN_FAILED'
}

// AUTH FORGOT PASSWORD ACTION
export const AUTH_FORGOT_PASSWORD_ACTION = {
  CHANGE_STATE: 'AUTH_FORGOT_PASSWORD_ACTION_CHANGE_STATE',
  CHANGE_INPUT_AND_ERROR_STATE:
    'AUTH_FORGOT_PASSWORD_ACTION_CHANGE_INPUT_AND_ERROR_STATE',
  RESET_STATE: 'AUTH_FORGOT_PASSWORD_ACTION_RESET_STATE',

  ON_REQUEST_PASSWORD_CHANGE:
    'AUTH_FORGOT_PASSWORD_ACTION_ON_REQUEST_PASSWORD_CHANGE',
  ON_REQUEST_PASSWORD_CHANGE_SUCCESS:
    'AUTH_FORGOT_PASSWORD_ACTION_ON_REQUEST_PASSWORD_CHANGE_SUCCESS',
  ON_REQUEST_PASSWORD_CHANGE_FAILED:
    'AUTH_FORGOT_PASSWORD_ACTION_ON_REQUEST_PASSWORD_CHANGE_FAILED'
}

// AUTH RESET PASSWORD ACTION
export const AUTH_RESET_PASSWORD_ACTION = {
  CHANGE_STATE: 'AUTH_RESET_PASSWORD_ACTION_CHANGE_STATE',
  CHANGE_INPUT_AND_ERROR_STATE:
    'AUTH_RESET_PASSWORD_ACTION_CHANGE_INPUT_AND_ERROR_STATE',
  RESET_STATE: 'AUTH_RESET_PASSWORD_ACTION_RESET_STATE',

  ON_RESET_PASSWORD: 'AUTH_RESET_PASSWORD_ACTION_ON_RESET_PASSWORD',
  ON_RESET_PASSWORD_SUCCESS:
    'AUTH_RESET_PASSWORD_ACTION_ON_RESET_PASSWORD_SUCCESS',
  ON_RESET_PASSWORD_FAILED:
    'AUTH_RESET_PASSWORD_ACTION_ON_RESET_PASSWORD_FAILED'
}

// AUTH ACTION
export const AUTH_ACTION = {
  ON_CHANGE_PASSWORD: 'AUTH_ACTION_ON_CHANGE_PASSWORD',
  ON_CHANGE_PASSWORD_SUCCESS: 'AUTH_ACTION_ON_CHANGE_PASSWORD_SUCCESS',
  ON_CHANGE_PASSWORD_FAILED: 'AUTH_ACTION_ON_CHANGE_PASSWORD_FAILED',

  ON_GET_CURRENT_USER: 'AUTH_ACTION_ON_GET_CURRENT_USER',
  ON_GET_CURRENT_USER_SUCCESS: 'AUTH_ACTION_ON_GET_CURRENT_USER_SUCCESS',
  ON_GET_CURRENT_USER_FAILED: 'AUTH_ACTION_ON_GET_CURRENT_USER_FAILED',

  ON_GET_CURRENT_USER_AFTER_UPDATE_SUCCESS:
    'AUTH_ACTION_ON_GET_CURRENT_USER_AFTER_UPDATE_SUCCESS',
  ON_GET_CURRENT_USER_AFTER_UPDATE_FAILED:
    'AUTH_ACTION_ON_GET_CURRENT_USER_AFTER_UPDATE_FAILED',

  ON_SIGN_OUT: 'AUTH_ACTION_ON_SIGN_OUT',
  ON_SIGN_OUT_SUCCESS: 'AUTH_ACTION_ON_SIGN_OUT_SUCCESS',
  ON_SIGN_OUT_FAILED: 'AUTH_ACTION_ON_SIGN_OUT_FAILED',

  ON_UPDATE_USER_ATTRIBUTES: 'AUTH_ACTION_ON_UPDATE_USER_ATTRIBUTES',
  ON_UPDATE_USER_ATTRIBUTES_SUCCESS:
    'AUTH_ACTION_ON_UPDATE_USER_ATTRIBUTES_SUCCESS',
  ON_UPDATE_USER_ATTRIBUTES_FAILED:
    'AUTH_ACTION_ON_UPDATE_USER_ATTRIBUTES_FAILED',

  ON_ACCEPT_TERMS: 'AUTH_ACTION_ON_ACCEPT_TERMS'
}

// MAIN PROFILE ACTION
export const MAIN_PROFILE_ACTION = {
  ON_LOAD_MAIN_DATA: 'MAIN_PROFILE_ACTION_ON_LOAD_MAIN_DATA',
  ON_LOAD_MAIN_DATA_SUCCESS: 'MAIN_PROFILE_ACTION_ON_LOAD_MAIN_DATA_SUCCESS',
  ON_LOAD_MAIN_DATA_FAILED: 'MAIN_PROFILE_ACTION_ON_LOAD_MAIN_DATA_FAILED',

  CHANGE_STATE: 'MAIN_PROFILE_ACTION_CHANGE_STATE',

  ON_GET_USER_BRAND_TOKENS: 'MAIN_PROFILE_ACTION_ON_GET_USER_BRAND_TOKENS',

  ON_GET_PAYMENT_SUBSCRIPTIONS_EXCHANGE_PARTNERS:
    'MAIN_PROFILE_ACTION_ON_GET_PAYMENT_SUBSCRIPTIONS_EXCHANGE_PARTNERS',

  ON_NEW_BRAND_CREATION: 'MAIN_PROFILE_ACTION_ON_NEW_BRAND_CREATION',

  ON_SWITCH_BRAND_AND_TOKEN: 'MAIN_PROFILE_ACTION_ON_SWITCH_BRAND_AND_TOKEN',
  ON_SWITCH_BRAND_AND_TOKEN_SUCCESS:
    'MAIN_PROFILE_ACTION_ON_SWITCH_BRAND_AND_TOKEN_SUCCESS'
}

// DOWNLOAD ACTION
export const DOWNLOAD_ACTION = {
  ON_RESET_STATE: 'DOWNLOAD_ACTION_ON_RESET_STATE',

  ON_SENT_REPORT_IN_EMAIL_SUCCESS:
    'DOWNLOAD_ACTION_ON_SENT_REPORT_IN_EMAIL_SUCCESS',
  ON_SENT_REPORT_IN_EMAIL_FAILED:
    'DOWNLOAD_ACTION_ON_SENT_REPORT_IN_EMAIL_FAILED'
}

// ANALYTICS FILTER ACTION
export const ANALYTICS_FILTER_ACTION = {
  ON_CHANGE_STATE: 'ANALYTICS_FILTER_ACTION_ON_CHANGE_STATE',
  ON_RESET_STATE: 'ANALYTICS_FILTER_ACTION_ON_RESET_STATE',

  ON_POINTS_BANK_TRANSACTION_TYPE_CHANGE:
    'ANALYTICS_FILTER_ACTION_ON_POINTS_BANK_TRANSACTION_TYPE_CHANGE',

  ON_POINTS_EXCHANGE_TYPE_CHANGE:
    'ANALYTICS_FILTER_ACTION_ON_POINTS_EXCHANGE_TYPE_CHANGE'
}

// ANALYTICS POINTS BANK ACTION
export const ANALYTICS_POINTS_BANK_ACTION = {
  ON_RESET_STATE: 'ANALYTICS_POINTS_BANK_ACTION_ON_RESET_STATE',

  ON_GET_ACTIVE_TOTAL_MEMBERS:
    'ANALYTICS_POINTS_BANK_ACTION_ON_GET_ACTIVE_TOTAL_MEMBERS',
  ON_GET_ACTIVE_TOTAL_MEMBERS_SUCCESS:
    'ANALYTICS_POINTS_BANK_ACTION_ON_GET_ACTIVE_TOTAL_MEMBERS_SUCCESS',
  ON_GET_ACTIVE_TOTAL_MEMBERS_FAILED:
    'ANALYTICS_POINTS_BANK_ACTION_ON_GET_ACTIVE_TOTAL_MEMBERS_FAILED',

  ON_GET_TOKEN_STATS: 'ANALYTICS_POINTS_BANK_ACTION_ON_GET_TOKEN_STATS',
  ON_GET_TOKEN_STATS_SUCCESS:
    'ANALYTICS_POINTS_BANK_ACTION_ON_GET_TOKEN_STATS_SUCCESS',
  ON_GET_TOKEN_STATS_FAILED:
    'ANALYTICS_POINTS_BANK_ACTION_ON_GET_TOKEN_STATS_FAILED'
}

// ANALYTICS POINTS EXCHANGE ACTION
export const ANALYTICS_POINTS_EXCHANGE_ACTION = {
  ON_RESET_STATE: 'ANALYTICS_POINTS_EXCHANGE_ACTION_ON_RESET_STATE',

  ON_GET_EXCHANGE_MEMBERS:
    'ANALYTICS_POINTS_EXCHANGE_ACTION_ON_GET_EXCHANGE_MEMBERS',
  ON_GET_EXCHANGE_MEMBERS_SUCCESS:
    'ANALYTICS_POINTS_EXCHANGE_ACTION_ON_GET_EXCHANGE_MEMBERS_SUCCESS',
  ON_GET_EXCHANGE_MEMBERS_FAILED:
    'ANALYTICS_POINTS_EXCHANGE_ACTION_ON_GET_EXCHANGE_MEMBERS_FAILED',

  ON_GET_TOP_EXCHANGE_PARTNERS:
    'ANALYTICS_POINTS_EXCHANGE_ACTION_ON_GET_TOP_EXCHANGE_PARTNERS',
  ON_GET_TOP_EXCHANGE_PARTNERS_SUCCESS:
    'ANALYTICS_POINTS_EXCHANGE_ACTION_ON_GET_TOP_EXCHANGE_PARTNERS_SUCCESS',
  ON_GET_TOP_EXCHANGE_PARTNERS_FAILED:
    'ANALYTICS_POINTS_EXCHANGE_ACTION_ON_GET_TOP_EXCHANGE_PARTNERS_FAILED'
}

// BILLING ACTION
export const BILLING_ACTION = {
  ON_CHANGE_STATE: 'BILLING_ACTION_ON_CHANGE_STATE',

  ON_START_CHECKOUT: 'BILLING_ACTION_ON_START_CHECKOUT',
  ON_REACTIVATE_SUBSCRIPTION: 'BILLING_ACTION_ON_REACTIVATE_SUBSCRIPTION',

  ON_GENERATE_CHECKOUT_SESSION: 'BILLING_ACTION_ON_GENERATE_CHECKOUT_SESSION',
  ON_GENERATE_CHECKOUT_SESSION_SUCCESS:
    'BILLING_ACTION_ON_GENERATE_CHECKOUT_SESSION_SUCCESS',
  ON_GENERATE_CHECKOUT_SESSION_FAILED:
    'BILLING_ACTION_ON_GENERATE_CHECKOUT_SESSION_FAILED',

  ON_GET_PACKAGE_SUBSCRIPTIONS_SUCCESS:
    'BILLING_ACTION_ON_GET_PACKAGE_SUBSCRIPTIONS_SUCCESS',
  ON_GET_PACKAGE_SUBSCRIPTIONS_FAILED:
    'BILLING_ACTION_ON_GET_PACKAGE_SUBSCRIPTIONS_FAILED',

  ON_GENERATE_VIEW_BILLING_SESSION:
    'BILLING_ACTION_ON_GENERATE_VIEW_BILLING_SESSION',
  ON_GENERATE_VIEW_BILLING_SESSION_SUCCESS:
    'BILLING_ACTION_ON_GENERATE_VIEW_BILLING_SESSION_SUCCESS',
  ON_GENERATE_VIEW_BILLING_SESSION_FAILED:
    'BILLING_ACTION_ON_GENERATE_VIEW_BILLING_SESSION_FAILED',

  ON_UPDATE_PACKAGE_SUBSCRIPTION_SUCCESS:
    'BILLING_ACTION_ON_UPDATE_PACKAGE_SUBSCRIPTION_SUCCESS',
  ON_UPDATE_PACKAGE_SUBSCRIPTION_FAILED:
    'BILLING_ACTION_ON_UPDATE_PACKAGE_SUBSCRIPTION_FAILED',

  ON_GET_EXCHANGE_PERFORMANCE: 'BILLING_ACTION_ON_GET_EXCHANGE_PERFORMANCE',
  ON_GET_EXCHANGE_PERFORMANCE_SUCCESS:
    'BILLING_ACTION_ON_GET_EXCHANGE_PERFORMANCE_SUCCESS',
  ON_GET_EXCHANGE_PERFORMANCE_FAILED:
    'BILLING_ACTION_ON_GET_EXCHANGE_PERFORMANCE_FAILED'
}

// ACCOUNT ACTION
export const ACCOUNT_ACTION = {
  CHANGE_STATE: 'ACCOUNT_ACTION_CHANGE_STATE',
  RESET_STATE: 'ACCOUNT_ACTION_RESET_STATE',
  CHANGE_INPUT_AND_ERROR_STATE: 'ACCOUNT_ACTION_CHANGE_INPUT_AND_ERROR_STATE',

  GET_ACCOUNT_DETAILS: 'ACCOUNT_ACTION_GET_ACCOUNT_DETAILS',
  ON_GET_ACCOUNT_DETAILS_SUCCESS:
    'ACCOUNT_ACTION_ON_GET_ACCOUNT_DETAILS_SUCCESS',
  ON_GET_ACCOUNT_DETAILS_FAILED: 'ACCOUNT_ACTION_ON_GET_ACCOUNT_DETAILS_FAILED',

  ON_CREATE_ACCOUNT_SUCCESS: 'ACCOUNT_ACTION_ON_CREATE_ACCOUNT_SUCCESS',
  ON_CREATE_ACCOUNT_FAILED: 'ACCOUNT_ACTION_ON_CREATE_ACCOUNT_FAILED',

  ON_UPDATE_USER_ACCOUNT: 'ACCOUNT_ACTION_ON_UPDATE_USER_ACCOUNT',
  ON_UPDATE_USER_ACCOUNT_SUCCESS:
    'ACCOUNT_ACTION_ON_UPDATE_USER_ACCOUNT_SUCCESS',
  ON_UPDATE_USER_ACCOUNT_FAILED: 'ACCOUNT_ACTION_ON_UPDATE_USER_ACCOUNT_FAILED'
}

// TOKEN ACTIONS
export const TOKEN_ACTIONS = {
  ON_RESET_STATE: 'TOKEN_ACTIONS_ON_RESET_STATE',
  ON_CHANGE_STATE: 'TOKEN_ACTIONS_ON_CHANGE_STATE',

  ON_UPDATE_TOKEN: 'TOKEN_ACTIONS_ON_UPDATE_TOKEN',
  ON_UPDATE_TOKEN_SUCCESS: 'TOKEN_ACTIONS_ON_UPDATE_TOKEN_SUCCESS',
  ON_UPDATE_TOKEN_FAILED: 'TOKEN_ACTIONS_ON_UPDATE_TOKEN_FAILED',

  ON_CREATE_TOKEN: 'TOKEN_ACTIONS_ON_CREATE_TOKEN',
  ON_CREATE_TOKEN_SUCCESS: 'TOKEN_ACTIONS_ON_CREATE_TOKEN_SUCCESS',
  ON_CREATE_TOKEN_FAILED: 'TOKEN_ACTIONS_ON_CREATE_TOKEN_FAILED',

  ON_GET_TOKENS: 'TOKEN_ACTIONS_ON_GET_TOKENS',
  ON_GET_TOKENS_SUCCESS: 'TOKEN_ACTIONS_ON_GET_TOKENS_SUCCESS',
  ON_GET_TOKENS_FAILED: 'TOKEN_ACTIONS_ON_GET_TOKENS_FAILED',

  ON_GET_TOKEN_BY_ID: 'TOKEN_ACTIONS_ON_GET_TOKEN_BY_ID',
  ON_GET_TOKEN_BY_ID_SUCCESS: 'TOKEN_ACTIONS_ON_GET_TOKEN_BY_ID_SUCCESS',
  ON_GET_TOKEN_BY_ID_FAILED: 'TOKEN_ACTIONS_ON_GET_TOKEN_BY_ID_FAILED',

  ON_GET_TOKEN_STATS_SUCCESS: 'TOKEN_ACTIONS_ON_GET_TOKEN_STATS_SUCCESS',
  ON_GET_TOKEN_STATS_FAILED: 'TOKEN_ACTIONS_ON_GET_TOKEN_STATS_FAILED',
  ON_RESET_TOKEN_STATS: 'TOKEN_ACTIONS_ON_RESET_TOKEN_STATS',

  ON_GET_TOKEN_STATS_FOR_LAST_AND_CURRENT_WEEK:
    'TOKEN_ACTIONS_ON_GET_TOKEN_STATS_FOR_LAST_AND_CURRENT_WEEK',
  ON_GET_TOKEN_STATS_FOR_LAST_AND_CURRENT_WEEK_SUCCESS:
    'TOKEN_ACTIONS_ON_GET_TOKEN_STATS_FOR_LAST_AND_CURRENT_WEEK_SUCCESS',
  ON_GET_TOKEN_STATS_FOR_LAST_AND_CURRENT_WEEK_FAILED:
    'TOKEN_ACTIONS_ON_GET_TOKEN_STATS_FOR_LAST_AND_CURRENT_WEEK_FAILED',

  ON_SELECT_TOKEN: 'TOKEN_ACTIONS_ON_SELECT_TOKEN',
  ON_UPDATED_TOKEN_ID_IN_LOCAL_STORAGE:
    'TOKEN_ACTIONS_ON_UPDATED_TOKEN_ID_IN_LOCAL_STORAGE'
}

// TRANSACTIONS
export const TRANSACTION_ACTIONS = {
  ON_GET_TRANSACTIONS: 'TRANSACTION_ACTIONS_ON_GET_TRANSACTIONS',
  ON_GET_TRANSACTIONS_SUCCESS:
    'TRANSACTION_ACTIONS_ON_GET_TRANSACTIONS_SUCCESS',
  ON_GET_TRANSACTIONS_FAILED: 'TRANSACTION_ACTIONS_ON_GET_TRANSACTIONS_FAILED',

  ON_GET_EXCHANGE_ACTIVITY_CHART:
    'TRANSACTION_ACTIONS_ON_GET_EXCHANGE_ACTIVITY_CHART',
  ON_GET_EXCHANGE_ACTIVITY_CHART_SUCCESS:
    'TRANSACTION_ACTIONS_ON_GET_EXCHANGE_ACTIVITY_CHART_SUCCESS',
  ON_GET_EXCHANGE_ACTIVITY_CHART_FAILED:
    'TRANSACTION_ACTIONS_ON_GET_EXCHANGE_ACTIVITY_CHART_FAILED',

  ON_GET_TRANSACTIONS_CHART: 'TRANSACTION_ACTIONS_ON_GET_TRANSACTIONS_CHART',
  ON_GET_TRANSACTIONS_CHART_SUCCESS:
    'TRANSACTION_ACTIONS_ON_GET_TRANSACTIONS_CHART_SUCCESS',
  ON_GET_TRANSACTIONS_CHART_FAILED:
    'TRANSACTION_ACTIONS_ON_GET_TRANSACTIONS_CHART_FAILED',

  ON_RESET_STATE: 'TRANSACTION_ACTIONS_ON_RESET_STATE',
  ON_RESET_CHART_STATE: 'TRANSACTION_ACTIONS_ON_RESET_CHART_STATE',

  ON_GET_LIABILITY_TREND_CHART:
    'TRANSACTION_ACTIONS_ON_GET_LIABILITY_TREND_CHART',
  ON_GET_LIABILITY_TREND_CHART_SUCCESS:
    'TRANSACTION_ACTIONS_ON_GET_LIABILITY_TREND_CHART_SUCCESS',
  ON_GET_LIABILITY_TREND_CHART_FAILED:
    'TRANSACTION_ACTIONS_ON_GET_LIABILITY_TREND_CHART_FAILED'
}

// EXCHANGE TRANSACTIONS
export const EXCHANGE_TRANSACTION_ACTIONS = {
  ON_RESET_STATE: 'EXCHANGE_TRANSACTION_ACTIONS_ON_RESET_STATE',
  ON_RESET_CHART_STATE: 'EXCHANGE_TRANSACTION_ACTIONS_ON_RESET_CHART_STATE'
}

export const EXCHANGE_TERMS_ACTION = {
  RESET_STATE: 'EXCHANGE_TERMS_ACTION_RESET_STATE',
  CHANGE_STATE: 'EXCHANGE_TERMS_ACTION_CHANGE_STATE',
  CHANGE_FORM_DATA_STATE: 'EXCHANGE_TERMS_ACTION_CHANGE_FORM_DATA_STATE',

  ON_SUBMIT_TERMS: 'EXCHANGE_TERMS_ACTION_ON_SUBMIT_TERMS',
  ON_SUBMIT_TERMS_SUCCESS: 'EXCHANGE_TERMS_ACTION_ON_SUBMIT_TERMS_SUCCESS',
  ON_SUBMIT_TERMS_FAILED: 'EXCHANGE_TERMS_ACTION_ON_SUBMIT_TERMS_FAILED'
}

// TRANSACTIONS DOWNLOAD
export const TRANSACTION_DOWNLOAD_ACTIONS = {
  ON_RESET_STATE: 'TRANSACTION_DOWNLOAD_ACTIONS_ON_RESET_STATE',

  ON_GET_TRANSACTIONS_FOR_DOWNLOAD:
    'TRANSACTION_DOWNLOAD_ACTIONS_ON_GET_TRANSACTIONS_FOR_DOWNLOAD',
  ON_GET_TRANSACTIONS_FOR_DOWNLOAD_SUCCESS:
    'TRANSACTION_DOWNLOAD_ACTIONS_ON_GET_TRANSACTIONS_FOR_DOWNLOAD_SUCCESS',
  ON_GET_TRANSACTIONS_FOR_DOWNLOAD_FAILED:
    'TRANSACTION_DOWNLOAD_ACTIONS_ON_GET_TRANSACTIONS_FOR_DOWNLOAD_FAILED',

  ON_SEND_REPORT_IN_EMAIL:
    'TRANSACTION_DOWNLOAD_ACTIONS_ON_SEND_REPORT_IN_EMAIL'
}

// MEMBERS
export const MEMBER_ACTIONS = {
  ON_GET_MEMBERS: 'MEMBER_ACTIONS_ON_GET_MEMBERS',
  ON_GET_MEMBERS_SUCCESS: 'MEMBER_ACTIONS_ON_GET_MEMBERS_SUCCESS',
  ON_GET_MEMBERS_FAILED: 'MEMBER_ACTIONS_ON_GET_MEMBERS_FAILED',

  ON_RESET_STATE: 'MEMBER_ACTIONS_ON_RESET_STATE',

  ON_GET_RECENT_TRANSACTIONS: 'MEMBER_ACTIONS_ON_GET_RECENT_TRANSACTIONS',

  ON_GET_RECENT_TRANSACTIONS_SUCCESS:
    'MEMBER_ACTIONS_ON_GET_RECENT_TRANSACTIONS_SUCCESS',
  ON_GET_RECENT_TRANSACTIONS_FAILED:
    'MEMBER_ACTIONS_ON_GET_RECENT_TRANSACTIONS_FAILED'
}

// MEMBERS DOWNLOAD
export const MEMBER_DOWNLOAD_ACTIONS = {
  ON_RESET_STATE: 'MEMBER_DOWNLOAD_ACTIONS_ON_RESET_STATE',

  ON_GET_MEMBERS_FOR_DOWNLOAD:
    'MEMBER_DOWNLOAD_ACTIONS_ON_GET_MEMBERS_FOR_DOWNLOAD',
  ON_GET_MEMBERS_FOR_DOWNLOAD_SUCCESS:
    'MEMBER_DOWNLOAD_ACTIONS_ON_GET_MEMBERS_FOR_DOWNLOAD_SUCCESS',
  ON_GET_MEMBERS_FOR_DOWNLOAD_FAILED:
    'MEMBER_DOWNLOAD_ACTIONS_ON_GET_MEMBERS_FOR_DOWNLOAD_FAILED',

  ON_SEND_REPORT_IN_EMAIL: 'MEMBER_DOWNLOAD_ACTIONS_ON_SEND_REPORT_IN_EMAIL'
}

// MEMBER TRANSACTION
export const MEMBER_TRANSACTION_ACTIONS = {
  ON_CHANGE_STATE: 'MEMBER_TRANSACTION_ACTIONS_ON_CHANGE_STATE',
  ON_RESET_STATE: 'MEMBER_TRANSACTION_ACTIONS_ON_RESET_STATE',

  ON_CREDIT_DEBIT_POINTS: 'MEMBER_TRANSACTION_ACTIONS_ON_CREDIT_DEBIT_POINTS',
  ON_CREDIT_DEBIT_POINTS_SUCCESS:
    'MEMBER_TRANSACTION_ACTIONS_ON_CREDIT_DEBIT_POINTS_SUCCESS',
  ON_CREDIT_DEBIT_POINTS_FAILED:
    'MEMBER_TRANSACTION_ACTIONS_ON_CREDIT_DEBIT_POINTS_FAILED',

  ON_GET_BALANCES: 'MEMBER_TRANSACTION_ACTIONS_ON_GET_BALANCES',
  ON_GET_BALANCES_SUCCESS: 'MEMBER_TRANSACTION_ACTIONS_ON_GET_BALANCES_SUCCESS',
  ON_GET_BALANCES_FAILED: 'MEMBER_TRANSACTION_ACTIONS_ON_GET_BALANCES_FAILED'
}

export const REWARD_MEMBER_ACTION = {
  ON_CHANGE_STATE: 'REWARD_MEMBER_ACTION_ON_CHANGE_STATE',
  ON_RESET_STATE: 'REWARD_MEMBER_ACTION_ON_RESET_STATE',

  ON_CREDIT_POINTS_BANK: 'REWARD_MEMBER_ACTION_ON_CREDIT_POINTS_BANK',
  ON_CREDIT_POINTS_BANK_SUCCESS:
    'REWARD_MEMBER_ACTION_ON_CREDIT_POINTS_BANK_SUCCESS',
  ON_CREDIT_POINTS_BANK_FAILED:
    'REWARD_MEMBER_ACTION_ON_CREDIT_POINTS_BANK_FAILED',

  ON_CREDIT_POINTS_EXCHANGE: 'REWARD_MEMBER_ACTION_ON_CREDIT_POINTS_EXCHANGE',
  ON_CREDIT_POINTS_EXCHANGE_SUCCESS:
    'REWARD_MEMBER_ACTION_ON_CREDIT_POINTS_EXCHANGE_SUCCESS',
  ON_CREDIT_POINTS_EXCHANGE_FAILED:
    'REWARD_MEMBER_ACTION_ON_CREDIT_POINTS_EXCHANGE_FAILED'
}

// MEMBER SEARCH
export const MEMBER_SEARCH_ACTIONS = {
  ON_CHANGE_STATE: 'MEMBER_SEARCH_ACTIONS_ON_CHANGE_STATE',
  ON_RESET_STATE: 'MEMBER_SEARCH_ACTIONS_ON_RESET_STATE',

  ON_SEARCH: 'MEMBER_SEARCH_ACTIONS_ON_SEARCH',
  ON_SEARCH_SUCCESS: 'MEMBER_SEARCH_ACTIONS_ON_SEARCH_SUCCESS',
  ON_SEARCH_FAILED: 'MEMBER_SEARCH_ACTIONS_ON_SEARCH_FAILED'
}

// DASHBOARD
export const DASHBOARD_ACTIONS = {
  ON_CHANGE_DASHBOARD_SETUP_STATE:
    'DASHBOARD_ACTIONS_ON_CHANGE_DASHBOARD_SETUP_STATE',
  CHANGE_STATE: 'DASHBOARD_ACTIONS_CHANGE_STATE',

  ON_MILESTONE_ANIMATION_COMPLETE:
    'DASHBOARD_ACTIONS_ON_MILESTONE_ANIMATION_COMPLETE',
  ON_MILESTONE_ANIMATION_COMPLETE_SUCCESS:
    'DASHBOARD_ACTIONS_ON_MILESTONE_ANIMATION_COMPLETE_SUCCESS'
}

// BRAND
export const BRAND_ACTIONS = {
  ON_RESET_STATE: 'BRAND_ACTIONS_ON_RESET_STATE',
  ON_CHANGE_BRAND_STATE: 'BRAND_ACTIONS_ON_CHANGE_BRAND_STATE',
  ON_RESET_BRAND_STATE: 'BRAND_ACTIONS_ON_RESET_BRAND_STATE',

  ON_CREATE_BRAND: 'BRAND_ACTIONS_ON_CREATE_BRAND',
  ON_CREATE_BRAND_SUCCESS: 'BRAND_ACTIONS_ON_CREATE_BRAND_SUCCESS',
  ON_CREATE_BRAND_FAILED: 'BRAND_ACTIONS_ON_CREATE_BRAND_FAILED',

  ON_INIT_UPDATE_BRAND: 'BRAND_ACTIONS_ON_INIT_UPDATE_BRAND',

  ON_UPDATE_BRAND: 'BRAND_ACTIONS_ON_UPDATE_BRAND',
  ON_UPDATE_BRAND_SUCCESS: 'BRAND_ACTIONS_ON_UPDATE_BRAND_SUCCESS',
  ON_UPDATE_BRAND_FAILED: 'BRAND_ACTIONS_ON_UPDATE_BRAND_FAILED',

  ON_UPDATE_BRAND_CONFIG: 'BRAND_ACTIONS_ON_UPDATE_BRAND_CONFIG',
  ON_UPDATE_BRAND_CONFIG_SUCCESS:
    'BRAND_ACTIONS_ON_UPDATE_BRAND_CONFIG_SUCCESS',
  ON_UPDATE_BRAND_CONFIG_FAILED: 'BRAND_ACTIONS_ON_UPDATE_BRAND_CONFIG_FAILED',

  ON_SWITCH_BRAND_PROFILE: 'BRAND_ACTIONS_ON_SWITCH_BRAND_PROFILE'
}

// FILE UPLOAD
export const FILE_UPLOAD_ACTIONS = {
  ON_GET_PRESIGNED_URL: 'FILE_UPLOAD_ACTIONS_ON_GET_PRESIGNED_URL',
  ON_GET_PRESIGNED_URL_SUCCESS:
    'FILE_UPLOAD_ACTIONS_ON_GET_PRESIGNED_URL_SUCCESS',
  ON_GET_PRESIGNED_URL_FAILED:
    'FILE_UPLOAD_ACTIONS_ON_GET_PRESIGNED_URL_FAILED',

  ON_UPLOAD_FILE: 'FILE_UPLOAD_ACTIONS_ON_UPLOAD_FILE',
  ON_UPLOAD_FILE_SUCCESS: 'FILE_UPLOAD_ACTIONS_ON_UPLOAD_FILE_SUCCESS',
  ON_UPLOAD_FILE_FAILED: 'FILE_UPLOAD_ACTIONS_ON_UPLOAD_FILE_FAILED'
}

// EXCHANGE SETTINGS
export const EXCHANGE_SETTINGS_ACTION = {
  CHANGE_STATE: 'EXCHANGE_SETTINGS_ACTION_CHANGE_STATE',

  ON_SAVE_SETTINGS: 'EXCHANGE_SETTINGS_ACTION_ON_SAVE_SETTINGS',
  ON_SAVE_SETTINGS_SUCCESS: 'EXCHANGE_SETTINGS_ACTION_ON_SAVE_SETTINGS_SUCCESS',
  ON_SAVE_SETTINGS_FAILED: 'EXCHANGE_SETTINGS_ACTION_ON_SAVE_SETTINGS_FAILED'
}

export const EXCHANGE_FEEDBACK_ACTION = {
  CHANGE_STATE: 'EXCHANGE_FEEDBACK_ACTION_CHANGE_STATE',
  ON_RESET_STATE: 'EXCHANGE_FEEDBACK_ACTION_ON_RESET_STATE'
}

export const EXCHANGE_CONFIG_ACTION = {
  CHANGE_STATE: 'EXCHANGE_CONFIG_ACTION_CHANGE_STATE',
  ON_RESET_STATE: 'EXCHANGE_CONFIG_ACTION_ON_RESET_STATE'
}

export const EXCHANGE_PARTNER_ACTION = {
  ON_RESET_FILTERS: 'EXCHANGE_PARTNER_ACTION_ON_RESET_FILTERS',

  CHANGE_STATE: 'EXCHANGE_PARTNER_ACTION_CHANGE_STATE',

  ON_GET_PARTNERS: 'EXCHANGE_ACTION_ON_GET_PARTNERS',
  ON_GET_PARTNERS_SUCCESS: 'EXCHANGE_ACTION_ON_GET_PARTNERS_SUCCESS',
  ON_GET_PARTNERS_FAILED: 'EXCHANGE_ACTION_ON_GET_PARTNERS_FAILED',

  ON_GET_WHITELIST: 'EXCHANGE_ACTION_ON_GET_WHITELIST',
  ON_GET_WHITELIST_SUCCESS: 'EXCHANGE_ACTION_ON_GET_WHITELIST_SUCCESS',
  ON_GET_WHITELIST_FAILED: 'EXCHANGE_ACTION_ON_GET_WHITELIST_FAILED',

  ON_CONNECT_EXCHANGE: 'EXCHANGE_ACTION_ON_CONNECT_EXCHANGE',
  ON_CONNECT_EXCHANGE_SUCCESS: 'EXCHANGE_ACTION_ON_CONNECT_EXCHANGE_SUCCESS',
  ON_CONNECT_EXCHANGE_FAILED: 'EXCHANGE_ACTION_ON_CONNECT_EXCHANGE_FAILED',

  ON_BULK_CONNECT_EXCHANGE: 'EXCHANGE_ACTION_ON_BULK_CONNECT_EXCHANGE',
  ON_BULK_CONNECT_EXCHANGE_SUCCESS:
    'EXCHANGE_ACTION_ON_BULK_CONNECT_EXCHANGE_SUCCESS',
  ON_BULK_CONNECT_EXCHANGE_FAILED:
    'EXCHANGE_ACTION_ON_BULK_CONNECT_EXCHANGE_FAILED',

  ON_UPDATE_EXCHANGE_CONNECTION:
    'EXCHANGE_ACTION_ON_UPDATE_EXCHANGE_CONNECTION',
  ON_UPDATE_EXCHANGE_CONNECTION_SUCCESS:
    'EXCHANGE_ACTION_ON_UPDATE_EXCHANGE_CONNECTION_SUCCESS',
  ON_UPDATE_EXCHANGE_CONNECTION_FAILED:
    'EXCHANGE_ACTION_ON_UPDATE_EXCHANGE_CONNECTION_FAILED',

  ON_GET_ALL_PARTNERS_SUCCESS: 'EXCHANGE_ACTION_ON_GET_ALL_PARTNERS_SUCCESS',
  ON_GET_ALL_PARTNERS_FAILED: 'EXCHANGE_ACTION_ON_GET_ALL_PARTNERS_FAILED'
}

export const EXCHANGE_NFT_ACTION = {
  CHANGE_STATE: 'EXCHANGE_NFT_ACTION_CHANGE_STATE',

  ON_BUY: 'EXCHANGE_NFT_ACTION_ON_BUY',
  ON_BUY_SUCCESS: 'EXCHANGE_NFT_ACTION_ON_BUY_SUCCESS',
  ON_BUY_FAILED: 'EXCHANGE_NFT_ACTION_ON_BUY_FAILED'
}

// NFTS
export const NFTS_PROFILE_ACTIONS = {
  CHANGE_STATE: 'NFTS_PROFILE_ACTIONS_CHANGE_STATE',
  RESET_STATE: 'NFTS_PROFILE_ACTIONS_RESET_STATE',

  ON_CREATE_NFT: 'NFTS_PROFILE_ACTIONS_ON_CREATE_NFT',
  ON_CREATE_NFT_SUCCESS: 'NFTS_PROFILE_ACTIONS_ON_CREATE_NFT_SUCCESS',
  ON_CREATE_NFT_FAILED: 'NFTS_PROFILE_ACTIONS_ON_CREATE_NFT_FAILED',

  ON_UPLOAD_FILES_SUCCESS: 'NFTS_PROFILE_ACTIONS_ON_UPLOAD_FILES_SUCCESS',
  ON_UPLOAD_FILES_FAILED: 'NFTS_PROFILE_ACTIONS_ON_UPLOAD_FILES_FAILED',

  ON_UPDATE_NFT: 'NFTS_PROFILE_ACTIONS_ON_UPDATE_NFT',
  ON_UPDATE_NFT_SUCCESS: 'NFTS_PROFILE_ACTIONS_ON_UPDATE_NFT_SUCCESS',
  ON_UPDATE_NFT_FAILED: 'NFTS_PROFILE_ACTIONS_ON_UPDATE_NFT_FAILED',

  ON_TRANSFER_NFT: 'NFTS_PROFILE_ACTIONS_ON_TRANSFER_NFT',
  ON_TRANSFER_NFT_SUCCESS: 'NFTS_PROFILE_ACTIONS_ON_TRANSFER_NFT_SUCCESS',
  ON_TRANSFER_NFT_FAILED: 'NFTS_PROFILE_ACTIONS_ON_TRANSFER_NFT_FAILED',

  ON_GET_NFTS: 'NFTS_PROFILE_ACTIONS_ON_GET_NFTS',
  ON_GET_NFTS_SUCCESS: 'NFTS_PROFILE_ACTIONS_ON_GET_NFTS_SUCCESS',
  ON_GET_NFTS_FAILED: 'NFTS_PROFILE_ACTIONS_ON_GET_NFTS_FAILED',

  ON_GET_NFT_USERS: 'NFTS_PROFILE_ACTIONS_ON_GET_NFT_USERS',
  ON_GET_NFT_USERS_SUCCESS: 'NFTS_PROFILE_ACTIONS_ON_GET_NFT_USERS_SUCCESS',
  ON_GET_NFT_USERS_FAILED: 'NFTS_PROFILE_ACTIONS_ON_GET_NFT_USERS_FAILED',

  ON_REDEEM_EXCLUSIVE_CONTENT:
    'NFTS_PROFILE_ACTIONS_ON_REDEEM_EXCLUSIVE_CONTENT',
  ON_REDEEM_EXCLUSIVE_CONTENT_SUCCESS:
    'NFTS_PROFILE_ACTIONS_ON_REDEEM_EXCLUSIVE_CONTENT_SUCCESS',
  ON_REDEEM_EXCLUSIVE_CONTENT_FAILED:
    'NFTS_PROFILE_ACTIONS_ON_REDEEM_EXCLUSIVE_CONTENT_FAILED',

  ON_SELECT: 'NFTS_PROFILE_ACTIONS_ON_SELECT',
  ON_RESET_LIST: 'NFTS_PROFILE_ACTIONS_ON_RESET_LIST'
}

// SURVEY
export const SURVEY_ACTION = {
  CHANGE_STATE: 'SURVEY_ACTION_CHANGE_STATE',

  ON_SUBMIT_USER_SURVEY: 'SURVEY_ACTION_ON_SUBMIT_USER_SURVEY',
  ON_SUBMIT_USER_SURVEY_SUCCESS: 'SURVEY_ACTION_ON_SUBMIT_USER_SURVEY_SUCCESS',
  ON_SUBMIT_USER_SURVEY_FAILED: 'SURVEY_ACTION_ON_SUBMIT_USER_SURVEY_FAILED',

  ON_GET_USER_SURVEYS: 'SURVEY_ACTION_ON_GET_USER_SURVEYS',
  ON_GET_USER_SURVEYS_SUCCESS: 'SURVEY_ACTION_ON_GET_USER_SURVEYS_SUCCESS',
  ON_GET_USER_SURVEYS_FAILED: 'SURVEY_ACTION_ON_GET_USER_SURVEYS_FAILED',

  ON_GET_BRAND_SURVEYS: 'SURVEY_ACTION_ON_GET_BRAND_SURVEYS',
  ON_GET_BRAND_SURVEYS_SUCCESS: 'SURVEY_ACTION_ON_GET_BRAND_SURVEYS_SUCCESS',
  ON_GET_BRAND_SURVEYS_FAILED: 'SURVEY_ACTION_ON_GET_BRAND_SURVEYS_FAILED',

  ON_GET_LIST_USER_SURVEYS: 'SURVEY_ACTION_ON_GET_LIST_USER_SURVEYS',
  ON_GET_LIST_USER_SURVEYS_SUCCESS:
    'SURVEY_ACTION_ON_GET_LIST_USER_SURVEYS_SUCCESS',
  ON_GET_LIST_USER_SURVEYS_FAILED:
    'SURVEY_ACTION_ON_GET_LIST_USER_SURVEYS_FAILED',

  ON_UPDATE_USER_SURVEY: 'SURVEY_ACTION_ON_UPDATE_USER_SURVEY',
  ON_UPDATE_USER_SURVEY_SUCCESS: 'SURVEY_ACTION_ON_UPDATE_USER_SURVEY_SUCCESS',
  ON_UPDATE_USER_SURVEY_FAILED: 'SURVEY_ACTION_ON_UPDATE_USER_SURVEY_FAILED'
}

// USER SURVEY
export const USER_SURVEY_ACTION = {
  ON_GET_SURVEY_ANALYTICS_SUCCESS:
    'USER_SURVEY_ACTION_ON_GET_SURVEY_ANALYTICS_SUCCESS',
  ON_GET_SURVEY_ANALYTICS_FAILED:
    'USER_SURVEY_ACTION_ON_GET_SURVEY_ANALYTICS_FAILED'
}

// WELCOME
export const WELCOME_ACTION = {
  ON_GET_WELCOME_STATE: 'WELCOME_ACTION_ON_GET_WELCOME_STATE',
  ON_GET_WELCOME_STATE_SUCCESS: 'WELCOME_ACTION_ON_GET_WELCOME_STATE_SUCCESS',

  ON_UPDATE_WELCOME_STATE: 'WELCOME_ACTION_ON_UPDATE_WELCOME_STATE',
  ON_UPDATE_WELCOME_STATE_SUCCESS:
    'WELCOME_ACTION_ON_UPDATE_WELCOME_STATE_SUCCESS'
}

export const ADMIN_ACTION = {
  ON_GET_BRAND_AND_TOKEN_IDS: 'ADMIN_ACTION_ON_GET_BRAND_AND_TOKEN_IDS',
  ON_GET_BRAND_AND_TOKEN_IDS_SUCCESS:
    'ADMIN_ACTION_ON_GET_BRAND_AND_TOKEN_IDS_SUCCESS',

  ON_GET_TEAM_MEMBERS: 'ADMIN_ACTION_ON_GET_TEAM_MEMBERS',
  ON_GET_TEAM_MEMBERS_SUCCESS: 'ADMIN_ACTION_ON_GET_TEAM_MEMBERS_SUCCESS',
  ON_GET_TEAM_MEMBERS_FAILED: 'ADMIN_ACTION_ON_GET_TEAM_MEMBERS_FAILED',

  ON_ADD_TEAM_MEMBER: 'ADMIN_ACTION_ON_ADD_TEAM_MEMBER',
  ON_ADD_TEAM_MEMBER_SUCCESS: 'ADMIN_ACTION_ON_ADD_TEAM_MEMBER_SUCCESS',
  ON_ADD_TEAM_MEMBER_FAILED: 'ADMIN_ACTION_ON_ADD_TEAM_MEMBER_FAILED',

  ON_REMOVE_TEAM_MEMBER: 'ADMIN_ACTION_ON_REMOVE_TEAM_MEMBER',
  ON_REMOVE_TEAM_MEMBER_SUCCESS: 'ADMIN_ACTION_ON_REMOVE_TEAM_MEMBER_SUCCESS',
  ON_REMOVE_TEAM_MEMBER_FAILED: 'ADMIN_ACTION_ON_REMOVE_TEAM_MEMBER_FAILED',

  CHANGE_INPUT_AND_ERROR_STATE: 'ADMIN_ACTION_CHANGE_INPUT_AND_ERROR_STATE',
  ON_RESET_STATE: 'ADMIN_ACTION_ON_RESET_STATE',
  CHANGE_STATE: 'ADMIN_ACTION_CHANGE_STATE'
}

// API KEYS
export const API_KEY_ACTIONS = {
  ON_RESET_STATE: 'API_KEY_ACTIONS_ON_RESET_STATE',
  ON_CHANGE_STATE: 'API_KEY_ACTIONS_ON_CHANGE_STATE',

  ON_GET_API_KEYS_SUCCESS: 'API_KEY_ACTIONS_ON_GET_API_KEYS_SUCCESS',
  ON_GET_API_KEYS_FAILED: 'API_KEY_ACTIONS_ON_GET_API_KEYS_FAILED',

  ON_CREATE_API_KEY: 'API_KEY_ACTIONS_ON_CREATE_API_KEY',
  ON_CREATE_API_KEY_SUCCESS: 'API_KEY_ACTIONS_ON_CREATE_API_KEY_SUCCESS',
  ON_CREATE_API_KEY_FAILED: 'API_KEY_ACTIONS_ON_CREATE_API_KEY_FAILED',

  ON_DELETE_API_KEY: 'API_KEY_ACTIONS_ON_DELETE_API_KEY',
  ON_DELETE_API_KEY_SUCCESS: 'API_KEY_ACTIONS_ON_DELETE_API_KEY_SUCCESS',
  ON_DELETE_API_KEY_FAILED: 'API_KEY_ACTIONS_ON_DELETE_API_KEY_FAILED'
}

// CANNY REQUEST FEATURE
export const REQUEST_FEATURE_ACTIONS = {
  ON_GET_SSO_TOKEN_SUCCESS: 'REQUEST_FEATURE_ACTIONS_ON_GET_SSO_TOKEN_SUCCESS',
  ON_GET_SSO_TOKEN_FAILED: 'REQUEST_FEATURE_ACTIONS_ON_GET_SSO_TOKEN_FAILED'
}

// EXTENSIONS ACTION
export const EXTENSIONS_ACTION = {
  CHANGE_TOOL_FILTER_STATE: 'EXTENSIONS_ACTION_CHANGE_TOOL_FILTER_STATE',
  CHANGE_STATE: 'EXTENSIONS_ACTION_CHANGE_STATE'
}

// LOVE BRANDS FILTERS ACTION
export const LOVE_BRANDS_FILTERS_ACTION = {
  CHANGE_STATE: 'LOVE_BRANDS_FILTERS_ACTION_CHANGE_STATE'
}

// TRANSACTION STATUS CHANGE FOR FUTURE POINTS
export const TXN_STATUS_ACTION = {
  ON_CHANGE_STATE: 'TXN_STATUS_ACTION_ON_CHANGE_STATE',
  ON_RESET_STATE: 'TXN_STATUS_ACTION_ON_RESET_STATE',

  ON_CHANGE_STATUS: 'TXN_STATUS_ACTION_ON_CHANGE_STATUS',
  ON_CHANGE_STATUS_SUCCESS: 'TXN_STATUS_ACTION_ON_CHANGE_STATUS_SUCCESS',
  ON_CHANGE_STATUS_FAILED: 'TXN_STATUS_ACTION_ON_CHANGE_STATUS_FAILED'
}

// REFUND MILES
export const TXN_REFUND_MILES_ACTION = {
  ON_RESET_STATE: 'TXN_REFUND_MILES_ACTION_ON_RESET_STATE',

  ON_REFUND: 'TXN_REFUND_MILES_ACTION_ON_REFUND',
  ON_REFUND_SUCCESS: 'TXN_REFUND_MILES_ACTION_ON_REFUND_SUCCESS',
  ON_REFUND_FAILED: 'TXN_REFUND_MILES_ACTION_ON_REFUND_FAILED'
}

// TXN COLUMNS
export const TXN_COLUMNS_ACTION = {
  ON_SELECT: 'TXN_COLUMNS_ACTION_ON_SELECT',
  ON_SELECTED: 'TXN_COLUMNS_ACTION_ON_SELECTED'
}

// TXN SEARCH
export const TXN_SEARCH_ACTIONS = {
  ON_CHANGE_STATE: 'TXN_SEARCH_ACTIONS_ON_CHANGE_STATE',
  ON_RESET_STATE: 'TXN_SEARCH_ACTIONS_ON_RESET_STATE'
}
