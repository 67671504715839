export const APP_SIGNAL_NAMESPACE = 'LAD'
export const APP_TITLE = 'qiibee LAD'
export const S3_BASE_URL = 'https://lad-files.qiibee.com/'

export const NUMBER_OF_RECORDS_PER_PAGE = 10
export const MEMBER_RECENT_TRANSACTION = 5
export const MAX_NUMBER_OF_RECORDS_FOR_DOWNLOAD = 100
export const MAX_NUMBER_OF_TRANSACTIONS_FOR_DOWNLOAD = 300
export const ALLOW_MAX_RECORDS_FOR_DOWNLOAD = 1000
export const MAX_API_KEYS_ALLOW = 10
export const POP = 'POP'

export const PAGE_NUMBER_OPTIONS = [NUMBER_OF_RECORDS_PER_PAGE, 20, 30]

export const DEFAULT_DEBOUNCE_TIME = 1000

export const QB_PRODUCTION = 'production'
export const QB_TESTING = 'testing'
export const QB_DEVELOPMENT = 'development'
export const QB_SANDBOX = 'sandbox'

export const QB_DEFAULT_SORTING_VALUE = 'ranking'
export const QB_DEFAULT_TYPE_VALUE = 'all'
export const QB_DEFAULT_MEMBERS_VALUE = 'ALL'
export const QB_DEFAULT_REGION_VALUE = 'Worldwide'
export const QB_DEFAULT_COUNTRY_VALUE = 'AAA'

export const QB_REWARD_POINT = {
  NAME: 'LAD Credits',
  SYMBOL: 'LAD'
}

export const QB_SETUP_COMPLETE_POINTS = 20
export const BNB_SMART_CHAIN_COST_PER_TXN = 20 // Cents
export const MAX_EXCHANGE_AMOUNT_IN_USD = 100

export const API_PROGRESS_STATE = {
  NONE: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2
}

export const DOWNLOAD_REPORT_TYPES = {
  TRANSACTIONS: 'transactions',
  MEMBERS: 'members'
}

export const STATIC_LOGO_URL = 'https://static.qiibee.com/logos/'

export const SOCIAL_MEDIA = {
  FACEBOOK: 'Facebook',
  GOOGLE: 'Google'
}

export const CURRENCY_SYMBOL = {
  NFT: 'NFT',
  USD: 'USD'
}

export const FILE_TYPES = {
  CSV: 'csv'
}

export const CUSTOM_BRAND_ID = {
  MILES_AND_MORE: 'miles_and_more',
  ETIHAD: 'etihad',
  TROLLBEADS_MEMBERSHIP_CLUB: 'trollbeads_membership_club',
  COINBASE: 'coinbase',
  BINANCE: 'binance',
  PAYPAL: 'paypal',
  XOXO_DAY: 'xoxoday',
  GET_CHANGE: 'get_change',
  QIIBEE_CONFIG_BRAND: 'qiibee_config_brand',
  ETH_ADDRESS: 'eth_address'
}

export const HTML_DOCTYPE = '!doctype html'

export const CURRENCY_DELIMITER = "'"

export const FUTURE_MILES_TIME = 605 // in seconds
