import React, { memo } from 'react'

import Verification from '../shared/Verification/Verification'
import { ERROR_TEXT, LABEL_TEXT } from '../../constants/messages'
import Text from '../shared/text/Text'
import {
  isResendSignupVerifyFailed,
  isResendSignupVerifySuccess
} from '../../util/auth.helpers'

const SignupVerification = ({
  signupState,
  onResendVerification,
  onCancelVerification,
  t
}) => {
  return (
    <Verification
      submitBtnStyles={{ variant: 'outlined' }}
      message={
        <>
          {isResendSignupVerifySuccess(signupState) && (
            <Text
              color='success.main'
              label={t(LABEL_TEXT.VERIFY_RESENT_SUCCESS)}
              variant='body'
            />
          )}
          {isResendSignupVerifyFailed(signupState) && (
            <Text
              color='error.main'
              label={t(ERROR_TEXT.RESEND_EMAIL_VERIFICATION_FAILED)}
              variant='body'
            />
          )}
        </>
      }
      onSubmit={onResendVerification}
      onCancel={onCancelVerification}
      t={t}
    />
  )
}

export default memo(SignupVerification)
