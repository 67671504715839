import React, { memo } from 'react'
import {
  faCopy,
  faEye,
  faEyeSlash,
  faTrashCan
} from '@fortawesome/pro-light-svg-icons'
import { Grid, Stack } from '@mui/material'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'

import Locked from '../shared/Locked/Locked'
import AccountForm from '../shared/AccountForm/AccountForm'
import Input from '../shared/inputs/Input'
import FaIcon from '../shared/FaIcon/FaIcon'
import CustomIconButton from '../shared/buttons/CustomIconButton'
import { MAX_API_KEYS_ALLOW } from '../../constants/app'

const ViewAPI = ({
  onToggleDisplayKey,
  onCopyAPIKey,
  onGetAPIKey,
  isDashboardSetupReady,
  apiKeys,
  apiKeyState,
  onDeleteAPIKey,
  exchangeTxCount,
  t
}) => {
  const title = t('developers.api-keys')
  const description = t('developers.your-api-key-to-get-access')
  const noAPIKeysExists = apiKeys.length <= 0
  return !isDashboardSetupReady ? (
    <Locked
      title={title}
      description={description}
      t={t}
      lockedLabel='developers.api-key-locked-text'
    />
  ) : (
    <AccountForm
      t={t}
      title={title}
      description={t('developers.if-you-integrate-api', {
        value: MAX_API_KEYS_ALLOW
      })}
      submitLabel={`developers.${noAPIKeysExists ? 'get' : 'add'}-api-key`}
      noActions={apiKeys.length >= MAX_API_KEYS_ALLOW}
      submitStyles={{
        startIcon: !noAPIKeysExists && <FaIcon icon={faPlus} />,
        variant: noAPIKeysExists ? 'contained' : 'outlined',
        isShowGuidance:
          isDashboardSetupReady && exchangeTxCount <= 0 && noAPIKeysExists
      }}
      onFormSubmit={onGetAPIKey}
      fields={
        <Grid item xs={12}>
          {apiKeys.map((apiKey) => (
            <APIKeyField
              key={apiKey.id}
              {...apiKey}
              {...apiKeyState?.[apiKey.id]}
              onToggleDisplayKey={() => onToggleDisplayKey(apiKey)}
              onDeleteAPIKey={() => onDeleteAPIKey(apiKey)}
              onCopyAPIKey={() => onCopyAPIKey(apiKey)}
              t={t}
            />
          ))}
        </Grid>
      }
    />
  )
}

export default memo(ViewAPI)

const APIKeyField = ({
  apiKey,
  name,
  displayKey,
  isKeyCopied,
  onToggleDisplayKey,
  onDeleteAPIKey,
  onCopyAPIKey,
  t
}) => {
  return (
    <Grid container spacing={2} alignItems='flex-end'>
      <Grid item xs={12} sm={9}>
        <Input
          hide={!displayKey}
          value={apiKey}
          label={name || t('developers.your-api-key')}
          successMsg={isKeyCopied && t('developers.api-key-copied')}
          disabled
          size='small'
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Stack
          direction='row'
          spacing={1}
          justifyContent='end'
          alignItems='center'
          sx={{ ...(isKeyCopied && { position: 'relative', bottom: '24px' }) }}
        >
          {displayKey && (
            <CustomIconButton icon={faCopy} onClick={onCopyAPIKey} />
          )}
          <CustomIconButton
            icon={displayKey ? faEyeSlash : faEye}
            onClick={onToggleDisplayKey}
          />
          <CustomIconButton
            color='error'
            variant='outlined'
            icon={faTrashCan}
            onClick={onDeleteAPIKey}
          />
        </Stack>
      </Grid>
    </Grid>
  )
}
