import { mergeMap } from 'rxjs/operators'

import {
  AUTH_FIRST_NAME_ATTR,
  AUTH_LAST_NAME_ATTR,
  QB_AUTH_LANGUAGE_ATTR
} from '../constants/auth'
import { SIGN_UP_PROCESS_STEP } from '../constants/containerStates'
import authService from '../services/auth'

export function extractCognitoUser(state) {
  const { authReducer } = state.value
  const { cognitoUser } = authReducer.authProfile
  return cognitoUser
}

export function extractCognitoUserAttributes(cognitoUser) {
  return cognitoUser.attributes
}

export function extractCognitoUserUsername(state) {
  return extractCognitoUser(state).signInUserSession?.accessToken?.payload?.sub
}

export function formatUserFieldsForCreation(state) {
  const cognitoUser = extractCognitoUser(state)
  const attributes = cognitoUser.attributes
  return {
    dashboard_user: {
      auth_id: cognitoUser.signInUserSession.accessToken.payload.sub,
      first_name: attributes[AUTH_FIRST_NAME_ATTR],
      second_name: attributes[AUTH_LAST_NAME_ATTR],
      email: attributes.email,
      language: attributes[QB_AUTH_LANGUAGE_ATTR]
    }
  }
}

export function extractUserId(state) {
  const { accountReducer } = state.value
  const { userId } = accountReducer.accountProfile
  return userId
}

export function extractCognitoUserSocialIdentity(state) {
  return extractProviderType(state.cognitoUser)
}

export function extractRewardCode(state) {
  const { rewardsReducer } = state.value
  const { rewardCode } = rewardsReducer.rewardsProfile
  return rewardCode
}

function extractProviderType(cognitoUser) {
  const payload = cognitoUser.signInUserSession.idToken.payload
  const identities = payload.identities
  if (identities && identities.length > 0) {
    return identities[0].providerType
  }
  return ''
}

export const isSignUpVerified = (signupState) => {
  return signupState === SIGN_UP_PROCESS_STEP.SIGN_UP_VERIFIED
}

export const isResendSignupVerifySuccess = (signupState) => {
  return signupState === SIGN_UP_PROCESS_STEP.RESEND_VERIFICATION_SUCCESS
}

export const isResendSignupVerifyFailed = (signupState) => {
  return signupState === SIGN_UP_PROCESS_STEP.RESEND_VERIFICATION_FAILED
}

export const extractAccessToken = (cognitoUser) => {
  return cognitoUser?.accessToken?.jwtToken || ''
}

export const wrapUserAccessToken = (callback) => {
  return authService.getUserSession$().pipe(
    mergeMap((userSession) => {
      return callback(extractAccessToken(userSession))
    })
  )
}

export const matchPartialUrl = (path, url, isOutside) => {
  if (!isOutside) {
    return path?.split('/')[1] === url?.split('/')[1]
  }
}
