import React, { memo } from 'react'
import { Box, Stack } from '@mui/material'
import { faHexagonVerticalNftSlanted } from '@fortawesome/pro-light-svg-icons'

import { formatTransactionDisplayData } from '../../../util/transaction/transactionRow.helpers'
import TableCellData from '../DataTable/TableCellData'
import FaIcon from '../FaIcon/FaIcon'
import CustomTooltip from '../CustomTooltip/CustomTooltip'
import { formatTicks, truncateTextForHash } from '../../../util/string.helpers'
import { buildTxnStatusColorIcon } from '../../../util/transaction/transaction.helpers'
import StatusIcon from '../StatusIcon/StatusIcon'
import { CURRENCY_SYMBOL } from '../../../constants/app'
import Text from '../text/Text'
import { TRANSACTION_STATUS_LABEL } from '../../../constants/transactionTypes'

const TransactionDataRow = ({
  t,
  theme: { palette },
  showUserDetails,
  showTxnForExchange,
  showMoreActions,
  changeStatus,
  refundAction,
  customColumns,
  sortedTxnColumns,
  ...restAttrs
}) => {
  const {
    name,
    email,
    auth,
    date,
    hash,
    id,
    eventType,
    code,
    reward,
    triggeredBy,
    brandRef,
    exchangeCurrency,
    eventTypeLabel,
    pointAmount,
    exchangeAmount,
    statusLabel,
    statusGroup,
    eventTypeColor,
    additionalData,
    mmAccount
  } = formatTransactionDisplayData(restAttrs, showTxnForExchange)
  const columns = {
    ...(showUserDetails && {
      name: {
        cellProps: {
          textProps: { color: '', fontWeight: 500 }
        },
        data: name
      },
      email: {
        data: email
      },
      auth: {
        data: (
          <CustomTooltip label={auth}>
            {truncateTextForHash(auth)}
          </CustomTooltip>
        )
      }
    }),
    status: {
      cellProps: {
        align: 'center',
        noTag: true
      },
      data: (
        <CustomTooltip label={t(statusLabel)}>
          <StatusIcon {...buildTxnStatusColorIcon(statusGroup)} />
        </CustomTooltip>
      )
    },
    date: {
      data: date
    },
    hash: {
      data: (
        <CustomTooltip label={hash}>{truncateTextForHash(hash)}</CustomTooltip>
      )
    },
    id: {
      data: id
    },
    type: {
      cellProps: {
        textProps: { color: `${eventTypeColor}.main` },
        wordBreak: false
      },
      data: t(eventTypeLabel)
    },
    amount: {
      data: (
        <>
          {pointAmount && (
            <>
              <TransactionTypeIcon
                icon={pointAmount.icon}
                color={palette[pointAmount.color].main}
              />
              {formatTicks(pointAmount.value || 0)}
            </>
          )}
          {additionalData?.fees ? (
            <Text
              label={
                <>
                  <strong>{t('transactions.columns.fees')}: </strong>
                  {formatTicks(additionalData?.fees)}
                </>
              }
              variant='caption'
              component='div'
            />
          ) : (
            ''
          )}
        </>
      )
    },
    exchange: {
      data: (
        <>
          {exchangeAmount && (
            <>
              <TransactionTypeIcon
                icon={exchangeAmount.icon}
                color={palette[exchangeAmount.color].main}
              />
              {formatTicks(exchangeAmount.value || 0)}
              &nbsp;
            </>
          )}
          <ExchangeCurrencyLabel exchangeCurrency={exchangeCurrency} />
        </>
      )
    },
    eventType: {
      data: eventType
    },
    code: {
      data: code
    },
    reward: {
      data: reward
    },
    triggeredBy: {
      data: triggeredBy
    },
    brandRef: {
      data: brandRef
    },
    mmAccount: {
      data: mmAccount
    }
  }
  return (
    <>
      {(customColumns || sortedTxnColumns).map((field) => {
        const column = columns[field]
        return (
          <TableCellData key={field} {...column?.cellProps}>
            {column?.data}
          </TableCellData>
        )
      })}
      {showMoreActions && (
        <TableCellData noTag>
          <Stack gap={0.5}>
            {statusGroup === TRANSACTION_STATUS_LABEL.FUTURE && (
              <>{changeStatus}</>
            )}
            {refundAction}
          </Stack>
        </TableCellData>
      )}
    </>
  )
}

export default memo(TransactionDataRow)

const TransactionTypeIcon = (props) => {
  return (
    <Box marginRight={0.5} display='inline-block'>
      <FaIcon {...props} />
    </Box>
  )
}

const ExchangeCurrencyLabel = ({ exchangeCurrency }) => {
  return exchangeCurrency === CURRENCY_SYMBOL.NFT ? (
    <TransactionTypeIcon icon={faHexagonVerticalNftSlanted} />
  ) : (
    <>{exchangeCurrency}</>
  )
}
