import { DOWNLOAD_ACTION } from '../../constants/actions'

// DOWNLOAD
const resetDownloadState = () => {
  return {
    type: DOWNLOAD_ACTION.ON_RESET_STATE
  }
}

export { resetDownloadState }
