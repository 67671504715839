import React, { memo } from 'react'
import { CardContent, Typography, Box, Grid } from '@mui/material'
import Chart from 'react-apexcharts'
import { useTheme } from '@mui/material/styles'
import { faCoins } from '@fortawesome/pro-light-svg-icons'

import FaIcon from '../shared/FaIcon/FaIcon'
import BaseCard from '../shared/BaseCard/BaseCard'
import Text from '../shared/text/Text'
import ChartLabels from '../shared/ChartLabels/ChartLabels'
import TitleWithPopover from '../shared/TitleWithPopover/TitleWithPopover'
import {
  buildChartOptions,
  buildDonutChartOptions
} from '../../util/chart/chart.helpers'
import { formatTicks } from '../../util/string.helpers'
import { sumOfArrayElements } from '../../util/app/app.helpers'

const TransactionNumberChart = ({
  title,
  helpText,
  data,
  colorRefMethod,
  chartLabels,
  customColors = []
}) => {
  const totalTxsCount = sumOfArrayElements(data)
  const hasNoData = totalTxsCount === 0
  const chartOptions = buildChartOptions(
    { ...useTheme(), customColors },
    {
      labels: Object.values(chartLabels),
      ...buildDonutChartOptions(65)
    },
    { hasNoData }
  )

  const chartData = hasNoData ? [100] : data
  return (
    <BaseCard>
      <CardContent>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={7}>
            <Text
              variant='h1'
              sx={{
                lineHeight: '35px'
              }}
              label={formatTicks(totalTxsCount)}
              fontWeight={500}
            />

            <TitleWithPopover
              color='textSecondary'
              variant='h6'
              title={title}
              helpText={helpText}
            />
            <ChartLabels
              data={chartLabels}
              size={12}
              colorRefMethod={colorRefMethod}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Box
              sx={{
                position: 'relative',
                mt: 1
              }}
            >
              <Chart
                options={chartOptions}
                series={chartData}
                type='donut'
                height={145}
              />
              <Typography
                color='textSecondary'
                sx={{
                  position: 'absolute',
                  left: '43%',
                  top: '41%'
                }}
              >
                <FaIcon icon={faCoins} size='lg' />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </BaseCard>
  )
}

export default memo(TransactionNumberChart)
