export const QB_INPUT_PASSWORD = 'password'
export const QB_INPUT_TEXT = 'text'
export const QB_INPUT_NUMBER = 'number'
export const QB_INPUT_EMAIL = 'email'
export const QB_ACTION_SUBMIT = 'submit'
export const QB_ACTION_BUTTON = 'button'

export const FILE_UPLOAD_TYPE = {
  CREATE_BRAND: 'createBrand',
  UPDATE_BRAND: 'updateBrand'
}
