import React, { memo } from 'react'
import { Grid } from '@mui/material'
import {
  faHandshake,
  faArrowRightArrowLeft,
  faCode,
  faChartMixed,
  faCircleInfo
} from '@fortawesome/pro-light-svg-icons'

import PageContainer from '../shared/PageContainer/PageContainer'
import ActivityCard from '../shared/ActivityCard/ActivityCard'
import ActionCard from './ActionCard'
import { ANALYTICS_URL, INTEGRATION_URL } from '../../constants/navigation'
import { HELP_CENTER_EXTERNAL_URL } from '../../constants/externalResources'
import InitialSetup from './InitialSetup'
import ExchangeActivityChart from './ExchangeActivityChart'
import { buildExchangePotentialUrl } from '../../util/exchange.helpers'
import { formatTicks } from '../../util/string.helpers'
import RoadToSuccess from './RoadToSuccess'
import DisplayAlerts from '../setup/common/DisplayAlerts'
import { COLOR_PALETTE } from '../../constants/theme'
import { isStarterPackagePlan } from '../../util/billing.helpers'
import UpgradeFromStarter from './UpgradeFromStarter'

const Dashboard = ({
  dashboardSetupState,
  t,
  transactionsGraphProfile,
  brandProfile,
  activePartnershipsCount,
  tokenProfile,
  membershipMilestonesData,
  milestoneAnimationStatus,
  isDashboardSetupReady,
  onCompleteMilestoneAnimation,
  billingProfile: { subscribedPackage },
  exchangeTxCount,
  ...restProps
}) => {
  const isPackageStarter =
    isDashboardSetupReady &&
    isStarterPackagePlan(subscribedPackage?.details?.name)
  const gridSize = isPackageStarter ? 4 : 6
  return (
    <PageContainer title={t('sidebar.home')}>
      <DisplayAlerts
        isDashboardSetupReady={isDashboardSetupReady}
        tokenStatus={tokenProfile.status}
        subscribedPackage={subscribedPackage}
        t={t}
      />
      <Grid container spacing={0}>
        <Grid item xs={12} padding={2}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={gridSize}>
              <ExchangeActivityChart
                t={t}
                exchangeActivityChart={
                  transactionsGraphProfile.exchangeActivityChart
                }
                exchangeTxCount={exchangeTxCount}
              />
            </Grid>

            <Grid item xs={12} md={gridSize}>
              <ActivityCard
                title={t('home.partnerships')}
                heading={formatTicks(activePartnershipsCount)}
                label={t('home.active-exchange-partnerships')}
                icon={faHandshake}
                color={COLOR_PALETTE.PRIMARY}
              />
            </Grid>

            {isPackageStarter && (
              <Grid item xs={12} md={gridSize}>
                <UpgradeFromStarter t={t} />
              </Grid>
            )}

            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={4} display='flex' alignItems='stretch'>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <InitialSetup
                        t={t}
                        dashboardSetupState={dashboardSetupState}
                        {...restProps}
                        isDashboardSetupReady={isDashboardSetupReady}
                        isBrandCreated={brandProfile.id}
                        isTokenCreated={tokenProfile.id}
                        milestoneAnimationStatus={milestoneAnimationStatus}
                        onCompleteMilestoneAnimation={
                          onCompleteMilestoneAnimation
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ActionCard
                        title={t('sidebar.integration')}
                        description={t('home.integration-description')}
                        buttonLabel={t('home.explore-integrations')}
                        to={INTEGRATION_URL}
                        icon={faCode}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <RoadToSuccess
                    tokenProfile={tokenProfile}
                    isDashboardSetupReady={isDashboardSetupReady}
                    membershipMilestonesData={membershipMilestonesData}
                    milestoneAnimationStatus={milestoneAnimationStatus}
                    onCompleteMilestoneAnimation={onCompleteMilestoneAnimation}
                    t={t}
                  />
                </Grid>
                <Grid item xs={12} lg={4} display='flex' alignItems='stretch'>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <ActionCard
                        title={t('exchange.rewards-marketplace')}
                        description={t(
                          'exchange.rewards-marketplace-description'
                        )}
                        buttonLabel={t('exchange.explore-marketplace')}
                        to={buildExchangePotentialUrl()}
                        icon={faArrowRightArrowLeft}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ActionCard
                        title={t('sidebar.analytics')}
                        description={t('home.analytics-description')}
                        buttonLabel={t('home.view-analytics')}
                        to={ANALYTICS_URL}
                        icon={faChartMixed}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ActionCard
                        title={t('sidebar.help-center')}
                        description={t('home.help-center-description')}
                        buttonLabel={t('home.go-to-help-center')}
                        to={HELP_CENTER_EXTERNAL_URL}
                        isOutside
                        icon={faCircleInfo}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  )
}

export default memo(Dashboard)
