import { mergeToNewObject } from '../../../util/object.helpers'
import {
  EXCHANGE_CONFIG_ACTION,
  EXCHANGE_PARTNER_ACTION
} from '../../../constants/actions'
import { isExchangeActionMaxLimitChange } from '../../../util/exchange.helpers'
import {
  buildErrorMessage,
  buildSuccessMessages
} from '../../../util/reducer.helpers'
import { API_RESPONSE } from '../../../constants/api'
import { formatValidator } from '../../../util/validation.helpers'
import { ERROR_TEXT } from '../../../constants/messages'
import { formatCurrencyValue } from '../../../util/number.helpers'
import { maxExchangeValue } from '../../../util/app/app.helpers'

export const exchangeConfigProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case EXCHANGE_CONFIG_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case EXCHANGE_CONFIG_ACTION.ON_RESET_STATE:
      return INITIAL_STATE

    case EXCHANGE_PARTNER_ACTION.ON_UPDATE_EXCHANGE_CONNECTION:
      return mergeToNewObject(
        state,
        isExchangeActionMaxLimitChange(payload.action) ? { success: {} } : {}
      )

    case EXCHANGE_PARTNER_ACTION.ON_UPDATE_EXCHANGE_CONNECTION_SUCCESS:
      const { action, data, connection } = payload
      return mergeToNewObject(
        state,
        isExchangeActionMaxLimitChange(action)
          ? handleSaveExchangeResponse(data, connection, state)
          : {}
      )

    case EXCHANGE_PARTNER_ACTION.ON_UPDATE_EXCHANGE_CONNECTION_FAILED:
      return mergeToNewObject(state, extractErrorMessage(payload))

    default:
      return state
  }
}

const INITIAL_STATE = {
  maxValue: '',
  maxValueTemp: '',

  error: {},
  success: {}
}

export const EXCHANGE_CONFIG_DISPATCH = {
  MAX_VALUE: 'maxValue',
  MAX_VALUE_TEMP: 'maxValueTemp',

  ERROR: 'error',
  SUCCESS: 'success'
}

const handleSaveExchangeResponse = (data, connection, state) => {
  const maxValue = formatCurrencyValue(
    connection?.isFrom ? data.fromTokenMaxUsd : data.toTokenMaxUsd
  )
  const { maxValue: maxValueTemp } = state
  const prevDetails = {
    maxValueTemp
  }
  const updatedDetails = {
    maxValueTemp: maxValue
  }
  return {
    ...updatedDetails,
    maxValue,

    success: buildSuccessMessages(prevDetails, updatedDetails),
    error: {}
  }
}

const extractErrorMessage = (payload) => {
  return {
    error: {
      maxValueTemp: buildMaxValueErrorMessage(payload)
    }
  }
}

const buildMaxValueErrorMessage = (payload) => {
  if (payload.status === API_RESPONSE.BAD_REQUEST) {
    return formatValidator(ERROR_TEXT.EXCEED_MAX_LIMIT, {
      value: maxExchangeValue()
    })
  }
  return buildErrorMessage(payload)
}
