import React, { memo } from 'react'
import { Grid } from '@mui/material'

import Docs from './Docs'
import ViewAPI from './ViewAPI'
import PageContainer from '../shared/PageContainer/PageContainer'
import TokenInfo from './TokenInfo'
import LWA from './LWA'
import BrandAPI from './BrandAPI'
import DisplayAlerts from '../setup/common/DisplayAlerts'
import ImportantNote from '../shared/ImportantNote/ImportantNote'
import { INTEGRATION_OPTIONS_GUIDE_URL } from '../../util/support.helpers'
import { LAD_SURVEY_IDS } from '../../constants/survey'
import { buildLoveBrandCardProps } from '../../util/extensions/extensions.helpers'
import MarketplaceCard from './MarketplaceCard'

const Integration = ({
  onToggleDisplayKey,
  onDeleteAPIKey,
  onCopyAPIKey,
  isDashboardSetupReady,
  tokenProfile,
  onCloseImportantNote,
  hideIntegrationNote,
  onGetAPIKey,
  apiKeysProfile: { apiKeys },
  apiKeyState,
  onClickLWASetup,
  billingProfile,
  changeTokenState,
  onFormSubmit,
  exchangeTxCount,
  t,
  surveyProfile: { surveys, userSurveys },
  onLikeUnlikeSurvey,
  extensionsProfile: { userSurveysLikesCount }
}) => {
  const showImportantNote = (
    <ImportantNote
      t={t}
      title='developers.important-note'
      guideUrl={INTEGRATION_OPTIONS_GUIDE_URL}
      onClose={onCloseImportantNote}
      closedImportantNote={hideIntegrationNote}
    />
  )
  const ladLwaComingSoonSurvey =
    surveys[LAD_SURVEY_IDS.LAD_LWA_COMING_SOON]?.questions?.en?.questions?.[0]
  const gridSize = ladLwaComingSoonSurvey ? 4 : 6
  return (
    <PageContainer title={t('sidebar.integration')}>
      <DisplayAlerts
        isDashboardSetupReady={isDashboardSetupReady}
        tokenStatus={tokenProfile.status}
        subscribedPackage={billingProfile.subscribedPackage}
        t={t}
      />
      {!hideIntegrationNote && showImportantNote}
      <Grid container>
        <Grid item xs={12} padding={2}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={5}>
              <Docs t={t} />
            </Grid>
            <Grid item xs={12} md={7}>
              <ViewAPI
                apiKeys={apiKeys}
                onToggleDisplayKey={onToggleDisplayKey}
                onDeleteAPIKey={onDeleteAPIKey}
                onCopyAPIKey={onCopyAPIKey}
                onGetAPIKey={onGetAPIKey}
                isDashboardSetupReady={isDashboardSetupReady}
                apiKeyState={apiKeyState}
                exchangeTxCount={exchangeTxCount}
                t={t}
              />
            </Grid>
            <Grid item xs={12}>
              <TokenInfo
                isDashboardSetupReady={isDashboardSetupReady}
                t={t}
                tokenProfile={tokenProfile}
                lockContent={!isDashboardSetupReady}
                dispatchAction={changeTokenState}
                onFormSubmit={onFormSubmit}
              />
            </Grid>
            <Grid item xs={12} md={gridSize}>
              <LWA t={t} onClickLWASetup={onClickLWASetup} />
            </Grid>
            {ladLwaComingSoonSurvey && (
              <Grid item xs={12} md={gridSize}>
                <MarketplaceCard
                  t={t}
                  {...buildLoveBrandCardProps(
                    LAD_SURVEY_IDS.LAD_LWA_COMING_SOON,
                    {
                      userSurveys,
                      userSurveysLikesCount,
                      id: ladLwaComingSoonSurvey.id
                    },
                    onLikeUnlikeSurvey
                  )}
                  {...ladLwaComingSoonSurvey}
                />
              </Grid>
            )}
            <Grid item xs={12} md={gridSize}>
              <BrandAPI t={t} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {hideIntegrationNote && showImportantNote}
    </PageContainer>
  )
}

export default memo(Integration)
