import { map, mergeMap, catchError, tap, filter, delay } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'

import { logger } from '../../util/log.helpers'
import { BILLING_ACTION, TOKEN_ACTIONS } from '../../constants/actions'
import apiService from '../../services/api'
import { wrapUserAccessToken } from '../../util/auth.helpers'
import {
  extractSubscribedPackage,
  extractTokenId
} from '../../util/epics.helpers'
import { buildExchangeDateRange } from '../../util/billing.helpers'
import { commonParser } from '../../util/apiParser.helpers'
import { EXCHANGE_PERFORMANCE_PATH } from '../../constants/api'

const handleGenerateCheckoutSessionEpic = (action$) =>
  action$.pipe(
    ofType(BILLING_ACTION.ON_GENERATE_CHECKOUT_SESSION),
    mergeMap(({ payload: { tokenId, priceId } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .generateCheckoutSession(tokenId, { price_id: priceId }, accessToken)
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: BILLING_ACTION.ON_GENERATE_CHECKOUT_SESSION_SUCCESS,
              payload: response.data
            })),
            catchError((error) =>
              of({
                type: BILLING_ACTION.ON_GENERATE_CHECKOUT_SESSION_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

const handleGenerateViewBillingSessionEpic = (action$) =>
  action$.pipe(
    ofType(BILLING_ACTION.ON_GENERATE_VIEW_BILLING_SESSION),
    mergeMap(({ payload: { tokenId } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService.generateViewBillingSession(tokenId, accessToken).pipe(
          tap((data) => logger(data)),
          map(({ response }) => ({
            type: BILLING_ACTION.ON_GENERATE_VIEW_BILLING_SESSION_SUCCESS,
            payload: response.data
          })),
          catchError((error) =>
            of({
              type: BILLING_ACTION.ON_GENERATE_VIEW_BILLING_SESSION_FAILED,
              payload: error
            })
          )
        )
      )
    )
  )

const handleGetExchangePerformanceEpic = (action$, state$) =>
  action$.pipe(
    ofType(BILLING_ACTION.ON_GET_EXCHANGE_PERFORMANCE),
    map(() => extractSubscribedPackage(state$)),
    filter((subscribedPackage) => subscribedPackage?.id),
    mergeMap((subscribedPackage) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getAnalyticsData(
            EXCHANGE_PERFORMANCE_PATH,
            extractTokenId(state$),
            buildExchangeDateRange(subscribedPackage),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: BILLING_ACTION.ON_GET_EXCHANGE_PERFORMANCE_SUCCESS,
              payload: commonParser(response.data)
            })),
            catchError((error) =>
              of({
                type: BILLING_ACTION.ON_GET_EXCHANGE_PERFORMANCE_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

const handleUpdatePackageSubscriptionEpic = (action$, state$) =>
  action$.pipe(
    ofType(TOKEN_ACTIONS.ON_CREATE_TOKEN_SUCCESS),
    filter(({ payload }) => payload.isPaidSubscription),
    mergeMap(({ payload: { token, prevToken } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .updatePackageSubscription(
            prevToken.id,
            extractSubscribedPackage(state$).id,
            buildDataForSubscriptionUpdate(token.id),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: BILLING_ACTION.ON_UPDATE_PACKAGE_SUBSCRIPTION_SUCCESS,
              payload: commonParser(response.data)
            })),
            catchError((error) =>
              of({
                type: BILLING_ACTION.ON_UPDATE_PACKAGE_SUBSCRIPTION_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

const handleGetTokensOnCheckoutSessionEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      BILLING_ACTION.ON_START_CHECKOUT,
      BILLING_ACTION.ON_REACTIVATE_SUBSCRIPTION
    ),
    delay(3000),
    map(() => ({
      type: TOKEN_ACTIONS.ON_GET_TOKEN_BY_ID,
      payload: {
        tokenId: extractTokenId(state$),
        shouldUpdateSubscriptions: true
      }
    }))
  )

const handleGetSubscriptionsEpic = (action$) =>
  action$.pipe(
    ofType(TOKEN_ACTIONS.ON_GET_TOKEN_BY_ID_SUCCESS),
    filter(({ payload }) => payload.shouldUpdateSubscriptions),
    mergeMap(({ payload: { token } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService.getPackageSubscriptions(token.id, accessToken).pipe(
          tap((data) => logger(data)),
          map(({ response }) => {
            const currentToken = token
            return {
              type: BILLING_ACTION.ON_GET_PACKAGE_SUBSCRIPTIONS_SUCCESS,
              payload: {
                packageSubscriptions: commonParser(response),
                currentToken
              }
            }
          }),
          catchError((err) =>
            of({
              type: BILLING_ACTION.ON_GET_PACKAGE_SUBSCRIPTIONS_FAILED,
              payload: err
            })
          )
        )
      )
    )
  )

export const billingEpic = combineEpics(
  handleGenerateCheckoutSessionEpic,
  handleGenerateViewBillingSessionEpic,
  handleGetExchangePerformanceEpic,
  handleUpdatePackageSubscriptionEpic,
  handleGetTokensOnCheckoutSessionEpic,
  handleGetSubscriptionsEpic
)

const buildDataForSubscriptionUpdate = (tokenId) => {
  return {
    metadata: {
      token_id: tokenId
    }
  }
}
