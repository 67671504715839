import React, { memo } from 'react'
import { Card } from '@mui/material'

import { mergeToNewObject } from '../../../util/object.helpers'

const BaseCard = ({ children, sx }) => (
  <Card sx={mergeToNewObject({ height: '100%', margin: 0 }, sx)}>
    {children}
  </Card>
)

export default memo(BaseCard)
