import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import { getSupportedLanguages } from '../util/translation.helpers'
import { formatDate } from '../util/date.helpers'
import { QB_REWARD_POINT } from '../constants/app'

const DETECTION_OPTIONS = {
  order: ['cookie', 'localStorage', 'navigator'],
  lookupCookie: 'locale',
  lookupLocalStorage: 'locale',
  caches: ['localStorage', 'cookie'],
  checkWhitelist: true
}

const supportedLanguages = getSupportedLanguages()

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/translations.json',
      requestOptions: {
        cache: 'no-store'
      }
    },
    detection: DETECTION_OPTIONS,
    fallbackLng: supportedLanguages[0],
    ns: ['translations'],
    defaultNS: 'translations',
    supportedLngs: supportedLanguages,
    whitelist: supportedLanguages,
    debug: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
      format: formatDate
    },
    react: {
      useSuspense: false
    }
  })

i18n.options.interpolation.defaultVariables = {
  creditName: QB_REWARD_POINT.NAME,
  creditSymbol: QB_REWARD_POINT.SYMBOL
}

export default i18n
