import React, { memo } from 'react'
import { Stack } from '@mui/material'

const ButtonsGroup = ({ children, ...restProps }) => {
  return (
    <Stack
      spacing={2}
      direction={{
        xs: 'column',
        sm: 'row'
      }}
      display={{ md: 'grid' }}
      gridAutoFlow={{ md: 'column' }}
      justifyContent='end'
      {...restProps}
    >
      {children}
    </Stack>
  )
}

export default memo(ButtonsGroup)
