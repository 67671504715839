import React, { memo } from 'react'
import { faCoin } from '@fortawesome/pro-light-svg-icons'
import { Grid, useTheme } from '@mui/material'

import CustomDialog from '../../shared/CustomDialog/CustomDialog'
import AmountOfPoints from '../../shared/inputs/AmountOfPoints'
import Input from '../../shared/inputs/Input'
import { buildMemberTransactionTypeIconColor } from '../../../util/transaction/transaction.helpers'
import { MEMBER_POINTS_TRANSACTION_TYPES } from '../../../constants/members'
import { QB_INPUT_EMAIL } from '../../../constants/input'
import FaIcon from '../../shared/FaIcon/FaIcon'
import { REWARD_MEMBER_DISPATCH } from '../../../store/reducers/members/rewardMember'
import { transWithArgs } from '../../../util/validation.helpers'
import Text from '../../shared/text/Text'

const RewardMember = ({
  t,
  onClose,
  onSubmit,
  dispatchAction,
  rewardMemberProfile: { amount, account, isProcessing, error },
  gridSize = 6,
  accountLabel = 'reward.member-account',
  title,
  description,
  loyaltyPoint,
  amountProps,
  accountProps,
  ...restProps
}) => {
  const theme = useTheme()
  const { icon, color } = buildMemberTransactionTypeIconColor(
    MEMBER_POINTS_TRANSACTION_TYPES.CREDIT_POINTS
  )
  return (
    <CustomDialog
      isForm
      title={t(title)}
      description={t(description)}
      open
      onClose={onClose}
      submitProps={{
        label: t('member.transaction-filters.credit_points'),
        disabled: isProcessing,
        startIcon: <FaIcon icon={faCoin} />
      }}
      body={
        <>
          <Text
            label={t('member.transaction-filters.credit_points')}
            fontWeight={700}
            variant='subtitle2'
            component='div'
          />
          <Grid container spacing={2}>
            {loyaltyPoint}
            <Grid item md={gridSize} sm={gridSize} xs={12}>
              <AmountOfPoints
                t={t}
                id={REWARD_MEMBER_DISPATCH.AMOUNT}
                value={amount}
                error={error}
                dispatchAction={dispatchAction}
                icon={icon}
                color={theme.palette[color].main}
                {...amountProps}
              />
            </Grid>
            <Grid item md={gridSize} sm={gridSize} xs={12}>
              <Input
                id={REWARD_MEMBER_DISPATCH.ACCOUNT}
                label={t(accountLabel)}
                type={QB_INPUT_EMAIL}
                value={account}
                size='small'
                onChange={(value) =>
                  dispatchAction(REWARD_MEMBER_DISPATCH.ACCOUNT, value)
                }
                errorMessage={transWithArgs(
                  t,
                  error[REWARD_MEMBER_DISPATCH.ACCOUNT]
                )}
                {...accountProps}
              />
            </Grid>
          </Grid>
        </>
      }
      onSubmit={onSubmit}
      t={t}
      {...restProps}
    />
  )
}

export default memo(RewardMember)
