import React, { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Text from '../../shared/text/Text'
import { getEnvModeLabelColor } from '../../../util/app/app.helpers'
import { PRODUCTION_URL } from '../../../constants/externalResources'
import Link from '../../shared/link/Link'
import CustomAlert from '../../shared/CustomAlert/CustomAlert'
import { testingAlertHeight } from '../../global/Theme-variable'
import { CSS_FLEX_CENTERED } from '../../../styles/common'

const SandboxBoxModeBanner = () => {
  const { t } = useTranslation()
  const { label, color } = getEnvModeLabelColor()
  return (
    <CustomAlert
      variant='filled'
      severity={color}
      sx={{
        ...CSS_FLEX_CENTERED,
        textAlign: 'center',
        position: 'fixed',
        width: '100%',
        borderRadius: 0,
        zIndex: 10,
        height: `${testingAlertHeight}px`,
        m: 0
      }}
    >
      <Text
        sx={{ typography: { sm: 'h4', xs: 'subtitle2' } }}
        label={
          <>
            <strong>{`${t('common.important')}: `}</strong>
            <Trans i18nKey='common.important-note' i18nIsDynamicList>
              {t(label)}
              <Link
                isOutside
                to={PRODUCTION_URL}
                label={t('common.production-mode')}
                color='inherit'
              />
            </Trans>
          </>
        }
      />
    </CustomAlert>
  )
}

export default memo(SandboxBoxModeBanner)
