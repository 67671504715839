import React, { memo } from 'react'
import { Grid } from '@mui/material'

import DefaultText from '../text/DefaultText'
import ButtonsGroup from '../buttons/ButtonsGroup'

const TabDescription = ({ description, body, actions }) => {
  return (
    <>
      <DefaultText label={description} component='div' />
      {body}
      {actions && (
        <Grid
          container
          spacing={2}
          mt={2}
          sx={{ display: 'flex', flexDirection: 'column' }}
        >
          <Grid item xs={12}>
            <ButtonsGroup>{actions}</ButtonsGroup>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default memo(TabDescription)
