import { QB_MEMBERS_TABLE_FIELDS } from './members'

export const MEMBERS_TABLE_HEADER = [
  {
    value: QB_MEMBERS_TABLE_FIELDS.NAME,
    label: 'members.name'
  },
  {
    value: QB_MEMBERS_TABLE_FIELDS.EMAIL,
    label: 'members.email'
  },
  {
    value: QB_MEMBERS_TABLE_FIELDS.AUTH_ID,
    label: 'members.member-auth-id'
  },
  {
    value: QB_MEMBERS_TABLE_FIELDS.USER_CREATED,
    label: 'members.user-created'
  }
]

const TEAM_MEMBERS_TABLE_FIELDS = {
  TEAM_MEMBER: 'TEAM_MEMBER',
  STATUS: 'STATUS',
  ACCESS_LEVEL: 'ACCESS_LEVEL',
  ACTION: 'ACTION'
}

const NFT_TABLE_FIELDS = {
  NUMBER: 'NUMBER',
  OWNER: 'OWNER',
  EXCLUSIVE_CONTENT_STATUS: 'EXCLUSIVE_CONTENT_STATUS',
  ACTIONS: 'ACTIONS'
}

export const TEAM_MEMBERS_TABLE_HEADER = [
  {
    value: TEAM_MEMBERS_TABLE_FIELDS.TEAM_MEMBER,
    label: 'admin.team-member'
  },
  {
    value: TEAM_MEMBERS_TABLE_FIELDS.STATUS,
    label: 'common.status'
  },
  {
    value: TEAM_MEMBERS_TABLE_FIELDS.ACCESS_LEVEL,
    label: 'admin.access-level'
  },
  {
    value: TEAM_MEMBERS_TABLE_FIELDS.ACTION,
    label: 'admin.action'
  }
]

export const TEAM_MEMBERS_STATUS = {
  ACTIVE: 'Active'
}

export const TEAM_MEMBERS_CONTROL_LEVEL = {
  ADMIN: 'Admin'
}

export const NFT_TABLE_HEADER = [
  {
    value: NFT_TABLE_FIELDS.NUMBER,
    label: 'nft.table-header.number',
    smallFont: true,
    disablePadding: true
  },
  {
    value: NFT_TABLE_FIELDS.OWNER,
    label: 'nft.table-header.owner',
    smallFont: true,
    disablePadding: true,
    sx: { pl: 2 }
  },
  {
    value: NFT_TABLE_FIELDS.EXCLUSIVE_CONTENT_STATUS,
    label: 'nft.table-header.exclusive-content-status',
    smallFont: true,
    disablePadding: true,
    helpText: 'nft.exclusive-content-status-desc',
    sx: { width: '25%' }
  },
  {
    value: NFT_TABLE_FIELDS.ACTIONS,
    label: 'nft.table-header.actions',
    smallFont: true,
    disablePadding: true,
    sx: { width: '18%' }
  }
]
