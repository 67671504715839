import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'

import {
  LOG_IN_URL,
  HOME_URL,
  UNAUTHORIZED_URLS,
  AUTHORIZED_URLS
} from '../constants/navigation'
import {
  confirmSignup,
  getCurrentUser,
  signOut,
  changeSignupInputAndErrorState,
  changeSignupState,
  acceptTerms
} from '../store/actions/auth'

import { changeAccountState } from '../store/actions/account'
import {
  isNotAuthenticated,
  isAuthenticated,
  isCheckingAuth
} from '../util/account.helpers'
import Loading from '../components/shared/Loading/Loading'
import { matchPartialUrl } from '../util/auth.helpers'
import { extractEmailFromUrl } from '../util/string.helpers'
import { buildExchangePotentialUrl } from '../util/exchange.helpers'

export default (ChildComponent) => {
  class ComposedComponent extends Component {
    componentDidMount() {
      if (isNotAuthenticated(this.props.authState)) {
        this.props.getCurrentUser()
      }
    }

    componentDidUpdate(prevProps) {
      const { authState, history } = this.props
      const queryParams = queryString.parse(history.location.search)
      const locationPath = history.location.pathname
      if (isNotAuthenticated(authState)) {
        if (isAuthenticated(prevProps.authState)) {
          history.replace(LOG_IN_URL)
        } else if (isCheckingAuth(prevProps.authState)) {
          // Checking !queryParams.state to avoid collision with reward ode and code after the social media login page redirect
          if (queryParams.signupCode) {
            const email = extractEmailFromUrl(history.location.search)
            this.props.confirmSignup(email, queryParams.signupCode)
            history.replace(LOG_IN_URL)
          } else {
            const urlIndex = UNAUTHORIZED_URLS.findIndex(
              (url) => url === locationPath
            )
            if (urlIndex < 0) {
              history.replace(LOG_IN_URL)
            }
          }
        }
      } else if (
        isAuthenticated(authState) &&
        isCheckingAuth(prevProps.authState)
      ) {
        const urlIndex = AUTHORIZED_URLS.findIndex((url) =>
          matchPartialUrl(locationPath, url)
        )
        let redirectPath
        // @TODO: temporary redirection to old exchange marketplace URL, can be removed later
        if (locationPath === '/exchange/potential') {
          redirectPath = buildExchangePotentialUrl()
        } else {
          redirectPath = urlIndex < 0 ? HOME_URL : locationPath
        }
        history.replace(redirectPath)
      }
    }

    render() {
      const { authState } = this.props
      const checkIsAuthenticated = isAuthenticated(authState)
      return checkIsAuthenticated ? (
        <ChildComponent {...this.props} />
      ) : (
        <Loading />
      )
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(ComposedComponent)
}

const mapStateToProps = ({ authReducer, accountReducer, mainReducer }) => {
  const { authProfile, signupProfile } = authReducer
  const { accountProfile } = accountReducer
  const { mainProfile } = mainReducer
  const { authState } = authProfile
  return {
    authState: authState,
    loading: mainProfile.loading,
    accountProfile,
    signupProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCurrentUser,
      signOut,
      confirmSignup,
      changeSignupInputAndErrorState,
      changeSignupState,
      acceptTerms,
      changeAccountState
    },
    dispatch
  )
