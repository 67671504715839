import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'

import Login from '../components/login/Login'
import Verification from '../components/shared/Verification/Verification'
import {
  changeLoginState,
  changeLoginInputAndErrorState,
  cleanLoginState,
  logIn,
  resendSignupEmail,
  changeSignupState
} from '../store/actions/auth'
import requireUnauth from '../hoc/requireUnauth'
import { LOGIN_PROFILE_DISPATCH } from '../store/reducers/auth/login'
import { DEFAULT_CONTAINER_STATE } from '../constants/containerStates'
import { SIGN_UP_PROFILE_DISPATCH } from '../store/reducers/auth/signup'
import { isSignUpVerified } from '../util/auth.helpers'
import { containsValues } from '../util/object.helpers'
import {
  checkValidations,
  requiredRule,
  emailRule,
  passwordRule
} from '../util/validation.helpers'

const LoginContainer = ({
  loginProfile,
  changeLoginState,
  changeLoginInputAndErrorState,
  cleanLoginState,
  logIn,
  resendSignupEmail,
  changeSignupState,
  signupProfile
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      cleanLoginState()
      cleanSignup()
    }
  }, [cleanLoginState])

  const handleLoginSubmit = () => {
    changeLoginState(LOGIN_PROFILE_DISPATCH.ERROR, {})
    const { email, password } = loginProfile
    const errors = checkValidations({
      email: { value: email, rules: [requiredRule, emailRule] },
      password: { value: password, rules: [requiredRule, passwordRule] }
    })
    if (containsValues(errors)) {
      changeLoginState(LOGIN_PROFILE_DISPATCH.ERROR, errors)
    } else {
      logIn(email, password)
    }
  }

  const cleanSignup = () => {
    changeSignupState(
      SIGN_UP_PROFILE_DISPATCH.SIGN_UP_STATE,
      DEFAULT_CONTAINER_STATE.NONE
    )
  }

  const handleSubmitVerify = () => {
    cleanSignup()
  }

  const handleResendVerification = () => {
    const { email } = loginProfile
    resendSignupEmail(email)
  }

  const signupState = signupProfile.signupState
  return isSignUpVerified(signupState) ? (
    <Verification verified onSubmit={handleSubmitVerify} t={t} />
  ) : (
    <>
      <Login
        loginProfile={loginProfile}
        signupProfile={signupProfile}
        dispatchAction={changeLoginInputAndErrorState}
        signupState={signupState}
        onResendVerification={handleResendVerification}
        onLoginSubmit={handleLoginSubmit}
        t={t}
      />
    </>
  )
}

const mapStateToProps = ({ authReducer }) => {
  const { loginProfile, signupProfile } = authReducer
  return {
    loginProfile,
    signupProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeLoginState,
      changeLoginInputAndErrorState,
      cleanLoginState,
      logIn,
      resendSignupEmail,
      changeSignupState
    },
    dispatch
  )

export default requireUnauth(
  connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
)
