import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'

import { mergeToNewObject } from '../../../util/object.helpers'

const CustomButton = ({ label, to, sx, noPadding, ...restProps }) => {
  return (
    <Button
      component={to && Link}
      to={to}
      sx={mergeToNewObject(
        !noPadding && {
          pt: 1.3,
          pb: 1.3
        },
        sx
      )}
      {...restProps}
    >
      {label}
    </Button>
  )
}

CustomButton.defaultProps = {
  fullWidth: true,
  color: 'primary',
  variant: 'contained'
}

export default memo(CustomButton)
