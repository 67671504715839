import { Box } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { HOME_URL } from '../../../constants/navigation'
import { ReactComponent as LogoDark } from '../../../assets/images/logos/qbx-logo-dark.svg'
import { ReactComponent as LogoLight } from '../../../assets/images/logos/qbx-logo-light.svg'

const LogoIcon = () => {
  const accountProfile = useSelector(
    (state) => state.accountReducer.accountProfile
  )
  return (
    <Link to={HOME_URL}>
      <Box sx={{ width: 100, margin: 'auto' }}>
        {accountProfile.metadata?.darkMode ? <LogoLight /> : <LogoDark />}
      </Box>
    </Link>
  )
}

export default LogoIcon
