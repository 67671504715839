import React, { memo } from 'react'

import GoogleIconSvg from './GoogleIconSvg'
import CustomButton from '../shared/buttons/CustomButton'
import { SOCIAL_MEDIA } from '../../constants/app'
import Text from '../shared/text/Text'

const Google = ({ disabled, onClick, label, ...restProps }) => {
  return (
    <CustomButton
      variant='outlined'
      size='large'
      sx={{
        borderColor: (theme) => theme.palette.ssoBorderColor,
        borderWidth: '2px',
        textAlign: 'center',
        pt: '10px',
        pb: '10px',
        '&:hover': {
          borderColor: (theme) => theme.palette.ssoBorderColor,
          borderWidth: '2px'
        }
      }}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
      label={
        <>
          <GoogleIconSvg disabled={disabled} />
          <Text
            label={`${SOCIAL_MEDIA.GOOGLE}${label ? ` ${label}` : ''}`}
            color={!disabled && 'textPrimary'}
            variant='h5'
          />
        </>
      }
      {...restProps}
    />
  )
}

export default memo(Google)
