import React, { memo } from 'react'
import { Box } from '@mui/material'

import { CSS_FLEX_ALIGNED } from '../../../styles/common'

const CustomLinearProgress = ({ value, size = 4 }) => {
  const fillerRelativePercentage = (100 / value) * 100
  return (
    <Box
      sx={CSS_FLEX_ALIGNED}
      role='progressbar'
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={value}
    >
      <Box
        sx={{
          flex: 1,
          height: `${size}px`,
          background: ({ palette: { grey } }) => grey.A100,
          borderRadius: '6px',
          overflow: 'hidden'
        }}
      >
        <Box
          sx={{
            width: `${value}%`,
            transition: 'width 2s ease-i-out',
            height: 'inherit',
            borderRadius: 'inherit',
            overflow: 'hidden'
          }}
        >
          <Box
            sx={{
              width: `${fillerRelativePercentage}%`,
              height: 'inherit',
              transition: 'width 2s ease-i-out',
              background: ({ palette: { primary, secondary } }) =>
                `linear-gradient(90deg, ${primary.main}, ${secondary.main})`
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default memo(CustomLinearProgress)
