import React, { memo } from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Chart from 'react-apexcharts'

import DashboardCard from '../shared/DashboardCard/DashboardCard'
import ChartLabels from '../shared/ChartLabels/ChartLabels'
import { buildChartOptions } from '../../util/chart/chart.helpers'
import {
  buildTransactionsChartColors,
  transactionChartTooltip
} from '../../util/chart/transactionsGraph.helpers'
import Loading from '../shared/Loading/Loading'
import { CSS_FLEX_ALIGNED } from '../../styles/common'
import { CHART_TRANSACTIONS_HEIGHT } from '../../constants/chart'

const TransactionsChart = ({
  title,
  helpText,
  filteredData,
  isProcessing,
  colorRefMethod,
  chartLabels,
  colorConfig
}) => {
  const theme = useTheme()
  const chartOptions = buildChartOptions(theme, {
    stroke: {
      curve: 'smooth',
      width: 3
    },
    ...transactionChartTooltip()
    // markers: {
    //   size: 4,
    //   border: 1
    // },
  })

  const dataKeys = Object.keys(filteredData)
  const chartData = dataKeys.map((dataKey) => ({
    data: filteredData[dataKey],
    name: chartLabels[dataKey]
  }))

  return (
    <DashboardCard
      title={title}
      helpText={helpText}
      contentProps={{ pr: 0, pl: 0, pt: 0 }}
    >
      <Box>
        {isProcessing ? (
          <Box sx={{ height: CHART_TRANSACTIONS_HEIGHT, ...CSS_FLEX_ALIGNED }}>
            <Loading noFullHeight />
          </Box>
        ) : (
          <Chart
            options={{
              ...chartOptions,
              colors: buildTransactionsChartColors(theme, dataKeys, colorConfig)
            }}
            series={chartData}
            type='line'
            height={CHART_TRANSACTIONS_HEIGHT}
          />
        )}
      </Box>

      <ChartLabels
        data={chartLabels}
        display='flex'
        justifyContent='center'
        size={3}
        colorRefMethod={colorRefMethod}
      />
    </DashboardCard>
  )
}

export default memo(TransactionsChart)
