import React, { memo } from 'react'
import { Trans } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { ButtonBase } from '@mui/material'
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'

import Link from '../shared/link/Link'
import DefaultText from '../shared/text/DefaultText'
import ActionCard from './ActionCard'
import {
  DASHBOARD_EMAIL,
  SUCCESS_PLAYBOOK_URL
} from '../../constants/externalResources'
import {
  buildEmailLinkParams,
  sumOfArrayElements
} from '../../util/app/app.helpers'
import MilestoneProgress from '../shared/ProgressBar/MilestoneProgress'
import { formatTicks } from '../../util/string.helpers'
import GetRewardPoints from './GetRewardPoints'
import ConfettiAnimation from '../shared/ConfettiAnimation/ConfettiAnimation'
import { isMilestoneCompleted } from '../../util/dashboard.helpers'

const RoadToSuccess = ({
  tokenProfile: { id, symbol },
  isDashboardSetupReady,
  membershipMilestonesData,
  milestoneAnimationStatus,
  onCompleteMilestoneAnimation,
  t
}) => {
  return (
    <ActionCard
      title={t('dashboard.road-to-success')}
      description={
        <>
          {t('dashboard.road-to-success-desc')}
          <GetRewardPoints
            points={sumOfArrayElements(
              membershipMilestonesData?.map((data) => data.points)
            )}
          />
        </>
      }
      buttonLabel={t('home.success-playbook')}
      to={SUCCESS_PLAYBOOK_URL}
      isOutside
      icon={faCircleInfo}
    >
      {isDashboardSetupReady && (
        <DefaultText
          label={
            <Trans i18nKey='dashboard.milestone-note'>
              <Link
                isEmail
                label={DASHBOARD_EMAIL}
                to={
                  DASHBOARD_EMAIL +
                  buildEmailLinkParams(
                    t('dashboard.milestone-achieved', { tokenId: id }),
                    t('dashboard.please-reward-for-milestone')
                  )
                }
              />
            </Trans>
          }
          sx={{ mt: 0 }}
        />
      )}
      {membershipMilestonesData?.map((milestoneData, index) => (
        <Milestone
          key={index}
          t={t}
          symbol={symbol}
          milestoneAnimationStatus={milestoneAnimationStatus}
          onCompleteMilestoneAnimation={onCompleteMilestoneAnimation}
          {...milestoneData}
        />
      ))}
    </ActionCard>
  )
}

export default memo(RoadToSuccess)

const Milestone = ({
  t,
  label,
  symbol,
  points,
  completed,
  count,
  link,
  id,
  milestoneAnimationStatus,
  onCompleteMilestoneAnimation,
  mandatory,
  ...restProps
}) => {
  return (
    <ButtonBase
      component={RouterLink}
      to={link}
      disableRipple
      disableTouchRipple
      sx={{ width: '100%', mb: 2, mt: 1, display: 'block' }}
    >
      <MilestoneProgress
        title={
          mandatory
            ? label
            : t(label, {
                ...restProps,
                symbol,
                count: count ? formatTicks(count) : ''
              })
        }
        description={t('dashboard.reward-symbol-label', {
          points
        })}
        completed={completed}
        rootSx={{ mb: 0, mt: 0 }}
      />
      {isMilestoneCompleted(completed) && !milestoneAnimationStatus?.[id] && (
        <ConfettiAnimation
          id={id}
          onComplete={() => onCompleteMilestoneAnimation(id)}
        />
      )}
    </ButtonBase>
  )
}
