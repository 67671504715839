import React, { memo } from 'react'
import { Box, Grid } from '@mui/material'

import Text from '../text/Text'

const ChartLabels = ({ data, size = 6, colorRefMethod, ...restProps }) => {
  return (
    <Grid container spacing={1} marginTop={1} {...restProps}>
      {data &&
        Object.keys(data).map((type, index) => {
          const color = colorRefMethod(type)
          return (
            <Grid
              item
              xs={12}
              sm={size}
              key={index}
              display='flex'
              alignItems='center'
            >
              <Box
                sx={{
                  backgroundColor: `${color}.main`,
                  borderRadius: '50%',
                  height: 12,
                  width: 12,
                  mr: 1
                }}
              />
              <Text color={`${color}.main`} variant='h6' label={data[type]} />
            </Grid>
          )
        })}
    </Grid>
  )
}

export default memo(ChartLabels)
