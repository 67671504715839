import React, { useEffect, Suspense } from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { hotjar } from 'react-hotjar'
import GoogleTagManager from 'react-gtm-module'

import packageJson from '../package.json'
import AppRoutes from './AppRoutes'
import { store } from './store'
import history from './history'
import i18n from './i18n'
import { APP_CONFIG, GOOGLE_RECAPTCHA_SCRIPT_URL } from './constants/appConfig'
import Loading from './components/shared/Loading/Loading'
import ThemeWrapper from './ThemeWrapper'
import { isProduction, scrollToTop } from './util/app/app.helpers'
import { useScript } from './hooks/useScript'
import { clearCacheOnNewAppVersion } from './util/cache.helpers'
import SandboxBoxModeBanner from './components/dashboardLayout/banner/SandboxBoxModeBanner'

const Root = () => {
  useScript(
    `${GOOGLE_RECAPTCHA_SCRIPT_URL}${APP_CONFIG.RECAPTCHA_ID}`,
    'recaptcha'
  )

  useEffect(() => {
    clearCacheOnNewAppVersion(packageJson)

    // Google Tag Manager
    const googleTagManagerId = APP_CONFIG.GOOGLE_TAG_MANAGER_ID
    if (googleTagManagerId) {
      GoogleTagManager.initialize({
        gtmId: googleTagManagerId,
        dataLayerName: process.env.REACT_APP_ENV
      })
    }

    // Hotjar initialize
    const hotjarConfig = APP_CONFIG.HOTJAR_CONFIG
    if (hotjarConfig.id) {
      hotjar.initialize(hotjarConfig.id, hotjarConfig.version)
    }
  }, [])

  history.listen(() => {
    scrollToTop()
  })

  return (
    <Provider store={store}>
      <Suspense fallback={<Loading />}>
        <ThemeWrapper>
          <I18nextProvider i18n={i18n}>
            {!isProduction() && <SandboxBoxModeBanner />}
            <Router history={history}>
              <AppRoutes />
            </Router>
          </I18nextProvider>
        </ThemeWrapper>
      </Suspense>
    </Provider>
  )
}

export default Root
