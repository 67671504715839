import React, { memo } from 'react'
import QrReader from 'react-qr-reader'
import styled from '@emotion/styled'

import CustomDialog from '../CustomDialog/CustomDialog'
import { baseTheme } from '../../global/Theme-variable'

const ScanQrCode = ({ onScan, onError, ...restProps }) => {
  return (
    <CustomDialog
      open
      maxWidth='xs'
      {...restProps}
      customCss={{ m: 'auto' }}
      body={
        <QrCodeContainer
          onError={onError}
          onScan={onScan}
          facingMode='environment'
          delay={300}
        />
      }
    />
  )
}

export default memo(ScanQrCode)

const QrCodeContainer = styled(QrReader)`
  height: 290px;
  width: 290px;
  margin-top: 16px;
  div {
    border: none !important;
    box-shadow: none !important;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
    border: 2px solid ${baseTheme.palette.darkGrey.main};
    border-radius: 10px;
  }
`
