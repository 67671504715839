import { mergeToNewObject } from '../../../util/object.helpers'
import { EXCHANGE_NFT_ACTION } from '../../../constants/actions'
import {
  DEFAULT_CONTAINER_STATE,
  EXCHANGE_MARKETPLACE_PROCESS_STEP
} from '../../../constants/containerStates'

export const buyNftProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case EXCHANGE_NFT_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, {
        [payload.property]: payload.value
      })

    case EXCHANGE_NFT_ACTION.ON_BUY:
      return mergeToNewObject(state, {
        isProcessing: true
      })

    case EXCHANGE_NFT_ACTION.ON_BUY_SUCCESS:
      return mergeToNewObject(state, {
        isProcessing: false,
        popupState: EXCHANGE_MARKETPLACE_PROCESS_STEP.CONFIRMED_NFT_PURCHASE
      })

    case EXCHANGE_NFT_ACTION.ON_BUY_FAILED:
      return mergeToNewObject(state, {
        isProcessing: false,
        popupState: DEFAULT_CONTAINER_STATE.SOMETHING_WENT_WRONG
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  amountToPurchase: '',
  error: {},
  popupState: DEFAULT_CONTAINER_STATE.NONE
}

export const EXCHANGE_BUY_NFT_ACTION_DISPATCH = {
  AMOUNT_TO_PURCHASE: 'amountToPurchase',
  ERROR: 'error',
  POPUP_STATE: 'popupState'
}
