import React, { memo } from 'react'
import { Grid } from '@mui/material'

const CardListGrid = ({ children }) => {
  return (
    <Grid item xs={12}>
      <Grid container spacing={4}>
        {children}
      </Grid>
    </Grid>
  )
}

export default memo(CardListGrid)
