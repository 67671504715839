import { combineEpics, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators'

import { SURVEY_ACTION, USER_SURVEY_ACTION } from '../../constants/actions'
import apiService from '../../services/api'
import { wrapUserAccessToken } from '../../util/auth.helpers'
import { logger } from '../../util/log.helpers'
import {
  buildCreateUserSurveyData,
  buildMarketplaceSurvey,
  buildUpdateUserSurveyData,
  formatUserSurveysToObject,
  formatUserSurveyToObject
} from '../../util/survey.helpers'
import { extractBrandId } from '../../util/epics.helpers'

const handleSubmitUserSurvey = (action$, state$) =>
  action$.pipe(
    ofType(SURVEY_ACTION.ON_SUBMIT_USER_SURVEY),
    mergeMap(({ payload: { surveyId, questionId, liked } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .submitUserSurvey(
            buildCreateUserSurveyData(
              surveyId,
              {
                [questionId]: { liked }
              },
              state$
            ),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: SURVEY_ACTION.ON_SUBMIT_USER_SURVEY_SUCCESS,
              payload: formatUserSurveyToObject(response.data)
            })),
            catchError((err) =>
              of({
                type: SURVEY_ACTION.ON_SUBMIT_USER_SURVEY_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleUpdateUserSurvey = (action$, state$) =>
  action$.pipe(
    ofType(SURVEY_ACTION.ON_UPDATE_USER_SURVEY),
    mergeMap(({ payload: { surveyId, id, questionId, liked } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .updateUserSurvey(
            id,
            buildUpdateUserSurveyData(
              { surveyId, response: { [questionId]: { liked } } },
              state$
            ),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: SURVEY_ACTION.ON_UPDATE_USER_SURVEY_SUCCESS,
              payload: formatUserSurveyToObject(response.data)
            })),
            catchError((err) =>
              of({
                type: SURVEY_ACTION.ON_UPDATE_USER_SURVEY_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleGetUserSurveyResponses = (action$) =>
  action$.pipe(
    ofType(SURVEY_ACTION.ON_GET_USER_SURVEYS),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService.getUserSurveys(accessToken).pipe(
          tap((data) => logger(data)),
          map(({ response }) => ({
            type: SURVEY_ACTION.ON_GET_USER_SURVEYS_SUCCESS,
            payload: formatUserSurveysToObject(response.data)
          })),
          catchError((err) =>
            of({
              type: SURVEY_ACTION.ON_GET_USER_SURVEYS_FAILED,
              payload: err
            })
          )
        )
      )
    )
  )

const handleGetBrandSurveyResponses = (action$, state$) =>
  action$.pipe(
    ofType(SURVEY_ACTION.ON_GET_BRAND_SURVEYS),
    map(() => ({
      brandId: extractBrandId(state$)
    })),
    filter(({ brandId }) => brandId),
    mergeMap(({ brandId }) =>
      wrapUserAccessToken((accessToken) =>
        apiService.getBrandSurveys(brandId, accessToken).pipe(
          tap((data) => logger(data)),
          map(({ response }) => ({
            type: SURVEY_ACTION.ON_GET_BRAND_SURVEYS_SUCCESS,
            payload: formatUserSurveysToObject(response.data)
          })),
          catchError((err) =>
            of({
              type: SURVEY_ACTION.ON_GET_BRAND_SURVEYS_FAILED,
              payload: err
            })
          )
        )
      )
    )
  )

const handleGetListUserSurveyResponses = (action$, state$) =>
  action$.pipe(
    ofType(SURVEY_ACTION.ON_GET_LIST_USER_SURVEYS),
    map(({ payload }) => ({
      brandId: extractBrandId(state$),
      payload
    })),
    filter(({ brandId }) => brandId),
    mergeMap(({ brandId, payload }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getListUserSurveys(
            brandId,
            buildListSurveysParams(payload),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: SURVEY_ACTION.ON_GET_LIST_USER_SURVEYS_SUCCESS,
              payload: response.data
            })),
            catchError((err) =>
              of({
                type: SURVEY_ACTION.ON_GET_LIST_USER_SURVEYS_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleGetSurveyResponseAnalytics = (action$) =>
  action$.pipe(
    ofType(SURVEY_ACTION.ON_GET_USER_SURVEYS_SUCCESS),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService.getUserSurveyAnalytics(accessToken).pipe(
          tap((data) => logger(data)),
          map(({ response }) => ({
            type: USER_SURVEY_ACTION.ON_GET_SURVEY_ANALYTICS_SUCCESS,
            payload: response.data
          })),
          catchError((err) =>
            of({
              type: USER_SURVEY_ACTION.ON_GET_SURVEY_ANALYTICS_FAILED,
              payload: err
            })
          )
        )
      )
    )
  )

export const surveyEpic = combineEpics(
  handleSubmitUserSurvey,
  handleUpdateUserSurvey,
  handleGetUserSurveyResponses,
  handleGetBrandSurveyResponses,
  handleGetListUserSurveyResponses,
  handleGetSurveyResponseAnalytics
)

const buildListSurveysParams = ({ partner }) => {
  return {
    requesting_brand_id: partner.brand.id,
    survey_id: buildMarketplaceSurvey({
      id: partner.tokenExchangeWhitelist.toTokenId
    })
  }
}
