export const COMMON_TOKEN_TYPE = {
  MILES: 'miles',
  CRYPTO: 'crypto',
  FIAT: 'fiat',
  GIFT_CARD: 'gift_card',
  DONATION: 'donation',
  MERCHANDISE: 'merchandise'
}

export const TOKEN_TYPE = {
  ERC_20: 'erc20',
  EXTERNAL: 'external',
  ERC_721: 'erc721',
  SHOPIFY: 'shopify',
  ...COMMON_TOKEN_TYPE
}

export const ALL_TOKENS_EXCEPT_NFT = [
  TOKEN_TYPE.ERC_20,
  TOKEN_TYPE.EXTERNAL,
  TOKEN_TYPE.MILES,
  TOKEN_TYPE.CRYPTO,
  TOKEN_TYPE.FIAT,
  TOKEN_TYPE.GIFT_CARD,
  TOKEN_TYPE.DONATION
]

export const NATURAL_NUMBER_VALIDATION_PATTERN = /^[0-9]*[1-9]+$|^[1-9]+[0-9]*$/

export const TOKEN_WHITELIST_STATUS = {
  PENDING: 'pending',
  SETUP: 'setup',
  ACTIVE: 'active',
  PAUSED: 'paused',
  STOPPED: 'stopped',
  REJECTED: 'rejected',
  CANCELLED: 'cancelled'
}

export const QB_TOKEN_STATUS = {
  PENDING: 'pending',
  ACTIVE: 'active',
  PAUSED: 'paused',
  STOPPED: 'stopped',
  REJECTED: 'rejected'
}

export const TOKEN_STATS_FILTER = {
  THIS_WEEK: 'thisWeek',
  LAST_WEEK: 'lastWeek'
}
