import React, { memo } from 'react'
import { Box } from '@mui/material'

import { TRANSPARENT_BLACK } from '../../../styles/common'

const MenuDivider = () => {
  return (
    <Box
      sx={{
        width: '1px',
        backgroundColor: TRANSPARENT_BLACK,
        height: '25px'
      }}
      ml={{ sm: 1 }}
      mr={{ sm: 1 }}
    />
  )
}

export default memo(MenuDivider)
