import React, { memo } from 'react'
import { Grid } from '@mui/material'

import { NFTS_PROFILE_DISPATCH } from '../../../store/reducers/nfts/nft'
import CustomDialog from '../../shared/CustomDialog/CustomDialog'
import Input from '../../shared/inputs/Input'
import {
  transWithArgs,
  validPointValueRule
} from '../../../util/validation.helpers'
import { QB_LABEL_TEXT } from '../../../constants/messages'
import { formatTicks } from '../../../util/string.helpers'
import Dropdown from '../../shared/inputs/Dropdown'
import { buildMarketplaceOptions } from '../../../util/nft.helpers'
import { calculatePointValue } from '../../../util/token.helpers'

const ListNft = ({
  t,
  onClose,
  onSubmit,
  dispatchAction,
  nftProfile: { error, isProcessing, pointValue, marketplace, isCreatedNft },
  tokenProfile: { name, symbol, onramp }
}) => {
  return (
    <CustomDialog
      isForm
      title={t('nft.list-nft-title')}
      description={t('nft.list-nft-desc')}
      open
      onClose={onClose}
      cancelProps={{
        label: t(isCreatedNft ? 'common.skip' : QB_LABEL_TEXT.CANCEL)
      }}
      submitProps={{
        label: `${t('nft.list')} ${t('sidebar.nft')}`,
        disabled: isProcessing
      }}
      body={
        <Grid container spacing={3}>
          <Grid item md={5} xs={12}>
            <Input
              id={NFTS_PROFILE_DISPATCH.POINT_VALUE}
              label={t('nft.point-value')}
              helpText={t('nft.point-value-desc', {
                symbol,
                name
              })}
              startIcon={symbol}
              value={pointValue}
              errorMessage={transWithArgs(
                t,
                error[NFTS_PROFILE_DISPATCH.POINT_VALUE]
              )}
              onChange={(value) =>
                dispatchAction(NFTS_PROFILE_DISPATCH.POINT_VALUE, value)
              }
              validate={[validPointValueRule]}
              size='small'
              bottomLabel={t('nft.usd-value', {
                value: formatTicks(calculatePointValue(pointValue, onramp))
              })}
            />
          </Grid>
          <Grid item md={7} xs={12}>
            <Dropdown
              id={NFTS_PROFILE_DISPATCH.MARKETPLACE}
              label={t('sidebar.marketplace')}
              helpText={t('nft.marketplace-option-desc')}
              options={buildMarketplaceOptions(t)}
              onChange={({ target }) =>
                dispatchAction(NFTS_PROFILE_DISPATCH.MARKETPLACE, target.value)
              }
              value={marketplace}
              errorMessage={transWithArgs(
                t,
                error[NFTS_PROFILE_DISPATCH.MARKETPLACE]
              )}
              size='small'
            />
          </Grid>
        </Grid>
      }
      onSubmit={onSubmit}
      t={t}
    />
  )
}

export default memo(ListNft)
