import React, { memo } from 'react'
import { TableRow } from '@mui/material'

import Text from '../shared/text/Text'
import TableHeaderBody from '../shared/DataTable/TableHeaderBody'
import {
  RECENT_TXN_COLUMN_FIELDS,
  buildTransactionsTableHeaders
} from '../../util/transaction/transaction.helpers'
import TransactionDataRow from '../shared/TransactionDataRow/TransactionDataRow'
import Loading from '../shared/Loading/Loading'

const MemberRecentTransactions = ({
  membersProfile: { recentTransactions, recentTransactionsLoading },
  tokenProfile: { symbol, id },
  currentBrandId,
  theme,
  t,
  showTxnForExchange
}) => {
  return (
    <>
      <Text
        variant='h4'
        fontWeight={500}
        label={t('analytics.most-recent-transactions')}
      />
      {recentTransactionsLoading ? (
        <Loading noFullHeight />
      ) : (
        recentTransactions &&
        recentTransactions.length > 0 && (
          <TableHeaderBody
            headers={buildTransactionsTableHeaders(t, { symbol })}
            data={recentTransactions.map((transaction, index) => (
              <TableRow key={index}>
                <TransactionDataRow
                  {...transaction}
                  currentTokenId={id}
                  currentBrandId={currentBrandId}
                  t={t}
                  theme={theme}
                  showTxnForExchange={showTxnForExchange}
                  customColumns={RECENT_TXN_COLUMN_FIELDS}
                />
              </TableRow>
            ))}
            size='small'
          />
        )
      )}
    </>
  )
}

export default memo(MemberRecentTransactions)
