import React, { memo } from 'react'
import { Box } from '@mui/material'

import SignupInputContent from './SignupInputContent'
import { LOG_IN_URL } from '../../constants/navigation'
import PageContainer from '../shared/PageContainer/PageContainer'
import Link from '../shared/link/Link'
import Text from '../shared/text/Text'
import CustomForm from '../shared/forms/customForm/CustomForm'
import CustomButton from '../shared/buttons/CustomButton'
import UnauthorizedLayoutContainer from '../../containers/UnauthorizedLayoutContainer'
import { QB_ACTION_SUBMIT } from '../../constants/input'
import SocialMediaButtonsContainer from '../../containers/socialMediaButtons/SocialMediaButtonsContainer'
import TermsAndConditions from './TermsAndConditions'

const Signup = ({ signupProfile, dispatchAction, onSignupSubmit, t }) => {
  const title = t('sign-up.create-your-account')
  const action = t('sign-up.sign-up')
  return (
    <PageContainer title={title}>
      <UnauthorizedLayoutContainer title={title}>
        <Box display='flex' alignItems='center'>
          <Text
            variant='h6'
            label={t('sign-up.already-have-an-account')}
            color='textSecondary'
            sx={{ mr: 1 }}
          />
          <Link to={LOG_IN_URL} label={t('log-in.log-in')} fontWeight={500} />
        </Box>
        <SocialMediaButtonsContainer action={action} />
        <CustomForm onFormSubmit={onSignupSubmit}>
          <SignupInputContent
            signupProfile={signupProfile}
            dispatchAction={dispatchAction}
            t={t}
          />
          <TermsAndConditions t={t} />
          <CustomButton
            type={QB_ACTION_SUBMIT}
            label={action}
            size='large'
            disabled={signupProfile.isProcessing}
          />
        </CustomForm>
      </UnauthorizedLayoutContainer>
    </PageContainer>
  )
}

export default memo(Signup)
