import React, { memo, useRef, useEffect, useState } from 'react'
import { Box } from '@mui/material'

import { getTopHeight } from '../../../util/style.helpers'

const FixedTop = ({ children }) => {
  const top = getTopHeight(85)
  const anchorRef = useRef(null)
  const [isFixedPosition, setIsFixedPosition] = useState(false)
  useEffect(() => {
    const handleFixedTop = () => {
      const anchorTop = anchorRef?.current?.offsetTop
      if (anchorTop && window.scrollY > anchorTop) {
        setIsFixedPosition(true)
      } else {
        setIsFixedPosition(false)
      }
    }

    window.addEventListener('scroll', handleFixedTop)

    return () => window.removeEventListener('scroll', handleFixedTop)
  }, [])

  const parentElement = anchorRef?.current?.parentElement

  return (
    <>
      <Box
        sx={
          isFixedPosition
            ? {
                position: 'fixed',
                top,
                zIndex: 5,
                width: parseInt(parentElement?.offsetWidth) - 32 // @TODO: hard coded for now, need to change dynamically
              }
            : {}
        }
      >
        {children}
      </Box>
      <div ref={anchorRef}></div>
    </>
  )
}

export default memo(FixedTop)
