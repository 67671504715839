import React, { memo } from 'react'
import { Skeleton } from '@mui/material'

const CustomSkeleton = (props) => {
  return <Skeleton {...props} />
}

CustomSkeleton.defaultProps = {
  animation: 'wave'
}

export default memo(CustomSkeleton)
