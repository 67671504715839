import { AUTH_SIGN_UP_ACTION } from './../../../constants/actions'
import {
  DEFAULT_CONTAINER_STATE,
  SIGN_UP_PROCESS_STEP
} from '../../../constants/containerStates'
import { ERROR_TEXT, QB_ERROR_TEXT } from '../../../constants/messages'
import { QB_COGNITO_ERRORS } from '../../../constants/apiErrors'
import {
  mergeToNewObject,
  extractInputValueAndError
} from '../../../util/object.helpers'
import { formatValidator } from '../../../util/validation.helpers'
import { logToAppSignal } from '../../../util/app/appSignal.helpers'

export const signupProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case AUTH_SIGN_UP_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case AUTH_SIGN_UP_ACTION.CHANGE_INPUT_AND_ERROR_STATE:
      return mergeToNewObject(
        state,
        extractInputValueAndError(payload, state.error)
      )

    case AUTH_SIGN_UP_ACTION.ON_SIGN_UP:
      return mergeToNewObject(state, {
        isProcessing: true
      })

    case AUTH_SIGN_UP_ACTION.ON_SIGN_UP_SUCCESS:
      return mergeToNewObject(state, {
        signupState: SIGN_UP_PROCESS_STEP.SIGN_UP_SUCCESS,
        isProcessing: false
      })

    case AUTH_SIGN_UP_ACTION.ON_SIGN_UP_FAILED:
      return mergeToNewObject(state, {
        error: extractSignupErrors(payload, state),
        isProcessing: false
      })

    case AUTH_SIGN_UP_ACTION.ON_RESEND_EMAIL_SUCCESS:
      return mergeToNewObject(state, {
        signupState: SIGN_UP_PROCESS_STEP.RESEND_VERIFICATION_SUCCESS
      })

    case AUTH_SIGN_UP_ACTION.ON_RESEND_EMAIL_FAILED:
      return mergeToNewObject(state, {
        signupState: SIGN_UP_PROCESS_STEP.RESEND_VERIFICATION_FAILED
      })

    case AUTH_SIGN_UP_ACTION.ON_CONFIRM_SUCCESS:
      return mergeToNewObject(state, {
        signupState: SIGN_UP_PROCESS_STEP.SIGN_UP_VERIFIED
      })

    case AUTH_SIGN_UP_ACTION.RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  signupState: DEFAULT_CONTAINER_STATE.NONE,
  error: {},
  isProcessing: false
}

export const SIGN_UP_PROFILE_DISPATCH = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL_ADDRESS: 'email',
  PASSWORD: 'password',
  SIGN_UP_STATE: 'signupState',
  ERROR: 'error'
}

const extractSignupErrors = (cognito, { firstName, lastName, email }) => {
  const code = cognito?.code
  switch (code) {
    case QB_COGNITO_ERRORS.USERNAME_EXISTS_EXCEPTION:
      return {
        email: formatValidator(ERROR_TEXT.EMAIL_ALREADY_EXISTS)
      }
    default:
      logToAppSignal(code, cognito?.message, {
        firstName,
        lastName,
        email
      })
      return {
        email: formatValidator(QB_ERROR_TEXT.SOMETHING_WENT_WRONG)
      }
  }
}
