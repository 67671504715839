import React, { memo } from 'react'
import { Trans } from 'react-i18next'

const GetRewardPoints = ({ points }) => {
  return (
    <Trans
      i18nKey='dashboard.get-rewarded-desc'
      values={{
        points
      }}
    ></Trans>
  )
}

export default memo(GetRewardPoints)
