export const SIGN_UP_PROCESS_STEP = {
  SIGN_UP_SUCCESS: 'signUpSuccess',
  RESEND_VERIFICATION_SUCCESS: 'resendVerificationSuccess',
  RESEND_VERIFICATION_FAILED: 'resendVerificationFailed',
  SIGN_UP_VERIFIED: 'signUpVerified'
}

export const DEFAULT_CONTAINER_STATE = {
  NONE: '',
  SOMETHING_WENT_WRONG: 'somethingWentWrong'
}

export const ACCOUNT_INIT_PROCESS_STEP = {
  CREATE_ACCOUNT_FAILED: 'createAccountFailed'
}

export const EXCHANGE_MARKETPLACE_PROCESS_STEP = {
  LIST_YOUR_POINT_POPUP: 'listYourPointPopup',
  EXCHANGE_CONNECTOR_DETAILS: 'exchangeConnectorDetails',
  ACCEPT_EXCHANGE_TERMS: 'acceptExchangeTerms',
  EXCHANGE_REQUEST_SENT: 'exchangeRequestSent',
  EXCHANGE_SETUP_DASHBOARD: 'exchangeSetupDashboard',
  MISSING_EXCHANGE_SETTINGS: 'missingExchangeSettings',

  REMOVE_EXCHANGE_REQUEST: 'removeExchangeRequest',
  REMOVE_EXCHANGE_FEEDBACK: 'removeExchangeFeedback',
  REMOVE_EXCHANGE_SUCCESS: 'removeExchangeSuccess',

  ACCEPT_EXCHANGE_SUCCESS: 'acceptExchangeSuccess',

  REJECT_EXCHANGE_REQUEST: 'rejectExchangeRequest',
  REJECT_EXCHANGE_FEEDBACK: 'rejectPartnerFeedback',
  REJECT_EXCHANGE_SUCCESS: 'rejectPartnerSuccess',

  CANCEL_EXCHANGE_REQUEST: 'cancelExchangeRequest',
  CANCEL_EXCHANGE_FEEDBACK: 'cancelPartnerFeedback',
  CANCEL_EXCHANGE_SUCCESS: 'cancelPartnerSuccess',

  ERROR_REJECTED_EXCHANGE: 'errorRejectedExchange',
  ERROR_DISCONNECTED_EXCHANGE: 'errorDisconnectedExchange',

  VIEW_RANK_INFO: 'viewRankInfo',

  NFT_DETAILS: 'nftDetails',

  CONFIRM_NFT_PURCHASE: 'confirmNftPurchase',
  CONFIRMED_NFT_PURCHASE: 'confirmedNftPurchase'
}

export const INTEGRATION_PROCESS_STEP = {
  CREATE_API_KEY: 'createAPIKey',
  DELETE_API_KEY: 'deleteAPIKey',
  SAVE_TOKEN_TYPE: 'saveTokenType'
}

export const TEAM_MEMBERS_PROCESS_STEP = {
  NO_BRAND_CREATED: 'noBrandCreated',
  ADD_TEAM_MEMBER: 'addTeamMember',
  REMOVE_TEAM_MEMBER: 'removeTeamMember',
  USER_HAS_NO_ACCOUNT: 'userHasNoAccount',
  USER_ALREADY_EXISTS: 'userAlreadyExists'
}

export const PACKAGE_BILLING_PROCESS_STEP = {
  CHANGE_PACKAGE: 'changePackage',
  NO_PACKAGE_SUBSCRIBED: 'noPackageSubscribed',
  REACTIVATE_PACKAGE: 'reactivatePackage'
}

export const ANALYTICS_PROCESS_STEP = {
  SENDING_REPORT_EMAIL: 'sendingReportEmail',
  MEMBER_MORE_ACTIONS: 'memberMoreActions',
  MEMBER_TRANSACTION_CONFIRMATION: 'memberTransactionConfirmation',
  MEMBER_TRANSACTION_COMPLETED: 'memberTransactionCompleted',
  REWARD_POINTS_BANK_MEMBER: 'rewardPointsBankMember',
  REWARD_EXCHANGE_MEMBER: 'rewardExchangeMember',
  REWARD_MEMBER_CONFIRMATION: 'rewardMemberConfirmation',
  REWARDED_MEMBER_SUCCESS: 'rewardedMemberSuccess',
  CHANGE_TXN_STATUS: 'changeTxnStatus',
  REFUND_MILES: 'refundMiles'
}

export const NFTS_PROCESS_STEP = {
  CREATE_NEW_NFT: 'createNewNft',
  FILE_TYPE_NOT_SUPPORTED: 'fileTypeNotSupported',
  NFT_DETAILS: 'nftDetails',
  TRANSFER_NFT: 'transferNft',
  REDEEM_EXCLUSIVE_CONTENT: 'redeemExclusiveContent',
  NFT_TRANSFERRED: 'nftTransferred',
  LIST_NFT: 'listNft',
  PENDING_NFT: 'pendingNft',
  USER_PERMISSIONS: 'userPermissions'
}

export const QR_CODE_PROCESS_STEP = {
  SCAN_CODE: 'scanCode',
  CAMERA_BLOCKED: 'cameraBlocked'
}

export const DASHBOARD_SETUP_PROCESS_STEP = {
  MIRROR_TRANSACTIONS_ON_CHAIN: 'mirrorTransactionsOnChain'
}

export const EXTENSION_PROCESS_STEP = {
  SETUP_DASHBOARD: 'extensionSetupDashboard',
  ACTIVATION_CHANGED: 'extensionActivationChanged',
  READ_ONLY_EXTENSION_TERMS: 'readOnlyExtensionTerms'
}
