import React, { memo } from 'react'
import { Box, Menu } from '@mui/material'

import { getTopHeight } from '../../../util/style.helpers'

const HeaderMenu = ({
  id,
  open,
  onClose,
  heading,
  body,
  width = 385,
  noTop = false,
  position = 'center',
  contentPadding = '30px',
  maxHeight
}) => {
  return (
    <Menu
      id={id}
      anchorEl={open}
      keepMounted
      open={Boolean(open)}
      onClose={onClose}
      sx={{
        '& .MuiMenu-paper': {
          width: `${width}px`,
          marginTop: '6px',
          maxHeight,
          ...(!noTop && { top: `${getTopHeight(64)}px !important` })
        },
        '& .MuiList-padding': {
          p: contentPadding
        }
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: position
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: position
      }}
    >
      {heading && (
        <Box
          sx={{
            mb: 1
          }}
        >
          {heading}
        </Box>
      )}
      {body}
    </Menu>
  )
}

export default memo(HeaderMenu)
