import { ERROR_TEXT } from '../constants/messages'
import { NATURAL_NUMBER_VALIDATION_PATTERN } from '../constants/token'
import { URL_VALIDATION_PATTERN } from '../constants/validation'
import { QB_ERROR_TEXT } from '../constants/messages'
import { formatTicks, trim } from './string.helpers'
import { mergeToNewObject } from './object.helpers'
import {
  elementIsInArray,
  elementIsInArrayCaseInsensitive
} from './array.helpers'
import { getFileExtension } from './app/app.helpers'

const requiredRule = (value) => {
  return !isValidRequired(value)
    ? formatValidator(QB_ERROR_TEXT.FIELD_REQUIRED)
    : ''
}

const checkValueExistsInListRule = (list, errorMessage) => (value) => {
  return elementIsInArrayCaseInsensitive(list, value) ? errorMessage : ''
}

const isValidRequired = (value) => {
  return value && trim(String(value)).length
}

const emailRule = (email) => {
  return !isValidEmail(email)
    ? formatValidator(QB_ERROR_TEXT.INVALID_EMAIL)
    : ''
}

const passwordRule = (password) => {
  return !isValidPassword(password)
    ? formatValidator(QB_ERROR_TEXT.INVALID_PASSWORD)
    : ''
}

const isValidEmail = (email) => {
  const valid =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return valid.test(email)
}

const isValidPassword = (password) => {
  const valid =
    /^[><?@+'`~^%&\\*\\[\]\\{\\}.!#|\\\\"$';,:;=/\\(\\),\-\w+]{8,99}$/
  return valid.test(password)
}

const isValidPositiveNumber = (value) => {
  return Number(value) > 0 || false
}

const checkForRequiredFields = (fields) => {
  const newErrorMessages = Object.entries(fields).reduce((curr, next) => {
    if (next.length > 1 && !next[1].length) {
      return mergeToNewObject(curr, {
        [next[0]]: formatValidator(QB_ERROR_TEXT.FIELD_REQUIRED)
      })
    }
    return curr
  }, {})

  return newErrorMessages
}

const checkValidations = (fields) => {
  const newErrorMessages = Object.entries(fields).reduce((curr, next) => {
    const { value, rules } = next[1]
    const invalid = checkRulesAgainstValue(rules, value)?.[0]
    if (invalid) {
      return mergeToNewObject(curr, { [next[0]]: invalid })
    }
    return curr
  }, {})

  return newErrorMessages
}

const positiveNumberRule = (number) => {
  return !isValidPositiveNumber(number)
    ? formatValidator(QB_ERROR_TEXT.INVALID_NUMBER_FORMAT)
    : ''
}

const checkRulesAgainstValue = (rules, value) => {
  if (rules && rules.length > 0) {
    return rules.map((rule) => rule(value)).filter((msg) => msg !== '')
  }
  return []
}

const isValidPositiveDecimal = (value) => {
  const valid = /^\d+(\.\d{1,18})?$/
  return valid.test(value)
}

const positiveDecimalRule = (number) => {
  return number <= 0 || !isValidPositiveDecimal(number)
    ? formatValidator(QB_ERROR_TEXT.INVALID_NUMBER_FORMAT)
    : ''
}

const maxRule =
  (max = 25) =>
  (value) => {
    const valueLength = value.length
    return valueLength > 0 && valueLength > max
      ? formatValidator(QB_ERROR_TEXT.CHARACTERS_MAX, { max: formatTicks(max) })
      : ''
  }

const minMaxRule =
  (min = 3, max = 25) =>
  (value) => {
    const valueLength = value.length
    return valueLength < min || valueLength > max
      ? formatValidator(QB_ERROR_TEXT.CHARACTERS_MIN_MAX, { min, max })
      : ''
  }

const greaterOrEqualRule = (targetValue, targetField) => (value) => {
  return !(value >= targetValue)
    ? formatValidator(QB_ERROR_TEXT.GREATER_OR_EQUAL_TO_VALUE, { targetField })
    : ''
}

const lowerOrEqualRule = (targetValue) => (value) => {
  return !(value <= targetValue)
    ? formatValidator('error.lower-or-equal-to-value', { targetValue })
    : ''
}

const transWithArgs = (t, messageWithArgs) => {
  if (messageWithArgs) {
    const { message, args } = messageWithArgs
    return t(message, args)
  }
  return ''
}

const isValidRewardCode = (rewardCode) => {
  if (!rewardCode) {
    return false
  }
  const cleanCode = rewardCode.replace(/[^a-zA-Z0-9]/g, '')
  const valid = /^[a-zA-Z0-9]{9}$/
  return valid.test(cleanCode) ? cleanCode : false
}

const isValidImage = (files) => {
  if (files.length && files[0].size < 1048576) {
    const extension = files[0].name.slice(-3)
    switch (extension) {
      case 'png':
      case 'jpg':
      case 'svg':
        return true

      default:
        return false
    }
  }
  return false
}

const formatValidator = (message, args) => {
  return { message, args }
}

const isValidPointValue = (value) => {
  return numberValidationRule().test(value)
}

const isValidSalesFeeValue = (value) => {
  return numberValidationRule(2, 2).test(value)
}

const numberValidationRule = (numLimit = 10, decimalPoints = 4) => {
  return new RegExp(
    `^(\\d{0,${numLimit}}(\\.\\d{0,${decimalPoints}})?|\\.?\\d{0,${decimalPoints}})$`
  )
}

const isValidNaturalNumber = (value) => {
  return NATURAL_NUMBER_VALIDATION_PATTERN.test(value)
}

const validPointValueRule = (number) => {
  return !isValidPointValue(number)
    ? formatValidator(QB_ERROR_TEXT.INVALID_NUMBER_FORMAT)
    : ''
}

const validNftSalesFeeRule = (number) => {
  return !isValidSalesFeeValue(number)
    ? formatValidator(QB_ERROR_TEXT.INVALID_NUMBER_FORMAT)
    : ''
}

const validNaturalNumberRule = (number) => {
  return !isValidNaturalNumber(number)
    ? formatValidator(QB_ERROR_TEXT.INVALID_NUMBER_FORMAT)
    : ''
}

const isValidUrl = (value) => {
  return value.match(URL_VALIDATION_PATTERN)
}

const validUrlRule = (url) => {
  return isValidRequired(url) && !isValidUrl(url)
    ? formatValidator(ERROR_TEXT.INVALID_URL)
    : ''
}

const exceedLimitRule = (max) => (value) => {
  return Number(value) > max
    ? formatValidator(ERROR_TEXT.EXCEED_MAX_LIMIT, {
        value: max
      })
    : ''
}

const checkFileExtensionRule = (extensions) => (value) => {
  return !elementIsInArray(extensions, getFileExtension(value))
    ? formatValidator('nft.file-type-not-supported')
    : ''
}

export {
  requiredRule,
  checkValueExistsInListRule,
  emailRule,
  passwordRule,
  checkForRequiredFields,
  checkValidations,
  positiveNumberRule,
  positiveDecimalRule,
  checkRulesAgainstValue,
  isValidRewardCode,
  isValidRequired,
  isValidEmail,
  isValidPassword,
  isValidPositiveNumber,
  isValidPositiveDecimal,
  isValidImage,
  maxRule,
  minMaxRule,
  transWithArgs,
  formatValidator,
  greaterOrEqualRule,
  lowerOrEqualRule,
  isValidPointValue,
  validPointValueRule,
  isValidUrl,
  validUrlRule,
  exceedLimitRule,
  validNaturalNumberRule,
  validNftSalesFeeRule,
  checkFileExtensionRule
}
