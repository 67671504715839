import React, { memo } from 'react'
import { Box } from '@mui/material'

import Text from '../shared/text/Text'
import FaIcon from '../shared/FaIcon/FaIcon'
import BaseCard from '../shared/BaseCard/BaseCard'
import { Trans } from 'react-i18next'

const WelcomeCard = ({ card, index, t }) => {
  const { title, icon, text } = card
  return (
    <BaseCard
      sx={{
        p: 0,
        border: (theme) => `1px solid ${theme.palette.background.default}`,
        borderRadius: '10px'
      }}
    >
      <Box
        display='flex'
        justifyContent='center'
        sx={{
          backgroundImage: ({ palette: { primary, secondary } }) =>
            `linear-gradient(135deg, ${primary.main}, ${secondary.main})`,
          height: 170
        }}
      >
        <Box
          sx={{
            color: (theme) => theme.palette.lightGrey.light,
            p: 4,
            textAlign: 'center'
          }}
        >
          <Box sx={{ pb: 1 }}>
            <FaIcon icon={icon} size='3x' />
          </Box>
          <Text
            label={`${index + 1}. ${t(title)}`}
            variant='h5'
            fontWeight={700}
          />
        </Box>
      </Box>
      <Box sx={{ p: '20px 15px', textAlign: 'center' }}>
        <Text
          label={<Translation text={text} t={t} />}
          color='textprimary'
          variant='h5'
        />
      </Box>
    </BaseCard>
  )
}

export default memo(WelcomeCard)

const Translation = ({ text: { i18nKey, bold1, bold2 }, t }) => {
  return (
    <Trans i18nKey={i18nKey} i18nIsDynamicList>
      <Text label={t(bold1)} variant='inherit' fontWeight={700} />
      {bold2 && <Text label={t(bold2)} variant='inherit' fontWeight={700} />}
    </Trans>
  )
}
