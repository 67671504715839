import React, { memo } from 'react'

import { QB_ERROR_TEXT } from '../../../constants/messages'
import { MULTILINE_FIELD_MAX_LENGTH } from '../../../constants/validation'
import { formatTicks } from '../../../util/string.helpers'
import Input from '../inputs/Input'

const MultilineField = ({ t, ...restProps }) => {
  return (
    <Input
      bottomLabel={t(QB_ERROR_TEXT.CHARACTERS_MAX, {
        max: formatTicks(MULTILINE_FIELD_MAX_LENGTH)
      })}
      inputProps={{ maxLength: MULTILINE_FIELD_MAX_LENGTH }}
      multiline
      rows={4}
      size='small'
      {...restProps}
    />
  )
}

export default memo(MultilineField)
