import { ajax } from 'rxjs/ajax'
import { catchError } from 'rxjs/operators'

import { API_RESPONSE, HTTP_METHOD } from '../../constants/api'
import { logToAppSignal } from './appSignal.helpers'
import { mergeToNewObject } from '../object.helpers'

const DEFAULT_HEADERS = {
  'content-type': 'application/json'
}

const getHeaders = (data) => {
  const { accessToken, recaptchaToken } = data || {}
  const headers = {}
  if (accessToken) {
    headers.Authorization = 'Bearer ' + accessToken
  }
  if (recaptchaToken) {
    headers.recaptcha_token = recaptchaToken
  }
  if (data.isFile) {
    headers['content-type'] = 'multipart/form-data'
  }
  return mergeToNewObject(DEFAULT_HEADERS, headers)
}

const withIntercept = (method, url, headers, body, isLogEverything = false) => {
  return ajax({ method, url, headers: getHeaders(headers), body }).pipe(
    catchError((error) => {
      const status = error.status
      if (
        isLogEverything ||
        (!(method === HTTP_METHOD.GET && status === API_RESPONSE.NOT_FOUND) &&
          !(method === HTTP_METHOD.POST && status === API_RESPONSE.BAD_REQUEST))
      ) {
        const { message, response } = error
        logToAppSignal(
          message,
          response?.error || response?.errors?.detail || 'Failed API',
          {
            url,
            method,
            request: body,
            status,
            response
          }
        )
      }
      throw error
    })
  )
}

export { withIntercept }
