import { TOKEN_ACTIONS } from '../../../constants/actions'
import { calculateExchangeInOutCount } from '../../../util/chart/transactionsGraph.helpers'
import { mergeToNewObject } from '../../../util/object.helpers'

export const tokenStatsProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TOKEN_ACTIONS.ON_GET_TOKEN_STATS_SUCCESS:
      return mergeToNewObject(state, handleTokenStatsResponse(payload))

    case TOKEN_ACTIONS.ON_RESET_TOKEN_STATS:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  exchangeInTxVolume: 0,
  exchangeOutTxVolume: 0,
  exchangeTxCount: 0
}

const handleTokenStatsResponse = ({
  exchangeInTxVolume,
  exchangeOutTxVolume,
  exchangeInTxCount,
  exchangeOutTxCount
}) => {
  return {
    exchangeInTxVolume,
    exchangeOutTxVolume,
    exchangeTxCount: calculateExchangeInOutCount({
      exchangeInTxCount,
      exchangeOutTxCount
    })
  }
}
