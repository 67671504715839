import React, { memo, useState } from 'react'
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleXmark,
  faGlobe,
  faPlusCircle
} from '@fortawesome/pro-light-svg-icons'
import { Grid } from '@mui/material'

import CustomDialog from '../../shared/CustomDialog/CustomDialog'
import FaIcon from '../../shared/FaIcon/FaIcon'
import CustomButton from '../../shared/buttons/CustomButton'
import ExchangeBrandCard from '../../exchange/ExchangeBrandCard'
import DisplayLabel from '../../shared/DisplayLabel/DisplayLabel'
import {
  displayCustomCountryList,
  displayMembersLabel
} from '../../../util/token.helpers'
import ExchangeStatusChip from '../../exchange/ExchangeStatusChip'
import {
  EXCHANGE_DETAILS_TAB_TYPE,
  EXCHANGE_PARTNER_TYPES
} from '../../../constants/exchange'
import ExchangeRateLabel from '../../exchange/ExchangeRateLabel'
import BrandTokenLabel from '../../exchange/BrandTokenLabel'
import RegionsLabel from '../../exchange/RegionsLabel'
import MaxValueConfigForm from './MaxValueConfigForm'
import TabContent from '../../shared/TabContent/TabContent'
import {
  buildExchangeTypeLabel,
  fromExchangeDetailsTabIdToType,
  fromExchangeDetailsTypeToTabId
} from '../../../util/exchange.helpers'
import RewardConditions from './RewardConditions'
import Loading from '../../shared/Loading/Loading'
import { checkBuyPointsConditions } from '../../../util/extensions/extensions.helpers'
import { TOKEN_TYPE } from '../../../constants/token'

const ExchangePartnerDetails = ({
  t,
  partner,
  onSubmit,
  onClose,
  onWebsiteClick,
  tokenProfile,
  selectedTab,
  onAcceptRequest,
  onExchangeConfigSubmit,
  changeExchangeConfig,
  exchangeConfigProfile,
  dashboardConfigs,
  partnersProfile: { isProcessing, active },
  exchangeTerms,
  brandSurvey,
  showRewardConditions,
  isSellerExchangeTermsProcessing,
  exchangePartnersStats
}) => {
  const [subTab, setSubTab] = useState(EXCHANGE_DETAILS_TAB_TYPE.REWARD_DETAILS)
  const {
    brand,
    name,
    symbol,
    countries,
    offramp,
    onramp,
    estimatedMemberCountLower,
    estimatedMemberCountHigher,
    metadata,
    tokenExchangeWhitelist,
    isActiveExchange,
    isFrom,
    type
  } = partner

  const [
    submitLabel,
    submitIcon,
    submitColor,
    variant,
    additionalAction,
    disabledMessage
  ] = buildSubmitActionStyles(
    selectedTab,
    partner,
    onAcceptRequest,
    tokenProfile,
    exchangePartnersStats,
    active,
    t
  )

  const handleChangeTab = (event, selectedTabId) => {
    const newTab = fromExchangeDetailsTabIdToType(selectedTabId)
    if (subTab !== newTab) {
      setSubTab(newTab)
    }
  }

  const buildContent = () => {
    switch (subTab) {
      case EXCHANGE_DETAILS_TAB_TYPE.REWARD_DETAILS:
        return (
          <Grid container spacing={2} mt={1}>
            {type !== TOKEN_TYPE.SHOPIFY && (
              <Grid item xs={12} sm={3}>
                <RegionsLabel
                  isCrypto={type === TOKEN_TYPE.CRYPTO}
                  countries={countries}
                  t={t}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={3}>
              <DisplayLabel
                label={t('sidebar.members')}
                value={displayMembersLabel({
                  estimatedMemberCountLower,
                  estimatedMemberCountHigher
                })}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <DisplayLabel
                label={t('common.type')}
                value={t(
                  `exchange.filter-options.${buildExchangeTypeLabel(type)}`
                )}
              />
            </Grid>
            <Grid item xs={3}>
              {tokenProfile?.symbol && (
                <ExchangeRateLabel
                  tokenProfile={tokenProfile}
                  targetToken={{ onramp, offramp, symbol, isFrom }}
                  t={t}
                  type={type}
                />
              )}
            </Grid>
            {/* <Grid item xs={3}>
            <DisplayLabel
              label={
                <>
                  {t('exchange.login-id-type')}
                  <PopoverHelpText
                    label={t('exchange.login-id-type-help-text')}
                  />
                </>
              }
              value={t(
                `exchange.login-id-types.${brand?.brandConfig?.loginIdType}`
              )}
            />
          </Grid> */}
            {isActiveExchange && (
              <MaxValueConfigForm
                onFormSubmit={onExchangeConfigSubmit}
                dispatchAction={changeExchangeConfig}
                exchangeConfigProfile={exchangeConfigProfile}
                tokenProfile={tokenProfile}
                partner={partner}
                dashboardConfigs={dashboardConfigs}
                isProcessing={isProcessing}
                t={t}
              />
            )}
            <Grid item xs={12}>
              <DisplayLabel
                label={t('common.description')}
                value={metadata?.description?.data}
              />
            </Grid>
            <Grid item xs={12}>
              <DisplayLabel
                label={t('common.all-countries')}
                value={displayCustomCountryList(countries, t)}
              />
            </Grid>
          </Grid>
        )
      case EXCHANGE_DETAILS_TAB_TYPE.REWARD_CONDITIONS:
        return (
          <RewardConditions
            exchangeTerms={exchangeTerms}
            brandSurvey={brandSurvey}
            isActiveExchange={isActiveExchange || showRewardConditions}
            mt={1}
          />
        )

      default:
        return null
    }
  }
  return (
    <CustomDialog
      open
      onClose={onClose}
      onSubmit={onSubmit}
      submitProps={{
        label: t(submitLabel),
        startIcon: submitIcon && <FaIcon icon={submitIcon} />,
        color: submitColor,
        variant,
        disabledMessage,
        disabled: isProcessing || Boolean(disabledMessage)
      }}
      additionalActions={
        <>
          <CustomButton
            label={t('common.website')}
            startIcon={<FaIcon icon={faGlobe} />}
            color='primary'
            onClick={() => onWebsiteClick(partner)}
            fullWidth={false}
            sx={{ mr: 1 }}
            variant='outlined'
          />
          {additionalAction && (
            <CustomButton
              label={t(additionalAction.label)}
              startIcon={<FaIcon icon={additionalAction.icon} />}
              color={additionalAction.color}
              onClick={additionalAction.onClick}
              fullWidth={false}
              sx={{ mr: 1 }}
              disabled={isProcessing}
            />
          )}
        </>
      }
      banner={
        <ExchangeBrandCard
          type={type}
          symbol={symbol}
          {...brand}
          size={200}
          brandLogoSize={150}
          partner={partner}
        />
      }
      t={t}
      body={
        isSellerExchangeTermsProcessing ? (
          <Loading noFullHeight />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={isActiveExchange ? 6 : 12}>
              <BrandTokenLabel
                details={{ brandName: brand?.name, tokenName: name, symbol }}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} textAlign='right'>
            <Chip label={brand?.brandConfig?.industrySector} />
          </Grid> */}
            {isActiveExchange && (
              <Grid item xs={12} sm={6} textAlign='right'>
                <ExchangeStatusChip
                  status={tokenExchangeWhitelist?.status}
                  t={t}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TabContent
                selectedTab={fromExchangeDetailsTypeToTabId(subTab)}
                onChangeTab={handleChangeTab}
                menuItems={tabMenuItems(exchangeTerms)}
                t={t}
                content={buildContent()}
              />
            </Grid>
            {/* <Grid item xs={12}>
            <DisplayLabel
              label={t('exchange.custom-brand-partner-requirements')}
              value={brand?.brandConfig?.customBrandPartnerRequirements}
            />
          </Grid>
          <Grid item xs={12}>
            <DisplayLabel
              label={t('exchange.additional-services-offered')}
              value={brand?.brandConfig?.additionalServicesOffered}
            />
          </Grid> */}
          </Grid>
        )
      }
      // maxWidth='md'
    />
  )
}

export default memo(ExchangePartnerDetails)

const buildSubmitActionStyles = (
  selectedTab,
  partner,
  onAcceptRequest,
  tokenProfile,
  exchangePartnersStats,
  active,
  t
) => {
  switch (selectedTab) {
    case EXCHANGE_PARTNER_TYPES.POTENTIAL:
      return [
        'common.connect',
        faPlusCircle,
        'success',
        'contained',
        null,
        checkBuyPointsConditions(
          {
            partner,
            tokenProfile
          },
          exchangePartnersStats
        )
      ]
    case EXCHANGE_PARTNER_TYPES.ACTIVE:
      return [
        'exchange.remove',
        faCircleXmark,
        'error',
        'outlined',
        null,
        active.data.length === 1 &&
          t('exchange.exchange-partnership-disable-remove-desc')
      ]
    case EXCHANGE_PARTNER_TYPES.REQUESTS:
      return [
        partner?.isFrom ? 'exchange.cancel-request' : 'common.reject',
        faCircleXmark,
        'error',
        'outlined',
        !partner?.isFrom && {
          label: 'common.accept',
          icon: faCircleCheck,
          color: 'success',
          onClick: () => onAcceptRequest(partner)
        }
      ]
    default:
      return []
  }
}

const tabMenuItems = (rewardConditionsExists) => {
  const menuItems = [
    {
      value: EXCHANGE_DETAILS_TAB_TYPE.REWARD_DETAILS,
      label: 'exchange.reward-details',
      icon: faCircleInfo
    }
  ]
  if (rewardConditionsExists) {
    menuItems.push({
      value: EXCHANGE_DETAILS_TAB_TYPE.REWARD_CONDITIONS,
      label: 'exchange.reward-conditions',
      icon: faCircleExclamation
    })
  }
  return menuItems
}
