import { mergeToNewObject } from '../../../util/object.helpers'
import { MEMBER_SEARCH_ACTIONS } from '../../../constants/actions'

export const memberSearchProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case MEMBER_SEARCH_ACTIONS.ON_CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case MEMBER_SEARCH_ACTIONS.ON_RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  query: ''
}

export const MEMBER_SEARCH_PROFILE_DISPATCH = {
  QUERY: 'query'
}
