import React, { memo } from 'react'
import { Box } from '@mui/material'

import { LOG_IN_URL } from '../../constants/navigation'
import { FORGOT_PASSWORD_PROFILE_DISPATCH } from '../../store/reducers/auth/forgotPassword'
import Text from '../shared/text/Text'
import Input from '../shared/inputs/Input'
import CustomForm from '../shared/forms/customForm/CustomForm'
import CustomButton from '../shared/buttons/CustomButton'
import PageContainer from '../shared/PageContainer/PageContainer'
import UnauthorizedLayoutContainer from '../../containers/UnauthorizedLayoutContainer'
import { QB_INPUT_EMAIL, QB_ACTION_SUBMIT } from '../../constants/input'
import { transWithArgs } from '../../util/validation.helpers'

const ForgotPassword = ({
  forgotPasswordProfile: { error, email, success, isProcessing },
  dispatchAction,
  t,
  onSendLinkClick
}) => {
  const title = t('forgot-password.forgot-your-password')
  return (
    <PageContainer title={title}>
      <UnauthorizedLayoutContainer title={title}>
        <Text
          color='textSecondary'
          variant='h5'
          label={t('forgot-password.enter-email-forgot-password')}
          sx={{
            mt: 2
          }}
        />
        <Box
          sx={{
            mt: 4
          }}
        >
          <CustomForm onFormSubmit={onSendLinkClick}>
            <Input
              id={FORGOT_PASSWORD_PROFILE_DISPATCH.EMAIL}
              label={t('log-in.email')}
              errorMessage={transWithArgs(
                t,
                error[FORGOT_PASSWORD_PROFILE_DISPATCH.EMAIL]
              )}
              value={email}
              onChange={(value) =>
                dispatchAction(FORGOT_PASSWORD_PROFILE_DISPATCH.EMAIL, value)
              }
              type={QB_INPUT_EMAIL}
              successMsg={transWithArgs(
                t,
                success[FORGOT_PASSWORD_PROFILE_DISPATCH.EMAIL]
              )}
            />

            <CustomButton
              sx={{ mt: 4 }}
              type={QB_ACTION_SUBMIT}
              label={t('forgot-password.send-link')}
              size='large'
              disabled={isProcessing}
            />
            <CustomButton
              sx={{ mt: 2 }}
              label={t('common.back-to-login')}
              to={LOG_IN_URL}
              variant=''
              size='large'
            />
          </CustomForm>
        </Box>
      </UnauthorizedLayoutContainer>
    </PageContainer>
  )
}

export default memo(ForgotPassword)
