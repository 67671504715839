import React, { memo } from 'react'
import { FormControlLabel, FormControl, Radio, RadioGroup } from '@mui/material'

import CustomFormLabel from '../forms/customElements/CustomFormLabel'

const CustomRadioGroup = ({
  fieldLabel,
  data,
  disabled,
  size,
  row = false,
  componentsProps,
  ...restProps
}) => {
  return (
    <FormControl>
      {fieldLabel && <CustomFormLabel>{fieldLabel}</CustomFormLabel>}
      <RadioGroup
        name='controlled-radio-buttons-group'
        row={row}
        {...restProps}
      >
        {data.map(({ value, label }, index) => (
          <FormControlLabel
            key={index}
            value={value}
            control={<Radio size={size} />}
            label={label}
            disabled={disabled}
            componentsProps={componentsProps}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default memo(CustomRadioGroup)
