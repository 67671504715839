import React, { memo } from 'react'
import { Box, Grid } from '@mui/material'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons'

import TokensList from './TokensList'
import TokensButton from './TokensButton'
import FaIcon from '../../shared/FaIcon/FaIcon'

const BrandInformation = ({
  brandProfile,
  tokenProfile,
  onCreateNewPoint,
  onChangeToken,
  onOpenTokens,
  onCloseTokens,
  isExtendedList,
  isDarkMode,
  brandAndTokensMenuData: { disableCreateNewBrand, brandTokens },
  t
}) => {
  const { name, brandConfig } = brandProfile
  const brandTokenLabels = {
    brandName: name,
    logoUrl: brandConfig?.logoUrl,
    tokenName: tokenProfile?.nameTemp,
    tokenSymbol: tokenProfile?.symbolTemp
  }
  const hasMenuItems = !disableCreateNewBrand || brandTokens.length > 0
  return (
    <Box
      sx={{
        p: 2,
        mt: 2,
        mb: 2,
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
        borderRadius: '15px',
        backgroundImage:
          isDarkMode &&
          'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))'
      }}
    >
      <TokensButton
        {...brandTokenLabels}
        onClickButton={hasMenuItems ? onOpenTokens : onCloseTokens}
        icon={
          hasMenuItems && (
            <Grid item xs={1}>
              <FaIcon icon={faAngleDown} size='lg' />
            </Grid>
          )
        }
        t={t}
      />
      {isExtendedList && hasMenuItems && (
        <TokensList
          t={t}
          onCreateNewPoint={onCreateNewPoint}
          onChangeToken={onChangeToken}
          isExtendedList={isExtendedList}
          onCloseTokens={onCloseTokens}
          isDarkMode={isDarkMode}
          brandTokens={brandTokens}
          disableCreateNewBrand={disableCreateNewBrand}
          {...brandTokenLabels}
        />
      )}
    </Box>
  )
}

export default memo(BrandInformation)
