import React, { memo } from 'react'
import { FormGroup } from '@mui/material'

import Checkbox from '../shared/inputs/Checkbox'
import { TRANSACTIONS_TABLE_FIELDS } from '../../constants/members'
import InputHelperText from '../shared/inputs/InputHelperText'
import { transWithArgs } from '../../util/validation.helpers'

const TxnColumnsMenu = ({
  t,
  txnTableFullHeaders,
  onSelectTxnColumn,
  transactionColumnsProfile: { txnColumns, error }
}) => {
  return (
    <FormGroup>
      {error?.message && (
        <InputHelperText errorMessage={transWithArgs(t, error)} />
      )}
      {txnTableFullHeaders
        .filter((field) => field.value !== TRANSACTIONS_TABLE_FIELDS.FEES)
        .map((header) => {
          return (
            <Checkbox
              checked={txnColumns[header.value] || false}
              onChange={() => onSelectTxnColumn(header.value)}
              key={header.value}
              label={header.label}
              size='small'
              sx={{ mb: 0, mt: 0 }}
              checkBoxStyles={{
                pt: 0.2,
                pb: 0.2
              }}
            />
          )
        })}
    </FormGroup>
  )
}

export default memo(TxnColumnsMenu)
