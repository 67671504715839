import { ANALYTICS_INTERVAL_FILTERS } from '../../constants/analytics'
import { DATE_FORMATS, ISO_WEEK, SHORT_DATE_FORMAT } from '../../constants/date'
import { TRANSACTION_TYPES } from '../../constants/transactionTypes'
import { formatTimeToMoment, formatToIOSdate } from '../date.helpers'
import { isMobile } from '../media.helpers'
import { addNumbers, formatCurrencyValue } from '../number.helpers'
import { getSortedKeysFromData } from '../object.helpers'
import { formatTicks } from '../string.helpers'
import { calculatePointValue } from '../token.helpers'

const buildExchangeActivityChartData = ({
  chartData,
  analyticsFilter: { interval }
}) => {
  const data = []
  const labels = []

  for (let time of getSortedKeysFromData(chartData)) {
    const chartRecord = chartData[time]
    data.push(calculateExchangeInOutCount(chartRecord))
    const momentTime = formatTimeToMoment(time)
    labels.push({
      formatted: buildFormattedChartLabel(momentTime, interval),
      unformatted: momentTime
    })
  }
  return {
    data,
    labels
  }
}

const buildTransactionsChartData = ({
  tokenProfile,
  chartData,
  analyticsFilter: { transactionType, interval }
}) => {
  const txCountData = {
    exchange: [],
    reward: [],
    redeem: []
  }
  const txTransferredData = []
  const txTransferredLabels = []
  for (let time of getSortedKeysFromData(chartData)) {
    const chartRecord = chartData[time]
    const x = formatToIOSdate(time)

    const momentTime = formatTimeToMoment(time)

    txTransferredLabels.push({
      formatted: buildFormattedChartLabel(momentTime, interval),
      unformatted: momentTime
    })
    txTransferredData.push({
      exchange: calculateExchangeInOutValue(chartRecord, tokenProfile),
      reward: calculatePointValue(
        chartRecord.rewardTxVolume,
        tokenProfile.onramp
      ),
      redeem: calculatePointValue(
        chartRecord.redeemTxVolume,
        tokenProfile.onramp
      )
    })

    txCountData.exchange.push({
      x,
      y: calculateExchangeInOutCount(chartRecord),
      interval
    })

    txCountData.reward.push({
      x,
      y: chartRecord?.rewardTxCount || 0,
      interval
    })
    txCountData.redeem.push({
      x,
      y: chartRecord?.redeemTxCount || 0,
      interval
    })
  }
  return {
    valueTransferredChart: {
      data: txTransferredData,
      filteredData: processPointsTransferredChartByFilter(
        txTransferredData,
        transactionType
      ),
      labels: txTransferredLabels
    },
    transactionsChart: {
      data: txCountData,
      filteredData: processTransactionsChartByFilter(
        txCountData,
        transactionType
      )
    }
  }
}

const processTransactionsChartByFilter = (data, transactionType) => {
  switch (transactionType) {
    case TRANSACTION_TYPES.EXCHANGE:
      return { exchange: data.exchange }
    case TRANSACTION_TYPES.REWARD:
      return { reward: data.reward }
    case TRANSACTION_TYPES.REDEEM:
      return { redeem: data.redeem }
    default:
      return {
        exchange: data.exchange,
        reward: data.reward,
        redeem: data.redeem
      }
  }
}

const processPointsTransferredChartByFilter = (data, transactionType) => {
  switch (transactionType) {
    case TRANSACTION_TYPES.EXCHANGE:
      return data.map((record) => record.exchange)
    case TRANSACTION_TYPES.REWARD:
      return data.map((record) => record.reward)
    case TRANSACTION_TYPES.REDEEM:
      return data.map((record) => record.redeem)
    default:
      return data.map(
        (record) => record.exchange + record.reward + record.redeem
      )
  }
}

const buildFormattedChartLabel = (momentTime, interval) => {
  switch (interval) {
    case ANALYTICS_INTERVAL_FILTERS.HOURLY:
      return momentTime.format(DATE_FORMATS.HOURLY)
    case ANALYTICS_INTERVAL_FILTERS.DAILY:
      return momentTime.format(DATE_FORMATS.DAILY)
    case ANALYTICS_INTERVAL_FILTERS.WEEKLY:
      return momentTime.format(DATE_FORMATS.WEEKLY)
    case ANALYTICS_INTERVAL_FILTERS.MONTHLY:
      return momentTime.format(DATE_FORMATS.MONTHLY)
    case ANALYTICS_INTERVAL_FILTERS.YEARLY:
      return momentTime.format(DATE_FORMATS.YEARLY)
  }
}

const buildTransactionsChartColors = ({ palette }, dataKeys, colorConfig) => {
  return dataKeys.map((dataKey) => palette[colorConfig[dataKey]].main)
}

const calculateExchangeInOutValue = (data, { offramp, onramp }) => {
  let valueExchanged = 0
  if (data?.exchangeOutTxVolume) {
    valueExchanged += calculatePointValue(data.exchangeOutTxVolume, offramp)
  }
  if (data?.exchangeInTxVolume) {
    valueExchanged += calculatePointValue(data.exchangeInTxVolume, onramp)
  }
  return valueExchanged
}

const calculateExchangeInOutCount = (data) => {
  return addNumbers(data?.exchangeOutTxCount, data?.exchangeInTxCount)
}

const buildLiabilityTrendChartData = ({
  chartData,
  tokenProfile,
  analyticsFilter: { interval }
}) => {
  const data = []
  const previousTransactionStats = chartData.previousTransactionStats
  const previousOutstandingPoints = previousTransactionStats
    ? calculateOutstandingPointsDiff(previousTransactionStats, tokenProfile)
    : 0
  getSortedKeysFromData(chartData.transactionStats).forEach((time, index) => {
    const chartRecord = chartData.transactionStats[time]
    const x = formatToIOSdate(time)
    const previousOutstanding =
      index === 0 ? previousOutstandingPoints : data[index - 1].y
    const value =
      previousOutstanding +
      calculateOutstandingPointsDiff(chartRecord, tokenProfile)
    data.push({
      x,
      y: Math.max(formatCurrencyValue(value), 0),
      interval
    })
  })
  return {
    data
  }
}

const calculateOutstandingPointsDiff = (
  { exchangeInTxVolume, rewardTxVolume, exchangeOutTxVolume, redeemTxVolume },
  { onramp }
) => {
  return calculatePointValue(
    addNumbers(rewardTxVolume, exchangeInTxVolume) -
      addNumbers(redeemTxVolume, exchangeOutTxVolume),
    onramp
  )
}

const transactionChartTooltip = () => {
  return {
    xaxis: {
      type: 'datetime',
      labels: {
        ...(isMobile() && {
          style: {
            fontSize: '9px'
          }
        })
      }
    },
    grid: {
      show: true,
      borderColor: 'rgba(0, 0, 0, .2)',
      color: '#777e89',
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false
        }
      },
      padding: {
        left: 24,
        right: 4
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    tooltip: {
      y: {
        formatter: (value) => {
          return formatTicks(value)
        }
      },
      x: {
        formatter: (time, { seriesIndex, dataPointIndex, w }) => {
          const data =
            w?.globals?.initialSeries[seriesIndex]?.data[dataPointIndex]
          if (data) {
            const label = formatTimeToMoment(time)
            switch (data?.interval) {
              case ANALYTICS_INTERVAL_FILTERS.HOURLY:
                return `${label.format(DATE_FORMATS.HOURLY)} / ${label.format(
                  SHORT_DATE_FORMAT
                )}`

              case ANALYTICS_INTERVAL_FILTERS.DAILY:
                return label.format(DATE_FORMATS.DAILY)

              case ANALYTICS_INTERVAL_FILTERS.WEEKLY:
                return `${label
                  .startOf(ISO_WEEK)
                  .format(SHORT_DATE_FORMAT)} - ${label
                  .endOf(ISO_WEEK)
                  .format(SHORT_DATE_FORMAT)}`

              case ANALYTICS_INTERVAL_FILTERS.MONTHLY:
                return label.format(DATE_FORMATS.MONTHLY)

              case ANALYTICS_INTERVAL_FILTERS.YEARLY:
                return label.format(DATE_FORMATS.YEARLY)

              default:
                break
            }
          }
          return ''
        }
      }
    }
  }
}

export {
  buildExchangeActivityChartData,
  buildTransactionsChartData,
  buildTransactionsChartColors,
  calculateExchangeInOutValue,
  calculateExchangeInOutCount,
  processTransactionsChartByFilter,
  processPointsTransferredChartByFilter,
  buildFormattedChartLabel,
  buildLiabilityTrendChartData,
  transactionChartTooltip
}
