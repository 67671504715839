import React, { memo } from 'react'
import { faHeart } from '@fortawesome/pro-solid-svg-icons'
import { faHeart as faHeartLight } from '@fortawesome/pro-light-svg-icons'
import { Box } from '@mui/material'

import AccountForm from '../shared/AccountForm/AccountForm'
import FaIcon from '../shared/FaIcon/FaIcon'
import Text from '../shared/text/Text'

const MarketplaceCard = ({
  title,
  likesCount = 0,
  description,
  liked,
  t,
  onLikeUnlike
}) => {
  return (
    <AccountForm
      title={
        <>
          {title}
          <br />
          {t('integration.coming-soon')}
        </>
      }
      description={description}
      submitLabel={`extensions.${liked ? 'liked' : 'like'}`}
      onFormSubmit={onLikeUnlike}
      backAction={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <FaIcon icon={faHeart} />
          <Text
            label={likesCount}
            variant='h4'
            sx={{ ml: '5px', fontWeight: '500' }}
          />
        </Box>
      }
      submitStyles={{
        color: 'error',
        variant: liked ? 'contained' : 'outlined',
        startIcon: <FaIcon icon={liked ? faHeart : faHeartLight} width={14} />
      }}
      t={t}
    />
  )
}

export default memo(MarketplaceCard)
