import React, { memo } from 'react'
import { styled } from '@mui/material/styles'
import { TextField } from '@mui/material'

import { THEME_MODE_DARK } from '../../../../constants/theme'

const CustomTextField = styled((props) => <TextField {...props} />)(
  ({ theme }) => ({
    '& .MuiOutlinedInput-input::-webkit-input-placeholder': {
      color: '#767e89',
      opacity: '1'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.inputBorderColor
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      backgroundColor: `${
        theme.palette.mode === THEME_MODE_DARK
          ? 'rgba(0, 0, 0, 0.12) '
          : '#f8f9fb '
      }`
    },
    '& .MuiOutlinedInput-input.Mui-disabled::-webkit-input-placeholder': {
      color: '#767e89',
      opacity: '1'
    }
  })
)

export default memo(CustomTextField)
