import React, { memo } from 'react'
import { Trans } from 'react-i18next'

import Link from '../link/Link'

const SetupText = ({ i18nKey, linkProps, ...restProps }) => {
  return (
    <Trans i18nKey={i18nKey}>
      <Link {...restProps} {...linkProps} />
    </Trans>
  )
}

export default memo(SetupText)
