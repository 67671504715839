import {
  ANALYTICS_FILTER_ACTION,
  ANALYTICS_POINTS_BANK_ACTION,
  ANALYTICS_POINTS_EXCHANGE_ACTION
} from '../../constants/actions'

// ANALYTICS FILTER
const changeAnalyticsFilterState = (property, value) => {
  return {
    type: ANALYTICS_FILTER_ACTION.ON_CHANGE_STATE,
    payload: {
      property,
      value
    }
  }
}

const resetAnalyticsFilterState = () => {
  return {
    type: ANALYTICS_FILTER_ACTION.ON_RESET_STATE
  }
}

// POINTS BANK ANALYTICS
const getActiveTotalMembers = () => {
  return {
    type: ANALYTICS_POINTS_BANK_ACTION.ON_GET_ACTIVE_TOTAL_MEMBERS
  }
}

const getExchangeMembers = () => {
  return {
    type: ANALYTICS_POINTS_EXCHANGE_ACTION.ON_GET_EXCHANGE_MEMBERS
  }
}

const getTopExchangePartners = () => {
  return {
    type: ANALYTICS_POINTS_EXCHANGE_ACTION.ON_GET_TOP_EXCHANGE_PARTNERS
  }
}

const getAnalyticsTokenStats = () => {
  return {
    type: ANALYTICS_POINTS_BANK_ACTION.ON_GET_TOKEN_STATS
  }
}

export {
  changeAnalyticsFilterState,
  resetAnalyticsFilterState,
  getActiveTotalMembers,
  getExchangeMembers,
  getTopExchangePartners,
  getAnalyticsTokenStats
}
