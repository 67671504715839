import {
  buildDropdownList,
  isExistsElementFromList,
  sortOptionsByASC
} from '../app/app.helpers'
import {
  THIRD_PARTY_TOOLS_TYPE_FILTERS,
  EXTENSIONS_TAB_TYPE,
  EXTENSIONS_TAB_CONTENT_ID,
  EARN_OPTION_TOGGLE_IDS
} from '../../constants/extensions'
import { toKebabCase, trim } from '../string.helpers'
import { EXTENSIONS_URL } from '../../constants/navigation'
import {
  QB_DEFAULT_REGION_VALUE,
  QB_DEFAULT_TYPE_VALUE
} from '../../constants/app'
import TransWithInAppLink from '../../components/shared/translations/TransWithInAppLink'
import { isTokenTypeCryptoFiat } from '../token.helpers'
import { isExchangeWhitelistActiveOrPending } from '../exchange.helpers'
import { isBrandMM } from '../brand.helpers'

const buildThirdPartyToolTypes = (t) => {
  return buildDropdownList(
    THIRD_PARTY_TOOLS_TYPE_FILTERS,
    'extensions.third-party-tools-type-filters',
    t
  )
}

const extractAllLoveBrandTypeOptions = (data, t) => {
  const unique = {}
  let types = []
  data.forEach((brand) => {
    const type = brand?.metadata?.type
    if (type && !unique[type]) {
      types.push({ label: type, value: type })
      unique[type] = true
    }
  })
  types = sortOptionsByASC(types)
  types.unshift({
    label: t('exchange.filter-options.all'),
    value: QB_DEFAULT_TYPE_VALUE
  })
  return types
}

const fromExtensionsTabIdToType = (tabId) => {
  switch (tabId) {
    case EXTENSIONS_TAB_CONTENT_ID.EARN_OPTIONS:
      return EXTENSIONS_TAB_TYPE.EARN_OPTIONS
    case EXTENSIONS_TAB_CONTENT_ID.REDEMPTION_SHOP:
      return EXTENSIONS_TAB_TYPE.REDEMPTION_SHOP
    default:
      return EXTENSIONS_TAB_TYPE.THIRD_PARTY_TOOLS
  }
}

const fromExtensionsTypeToTabId = (selectedTab) => {
  switch (selectedTab) {
    case EXTENSIONS_TAB_TYPE.EARN_OPTIONS:
      return EXTENSIONS_TAB_CONTENT_ID.EARN_OPTIONS
    case EXTENSIONS_TAB_TYPE.REDEMPTION_SHOP:
      return EXTENSIONS_TAB_CONTENT_ID.REDEMPTION_SHOP
    default:
      return EXTENSIONS_TAB_CONTENT_ID.THIRD_PARTY_TOOLS
  }
}

const buildThirdPartyToolsData = (thirdPartyTools, toolsFilterType) => {
  if (toolsFilterType === THIRD_PARTY_TOOLS_TYPE_FILTERS.ALL) {
    return thirdPartyTools
  }
  return thirdPartyTools.filter(
    (record) => trim(toKebabCase(record?.metadata?.type)) === toolsFilterType
  )
}

const buildLoveBrandsData = (state) => {
  return state.data.filter((record) => {
    const type = record?.metadata?.type
    const typeCondition =
      state.type !== QB_DEFAULT_TYPE_VALUE && type
        ? trim(type) === state.type
        : true
    const region = record?.metadata?.region
    const regionCondition =
      state.region !== QB_DEFAULT_REGION_VALUE && region
        ? isExistsElementFromList(region, state.region)
        : true
    return typeCondition && regionCondition
  })
}

const buildLoveBrandCardProps = (
  surveyId,
  { userSurveys, userSurveysLikesCount, id },
  onLikeUnlikeSurvey
) => {
  return {
    onLikeUnlike: () => onLikeUnlikeSurvey(surveyId, id),
    likesCount: userSurveysLikesCount?.[surveyId]?.[id],
    liked: userSurveys?.[surveyId]?.response?.[id]?.liked
  }
}

const sortOptionsByTitleASC = (options) => {
  if (!options || options.length === 0) {
    return []
  }
  return options?.sort((a, b) => a.title.localeCompare(b.title))
}

const buildExtensionsUrl = (activeTab) => {
  return `${EXTENSIONS_URL}/${activeTab}`
}

const checkBuyPointsConditions = ({ partner, tokenProfile }, extension) => {
  const to = buildExtensionsUrl(EXTENSIONS_TAB_TYPE.EARN_OPTIONS)
  if (tokenProfile.enablePurchasePoints && isBrandMM(partner.brand.id)) {
    return (
      <TransWithInAppLink
        labelKey={`extensions.earn-options.${EARN_OPTION_TOGGLE_IDS.ENABLE_PURCHASE_POINTS}.disabled-connect`}
        to={to}
        linkLabelKey={`extensions.earn-options.${EARN_OPTION_TOGGLE_IDS.ENABLE_PURCHASE_POINTS}.title`}
      />
    )
  } else if (
    isTokenTypeCryptoFiat(partner.type) &&
    isExchangeWhitelistActiveOrPending(
      extension?.milesAndMoreExchangePair?.tokenExchangeWhitelist?.status
    )
  ) {
    return (
      <TransWithInAppLink
        labelKey={`extensions.earn-options.${EARN_OPTION_TOGGLE_IDS.ENABLE_PURCHASE_POINTS_WITH_MILES}.disabled-connect`}
        to={to}
        linkLabelKey={`extensions.earn-options.${EARN_OPTION_TOGGLE_IDS.ENABLE_PURCHASE_POINTS_WITH_MILES}.title`}
      />
    )
  }
  return null
}

export {
  buildThirdPartyToolTypes,
  extractAllLoveBrandTypeOptions,
  fromExtensionsTabIdToType,
  fromExtensionsTypeToTabId,
  buildThirdPartyToolsData,
  buildLoveBrandsData,
  buildLoveBrandCardProps,
  sortOptionsByTitleASC,
  buildExtensionsUrl,
  checkBuyPointsConditions
}
