import React, { memo, useState } from 'react'
import { AppBar, Box, IconButton, Toolbar, Button, Hidden } from '@mui/material'
import {
  faBars,
  faBell,
  faChevronDown,
  faMoon,
  faSunBright,
  faPhone,
  faCircleQuestion
} from '@fortawesome/pro-light-svg-icons'

import ProfileDropdown from './ProfileDropdown'
import CustomButton from '../../shared/buttons/CustomButton'
import UserInitials from '../../shared/UserInitials/UserInitials'
import FaIcon from '../../shared/FaIcon/FaIcon'
import Text from '../../shared/text/Text'
import Notification from './Notification'
import HeaderMenu from './HeaderMenu'
import MenuDivider from './MenuDivider'
import { openExternalUrl } from '../../../util/app/app.helpers'
import { buildCalendarUrl } from '../../../util/externalResource.helpers'
import ShowCircleGuidance from '../../shared/Chip/ShowCircleGuidance'
import HelpDropdownMenu from './HelpDropdownMenu'
import ProductUpdates from './ProductUpdates'

const Header = ({
  sx,
  toggleSidebar,
  toggleMobileSidebar,
  firstName,
  lastName,
  email,
  onLogout,
  isDarkMode,
  onChangeMode,
  notifications,
  showBookACall,
  userId,
  t
}) => {
  const notificationsCount = notifications.length

  const [profileAnchor, setProfileAnchor] = useState(null)
  const [notificationsOpen, setNotificationsOpen] = useState(null)
  const [helpOpen, setHelpAnchor] = useState(null)

  const handleOnNotificationsClick = (event) => {
    setNotificationsOpen(event.currentTarget)
  }

  const handleCloseNotifications = () => {
    setNotificationsOpen(null)
  }

  const handleOnProfileClick = (event) => {
    setProfileAnchor(event.currentTarget)
  }

  const handleCloseProfileMenu = () => {
    setProfileAnchor(null)
  }

  const handleOnHelpClick = (event) => {
    setHelpAnchor(event.currentTarget)
  }

  const handleCloseHelpMenu = () => {
    setHelpAnchor(null)
  }

  return (
    <AppBar sx={sx} elevation={0}>
      <Toolbar>
        <IconButton
          edge='start'
          color='inherit'
          aria-label='menu'
          onClick={toggleSidebar}
          size='large'
          sx={{
            display: {
              lg: 'flex',
              xs: 'none'
            },
            ...ICON_BUTTON_STYLES
          }}
        >
          <FaIcon icon={faBars} width={20} />
        </IconButton>
        <IconButton
          size='large'
          color='inherit'
          aria-label='menu'
          onClick={toggleMobileSidebar}
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            ...ICON_BUTTON_STYLES
          }}
        >
          <FaIcon icon={faBars} width={20} />
        </IconButton>
        <Box flexGrow={1} />
        {showBookACall && (
          <>
            <CustomButton
              onClick={() => {
                openExternalUrl(buildCalendarUrl(userId))
              }}
              variant='text'
              fullWidth={false}
              label={<Hidden smDown>{t('header.book-a-call')}</Hidden>}
              startIcon={<FaIcon width={20} icon={faPhone} />}
              sx={{ pl: 1.5, pr: 1.5 }}
            />
            <MenuDivider />
          </>
        )}
        <IconButton
          size='large'
          color='inherit'
          onClick={onChangeMode}
          sx={ICON_BUTTON_STYLES}
        >
          <FaIcon icon={!isDarkMode ? faMoon : faSunBright} width={18} />
        </IconButton>
        <MenuDivider />
        <ProductUpdates iconStyles={ICON_BUTTON_STYLES} />
        <MenuDivider />
        <IconButton
          size='large'
          color='inherit'
          onClick={handleOnNotificationsClick}
          sx={{ ...ICON_BUTTON_STYLES, p: 1 }}
        >
          <ShowCircleGuidance
            show={notificationsCount > 0}
            circlecss={{ bottom: 8, marginLeft: 3 }}
          >
            <FaIcon icon={faBell} width={18} />
          </ShowCircleGuidance>
        </IconButton>
        <MenuDivider />
        <HeaderMenu
          id='notifications-menu'
          open={notificationsOpen}
          onClose={handleCloseNotifications}
          heading={
            <>
              <Box display='flex' alignItems='center' justifyItems='center'>
                <Text
                  fontWeight={500}
                  label={t('notifications.notifications')}
                />
                {notificationsCount > 0 && (
                  <Text
                    variant='h6'
                    label={t('notifications.new', {
                      count: 1
                    })}
                    sx={{
                      background: (theme) => theme.palette.primary.main,
                      color: (theme) => theme.palette.lightGrey.light,
                      pl: 1,
                      pr: 1,
                      ml: 1,
                      borderRadius: '5px'
                    }}
                  />
                )}
              </Box>
              {!notificationsCount > 0 && (
                <Text label={t('notifications.no-notifications')} />
              )}
            </>
          }
          body={notifications?.map((notification, index) => {
            return (
              <Notification
                {...notification}
                onClick={handleCloseNotifications}
                key={index}
              />
            )
          })}
        />

        <IconButton
          size='large'
          color='inherit'
          onClick={handleOnHelpClick}
          sx={ICON_BUTTON_STYLES}
        >
          <FaIcon icon={faCircleQuestion} width={18} />
        </IconButton>
        <MenuDivider />
        <HeaderMenu
          id='help-menu'
          open={helpOpen}
          onClose={handleCloseHelpMenu}
          body={<HelpDropdownMenu t={t} onClick={handleCloseHelpMenu} />}
          width={270}
        />

        <Button
          aria-label='menu'
          color='inherit'
          aria-controls='profile-menu'
          aria-haspopup='true'
          onClick={handleOnProfileClick}
        >
          <Box display='flex' alignItems='center'>
            <UserInitials firstName={firstName} lastName={lastName} />
            <Box
              sx={{
                display: {
                  xs: 'none',
                  sm: 'flex'
                },
                alignItems: 'center'
              }}
            >
              <Text
                color='textSecondary'
                variant='h5'
                sx={{ ml: 1 }}
                label={`${t('header.hi')},`}
              />

              <Text
                variant='h5'
                fontWeight={700}
                sx={{
                  ml: 1
                }}
                label={firstName}
              />

              <FaIcon icon={faChevronDown} width={20} />
            </Box>
          </Box>
        </Button>
        <HeaderMenu
          id='profile-menu'
          open={profileAnchor}
          onClose={handleCloseProfileMenu}
          heading={
            <Box display='flex' alignItems='center'>
              <Text fontWeight={500} label={t('header.user-profile')} />
            </Box>
          }
          body={
            <Box>
              <ProfileDropdown
                firstName={firstName}
                lastName={lastName}
                email={email}
                t={t}
                onClick={handleCloseProfileMenu}
              />
              <CustomButton
                sx={{
                  mt: 2
                }}
                variant='outlined'
                color='danger'
                label={t('log-in.logout')}
                onClick={onLogout}
              />
            </Box>
          }
        />
      </Toolbar>
    </AppBar>
  )
}

export default memo(Header)

const ICON_BUTTON_STYLES = {
  height: 46,
  width: 46
}
