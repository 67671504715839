import React, { memo } from 'react'
import { Box, MenuItem, Divider, Link as MuiLink } from '@mui/material'
import { faEnvelope, faUserCircle } from '@fortawesome/pro-light-svg-icons'
import { Link } from 'react-router-dom'

import Text from '../../shared/text/Text'
import UserInitials from '../../shared/UserInitials/UserInitials'
import { ACCOUNT_URL } from '../../../constants/navigation'
import FaIcon from '../../shared/FaIcon/FaIcon'
import CustomTooltip from '../../shared/CustomTooltip/CustomTooltip'
import DropdownMenuItem from './DropdownMenuItem'
import { CSS_TRUNCATE_TEXT } from '../../../styles/common'

const ProfileDropdown = ({ firstName, lastName, email, t, onClick }) => (
  <Box>
    <Box
      sx={{
        pb: 3,
        mt: 3
      }}
    >
      <Box display='flex' alignItems='center'>
        <UserInitials firstName={firstName} lastName={lastName} size={70} />
        <Box
          sx={{
            ml: 2
          }}
        >
          <Text
            sx={{
              lineHeight: '1.235'
            }}
            label={`${firstName} ${lastName}`}
          />

          <Box
            display='flex'
            component='div'
            sx={{ mt: 1, width: 235 }}
            alignItems='center'
          >
            <Text
              color='textSecondary'
              display='flex'
              alignItems='center'
              sx={{
                color: (theme) => theme.palette.grey.A200,
                mr: 1
              }}
              label={<FaIcon icon={faEnvelope} />}
            />

            <CustomTooltip
              label={email}
              parentProps={{
                sx: { overflow: 'hidden' }
              }}
              showIfLength={27}
            >
              <Text
                color='textSecondary'
                variant='h6'
                label={email}
                fontWeight={500}
                sx={CSS_TRUNCATE_TEXT}
              />
            </CustomTooltip>
          </Box>
        </Box>
      </Box>
    </Box>
    <Divider
      sx={{
        mt: 0,
        mb: 0
      }}
    />

    <MuiLink
      component={Link}
      to={ACCOUNT_URL}
      sx={{ textDecoration: 'none' }}
      onClick={onClick}
    >
      <MenuItem
        sx={{
          pt: 3,
          pb: 3
        }}
      >
        <DropdownMenuItem
          icon={faUserCircle}
          title={t('header.my-profile')}
          description={t('header.account-settings')}
        />
      </MenuItem>
    </MuiLink>
  </Box>
)

export default memo(ProfileDropdown)
