import React, { memo } from 'react'
import { Grid, Box } from '@mui/material'

import BaseForm from '../BaseForm/BaseForm'
import CustomButton from '../buttons/CustomButton'
import BaseCard from '../BaseCard/BaseCard'
import { QB_ACTION_SUBMIT } from '../../../constants/input'
import ShowCircleGuidance from '../Chip/ShowCircleGuidance'

const AccountForm = ({
  t,
  fields,
  disabledSubmit,
  noActions,
  submitLabel = 'common.save',
  submitStyles: { isShowGuidance, ...restSubmitStyles },
  extraAction,
  backAction,
  ...restProps
}) => {
  return (
    <BaseCard>
      <BaseForm
        {...restProps}
        fields={
          <Grid container spacing={3}>
            {fields}
          </Grid>
        }
        actions={
          !noActions && (
            <>
              {backAction}
              <Box sx={{ flex: '1 1 auto' }} />
              {extraAction}

              <CustomButton
                type={QB_ACTION_SUBMIT}
                variant='contained'
                label={
                  <ShowCircleGuidance show={isShowGuidance} invert>
                    {t(submitLabel)}
                  </ShowCircleGuidance>
                }
                fullWidth={false}
                disabled={disabledSubmit}
                sx={{ ml: 2 }}
                {...restSubmitStyles}
              />
            </>
          )
        }
      />
    </BaseCard>
  )
}

AccountForm.defaultProps = {
  submitStyles: {}
}

export default memo(AccountForm)
