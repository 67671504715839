import React, { memo } from 'react'

import { LABEL_TEXT } from '../../../constants/messages'
import UserAlert from '../../shared/CustomAlert/UserAlert'
import { adminPackagesBillingUrl } from '../../../util/admin.helpers'

const SubscriptionAlert = ({
  label,
  t,
  severity,
  i18nKey = LABEL_TEXT.GET_ACCESS_TO_DASHBOARD
}) => {
  return (
    <UserAlert
      label={t(`setup.${label}`)}
      to={adminPackagesBillingUrl()}
      i18nKey={i18nKey}
      severity={severity}
    />
  )
}

export default memo(SubscriptionAlert)
