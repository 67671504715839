import React, { memo } from 'react'
import { Stepper, Step, StepLabel } from '@mui/material'

import BaseCard from '../BaseCard/BaseCard'

const FormWizard = ({ data, selectedTrack, body }) => {
  return (
    <BaseCard>
      <Stepper activeStep={selectedTrack}>
        {data.map(({ id, label }) => {
          return (
            <Step key={id}>
              <StepLabel>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      {body}
    </BaseCard>
  )
}

export default memo(FormWizard)
