import React, { memo } from 'react'
import { Link } from '@mui/material'

import { hotReloadApp } from '../../../util/app/app.helpers'
import { isAndroid, isMobile, isSafari } from '../../../util/media.helpers'
import CustomDialog from '../CustomDialog/CustomDialog'

const CameraBlocked = ({ t, ...restProps }) => {
  return (
    <CustomDialog
      maxWidth='xs'
      title={t('qr-code.camera-is-blocked')}
      description={buildCameraBlockedMessage(t)}
      open
      t={t}
      {...restProps}
    />
  )
}

export default memo(CameraBlocked)

const buildCameraBlockedMessage = (t) => {
  const qrScannerRequiresText = t('qr-code.requires-camera-access')
  if (isMobile()) {
    if (isAndroid()) {
      return (
        <>
          {qrScannerRequiresText} {t('qr-code.camera-blocked-android-chrome')}{' '}
          {buildRefreshText(t)}
        </>
      )
    }
    return (
      <>
        {qrScannerRequiresText}{' '}
        {
          <>
            {t('common.please')} {refreshLink(t)}{' '}
            {t('qr-code.to-access-camera')}
          </>
        }
      </>
    )
  } else {
    if (isSafari()) {
      return (
        <>
          {qrScannerRequiresText} {t('qr-code.camera-blocked-safari')}{' '}
          {buildRefreshText(t)}
        </>
      )
    } else {
      return (
        <>
          {qrScannerRequiresText} {t('qr-code.camera-blocked-common')}
        </>
      )
    }
  }
}

const buildRefreshText = (t) => {
  return (
    <>
      {t('common.and-then')} {refreshLink(t)}
    </>
  )
}

const refreshLink = (t) => {
  return (
    <Link href='#' onClick={hotReloadApp}>
      {t('common.refresh')}
    </Link>
  )
}
