import { QB_AUTH_LANGUAGE_ATTR } from '../constants/auth'
import { commonParser, parseToken, parseTransaction } from './apiParser.helpers'
import { trim } from './string.helpers'
import { cannySSOUserFormat } from './account.helpers'
import { keysToCamel } from './object.helpers'
import {
  isEventTypeNftSale,
  isEventTypeNftTransfer
} from './transaction/transaction.helpers'

const extractAccountReducer = (state) => state.value.accountReducer
const membersReducer = (state) => state.value.membersReducer
const transactionsReducer = (state) => state.value.transactionsReducer
const tokensReducer = (state) => state.value.tokensReducer
const nftsReducer = (state) => state.value.nftsReducer
const exchangeReducer = (state) => state.value.exchangeReducer

export const extractAccountProfile = (state) => {
  return extractAccountReducer(state).accountProfile
}

export const extractCannySSOData = (state) => {
  const accountProfile = extractAccountProfile(state)
  const userData = cannySSOUserFormat(accountProfile)
  return userData
}

export const hasUserAccountStateChanged = (
  updatedCognitoUser,
  accountProfile
) => {
  const {
    email,
    family_name,
    given_name,
    [QB_AUTH_LANGUAGE_ATTR]: language
  } = updatedCognitoUser.attributes
  const {
    email: emailTemp,
    firstName,
    lastName,
    language: languageTemp,
    jobPosition,
    jobPositionTemp,
    country,
    countryTemp
  } = accountProfile
  return (
    email !== emailTemp ||
    family_name !== trim(firstName) ||
    given_name !== trim(lastName) ||
    language !== languageTemp ||
    jobPosition !== trim(jobPositionTemp) ||
    country !== trim(countryTemp)
  )
}

export const extractBrandProfile = (state) => {
  return state.value.dashboardReducer.brandProfile
}

const extractFileUploadProfile = (state) => {
  return state.value.mainReducer.fileUploadProfile
}

export const extractMainProfile = (state) => {
  return state.value.mainReducer.mainProfile
}

export const extractAnalyticsFilterProfile = (state) => {
  return state.value.analyticsReducer.analyticsFilterProfile
}

export const extractSubscribedPackage = (state) => {
  return state.value.mainReducer.billingProfile.subscribedPackage
}

export const extractTokenInfo = (state) => {
  return tokensReducer(state).tokenProfile
}

export const extractTokenId = (state) => {
  return extractTokenInfo(state).id
}

export const extractBrandId = (state) => {
  return extractBrandProfile(state).id
}

export const extractFileUploadType = (state) => {
  return extractFileUploadProfile(state).type
}

export const extractBrandLogo = (state) => {
  return extractBrandProfile(state).logo
}

export const extractMembersProfile = (state) => {
  return membersReducer(state).membersProfile
}

export const extractMembersPagination = (state) => {
  return extractMembersProfile(state).pagination
}

export const extractMemberTransactionProfile = (state) => {
  return membersReducer(state).memberTransactionProfile
}

export const extractMemberSearchProfile = (state) => {
  return membersReducer(state).memberSearchProfile
}

export const extractRewardMemberProfile = (state) => {
  return membersReducer(state).rewardMemberProfile
}

export const extractSelectedMember = (state) => {
  return extractMemberTransactionProfile(state).selectedMember
}

export const extractTransactionsProfile = (state) => {
  return transactionsReducer(state).transactionsProfile
}

export const extractTransactionSearchProfile = (state) => {
  return transactionsReducer(state).transactionSearchProfile
}

export const extractNftUsersProfile = (state) => {
  return nftsReducer(state).nftUsersProfile
}

export const extractNftUsersPagination = (state) => {
  return extractNftUsersProfile(state).pagination
}

export const extractTransactionsPagination = (state) => {
  return extractTransactionsProfile(state).pagination
}

export const extractPartnersProfile = (state) => {
  return exchangeReducer(state).partnersProfile
}

export const formatBrands = (brands) => {
  if (brands && brands.length > 0) {
    return brands.map((brand) => commonParser(brand))
  }
  return []
}

export const formatTokens = (tokens) => {
  if (tokens && tokens.length > 0) {
    return tokens.map((token) => parseToken(token))
  }
  return []
}

export const formatAPIKeys = (apiKeys) => {
  if (apiKeys && apiKeys.length > 0) {
    return apiKeys.map((apiKey) => commonParser(apiKey))
  }
  return []
}

export const formatTransactions = (transactions) => {
  return transactions?.reduce((current, next) => {
    const transaction = parseTransaction(next)
    if (
      isEventTypeNftTransfer(transaction.loyaltyEventType) ||
      isEventTypeNftSale(transaction.loyaltyEventType)
    ) {
      return [
        ...current,
        {
          ...transaction
        },
        { ...transaction, isSeller: true }
      ]
    }
    return [...current, transaction]
  }, [])
}

export const formatMembers = (members) => {
  return members && members.map((member) => commonParser(member))
}

export const formatTeamMembers = (teamMembers) => {
  return (
    teamMembers && teamMembers.map((teamMember) => commonParser(teamMember))
  )
}

const formatNftUsers = (users) => {
  return users && users.map((user) => commonParser(user))
}

export const parseNftUsersResponse = (usersResponse) => {
  if (usersResponse.users) {
    usersResponse.users = formatNftUsers(usersResponse.users)
  }
  return keysToCamel(usersResponse)
}

export const buildOffsetRangeForDownload = (totalTxCount = 0, limit = 100) => {
  const end = Math.ceil(totalTxCount / limit)
  const output = []
  for (let i = 0; i < end; i++) {
    output.push(i * limit)
  }
  return output
}

export const buildPaginationFilters = (payload, pagination) => {
  const recordsPerPage = payload?.recordsPerPage || pagination.recordsPerPage
  const pageNumber = payload?.pageNumber || pagination.pageNumber
  return {
    limit: recordsPerPage,
    offset: pageNumber > 0 ? pageNumber * recordsPerPage : 0
  }
}

export const compareFileUploadType = (extractedType, type) => {
  return extractedType === type
}

export const extractTxnChangeStatusProfile = (state) => {
  return transactionsReducer(state).changeStatusProfile
}
