import moment from 'moment'

import {
  DAYS,
  DAY,
  HOUR,
  WEEKS,
  ISO_WEEK,
  HOURS,
  SHORT_DATE_FORMAT,
  LONG_DATE_FORMAT,
  MONTHS,
  MONTH,
  SECONDS,
  SECOND,
  IOS_DATE_FORMAT,
  MINUTES,
  DEFAULT_DATE_TIME_FORMAT
} from '../constants/date'

export const getCurrentISOTime = () => {
  return moment().toISOString()
}

export const getDateFromTimestamp = (timestamp) => {
  return moment(timestamp * 1000)
}

export const getDateDaysSubtractedBy = (days) => {
  return moment.utc().subtract(days, DAYS).startOf(DAY)
}

export const getDateHoursSubtractedBy = (hours) => {
  return moment().subtract(hours, HOURS).startOf(HOUR)
}

export const getDateSecondsSubtractedBy = (seconds) => {
  return moment().subtract(seconds, SECONDS).startOf(SECOND)
}

export const getDateWeeksSubtractedBy = (weeks) => {
  return moment().subtract(weeks, WEEKS).startOf(ISO_WEEK)
}

export const getDateMonthsSubtractedByStartOf = (months) => {
  return getDateMonthsSubtractedBy(months).startOf(MONTH)
}

export const getDateMonthsSubtractedBy = (months) => {
  return moment().subtract(months, MONTHS)
}

export const formatToShortDate = (date) => {
  return moment(date).format(SHORT_DATE_FORMAT)
}

export const formatTimeToMoment = (date) => {
  return moment.utc(date)
}

export const convertUnix = (date) => {
  return moment.unix(date)
}

export const formatUnixToShortDate = (date) => {
  return convertUnix(date).format(SHORT_DATE_FORMAT)
}

export const formatToLongDate = (date) => {
  return moment(date).format(LONG_DATE_FORMAT)
}

export const formatDate = (value, formatting) => {
  return moment.utc(value).local().format(formatting)
}

export const formatToIOSdate = (date) => {
  return moment(date).format(IOS_DATE_FORMAT)
}

export const convertToDate = (date) => {
  return date.toDate()
}

export const minStartDate = () => {
  return new Date(2020, 0, 1)
}

export const getTodaysDate = () => {
  return convertToDate(moment.utc())
}

export const getCleanedStartDate = (date) => {
  return moment.utc(date).startOf(DAY).toISOString()
}

export const getCleanedEndDate = (date) => {
  return moment.utc(date).endOf(DAY).toISOString()
}

export const getCleanedUnixStartDate = (date) => {
  return moment.utc(convertUnix(date)).startOf(DAY).toISOString()
}

export const getCleanedUnixEndDate = (date) => {
  return moment.utc(convertUnix(date)).endOf(DAY).toISOString()
}

export const getMaxDaysForFilter = () => {
  return getTodaysDate()
}

const isValidFutureDate = (date) => {
  return date ? moment.utc(date).unix() > moment.utc().unix() : false
}

const isTimeDiffWithinTenMinutesWindow = (date) => {
  const givenUtcDate = moment.utc(date)
  const currentUtcTime = moment.utc()
  const diffInMinutes = givenUtcDate.diff(currentUtcTime, MINUTES)
  return diffInMinutes >= 0 && diffInMinutes <= 10
}

export const isGivenDatePassed = (date) => {
  return date
    ? !isValidFutureDate(date) || isTimeDiffWithinTenMinutesWindow(date)
    : false
}

export const getCurrentDateTimeWithSeconds = (seconds) => {
  return moment
    .utc(moment().add(seconds, SECONDS))
    .format(DEFAULT_DATE_TIME_FORMAT)
}
