import {
  catchError,
  map,
  tap,
  switchMap,
  delay,
  repeat,
  mergeMap,
  filter,
  mapTo
} from 'rxjs/operators'
import { forkJoin, of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'

import {
  DOWNLOAD_ACTION,
  MEMBER_ACTIONS,
  MEMBER_DOWNLOAD_ACTIONS,
  MEMBER_SEARCH_ACTIONS,
  MEMBER_TRANSACTION_ACTIONS,
  REWARD_MEMBER_ACTION,
  TRANSACTION_ACTIONS
} from '../../constants/actions'
import apiService from '../../services/api'
import {
  buildOffsetRangeForDownload,
  buildPaginationFilters,
  extractBrandId,
  extractMemberSearchProfile,
  extractMembersPagination,
  extractMembersProfile,
  extractMemberTransactionProfile,
  extractSelectedMember,
  extractTokenId,
  extractTokenInfo,
  formatMembers,
  formatTransactions
} from '../../util/epics.helpers'
import { wrapUserAccessToken } from '../../util/auth.helpers'
import {
  ALLOW_MAX_RECORDS_FOR_DOWNLOAD,
  DOWNLOAD_REPORT_TYPES,
  MAX_NUMBER_OF_RECORDS_FOR_DOWNLOAD,
  MEMBER_RECENT_TRANSACTION,
  NUMBER_OF_RECORDS_PER_PAGE
} from '../../constants/app'
import {
  commonParser,
  parseMembersResponse
} from '../../util/apiParser.helpers'
import { logger } from '../../util/log.helpers'
import { buildDownloadFileName } from '../../util/download.helpers'
import {
  analyticsFilterData,
  buildDateRangeQueryParams
} from '../../util/analytics/analytics.helpers'
import {
  getBrandIdTokenIdAnalyticsFilters,
  handleGetFilterTypeForTransactions
} from '../../util/transaction/transaction.helpers'
import {
  buildParamsForRewardExchange,
  buildParamsForRewardPointsBank
} from '../../util/members/members.helpers'

const handleGetMembersEpic = (action$, state$) =>
  action$.pipe(
    ofType(MEMBER_ACTIONS.ON_GET_MEMBERS),
    mergeMap(({ payload }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getMembers(
            buildMemberPaginationFilters(payload, state$),
            extractBrandId(state$),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: MEMBER_ACTIONS.ON_GET_MEMBERS_SUCCESS,
              payload: parseMembersResponse(response.data)
            })),
            catchError((err) =>
              of({
                type: MEMBER_ACTIONS.ON_GET_MEMBERS_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleGetMembersForDownloadEpic = (action$, state$) =>
  action$.pipe(
    ofType(MEMBER_DOWNLOAD_ACTIONS.ON_GET_MEMBERS_FOR_DOWNLOAD),
    map(() => extractMembersPagination(state$)),
    filter(
      ({ totalTxCount, recordsPerPage }) =>
        totalTxCount <= ALLOW_MAX_RECORDS_FOR_DOWNLOAD &&
        totalTxCount > recordsPerPage
    ),
    mergeMap(({ totalTxCount }) =>
      wrapUserAccessToken((accessToken) =>
        getAllMembersForDownload(totalTxCount, state$, accessToken).pipe(
          map((response) => {
            return {
              type: MEMBER_DOWNLOAD_ACTIONS.ON_GET_MEMBERS_FOR_DOWNLOAD_SUCCESS,
              payload: response
            }
          }),
          catchError((err) =>
            of({
              type: MEMBER_DOWNLOAD_ACTIONS.ON_GET_MEMBERS_FOR_DOWNLOAD_FAILED,
              payload: err
            })
          )
        )
      )
    )
  )

const handleMembersForDownloadBelowThresholdEpic = (action$, state$) =>
  action$.pipe(
    ofType(MEMBER_DOWNLOAD_ACTIONS.ON_GET_MEMBERS_FOR_DOWNLOAD),
    map(() => extractMembersProfile(state$)),
    filter(
      ({ pagination: { totalTxCount, recordsPerPage } }) =>
        totalTxCount <= recordsPerPage
    ),
    map(({ members }) => {
      return {
        type: MEMBER_DOWNLOAD_ACTIONS.ON_GET_MEMBERS_FOR_DOWNLOAD_SUCCESS,
        payload: members
      }
    })
  )

const getAllMembersForDownload = (totalTxCount, state, accessToken) => {
  const { brandId, limit, ...restQueryAttributes } =
    buildMembersQueriesForDownload(state)
  return forkJoin(
    buildOffsetRangeForDownload(totalTxCount, limit).map((offset) =>
      apiService.getMembers(
        { offset, limit, ...restQueryAttributes },
        brandId,
        accessToken
      )
    )
  ).pipe(
    map((responseArray) =>
      responseArray.flatMap(({ response }) =>
        formatMembers(response.data.users)
      )
    )
  )
}

const handleGetMemberRecentTransactionsEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      MEMBER_TRANSACTION_ACTIONS.ON_GET_BALANCES_SUCCESS,
      MEMBER_ACTIONS.ON_GET_RECENT_TRANSACTIONS
    ),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getTransactions(
            {
              brandId: extractBrandId(state$),
              ...buildMemberRecentTransactionsParams(state$)
            },
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: MEMBER_ACTIONS.ON_GET_RECENT_TRANSACTIONS_SUCCESS,
              payload: formatTransactions(response.data.transactions)
            })),
            catchError((err) =>
              of({
                type: MEMBER_ACTIONS.ON_GET_RECENT_TRANSACTIONS_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleCheckConditionForReportEmailEpic = (action$, state$) =>
  action$.pipe(
    ofType(MEMBER_DOWNLOAD_ACTIONS.ON_GET_MEMBERS_FOR_DOWNLOAD),
    map(() => extractMembersPagination(state$)),
    filter(({ totalTxCount }) => totalTxCount > ALLOW_MAX_RECORDS_FOR_DOWNLOAD),
    mapTo({
      type: MEMBER_DOWNLOAD_ACTIONS.ON_SEND_REPORT_IN_EMAIL
    })
  )

const handleMembersReportEmailEpic = (action$, state$) =>
  action$.pipe(
    ofType(MEMBER_DOWNLOAD_ACTIONS.ON_SEND_REPORT_IN_EMAIL),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .downloadReportToEmail(
            DOWNLOAD_REPORT_TYPES.MEMBERS,
            buildMembersQueriesForReportEmail(state$),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: DOWNLOAD_ACTION.ON_SENT_REPORT_IN_EMAIL_SUCCESS,
              payload: response
            })),
            catchError((err) =>
              of({
                type: DOWNLOAD_ACTION.ON_SENT_REPORT_IN_EMAIL_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleGetMemberBalancesEpic = (action$, state$) =>
  action$.pipe(
    ofType(MEMBER_TRANSACTION_ACTIONS.ON_GET_BALANCES),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .getMemberBalances(
            extractBrandId(state$),
            extractSelectedMember(state$)?.authId,
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: MEMBER_TRANSACTION_ACTIONS.ON_GET_BALANCES_SUCCESS,
              payload: response.data
            })),
            catchError((err) =>
              of({
                type: MEMBER_TRANSACTION_ACTIONS.ON_GET_BALANCES_FAILED,
                payload: err
              })
            )
          )
      )
    )
  )

const handleMemberPointsCreditDebitEpic = (action$, state$) =>
  action$.pipe(
    ofType(MEMBER_TRANSACTION_ACTIONS.ON_CREDIT_DEBIT_POINTS),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .memberTransaction(
            extractBrandId(state$),
            buildParamsForTransaction(
              extractSelectedMember(state$)?.authId,
              state$
            ),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(() => ({
              type: MEMBER_TRANSACTION_ACTIONS.ON_CREDIT_DEBIT_POINTS_SUCCESS
            })),
            catchError((data) =>
              of({
                type: MEMBER_TRANSACTION_ACTIONS.ON_CREDIT_DEBIT_POINTS_FAILED,
                payload: data.response
              })
            )
          )
      )
    )
  )

const handleRecentTransactionOnCreditDebitPointsEpic = (action$, state$) =>
  action$.pipe(
    ofType(MEMBER_TRANSACTION_ACTIONS.ON_CREDIT_DEBIT_POINTS_SUCCESS),
    delay(2000),
    map((_) => extractMemberTransactionProfile(state$)),
    switchMap(({ pointsBalance, selectedMember }) => {
      return wrapUserAccessToken((accessToken) =>
        apiService
          .getMemberBalances(
            extractBrandId(state$),
            selectedMember?.authId,
            accessToken
          )
          .pipe(
            delay(3000),
            repeat(3),
            filter(
              (_) =>
                pointsBalance ===
                extractMemberTransactionProfile(state$).pointsBalance
            ),
            tap((data) => logger(data)),
            map(({ response }) => ({
              type: MEMBER_TRANSACTION_ACTIONS.ON_GET_BALANCES_SUCCESS,
              payload: response.data
            })),
            catchError((err) =>
              of({
                type: MEMBER_TRANSACTION_ACTIONS.ON_GET_BALANCES_FAILED,
                payload: err
              })
            )
          )
      )
    })
  )

const handleMembersSearchByQueryEpic = (action$, state$) =>
  action$.pipe(
    ofType(MEMBER_SEARCH_ACTIONS.ON_SEARCH),
    mergeMap(({ payload: { query } }) =>
      wrapUserAccessToken((accessToken) =>
        searchMembersByEmailAuthId(query, state$, accessToken).pipe(
          map((response) => {
            return {
              type: MEMBER_SEARCH_ACTIONS.ON_SEARCH_SUCCESS,
              payload: response
            }
          }),
          catchError((err) =>
            of({
              type: MEMBER_SEARCH_ACTIONS.ON_SEARCH_FAILED,
              payload: err
            })
          )
        )
      )
    )
  )

const handleRewardPointsBankMemberEpic = (action$, state$) =>
  action$.pipe(
    ofType(REWARD_MEMBER_ACTION.ON_CREDIT_POINTS_BANK),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .rewardPointsBank(
            extractBrandId(state$),
            buildParamsForRewardPointsBank(state$),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(() => ({
              type: REWARD_MEMBER_ACTION.ON_CREDIT_POINTS_BANK_SUCCESS
            })),
            catchError((data) =>
              of({
                type: REWARD_MEMBER_ACTION.ON_CREDIT_POINTS_BANK_FAILED,
                payload: data.response
              })
            )
          )
      )
    )
  )

const handleRewardExchangeMemberEpic = (action$, state$) =>
  action$.pipe(
    ofType(REWARD_MEMBER_ACTION.ON_CREDIT_POINTS_EXCHANGE),
    mergeMap(({ payload }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .memberTransaction(
            extractBrandId(state$),
            buildParamsForRewardExchange(state$, payload),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(() => ({
              type: REWARD_MEMBER_ACTION.ON_CREDIT_POINTS_EXCHANGE_SUCCESS
            })),
            catchError((data) =>
              of({
                type: REWARD_MEMBER_ACTION.ON_CREDIT_POINTS_EXCHANGE_FAILED,
                payload: data.response
              })
            )
          )
      )
    )
  )

const handleUpdateTransactionsOnCreditPointsEpic = (action$) =>
  action$.pipe(
    ofType(
      REWARD_MEMBER_ACTION.ON_CREDIT_POINTS_EXCHANGE_SUCCESS,
      MEMBER_TRANSACTION_ACTIONS.ON_CREDIT_DEBIT_POINTS_SUCCESS
    ),
    delay(3000),
    map(() => ({
      type: TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS
    }))
  )

export const memberEpic = combineEpics(
  handleGetMembersEpic,
  handleGetMembersForDownloadEpic,
  handleMembersForDownloadBelowThresholdEpic,
  handleGetMemberRecentTransactionsEpic,
  handleCheckConditionForReportEmailEpic,
  handleMembersReportEmailEpic,
  handleGetMemberBalancesEpic,
  handleMemberPointsCreditDebitEpic,
  handleRecentTransactionOnCreditDebitPointsEpic,
  handleMembersSearchByQueryEpic,
  handleRewardPointsBankMemberEpic,
  handleRewardExchangeMemberEpic,
  handleUpdateTransactionsOnCreditPointsEpic
)

const buildMemberPaginationFilters = (payload, state) => {
  const pagination = extractMembersPagination(state)
  const { from_time, to_time } = buildMembersQueriesForDownload(state)
  const { query } = extractMemberSearchProfile(state)
  return {
    ...buildPaginationFilters(payload, pagination),
    ...(!query && { from_time, to_time }),
    ...emailQuery(query)
  }
}

const buildMemberRecentTransactionsParams = (state) => {
  const { analyticsFilter, tokenId } = getBrandIdTokenIdAnalyticsFilters(state)

  const { authId } = extractSelectedMember(state)
  return {
    user_auth_id: authId,
    limit: MEMBER_RECENT_TRANSACTION,
    offset: 0,
    ...handleGetFilterTypeForTransactions(analyticsFilter, tokenId)
  }
}

const buildMembersQueriesForDownload = (state) => {
  const brandId = extractBrandId(state)
  const limit = MAX_NUMBER_OF_RECORDS_FOR_DOWNLOAD
  const analyticsFilter = analyticsFilterData(state)
  const { query } = extractMemberSearchProfile(state)
  return {
    limit,
    brandId,
    ...(!query && buildDateRangeQueryParams(analyticsFilter)),
    ...emailQuery(query)
  }
}

const buildMembersQueriesForReportEmail = (state) => {
  const { brandId, from_time, to_time } = buildMembersQueriesForDownload(state)
  const { query } = extractMemberSearchProfile(state)
  const tokenProfile = extractTokenInfo(state)
  return {
    brand_id: brandId,
    from_time,
    to_time,
    ...emailQuery(query),
    filename: buildDownloadFileName(
      tokenProfile.name,
      DOWNLOAD_REPORT_TYPES.MEMBERS
    )
  }
}

const buildParamsForTransaction = (authId, state) => {
  const tokenId = extractTokenId(state)
  const { type, amount } = extractMemberTransactionProfile(state)
  return {
    transaction: {
      user_auth_id: authId,
      type,
      amount,
      token_id: tokenId,
      send_email: false
    }
  }
}

const searchMembersByEmailAuthId = (query, state, accessToken) => {
  const brandId = extractBrandId(state)
  return forkJoin({
    list: apiService.getMembers(
      {
        ...emailQuery(query),
        offset: 0,
        limit: NUMBER_OF_RECORDS_PER_PAGE
      },
      brandId,
      accessToken
    ),
    details: apiService
      .getMemberDetails(query, brandId, accessToken)
      .pipe(catchError((error) => of(error)))
  }).pipe(
    map((response) => {
      const listData = parseMembersResponse(response.list.response.data)
      const detailsData = response.details.response.data
      if (detailsData) {
        const searchedUser = commonParser(detailsData)
        if (
          !listData.users.some((user) => user.authId === searchedUser.authId)
        ) {
          listData.totalCount = listData.totalCount + 1
          listData.users.push(searchedUser)
        }
      }
      return listData
    })
  )
}

const emailQuery = (query) => {
  return query && { email_like: encodeURIComponent(query) }
}
