import { TOKEN_ACTIONS } from '../../constants/actions'

// DASHBOARD SETUP TOKEN
const changeTokenState = (property, value) => {
  return {
    type: TOKEN_ACTIONS.ON_CHANGE_STATE,
    payload: {
      property,
      value
    }
  }
}

const createToken = () => {
  return {
    type: TOKEN_ACTIONS.ON_CREATE_TOKEN
  }
}

const updateToken = (tokenInfo) => ({
  type: TOKEN_ACTIONS.ON_UPDATE_TOKEN,
  payload: tokenInfo
})

const resetTokenState = () => ({
  type: TOKEN_ACTIONS.ON_RESET_STATE
})

const resetTokenStats = () => ({
  type: TOKEN_ACTIONS.ON_RESET_TOKEN_STATS
})

const getTokens = (fetchDetails = false) => {
  return {
    type: TOKEN_ACTIONS.ON_GET_TOKENS,
    payload: { fetchDetails }
  }
}

const getTokenById = (payload) => {
  return {
    type: TOKEN_ACTIONS.ON_GET_TOKEN_BY_ID,
    payload
  }
}

const getTokenStatsForLastAndCurrentWeek = () => {
  return {
    type: TOKEN_ACTIONS.ON_GET_TOKEN_STATS_FOR_LAST_AND_CURRENT_WEEK
  }
}

const selectToken = (id) => {
  return {
    type: TOKEN_ACTIONS.ON_SELECT_TOKEN,
    payload: id
  }
}

export {
  changeTokenState,
  resetTokenState,
  createToken,
  updateToken,
  getTokenById,
  selectToken,
  getTokens,
  resetTokenStats,
  getTokenStatsForLastAndCurrentWeek
}
