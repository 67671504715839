import React, { memo } from 'react'
import { Trans } from 'react-i18next'

import Confirmed from '../common/Confirmed'
import Link from '../../shared/link/Link'

const ActivationConfirmed = ({
  t,
  selectedModule: { id },
  helpCenterUrl,
  descriptionId,
  ...restProps
}) => {
  return (
    <Confirmed
      t={t}
      title='common.congratulations'
      rawDescription={
        <>
          {t(`extensions.earn-options.${descriptionId}.activated-desc`, {
            title: t(`extensions.earn-options.${id}.title`)
          })}
          <br />
          <br />
          <Trans i18nKey='extensions.activate-help-link'>
            <Link isOutside to={helpCenterUrl} label={t('common.here')} />
          </Trans>
        </>
      }
      {...restProps}
    />
  )
}

export default memo(ActivationConfirmed)
