import { ANALYTICS_FILTER_ACTION } from '../../../constants/actions'
import { mergeToNewObject } from '../../../util/object.helpers'
import {
  ANALYTICS_DATE_RANGE_FILTERS,
  ANALYTICS_INTERVAL_FILTERS,
  ANALYTICS_TAB_TYPE
} from '../../../constants/analytics'

import {
  getDateDaysSubtractedBy,
  convertToDate,
  getTodaysDate,
  getMaxDaysForFilter,
  getDateMonthsSubtractedBy,
  getDateHoursSubtractedBy
} from '../../../util/date.helpers'
import {
  EXCHANGE_TRANSACTION_TYPES,
  TRANSACTION_TYPES
} from '../../../constants/transactionTypes'

export const analyticsFilterProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case ANALYTICS_FILTER_ACTION.ON_CHANGE_STATE:
      return mergeToNewObject(state, buildOnChangeState(payload, state))

    case ANALYTICS_FILTER_ACTION.ON_RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  type: ANALYTICS_TAB_TYPE.POINTS_EXCHANGE,
  dateRange: ANALYTICS_DATE_RANGE_FILTERS.LAST_30_DAYS,
  startDate: convertToDate(getDateDaysSubtractedBy(29)),
  endDate: getTodaysDate(),
  transactionType: TRANSACTION_TYPES.ALL,
  interval: ANALYTICS_INTERVAL_FILTERS.DAILY,
  exchangeType: EXCHANGE_TRANSACTION_TYPES.ALL
}

export const ANALYTICS_FILTER_DISPATCH = {
  TYPE: 'type',
  DATE_RANGE: 'dateRange',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  TRANSACTION_TYPE: 'transactionType',
  INTERVAL: 'interval',
  EXCHANGE_TYPE: 'exchangeType'
}

const buildOnChangeState = ({ property, value }, state) => {
  return {
    ...state,
    [property]: value,
    ...buildStartEndDateByFilter({ property, value })
  }
}

const buildStartEndDateByFilter = ({ property, value }) => {
  if (property === ANALYTICS_FILTER_DISPATCH.DATE_RANGE) {
    switch (value) {
      case ANALYTICS_DATE_RANGE_FILTERS.LAST_24_HOURS:
        return {
          startDate: convertToDate(getDateHoursSubtractedBy(23)),
          endDate: getTodaysDate()
        }
      case ANALYTICS_DATE_RANGE_FILTERS.LAST_7_DAYS:
        return {
          startDate: convertToDate(getDateDaysSubtractedBy(6)),
          endDate: getTodaysDate()
        }
      case ANALYTICS_DATE_RANGE_FILTERS.LAST_15_DAYS:
        return {
          startDate: convertToDate(getDateDaysSubtractedBy(14)),
          endDate: getTodaysDate()
        }
      case ANALYTICS_DATE_RANGE_FILTERS.LAST_30_DAYS:
        return {
          startDate: convertToDate(getDateDaysSubtractedBy(29)),
          endDate: getTodaysDate()
        }
      case ANALYTICS_DATE_RANGE_FILTERS.LAST_3_MONTHS:
        return {
          startDate: convertToDate(getDateMonthsSubtractedBy(3)),
          endDate: getTodaysDate()
        }
      case ANALYTICS_DATE_RANGE_FILTERS.LAST_6_MONTHS:
        return {
          startDate: convertToDate(getDateMonthsSubtractedBy(6)),
          endDate: getTodaysDate()
        }
      case ANALYTICS_DATE_RANGE_FILTERS.LAST_12_MONTHS:
        return {
          startDate: convertToDate(getDateMonthsSubtractedBy(12)),
          endDate: getTodaysDate()
        }

      default:
        return {}
    }
  } else if (property === ANALYTICS_FILTER_DISPATCH.START_DATE) {
    return {
      endDate: getMaxDaysForFilter(value),
      dateRange: ANALYTICS_DATE_RANGE_FILTERS.CUSTOM
    }
  } else if (property === ANALYTICS_FILTER_DISPATCH.END_DATE) {
    return {
      dateRange: ANALYTICS_DATE_RANGE_FILTERS.CUSTOM
    }
  }
}
