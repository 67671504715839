import React, { memo } from 'react'
import { FormControl } from '@mui/material'

import InputHelperText from './InputHelperText'

const CustomFormControl = ({
  bottomLabel,
  errorMessage,
  successMsg,
  fullWidth = true,
  children,
  ...restProps
}) => {
  const error = Boolean(errorMessage)
  return (
    <FormControl fullWidth={fullWidth} error={error} {...restProps}>
      {children}
      <InputHelperText
        successMsg={successMsg}
        errorMessage={errorMessage}
        bottomLabel={bottomLabel}
      />
    </FormControl>
  )
}

export default memo(CustomFormControl)
