// @ts-nocheck
import React, { memo } from 'react'
import { Grid, Box } from '@mui/material'

import Text from '../shared/text/Text'
import FormWrapper from '../shared/BaseForm/FormWrapper'
import CustomButton from '../shared/buttons/CustomButton'
import PointValueField from '../tokenInfo/PointValueField'
import Input from '../shared/inputs/Input'
import { LABEL_TEXT } from '../../constants/messages'
import {
  transWithArgs,
  validPointValueRule
} from '../../util/validation.helpers'
import { POINT_VALUE_MIN_LENGTH } from '../../constants/validation'
import { EXCHANGE_SETTINGS_DISPATCH } from '../../store/reducers/exchange/exchangeSettings'
import Dropdown from '../shared/inputs/Dropdown'
import { buildLoginIdTypeOptions } from '../../data/exchange'
import MultilineField from '../shared/MultilineField/MultilineField'
import { Trans } from 'react-i18next'
import Link from '../shared/link/Link'
import { DASHBOARD_EMAIL } from '../../constants/externalResources'
import DefaultText from '../shared/text/DefaultText'
import { QB_ACTION_SUBMIT } from '../../constants/input'

const ExchangeSettings = ({
  onFormSubmit,
  t,
  dispatchAction,
  exchangeSettingsProfile: {
    exchangeValueTemp,
    programUrlTemp,
    catalogueUrlTemp,
    loginIdTypeTemp,
    brandRequirementsTemp,
    additionalServicesTemp,
    error,
    success
  }
}) => {
  return (
    <>
      <DefaultText label={t('exchange.exchange-settings-desc')} />
      <FormWrapper
        onFormSubmit={onFormSubmit}
        fields={
          <Grid
            container
            spacing={3}
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <Grid item lg={2} md={2} xs={12}>
              <PointValueField t={t} value={1.4} disabled />
            </Grid>
            <Grid item lg={3.3} md={3.3} xs={12}>
              <Input
                id={EXCHANGE_SETTINGS_DISPATCH.EXCHANGE_VALUE_TEMP}
                label={`${t(LABEL_TEXT.POINT_EXCHANGE_VALUE)} (${t(
                  'exchange.off-ramp'
                )}) (${t('common.optional')})`}
                bottomLabel={t(LABEL_TEXT.EXCHANGE_CURRENCY_DESC)}
                size='small'
                value={exchangeValueTemp}
                errorMessage={transWithArgs(
                  t,
                  error?.[EXCHANGE_SETTINGS_DISPATCH.EXCHANGE_VALUE_TEMP]
                )}
                successMsg={transWithArgs(
                  t,
                  success?.[EXCHANGE_SETTINGS_DISPATCH.EXCHANGE_VALUE_TEMP]
                )}
                validate={[validPointValueRule]}
                onChange={(value) =>
                  dispatchAction(
                    EXCHANGE_SETTINGS_DISPATCH.EXCHANGE_VALUE_TEMP,
                    value
                  )
                }
                inputProps={{
                  maxLength: POINT_VALUE_MIN_LENGTH
                }}
              />
            </Grid>
            <Grid item lg={3.3} md={3.3} xs={12}>
              <Input
                id={EXCHANGE_SETTINGS_DISPATCH.PROGRAM_URL_TEMP}
                label={
                  <>
                    <br /> {t('exchange.loyalty-program-url')}
                  </>
                }
                size='small'
                value={programUrlTemp}
                errorMessage={transWithArgs(
                  t,
                  error?.[EXCHANGE_SETTINGS_DISPATCH.PROGRAM_URL_TEMP]
                )}
                successMsg={transWithArgs(
                  t,
                  success?.[EXCHANGE_SETTINGS_DISPATCH.PROGRAM_URL_TEMP]
                )}
                onChange={(value) =>
                  dispatchAction(
                    EXCHANGE_SETTINGS_DISPATCH.PROGRAM_URL_TEMP,
                    value
                  )
                }
                bottomLabel=' '
              />
            </Grid>
            <Grid item lg={3.3} md={3.3} xs={12}>
              <Input
                id={EXCHANGE_SETTINGS_DISPATCH.CATALOGUE_URL_TEMP}
                label={`${t(
                  'exchange.loyalty-program-redemption-catalogue-url'
                )} (${t('common.optional')})`}
                size='small'
                value={catalogueUrlTemp}
                errorMessage={transWithArgs(
                  t,
                  error?.[EXCHANGE_SETTINGS_DISPATCH.CATALOGUE_URL_TEMP]
                )}
                successMsg={transWithArgs(
                  t,
                  success?.[EXCHANGE_SETTINGS_DISPATCH.CATALOGUE_URL_TEMP]
                )}
                onChange={(value) =>
                  dispatchAction(
                    EXCHANGE_SETTINGS_DISPATCH.CATALOGUE_URL_TEMP,
                    value
                  )
                }
                bottomLabel=' '
              />
            </Grid>
            <Grid item lg={3} md={3} xs={12}>
              <Dropdown
                id={EXCHANGE_SETTINGS_DISPATCH.LOGIN_ID_TYPE_TEMP}
                label={t('exchange.login-id-type')}
                options={buildLoginIdTypeOptions(t)}
                onChange={({ target }) =>
                  dispatchAction(
                    EXCHANGE_SETTINGS_DISPATCH.LOGIN_ID_TYPE_TEMP,
                    target.value
                  )
                }
                value={loginIdTypeTemp}
                errorMessage={transWithArgs(
                  t,
                  error[EXCHANGE_SETTINGS_DISPATCH.LOGIN_ID_TYPE_TEMP]
                )}
                successMsg={transWithArgs(
                  t,
                  success[EXCHANGE_SETTINGS_DISPATCH.LOGIN_ID_TYPE_TEMP]
                )}
                size='small'
                bottomLabel=' '
              />
            </Grid>
            <Grid item lg={9} md={9} xs={12}>
              <Text
                variant='body1'
                color='textprimary'
                label={
                  <Trans i18nKey='exchange.login-id-custom-api-desc'>
                    <Link isEmail to={DASHBOARD_EMAIL} />
                  </Trans>
                }
                sx={{ mt: 1 }}
              />
            </Grid>
            <Grid item xs={12}>
              <MultilineField
                id={EXCHANGE_SETTINGS_DISPATCH.BRAND_REQUIREMENTS_TEMP}
                label={`${t('exchange.custom-brand-requirements')} (${t(
                  'common.optional'
                )})`}
                value={brandRequirementsTemp}
                errorMessage={transWithArgs(
                  t,
                  error[EXCHANGE_SETTINGS_DISPATCH.BRAND_REQUIREMENTS_TEMP]
                )}
                successMsg={transWithArgs(
                  t,
                  success[EXCHANGE_SETTINGS_DISPATCH.BRAND_REQUIREMENTS_TEMP]
                )}
                onChange={(value) =>
                  dispatchAction(
                    EXCHANGE_SETTINGS_DISPATCH.BRAND_REQUIREMENTS_TEMP,
                    value
                  )
                }
                t={t}
              />
            </Grid>
            <Grid item xs={12}>
              <MultilineField
                id={EXCHANGE_SETTINGS_DISPATCH.ADDITIONAL_SERVICES_TEMP}
                label={`${t('exchange.additional-services-you-offer')} (${t(
                  'common.optional'
                )})`}
                value={additionalServicesTemp}
                errorMessage={transWithArgs(
                  t,
                  error[EXCHANGE_SETTINGS_DISPATCH.ADDITIONAL_SERVICES_TEMP]
                )}
                successMsg={transWithArgs(
                  t,
                  success[EXCHANGE_SETTINGS_DISPATCH.ADDITIONAL_SERVICES_TEMP]
                )}
                onChange={(value) =>
                  dispatchAction(
                    EXCHANGE_SETTINGS_DISPATCH.ADDITIONAL_SERVICES_TEMP,
                    value
                  )
                }
                t={t}
              />
            </Grid>
          </Grid>
        }
        actions={
          <>
            <Box sx={{ flex: '1 1 auto' }} />
            <CustomButton
              type={QB_ACTION_SUBMIT}
              variant='contained'
              label={t('common.save')}
              fullWidth={false}
            />
          </>
        }
      />
    </>
  )
}

export default memo(ExchangeSettings)
