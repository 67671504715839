import { AUTH_ACTION, ACCOUNT_ACTION } from '../../../constants/actions'
import {
  ERROR_TEXT,
  MESSAGE_TEXT,
  QB_ERROR_TEXT
} from '../../../constants/messages'
import {
  DEFAULT_CONTAINER_STATE,
  ACCOUNT_INIT_PROCESS_STEP
} from '../../../constants/containerStates'
import { THEME_MODE_DARK, THEME_MODE_LIGHT } from '../../../constants/theme'
import { buildSuccessMessages } from '../../../util/reducer.helpers'
import { QB_COGNITO_ERRORS } from '../../../constants/apiErrors'
import {
  mergeToNewObject,
  extractInputValueAndError
} from '../../../util/object.helpers'
import { formatValidator } from '../../../util/validation.helpers'
import { APP_CONFIG } from '../../../constants/appConfig'

export const accountProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACCOUNT_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case ACCOUNT_ACTION.GET_ACCOUNT_DETAILS:
    case ACCOUNT_ACTION.ON_UPDATE_USER_ACCOUNT:
      return mergeToNewObject(state, { loading: true })

    case ACCOUNT_ACTION.CHANGE_INPUT_AND_ERROR_STATE:
      return mergeToNewObject(
        state,
        extractInputValueAndError(payload, state.error)
      )

    case ACCOUNT_ACTION.ON_GET_ACCOUNT_DETAILS_SUCCESS:
    case ACCOUNT_ACTION.ON_CREATE_ACCOUNT_SUCCESS:
      return mergeToNewObject(state, handleGetAccountDetailsResponse(payload), {
        loading: false
      })

    case ACCOUNT_ACTION.ON_UPDATE_USER_ACCOUNT_SUCCESS:
      return mergeToNewObject(state, handleUserUpdateResponse(payload, state), {
        loading: false
      })

    case AUTH_ACTION.ON_UPDATE_USER_ATTRIBUTES_FAILED:
      return mergeToNewObject(
        state,
        { error: handleUpdateUserErrors(payload) },
        { loading: false }
      )

    case AUTH_ACTION.ON_CHANGE_PASSWORD_SUCCESS:
      return mergeToNewObject(
        state,
        {
          success: { newPassword: { message: MESSAGE_TEXT.PASSWORD_CHANGED } }
        },
        { loading: false }
      )

    case AUTH_ACTION.ON_CHANGE_PASSWORD_FAILED:
      return mergeToNewObject(
        state,
        { error: handleChangePasswordErrors(payload) },
        { loading: false }
      )

    case ACCOUNT_ACTION.RESET_STATE:
      return mergeToNewObject(state, handleResetState(state))

    case ACCOUNT_ACTION.ON_CREATE_ACCOUNT_FAILED:
      return mergeToNewObject(state, {
        accountInitState: ACCOUNT_INIT_PROCESS_STEP.CREATE_ACCOUNT_FAILED
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  userId: '',

  firstName: '',
  firstNameTemp: '',

  lastName: '',
  lastNameTemp: '',

  email: '',
  emailTemp: '',

  jobPosition: '',
  jobPositionTemp: '',

  country: '',
  countryTemp: '',

  oldPassword: '',
  newPassword: '',

  language: '',

  accountInitState: DEFAULT_CONTAINER_STATE.NONE,

  metadata: {
    darkMode: false,
    hideIntegrationNote: false,
    hideAnalyticsNote: false,
    hideNftNote: false
  },

  activeMode: THEME_MODE_LIGHT,

  error: {},
  success: {},

  loading: false
}

export const ACCOUNT_PROFILE_DISPATCH = {
  USER_ID: 'userId',

  FIRST_NAME: 'firstName',
  FIRST_NAME_TEMP: 'firstNameTemp',

  LAST_NAME: 'lastName',
  LAST_NAME_TEMP: 'lastNameTemp',

  EMAIL: 'email',
  EMAIL_TEMP: 'emailTemp',

  JOB_POSITION: 'jobPosition',
  JOB_POSITION_TEMP: 'jobPositionTemp',

  COUNTRY: 'country',
  COUNTRY_TEMP: 'countryTemp',

  OLD_PASSWORD: 'oldPassword',
  NEW_PASSWORD: 'newPassword',

  LANGUAGE: 'language',

  ACCOUNT_INIT_STATE: 'accountInitState',

  ERROR: 'error',
  SUCCESS: 'success',
  LOADING: 'loading'
}

function handleGetAccountDetailsResponse(payload) {
  const {
    firstName,
    secondName,
    email,
    id,
    language,
    jobPosition,
    countryISO,
    metadata
  } = payload
  if (APP_CONFIG.STONLY_WIDGET_ID) {
    window.StonlyWidget('identify', id)
  }
  return {
    userId: id,

    firstName,
    firstNameTemp: firstName,

    lastName: secondName,
    lastNameTemp: secondName,

    email,
    emailTemp: email,

    jobPosition,
    jobPositionTemp: jobPosition,

    country: countryISO,
    countryTemp: countryISO,

    language,

    metadata,

    activeMode: metadata?.darkMode ? THEME_MODE_DARK : THEME_MODE_LIGHT,

    error: {},
    success: {}
  }
}

function handleResetState(state) {
  const { firstName, lastName, email, jobPosition, country } = state

  return {
    firstNameTemp: firstName,
    lastNameTemp: lastName,
    emailTemp: email,
    jobPositionTemp: jobPosition,
    countryTemp: country,
    oldPassword: '',
    newPassword: '',
    error: {},
    success: {}
  }
}

const handleUserUpdateResponse = (payload, state) => {
  const {
    firstName,
    secondName,
    email,
    language,
    jobPosition,
    countryISO,
    metadata
  } = payload
  const {
    firstName: firstNameTemp,
    lastName: lastNameTemp,
    email: emailTemp,
    jobPosition: jobPositionTemp,
    country: countryTemp
  } = state
  const prevDetails = {
    firstNameTemp,
    lastNameTemp,
    emailTemp,
    jobPositionTemp,
    countryTemp
  }
  const updatedDetails = {
    firstNameTemp: firstName,
    lastNameTemp: secondName,
    emailTemp: email,
    jobPositionTemp: jobPosition,
    countryTemp: countryISO
  }
  return {
    firstName,
    lastName: secondName,
    email,
    language,
    jobPosition,
    country: countryISO,
    ...updatedDetails,
    success: buildSuccessMessages(prevDetails, updatedDetails),
    metadata,
    activeMode: metadata?.darkMode ? THEME_MODE_DARK : THEME_MODE_LIGHT,
    error: {}
  }
}

const handleUpdateUserErrors = ({ code }) => {
  switch (code) {
    case QB_COGNITO_ERRORS.ALIAS_EXISTS_EXCEPTION:
      return {
        emailTemp: formatValidator(ERROR_TEXT.EMAIL_ALREADY_EXISTS)
      }
    default:
      return {
        emailTemp: formatValidator(QB_ERROR_TEXT.SOMETHING_WENT_WRONG)
      }
  }
}

const handleChangePasswordErrors = ({ code }) => {
  switch (code) {
    case QB_COGNITO_ERRORS.NOT_AUTHORIZED_EXCEPTION:
      return {
        oldPassword: formatValidator(ERROR_TEXT.INCORRECT_OLD_PASSWORD)
      }
    case QB_COGNITO_ERRORS.LIMIT_EXCEEDED_EXCEPTION:
      return {
        newPassword: formatValidator(QB_ERROR_TEXT.ATTEMPT_LIMIT_EXCEEDED)
      }
    default:
      return {
        newPassword: formatValidator(QB_ERROR_TEXT.SOMETHING_WENT_WRONG)
      }
  }
}
