import React, { memo } from 'react'
import { Typography } from '@mui/material'

const Text = ({ label, ...restProps }) => {
  return <Typography {...restProps}>{label}</Typography>
}

Text.defaultProps = {
  fontWeight: 400,
  variant: 'h4'
}

export default memo(Text)
