import { combineReducers } from 'redux'

import { authReducer } from './auth'
import { tokensReducer } from './tokens'
import { transactionsReducer } from './transactions'
import { dashboardReducer } from './dashboard'
import { accountReducer } from './account'
import { mainReducer } from './main'
import { membersReducer } from './members'
import { exchangeReducer } from './exchange'
import { AUTH_ACTION } from '../../constants/actions'
import { welcomeReducer } from './welcome'
import { adminReducer } from './admin'
import { apiKeysReducer } from './apiKeys'
import { analyticsReducer } from './analytics'
import { nftsReducer } from './nfts'
import { requestFeatureReducer } from './requestFeature'
import { surveyReducer } from './survey'
import { extensionsReducer } from './extensions'

const rootReducer = combineReducers({
  authReducer,
  tokensReducer,
  transactionsReducer,
  dashboardReducer,
  accountReducer,
  mainReducer,
  membersReducer,
  exchangeReducer,
  welcomeReducer,
  adminReducer,
  apiKeysReducer,
  analyticsReducer,
  nftsReducer,
  requestFeatureReducer,
  surveyReducer,
  extensionsReducer
})

const reducer = (state, action) => {
  if (action.type === AUTH_ACTION.ON_SIGN_OUT_SUCCESS) {
    state = undefined
  }
  return rootReducer(state, action)
}

export { reducer }
