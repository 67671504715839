import React, { memo } from 'react'
import { Grid } from '@mui/material'

import ExchangeDetails from './ExchangeDetails'
import ExchangeProgress from './ExchangeProgress'
import BuyerSellerPerformanceFee from './BuyerSellerPerformanceFee'
import { isTokenStopped } from '../../../util/token.helpers'

const PackageBillingDetails = ({
  billingProfile: { subscribedPackage, exchangePerformance },
  onChangePackage,
  t,
  tokenStatus
}) => {
  if (!subscribedPackage?.id || isTokenStopped(tokenStatus)) {
    return null
  }
  return (
    <Grid container spacing={2} pt={3}>
      <Grid item xs={12}>
        <ExchangeDetails subscribedPackage={subscribedPackage} t={t} />
      </Grid>
      <Grid item xs={12}>
        <ExchangeProgress
          subscribedPackage={subscribedPackage}
          t={t}
          exchangePerformance={exchangePerformance}
        />
      </Grid>
      <Grid item xs={12}>
        <BuyerSellerPerformanceFee
          subscribedPackage={subscribedPackage}
          exchangePerformance={exchangePerformance}
          onChangePackage={onChangePackage}
          t={t}
        />
      </Grid>
    </Grid>
  )
}

export default memo(PackageBillingDetails)
