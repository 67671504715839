import React, { memo } from 'react'
import { CardContent, Grid } from '@mui/material'

import BaseCard from '../BaseCard/BaseCard'
import TitleWithPopover from '../TitleWithPopover/TitleWithPopover'
import { mergeToNewObject } from '../../../util/object.helpers'

const DashboardCard = ({
  title,
  helpText,
  action,
  children,
  noPadding,
  contentProps,
  headerProps,
  sx
}) => (
  <BaseCard sx={sx}>
    {(title || action) && (
      <Grid sx={headerProps} container spacing={1} padding={2}>
        <Grid item xs={12} sm={action ? 4 : 12}>
          {title && (
            <TitleWithPopover variant='h3' title={title} helpText={helpText} />
          )}
        </Grid>
        <Grid item xs={12} sm={8} textAlign='right'>
          {action}
        </Grid>
      </Grid>
    )}
    <CardContent
      sx={mergeToNewObject(contentProps, noPadding && PADDING_BOTTOM_NONE)}
    >
      {children}
    </CardContent>
  </BaseCard>
)

export default memo(DashboardCard)

const PADDING_BOTTOM_NONE = { '&:last-child': { pb: 0 } }
