export const POINT_NAME_MAX_LENGTH = 25
export const DEFAULT_INPUT_MAX_LENGTH = 32
export const POINT_SYMBOL_MIN_LENGTH = 3
export const POINT_SYMBOL_MAX_LENGTH = 5
export const MULTILINE_FIELD_MAX_LENGTH = 1000
export const NFT_NAME_MAX_LENGTH = 100
export const POINT_VALUE_MIN_LENGTH = 15
export const CREDIT_DEBIT_POINTS_MAX_LENGTH = 6
export const SALES_FEE_MAX_LENGTH = 2

export const URL_VALIDATION_PATTERN =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/gm
