import React, { memo } from 'react'
import { Box } from '@mui/material'

import Text from '../text/Text'
import DefaultText from '../text/DefaultText'
import { CSS_FLEX_ALIGNED, CSS_FLEX_COLUMN } from '../../../styles/common'

const PanelHeading = ({ title, description, button, customCss, children }) => {
  return (
    <Box
      sx={{
        ...CSS_FLEX_COLUMN,
        height: '100%',
        p: 3,
        ...customCss
      }}
    >
      <Box sx={{ ...CSS_FLEX_ALIGNED, justifyContent: 'space-between', p: 0 }}>
        {title && <Text variant='h2' label={title} fontWeight={500} />}
        {button}
      </Box>
      {description && <DefaultText label={description} />}
      {children}
    </Box>
  )
}

export default memo(PanelHeading)
