export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  PAST_DUE: 'past_due',
  UNPAID: 'unpaid',
  CANCELED: 'canceled',
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  TRAILING: 'trialing',
  ALL: 'all',
  ENDED: 'ended'
}

export const PERFORMANCE_FEE_TYPES = {
  BUYERS: 'buyers',
  SELLERS: 'sellers',
  TOTAL: 'total'
}

export const PERFORMANCE_FEE_COLORS = {
  [PERFORMANCE_FEE_TYPES.BUYERS]: 'danger',
  [PERFORMANCE_FEE_TYPES.SELLERS]: 'success',
  [PERFORMANCE_FEE_TYPES.TOTAL]: 'primary'
}

export const STRIPE_PAYMENT_STATUS = {
  ACTIVE: 'active'
}

export const STARTER_PACKAGE_CALENDLY_URL =
  'https://calendly.com/qiibee/discoverycall'
