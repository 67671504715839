import React, { memo } from 'react'
import { IconButton } from '@mui/material'
import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-light-svg-icons'

import FaIcon from '../FaIcon/FaIcon'
import Input from './Input'

const SearchInput = ({ onResetSearchClick, value, ...restProps }) => {
  return (
    <Input
      size='small'
      endIcon={
        value ? (
          <IconButton onClick={onResetSearchClick} edge='end'>
            <FaIcon icon={faXmark} />
          </IconButton>
        ) : (
          <FaIcon icon={faMagnifyingGlass} />
        )
      }
      value={value}
      {...restProps}
    />
  )
}

export default memo(SearchInput)
