export const MESSAGE_TEXT = {
  PASSWORD_CHANGED: 'message.your-password-was-changed',
  FORGOT_PASSWORD_SUCCESS: 'message.forgot-password-success'
}

export const ERROR_TEXT = {
  SOMETHING_WENT_WRONG: 'error.something-went-wrong',
  CHECK_FIELDS_CORRECT: 'error.check-fields-correct',
  FORGOT_PASSWORD_ERROR: 'error.forgot-password-error',
  EMAIL_ALREADY_EXISTS: 'error.email-already-exists',
  INCORRECT_OLD_PASSWORD: 'error.old-password-incorrect',
  EMAIL_VERIFICATION_FAILED: 'error.email-verification-failed',
  RESEND_EMAIL_VERIFICATION_FAILED: 'error.failed-to-resend-verification-email',
  PASSWORD_LINK_EXPIRED: 'error.password-reset-link-expired',
  OLD_NEW_PASSWORDS_SAME: 'error.old-new-passwords-are-same',
  INVALID_URL: 'error.invalid-url',
  NFT_NAM_DESCRIPTION_CONFLICT: 'nft.nft-name-description-already-exists',
  INVALID_ACCOUNT_NUMBER: 'error.invalid-account-number',
  INSUFFICIENT_AMOUNT_TO_REWARD: 'error.insufficient-amount-to-reward',
  EXCEED_MAX_LIMIT: 'error.you-exceed-max-limit'
}

export const LABEL_TEXT = {
  GET_ACCESS_TO_DASHBOARD: 'setup.get-access-to-the-dashboard',
  STREET_ADDRESS: 'common.street-address',
  ZIP_CODE: 'common.zip-code',
  CITY: 'common.city',
  BRAND_NAME: 'setup.brand-name',
  EXCHANGE_CURRENCY_DESC: 'common.exchange-currency-desc',
  POINT_NO_EXPIRATION: 'token-setup.no-expiration',
  POINT_EXTEND_NEXT_YEAR: 'token-setup.extend-end-of-next-year',
  POINT_NAME: 'token-setup.loyalty-point-name',
  POINT_STATUS: 'token-setup.loyalty-point-status',
  POINT_SYMBOL: 'token-setup.loyalty-point-symbol',
  AMOUNT_OF_MEMBERS: 'token-setup.amount-of-members',
  PROGRAM_DESCRIPTION: 'token-setup.loyalty-program-description',
  POINT_VALUE: 'token-setup.point-value',
  SELECT_PREFERRED_BLOCKCHAIN: 'token-setup.select-your-preferred-blockchain',
  POINT_EXCHANGE_VALUE: 'token-setup.point-exchange-value',
  VERIFY_RESENT_SUCCESS: 'verify.resent-verification-success',
  REWARD_TYPE: 'exchange.reward-type',
  CONNECTION_TYPE: 'exchange.connection-type',
  REQUEST_TYPE: 'exchange.request-type',
  TYPE_OF_INTEGRATION: 'integration.type-of-integration',
  SORTING: 'common.sorting',
  STATUS: 'common.status',
  TYPE: 'common.type',
  DESCRIPTION: 'common.description',
  SIGNUP: 'Signup'
}

export const QB_ERROR_TEXT = {
  FIELD_REQUIRED: 'error.required-field',
  INVALID_EMAIL: 'error.invalid-email',
  INVALID_PASSWORD: 'error.invalid-password',
  INVALID_NUMBER_FORMAT: 'error.invalid-number-format',
  SOMETHING_WENT_WRONG: 'error.something-went-wrong',
  ATTEMPT_LIMIT_EXCEEDED: 'error.attempt-limit-exceeded',
  ACCESS_DENIED: 'error.access-denied',
  CHARACTERS_MIN_MAX: 'error.characters-min-max',
  CHARACTERS_MAX: 'error.characters-max',
  GREATER_OR_EQUAL_TO_VALUE: 'error.greater-or-equal-to-value',
  MAX: 'error.max'
}

export const QB_LABEL_TEXT = {
  LOGOUT: 'auth.log-out',
  SIDEBAR_ACCOUNT: 'dashboard-sidebar.account',
  TOTAL: 'dashboard-sidebar.total',
  SUPPLY: 'dashboard-sidebar.supply',
  CIRCULATING: 'dashboard-sidebar.circulating',
  ALL_TRANSACTIONS: 'analytics.points-bank-txn-type-filters.all',
  ALL_MEMBERS: 'members.all-members',
  CREATED: 'transactions.created',
  VERIFY_TITLE: 'verify.title',
  VERIFY_DESC: 'verify.description',
  VERIFY_BUTTON: 'verify.button-label',
  VERIFIED_TITLE: 'verified.title',
  VERIFIED_DESC: 'verified.description',
  VERIFIED_BUTTON: 'verified.button-label',
  CANCEL: 'common.cancel',
  USE_SHIFT_TO_SELECT: 'common.use-shift-to-select-multiple',
  SELECT_REGION: 'common.select-region',
  SELECT_COUNTRY: 'common.select-country',
  BACK: 'common.back',
  COUNTRY: 'common.country',
  REGION: 'common.region',
  MEMBERS: 'sidebar.members',
  TOKEN_ACTIVE: 'token.status.active',
  TOKEN_PAUSED: 'token.status.paused',
  TOKEN_STOPPED: 'token.status.stopped',
  TOKEN_SUCCESSFUL: 'token.status.successful',
  TOKEN_REJECTED: 'token.status.rejected',
  TOKEN_PENDING: 'token.status.pending',
  ALL_COUNTRIES_SELECTED_REGION: 'common.all-countries-in-selected-region',
  WORLDWIDE: 'common.worldwide'
}
