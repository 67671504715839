import React, { memo } from 'react'
import { Grid, CardContent, Box } from '@mui/material'

import FaIcon from '../FaIcon/FaIcon'
import Text from '../text/Text'
import TitleWithPopover from '../TitleWithPopover/TitleWithPopover'
import CustomFab from '../inputs/CustomFab'
import { TRANSPARENT_BLACK } from '../../../styles/common'

const StatCard = ({
  header,
  title,
  helpText,
  color,
  icon,
  noBorder,
  cardStyles,
  borderDirection = 'borderRight',
  gridSize = 4
}) => {
  return (
    <Grid item xs={12} sm={gridSize}>
      <CardContent
        sx={{
          ...(!noBorder && {
            [borderDirection]: {
              xs: '0',
              sm: `1px solid ${TRANSPARENT_BLACK}`
            }
          }),
          padding: 3,
          height: '100%',
          display: 'grid',
          placeItems: 'center',
          placeContent: 'center',
          ...cardStyles
        }}
      >
        <CustomFab
          size='large'
          sx={{
            backgroundColor: `${color}.light`,
            color: `${color}.main`,
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: `${color}.light`
            }
          }}
        >
          <FaIcon icon={icon} size='lg' />
        </CustomFab>
        <Box
          display='flex'
          alignItems='center'
          sx={{
            mt: 2
          }}
        >
          <Text
            variant='h3'
            color={`${color}.main`}
            label={header}
            fontWeight={500}
          />
        </Box>
        <TitleWithPopover
          color='textSecondary'
          variant='h6'
          title={title}
          helpText={helpText}
          sx={{ textAlign: 'center' }}
        />
      </CardContent>
    </Grid>
  )
}

export default memo(StatCard)
