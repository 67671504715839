import React, { memo } from 'react'
import { Grid } from '@mui/material'

import DisplayLabel from '../../shared/DisplayLabel/DisplayLabel'
import i18n from '../../../i18n'
import { extractSurveyQuestions } from '../../../util/survey.helpers'
import RichText from '../../shared/text/RichText'
import { SURVEY_QUESTION_EXCHANGE } from '../../../constants/survey'
import { CURRENCY_SYMBOL } from '../../../constants/app'

const RewardConditions = ({
  exchangeTerms,
  brandSurvey,
  isActiveExchange,
  ...restProps
}) => {
  const surveyQuestions = extractSurveyQuestions(
    exchangeTerms,
    i18n.language
  )?.questions
  return (
    <Grid container spacing={2} {...restProps}>
      {surveyQuestions?.map(({ id, title, description }, index) => {
        let desc = description
        if (isActiveExchange) {
          if (
            id === SURVEY_QUESTION_EXCHANGE.TERMS &&
            brandSurvey &&
            brandSurvey?.response?.[id]
          ) {
            desc = brandSurvey?.response?.[id]?.description
          } else if (
            id === SURVEY_QUESTION_EXCHANGE.EXCHANGE_VOLUME &&
            brandSurvey &&
            brandSurvey?.response?.[id]
          ) {
            desc = `${CURRENCY_SYMBOL.USD} ${brandSurvey?.response?.[id]?.selected}`
          } else if (id === SURVEY_QUESTION_EXCHANGE.MARKETING) {
            desc = `${desc} ${brandSurvey?.response?.[id]?.selected || ''}`
          }
        }
        return (
          <Grid item xs={12} key={index}>
            <DisplayLabel
              label={title}
              value={desc && <RichText content={desc} />}
              noMaxHeight
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default memo(RewardConditions)
