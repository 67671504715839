import React, { memo } from 'react'
import { faCoin, faSliders } from '@fortawesome/pro-light-svg-icons'

import {
  isSetupLoyaltyPoint,
  isDashboardStatus
} from '../../util/dashboard.helpers'
import ActionCard from './ActionCard'
import { LOYALTY_POINT_URL, SETUP_URL } from '../../constants/navigation'
import { QB_SETUP_COMPLETE_POINTS } from '../../constants/app'
import GetRewardPoints from './GetRewardPoints'
import MilestoneProgress from '../shared/ProgressBar/MilestoneProgress'
import ConfettiAnimation from '../shared/ConfettiAnimation/ConfettiAnimation'
import { MILESTONE_ANIMATION_KEYS } from '../../constants/dashboard'

const InitialSetup = ({
  dashboardSetupState,
  t,
  isBrandCreated,
  isTokenCreated,
  isDashboardSetupReady,
  milestoneAnimationStatus,
  onCompleteMilestoneAnimation
}) => {
  const buttonLabel = isDashboardSetupReady
    ? t('sidebar.loyalty-point')
    : t('home.continue-setup')
  const to = isDashboardSetupReady ? LOYALTY_POINT_URL : SETUP_URL
  const { brand, loyaltyPoint, status, packageBilling } = buildSetupStatus(
    isBrandCreated,
    isTokenCreated && !isSetupLoyaltyPoint(dashboardSetupState),
    isDashboardSetupReady,
    isDashboardStatus(dashboardSetupState)
  )
  const progressData = [
    { title: 'setup.brand-details', completed: brand },
    { title: 'token-setup.loyalty-point-details', completed: loyaltyPoint },
    { title: 'setup.subscription-package', completed: packageBilling },
    { title: 'setup.status-verification', completed: status }
  ]
  const animationId = MILESTONE_ANIMATION_KEYS.DASHBOARD_SETUP
  return (
    <ActionCard
      title={t('home.initial-setup')}
      description={
        <>
          {t('home.initial-setup-desc')}
          <GetRewardPoints points={QB_SETUP_COMPLETE_POINTS} />
        </>
      }
      buttonLabel={buttonLabel}
      to={to}
      icon={isDashboardSetupReady ? faCoin : faSliders}
      noGapBeforeAction
      actionProps={{ mt: 1.5 }}
      isShowGuidance={!isDashboardSetupReady}
    >
      {progressData.map(({ title, completed }, index) => (
        <MilestoneProgress
          key={index}
          title={t(title)}
          description={t('setup.setup-step-label', {
            step: index + 1
          })}
          completed={completed}
        />
      ))}
      {isDashboardSetupReady && !milestoneAnimationStatus?.[animationId] && (
        <ConfettiAnimation
          id={animationId}
          onComplete={() => onCompleteMilestoneAnimation(animationId)}
        />
      )}
    </ActionCard>
  )
}

export default memo(InitialSetup)

const SETUP_STEP_STATUS = {
  OPEN: 0,
  IN_PROGRESS: 50,
  DONE: 100
}

const buildSetupStatus = (
  isBrandCreated,
  isTokenCreated,
  isDashboardSetupReady,
  isDashboardSetupStatus
) => {
  return {
    brand: isBrandCreated
      ? SETUP_STEP_STATUS.DONE
      : SETUP_STEP_STATUS.IN_PROGRESS,
    loyaltyPoint: isTokenCreated
      ? SETUP_STEP_STATUS.DONE
      : isBrandCreated
      ? SETUP_STEP_STATUS.IN_PROGRESS
      : SETUP_STEP_STATUS.OPEN,
    packageBilling:
      (isBrandCreated && isTokenCreated && isDashboardSetupStatus) ||
      isDashboardSetupReady
        ? SETUP_STEP_STATUS.DONE
        : isBrandCreated &&
          isTokenCreated &&
          isDashboardSetupStatus &&
          !isDashboardSetupReady
        ? SETUP_STEP_STATUS.IN_PROGRESS
        : SETUP_STEP_STATUS.OPEN,
    status: isDashboardSetupReady
      ? SETUP_STEP_STATUS.DONE
      : isTokenCreated && isDashboardSetupStatus && !isDashboardSetupReady
      ? SETUP_STEP_STATUS.IN_PROGRESS
      : SETUP_STEP_STATUS.OPEN
  }
}
