import React, { memo } from 'react'
import { Box } from '@mui/material'

import { LOG_IN_URL } from '../../constants/navigation'
import { RESET_PASSWORD_PROFILE_DISPATCH } from '../../store/reducers/auth/resetPassword'
import Input from '../shared/inputs/Input'
import CustomForm from '../shared/forms/customForm/CustomForm'
import CustomButton from '../shared/buttons/CustomButton'
import PageContainer from '../shared/PageContainer/PageContainer'
import UnauthorizedLayoutContainer from '../../containers/UnauthorizedLayoutContainer'
import { QB_INPUT_PASSWORD, QB_ACTION_SUBMIT } from '../../constants/input'
import { transWithArgs } from '../../util/validation.helpers'

const ResetPassword = ({
  resetPasswordProfile: { error, password, resetSuccess, isProcessing },
  dispatchAction,
  onSubmitResetPassword,
  t
}) => {
  const title = t('reset-password.reset-your-password')
  return (
    <PageContainer title={title}>
      <UnauthorizedLayoutContainer title={title}>
        <Box
          sx={{
            mt: 4
          }}
        >
          <CustomForm onFormSubmit={onSubmitResetPassword}>
            <Input
              id={RESET_PASSWORD_PROFILE_DISPATCH.NEW_PASSWORD}
              label={t('reset-password.enter-your-new-password')}
              errorMessage={transWithArgs(
                t,
                error[RESET_PASSWORD_PROFILE_DISPATCH.NEW_PASSWORD]
              )}
              value={password}
              onChange={(value) =>
                dispatchAction(
                  RESET_PASSWORD_PROFILE_DISPATCH.NEW_PASSWORD,
                  value
                )
              }
              type={QB_INPUT_PASSWORD}
              successMsg={
                resetSuccess && t('reset-password.reset-password-success')
              }
            />

            <CustomButton
              sx={{ mt: 4 }}
              type={QB_ACTION_SUBMIT}
              label={
                resetSuccess
                  ? t('reset-password.go-to-login')
                  : t('reset-password.reset')
              }
              size='large'
              disabled={isProcessing}
            />
            <CustomButton
              sx={{ mt: 2 }}
              label={t('common.back-to-login')}
              to={LOG_IN_URL}
              variant=''
              size='large'
            />
          </CustomForm>
        </Box>
      </UnauthorizedLayoutContainer>
    </PageContainer>
  )
}

export default memo(ResetPassword)
