import React, { memo } from 'react'
import { Box, TableRow } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { faUserPlus, faUserMinus } from '@fortawesome/pro-light-svg-icons'

import DashboardCard from '../shared/DashboardCard/DashboardCard'
import DataTable from '../shared/DataTable/DataTable'
import TableCellData from '../shared/DataTable/TableCellData'
import {
  TEAM_MEMBERS_CONTROL_LEVEL,
  TEAM_MEMBERS_STATUS
} from '../../constants/table'
import CustomButton from '../shared/buttons/CustomButton'
import FaIcon from '../shared/FaIcon/FaIcon'
import { isMyEmail } from '../../util/admin.helpers'
import { CSS_FLEX_ALIGNED, CSS_FLEX_COLUMN } from '../../styles/common'
import UserInitials from '../shared/UserInitials/UserInitials'
import Text from '../shared/text/Text'
import StatusLabel from '../shared/StatusLabel/StatusLabel'
import { COLOR_PALETTE } from '../../constants/theme'

const TeamMembersData = ({
  t,
  teamMembersTableProps: {
    teamMembersHeaders,
    teamMembersProfile: { teamMembers, pagination },
    onInviteTeamMember,
    onRemoveTeamMember,
    userEmail
  }
}) => {
  const theme = useTheme()
  const txTableData = teamMembers.map((item, index) => (
    <DataTableRow
      key={index}
      {...item}
      theme={theme}
      onClickMember={() => onRemoveTeamMember(item)}
      userEmail={userEmail}
      t={t}
    />
  ))
  return (
    <DashboardCard
      title={t('admin.team-members')}
      sx={{ p: 0, boxShadow: 'none', borderRadius: 0 }}
      contentProps={{ p: 0 }}
      headerProps={{ pl: 0, pr: 0 }}
      action={
        <CustomButton
          label={t('admin.invite-team-member')}
          onClick={onInviteTeamMember}
          startIcon={<FaIcon icon={faUserPlus} width={16} />}
          variant='contained'
          color='primary'
          fullWidth={false}
          noPadding
          sx={{ lineHeight: 2 }}
        />
      }
      noPadding
    >
      <DataTable
        headers={teamMembersHeaders}
        data={txTableData}
        pagination={pagination}
        sx={{
          whiteSpace: {
            xs: 'nowrap',
            sm: 'unset'
          }
        }}
      />
    </DashboardCard>
  )
}

export default memo(TeamMembersData)

const DataTableRow = ({
  user: { firstName, secondName, email },
  onClickMember,
  userEmail,
  theme: { palette }
}) => {
  return (
    <TableRow>
      <TableCellData>
        <Box sx={CSS_FLEX_ALIGNED}>
          <UserInitials firstName={firstName} lastName={secondName} />
          <Box sx={{ pl: 2, ...CSS_FLEX_COLUMN }}>
            <Box sx={{ color: '', fontWeight: 500 }}>
              {firstName} {secondName}
            </Box>
            <Text label={email} variant='h6' color='textSecondary' />
          </Box>
        </Box>
      </TableCellData>
      <TableCellData noTag>
        <StatusLabel
          label={TEAM_MEMBERS_STATUS.ACTIVE}
          color={COLOR_PALETTE.SUCCESS}
          size={10}
        />
      </TableCellData>
      <TableCellData textProps={{ color: 'textSecondary' }}>
        {TEAM_MEMBERS_CONTROL_LEVEL.ADMIN}
        {/*{toUpperFirstLetter(role)} @TODO remove hardcoded role*/}
      </TableCellData>
      <TableCellData>
        {!isMyEmail(email, userEmail) && (
          <CustomButton
            color='grey'
            fullWidth={false}
            label={
              <FaIcon
                icon={faUserMinus}
                size='lg'
                color={palette.text.secondary}
              />
            }
            onClick={onClickMember}
          />
        )}
      </TableCellData>
    </TableRow>
  )
}
