import React, { memo } from 'react'

import FaIcon from '../FaIcon/FaIcon'
import {
  transWithArgs,
  validNaturalNumberRule
} from '../../../util/validation.helpers'
import { CREDIT_DEBIT_POINTS_MAX_LENGTH } from '../../../constants/validation'
import Input from './Input'

const AmountOfPoints = ({
  t,
  dispatchAction,
  icon,
  color,
  value,
  id,
  error,
  success,
  ...restProps
}) => {
  return (
    <Input
      id={id}
      label={t('member.amount-of-points')}
      value={value}
      errorMessage={transWithArgs(t, error[id])}
      successMsg={success && transWithArgs(t, success[id])}
      onChange={(value) => dispatchAction(id, value)}
      validate={[validNaturalNumberRule]}
      inputProps={{
        maxLength: CREDIT_DEBIT_POINTS_MAX_LENGTH
      }}
      size='small'
      startIcon={<FaIcon icon={icon} color={color} />}
      {...restProps}
    />
  )
}

export default memo(AmountOfPoints)
