import React, { memo } from 'react'
import { Grid } from '@mui/material'

import { buildPricingPlans } from '../../../data/pricing'
import PricingCard from './PricingCard'

const PackageCards = ({
  onSelectBillingPackage,
  billingProfile: { isYearly },
  billingPackageActions,
  isActionDisabled,
  t
}) => {
  return (
    <Grid container spacing={4}>
      {buildPricingPlans().map((pricing) => (
        <Grid item xs={12} lg={3} sm={6} key={pricing.name}>
          <PricingCard
            {...pricing}
            isYearly={isYearly}
            billingPackageAction={billingPackageActions?.[pricing.name]}
            onSelectPlan={() => onSelectBillingPackage(pricing)}
            isActionDisabled={isActionDisabled}
            t={t}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default memo(PackageCards)
