import React, { memo } from 'react'
import { Grid } from '@mui/material'
import {
  faHandshake,
  faUserCircle,
  faCoins,
  faSignIn,
  faUserGroupCrown
} from '@fortawesome/pro-light-svg-icons'

import DialogWithoutCard from '../../shared/CustomDialog/DialogWithoutCard'
import FaIcon from '../../shared/FaIcon/FaIcon'
import WelcomeCard from '../../welcome/WelcomeCard'

const Welcome = ({ onGetStartedClick, t }) => {
  const cards = [
    {
      title: 'welcome.set-up-your-account',
      icon: faUserCircle,
      text: {
        i18nKey: 'welcome.set-up-your-account-desc',
        bold1: 'welcome.create-your-account',
        bold2: 'welcome.join-the-loyalty-ecosystem'
      }
    },
    {
      title: 'welcome.join-the-marketplace',
      icon: faHandshake,
      text: {
        i18nKey: 'welcome.join-the-marketplace-desc',
        bold1: 'welcome.setup-your-loyalty-point',
        bold2: 'welcome.b2b-rewards-marketplace'
      }
    },
    {
      title: 'welcome.buy-and-sell-rewards',
      icon: faCoins,
      text: {
        i18nKey: 'welcome.buy-and-sell-rewards-desc',
        bold1: 'welcome.leverage-loyalty-ecosystem',
        bold2: 'welcome.integrate-new-partnerships'
      }
    },
    {
      title: 'welcome.treat-your-vips-right',
      icon: faUserGroupCrown,
      text: {
        i18nKey: 'welcome.treat-your-vips-right-desc',
        bold1: 'welcome.get-insights',
        bold2: 'welcome.maximize-lifetime-value'
      }
    }
  ]

  return (
    <DialogWithoutCard
      body={
        <Grid container spacing={4}>
          {cards.map((card, index) => {
            return (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <WelcomeCard index={index} card={card} t={t} />
              </Grid>
            )
          })}
        </Grid>
      }
      actionProps={{
        label: t('welcome.get-started-now'),
        startIcon: <FaIcon icon={faSignIn} />
      }}
      onClickAction={onGetStartedClick}
    />
  )
}

export default memo(Welcome)
