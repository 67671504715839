import { BILLING_ACTION } from '../../constants/actions'

const changeBillingState = (property, value) => ({
  type: BILLING_ACTION.ON_CHANGE_STATE,
  payload: {
    property,
    value
  }
})

const generateCheckoutSession = (tokenId, priceId) => ({
  type: BILLING_ACTION.ON_GENERATE_CHECKOUT_SESSION,
  payload: {
    tokenId,
    priceId
  }
})

const generateViewBillingSession = (tokenId) => ({
  type: BILLING_ACTION.ON_GENERATE_VIEW_BILLING_SESSION,
  payload: {
    tokenId
  }
})

const getExchangePerformance = () => ({
  type: BILLING_ACTION.ON_GET_EXCHANGE_PERFORMANCE
})

const startCheckout = () => ({
  type: BILLING_ACTION.ON_START_CHECKOUT
})

const reactivateSubscription = () => ({
  type: BILLING_ACTION.ON_REACTIVATE_SUBSCRIPTION
})

export {
  changeBillingState,
  generateCheckoutSession,
  generateViewBillingSession,
  getExchangePerformance,
  startCheckout,
  reactivateSubscription
}
