import React, { memo } from 'react'
import { Helmet } from 'react-helmet'

import { APP_TITLE } from '../../../constants/app'

const PageContainer = ({ title, description, children }) => {
  return (
    <div>
      <Helmet>
        <title>
          {APP_TITLE} - {title}
        </title>
        <meta name='description' content={description} />
      </Helmet>
      {children}
    </div>
  )
}

export default memo(PageContainer)
