import React, { memo } from 'react'
import { Box, Divider, Grid, useTheme } from '@mui/material'
import { faCoin } from '@fortawesome/pro-light-svg-icons'

import CustomDialog from '../../shared/CustomDialog/CustomDialog'
import AmountOfPoints from '../../shared/inputs/AmountOfPoints'
import CustomButton from '../../shared/buttons/CustomButton'
import Dropdown from '../../shared/inputs/Dropdown'
import { MEMBER_TRANSACTION_PROFILE_DISPATCH } from '../../../store/reducers/members/memberTransaction'
import { QB_ACTION_SUBMIT } from '../../../constants/input'
import CustomForm from '../../shared/forms/customForm/CustomForm'
import { buildDropdownList } from '../../../util/app/app.helpers'
import { MEMBER_POINTS_TRANSACTION_TYPES } from '../../../constants/members'
import FaIcon from '../../shared/FaIcon/FaIcon'
import UserInitials from '../../shared/UserInitials/UserInitials'
import TitleCaptionText from '../../shared/text/TitleCaptionText'
import { formatToShortDate } from '../../../util/date.helpers'
import MemberRecentTransactions from '../../analytics/MemberRecentTransactions'
import { formatTicks } from '../../../util/string.helpers'
import Text from '../../shared/text/Text'
import { buildMemberTransactionTypeIconColor } from '../../../util/transaction/transaction.helpers'

const MemberMoreActions = ({
  membersProfile,
  memberTransactionProfile: {
    type,
    amount,
    error,
    success,
    isProcessing,
    pointsBalance,
    selectedMember: { firstName, secondName, email, authId, insertedAt }
  },
  tokenProfile,
  brandProfile,
  onSubmit,
  t,
  onClose,
  dispatchAction,
  isPointsBank
}) => {
  const { symbol } = tokenProfile
  const theme = useTheme()

  const { icon, color } = buildMemberTransactionTypeIconColor(type)
  return (
    <CustomDialog
      open
      onClose={onClose}
      maxWidth='md'
      body={
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box display='flex' alignItems='center'>
                <UserInitials
                  firstName={firstName}
                  lastName={secondName}
                  size={40}
                  sx={{ mr: 2 }}
                />
                <TitleCaptionText
                  title={`${firstName} ${secondName}`}
                  caption={email}
                  titleStyles={{ whiteSpace: 'normal' }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TitleCaptionText
                title={`${t('member.auth-id')}: ${authId}`}
                caption={`${t('member.created-on')}: ${formatToShortDate(
                  insertedAt
                )}`}
                sx={{ textAlign: 'right' }}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 1, mb: 1 }} />
          <MemberRecentTransactions
            t={t}
            membersProfile={membersProfile}
            theme={theme}
            tokenProfile={tokenProfile}
            currentBrandId={brandProfile.id}
            showTxnForExchange={!isPointsBank}
          />
          {isPointsBank ? (
            <>
              <Box sx={{ mt: 2, mb: 1 }}>
                <Text
                  variant='h4'
                  fontWeight={500}
                  label={t('member.their-current-points-balance')}
                />

                <Text
                  variant='h2'
                  fontWeight={700}
                  label={`${formatTicks(pointsBalance)} ${symbol}`}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Text
                  variant='h4'
                  fontWeight={500}
                  label={t('member.credit-and-debit-points')}
                />
                <Text
                  variant='caption'
                  component='div'
                  label={t('member.credit-debit-points-dec')}
                />
              </Box>
              <CustomForm onFormSubmit={onSubmit}>
                <Grid container spacing={2}>
                  <Grid item md={3} sm={4} xs={12}>
                    <Dropdown
                      id={MEMBER_TRANSACTION_PROFILE_DISPATCH.TYPE}
                      label={t('member.credit-debit-points')}
                      options={buildDropdownList(
                        MEMBER_POINTS_TRANSACTION_TYPES,
                        'member.transaction-filters',
                        t
                      )}
                      onChange={({ target }) =>
                        dispatchAction(
                          MEMBER_TRANSACTION_PROFILE_DISPATCH.TYPE,
                          target.value
                        )
                      }
                      value={type}
                      size='small'
                    />
                  </Grid>
                  <Grid item md={6} sm={4} xs={12}>
                    <AmountOfPoints
                      t={t}
                      id={MEMBER_TRANSACTION_PROFILE_DISPATCH.AMOUNT}
                      value={amount}
                      error={error}
                      success={success}
                      dispatchAction={dispatchAction}
                      icon={icon}
                      color={theme.palette[color].main}
                    />
                  </Grid>
                  <Grid item md={3} sm={4} xs={12}>
                    <CustomButton
                      type={QB_ACTION_SUBMIT}
                      startIcon={<FaIcon icon={faCoin} />}
                      label={t(`member.transaction-filters.${type}`)}
                      disabled={
                        isProcessing || membersProfile.recentTransactionsLoading
                      }
                      sx={{ mt: 4.5 }}
                    />
                  </Grid>
                </Grid>
              </CustomForm>
            </>
          ) : (
            <Text
              variant='body2'
              label={t('analytics.exchange-analytics-transactions-note')}
              sx={{ mt: 2 }}
            />
          )}
        </>
      }
      t={t}
    />
  )
}

export default memo(MemberMoreActions)
