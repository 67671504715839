import { COLOR_PALETTE } from './theme'

export const TRANSACTION_TYPES = {
  ALL: 'all',
  EXCHANGE: 'exchange',
  REWARD: 'reward',
  REDEEM: 'redeem'
}

export const EXCHANGE_TRANSACTION_TYPES = {
  ALL: 'all',
  BUY: 'buy',
  SELL: 'sell'
}

export const ADDITIONAL_TXN_TYPES = {
  TRANSFER: 'transfer'
}

export const TRANSACTION_STATUS_LABEL = {
  REVERSED: 'reversed',
  FUTURE: 'future'
}

export const TRANSACTION_TYPE_COLORS = {
  [TRANSACTION_TYPES.EXCHANGE]: COLOR_PALETTE.PRIMARY,
  [ADDITIONAL_TXN_TYPES.TRANSFER]: COLOR_PALETTE.PRIMARY,
  [TRANSACTION_TYPES.REWARD]: COLOR_PALETTE.INFO,
  [TRANSACTION_TYPES.REDEEM]: COLOR_PALETTE.SECONDARY,
  [TRANSACTION_STATUS_LABEL.FUTURE]: COLOR_PALETTE.ORANGE
}

export const EXCHANGE_TRANSACTION_TYPE_COLORS = {
  [EXCHANGE_TRANSACTION_TYPES.BUY]: COLOR_PALETTE.PRIMARY,
  [EXCHANGE_TRANSACTION_TYPES.SELL]: COLOR_PALETTE.SECONDARY,
  [TRANSACTION_STATUS_LABEL.FUTURE]: COLOR_PALETTE.ORANGE
}

export const TRANSACTION_STATUS = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILED: 'failed',
  CANCELLED: 'cancelled',
  REVERTED: 'reverted'
}

export const FUTURE_TRANSACTION_STATUS = {
  SUCCESS: 'success',
  REVERSE: 'reverse'
}

export const LOYALTY_EVENT_TYPES = {
  CODE_TO_POINTS: 'code_to_points',
  CODE_TO_NFT: 'code_to_nft',
  CREDIT_POINTS: 'credit_points',
  DEBIT_POINTS: 'debit_points',
  POINTS_TO_REWARDS: 'points_to_rewards',
  ON_DEMAND_CODE_CREATION: 'on_demand_code_creation',
  SIGNUP_BONUS: 'signup_bonus',
  EXCHANGE: 'exchange',
  REVERT_EXCHANGE: 'revert_exchange',
  CREDIT_NFT: 'credit_nft',
  NFT_TRANSFER: 'nft_transfer',
  NFT_SALE: 'nft_sale'
}

export const ADDITIONAL_TXN_EVENT_TYPE = {
  POINTS_TO_NFT: 'points_to_nft',
  BUY_EXCHANGE: 'buy_exchange',
  SELL_EXCHANGE: 'sell_exchange'
}
