import { Box } from '@mui/material'
import React, { memo } from 'react'

import FormActionsBox from '../BaseForm/FormActionsBox'
import CustomButton from '../buttons/CustomButton'
import DefaultText from '../text/DefaultText'

const SetupRequiredAction = ({ submitLabel, description, onClickSetup }) => {
  return (
    <>
      <DefaultText label={description} component='div' />
      <FormActionsBox>
        <Box sx={{ flex: '1 1 auto' }} />
        <CustomButton
          variant='contained'
          fullWidth={false}
          label={submitLabel}
          onClick={onClickSetup}
        />
      </FormActionsBox>
    </>
  )
}

export default memo(SetupRequiredAction)
