import React, { memo } from 'react'
import { Grid } from '@mui/material'

import BaseCard from '../shared/BaseCard/BaseCard'
import { displayCurrencyValue } from '../../util/number.helpers'
import StatCard from '../shared/Analytics/StatCard'

const TransactionsUSDCards = ({
  data,
  colorRefMethod,
  borderDirection,
  gridSize,
  flexDirection = 'row'
}) => {
  return (
    <BaseCard sx={{ p: 0 }}>
      <Grid
        container
        spacing={0}
        height='100%'
        display='flex'
        flexDirection={flexDirection}
        flexWrap='noWrap'
      >
        {data.map(({ type, value, ...restProps }) => (
          <StatCard
            key={type}
            header={displayCurrencyValue(value)}
            color={colorRefMethod(type)}
            borderDirection={borderDirection}
            gridSize={gridSize}
            {...restProps}
          />
        ))}
      </Grid>
    </BaseCard>
  )
}

export default memo(TransactionsUSDCards)
