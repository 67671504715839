export const AUTH_FIRST_NAME_ATTR = 'given_name'
export const AUTH_LAST_NAME_ATTR = 'family_name'
export const AUTH_TERMS_ACCEPTED_ATTR = 'custom:termsAccepted'

export const QB_AUTH_STATE = {
  NOT_AUTHENTICATED: 0,
  CHECKING: 1,
  AUTHENTICATED: 2
}

export const QB_AUTH_LANGUAGE_ATTR = 'custom:language'
