import React, { memo } from 'react'
import { faCheck, faXmark } from '@fortawesome/pro-light-svg-icons'

import CustomDialog from '../../shared/CustomDialog/CustomDialog'
import FaIcon from '../../shared/FaIcon/FaIcon'
import RewardConditions from '../exchange/RewardConditions'
import { extractSurveyQuestions } from '../../../util/survey.helpers'
import i18n from '../../../i18n'
import { QB_LABEL_TEXT } from '../../../constants/messages'

const ExtensionPartnerDetails = ({
  t,
  onSubmit,
  onClose,
  isProcessing,
  exchangeTerms,
  brandSurvey,
  activate
}) => {
  const survey = extractSurveyQuestions(exchangeTerms, i18n.language)

  const submitProps = activate
    ? {
        label: t('extensions.activate'),
        startIcon: <FaIcon icon={faCheck} />
      }
    : {
        label: t('extensions.deactivate'),
        color: 'error',
        variant: 'outlined',
        startIcon: <FaIcon icon={faXmark} />
      }
  return (
    <CustomDialog
      open
      title={survey?.title}
      onClose={onClose}
      onSubmit={onSubmit}
      submitProps={{
        ...submitProps,
        disabled: isProcessing
      }}
      cancelProps={activate && { label: t(QB_LABEL_TEXT.CANCEL) }}
      t={t}
      body={
        <RewardConditions
          exchangeTerms={exchangeTerms}
          brandSurvey={brandSurvey}
          isActiveExchange
        />
      }
    />
  )
}

export default memo(ExtensionPartnerDetails)
