import { mergeToNewObject } from '../../../util/object.helpers'
import { REWARD_MEMBER_ACTION } from '../../../constants/actions'
import {
  ANALYTICS_PROCESS_STEP,
  DEFAULT_CONTAINER_STATE
} from '../../../constants/containerStates'
import { buildErrorMessage } from '../../../util/reducer.helpers'
import { formatValidator } from '../../../util/validation.helpers'
import { REWARD_EXCHANGE_MEMBER_ERRORS } from '../../../constants/apiErrors'
import { ERROR_TEXT } from '../../../constants/messages'
import { maxExchangeValue } from '../../../util/app/app.helpers'

export const rewardMemberProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case REWARD_MEMBER_ACTION.ON_CREDIT_POINTS_BANK:
    case REWARD_MEMBER_ACTION.ON_CREDIT_POINTS_EXCHANGE:
      return mergeToNewObject(state, {
        isProcessing: true,
        popupState: DEFAULT_CONTAINER_STATE.NONE
      })

    case REWARD_MEMBER_ACTION.ON_CHANGE_STATE:
      return mergeToNewObject(state, {
        [payload.property]: payload.value,
        ...resetErrors(payload, state)
      })

    case REWARD_MEMBER_ACTION.ON_CREDIT_POINTS_BANK_SUCCESS:
    case REWARD_MEMBER_ACTION.ON_CREDIT_POINTS_EXCHANGE_SUCCESS:
      return mergeToNewObject(state, {
        isProcessing: false,
        popupState: ANALYTICS_PROCESS_STEP.REWARDED_MEMBER_SUCCESS
      })

    case REWARD_MEMBER_ACTION.ON_CREDIT_POINTS_BANK_FAILED:
      return mergeToNewObject(state, {
        error: { amount: buildErrorMessage(payload) },
        isProcessing: false,
        popupState: ANALYTICS_PROCESS_STEP.REWARD_POINTS_BANK_MEMBER
      })

    case REWARD_MEMBER_ACTION.ON_CREDIT_POINTS_EXCHANGE_FAILED:
      return mergeToNewObject(state, {
        error: handleRewardExchangeErrors(payload),
        isProcessing: false,
        popupState: ANALYTICS_PROCESS_STEP.REWARD_EXCHANGE_MEMBER
      })

    case REWARD_MEMBER_ACTION.ON_RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

export const REWARD_MEMBER_DISPATCH = {
  AMOUNT: 'amount',
  ACCOUNT: 'account',
  LOYALTY_POINT: 'loyaltyPoint',
  ERROR: 'error'
}

const INITIAL_STATE = {
  amount: '',
  account: '',
  loyaltyPoint: '',
  popupState: DEFAULT_CONTAINER_STATE.NONE,
  isProcessing: false,
  error: {}
}

const handleRewardExchangeErrors = (payload) => {
  const errorMessage = payload?.errors?.detail

  switch (errorMessage) {
    case REWARD_EXCHANGE_MEMBER_ERRORS.USER_TO_EXCHANGE_NOT_EXISTS:
      return {
        account: formatValidator(ERROR_TEXT.INVALID_ACCOUNT_NUMBER)
      }
    case REWARD_EXCHANGE_MEMBER_ERRORS.AMOUNT_IS_NOT_SUFFICIENT:
      return {
        amount: formatValidator(ERROR_TEXT.INSUFFICIENT_AMOUNT_TO_REWARD)
      }
    case REWARD_EXCHANGE_MEMBER_ERRORS.MAXIMUM_ALLOWED_LIMIT_EXCEEDED:
      return {
        amount: formatValidator(ERROR_TEXT.EXCEED_MAX_LIMIT, {
          value: maxExchangeValue()
        })
      }
    default:
      return {
        account: buildErrorMessage(payload)
      }
  }
}

const resetErrors = ({ property }, state) => {
  if (property === REWARD_MEMBER_DISPATCH.LOYALTY_POINT) {
    return {
      error: {}
    }
  } else if (property !== REWARD_MEMBER_DISPATCH.ERROR) {
    return {
      error: {
        ...state.error,
        [property]: ''
      }
    }
  }
  return {}
}
