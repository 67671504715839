import { mergeToNewObject } from '../../../util/object.helpers'
import {
  DASHBOARD_ACTIONS,
  MAIN_PROFILE_ACTION,
  BRAND_ACTIONS,
  TOKEN_ACTIONS,
  BILLING_ACTION
} from '../../../constants/actions'
import {
  isTokenPending,
  isTokenRejected,
  extractBrandTokensFromMainData,
  isTokenActive
} from '../../../util/token.helpers'
import { selectSubscribedPackage } from '../../../util/billing.helpers'

export const dashboardSetupProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case DASHBOARD_ACTIONS.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS:
      return mergeToNewObject(state, getCorrectStateBasedOnData(payload))

    case TOKEN_ACTIONS.ON_GET_TOKENS_SUCCESS:
      return mergeToNewObject(state, getCorrectStateBasedOnTokensData(payload))

    case DASHBOARD_ACTIONS.ON_CHANGE_DASHBOARD_SETUP_STATE:
      return mergeToNewObject(state, { dashboardSetupState: payload.value })

    case BRAND_ACTIONS.ON_CREATE_BRAND_SUCCESS:
    case BRAND_ACTIONS.ON_UPDATE_BRAND_SUCCESS:
    case TOKEN_ACTIONS.ON_CREATE_TOKEN_FAILED:
      return mergeToNewObject(
        state,
        state.isDashboardSetup
          ? {
              dashboardSetupState: DASHBOARD_SETUP_STATE.LOYALTY_POINT
            }
          : {}
      )

    case TOKEN_ACTIONS.ON_UPDATE_TOKEN_SUCCESS:
      return mergeToNewObject(
        state,
        state.isDashboardSetup
          ? {
              dashboardSetupState: DASHBOARD_SETUP_STATE.PACKAGE
            }
          : {}
      )

    case BRAND_ACTIONS.ON_CREATE_BRAND_FAILED:
    case BRAND_ACTIONS.ON_UPDATE_BRAND_FAILED:
      return mergeToNewObject(state, {
        dashboardSetupState: DASHBOARD_SETUP_STATE.BRAND
      })

    case TOKEN_ACTIONS.ON_CREATE_TOKEN_SUCCESS:
      const { isPaidSubscription } = payload
      return mergeToNewObject(state, {
        dashboardSetupState: isPaidSubscription
          ? DASHBOARD_SETUP_STATE.STATUS
          : DASHBOARD_SETUP_STATE.PACKAGE
      })

    case BILLING_ACTION.ON_UPDATE_PACKAGE_SUBSCRIPTION_SUCCESS:
      return mergeToNewObject(state, {
        dashboardSetupState: DASHBOARD_SETUP_STATE.STATUS
      })

    case DASHBOARD_ACTIONS.ON_MILESTONE_ANIMATION_COMPLETE_SUCCESS:
      return mergeToNewObject(state, {
        milestoneAnimationStatus: payload
      })

    case MAIN_PROFILE_ACTION.ON_NEW_BRAND_CREATION:
      return mergeToNewObject(state, {
        dashboardSetupState: DASHBOARD_SETUP_STATE.BRAND
      })

    case TOKEN_ACTIONS.ON_GET_TOKEN_BY_ID_SUCCESS:
      return mergeToNewObject(state, getCorrectStateBasedOnTokenData(payload))

    case BILLING_ACTION.ON_START_CHECKOUT:
    case BILLING_ACTION.ON_REACTIVATE_SUBSCRIPTION:
      return mergeToNewObject(state, {
        dashboardSetupState: DASHBOARD_SETUP_STATE.STATUS
      })

    default:
      return state
  }
}

export const DASHBOARD_SETUP_STATE = {
  LOADING: 0,
  BRAND: 1,
  LOYALTY_POINT: 2,
  PACKAGE: 3,
  STATUS: 4,
  READY: 5
}

const INITIAL_STATE = {
  dashboardSetupState: DASHBOARD_SETUP_STATE.LOADING,
  isDashboardSetup: false,
  milestoneAnimationStatus: {}
}

export const DASHBOARD_SETUP_DISPATCH = {
  IS_DASHBOARD_SETUP: 'isDashboardSetup'
}

const getCorrectStateBasedOnData = (payload) => {
  const token = payload?.currentToken
  const subscribedPackage = selectSubscribedPackage(
    payload?.packageSubscriptions,
    token
  )
  if (subscribedPackage?.id) {
    if (isTokenPending(token.status) || isTokenRejected(token.status)) {
      return { dashboardSetupState: DASHBOARD_SETUP_STATE.STATUS }
    } else {
      return { dashboardSetupState: DASHBOARD_SETUP_STATE.READY }
    }
  }
  const tokens = extractBrandTokensFromMainData(
    payload,
    payload?.currentBrand?.id
  )
  if (tokens.length > 0) {
    return { dashboardSetupState: DASHBOARD_SETUP_STATE.PACKAGE }
  } else if (payload?.currentBrand?.id) {
    return { dashboardSetupState: DASHBOARD_SETUP_STATE.LOYALTY_POINT }
  }
  return { dashboardSetupState: DASHBOARD_SETUP_STATE.BRAND }
}

const getCorrectStateBasedOnTokensData = (payload) => {
  const token = payload?.currentToken
  const subscribedPackage = selectSubscribedPackage(
    payload?.packageSubscriptions,
    token
  )
  if (subscribedPackage?.id) {
    if (isTokenPending(token.status) || isTokenRejected(token.status)) {
      return { dashboardSetupState: DASHBOARD_SETUP_STATE.STATUS }
    } else {
      return { dashboardSetupState: DASHBOARD_SETUP_STATE.READY }
    }
  }
  if (token?.id) {
    return {
      dashboardSetupState: extractDashboardStateByTokenStatus(token)
    }
  } else if (payload?.brandId) {
    return { dashboardSetupState: DASHBOARD_SETUP_STATE.LOYALTY_POINT }
  }
  return { dashboardSetupState: DASHBOARD_SETUP_STATE.BRAND }
}

const getCorrectStateBasedOnTokenData = ({ token }) => {
  return isTokenActive(token.status)
    ? {
        dashboardSetupState: DASHBOARD_SETUP_STATE.READY
      }
    : {}
}

const extractDashboardStateByTokenStatus = (token) => {
  if (isTokenActive(token.status)) {
    return DASHBOARD_SETUP_STATE.READY
  } else if (isTokenPending(token.status)) {
    return DASHBOARD_SETUP_STATE.PACKAGE
  } else {
    return DASHBOARD_SETUP_STATE.STATUS
  }
}
