import { WELCOME_ACTION } from '../../../constants/actions'
import { mergeToNewObject } from '../../../util/object.helpers'

export const welcomeProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case WELCOME_ACTION.ON_GET_WELCOME_STATE_SUCCESS:
      return mergeToNewObject(state, { isWelcomeOpen: payload })

    case WELCOME_ACTION.ON_UPDATE_WELCOME_STATE_SUCCESS:
      return mergeToNewObject(state, { isWelcomeOpen: payload })

    default:
      return state
  }
}

const INITIAL_STATE = {
  isWelcomeOpen: false
}

export const WELCOME_PROFILE_DISPATCH = {
  IS_WELCOME_OPEN: 'isWelcomeOpen'
}
