import React, { memo } from 'react'

import Text from '../text/Text'
import DisplayLabelFormat from './DisplayLabelFormat'
import PopoverHelpText from '../PopoverHelpText/PopoverHelpText'

const DisplayLabel = ({
  label,
  value,
  helpText,
  valueProps,
  noMaxHeight,
  ...restProps
}) => {
  return (
    <DisplayLabelFormat
      label={
        <>
          {label}
          {helpText && <PopoverHelpText label={helpText} />}
        </>
      }
      value={
        <Text
          label={value}
          variant='caption'
          component='div'
          sx={{
            lineHeight: 1.3,
            ...(!noMaxHeight && { maxHeight: 96, overflow: 'auto' })
          }}
          {...valueProps}
        />
      }
      {...restProps}
    />
  )
}

export default memo(DisplayLabel)
