export const QB_DOCS_URL = 'https://docs.qiibee.com/'
export const TERMS_COND_URL = 'https://www.qiibee.com/terms-conditions/'
export const PRIVACY_POLICY_URL = 'https://www.qiibee.com/privacy-policy/'
export const HELP_CENTER_EXTERNAL_URL = 'https://qiibee.zendesk.com/hc/en-us'
export const LWA_SETUP_URL = 'https://cdbewjpd3gf.typeform.com/to/K7wyVREW'
export const LETS_TALK_BRAND_API_URL =
  'https://calendly.com/claudio-sgarbi/call-with-qiibee'
export const DASHBOARD_EMAIL = 'dashboard@qiibee.com'
export const QB_WEBSITE_DOMAIN = 'qiibee.com'
export const QB_WEBSITE_URL = `https://www.${QB_WEBSITE_DOMAIN}`
export const POINTS_EXCHANGE_API_URL = `${QB_DOCS_URL}api/qiibee-apis/points-exchange-api`
export const BILLING_FAQS_URL =
  'https://qiibee.zendesk.com/hc/en-us/sections/4416283500305-Packages-Billing'
export const YEARLY_MANUAL_BILLING_FORM_URL =
  'https://cdbewjpd3gf.typeform.com/to/nsVrmw6A'
export const PRODUCTION_URL = 'https://dashboard.qiibee.com/'
export const SANDBOX_URL = 'https://dashboardsandbox.qiibee.com/'
export const INTEGRATION_TYPES_URL = `${QB_DOCS_URL}api/integrations/light-vs.-full-integration`
export const LWA_API_URL = `${QB_DOCS_URL}api/qiibee-apis/lwa-integration-api`
export const API_URL = `${QB_DOCS_URL}api`
export const MARKDOWN_URL = 'https://www.markdownguide.org/cheat-sheet/'
export const SUCCESS_PLAYBOOK_URL =
  'https://docs.qiibee.com/api/introduction/customer-success-roadmap'

export const NAVIGATION_TARGET_BLANK = '_blank'

export const BUY_POINTS_HELP_CENTER =
  'https://qiibee.zendesk.com/hc/en-us/sections/11770959505053-Buy-Points'
export const CASHBACK_HELP_CENTER =
  'https://qiibee.zendesk.com/hc/en-us/sections/11771008869405-Cashback'

export const DYNAMIC_PAGE_REDIRECT_PATHS = {
  BILLING_SESSION: 'billing-session',
  CHECKOUT_SESSION: 'checkout-session'
}
