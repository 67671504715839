import { DASHBOARD_ACTIONS } from '../../constants/actions'

// DASHBOARD SETUP
const changeDashboardSetupState = (value) => {
  return {
    type: DASHBOARD_ACTIONS.ON_CHANGE_DASHBOARD_SETUP_STATE,
    payload: { value }
  }
}

const changeDashboardState = (property, value) => ({
  type: DASHBOARD_ACTIONS.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

// MILESTONE ANIMATION COMPLETE
const milestoneAnimationComplete = (status) => {
  return {
    type: DASHBOARD_ACTIONS.ON_MILESTONE_ANIMATION_COMPLETE,
    payload: status
  }
}

export {
  changeDashboardSetupState,
  changeDashboardState,
  milestoneAnimationComplete
}
