import { WELCOME_ACTION } from '../../constants/actions'

const getWelcomeState = () => ({
  type: WELCOME_ACTION.ON_GET_WELCOME_STATE
})

const setWelcomeState = (open) => ({
  type: WELCOME_ACTION.ON_UPDATE_WELCOME_STATE,
  payload: open
})

export { getWelcomeState, setWelcomeState }
