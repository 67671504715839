import React, { memo } from 'react'
import { faRankingStar } from '@fortawesome/free-solid-svg-icons'

import { isTokenTypeHasStaticUrl } from '../../util/token.helpers'
import { getStaticLogoPath } from '../../util/brand.helpers'
import BrandCard from '../shared/BrandCard/BrandCard'

const ExchangeBrandCard = ({
  name,
  brandConfig,
  size = 150,
  brandLogoSize,
  type,
  symbol,
  partner,
  onClick,
  cryptoNav,
  logoStyles,
  logoProps
}) => {
  const logoUrl = isTokenTypeHasStaticUrl(type)
    ? getStaticLogoPath(symbol)
    : brandConfig?.logoUrl
  return (
    <BrandCard
      logoUrl={logoUrl}
      brandName={name[0]}
      onClick={onClick}
      brandLogoSize={brandLogoSize}
      label={partner?.rank}
      icon={faRankingStar}
      size={size}
      cryptoNav={cryptoNav}
      logoStyles={logoStyles}
      logoProps={logoProps}
    />
  )
}

export default memo(ExchangeBrandCard)
