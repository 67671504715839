import React, { memo } from 'react'
import { faExchange } from '@fortawesome/pro-light-svg-icons'
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons'
import { useTheme } from '@mui/material'

import TitleWithPopover from '../shared/TitleWithPopover/TitleWithPopover'
import FaIcon from '../shared/FaIcon/FaIcon'
import CustomFab from '../shared/inputs/CustomFab'
import Text from '../shared/text/Text'
import ActionCard from '../dashboard/ActionCard'
import { buildExchangePotentialUrl } from '../../util/exchange.helpers'

const CallToAction = ({ weeksExchangeValuesDiff, t }) => {
  const theme = useTheme()

  const ctaValue =
    weeksExchangeValuesDiff > 0
      ? {
          color: theme.palette.success,
          icon: faCaretUp,
          title: t('analytics.cta-description-up')
        }
      : {
          color: theme.palette.danger,
          icon: faCaretDown,
          title: t('analytics.cta-description-down')
        }

  const color = ctaValue.color
  return (
    <ActionCard
      heading={
        <CustomFab
          sx={{
            backgroundColor: color.light,
            color: color.main,
            boxShadow: 'none',
            height: '45px',
            width: '100%',
            fontWeight: '500',
            borderRadius: '10px',
            flex: 1,
            '&:hover': {
              backgroundColor: color.light
            }
          }}
        >
          <FaIcon icon={ctaValue.icon} size='xl' />
          <Text
            sx={{ ml: '5px', fontWeight: '500' }}
            variant='h2'
            label={`${Math.ceil(weeksExchangeValuesDiff)}%`}
          />
        </CustomFab>
      }
      description={
        <TitleWithPopover
          variant='body1'
          sx={{
            mt: 2,
            mb: 2,
            flex: 2,
            paddingRight: '20px',
            paddingLeft: '20px'
          }}
          gutterBottom
          title={ctaValue.title}
          helpText={t('analytics.cta-help-text')}
          color='textprimary'
          fontWeight={400}
        />
      }
      buttonLabel={t('analytics.connect-to-partners')}
      to={buildExchangePotentialUrl()}
      icon={faExchange}
    />
  )
}

export default memo(CallToAction)
