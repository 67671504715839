import {
  TRANSACTION_ACTIONS,
  TRANSACTION_DOWNLOAD_ACTIONS,
  TXN_COLUMNS_ACTION,
  TXN_REFUND_MILES_ACTION,
  TXN_SEARCH_ACTIONS,
  TXN_STATUS_ACTION
} from '../../constants/actions'

const getTransactions = (payload) => {
  return {
    type: TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS,
    payload
  }
}

const getExchangeActivityChart = () => {
  return {
    type: TRANSACTION_ACTIONS.ON_GET_EXCHANGE_ACTIVITY_CHART
  }
}

const getTransactionsChartByFilter = () => {
  return {
    type: TRANSACTION_ACTIONS.ON_GET_TRANSACTIONS_CHART
  }
}

const getTransactionsForDownload = () => {
  return {
    type: TRANSACTION_DOWNLOAD_ACTIONS.ON_GET_TRANSACTIONS_FOR_DOWNLOAD
  }
}

const resetTransactionsDownloadState = () => {
  return {
    type: TRANSACTION_DOWNLOAD_ACTIONS.ON_RESET_STATE
  }
}

const resetTransactionsChartState = () => {
  return {
    type: TRANSACTION_ACTIONS.ON_RESET_CHART_STATE
  }
}

const resetTransactionsState = () => {
  return {
    type: TRANSACTION_ACTIONS.ON_RESET_STATE
  }
}

const getLiabilityTrendChartByFilter = () => {
  return {
    type: TRANSACTION_ACTIONS.ON_GET_LIABILITY_TREND_CHART
  }
}

const changeTxnStatusState = (property, value) => {
  return {
    type: TXN_STATUS_ACTION.ON_CHANGE_STATE,
    payload: {
      property,
      value
    }
  }
}

const resetTxnStatusState = () => {
  return {
    type: TXN_STATUS_ACTION.ON_RESET_STATE
  }
}

const txnStatusChangeSubmit = (transactionId) => {
  return {
    type: TXN_STATUS_ACTION.ON_CHANGE_STATUS,
    payload: { transactionId }
  }
}

const resetRefundMilesState = () => {
  return {
    type: TXN_REFUND_MILES_ACTION.ON_RESET_STATE
  }
}

const refundMiles = (transactionId) => {
  return {
    type: TXN_REFUND_MILES_ACTION.ON_REFUND,
    payload: { transactionId }
  }
}

const selectTxnColumn = (field) => {
  return {
    type: TXN_COLUMNS_ACTION.ON_SELECT,
    payload: { field }
  }
}

const changeTxnSearchState = (property, value) => {
  return {
    type: TXN_SEARCH_ACTIONS.ON_CHANGE_STATE,
    payload: {
      property,
      value
    }
  }
}

const resetTxnSearchState = () => {
  return {
    type: TXN_SEARCH_ACTIONS.ON_RESET_STATE
  }
}

export {
  getTransactions,
  getExchangeActivityChart,
  getTransactionsForDownload,
  resetTransactionsDownloadState,
  resetTransactionsChartState,
  resetTransactionsState,
  getTransactionsChartByFilter,
  getLiabilityTrendChartByFilter,
  changeTxnStatusState,
  resetTxnStatusState,
  txnStatusChangeSubmit,
  resetRefundMilesState,
  refundMiles,
  selectTxnColumn,
  changeTxnSearchState,
  resetTxnSearchState
}
