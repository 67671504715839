import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'
import { useParams } from 'react-router-dom'

import Loading from '../components/shared/Loading/Loading'
import {
  getTransactions,
  getTransactionsForDownload,
  resetTransactionsDownloadState,
  resetTransactionsChartState,
  resetTransactionsState,
  getTransactionsChartByFilter,
  getLiabilityTrendChartByFilter,
  changeTxnStatusState,
  resetTxnStatusState,
  txnStatusChangeSubmit,
  resetRefundMilesState,
  refundMiles,
  selectTxnColumn,
  changeTxnSearchState,
  resetTxnSearchState
} from '../store/actions/transactions'
import {
  getMembers,
  getMembersForDownload,
  resetMembersDownloadState,
  resetMembersState,
  changeMemberTransactionState,
  resetMemberTransactionState,
  getMemberBalances,
  getMemberTransactions,
  memberTransaction,
  changeMemberSearchState,
  resetMemberSearchState,
  searchMembers,
  changeRewardMemberState,
  resetRewardMemberState,
  creditPointsBankMember,
  creditPointsExchangeMember
} from '../store/actions/members'
import {
  getTokens,
  getTokenStatsForLastAndCurrentWeek
} from '../store/actions/token'
import { DASHBOARD_SETUP_STATE } from '../store/reducers/dashboard/dashboardSetup'
import Analytics from '../components/analytics/Analytics'
import { isDashboardReady } from '../util/dashboard.helpers'
import { buildTableHeaders, exportCsv } from '../util/csv.helpers'
import { MEMBERS_TABLE_HEADER } from '../constants/table'
import { formatToShortDate } from '../util/date.helpers'
import {
  buildSortedColumns,
  buildTxnCSVTableHeaders,
  buildTxnTableHeaders
} from '../util/transaction/transaction.helpers'
import { formatTransactionDisplayData } from '../util/transaction/transactionRow.helpers'
import { updateUserAccount } from '../store/actions/account'
import { resetDownloadState } from '../store/actions/download'
import { buildMetadataAttributes } from '../util/account.helpers'
import {
  DEFAULT_CONTAINER_STATE,
  ANALYTICS_PROCESS_STEP,
  QR_CODE_PROCESS_STEP
} from '../constants/containerStates'
import {
  changeAnalyticsFilterState,
  resetAnalyticsFilterState,
  getActiveTotalMembers,
  getExchangeMembers,
  getTopExchangePartners,
  getAnalyticsTokenStats
} from '../store/actions/analytics'
import { DEFAULT_DEBOUNCE_TIME, DOWNLOAD_REPORT_TYPES } from '../constants/app'
import { buildDownloadFileName } from '../util/download.helpers'
import {
  calculatePointValue,
  compareLastWeekWithThisWeek
} from '../util/token.helpers'
import { MEMBER_TRANSACTION_PROFILE_DISPATCH } from '../store/reducers/members/memberTransaction'
import { containsNoValues } from '../util/object.helpers'
import {
  checkValidations,
  emailRule,
  exceedLimitRule,
  requiredRule,
  validNaturalNumberRule
} from '../util/validation.helpers'
import { MEMBER_SEARCH_PROFILE_DISPATCH } from '../store/reducers/members/memberSearch'
import {
  MEMBER_AUTH_ID_MIN_LENGTH,
  MEMBER_POINTS_TRANSACTION_TYPES
} from '../constants/members'
import MemberMoreActionsPopup from '../components/popups/analytics/MemberMoreActions'
import ConfirmedPopup from '../components/popups/common/Confirmed'
import TransactionConfirmationPopup from '../components/popups/analytics/TransactionConfirmation'
import ScanQrCodePopup from '../components/shared/QrCode/ScanQrCode'
import CameraBlockedPopup from '../components/shared/QrCode/CameraBlocked'
import ChangeTxnStatusPopup from '../components/popups/analytics/ChangeTxnStatus'
import RefundMilesPopup from '../components/popups/analytics/RefundMiles'
import {
  isAPICompleted,
  isAPIInProgress,
  maxExchangeValue
} from '../util/app/app.helpers'
import {
  buildAnalyticsUrl,
  fromAnalyticsTabIdToType,
  isAnalyticsPointsBank
} from '../util/analytics/analytics.helpers'
import { isTokenFullIntegration } from '../util/analytics/pointsBank.helpers'
import { ANALYTICS_FILTER_DISPATCH } from '../store/reducers/analytics/analyticsFilter'
import { ANALYTICS_TAB_TYPE } from '../constants/analytics'
import RewardPointsBankMemberPopup from '../components/popups/analytics/RewardPointsBankMember'
import RewardExchangeMemberPopup from '../components/popups/analytics/RewardExchangeMember'
import RewardedMemberPopup from '../components/popups/analytics/RewardedMember'
import { REWARD_MEMBER_DISPATCH } from '../store/reducers/members/rewardMember'
import { getExchangeWhitelist } from '../store/actions/exchange'
import { formatExchangePartnersForReward } from '../util/members/members.helpers'
import { validationRulesForAccountNumberSanity } from '../util/members/validation.helpers'
import { getMinExchangeUSDValue } from '../util/exchange.helpers'

const AnalyticsContainer = ({
  dashboardSetupState,
  getMembers,
  getMembersForDownload,
  resetMembersDownloadState,
  getTransactions,
  getTransactionsForDownload,
  resetTransactionsDownloadState,
  resetTransactionsChartState,
  membersProfile,
  membersDownloadProfile,
  transactionsProfile,
  transactionsDownloadProfile,
  tokenProfile,
  updateUserAccount,
  getTokens,
  getAnalyticsTokenStats,
  downloadProfile,
  resetDownloadState,
  resetTransactionsState,
  resetMembersState,
  brandProfile,
  changeAnalyticsFilterState,
  resetAnalyticsFilterState,
  getTokenStatsForLastAndCurrentWeek,
  mainProfile,
  getTransactionsChartByFilter,
  memberTransactionProfile,
  changeMemberTransactionState,
  resetMemberTransactionState,
  getMemberBalances,
  getMemberTransactions,
  memberTransaction,
  memberSearchProfile,
  changeMemberSearchState,
  resetMemberSearchState,
  searchMembers,
  getActiveTotalMembers,
  getExchangeMembers,
  getTopExchangePartners,
  analyticsFilterProfile,
  history,
  rewardMemberProfile,
  changeRewardMemberState,
  resetRewardMemberState,
  creditPointsBankMember,
  creditPointsExchangeMember,
  getExchangeWhitelist,
  activeToExchanges,
  getLiabilityTrendChartByFilter,
  changeStatusProfile,
  changeTxnStatusState,
  resetTxnStatusState,
  txnStatusChangeSubmit,
  resetRefundMilesState,
  refundMiles,
  refundMilesProfile,
  selectTxnColumn,
  transactionColumnsProfile,
  transactionSearchProfile,
  changeTxnSearchState,
  resetTxnSearchState,
  ...restProps
}) => {
  const { t } = useTranslation()
  const { selectedTab } = useParams()
  const isFullIntegration = isTokenFullIntegration(tokenProfile.type)

  const setSelectedTab = (selectedTab) => {
    history.push(buildAnalyticsUrl(selectedTab))
  }

  const [columnMenuOpen, setColumnAnchor] = useState(null)

  const handleColumnMenuClick = (event) => {
    setColumnAnchor(event.currentTarget)
  }

  const handleCloseColumnMenu = () => {
    setColumnAnchor(null)
  }

  useEffect(() => {
    if (!selectedTab || !isFullIntegration) {
      setSelectedTab(ANALYTICS_TAB_TYPE.POINTS_EXCHANGE)
    } else {
      handleChangeAnalyticsType(selectedTab)
    }
    resetMemberSearchState()
    resetTxnSearchState()
  }, [selectedTab])

  useEffect(() => {
    if (changeStatusProfile.isChangedStatus) {
      resetTxnStatusState()
      handleNoPopup()
    }
  }, [changeStatusProfile.isChangedStatus])

  useEffect(() => {
    if (refundMilesProfile.isChangedStatus) {
      resetRefundMilesState()
      handleNoPopup()
    }
  }, [refundMilesProfile.isChangedStatus])

  const isPointsBank = isAnalyticsPointsBank(analyticsFilterProfile.type)
  const maxExchangeUSDValue = useMemo(() => {
    if (rewardMemberProfile.loyaltyPoint) {
      return maxExchangeValue(
        getMinExchangeUSDValue(
          activeToExchanges[rewardMemberProfile.loyaltyPoint]
            ?.tokenExchangeWhitelist,
          mainProfile.dashboardConfigs?.qiibeeMaxExchangeAmount
        )
      )
    }
  }, [rewardMemberProfile.loyaltyPoint])

  const [popupState, setPopupState] = useState({
    id: DEFAULT_CONTAINER_STATE.NONE,
    data: null
  })
  const [rewardExchangePopup, setRewardExchangePopup] = useState(false)

  useEffect(() => {
    const action = rewardMemberProfile.popupState
    if (action) {
      setPopupState({ id: action })
    }
  }, [rewardMemberProfile.popupState])

  const handleChangeTab = (event, selectedTabId) => {
    const newTab = fromAnalyticsTabIdToType(selectedTabId)
    if (selectedTab !== newTab) {
      setSelectedTab(newTab)
    }
  }

  const weeksExchangeValuesDiff = useMemo(() => {
    return compareLastWeekWithThisWeek(mainProfile)
  }, [mainProfile])

  useEffect(() => {
    getTokens()
    return () => {
      resetMemberSearchState()
      resetTxnSearchState()
      resetDownloadState()
      resetTransactionsDownloadState()
      resetMembersDownloadState()
      resetTransactionsChartState()
      resetTransactionsState()
      resetMembersState()
      resetMemberTransactionState()
      resetAnalyticsFilterState()
    }
  }, [])

  useEffect(() => {
    if (isDashboardReady(dashboardSetupState)) {
      getTokenStatsForLastAndCurrentWeek()
      if (selectedTab === ANALYTICS_TAB_TYPE.POINTS_EXCHANGE) {
        getExchangeMembers()
        getTopExchangePartners()
        getExchangeWhitelist()
      }
      if (selectedTab === ANALYTICS_TAB_TYPE.POINTS_BANK) {
        getActiveTotalMembers()
        getLiabilityTrendChartByFilter()
      }
      getTransactionsChartByFilter()
      getAnalyticsTokenStats()
    }
  }, [dashboardSetupState, tokenProfile, selectedTab])

  const handleCloseImportantNote = () => {
    updateUserAccount(buildMetadataAttributes({ hide_analytics_note: true }))
  }

  useEffect(() => {
    const downloadEmailProgress = downloadProfile.downloadEmailProgress
    if (isAPIInProgress(downloadEmailProgress)) {
      setPopupState({ id: ANALYTICS_PROCESS_STEP.SENDING_REPORT_EMAIL })
    }
  }, [downloadProfile.downloadEmailProgress])

  useEffect(() => {
    if (membersDownloadProfile.membersData.length > 0) {
      downloadMembersData(membersDownloadProfile.membersData)
      resetMembersDownloadState()
      handleNoPopup()
    }
  }, [membersDownloadProfile.membersData])

  useEffect(() => {
    if (transactionsDownloadProfile.transactionsData.length > 0) {
      downloadTransactionsData(transactionsDownloadProfile.transactionsData)
      resetTransactionsDownloadState()
      handleNoPopup()
    }
  }, [transactionsDownloadProfile.transactionsData])

  useEffect(() => {
    if (
      memberTransactionProfile.transactionAction ===
      ANALYTICS_PROCESS_STEP.MEMBER_TRANSACTION_COMPLETED
    ) {
      setPopupState({ id: ANALYTICS_PROCESS_STEP.MEMBER_MORE_ACTIONS })
    }
  }, [memberTransactionProfile.transactionAction])

  useEffect(() => {
    if (isDashboardReady(dashboardSetupState)) {
      handleDebounceMemberSearch(memberSearchProfile.query)
    }
  }, [dashboardSetupState, memberSearchProfile.query])

  const handleDebounceMemberSearch = useCallback(
    debounce((query) => {
      if (query) {
        searchMembers(query)
      } else {
        getMembers({ pageNumber: 0 })
      }
    }, DEFAULT_DEBOUNCE_TIME),
    []
  )

  useEffect(() => {
    if (isDashboardReady(dashboardSetupState)) {
      handleDebounceTransactionSearch(transactionSearchProfile.query)
    }
  }, [dashboardSetupState, transactionSearchProfile.query])

  const handleDebounceTransactionSearch = useCallback(
    debounce(() => {
      getTransactions({ pageNumber: 0 })
    }, DEFAULT_DEBOUNCE_TIME),
    []
  )

  const membersHeaders = buildTableHeaders(t, MEMBERS_TABLE_HEADER)
  const txnTableFullHeaders = buildTxnCSVTableHeaders(t, tokenProfile)

  const handleChangeMembersPage = (pageNumber) => {
    getMembers({ pageNumber })
  }

  const handleMembersPerPageChange = (recordsPerPage) => {
    getMembers({ pageNumber: 0, recordsPerPage })
  }

  const handleChangeTransactionsPage = (pageNumber) => {
    getTransactions({ pageNumber })
  }

  const handleTransactionsPerPageChange = (recordsPerPage) => {
    getTransactions({ pageNumber: 0, recordsPerPage })
  }

  const downloadMembersData = (data) => {
    const membersBody = data.map(
      ({ firstName, secondName, email, insertedAt, metadata, authId }) => [
        `${firstName} ${secondName}`,
        email || '',
        authId || '',
        formatToShortDate(insertedAt),
        metadata?.optsNewsletter || ''
      ]
    )
    exportCsv(
      [
        ...membersHeaders.map((header) => header.label),
        t('members.optional-checkbox')
      ],
      membersBody,
      buildDownloadFileName(tokenProfile.name, DOWNLOAD_REPORT_TYPES.MEMBERS)
    )
  }

  const downloadTransactionsData = (data) => {
    const currentBrandId = brandProfile.id
    const transactionsBody = data.map((transaction) => {
      const {
        name,
        email,
        auth,
        date,
        hash,
        id,
        eventType,
        code,
        reward,
        triggeredBy,
        brandRef,
        exchangeCurrency,
        eventTypeLabel,
        pointAmount,
        exchangeAmount,
        statusLabel,
        additionalData,
        mmAccount
      } = formatTransactionDisplayData(
        {
          currentTokenId: tokenProfile.id,
          currentBrandId,
          ...transaction
        },
        !isPointsBank
      )
      return [
        name,
        email,
        auth,
        t(statusLabel) || '',
        date,
        hash,
        id,
        t(eventTypeLabel) || '',
        pointAmount?.value
          ? `${pointAmount?.valueSymbol || ''}${pointAmount?.value}`
          : '',
        `${
          exchangeAmount?.value
            ? `${exchangeAmount?.valueSymbol || ''}${exchangeAmount?.value}`
            : ''
        } ${exchangeCurrency || ''}`,
        eventType,
        code,
        reward,
        additionalData?.fees || '',
        triggeredBy,
        brandRef,
        mmAccount
      ]
    })

    exportCsv(
      txnTableFullHeaders.map((header) => header.label),
      transactionsBody,
      buildDownloadFileName(
        tokenProfile.name,
        DOWNLOAD_REPORT_TYPES.TRANSACTIONS
      )
    )
  }

  const handleOnSubmitPopup = () => {
    switch (popupState.id) {
      case ANALYTICS_PROCESS_STEP.MEMBER_MORE_ACTIONS:
        const { amount } = memberTransactionProfile
        changeMemberTransactionState(
          MEMBER_TRANSACTION_PROFILE_DISPATCH.ERROR,
          {}
        )
        const errors = checkValidations({
          amount: {
            value: amount,
            rules: [requiredRule, validNaturalNumberRule]
          }
        })
        if (containsNoValues(errors)) {
          setPopupState({
            id: ANALYTICS_PROCESS_STEP.MEMBER_TRANSACTION_CONFIRMATION
          })
        } else {
          changeMemberTransactionState(
            MEMBER_TRANSACTION_PROFILE_DISPATCH.ERROR,
            errors
          )
        }
        break

      case ANALYTICS_PROCESS_STEP.REWARD_POINTS_BANK_MEMBER: {
        const { amount, account } = rewardMemberProfile
        changeRewardMemberState(REWARD_MEMBER_DISPATCH.ERROR, {})
        const errors = checkValidations({
          amount: {
            value: amount,
            rules: [requiredRule, validNaturalNumberRule]
          },
          account: {
            value: account,
            rules: [requiredRule, emailRule]
          }
        })
        if (containsNoValues(errors)) {
          setPopupState({
            id: ANALYTICS_PROCESS_STEP.REWARD_MEMBER_CONFIRMATION
          })
        } else {
          changeRewardMemberState(REWARD_MEMBER_DISPATCH.ERROR, errors)
        }
        break
      }

      case ANALYTICS_PROCESS_STEP.REWARD_EXCHANGE_MEMBER: {
        rewardExchangeMemberAction()
        break
      }

      case ANALYTICS_PROCESS_STEP.MEMBER_TRANSACTION_CONFIRMATION:
        memberTransaction()
        break

      case ANALYTICS_PROCESS_STEP.REWARD_MEMBER_CONFIRMATION:
        if (!isFullIntegration) {
          creditPointsExchangeMember(
            activeToExchanges?.[rewardMemberProfile.loyaltyPoint]?.onramp
          )
        } else {
          creditPointsBankMember()
        }
        break

      case ANALYTICS_PROCESS_STEP.REWARDED_MEMBER_SUCCESS:
        resetRewardMemberState()
        handleNoPopup()
        break

      case ANALYTICS_PROCESS_STEP.CHANGE_TXN_STATUS:
        txnStatusChangeSubmit(popupState.data.transactionId)
        break

      case ANALYTICS_PROCESS_STEP.REFUND_MILES:
        refundMiles(popupState.data.transactionId)
        break

      default:
        handleNoPopup()
        break
    }
  }

  const handleOnCancelPopup = () => {
    switch (popupState.id) {
      case ANALYTICS_PROCESS_STEP.DOWNLOAD_TRANSACTIONS:
      case ANALYTICS_PROCESS_STEP.DOWNLOAD_MEMBERS:
        handleNoPopup()
        resetDownloadState()
        break

      case ANALYTICS_PROCESS_STEP.MEMBER_MORE_ACTIONS:
        resetMemberTransactionState()
        handleNoPopup()
        break

      case ANALYTICS_PROCESS_STEP.MEMBER_TRANSACTION_CONFIRMATION:
        setPopupState({ id: ANALYTICS_PROCESS_STEP.MEMBER_MORE_ACTIONS })
        break

      case ANALYTICS_PROCESS_STEP.REWARD_POINTS_BANK_MEMBER:
      case ANALYTICS_PROCESS_STEP.REWARD_EXCHANGE_MEMBER:
        handleNoPopup()
        resetRewardMemberState()
        break

      case ANALYTICS_PROCESS_STEP.REWARD_MEMBER_CONFIRMATION:
        setPopupState({
          id: !isFullIntegration
            ? ANALYTICS_PROCESS_STEP.REWARD_EXCHANGE_MEMBER
            : ANALYTICS_PROCESS_STEP.REWARD_POINTS_BANK_MEMBER
        })
        break

      case QR_CODE_PROCESS_STEP.SCAN_CODE:
        if (rewardExchangePopup) {
          setPopupState({ id: ANALYTICS_PROCESS_STEP.REWARD_EXCHANGE_MEMBER })
        } else {
          handleNoPopup()
        }
        break

      case ANALYTICS_PROCESS_STEP.CHANGE_TXN_STATUS:
        resetTxnStatusState()
        handleNoPopup()
        break

      case ANALYTICS_PROCESS_STEP.REFUND_MILES:
        resetRefundMilesState()
        handleNoPopup()
        break

      default:
        handleNoPopup()
    }
  }

  const rewardExchangeMemberAction = () => {
    const { amount, account, loyaltyPoint } = rewardMemberProfile
    changeRewardMemberState(REWARD_MEMBER_DISPATCH.ERROR, {})
    const brandId = activeToExchanges?.[loyaltyPoint]?.brandId
    const errors = checkValidations({
      amount: {
        value: amount,
        rules: [requiredRule, validNaturalNumberRule]
      },
      loyaltyPoint: {
        value: loyaltyPoint,
        rules: [requiredRule]
      },
      account: {
        value: account,
        rules: validationRulesForAccountNumberSanity(brandId)
      }
    })
    if (containsNoValues(errors)) {
      const validateExchangeValue = exceedLimitRule(maxExchangeUSDValue)(
        calculatePointValue(amount, activeToExchanges[loyaltyPoint].onramp)
      )
      if (validateExchangeValue !== '') {
        changeRewardMemberState(REWARD_MEMBER_DISPATCH.ERROR, {
          amount: validateExchangeValue
        })
      } else {
        setPopupState({ id: ANALYTICS_PROCESS_STEP.REWARD_MEMBER_CONFIRMATION })
      }
    } else {
      changeRewardMemberState(REWARD_MEMBER_DISPATCH.ERROR, errors)
    }
  }

  const handleNoPopup = () => {
    setPopupState({ id: DEFAULT_CONTAINER_STATE.NONE, data: null })
  }

  const handleMoreMemberActions = (member) => {
    changeMemberTransactionState(
      MEMBER_TRANSACTION_PROFILE_DISPATCH.SELECTED_MEMBER,
      member
    )
    setPopupState({ id: ANALYTICS_PROCESS_STEP.MEMBER_MORE_ACTIONS })

    if (isPointsBank) {
      getMemberBalances()
    } else {
      getMemberTransactions()
    }
  }

  const setUpQrCodePopup = (isExchange = false) => {
    setRewardExchangePopup(isExchange)
    setPopupState({ id: QR_CODE_PROCESS_STEP.SCAN_CODE })
  }
  const handleMemberQrCodeClick = () => {
    setUpQrCodePopup(false)
  }

  const handleExchangeMemberQrClick = () => {
    setUpQrCodePopup(true)
  }

  const handleMemberResetSearchClick = () => {
    resetMemberSearchState()
  }

  const handleTransactionResetSearchClick = () => {
    resetTxnSearchState()
  }

  const handleQrCodeError = (error) => {
    console.log(error)
    setPopupState({ id: QR_CODE_PROCESS_STEP.CAMERA_BLOCKED })
  }

  const handleQrCodeScan = (text) => {
    if (text && text.length >= MEMBER_AUTH_ID_MIN_LENGTH) {
      handleNoPopup()
      if (rewardExchangePopup) {
        changeRewardMemberState(REWARD_MEMBER_DISPATCH.ACCOUNT, text)
        setPopupState({ id: ANALYTICS_PROCESS_STEP.REWARD_EXCHANGE_MEMBER })
      } else {
        changeMemberSearchState(MEMBER_SEARCH_PROFILE_DISPATCH.QUERY, text)
      }
    }
  }

  const handleRewardMember = () => {
    if (!isFullIntegration) {
      setPopupState({ id: ANALYTICS_PROCESS_STEP.REWARD_EXCHANGE_MEMBER })
    } else {
      changeRewardMemberState(
        REWARD_MEMBER_DISPATCH.ACCOUNT,
        memberSearchProfile.query
      )
      setPopupState({ id: ANALYTICS_PROCESS_STEP.REWARD_POINTS_BANK_MEMBER })
    }
  }

  const handleChangeTxnStatus = useCallback((transactionId) => {
    setPopupState({
      id: ANALYTICS_PROCESS_STEP.CHANGE_TXN_STATUS,
      data: { transactionId }
    })
  }, [])

  const handleRefundAction = useCallback((transactionId) => {
    setPopupState({
      id: ANALYTICS_PROCESS_STEP.REFUND_MILES,
      data: { transactionId }
    })
  }, [])

  const renderPopup = () => {
    switch (popupState.id) {
      case ANALYTICS_PROCESS_STEP.MEMBER_MORE_ACTIONS:
        return (
          <MemberMoreActionsPopup
            memberTransactionProfile={memberTransactionProfile}
            onClose={handleOnCancelPopup}
            onSubmit={handleOnSubmitPopup}
            dispatchAction={changeMemberTransactionState}
            t={t}
            membersProfile={membersProfile}
            tokenProfile={tokenProfile}
            brandProfile={brandProfile}
            isPointsBank={isPointsBank}
          />
        )

      case ANALYTICS_PROCESS_STEP.MEMBER_TRANSACTION_CONFIRMATION:
        return (
          <TransactionConfirmationPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            tokenProfile={tokenProfile}
            transaction={memberTransactionProfile}
          />
        )

      case ANALYTICS_PROCESS_STEP.SENDING_REPORT_EMAIL:
        return (
          <ConfirmedPopup
            t={t}
            onSubmit={handleNoPopup}
            title='analytics.csv-file-delivery'
            description='analytics.csv-file-delivery-desc'
            isProcessing={
              !isAPICompleted(downloadProfile.downloadEmailProgress)
            }
          />
        )

      case QR_CODE_PROCESS_STEP.SCAN_CODE:
        return (
          <ScanQrCodePopup
            onClose={handleOnCancelPopup}
            onError={handleQrCodeError}
            onScan={handleQrCodeScan}
            title={t('qr-code.qr-code-search')}
            description={t('qr-code.scan-members-account-number')}
            t={t}
          />
        )

      case QR_CODE_PROCESS_STEP.CAMERA_BLOCKED:
        return <CameraBlockedPopup onClose={handleOnCancelPopup} t={t} />

      case ANALYTICS_PROCESS_STEP.REWARD_POINTS_BANK_MEMBER:
        return (
          <RewardPointsBankMemberPopup
            t={t}
            onClose={handleOnCancelPopup}
            onSubmit={handleOnSubmitPopup}
            rewardMemberProfile={rewardMemberProfile}
            dispatchAction={changeRewardMemberState}
          />
        )

      case ANALYTICS_PROCESS_STEP.REWARD_EXCHANGE_MEMBER:
        return (
          <RewardExchangeMemberPopup
            t={t}
            onClose={handleOnCancelPopup}
            onSubmit={handleOnSubmitPopup}
            rewardMemberProfile={rewardMemberProfile}
            dispatchAction={changeRewardMemberState}
            activeToExchanges={activeToExchanges}
            onQrCodeClick={handleExchangeMemberQrClick}
            maxExchangeUSDValue={maxExchangeUSDValue}
          />
        )

      case ANALYTICS_PROCESS_STEP.REWARD_MEMBER_CONFIRMATION:
        return (
          <TransactionConfirmationPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            tokenProfile={
              isFullIntegration
                ? tokenProfile
                : activeToExchanges[rewardMemberProfile.loyaltyPoint]
            }
            transaction={{
              type: MEMBER_POINTS_TRANSACTION_TYPES.CREDIT_POINTS,
              ...rewardMemberProfile
            }}
          />
        )

      case ANALYTICS_PROCESS_STEP.REWARDED_MEMBER_SUCCESS:
        const token = isFullIntegration
          ? tokenProfile
          : activeToExchanges[rewardMemberProfile.loyaltyPoint]
        return (
          <RewardedMemberPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            title={t('reward.rewarded-member')}
            description={t('reward.rewarded-member-desc', {
              name: token.name
            })}
            tokenProfile={token}
            type={MEMBER_POINTS_TRANSACTION_TYPES.CREDIT_POINTS}
            value={rewardMemberProfile.amount}
            symbol={token.symbol}
            submitProps={{
              label: t('common.close'),
              disabled: rewardMemberProfile.isProcessing
            }}
          />
        )

      case ANALYTICS_PROCESS_STEP.CHANGE_TXN_STATUS:
        return (
          <ChangeTxnStatusPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            changeStatusProfile={changeStatusProfile}
            dispatchAction={changeTxnStatusState}
          />
        )

      case ANALYTICS_PROCESS_STEP.REFUND_MILES:
        return (
          <RefundMilesPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            refundMilesProfile={refundMilesProfile}
          />
        )

      default:
        break
    }
  }

  const handleChangeAnalyticsFilter = (property, value) => {
    changeAnalyticsFilterState(property, value)
  }

  const handleChangeAnalyticsType = (selectedTab) => {
    changeAnalyticsFilterState(ANALYTICS_FILTER_DISPATCH.TYPE, selectedTab)
  }

  const sortedTxnColumns = buildSortedColumns(
    transactionColumnsProfile.txnColumns
  )

  switch (dashboardSetupState) {
    case DASHBOARD_SETUP_STATE.LOADING:
      return <Loading />
    default:
      return (
        <>
          <Analytics
            onCloseImportantNote={handleCloseImportantNote}
            isDashboardSetupReady={isDashboardReady(dashboardSetupState)}
            membersTableProps={{
              membersHeaders: membersHeaders,
              membersProfile: membersProfile,
              onChangeMembersPage: handleChangeMembersPage,
              onDownloadMembersReport: getMembersForDownload,
              onMembersPerPageChange: handleMembersPerPageChange,
              membersDownloadProfile,
              onMoreMemberActions: handleMoreMemberActions,
              memberSearchProfile,
              changeMemberSearchState,
              onQrCodeClick: handleMemberQrCodeClick,
              onResetSearchClick: handleMemberResetSearchClick,
              onRewardMember: handleRewardMember
            }}
            transactionsTableProps={{
              transactionsHeaders: buildTxnTableHeaders(
                t,
                tokenProfile,
                sortedTxnColumns
              ),
              transactionsProfile: transactionsProfile,
              onChangeTransactionsPage: handleChangeTransactionsPage,
              onDownloadTransactionsReport: getTransactionsForDownload,
              onTransactionsPerPageChange: handleTransactionsPerPageChange,
              transactionsDownloadProfile,
              onChangeTxnStatus: handleChangeTxnStatus,
              onRefundAction: handleRefundAction,
              onColumnMenuClick: handleColumnMenuClick,
              columnMenuOpen,
              onCloseColumnMenu: handleCloseColumnMenu,
              txnTableFullHeaders,
              onSelectTxnColumn: selectTxnColumn,
              transactionColumnsProfile,
              sortedTxnColumns,
              transactionSearchProfile,
              onResetSearchClick: handleTransactionResetSearchClick,
              changeTxnSearchState
            }}
            tokenProfile={tokenProfile}
            brandProfile={brandProfile}
            onChangeAnalyticsFilter={handleChangeAnalyticsFilter}
            weeksExchangeValuesDiff={weeksExchangeValuesDiff}
            onChangeTab={handleChangeTab}
            isFullIntegration={isFullIntegration}
            analyticsFilterProfile={analyticsFilterProfile}
            {...restProps}
            t={t}
            isPointsBank={isPointsBank}
            activePartners={mainProfile.exchangePartnersStats.activeCount}
          />
          {renderPopup()}
        </>
      )
  }
}

const mapStateToProps = ({
  transactionsReducer,
  tokensReducer,
  dashboardReducer,
  membersReducer,
  accountReducer,
  mainReducer,
  analyticsReducer,
  exchangeReducer
}) => {
  const {
    transactionsGraphProfile,
    transactionsProfile,
    transactionsDownloadProfile,
    exchangeTransactionsGraphProfile,
    changeStatusProfile,
    refundMilesProfile,
    transactionColumnsProfile,
    transactionSearchProfile
  } = transactionsReducer
  const { brandProfile, dashboardSetupProfile } = dashboardReducer
  const { tokenProfile } = tokensReducer
  const { downloadProfile, billingProfile, mainProfile } = mainReducer
  const { analyticsFilterProfile, pointsBankProfile, pointsExchangeProfile } =
    analyticsReducer
  const {
    membersProfile,
    membersDownloadProfile,
    memberTransactionProfile,
    memberSearchProfile,
    rewardMemberProfile
  } = membersReducer

  const { partnersProfile } = exchangeReducer

  return {
    transactionsGraphProfile,
    transactionsProfile,
    transactionsDownloadProfile,
    membersProfile,
    membersDownloadProfile,
    tokenProfile,
    downloadProfile,
    dashboardSetupState: dashboardSetupProfile.dashboardSetupState,
    brandProfile,
    hideAnalyticsNote:
      accountReducer.accountProfile.metadata?.hideAnalyticsNote,
    analyticsFilterProfile,
    mainProfile,
    memberTransactionProfile,
    memberSearchProfile,
    billingProfile,
    pointsBankProfile,
    exchangeTransactionsGraphProfile,
    pointsExchangeProfile,
    rewardMemberProfile,
    activeToExchanges: formatExchangePartnersForReward(
      partnersProfile.active.data
    ),
    changeStatusProfile,
    refundMilesProfile,
    transactionColumnsProfile,
    transactionSearchProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAnalyticsTokenStats,
      getMembers,
      getMembersForDownload,
      resetMembersDownloadState,
      getTransactions,
      getTransactionsForDownload,
      resetTransactionsDownloadState,
      resetTransactionsChartState,
      updateUserAccount,
      resetDownloadState,
      getTokens,
      resetTransactionsState,
      resetMembersState,
      changeAnalyticsFilterState,
      resetAnalyticsFilterState,
      getTokenStatsForLastAndCurrentWeek,
      getTransactionsChartByFilter,
      changeMemberTransactionState,
      resetMemberTransactionState,
      getMemberBalances,
      getMemberTransactions,
      memberTransaction,
      changeMemberSearchState,
      resetMemberSearchState,
      searchMembers,
      getActiveTotalMembers,
      getExchangeMembers,
      getTopExchangePartners,
      changeRewardMemberState,
      resetRewardMemberState,
      creditPointsBankMember,
      creditPointsExchangeMember,
      getExchangeWhitelist,
      getLiabilityTrendChartByFilter,
      changeTxnStatusState,
      resetTxnStatusState,
      txnStatusChangeSubmit,
      resetRefundMilesState,
      refundMiles,
      selectTxnColumn,
      changeTxnSearchState,
      resetTxnSearchState
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsContainer)
