import { QB_TOKEN_STATUS } from '../constants/token'
import { formatMembers, formatTransactions } from './epics.helpers'
import { formatCurrencyValue } from './number.helpers'
import { keysToCamel } from './object.helpers'
import { TRANSACTION_STATUS } from '../constants/transactionTypes'

const parseAccountDetails = (account) => {
  if (!account) {
    return {}
  }
  account.job_position = account.job_position || ''
  account.country_ISO = account.country_ISO || ''
  return keysToCamel(account)
}

const parseToken = (token) => {
  if (!token) {
    return {}
  }
  if (!token.status) {
    token.status = QB_TOKEN_STATUS.PENDING
  }
  return keysToCamel(token)
}

const parseTransaction = (transaction) => {
  if (!transaction) {
    return {}
  }
  if (!transaction.status) {
    transaction.status = transaction.tx_hash
      ? TRANSACTION_STATUS.SUCCESS
      : TRANSACTION_STATUS.PENDING
  }
  if (transaction.amount_received) {
    transaction.amount_received = formatCurrencyValue(
      transaction.amount_received,
      6
    )
  }
  return keysToCamel(transaction)
}

const parseTransactionsResponse = (transactionsResponse) => {
  if (transactionsResponse.transactions) {
    transactionsResponse.transactions = formatTransactions(
      transactionsResponse.transactions
    )
  }
  return keysToCamel(transactionsResponse)
}

const parseMembersResponse = (membersResponse) => {
  if (membersResponse.users) {
    membersResponse.users = formatMembers(membersResponse.users)
  }
  return keysToCamel(membersResponse)
}

const commonParser = (data) => {
  if (!data) {
    return {}
  }
  return keysToCamel(data)
}

export {
  parseAccountDetails,
  parseToken,
  parseTransactionsResponse,
  parseTransaction,
  parseMembersResponse,
  commonParser
}
