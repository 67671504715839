import {
  extractInputValueAndError,
  mergeToNewObject
} from '../../../util/object.helpers'

import { ADMIN_ACTION } from '../../../constants/actions'
import { NUMBER_OF_RECORDS_PER_PAGE } from '../../../constants/app'
import {
  DEFAULT_CONTAINER_STATE,
  TEAM_MEMBERS_PROCESS_STEP
} from '../../../constants/containerStates'
import { TEAM_MEMBERS_ERRORS } from '../../../constants/apiErrors'

export const teamMembersProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case ADMIN_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case ADMIN_ACTION.CHANGE_INPUT_AND_ERROR_STATE:
      return mergeToNewObject(
        state,
        extractInputValueAndError(payload, state.error)
      )

    case ADMIN_ACTION.ON_ADD_TEAM_MEMBER:
      return mergeToNewObject(state, { isProcessing: true, manageAction: '' })

    case ADMIN_ACTION.ON_ADD_TEAM_MEMBER_SUCCESS:
      return mergeToNewObject(state, {
        teamMemberEmail: '',
        isProcessing: false,
        manageAction: TEAM_MEMBERS_PROCESS_STEP.ADD_TEAM_MEMBER
      })

    case ADMIN_ACTION.ON_ADD_TEAM_MEMBER_FAILED:
      return mergeToNewObject(state, handleUpdateTeamMembersErrors(payload), {
        teamMemberEmail: '',
        isProcessing: false
      })

    case ADMIN_ACTION.ON_REMOVE_TEAM_MEMBER:
      return mergeToNewObject(state, {
        isProcessing: true,
        manageAction: ''
      })

    case ADMIN_ACTION.ON_REMOVE_TEAM_MEMBER_SUCCESS:
      return mergeToNewObject(state, {
        isProcessing: false,
        manageAction: TEAM_MEMBERS_PROCESS_STEP.REMOVE_TEAM_MEMBER
      })

    case ADMIN_ACTION.ON_GET_TEAM_MEMBERS_SUCCESS:
      return mergeToNewObject(state, { teamMembers: payload })

    case ADMIN_ACTION.ON_RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  teamMembers: [],
  pagination: {
    recordsPerPage: NUMBER_OF_RECORDS_PER_PAGE,
    pageNumber: 0,
    totalTxCount: 0
  },
  teamMemberEmail: '',
  error: {},
  success: {},
  adminPopupState: DEFAULT_CONTAINER_STATE.NONE,
  isProcessing: false,
  manageAction: ''
}

export const ADMIN_DISPATCH = {
  TEAM_MEMBER_EMAIL: 'teamMemberEmail',
  ERROR: 'error',
  SUCCESS: 'success',
  ADMIN_POPUP_STATE: 'adminPopupState'
}

const handleUpdateTeamMembersErrors = ({ errors }) => {
  switch (errors.detail) {
    case TEAM_MEMBERS_ERRORS.TEAM_MEMBER_ALREADY_EXISTS:
      return {
        adminPopupState: TEAM_MEMBERS_PROCESS_STEP.USER_ALREADY_EXISTS
      }
    default:
      return {
        adminPopupState: TEAM_MEMBERS_PROCESS_STEP.USER_HAS_NO_ACCOUNT
      }
  }
}
