import { mergeToNewObject } from '../../../util/object.helpers'
import { EXCHANGE_FEEDBACK_ACTION } from '../../../constants/actions'

export const exchangeFeedbackProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case EXCHANGE_FEEDBACK_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case EXCHANGE_FEEDBACK_ACTION.ON_RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  reasons: [],
  feedback: '',
  error: {}
}

export const EXCHANGE_FEEDBACK_DISPATCH = {
  REASONS: 'reasons',

  FEEDBACK: 'feedback',

  ERROR: 'error'
}
