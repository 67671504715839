import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons'

import { formatToLongDate, isGivenDatePassed } from '../date.helpers'
import {
  buildEventGroup,
  getTransactionUser,
  isEventTypeRevertExchange
} from './transaction.helpers'
import {
  ADDITIONAL_TXN_EVENT_TYPE,
  ADDITIONAL_TXN_TYPES,
  EXCHANGE_TRANSACTION_TYPES,
  EXCHANGE_TRANSACTION_TYPE_COLORS,
  LOYALTY_EVENT_TYPES,
  TRANSACTION_STATUS,
  TRANSACTION_STATUS_LABEL,
  TRANSACTION_TYPES,
  TRANSACTION_TYPE_COLORS
} from '../../constants/transactionTypes'
import { COLOR_PALETTE } from '../../constants/theme'
import { isTokenTypeNft } from '../token.helpers'
import { CURRENCY_SYMBOL, CUSTOM_BRAND_ID } from '../../constants/app'
import { formatToCSVText } from '../string.helpers'

const formatTransactionDisplayData = (transaction, showTxnForExchange) => {
  let {
    id,
    insertedAt,
    loyaltyEventType,
    pointsBurned,
    amountReceived,
    token,
    tokenTo,
    status,
    reward,
    brandTo,
    user,
    userTo,
    txHash,
    currentBrandId,
    currentTokenId,
    effectiveAt,
    membershipNumberFrom,
    membershipNumberTo
  } = transaction
  let eventGroup = buildEventGroup(loyaltyEventType)
  let statusGroup = status
  let rewardTitle = reward?.rewardType?.name
  let eventTypeLabel = eventGroup
  let exchangeCurrency = tokenTo?.symbol
  let pointAmount = buildNegativeTxnParams(pointsBurned)
  let exchangeAmount = null
  let txnUser = getTransactionUser(user, userTo, brandTo, currentBrandId)
  let additionalData = {}

  // @TODO - change this implementation to swap for the points to points exchange

  if (loyaltyEventType === LOYALTY_EVENT_TYPES.CREDIT_NFT) {
    eventGroup = TRANSACTION_TYPES.REWARD
    eventTypeLabel = TRANSACTION_TYPES.REWARD
    exchangeCurrency = CURRENCY_SYMBOL.NFT
    pointAmount = null
    exchangeAmount = buildPositiveTxnParams(1)
    rewardTitle = token?.name
  } else if (loyaltyEventType === LOYALTY_EVENT_TYPES.NFT_TRANSFER) {
    eventGroup = ADDITIONAL_TXN_TYPES.TRANSFER
    eventTypeLabel = ADDITIONAL_TXN_TYPES.TRANSFER
    exchangeCurrency = CURRENCY_SYMBOL.NFT
    pointAmount = null
    rewardTitle = tokenTo?.name
    if (transaction?.isSeller) {
      exchangeAmount = buildNegativeTxnParams(1)
      txnUser = user
    } else {
      exchangeAmount = buildPositiveTxnParams(1)
      txnUser = userTo
    }
  } else if (loyaltyEventType === LOYALTY_EVENT_TYPES.NFT_SALE) {
    eventGroup = TRANSACTION_TYPES.EXCHANGE
    eventTypeLabel = TRANSACTION_TYPES.EXCHANGE
    exchangeCurrency = CURRENCY_SYMBOL.NFT
    rewardTitle = tokenTo?.name
    if (transaction?.isSeller) {
      pointAmount = buildPositiveTxnParams(amountReceived)
      exchangeAmount = buildNegativeTxnParams(1)
      additionalData.fees = pointsBurned - amountReceived
      txnUser = user
    } else {
      pointAmount = buildNegativeTxnParams(pointsBurned)
      exchangeAmount = buildPositiveTxnParams(1)
      txnUser = userTo
    }
  } else if (loyaltyEventType === LOYALTY_EVENT_TYPES.SIGNUP_BONUS) {
    pointAmount = buildPositiveTxnParams(amountReceived)
    if (isTokenTypeNft(token.type)) {
      pointAmount = null
      exchangeAmount = buildPositiveTxnParams(1)
      exchangeCurrency = CURRENCY_SYMBOL.NFT
      rewardTitle = token?.name
    }
  } else if (loyaltyEventType === LOYALTY_EVENT_TYPES.CODE_TO_NFT) {
    pointAmount = null
    exchangeAmount = buildPositiveTxnParams(1)
    exchangeCurrency = CURRENCY_SYMBOL.NFT
    rewardTitle = token?.name
  } else if (
    [
      LOYALTY_EVENT_TYPES.CODE_TO_POINTS,
      LOYALTY_EVENT_TYPES.CREDIT_POINTS
    ].includes(loyaltyEventType)
  ) {
    pointAmount = buildPositiveTxnParams(amountReceived)
  } else if (loyaltyEventType === LOYALTY_EVENT_TYPES.EXCHANGE) {
    exchangeAmount = buildPositiveTxnParams(amountReceived)
    if (
      token &&
      tokenTo &&
      user &&
      userTo &&
      isTokenTypeNft(tokenTo.type) &&
      user.authId === userTo.authId
    ) {
      // handling points_to_nft redemption
      eventGroup = TRANSACTION_TYPES.EXCHANGE
      eventTypeLabel = TRANSACTION_TYPES.EXCHANGE
      loyaltyEventType = ADDITIONAL_TXN_EVENT_TYPE.POINTS_TO_NFT
      exchangeCurrency = CURRENCY_SYMBOL.NFT
      rewardTitle = tokenTo?.name
    } else {
      if (token && token.brandId === currentBrandId) {
        loyaltyEventType = ADDITIONAL_TXN_EVENT_TYPE.BUY_EXCHANGE
        if (showTxnForExchange) {
          eventGroup = EXCHANGE_TRANSACTION_TYPES.BUY
          eventTypeLabel = loyaltyEventType
        }
      } else if (brandTo && brandTo.id === currentBrandId) {
        loyaltyEventType = ADDITIONAL_TXN_EVENT_TYPE.SELL_EXCHANGE
        if (showTxnForExchange) {
          eventGroup = EXCHANGE_TRANSACTION_TYPES.SELL
          eventTypeLabel = loyaltyEventType
        }
      }
      if (tokenTo?.id === currentTokenId) {
        exchangeCurrency = token?.symbol
        pointAmount = exchangeAmount
        exchangeAmount = buildNegativeTxnParams(pointsBurned)
      }
    }
    if (status === TRANSACTION_STATUS.CANCELLED) {
      statusGroup = TRANSACTION_STATUS_LABEL.REVERSED
      eventGroup = TRANSACTION_STATUS_LABEL.FUTURE
      eventTypeLabel = TRANSACTION_STATUS_LABEL.FUTURE
    } else if (
      effectiveAt &&
      status === TRANSACTION_STATUS.PENDING &&
      !txHash
    ) {
      statusGroup = isGivenDatePassed(effectiveAt)
        ? TRANSACTION_STATUS.PENDING
        : TRANSACTION_STATUS_LABEL.FUTURE
      eventGroup = TRANSACTION_STATUS_LABEL.FUTURE
      eventTypeLabel = TRANSACTION_STATUS_LABEL.FUTURE
    }
  } else if (isEventTypeRevertExchange(loyaltyEventType)) {
    statusGroup = TRANSACTION_STATUS_LABEL.REVERSED
    eventGroup = TRANSACTION_TYPES.EXCHANGE
    if (token && token.brandId === currentBrandId) {
      pointAmount = buildPositiveTxnParams(pointsBurned)
      exchangeAmount = buildNegativeTxnParams(amountReceived)
      if (showTxnForExchange) {
        eventGroup = EXCHANGE_TRANSACTION_TYPES.BUY
        eventTypeLabel = ADDITIONAL_TXN_EVENT_TYPE.BUY_EXCHANGE
      }
    } else if (brandTo && brandTo.id === currentBrandId) {
      pointAmount = buildNegativeTxnParams(amountReceived)
      exchangeAmount = buildPositiveTxnParams(pointsBurned)
      exchangeCurrency = token?.symbol
      if (showTxnForExchange) {
        eventGroup = EXCHANGE_TRANSACTION_TYPES.SELL
        eventTypeLabel = ADDITIONAL_TXN_EVENT_TYPE.SELL_EXCHANGE
      }
    }
  }
  if (
    [
      LOYALTY_EVENT_TYPES.CREDIT_POINTS,
      LOYALTY_EVENT_TYPES.CREDIT_NFT,
      LOYALTY_EVENT_TYPES.CODE_TO_POINTS,
      LOYALTY_EVENT_TYPES.CODE_TO_NFT
    ].includes(loyaltyEventType)
  ) {
    if (status === TRANSACTION_STATUS.CANCELLED) {
      statusGroup = TRANSACTION_STATUS_LABEL.REVERSED
      eventGroup = TRANSACTION_STATUS_LABEL.FUTURE
      eventTypeLabel = TRANSACTION_STATUS_LABEL.FUTURE
    } else if (effectiveAt && !txHash) {
      statusGroup = isGivenDatePassed(effectiveAt)
        ? TRANSACTION_STATUS.PENDING
        : TRANSACTION_STATUS_LABEL.FUTURE
      eventGroup = TRANSACTION_STATUS_LABEL.FUTURE
      eventTypeLabel = TRANSACTION_STATUS_LABEL.FUTURE
    }
  }

  if (status === TRANSACTION_STATUS.REVERTED) {
    statusGroup = TRANSACTION_STATUS_LABEL.REVERSED
  }
  const mmAccount =
    (token?.brandId === CUSTOM_BRAND_ID.MILES_AND_MORE &&
      membershipNumberFrom) ||
    (brandTo?.id === CUSTOM_BRAND_ID.MILES_AND_MORE && membershipNumberTo)
  return {
    name: `${txnUser?.firstName || ''} ${txnUser?.secondName || ''}`,
    email: txnUser?.email || '',
    auth: txnUser?.authId || '',
    date: formatToLongDate(insertedAt) || '',
    hash: txHash || '',
    id,
    eventType: loyaltyEventType || '',
    code: transaction?.code?.code || '',
    reward: formatToCSVText(rewardTitle),
    triggeredBy: transaction?.triggeredBy || '',
    brandRef: transaction?.brandReferenceId || '',
    mmAccount: mmAccount || '',
    pointAmount,
    exchangeAmount,
    statusLabel: `transactions.status.${statusGroup}`,
    eventTypeLabel: `transactions.event-type.${eventTypeLabel}`,
    eventTypeColor: buildEventTypeColor(eventGroup, showTxnForExchange),
    exchangeCurrency,
    statusGroup,
    additionalData
  }
}

export { formatTransactionDisplayData }

const buildNegativeTxnParams = (value) => {
  return {
    value,
    icon: faMinus,
    color: COLOR_PALETTE.ERROR,
    valueSymbol: '-'
  }
}

const buildPositiveTxnParams = (value) => {
  return {
    value,
    icon: faPlus,
    color: COLOR_PALETTE.SUCCESS
  }
}

const buildEventTypeColor = (eventGroup, showTxnForExchange) => {
  if (showTxnForExchange) {
    return EXCHANGE_TRANSACTION_TYPE_COLORS[eventGroup]
  }
  return TRANSACTION_TYPE_COLORS[eventGroup]
}
