import React, { memo } from 'react'
import { TableRow, Grid } from '@mui/material'
import { faGift, faPen } from '@fortawesome/pro-light-svg-icons'

import { formatToShortDate } from '../../util/date.helpers'
import DashboardCard from '../shared/DashboardCard/DashboardCard'
import DataTable from '../shared/DataTable/DataTable'
import TableCellData from '../shared/DataTable/TableCellData'
import DownloadButton from '../shared/inputs/DownloadButton'
import FaIcon from '../shared/FaIcon/FaIcon'
import CustomButton from '../shared/buttons/CustomButton'
import MembersSearch from './MembersSearch'

const MembersData = ({
  t,
  membersTableProps: {
    membersProfile,
    onChangeMembersPage,
    membersHeaders,
    onDownloadMembersReport,
    onMembersPerPageChange,
    onMoreMemberActions,
    memberSearchProfile,
    changeMemberSearchState,
    membersDownloadProfile: { isProcessing },
    onQrCodeClick,
    onResetSearchClick,
    onRewardMember
  },
  isFullIntegration
}) => {
  const { members, pagination } = membersProfile
  const headers = [...membersHeaders, {}]
  const txTableData = members.map((member, index) => (
    <DataTableRow
      key={index}
      member={member}
      t={t}
      onMoreMemberActions={onMoreMemberActions}
    />
  ))
  const rewardMemberButton = (
    <CustomButton
      startIcon={<FaIcon icon={faGift} width={16} />}
      onClick={onRewardMember}
      fullWidth={false}
      label={t(
        `reward.${!isFullIntegration ? 'reward-member' : 'reward-new-member'}`
      )}
      noPadding
      sx={{ lineHeight: 1.8 }}
    />
  )
  return (
    <DashboardCard
      title={t('sidebar.members')}
      action={
        <Grid container spacing={2} alignItems='center' justifyContent='end'>
          <MembersSearch
            t={t}
            memberSearchProfile={memberSearchProfile}
            dispatchAction={changeMemberSearchState}
            onQrCodeClick={onQrCodeClick}
            onResetSearchClick={onResetSearchClick}
            action={!isFullIntegration && rewardMemberButton}
          />
          <Grid item>
            <DownloadButton
              label={t('analytics.download-csv')}
              onClick={onDownloadMembersReport}
              disabled={members.length === 0 || isProcessing}
              fullWidth
            />
          </Grid>
        </Grid>
      }
      noPadding
    >
      <DataTable
        headers={headers}
        data={txTableData}
        onChangePage={onChangeMembersPage}
        onRowsPerPageChange={onMembersPerPageChange}
        pagination={pagination}
        t={t}
        sx={{
          whiteSpace: {
            xs: 'nowrap',
            sm: 'unset'
          }
        }}
        noRecordsMessage={t('member.no-members-found')}
        action={
          isFullIntegration &&
          memberSearchProfile.query &&
          !members.length &&
          rewardMemberButton
        }
      />
    </DashboardCard>
  )
}

export default memo(MembersData)

const DataTableRow = ({ member, onMoreMemberActions, t }) => {
  const { firstName, secondName, email, insertedAt, authId } = member
  return (
    <TableRow>
      <TableCellData textProps={{ color: '', fontWeight: 500, maxWidth: 146 }}>
        {firstName} {secondName}
      </TableCellData>
      <TableCellData>{email}</TableCellData>
      <TableCellData>{authId}</TableCellData>
      <TableCellData>{formatToShortDate(insertedAt)}</TableCellData>
      <TableCellData noTag align='right'>
        <CustomButton
          label={t('analytics.more-actions')}
          onClick={() => onMoreMemberActions(member)}
          startIcon={<FaIcon icon={faPen} width={16} />}
          fullWidth={false}
          noPadding
          size='small'
          sx={{ minWidth: 140 }}
        />
      </TableCellData>
    </TableRow>
  )
}
