export const SURVEY_FIELD_TYPES = {
  SMALL_TEXT: 'small-text',
  LARGE_TEXT: 'large-text',
  DROPDOWN: 'dropdown',
  CHECKBOX: 'checkbox',
  RADIO: 'radio'
}

export const LAD_SURVEY_IDS = {
  BRANDS_YOU_LOVE: 'lad_brands_you_love',
  THIRD_PARTY_TOOLS: 'lad_third_party_tools',
  EARN_OPTIONS: 'lad_earn_options',
  LAD_LWA_COMING_SOON: 'lad_lwa_coming_soon'
}

export const SURVEY_DESC_LENGTH = 140

export const MARKETPLACE_SETTINGS_SURVEY_ID = '_marketplace_settings'
export const POINTS_PURCHASE_SURVEY_ID = '_points_purchase'

export const SURVEY_QUESTION_EXCHANGE = {
  TERMS: 'terms',
  EXCHANGE_VOLUME: 'exchange_volume',
  MARKETING: 'marketing'
}
