import { mergeToNewObject } from '../../../util/object.helpers'
import {
  DOWNLOAD_ACTION,
  MEMBER_DOWNLOAD_ACTIONS
} from '../../../constants/actions'

export const membersDownloadProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case MEMBER_DOWNLOAD_ACTIONS.ON_GET_MEMBERS_FOR_DOWNLOAD:
      return mergeToNewObject(state, {
        membersData: [],
        isProcessing: true
      })

    case MEMBER_DOWNLOAD_ACTIONS.ON_GET_MEMBERS_FOR_DOWNLOAD_SUCCESS:
      return mergeToNewObject(state, {
        membersData: payload,
        isProcessing: false
      })

    case MEMBER_DOWNLOAD_ACTIONS.ON_GET_MEMBERS_FOR_DOWNLOAD_FAILED:
    case DOWNLOAD_ACTION.ON_SENT_REPORT_IN_EMAIL_SUCCESS:
    case DOWNLOAD_ACTION.ON_SENT_REPORT_IN_EMAIL_FAILED:
      return mergeToNewObject(state, { isProcessing: false })

    case MEMBER_DOWNLOAD_ACTIONS.ON_RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  membersData: [],
  isProcessing: false
}
