import {
  faCircleCheck,
  faCircleXmark,
  faClock,
  faMinus,
  faPlus,
  faCalendarDay,
  faArrowRotateLeft
} from '@fortawesome/pro-solid-svg-icons'

import {
  DEFAULT_TXN_TABLE_FIELDS,
  MEMBER_POINTS_TRANSACTION_TYPES,
  TRANSACTIONS_TABLE_FIELDS,
  TRANSACTION_SEARCH_FILTERS
} from '../../constants/members'
import {
  TRANSACTION_STATUS,
  TRANSACTION_TYPES,
  LOYALTY_EVENT_TYPES,
  ADDITIONAL_TXN_TYPES,
  EXCHANGE_TRANSACTION_TYPES,
  TRANSACTION_STATUS_LABEL,
  FUTURE_TRANSACTION_STATUS
} from '../../constants/transactionTypes'
import {
  convertToDate,
  getCurrentDateTimeWithSeconds,
  getDateDaysSubtractedBy,
  getTodaysDate
} from '../date.helpers'
import {
  extractBrandId,
  extractTokenId,
  extractTransactionSearchProfile
} from '../epics.helpers'
import {
  analyticsFilterData,
  buildDateRangeQueryParams,
  isAnalyticsPointsBank
} from '../analytics/analytics.helpers'
import { TOKEN_STATS_FILTER, TOKEN_TYPE } from '../../constants/token'
import { COLOR_PALETTE } from '../../constants/theme'
import { CUSTOM_BRAND_ID, FUTURE_MILES_TIME } from '../../constants/app'
import { buildDropdownList } from '../app/app.helpers'
import { getTxnColumns } from '../local.helpers'

export const REWARD_TRANSACTION_TYPES = [
  LOYALTY_EVENT_TYPES.CODE_TO_POINTS,
  LOYALTY_EVENT_TYPES.CODE_TO_NFT,
  LOYALTY_EVENT_TYPES.CREDIT_POINTS,
  LOYALTY_EVENT_TYPES.CREDIT_NFT,
  LOYALTY_EVENT_TYPES.SIGNUP_BONUS
]

const handleGetFilterTypeForTransactions = (
  { type, transactionType, exchangeType },
  tokenId
) => {
  if (isAnalyticsPointsBank(type)) {
    return buildTypesParamsForPointsBank(transactionType)
  } else {
    return buildParamsForPointsExchange(exchangeType, tokenId)
  }
}

const buildTypesParamsForPointsBank = (transactionType) => {
  switch (transactionType) {
    case TRANSACTION_TYPES.REWARD: //reward
      return {
        types: REWARD_TRANSACTION_TYPES
      }
    case TRANSACTION_TYPES.REDEEM: //redeem
      return {
        types: [
          LOYALTY_EVENT_TYPES.DEBIT_POINTS,
          LOYALTY_EVENT_TYPES.POINTS_TO_REWARDS
        ]
      }
    case TRANSACTION_TYPES.EXCHANGE: //exchange
      return {
        types: [
          LOYALTY_EVENT_TYPES.EXCHANGE,
          LOYALTY_EVENT_TYPES.NFT_SALE,
          LOYALTY_EVENT_TYPES.REVERT_EXCHANGE
        ]
      }
    default:
      return { types: [] }
  }
}

const buildParamsForPointsExchange = (exchangeType, tokenId) => {
  const types = [
    LOYALTY_EVENT_TYPES.EXCHANGE,
    LOYALTY_EVENT_TYPES.REVERT_EXCHANGE
  ]
  switch (exchangeType) {
    case EXCHANGE_TRANSACTION_TYPES.BUY: //buy exchange
      return {
        exclude_token_to_types: [TOKEN_TYPE.ERC_721],
        types,
        token_id: tokenId
      }

    case EXCHANGE_TRANSACTION_TYPES.SELL: //sell exchange
      return {
        exclude_token_to_types: [TOKEN_TYPE.ERC_721],
        types,
        token_to_id: tokenId
      }

    default:
      return {
        exclude_token_to_types: [TOKEN_TYPE.ERC_721],
        types
      }
  }
}

const buildTxnCSVTableHeaders = (t, { symbol }) => {
  return Object.values(TRANSACTIONS_TABLE_FIELDS).map((field) => {
    return formatTxnColumn(t, field, symbol)
  })
}

const buildTxnTableHeaders = (t, { symbol }, columns) => {
  return columns.map((field) => {
    return formatTxnColumn(t, field, symbol)
  })
}

const formatTxnColumn = (t, field, symbol) => {
  const args = field === TRANSACTIONS_TABLE_FIELDS.AMOUNT ? { symbol } : {}
  return {
    label: t(`transactions.columns.${field}`, args),
    value: field
  }
}

const RECENT_TXN_COLUMN_FIELDS = [
  TRANSACTIONS_TABLE_FIELDS.STATUS,
  TRANSACTIONS_TABLE_FIELDS.DATE,
  TRANSACTIONS_TABLE_FIELDS.HASH,
  TRANSACTIONS_TABLE_FIELDS.TYPE,
  TRANSACTIONS_TABLE_FIELDS.AMOUNT,
  TRANSACTIONS_TABLE_FIELDS.EXCHANGE
]

const buildTransactionsTableHeaders = (t, { symbol }) => {
  return RECENT_TXN_COLUMN_FIELDS.map((field) =>
    formatTxnColumn(t, field, symbol)
  )
}

const getLastCurrentWeekDateRanges = () => {
  return [
    {
      startDate: convertToDate(getDateDaysSubtractedBy(6)),
      endDate: getTodaysDate(),
      filterBy: TOKEN_STATS_FILTER.THIS_WEEK
    },
    {
      startDate: convertToDate(getDateDaysSubtractedBy(13)),
      endDate: convertToDate(getDateDaysSubtractedBy(7)),
      filterBy: TOKEN_STATS_FILTER.LAST_WEEK
    }
  ]
}

const getTransactionUser = (user, userTo, brandTo, currentBrandId) => {
  if (brandTo?.id === currentBrandId) {
    return userTo
  } else {
    return user
  }
}

const buildDefaultParamsWithFilters = (state) => {
  const { analyticsFilter, brandId, tokenId } =
    getBrandIdTokenIdAnalyticsFilters(state)
  return {
    brandId,
    tokenId,
    ...handleGetFilterTypeForTransactions(analyticsFilter, tokenId),
    ...buildDateRangeQueryParams(analyticsFilter)
  }
}

const getBrandIdTokenIdAnalyticsFilters = (state) => {
  const brandId = extractBrandId(state)
  const tokenId = extractTokenId(state)
  const analyticsFilter = analyticsFilterData(state)
  return {
    brandId,
    tokenId,
    analyticsFilter
  }
}

const buildTxnStatusColorIcon = (status) => {
  switch (status) {
    case TRANSACTION_STATUS.SUCCESS:
      return { icon: faCircleCheck, color: COLOR_PALETTE.SUCCESS }
    case TRANSACTION_STATUS.FAILED:
      return { icon: faCircleXmark, color: COLOR_PALETTE.ERROR }
    case TRANSACTION_STATUS.REVERTED:
    case TRANSACTION_STATUS_LABEL.REVERSED:
      return { icon: faArrowRotateLeft, color: COLOR_PALETTE.WARNING }
    case TRANSACTION_STATUS_LABEL.FUTURE:
      return { icon: faCalendarDay, color: COLOR_PALETTE.ORANGE }
    default:
      return { icon: faClock, color: COLOR_PALETTE.WARNING }
  }
}

const buildMemberTransactionTypeIconColor = (type) => {
  if (type === MEMBER_POINTS_TRANSACTION_TYPES.CREDIT_POINTS) {
    return {
      icon: faPlus,
      color: COLOR_PALETTE.SUCCESS
    }
  }
  return {
    icon: faMinus,
    color: COLOR_PALETTE.ERROR
  }
}

const buildEventGroup = (loyaltyEventType) => {
  switch (loyaltyEventType) {
    case LOYALTY_EVENT_TYPES.CODE_TO_POINTS:
    case LOYALTY_EVENT_TYPES.CODE_TO_NFT:
    case LOYALTY_EVENT_TYPES.CREDIT_POINTS:
    case LOYALTY_EVENT_TYPES.SIGNUP_BONUS:
      return TRANSACTION_TYPES.REWARD
    case LOYALTY_EVENT_TYPES.DEBIT_POINTS:
    case LOYALTY_EVENT_TYPES.POINTS_TO_REWARDS:
      return TRANSACTION_TYPES.REDEEM
    case LOYALTY_EVENT_TYPES.EXCHANGE:
      return TRANSACTION_TYPES.EXCHANGE
    case LOYALTY_EVENT_TYPES.NFT_TRANSFER:
      return ADDITIONAL_TXN_TYPES.TRANSFER
    default:
      return loyaltyEventType
  }
}

const isEventTypeRevertExchange = (eventType) => {
  return eventType === LOYALTY_EVENT_TYPES.REVERT_EXCHANGE
}

const isEventTypeNftTransfer = (eventType) => {
  return eventType === LOYALTY_EVENT_TYPES.NFT_TRANSFER
}

const isEventTypeNftSale = (eventType) => {
  return eventType === LOYALTY_EVENT_TYPES.NFT_SALE
}

const buildUpdateTxnStatusParams = () => {
  return {
    effective_at: getCurrentDateTimeWithSeconds(FUTURE_MILES_TIME)
  }
}

const buildTxnStatusOptions = (t) => {
  return buildDropdownList(
    FUTURE_TRANSACTION_STATUS,
    'analytics.future-txn-status',
    t
  )
}

const buildTxnSearchTypeOptions = (t) => {
  return buildDropdownList(
    TRANSACTION_SEARCH_FILTERS,
    'transactions.columns',
    t
  )
}

const isRefundActionAvailable = (
  { brandReferenceId, status, token, loyaltyEventType },
  currentBrandId
) => {
  return (
    currentBrandId !== CUSTOM_BRAND_ID.MILES_AND_MORE &&
    loyaltyEventType !== LOYALTY_EVENT_TYPES.REVERT_EXCHANGE &&
    brandReferenceId &&
    status === TRANSACTION_STATUS.SUCCESS &&
    token?.brandId === CUSTOM_BRAND_ID.MILES_AND_MORE
  )
}

const defaultTxnColumns = () => {
  const storageColumns = getTxnColumns()
  return Object.keys(storageColumns).length
    ? storageColumns
    : txnColumnListToObj(Object.values(DEFAULT_TXN_TABLE_FIELDS))
}

const txnColumnListToObj = (data) => {
  return data.reduce((map, current) => {
    map[current] = true
    return map
  }, {})
}

const buildSortedColumns = (data) => {
  const sortedKeys = Object.values(TRANSACTIONS_TABLE_FIELDS)
  const dataKeys = Object.keys(data)
  dataKeys.sort((a, b) => {
    const indexA = sortedKeys.indexOf(a)
    const indexB = sortedKeys.indexOf(b)
    return indexA - indexB
  })
  return dataKeys
}

const buildTxnSearchParams = (state, { token_id, token_to_id }) => {
  const { type, query } = extractTransactionSearchProfile(state)
  if (!type || !query) {
    return {} // Return empty object if type or query is missing
  }
  switch (type) {
    case TRANSACTION_SEARCH_FILTERS.EMAIL:
      if (token_id) {
        return { email_like: query }
      } else if (token_to_id) {
        return { email_to_like: query }
      } else {
        return { email_like: query, email_to_like: query }
      }
    case TRANSACTION_SEARCH_FILTERS.AUTH:
      if (token_id) {
        return { user_auth_id: query }
      } else if (token_to_id) {
        return { user_to_auth_id: query }
      } else {
        return { user_auth_id: query, user_to_auth_id: query }
      }

    case TRANSACTION_SEARCH_FILTERS.HASH:
      return { tx_hash: query }

    case TRANSACTION_SEARCH_FILTERS.BRAND_REF:
      if (token_id) {
        return { brand_reference_id: query }
      } else if (token_to_id) {
        return { brand_to_reference_id: query }
      } else {
        return { brand_reference_id: query, brand_to_reference_id: query }
      }

    case TRANSACTION_SEARCH_FILTERS.MM_ACCOUNT:
      if (token_id) {
        return { membership_number_from_like: query }
      } else if (token_to_id) {
        return { membership_number_to_like: query }
      } else {
        return {
          membership_number_from_like: query,
          membership_number_to_like: query
        }
      }
    default:
      return {}
  }
}

export {
  handleGetFilterTypeForTransactions,
  buildTxnCSVTableHeaders,
  RECENT_TXN_COLUMN_FIELDS,
  buildTxnTableHeaders,
  buildTransactionsTableHeaders,
  getTransactionUser,
  buildTxnStatusColorIcon,
  getLastCurrentWeekDateRanges,
  buildDefaultParamsWithFilters,
  getBrandIdTokenIdAnalyticsFilters,
  buildMemberTransactionTypeIconColor,
  buildEventGroup,
  isEventTypeRevertExchange,
  isEventTypeNftTransfer,
  isEventTypeNftSale,
  buildUpdateTxnStatusParams,
  buildTxnStatusOptions,
  buildTxnSearchTypeOptions,
  isRefundActionAvailable,
  defaultTxnColumns,
  txnColumnListToObj,
  buildSortedColumns,
  buildTxnSearchParams
}
