import { combineReducers } from 'redux'

import { mainProfile } from './main'
import { downloadProfile } from './download'
import { billingProfile } from '../main/billing'
import { fileUploadProfile } from './fileUpload'

export const mainReducer = combineReducers({
  mainProfile,
  downloadProfile,
  billingProfile,
  fileUploadProfile
})
