import React, { useState, useEffect, lazy, useCallback } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { bindActionCreators } from 'redux'
import { useParams } from 'react-router-dom'
import { faHexagonVerticalNftSlanted } from '@fortawesome/pro-light-svg-icons'

import Exchange from '../components/exchange/Exchange'
import { updateUserAccount } from '../store/actions/account'
import {
  changeExchangeSettingsState,
  saveExchangeSettings,
  changePartnersState,
  connectExchangePartner,
  changeExchangeFeedback,
  resetExchangeFeedback,
  updateExchangeConnection,
  getPotentialPartners,
  getExchangeWhitelist,
  changeExchangeConfig,
  resetExchangeConfig,
  resetExchangeFilters,
  changeBuyNftState,
  buyNft,
  changeExchangeTermsState,
  changeExchangeTermsFormDataState,
  submitExchangeTermsAndConnectExchange,
  resetExchangeTermsState,
  bulkConnectExchangePartners
} from '../store/actions/exchange'
import { buildMetadataAttributes } from '../util/account.helpers'
import { isDashboardReady } from '../util/dashboard.helpers'
import { EXCHANGE_SETTINGS_DISPATCH } from '../store/reducers/exchange/exchangeSettings'
import {
  checkValidations,
  exceedLimitRule,
  lowerOrEqualRule,
  requiredRule,
  validUrlRule
} from '../util/validation.helpers'
import { getTokens } from '../store/actions/token'
import {
  DEFAULT_CONTAINER_STATE,
  EXCHANGE_MARKETPLACE_PROCESS_STEP,
  PACKAGE_BILLING_PROCESS_STEP
} from '../constants/containerStates'
import {
  NFT_URL,
  REQUEST_FEATURE_URL,
  SETUP_URL
} from '../constants/navigation'
import { EXCHANGE_PARTNER_DISPATCH } from '../store/reducers/exchange/partners'
import {
  CANCEL_PARTNER_FEEDBACK_OPTIONS,
  EXCHANGE_CONNECTION_ACTION,
  EXCHANGE_CONNECTION_TYPES,
  EXCHANGE_FEEDBACK_FROM_OPTIONS,
  EXCHANGE_FEEDBACK_TO_OPTIONS,
  EXCHANGE_PARTNER_TYPES,
  EXCHANGE_REQUEST_TYPES,
  EXCHANGE_WHITELIST_STATUS,
  REJECT_PARTNER_FEEDBACK_OPTIONS
} from '../constants/exchange'
import {
  buildDataForMaxValueUpdate,
  buildExchangeUrl,
  checkOtherExistsInReasons,
  fromExchangeTabIdToType,
  getFromToLabel,
  getMaxThresholdValue
} from '../util/exchange.helpers'
import { EXCHANGE_FEEDBACK_DISPATCH } from '../store/reducers/exchange/exchangeFeedback'
import { openExternalUrl } from '../util/app/app.helpers'
import {
  containsValues,
  deepEqualObjects,
  containsNoValues
} from '../util/object.helpers'
import Text from '../components/shared/text/Text'
import { isTokenStatusActive, isTokenTypeNft } from '../util/token.helpers'
import { EXCHANGE_CONFIG_DISPATCH } from '../store/reducers/exchange/exchangeConfig'
import { formatCurrencyValue } from '../util/number.helpers'
import {
  getUserSurveys,
  submitUserSurvey,
  updateUserSurvey,
  getBrandSurveys,
  getListUserSurveys,
  changeSurveyState
} from '../store/actions/survey'
import FaIcon from '../components/shared/FaIcon/FaIcon'
import ConfirmBuyNftPopup from '../components/popups/exchange/ConfirmBuyNft'
import { EXCHANGE_BUY_NFT_ACTION_DISPATCH } from '../store/reducers/exchange/buyNft'
import { changeLoveBrandFiltersState } from '../store/actions/extensions'
import ExchangeNftDetailsPopup from '../components/popups/exchange/ExchangeNftDetails'
import SomethingWentWrong from '../components/popups/common/SomethingWentWrong'
import AcceptExchangeTermsPopup from '../components/popups/exchange/AcceptExchangeTerms'
import { EXCHANGE_TERMS_PROFILE_DISPATCH } from '../store/reducers/exchange/exchangeTerms'
import {
  buildCheckValidationRules,
  buildMarketplaceSurvey,
  buildResponseForExchangeTerms,
  extractSurveyQuestions
} from '../util/survey.helpers'
import i18n from '../i18n'
import { SURVEY_PROFILE_DISPATCH } from '../store/reducers/survey/survey'
import ExchangePartnerDetailsPopup from '../components/popups/exchange/ExchangePartnerDetails'

const ConfirmedPopup = lazy(() =>
  import('../components/popups/common/Confirmed')
)
const ConfirmationPopup = lazy(() =>
  import('../components/popups/common/Confirmation')
)
const MissingExchangeSettingsPopup = lazy(() =>
  import('../components/popups/exchange/MissingExchangeSettings')
)
const RemovalFeedbackPopup = lazy(() =>
  import('../components/popups/exchange/RemovalFeedback')
)
const NoPackageSubscriptionPopup = lazy(() =>
  import('../components/popups/billing/NoPackageSubscription')
)

const ExchangeContainer = ({
  exchangeSettingsProfile,
  updateUserAccount,
  changeExchangeSettingsState,
  saveExchangeSettings,
  getTokens,
  changePartnersState,
  partnersProfile,
  history,
  location,
  tokenProfile,
  connectExchangePartner,
  dashboardSetupState,
  exchangeFeedbackProfile,
  changeExchangeFeedback,
  resetExchangeFeedback,
  updateExchangeConnection,
  getPotentialPartners,
  getExchangeWhitelist,
  changeExchangeConfig,
  exchangeConfigProfile,
  resetExchangeConfig,
  mainProfile: { dashboardConfigs, exchangePartnersStats },
  resetExchangeFilters,
  getUserSurveys,
  getBrandSurveys,
  surveyProfile,
  submitUserSurvey,
  updateUserSurvey,
  changeBuyNftState,
  buyNftProfile,
  buyNft,
  loveBrandsFiltersProfile,
  changeLoveBrandFiltersState,
  exchangeTermsProfile,
  changeExchangeTermsState,
  changeExchangeTermsFormDataState,
  submitExchangeTermsAndConnectExchange,
  resetExchangeTermsState,
  getListUserSurveys,
  changeSurveyState,
  bulkConnectExchangePartners,
  ...restProps
}) => {
  const isDashboardSetupReady = isDashboardReady(dashboardSetupState)

  useEffect(() => {
    getTokens()
    resetExchangeFilters()
    return () => {
      resetErrorSuccess()
      handleNoPopup()
    }
  }, [])

  const [exchangeIndex, setExchangeIndex] = useState({})
  const [bulkPartnersIds, setBulkPartnerIds] = useState({})

  const { state } = location
  useEffect(() => {
    if (state?.requestsRouteStateIsTo) {
      changePartnersState(
        selectedTab,
        EXCHANGE_PARTNER_DISPATCH.REQUEST_TYPE,
        EXCHANGE_REQUEST_TYPES.REQUESTS_YOU_RECEIVED
      )
    }
  }, [state?.requestsRouteStateIsTo])

  const { t } = useTranslation()

  const { selectedTab } = useParams()

  const handleChangeTab = (event, selectedTabId) => {
    const newTab = fromExchangeTabIdToType(selectedTabId)
    if (selectedTab !== newTab) {
      setSelectedTab(newTab)
    }
  }

  const setSelectedTab = (selectedTab) => {
    history.push(buildExchangeUrl(selectedTab))
  }

  const [popupState, setPopupState] = useState(DEFAULT_CONTAINER_STATE.NONE)
  const [selectedPartner, setSelectedPartner] = useState(null)

  useEffect(() => {
    const action = partnersProfile.exchangeConnectionAction
    if (action) {
      buildSuccessPopup(action)
    } else {
      setPopupState('')
    }
  }, [partnersProfile.exchangeConnectionAction])

  useEffect(() => {
    const action = buyNftProfile.popupState
    if (action) {
      setPopupState(action)
    }
  }, [buyNftProfile.popupState])

  useEffect(() => {
    getPotentialPartners()
    getExchangeWhitelist()
    getUserSurveys()
    getBrandSurveys()
  }, [])

  useEffect(() => {
    if (!selectedTab) {
      setSelectedTab(EXCHANGE_PARTNER_TYPES.POTENTIAL)
    }
  }, [selectedTab])

  const handleSelectBulkPartners = (partner) => {
    const id = partner.id
    const isChecked = bulkPartnersIds[id]
    setBulkPartnerIds((prevState) => {
      const state = {
        ...prevState,
        [id]: !isChecked
      }
      if (!isChecked === false) {
        delete state[id]
      }
      return state
    })
  }

  const handleClearBulkPartners = () => {
    setBulkPartnerIds({})
  }

  const handleConnectBulkPartners = () => {
    handleConnectExchangePartner()
  }

  const handleCloseImportantNote = () => {
    updateUserAccount(buildMetadataAttributes({ hide_exchange_note: true }))
  }

  const buildSuccessPopup = (action) => {
    switch (action) {
      case EXCHANGE_CONNECTION_ACTION.SENT_REQUEST:
        handleClearBulkPartners()
        setPopupState(EXCHANGE_MARKETPLACE_PROCESS_STEP.EXCHANGE_REQUEST_SENT)
        setSelectedTab(EXCHANGE_PARTNER_TYPES.REQUESTS)
        changePartnersState(
          EXCHANGE_PARTNER_TYPES.REQUESTS,
          EXCHANGE_PARTNER_DISPATCH.REQUEST_TYPE,
          EXCHANGE_REQUEST_TYPES.REQUESTS_YOU_SENT
        )
        break

      case EXCHANGE_CONNECTION_ACTION.REMOVE_FROM:
      case EXCHANGE_CONNECTION_ACTION.REMOVE_TO:
        setPopupState(EXCHANGE_MARKETPLACE_PROCESS_STEP.REMOVE_EXCHANGE_SUCCESS)
        break

      case EXCHANGE_CONNECTION_ACTION.CANCEL:
        setPopupState(EXCHANGE_MARKETPLACE_PROCESS_STEP.CANCEL_EXCHANGE_SUCCESS)
        break

      case EXCHANGE_CONNECTION_ACTION.ACCEPT:
        setPopupState(EXCHANGE_MARKETPLACE_PROCESS_STEP.ACCEPT_EXCHANGE_SUCCESS)
        setSelectedTab(EXCHANGE_PARTNER_TYPES.ACTIVE)
        changePartnersState(
          EXCHANGE_PARTNER_TYPES.ACTIVE,
          EXCHANGE_PARTNER_DISPATCH.CONNECTION_TYPE,
          EXCHANGE_CONNECTION_TYPES.CONNECTED_TO_YOU
        )
        break

      case EXCHANGE_CONNECTION_ACTION.REJECT:
        setPopupState(EXCHANGE_MARKETPLACE_PROCESS_STEP.REJECT_EXCHANGE_SUCCESS)
        break

      case EXCHANGE_CONNECTION_ACTION.ERROR_REJECTED:
        setPopupState(EXCHANGE_MARKETPLACE_PROCESS_STEP.ERROR_REJECTED_EXCHANGE)
        break

      case EXCHANGE_CONNECTION_ACTION.ERROR_DISCONNECTED:
        setPopupState(
          EXCHANGE_MARKETPLACE_PROCESS_STEP.ERROR_DISCONNECTED_EXCHANGE
        )
        break

      default:
        break
    }
  }

  const handleExchangeSettingsSubmit = () => {
    resetErrorSuccess()
    const {
      exchangeValueTemp,
      programUrlTemp,
      catalogueUrlTemp,
      loginIdTypeTemp,
      brandRequirementsTemp,
      additionalServicesTemp,
      exchangeValue,
      programUrl,
      catalogueUrl,
      loginIdType,
      brandRequirements,
      additionalServices
    } = exchangeSettingsProfile

    const settingsErrors = checkValidations({
      programUrlTemp: {
        value: programUrlTemp,
        rules: [requiredRule, validUrlRule]
      },
      loginIdTypeTemp: { value: loginIdTypeTemp, rules: [requiredRule] },
      catalogueUrlTemp: { value: catalogueUrlTemp, rules: [validUrlRule] }
    })
    if (containsValues(settingsErrors)) {
      changeExchangeSettingsState(
        EXCHANGE_SETTINGS_DISPATCH.ERROR,
        settingsErrors
      )
    } else {
      const isExchangeSettingsChanged = !deepEqualObjects(
        {
          exchangeValueTemp,
          programUrlTemp,
          catalogueUrlTemp,
          loginIdTypeTemp,
          brandRequirementsTemp,
          additionalServicesTemp
        },
        {
          exchangeValueTemp: exchangeValue,
          programUrlTemp: programUrl,
          catalogueUrlTemp: catalogueUrl,
          loginIdTypeTemp: loginIdType,
          brandRequirementsTemp: brandRequirements,
          additionalServicesTemp: additionalServices
        }
      )
      if (isExchangeSettingsChanged) {
        saveExchangeSettings()
      }
    }
  }

  const resetErrorSuccess = () => {
    changeExchangeSettingsState(EXCHANGE_SETTINGS_DISPATCH.ERROR, {})
    changeExchangeSettingsState(EXCHANGE_SETTINGS_DISPATCH.SUCCESS, {})
  }

  const handleClickListPoint = () => {
    setPopupState(EXCHANGE_MARKETPLACE_PROCESS_STEP.LIST_YOUR_POINT_POPUP)
  }

  const handleClickSuggestFavExchangeOption = () => {
    history.push(REQUEST_FEATURE_URL)
  }

  const handleOnCancelPopup = () => {
    switch (popupState) {
      case EXCHANGE_MARKETPLACE_PROCESS_STEP.CONFIRM_NFT_PURCHASE:
        handleNoPopup()
        break

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.ACCEPT_EXCHANGE_TERMS:
        resetExchangeTermsState()
        handleNoPopup()
        break

      default:
        handleNoPopup()
        break
    }
  }

  const handleNoPopup = () => {
    setPopupState(DEFAULT_CONTAINER_STATE.NONE)
    changePartnersState(
      selectedTab,
      EXCHANGE_PARTNER_DISPATCH.EXCHANGE_CONNECTION_ACTION,
      DEFAULT_CONTAINER_STATE.NONE
    )
    changeBuyNftState(
      EXCHANGE_BUY_NFT_ACTION_DISPATCH.POPUP_STATE,
      DEFAULT_CONTAINER_STATE.NONE
    )
  }

  const handleOnSubmitPopup = () => {
    switch (popupState) {
      case EXCHANGE_MARKETPLACE_PROCESS_STEP.LIST_YOUR_POINT_POPUP:
      case EXCHANGE_MARKETPLACE_PROCESS_STEP.EXCHANGE_SETUP_DASHBOARD:
        history.push(SETUP_URL)
        break

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.EXCHANGE_REQUEST_SENT:
      case EXCHANGE_MARKETPLACE_PROCESS_STEP.REMOVE_EXCHANGE_SUCCESS:
      case EXCHANGE_MARKETPLACE_PROCESS_STEP.REJECT_EXCHANGE_SUCCESS:
      case EXCHANGE_MARKETPLACE_PROCESS_STEP.ACCEPT_EXCHANGE_SUCCESS:
      case EXCHANGE_MARKETPLACE_PROCESS_STEP.CANCEL_EXCHANGE_SUCCESS:
      case EXCHANGE_MARKETPLACE_PROCESS_STEP.ERROR_REJECTED_EXCHANGE:
      case EXCHANGE_MARKETPLACE_PROCESS_STEP.ERROR_DISCONNECTED_EXCHANGE:
      case EXCHANGE_MARKETPLACE_PROCESS_STEP.VIEW_RANK_INFO:
        handleNoPopup()
        break

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.EXCHANGE_CONNECTOR_DETAILS:
        handleExchangePartnerDetailsSubmit()
        break

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.ACCEPT_EXCHANGE_TERMS:
        const tokenId = selectedPartner?.id
        if (tokenId) {
          changeExchangeTermsState(EXCHANGE_TERMS_PROFILE_DISPATCH.ERROR, {})
          const surveyId = buildMarketplaceSurvey(selectedPartner)
          const surveyQuestions = extractSurveyQuestions(
            surveyProfile.surveys[surveyId],
            i18n.language
          )?.questions
          const errors = checkValidations(
            buildCheckValidationRules(
              surveyQuestions,
              exchangeTermsProfile.formData
            )
          )
          if (containsNoValues(errors)) {
            submitExchangeTermsAndConnectExchange(
              surveyId,
              buildResponseForExchangeTerms(
                surveyQuestions,
                exchangeTermsProfile.formData
              ),
              tokenProfile.id,
              tokenId
            )
          } else {
            changeExchangeTermsState(
              EXCHANGE_TERMS_PROFILE_DISPATCH.ERROR,
              errors
            )
          }
        }
        break

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.MISSING_EXCHANGE_SETTINGS:
        handleNoPopup()
        setSelectedTab(EXCHANGE_PARTNER_TYPES.SETTINGS)
        break

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.REMOVE_EXCHANGE_REQUEST:
        resetExchangeFeedback()
        setPopupState(
          EXCHANGE_MARKETPLACE_PROCESS_STEP.REMOVE_EXCHANGE_FEEDBACK
        )
        break

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.CANCEL_EXCHANGE_REQUEST:
        resetExchangeFeedback()
        setPopupState(
          EXCHANGE_MARKETPLACE_PROCESS_STEP.CANCEL_EXCHANGE_FEEDBACK
        )
        break

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.REJECT_EXCHANGE_REQUEST:
        resetExchangeFeedback()
        setPopupState(
          EXCHANGE_MARKETPLACE_PROCESS_STEP.REJECT_EXCHANGE_FEEDBACK
        )
        break

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.REMOVE_EXCHANGE_FEEDBACK:
        if (selectedPartner?.isFrom) {
          handleUpdateExchangeFlow(
            EXCHANGE_CONNECTION_ACTION.REMOVE_FROM,
            EXCHANGE_WHITELIST_STATUS.CANCEL
          )
        } else {
          handleUpdateExchangeFlow(
            EXCHANGE_CONNECTION_ACTION.REMOVE_TO,
            EXCHANGE_WHITELIST_STATUS.STOP
          )
        }
        break

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.CANCEL_EXCHANGE_FEEDBACK:
        handleUpdateExchangeFlow(
          EXCHANGE_CONNECTION_ACTION.CANCEL,
          EXCHANGE_WHITELIST_STATUS.CANCEL
        )
        break

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.REJECT_EXCHANGE_FEEDBACK:
        handleUpdateExchangeFlow(
          EXCHANGE_CONNECTION_ACTION.REJECT,
          EXCHANGE_WHITELIST_STATUS.REJECT
        )
        break

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.NFT_DETAILS:
        handleBuyNft()
        break

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.CONFIRM_NFT_PURCHASE:
        changeBuyNftState(EXCHANGE_BUY_NFT_ACTION_DISPATCH.ERROR, {})
        const { amountToPurchase } = buyNftProfile
        const buyNftErrors = checkValidations({
          amountToPurchase: {
            value: amountToPurchase,
            rules: [
              requiredRule,
              lowerOrEqualRule(selectedPartner?.availableSupply || 0)
            ]
          }
        })
        if (containsValues(buyNftErrors)) {
          changeBuyNftState(
            EXCHANGE_BUY_NFT_ACTION_DISPATCH.ERROR,
            buyNftErrors
          )
        } else {
          buyNft(selectedPartner, amountToPurchase)
        }
        break

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.CONFIRMED_NFT_PURCHASE:
        history.push(NFT_URL)
        break

      case DEFAULT_CONTAINER_STATE.SOMETHING_WENT_WRONG:
        handleNoPopup()
        break

      default:
        break
    }
  }

  const handleUpdateExchangeFlow = (action, status) => {
    const { reasons, feedback } = exchangeFeedbackProfile
    const errors = checkValidations({
      reasons: {
        value: reasons,
        rules: [requiredRule]
      },
      ...(checkOtherExistsInReasons(reasons) && {
        feedback: {
          value: feedback,
          rules: [requiredRule]
        }
      })
    })
    if (containsNoValues(errors)) {
      updateExchangeConnection(
        action,
        { reason: reasons.map((reason) => reason.value), feedback, status },
        selectedPartner
      )
    } else {
      changeExchangeFeedback(EXCHANGE_FEEDBACK_DISPATCH.ERROR, errors)
    }
  }

  const handleExchangeAction = useCallback(
    (partner, action) => {
      if (
        (action === EXCHANGE_MARKETPLACE_PROCESS_STEP.REJECT_EXCHANGE_REQUEST &&
          !isDashboardSetupReady) ||
        !isTokenStatusActive(tokenProfile)
      ) {
        noPackageSubscribedPopupState()
      } else {
        setSelectedPartner(partner)
        setPopupState(action)
      }
    },
    [selectedPartner, isDashboardSetupReady]
  )

  const handleAcceptRequest = useCallback(
    (partner) => {
      if (isDashboardSetupReady && isTokenStatusActive(tokenProfile)) {
        // @TODO: Need to enable this when rewards marketplace settings is ready
        // const { programUrl, loginIdType } = exchangeSettingsProfile
        // if (!programUrl && !loginIdType) {
        //   setPopupState(
        //     EXCHANGE_MARKETPLACE_PROCESS_STEP.MISSING_EXCHANGE_SETTINGS
        //   )
        // } else {

        updateExchangeConnection(
          EXCHANGE_CONNECTION_ACTION.ACCEPT,
          { status: EXCHANGE_WHITELIST_STATUS.ACCEPT },
          partner
        )
        //}
      } else {
        noPackageSubscribedPopupState()
      }
    },
    [
      selectedPartner,
      exchangeSettingsProfile,
      isDashboardSetupReady,
      tokenProfile
    ]
  )

  const noPackageSubscribedPopupState = () => {
    setPopupState(PACKAGE_BILLING_PROCESS_STEP.NO_PACKAGE_SUBSCRIBED)
  }

  const handleDetailsClick = useCallback((partner) => {
    setSelectedPartner(partner)
    if (isTokenTypeNft(partner.type)) {
      setPopupState(EXCHANGE_MARKETPLACE_PROCESS_STEP.NFT_DETAILS)
    } else {
      changeSurveyState(SURVEY_PROFILE_DISPATCH.SELLER_EXCHANGE_TERMS, null)
      if (partner.tokenExchangeWhitelist.toTokenId === tokenProfile.id) {
        getListUserSurveys(partner)
      }
      setPopupState(
        EXCHANGE_MARKETPLACE_PROCESS_STEP.EXCHANGE_CONNECTOR_DETAILS
      )
      const maxValue = partner?.isFrom
        ? partner?.tokenExchangeWhitelist?.fromTokenMaxUsd
        : partner?.tokenExchangeWhitelist?.toTokenMaxUsd
      resetExchangeConfig()
      if (maxValue) {
        const value = formatCurrencyValue(maxValue)
        changeExchangeConfig(EXCHANGE_CONFIG_DISPATCH.MAX_VALUE, value)
        changeExchangeConfig(EXCHANGE_CONFIG_DISPATCH.MAX_VALUE_TEMP, value)
      }
    }
  }, [])

  const handleWebsiteClick = useCallback((selectedPartner) => {
    const websiteUrl =
      selectedPartner?.metadata?.links?.websiteUrl ||
      selectedPartner?.brand?.brandConfig?.websiteUrl
    if (websiteUrl) {
      openExternalUrl(websiteUrl)
    }
  }, [])

  const handleConnectClick = useCallback(
    (partner) => {
      setSelectedPartner(partner)
      handleConnectExchangePartner(partner)
    },
    [isDashboardSetupReady, exchangeSettingsProfile]
  )

  const handleConnectExchangePartner = (partner) => {
    setPopupState(buildConnectExchangePopupType(partner))
  }

  const buildConnectExchangePopupType = (partner) => {
    // const { programUrl, loginIdType } = exchangeSettingsProfile
    let popupType
    if (!isDashboardSetupReady) {
      popupType = EXCHANGE_MARKETPLACE_PROCESS_STEP.EXCHANGE_SETUP_DASHBOARD
      // @TODO: Need to enable this when rewards marketplace settings is ready
      // } else if (!programUrl && !loginIdType) {
      // popupType = EXCHANGE_MARKETPLACE_PROCESS_STEP.MISSING_EXCHANGE_SETTINGS
    } else if (!isTokenStatusActive(tokenProfile)) {
      popupType = PACKAGE_BILLING_PROCESS_STEP.NO_PACKAGE_SUBSCRIBED
      // @TODO: Need to enable this when rewards marketplace settings is ready
      // } else if (!programUrl && !loginIdType) {
      // popupType = EXCHANGE_MARKETPLACE_PROCESS_STEP.MISSING_EXCHANGE_SETTINGS
    } else {
      // @TODO uncomment the popuptype for send request popup and remove directly connecting if statement
      const partnerIds = Object.keys(bulkPartnersIds)
      if (partnerIds.length) {
        bulkConnectExchangePartners(partnerIds)
      } else {
        if (partner?.id) {
          if (surveyProfile.surveys[buildMarketplaceSurvey(partner)]) {
            popupType = EXCHANGE_MARKETPLACE_PROCESS_STEP.ACCEPT_EXCHANGE_TERMS
          } else {
            connectExchangePartner(tokenProfile.id, partner.id)
          }
        }
      }
    }
    return popupType
  }

  const handleExchangePartnerDetailsSubmit = () => {
    let popupType
    switch (selectedTab) {
      case EXCHANGE_PARTNER_TYPES.POTENTIAL:
        popupType = buildConnectExchangePopupType(selectedPartner)
        break
      case EXCHANGE_PARTNER_TYPES.ACTIVE:
        popupType = EXCHANGE_MARKETPLACE_PROCESS_STEP.REMOVE_EXCHANGE_REQUEST
        break
      case EXCHANGE_PARTNER_TYPES.REQUESTS:
        popupType = selectedPartner?.isFrom
          ? EXCHANGE_MARKETPLACE_PROCESS_STEP.CANCEL_EXCHANGE_REQUEST
          : EXCHANGE_MARKETPLACE_PROCESS_STEP.REJECT_EXCHANGE_REQUEST
        break
    }
    if (popupType) {
      setPopupState(popupType)
    }
  }

  const handleChangeFilters = (property, value) => {
    changePartnersState(selectedTab, property, value)
  }

  const handleViewRankInfo = () => {
    setPopupState(EXCHANGE_MARKETPLACE_PROCESS_STEP.VIEW_RANK_INFO)
  }

  const handleBuyNft = (partner) => {
    if (!isDashboardSetupReady) {
      setPopupState(EXCHANGE_MARKETPLACE_PROCESS_STEP.EXCHANGE_SETUP_DASHBOARD)
    } else if (!isTokenStatusActive(tokenProfile)) {
      setPopupState(PACKAGE_BILLING_PROCESS_STEP.NO_PACKAGE_SUBSCRIBED)
    } else {
      if (partner) {
        setSelectedPartner(partner)
      }
      changeBuyNftState(EXCHANGE_BUY_NFT_ACTION_DISPATCH.ERROR, {})
      changeBuyNftState(EXCHANGE_BUY_NFT_ACTION_DISPATCH.AMOUNT_TO_PURCHASE, '')
      setPopupState(EXCHANGE_MARKETPLACE_PROCESS_STEP.CONFIRM_NFT_PURCHASE)
    }
  }

  const handleExchangeConfigSubmit = () => {
    changeExchangeConfig(EXCHANGE_CONFIG_DISPATCH.ERROR, {})
    const maxThresholdValue = getMaxThresholdValue(
      selectedPartner,
      dashboardConfigs
    )
    const { maxValue, maxValueTemp } = exchangeConfigProfile
    const errors = checkValidations({
      maxValueTemp: {
        value: maxValueTemp,
        rules: [requiredRule, exceedLimitRule(maxThresholdValue)]
      }
    })
    if (containsNoValues(errors)) {
      if (maxValue !== maxValueTemp) {
        updateExchangeConnection(
          EXCHANGE_CONNECTION_ACTION.CHANGE_MAX_LIMIT,
          buildDataForMaxValueUpdate(
            selectedPartner?.isFrom,
            Number(maxValueTemp)
          ),
          selectedPartner
        )
      }
    } else {
      changeExchangeConfig(EXCHANGE_CONFIG_DISPATCH.ERROR, errors)
    }
  }

  const renderPopup = () => {
    switch (popupState) {
      case EXCHANGE_MARKETPLACE_PROCESS_STEP.LIST_YOUR_POINT_POPUP:
        return (
          <ConfirmationPopup
            t={t}
            onClose={handleOnCancelPopup}
            onSubmit={handleOnSubmitPopup}
            title='exchange.list-your-own-reward'
            description='exchange.list-own-reward-desc'
            submitLabel='exchange.set-up-dashboard'
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.EXCHANGE_CONNECTOR_DETAILS:
        const surveyId = buildMarketplaceSurvey(selectedPartner)
        const exchangeSurveyParams = surveyProfile.sellerExchangeTerms
          ? {
              exchangeTerms: surveyProfile.sellerExchangeTerms.exchangeTerms,
              brandSurvey: surveyProfile.sellerExchangeTerms.brandSurvey
            }
          : {
              exchangeTerms: surveyProfile.surveys[surveyId],
              brandSurvey: surveyProfile.brandSurveys[surveyId]
            }
        return (
          <ExchangePartnerDetailsPopup
            t={t}
            partner={selectedPartner}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            onWebsiteClick={handleWebsiteClick}
            tokenProfile={tokenProfile}
            selectedTab={selectedTab}
            onAcceptRequest={handleAcceptRequest}
            onExchangeConfigSubmit={handleExchangeConfigSubmit}
            changeExchangeConfig={changeExchangeConfig}
            exchangeConfigProfile={exchangeConfigProfile}
            dashboardConfigs={dashboardConfigs}
            partnersProfile={partnersProfile}
            showRewardConditions={surveyProfile.sellerExchangeTerms}
            isSellerExchangeTermsProcessing={surveyProfile.isProcessing}
            exchangePartnersStats={exchangePartnersStats}
            {...exchangeSurveyParams}
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.NFT_DETAILS:
        return (
          <ExchangeNftDetailsPopup
            t={t}
            nft={selectedPartner}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            tokenProfile={tokenProfile}
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.ACCEPT_EXCHANGE_TERMS:
        return (
          <AcceptExchangeTermsPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            isProcessing={partnersProfile.isProcessing}
            exchangeTerms={
              surveyProfile.surveys[buildMarketplaceSurvey(selectedPartner)]
            }
            dispatchAction={changeExchangeTermsFormDataState}
            exchangeTermsProfile={exchangeTermsProfile}
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.EXCHANGE_REQUEST_SENT:
        return (
          <ConfirmedPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            title='exchange.request-sent'
            description='exchange.request-sent-desc'
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.EXCHANGE_SETUP_DASHBOARD:
        return (
          <ConfirmationPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            title='exchange.set-up-your-dashboard'
            description='exchange.set-up-your-dashboard-desc'
            submitLabel='developers.go-to-setup'
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.MISSING_EXCHANGE_SETTINGS:
        return (
          <MissingExchangeSettingsPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.REMOVE_EXCHANGE_REQUEST:
        return (
          <ConfirmationPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            title='exchange.remove-exchange-partner'
            description={`exchange.remove-exchange-partner-desc-${getFromToLabel(
              selectedPartner?.isFrom
            )}`}
            cancelLabel='common.cancel'
            submitLabel='exchange.remove'
            submitProps={{ color: 'error', variant: 'outlined' }}
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.REMOVE_EXCHANGE_FEEDBACK:
        return (
          <RemovalFeedbackPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            exchangeFeedbackProfile={exchangeFeedbackProfile}
            dispatchAction={changeExchangeFeedback}
            submitLabel='feedback.submit-remove-partner'
            options={
              selectedPartner?.isFrom
                ? EXCHANGE_FEEDBACK_FROM_OPTIONS
                : EXCHANGE_FEEDBACK_TO_OPTIONS
            }
            isProcessing={partnersProfile.isProcessing}
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.REMOVE_EXCHANGE_SUCCESS:
        return (
          <ConfirmedPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            description={`feedback.exchange-partner-removed-desc-${getFromToLabel(
              selectedPartner?.isFrom
            )}`}
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.ACCEPT_EXCHANGE_SUCCESS:
        return (
          <ConfirmedPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            title='common.congratulations'
            description='exchange.approved-exchange-request'
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.REJECT_EXCHANGE_REQUEST:
        return (
          <ConfirmationPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            title='exchange.reject-exchange-partner'
            description='exchange.reject-exchange-partner-desc'
            cancelLabel='common.cancel'
            submitLabel='exchange.reject-request'
            submitProps={{ color: 'error', variant: 'outlined' }}
            isProcessing={partnersProfile.isProcessing}
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.REJECT_EXCHANGE_FEEDBACK:
        return (
          <RemovalFeedbackPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            exchangeFeedbackProfile={exchangeFeedbackProfile}
            dispatchAction={changeExchangeFeedback}
            description='feedback.reject-request-partner-desc'
            options={REJECT_PARTNER_FEEDBACK_OPTIONS}
            isProcessing={partnersProfile.isProcessing}
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.REJECT_EXCHANGE_SUCCESS:
        return (
          <ConfirmedPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            description='exchange.reject-partner-confirmation'
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.CANCEL_EXCHANGE_REQUEST:
        return (
          <ConfirmationPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            title='exchange.cancel-exchange-partner-request'
            description='exchange.cancel-exchange-partner-request-desc'
            cancelLabel='common.cancel'
            submitLabel='exchange.cancel-request'
            submitProps={{ color: 'error', variant: 'outlined' }}
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.CANCEL_EXCHANGE_FEEDBACK:
        return (
          <RemovalFeedbackPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            exchangeFeedbackProfile={exchangeFeedbackProfile}
            dispatchAction={changeExchangeFeedback}
            description='feedback.cancel-request-partner-desc'
            options={CANCEL_PARTNER_FEEDBACK_OPTIONS}
            isProcessing={partnersProfile.isProcessing}
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.CANCEL_EXCHANGE_SUCCESS:
        return (
          <ConfirmedPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            description='exchange.reject-partner-confirmation'
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.ERROR_REJECTED_EXCHANGE:
        return (
          <ConfirmedPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            title='exchange.connect-partner.unable-to-request-partnership'
            description='exchange.connect-partner.rejected'
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.ERROR_DISCONNECTED_EXCHANGE:
        return (
          <ConfirmedPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            title='exchange.connect-partner.unable-to-request-partnership'
            description='exchange.connect-partner.disconnected'
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.VIEW_RANK_INFO:
        return (
          <ConfirmedPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            title='exchange.improve-your-ranking-title'
            description='exchange.improve-your-ranking-desc'
            additionalDesc={
              <Text
                variant='body1'
                color='textprimary'
                label={t('exchange.improve-your-ranking-add-desc')}
                sx={{ pl: 4, pr: 4, pt: 4 }}
              />
            }
          />
        )

      case PACKAGE_BILLING_PROCESS_STEP.NO_PACKAGE_SUBSCRIBED:
        return (
          <NoPackageSubscriptionPopup
            t={t}
            isDashboardSetupReady={isDashboardSetupReady}
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.CONFIRM_NFT_PURCHASE:
        return (
          <ConfirmBuyNftPopup
            t={t}
            buyNftProfile={buyNftProfile}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            nft={selectedPartner}
            dispatchAction={changeBuyNftState}
          />
        )

      case EXCHANGE_MARKETPLACE_PROCESS_STEP.CONFIRMED_NFT_PURCHASE:
        return (
          <ConfirmationPopup
            t={t}
            onSubmit={handleOnSubmitPopup}
            onClose={handleOnCancelPopup}
            title='common.congratulations'
            description='exchange.nft-purchased-desc'
            cancelLabel='common.close'
            submitLabel='exchange.go-to-nft-section'
            submitProps={{
              startIcon: <FaIcon icon={faHexagonVerticalNftSlanted} />
            }}
          />
        )

      case DEFAULT_CONTAINER_STATE.SOMETHING_WENT_WRONG:
        return <SomethingWentWrong t={t} onSubmit={handleOnSubmitPopup} />

      default:
        break
    }
  }

  const handleLikeUnlikeSurvey = (surveyId, questionId) => {
    const userSurvey = surveyProfile.userSurveys?.[surveyId]
    const userSurveyId = userSurvey?.id
    if (userSurveyId) {
      updateUserSurvey(
        surveyId,
        userSurveyId,
        questionId,
        !userSurvey.response?.[questionId]?.liked
      )
    } else {
      submitUserSurvey(surveyId, questionId, true)
    }
  }

  return (
    <>
      <Exchange
        onCloseImportantNote={handleCloseImportantNote}
        t={t}
        isDashboardSetupReady={isDashboardSetupReady}
        selectedTab={selectedTab}
        onChangeTab={handleChangeTab}
        onExchangeSettingsSubmit={handleExchangeSettingsSubmit}
        changeExchangeSettingsState={changeExchangeSettingsState}
        exchangeSettingsProfile={exchangeSettingsProfile}
        partnersProfile={partnersProfile}
        onClickListYourPoint={handleClickListPoint}
        onClickSuggestFavExchangeOption={handleClickSuggestFavExchangeOption}
        tokenProfile={tokenProfile}
        onDetailsClick={handleDetailsClick}
        onWebsiteClick={handleWebsiteClick}
        onConnectClick={handleConnectClick}
        onBuyNftClick={handleBuyNft}
        onExchangeAction={handleExchangeAction}
        onAcceptRequest={handleAcceptRequest}
        onChangeFilters={handleChangeFilters}
        onViewRankInfo={handleViewRankInfo}
        exchangePartnersStats={exchangePartnersStats}
        onLikeUnlikeSurvey={handleLikeUnlikeSurvey}
        surveyProfile={surveyProfile}
        exchangeIndex={exchangeIndex}
        setExchangeIndex={setExchangeIndex}
        onSelectBulkPartners={handleSelectBulkPartners}
        bulkPartnersIds={bulkPartnersIds}
        loveBrandDataProps={{
          loveBrandsFiltersProfile,
          dispatchAction: changeLoveBrandFiltersState
        }}
        onClearBulkPartners={handleClearBulkPartners}
        onConnectBulkPartners={handleConnectBulkPartners}
        {...restProps}
      />
      {renderPopup()}
    </>
  )
}

const mapStateToProps = ({
  dashboardReducer,
  accountReducer,
  exchangeReducer,
  tokensReducer,
  mainReducer,
  surveyReducer,
  extensionsReducer
}) => {
  const { dashboardSetupProfile } = dashboardReducer
  const {
    exchangeSettingsProfile,
    partnersProfile,
    exchangeFeedbackProfile,
    exchangeConfigProfile,
    buyNftProfile,
    exchangeTermsProfile
  } = exchangeReducer
  const { tokenProfile } = tokensReducer
  const { billingProfile } = mainReducer
  const { mainProfile } = mainReducer
  const { surveyProfile } = surveyReducer
  const { extensionsProfile, loveBrandsFiltersProfile } = extensionsReducer
  return {
    dashboardSetupState: dashboardSetupProfile.dashboardSetupState,
    hideExchangeNote: accountReducer.accountProfile.metadata?.hideExchangeNote,
    exchangeSettingsProfile,
    exchangeFeedbackProfile,
    exchangeConfigProfile,
    partnersProfile,
    tokenProfile,
    billingProfile,
    mainProfile,
    surveyProfile,
    extensionsProfile,
    buyNftProfile,
    loveBrandsFiltersProfile,
    exchangeTermsProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserAccount,
      changeExchangeSettingsState,
      saveExchangeSettings,
      getTokens,
      changePartnersState,
      connectExchangePartner,
      changeExchangeFeedback,
      resetExchangeFeedback,
      updateExchangeConnection,
      getPotentialPartners,
      getExchangeWhitelist,
      changeExchangeConfig,
      resetExchangeConfig,
      resetExchangeFilters,
      getUserSurveys,
      getBrandSurveys,
      submitUserSurvey,
      updateUserSurvey,
      changeBuyNftState,
      buyNft,
      changeLoveBrandFiltersState,
      changeExchangeTermsState,
      changeExchangeTermsFormDataState,
      submitExchangeTermsAndConnectExchange,
      resetExchangeTermsState,
      getListUserSurveys,
      changeSurveyState,
      bulkConnectExchangePartners
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeContainer)
