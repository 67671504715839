import React, { memo } from 'react'
import { Grid } from '@mui/material'

import AccountForm from '../shared/AccountForm/AccountForm'
import TokenDetails from '../shared/TokenDetails/TokenDetails'
import LoyaltyPointOverviewFields from './LoyaltyPointOverviewFields'
import CustomFormLabel from '../shared/forms/customElements/CustomFormLabel'
import { LABEL_TEXT } from '../../constants/messages'
import {
  buildTokenChipColor,
  buildTokenIconByStatus,
  buildTokenLabelByStatus
} from '../../util/token.helpers'
import ChipWithIcon from '../shared/Chip/ChipWithIcon'

const LoyaltyPointOverview = ({
  title,
  tokenProfile,
  dispatchAction,
  onFormSubmit,
  t
}) => {
  const { id, status } = tokenProfile
  return (
    <AccountForm
      t={t}
      title={title}
      description={t('token-setup.loyalty-point-desc')}
      submitLabel='common.save'
      onFormSubmit={onFormSubmit}
      fields={
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item lg={3} md={12} sm={12} xs={12}>
                  <CustomFormLabel htmlFor={id}>
                    {t(LABEL_TEXT.POINT_STATUS)}
                  </CustomFormLabel>
                  <ChipWithIcon
                    label={t(buildTokenLabelByStatus(status))}
                    icon={buildTokenIconByStatus(status)}
                    color={buildTokenChipColor(status)}
                    sx={{
                      color: (theme) => theme.palette.lightGrey.light
                    }}
                  />
                </Grid>
                <TokenDetails
                  isLoyaltyPoint
                  tokenProfile={tokenProfile}
                  t={t}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <LoyaltyPointOverviewFields
                  t={t}
                  {...tokenProfile}
                  dispatchAction={dispatchAction}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  )
}

export default memo(LoyaltyPointOverview)
