import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { HOME_URL } from '../constants/navigation'
import { POP } from '../constants/app'
import { getCurrentUser } from '../store/actions/auth'
import {
  isAuthenticated,
  isNotAuthenticated,
  isCheckingAuth
} from '../util/account.helpers'
import Loading from '../components/shared/Loading/Loading'

export default (ChildComponent) => {
  class ComposedComponent extends Component {
    componentDidMount() {
      const { authState, history } = this.props
      if (isNotAuthenticated(authState) && history.action === POP) {
        this.props.getCurrentUser()
      } else if (isAuthenticated(authState)) {
        history.replace(HOME_URL)
      }
    }

    componentDidUpdate() {
      const { authState, history } = this.props
      if (isAuthenticated(authState)) {
        history.replace(HOME_URL)
      }
    }

    render() {
      const { authState } = this.props
      return isCheckingAuth(authState) ? (
        <Loading />
      ) : (
        <ChildComponent {...this.props} />
      )
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(ComposedComponent)
}

const mapStateToProps = ({ authReducer }) => {
  const { authProfile } = authReducer
  const { authState } = authProfile
  return {
    authState
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCurrentUser
    },
    dispatch
  )
