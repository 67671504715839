import { mergeToNewObject } from '../../../util/object.helpers'
import { MEMBER_TRANSACTION_ACTIONS } from '../../../constants/actions'
import { MEMBER_POINTS_TRANSACTION_TYPES } from '../../../constants/members'
import { formatValidator } from '../../../util/validation.helpers'
import { ANALYTICS_PROCESS_STEP } from '../../../constants/containerStates'
import { MEMBER_TRANSACTION_ERRORS } from '../../../constants/apiErrors'
import { QB_ERROR_TEXT } from '../../../constants/messages'
import { extractUserPointsBalance } from '../../../util/analytics/pointsBank.helpers'

export const memberTransactionProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case MEMBER_TRANSACTION_ACTIONS.ON_CREDIT_DEBIT_POINTS:
      return mergeToNewObject(state, {
        transactionAction: '',
        isProcessing: true,
        error: {}
      })

    case MEMBER_TRANSACTION_ACTIONS.ON_CREDIT_DEBIT_POINTS_SUCCESS:
      return mergeToNewObject(state, {
        transactionAction: ANALYTICS_PROCESS_STEP.MEMBER_TRANSACTION_COMPLETED,
        success: {
          amount: formatValidator(`member.points-success-${state.type}`)
        },
        isProcessing: false
      })

    case MEMBER_TRANSACTION_ACTIONS.ON_CREDIT_DEBIT_POINTS_FAILED:
      return mergeToNewObject(state, {
        transactionAction: ANALYTICS_PROCESS_STEP.MEMBER_TRANSACTION_COMPLETED,
        ...extractErrorMessage(payload),
        isProcessing: false
      })

    case MEMBER_TRANSACTION_ACTIONS.ON_CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case MEMBER_TRANSACTION_ACTIONS.ON_RESET_STATE:
      return INITIAL_STATE

    case MEMBER_TRANSACTION_ACTIONS.ON_GET_BALANCES:
      return mergeToNewObject(state, {
        pointsBalance: 0
      })

    case MEMBER_TRANSACTION_ACTIONS.ON_GET_BALANCES_SUCCESS:
      return mergeToNewObject(
        state,
        payload.length > 0
          ? {
              pointsBalance: extractUserPointsBalance(payload)
            }
          : {}
      )

    default:
      return state
  }
}

const INITIAL_STATE = {
  selectedMember: null,
  type: MEMBER_POINTS_TRANSACTION_TYPES.CREDIT_POINTS,
  amount: '',
  isProcessing: false,
  pointsBalance: 0,
  transactionAction: '',
  error: {},
  success: {}
}

export const MEMBER_TRANSACTION_PROFILE_DISPATCH = {
  SELECTED_MEMBER: 'selectedMember',
  TYPE: 'type',
  AMOUNT: 'amount',
  ERROR: 'error'
}

const extractErrorMessage = (payload) => {
  return {
    error: {
      amount: formatValidator(buildErrorMessageForTransaction(payload))
    }
  }
}

const buildErrorMessageForTransaction = (payload) => {
  switch (payload?.errors?.detail) {
    case MEMBER_TRANSACTION_ERRORS.INSUFFICIENT_USER_BALANCE:
      return 'member.points-transaction-insufficient-balance'

    default:
      return QB_ERROR_TEXT.SOMETHING_WENT_WRONG
  }
}
