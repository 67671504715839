import React, { memo } from 'react'
import { Box, ListItem, ListItemText, Chip } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { Trans } from 'react-i18next'

import Text from '../text/Text'
import Link from '../link/Link'
import { QB_TOKEN_STATUS } from '../../../constants/token'
import { toUpper } from '../../../util/string.helpers'
import { buildTokenChipColor } from '../../../util/token.helpers'
import { QB_LABEL_TEXT } from '../../../constants/messages'
import CustomButton from '../buttons/CustomButton'
import { openBlankRedirectPage } from '../../../util/app/app.helpers'
import { billingSessionUrl } from '../../../util/navigation.helpers'

const TokenStatus = ({ t, status, id, email }) => {
  return (
    <ListItem
      sx={{
        mb: 1,
        backgroundColor: ({ palette }) =>
          alpha(palette[buildTokenChipColor(status)]['main'], 0.2)
      }}
    >
      <ListItemText>
        <Box display='flex' alignItems='center' sx={{ mb: 1 }}>
          {t('token.status.current-status')}:{' '}
          <Chip
            label={toUpper(t(buildLabelByStatus(status)))}
            sx={{ ml: 1, height: '21px' }}
            size='small'
            color={buildTokenChipColor(status)}
          />
        </Box>
        <Box sx={{ mb: 1 }}>
          <StatusMessage status={status} id={id} t={t} />
        </Box>
        <EmailDescription status={status} t={t} email={email} />
      </ListItemText>
    </ListItem>
  )
}

export default memo(TokenStatus)

const StatusMessage = ({ t, id, status }) => {
  switch (status) {
    case QB_TOKEN_STATUS.REJECTED:
      return (
        <Text
          variant='subtitle1'
          label={t('token.status.reject-status-title')}
        />
      )

    case QB_TOKEN_STATUS.ACTIVE:
      return (
        <Text
          variant='subtitle1'
          label={
            <>
              {t('token.status.success-status-title')}.{' '}
              <strong>{t('token.status.success-status-action')}. </strong>
            </>
          }
        />
      )

    default:
      return (
        <>
          <Text
            variant='subtitle1'
            label={<Trans i18nKey='token.status.pending-status-description' />}
          />
          <CustomButton
            fullWidth={false}
            label={t('setup.pay-now')}
            onClick={() => openBlankRedirectPage(billingSessionUrl(id))}
            noPadding
            sx={{ mt: 1 }}
          />
        </>
      )
  }
}

const EmailDescription = ({ t, status, email }) => {
  const emailLink = <Link isEmail variant='subtitle1' to={email} />
  switch (status) {
    case QB_TOKEN_STATUS.REJECTED:
      return (
        <Text
          variant='subtitle1'
          label={
            <>
              {t('token.status.any-questions-concerns')}: {emailLink}
            </>
          }
        />
      )

    default:
      return (
        <Text
          variant='subtitle1'
          label={
            <>
              {t('token.status.any-questions')}: {emailLink}
            </>
          }
        />
      )
  }
}

const buildLabelByStatus = (status) => {
  switch (status) {
    case QB_TOKEN_STATUS.ACTIVE:
      return QB_LABEL_TEXT.TOKEN_SUCCESSFUL
    case QB_TOKEN_STATUS.REJECTED:
      return QB_LABEL_TEXT.TOKEN_REJECTED
    default:
      return QB_LABEL_TEXT.TOKEN_PENDING
  }
}
