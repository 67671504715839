import React, { memo } from 'react'
import { Trans } from 'react-i18next'

import {
  TERMS_COND_URL,
  PRIVACY_POLICY_URL
} from '../../constants/externalResources'
import Link from '../shared/link/Link'
import Text from '../shared/text/Text'

const TermsAndConditions = ({ t }) => {
  return (
    <Text
      label={
        <Trans i18nKey='sign-up.accept-terms'>
          <Link
            isOutside
            to={TERMS_COND_URL}
            label={t('common.terms-conditions')}
          />
          <Link
            isOutside
            to={PRIVACY_POLICY_URL}
            label={t('common.privacy-policy')}
          />
        </Trans>
      }
      mt={3}
      mb={3}
      align='center'
    />
  )
}

export default memo(TermsAndConditions)
