import { ajax } from 'rxjs/ajax'
import moment from 'moment'
import { compare } from 'compare-versions'

import { hotReloadApp } from './app/app.helpers'

async function clearCache() {
  const { caches } = window
  if (caches) {
    const names = await caches.keys()
    await Promise.all(names.map((name) => caches.delete(name)))
  }
}

async function clearCacheOnNewAppVersion(packageJson) {
  ajax.get(`/meta.json?nocache=${moment().valueOf()}`).subscribe((data) => {
    console.log('Downloaded meta.json metadata')
    console.log(data)
    if (data && data.response) {
      const metadata = data.response
      if (metadata.version) {
        const currentVersion = packageJson.version
        console.log('## CURRENT VERSION: ', currentVersion)
        console.log('## META VERSION: ', metadata.version)
        if (currentVersion && compare(currentVersion, metadata.version, '<')) {
          clearCache().then(hotReloadApp)
        }
      }
    }
  })
}

export { clearCacheOnNewAppVersion }
