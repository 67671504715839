import { mergeMap, catchError, map, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'

import { logger } from '../../util/log.helpers'
import {
  AUTH_ACTION,
  AUTH_SIGN_UP_ACTION,
  AUTH_LOGIN_ACTION,
  AUTH_FORGOT_PASSWORD_ACTION,
  AUTH_RESET_PASSWORD_ACTION
} from '../../constants/actions'
import authService from '../../services/auth'
import { extractCognitoUser } from '../../util/auth.helpers'
import { removeBrandAndTokenIds } from '../../util/local.helpers'
import { logAuthErrors } from '../../util/app/appSignal.helpers'

// AUTH SIGN_UP
const handleResendSignupEmail = (action$) =>
  action$.pipe(
    ofType(AUTH_SIGN_UP_ACTION.ON_RESEND_EMAIL),
    mergeMap(({ payload }) =>
      authService.resendSignUpEmail$(payload.email).pipe(
        tap((data) => logger(data)),
        map((data) => ({
          type: AUTH_SIGN_UP_ACTION.ON_RESEND_EMAIL_SUCCESS,
          payload: data
        })),
        catchError((err) => {
          logAuthErrors(AUTH_SIGN_UP_ACTION.ON_RESEND_EMAIL_FAILED, err, {
            email: payload.email
          })
          return of({
            type: AUTH_SIGN_UP_ACTION.ON_RESEND_EMAIL_FAILED,
            payload: err
          })
        })
      )
    )
  )

const handleSignUp = (action$) =>
  action$.pipe(
    ofType(AUTH_SIGN_UP_ACTION.ON_SIGN_UP),
    mergeMap(({ payload }) =>
      authService
        .signup$(payload.email, payload.password, payload.attributes)
        .pipe(
          tap((data) => logger(data)),
          map((data) => ({
            type: AUTH_SIGN_UP_ACTION.ON_SIGN_UP_SUCCESS,
            payload: data
          })),
          catchError((err) => {
            return of({
              type: AUTH_SIGN_UP_ACTION.ON_SIGN_UP_FAILED,
              payload: err
            })
          })
        )
    )
  )

const handleConfirmSignup = (action$) =>
  action$.pipe(
    ofType(AUTH_SIGN_UP_ACTION.ON_CONFIRM),
    mergeMap(({ payload }) =>
      authService.confirmSignUp$(payload.email, payload.code).pipe(
        tap((data) => logger(data)),
        map((data) => ({
          type: AUTH_SIGN_UP_ACTION.ON_CONFIRM_SUCCESS,
          payload: data
        })),
        catchError((err) => {
          logAuthErrors(AUTH_SIGN_UP_ACTION.ON_CONFIRM_FAILED, err, {
            email: payload.email
          })
          return of({
            type: AUTH_SIGN_UP_ACTION.ON_CONFIRM_FAILED,
            payload: err
          })
        })
      )
    )
  )

// AUTH_ACTION LOGIN
const handleLogin = (action$) =>
  action$.pipe(
    ofType(AUTH_LOGIN_ACTION.ON_LOG_IN),
    mergeMap(({ payload }) =>
      authService.logIn$(payload.email, payload.password).pipe(
        tap((data) => logger(data)),
        map((data) => ({
          type: AUTH_LOGIN_ACTION.ON_LOG_IN_SUCCESS,
          payload: data
        })),
        catchError((err) => {
          logAuthErrors(AUTH_LOGIN_ACTION.ON_LOG_IN_FAILED, err, {
            email: payload.email
          })
          return of({ type: AUTH_LOGIN_ACTION.ON_LOG_IN_FAILED, payload: err })
        })
      )
    )
  )

const handleSocialMediaLogin = (action$) =>
  action$.pipe(
    ofType(AUTH_LOGIN_ACTION.ON_SOCIAL_MEDIA_LOG_IN),
    mergeMap(({ payload }) =>
      authService.socialMediaLogIn$(payload.provider).pipe(
        tap((data) => logger(data)),
        map((data) => ({
          type: AUTH_LOGIN_ACTION.ON_SOCIAL_MEDIA_LOG_IN_SUCCESS,
          payload: data
        })),
        catchError((err) =>
          of({
            type: AUTH_LOGIN_ACTION.ON_SOCIAL_MEDIA_LOG_IN_FAILED,
            payload: err
          })
        )
      )
    )
  )

// RESET & CHANGE PASSWORD
const handleRequestPasswordChange = (action$) =>
  action$.pipe(
    ofType(AUTH_FORGOT_PASSWORD_ACTION.ON_REQUEST_PASSWORD_CHANGE),
    mergeMap(({ payload }) =>
      authService.requestPasswordChange$(payload.email).pipe(
        tap((data) => logger(data)),
        map((data) => ({
          type: AUTH_FORGOT_PASSWORD_ACTION.ON_REQUEST_PASSWORD_CHANGE_SUCCESS,
          payload: data
        })),
        catchError((err) => {
          logAuthErrors(
            AUTH_FORGOT_PASSWORD_ACTION.ON_REQUEST_PASSWORD_CHANGE_FAILED,
            err,
            { email: payload.email }
          )
          return of({
            type: AUTH_FORGOT_PASSWORD_ACTION.ON_REQUEST_PASSWORD_CHANGE_FAILED,
            payload: err
          })
        })
      )
    )
  )

const handleResetPassword = (action$) =>
  action$.pipe(
    ofType(AUTH_RESET_PASSWORD_ACTION.ON_RESET_PASSWORD),
    mergeMap(({ payload }) =>
      authService
        .resetPassword$(payload.email, payload.code, payload.newPassword)
        .pipe(
          tap((data) => logger(data)),
          map((data) => ({
            type: AUTH_RESET_PASSWORD_ACTION.ON_RESET_PASSWORD_SUCCESS,
            payload: data
          })),
          catchError((err) => {
            logAuthErrors(
              AUTH_RESET_PASSWORD_ACTION.ON_RESET_PASSWORD_FAILED,
              err,
              { email: payload.email }
            )
            return of({
              type: AUTH_RESET_PASSWORD_ACTION.ON_RESET_PASSWORD_FAILED,
              payload: err
            })
          })
        )
    )
  )

const handleChangePassword = (action$, state$) =>
  action$.pipe(
    ofType(AUTH_ACTION.ON_CHANGE_PASSWORD),
    mergeMap(({ payload }) =>
      authService
        .changePassword$(
          extractCognitoUser(state$),
          payload.oldPassword,
          payload.newPassword
        )
        .pipe(
          tap((data) => logger(data)),
          map((data) => ({
            type: AUTH_ACTION.ON_CHANGE_PASSWORD_SUCCESS,
            payload: data
          })),
          catchError((err) => {
            logAuthErrors(AUTH_ACTION.ON_CHANGE_PASSWORD_FAILED, err, {})
            return of({
              type: AUTH_ACTION.ON_CHANGE_PASSWORD_FAILED,
              payload: err
            })
          })
        )
    )
  )

// CURRENT USER
const handleGetCurrentUserCredentials = (action$) =>
  action$.pipe(
    ofType(AUTH_ACTION.ON_GET_CURRENT_USER),
    mergeMap((_) =>
      authService.getCurrentUser$().pipe(
        tap((data) => logger(data)),
        map((data) => {
          return {
            type: AUTH_ACTION.ON_GET_CURRENT_USER_SUCCESS,
            payload: data
          }
        }),
        catchError((err) =>
          of({ type: AUTH_ACTION.ON_GET_CURRENT_USER_FAILED, payload: err })
        )
      )
    )
  )

// SIGN OUT
const handleSignOut = (action$) =>
  action$.pipe(
    ofType(AUTH_ACTION.ON_SIGN_OUT),
    mergeMap((_) =>
      authService.signOut$().pipe(
        tap((data) => logger(data)),
        map((data) => {
          removeBrandAndTokenIds()
          return {
            type: AUTH_ACTION.ON_SIGN_OUT_SUCCESS,
            payload: data
          }
        }),
        catchError((err) =>
          of({ type: AUTH_ACTION.ON_SIGN_OUT_FAILED, payload: err })
        )
      )
    )
  )

// COGNITO USER ATTRIBUTES
const handleUpdateUserAttributes = (action$, state$) =>
  action$.pipe(
    ofType(AUTH_ACTION.ON_UPDATE_USER_ATTRIBUTES),
    mergeMap(({ payload }) =>
      authService
        .updateUserAttributes$(extractCognitoUser(state$), payload.attributes)
        .pipe(
          tap((data) => logger(data)),
          map((data) => ({
            type: AUTH_ACTION.ON_UPDATE_USER_ATTRIBUTES_SUCCESS,
            payload: data
          })),
          catchError((err) => {
            logAuthErrors(
              AUTH_ACTION.ON_UPDATE_USER_ATTRIBUTES_FAILED,
              err,
              payload.attributes
            )
            return of({
              type: AUTH_ACTION.ON_UPDATE_USER_ATTRIBUTES_FAILED,
              payload: err
            })
          })
        )
    )
  )

const handleGetCognitoUserAfterUpdate = (action$) =>
  action$.pipe(
    ofType(AUTH_ACTION.ON_UPDATE_USER_ATTRIBUTES_SUCCESS),
    mergeMap((_) =>
      authService.getCurrentUser$().pipe(
        tap((data) => logger(data)),
        map((data) => ({
          type: AUTH_ACTION.ON_GET_CURRENT_USER_AFTER_UPDATE_SUCCESS,
          payload: data
        })),
        catchError((err) =>
          of({
            type: AUTH_ACTION.ON_GET_CURRENT_USER_AFTER_UPDATE_FAILED,
            payload: err
          })
        )
      )
    )
  )

export const authEpic = combineEpics(
  // AUTH_SIGN_UP
  handleResendSignupEmail,
  handleConfirmSignup,

  // AUTH
  handleLogin,
  handleSocialMediaLogin,
  handleRequestPasswordChange,
  handleResetPassword,
  handleGetCurrentUserCredentials,
  handleSignOut,
  handleSignUp,
  handleUpdateUserAttributes,
  handleChangePassword,
  handleGetCognitoUserAfterUpdate
)
