import { useEffect } from 'react'

const useScript = (scriptUrl, scriptId) => {
  useEffect(() => {
    const existingScript = document.getElementById(scriptId)
    if (!existingScript) {
      const script = document.createElement('script')
      script.src = scriptUrl
      script.id = scriptId
      document.body.appendChild(script)
    }

    return () => {
      const existingScript = document.getElementById(scriptId)
      if (existingScript) {
        document.body?.removeChild(existingScript)
      }
    }
  }, [scriptUrl, scriptId])
}

export { useScript }
