import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { bindActionCreators } from 'redux'

import Dashboard from '../components/dashboard/Dashboard'
import Loading from '../components/shared/Loading/Loading'
import {
  extractMilestonesAnimationStatus,
  isDashboardLoading,
  isDashboardReady
} from '../util/dashboard.helpers'
import {
  getExchangeActivityChart,
  resetTransactionsChartState
} from '../store/actions/transactions'
import { getTokens, resetTokenStats } from '../store/actions/token'
import { calculateMemberMilestones } from '../data/members'
import { milestoneAnimationComplete } from '../store/actions/dashboard'
import { calculateExchangeInOutValue } from '../util/chart/transactionsGraph.helpers'
import { getNfts } from '../store/actions/nfts'
import { calculateNftSupply } from '../util/nft.helpers'

const DashboardContainer = ({
  dashboardSetupState,
  getExchangeActivityChart,
  getTokens,
  activePartnershipsCount,
  brandProfile,
  tokenProfile,
  tokenStatsProfile,
  milestoneAnimationComplete,
  resetTransactionsChartState,
  resetTokenStats,
  nftsListProfile,
  getNfts,
  ...restProps
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    getTokens()
    getNfts()
  }, [])

  const milestonesLatestData = (
    activePartnershipsCount,
    tokenProfile,
    tokenStatsProfile,
    nftsListProfile
  ) => {
    const nftsCount = calculateNftSupply(nftsListProfile)
    return calculateMemberMilestones(
      activePartnershipsCount,
      tokenStatsProfile.exchangeTxCount,
      calculateExchangeInOutValue(tokenStatsProfile, tokenProfile),
      nftsCount,
      t
    )
  }

  const isDashboardSetupReady = isDashboardReady(dashboardSetupState)

  useEffect(() => {
    return () => {
      const milestonesAnimationStatus = extractMilestonesAnimationStatus(
        milestonesLatestData(
          activePartnershipsCount,
          tokenProfile,
          tokenStatsProfile,
          nftsListProfile
        )
      )
      if (Object.keys(milestonesAnimationStatus).length > 0) {
        milestoneAnimationComplete(milestonesAnimationStatus)
      }
    }
  }, [
    tokenProfile,
    tokenStatsProfile,
    activePartnershipsCount,
    nftsListProfile
  ])

  useEffect(() => {
    if (isDashboardSetupReady) {
      getExchangeActivityChart()
    } else {
      resetTransactionsChartState()
      resetTokenStats()
    }
  }, [isDashboardSetupReady, brandProfile])

  const handleCompleteMilestoneAnimation = (key) => {
    milestoneAnimationComplete({
      [key]: true
    })
  }

  const membershipMilestonesData = useMemo(() => {
    return milestonesLatestData(
      activePartnershipsCount,
      tokenProfile,
      tokenStatsProfile,
      nftsListProfile
    )
  }, [
    tokenProfile,
    tokenStatsProfile,
    activePartnershipsCount,
    nftsListProfile
  ])

  return isDashboardLoading(dashboardSetupState) ? (
    <Loading />
  ) : (
    <Dashboard
      t={t}
      dashboardSetupState={dashboardSetupState}
      activePartnershipsCount={activePartnershipsCount}
      membershipMilestonesData={membershipMilestonesData}
      tokenProfile={tokenProfile}
      isDashboardSetupReady={isDashboardSetupReady}
      onCompleteMilestoneAnimation={handleCompleteMilestoneAnimation}
      brandProfile={brandProfile}
      exchangeTxCount={tokenStatsProfile.exchangeTxCount}
      {...restProps}
    />
  )
}

const mapStateToProps = ({
  dashboardReducer,
  tokensReducer,
  transactionsReducer,
  mainReducer,
  nftsReducer
}) => {
  const { billingProfile, mainProfile } = mainReducer
  const { dashboardSetupProfile, brandProfile } = dashboardReducer
  const { tokenProfile, tokenStatsProfile } = tokensReducer
  const { transactionsGraphProfile } = transactionsReducer
  const { dashboardSetupState, milestoneAnimationStatus } =
    dashboardSetupProfile
  const { nftsListProfile } = nftsReducer
  return {
    dashboardSetupState,
    milestoneAnimationStatus,
    tokenProfile,
    tokenStatsProfile,
    brandProfile,
    transactionsGraphProfile,
    activePartnershipsCount: mainProfile.exchangePartnersStats.activeCount,
    billingProfile,
    nftsListProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getExchangeActivityChart,
      getTokens,
      milestoneAnimationComplete,
      resetTransactionsChartState,
      resetTokenStats,
      getNfts
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)
