import React, { memo } from 'react'
import { Grid } from '@mui/material'

import AccountForm from '../shared/AccountForm/AccountForm'
import LoyaltyPointEconomicsFields from './LoyaltyPointEconomicsFields'

const LoyaltyPointEconomics = ({ tokenProfile: { pointValue }, t }) => {
  return (
    <AccountForm
      noActions
      t={t}
      fields={
        <Grid item xs={12}>
          <LoyaltyPointEconomicsFields
            t={t}
            pointValue={pointValue}
            isUpdatePoint
          />
        </Grid>
      }
    />
  )
}

export default memo(LoyaltyPointEconomics)
