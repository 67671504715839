import React, { memo } from 'react'
import { Box, Grid } from '@mui/material'

import { BRAND_DISPATCH } from '../../../store/reducers/dashboard/brand'
import CustomButton from '../../shared/buttons/CustomButton'
import FileField from '../../shared/inputs/FileField'
import CountryField from '../../shared/CountryField/CountryField'
import Input from '../../shared/inputs/Input'
import { LABEL_TEXT, QB_ERROR_TEXT } from '../../../constants/messages'
import BrandLogo from '../../shared/BrandLogo/BrandLogo'
import BaseForm from '../../shared/BaseForm/BaseForm'
import { DEFAULT_INPUT_MAX_LENGTH } from '../../../constants/validation'
import { QB_ACTION_SUBMIT } from '../../../constants/input'
import { transWithArgs } from '../../../util/validation.helpers'
import { formatTicks } from '../../../util/string.helpers'
import { CSS_FLEX_CENTERED } from '../../../styles/common'

const BrandSetupForm = ({
  brandProfile: {
    id,
    nameTemp,
    websiteTemp,
    addressTemp,
    zipTemp,
    cityTemp,
    countryTemp,
    error,
    success,
    logo,
    logoUrl,
    isProcessing
  },
  dispatchAction,
  onClickContinue,
  title,
  description,
  customCss,
  t
}) => {
  const isBrandCreated = Boolean(id)
  return (
    <BaseForm
      onFormSubmit={onClickContinue}
      title={title}
      description={description}
      customCss={customCss}
      fields={
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2} sx={CSS_FLEX_CENTERED}>
                <BrandLogo src={logo ? URL.createObjectURL(logo) : logoUrl} />
              </Grid>
              <Grid item xs={12} md={10}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FileField
                      id={BRAND_DISPATCH.LOGO_URL_TEMP}
                      accept='image/*'
                      label={t('setup.brand-logo')}
                      errorMessage={transWithArgs(
                        t,
                        error[BRAND_DISPATCH.LOGO_URL_TEMP]
                      )}
                      successMsg={transWithArgs(
                        t,
                        success[BRAND_DISPATCH.LOGO_URL_TEMP]
                      )}
                      onChange={(e) => {
                        if (e.target.files && e.target.files.length > 0) {
                          dispatchAction(BRAND_DISPATCH.LOGO, e.target.files[0])
                        }
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Input
                      id={BRAND_DISPATCH.NAME_TEMP}
                      label={t(`setup.brand-name`)}
                      value={nameTemp}
                      errorMessage={transWithArgs(
                        t,
                        error[BRAND_DISPATCH.NAME_TEMP]
                      )}
                      successMsg={transWithArgs(
                        t,
                        success[BRAND_DISPATCH.NAME_TEMP]
                      )}
                      onChange={(value) =>
                        dispatchAction(BRAND_DISPATCH.NAME_TEMP, value)
                      }
                      size='small'
                      disabled={isBrandCreated}
                      inputProps={{ maxLength: DEFAULT_INPUT_MAX_LENGTH }}
                      bottomLabel={t(QB_ERROR_TEXT.CHARACTERS_MAX, {
                        max: formatTicks(DEFAULT_INPUT_MAX_LENGTH)
                      })}
                    />
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Input
                      id={BRAND_DISPATCH.WEBSITE_TEMP}
                      label={t(`setup.brand-website`)}
                      value={websiteTemp}
                      errorMessage={transWithArgs(
                        t,
                        error[BRAND_DISPATCH.WEBSITE_TEMP]
                      )}
                      successMsg={transWithArgs(
                        t,
                        success[BRAND_DISPATCH.WEBSITE_TEMP]
                      )}
                      onChange={(value) =>
                        dispatchAction(BRAND_DISPATCH.WEBSITE_TEMP, value)
                      }
                      size='small'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Input
              id={BRAND_DISPATCH.ADDRESS_TEMP}
              label={t(LABEL_TEXT.STREET_ADDRESS)}
              value={addressTemp}
              errorMessage={transWithArgs(
                t,
                error[BRAND_DISPATCH.ADDRESS_TEMP]
              )}
              successMsg={transWithArgs(
                t,
                success[BRAND_DISPATCH.ADDRESS_TEMP]
              )}
              onChange={(value) =>
                dispatchAction(BRAND_DISPATCH.ADDRESS_TEMP, value)
              }
              size='small'
            />
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Input
              id={BRAND_DISPATCH.ZIP_TEMP}
              label={t(LABEL_TEXT.ZIP_CODE)}
              value={zipTemp}
              errorMessage={transWithArgs(t, error[BRAND_DISPATCH.ZIP_TEMP])}
              successMsg={transWithArgs(t, success[BRAND_DISPATCH.ZIP_TEMP])}
              onChange={(value) =>
                dispatchAction(BRAND_DISPATCH.ZIP_TEMP, value)
              }
              size='small'
            />
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Input
              id={BRAND_DISPATCH.CITY_TEMP}
              label={t(LABEL_TEXT.CITY)}
              value={cityTemp}
              errorMessage={transWithArgs(t, error[BRAND_DISPATCH.CITY_TEMP])}
              successMsg={transWithArgs(t, success[BRAND_DISPATCH.CITY_TEMP])}
              onChange={(value) =>
                dispatchAction(BRAND_DISPATCH.CITY_TEMP, value)
              }
              size='small'
            />
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <CountryField
              id={BRAND_DISPATCH.COUNTRY_TEMP}
              value={countryTemp}
              errorMessage={transWithArgs(
                t,
                error[BRAND_DISPATCH.COUNTRY_TEMP]
              )}
              successMsg={transWithArgs(
                t,
                success[BRAND_DISPATCH.COUNTRY_TEMP]
              )}
              onChange={(target) =>
                dispatchAction(BRAND_DISPATCH.COUNTRY_TEMP, target?.value)
              }
              size='small'
              t={t}
            />
          </Grid>
        </Grid>
      }
      actions={
        <>
          <Box sx={{ flex: '1 1 auto' }} />

          <CustomButton
            type={QB_ACTION_SUBMIT}
            variant='contained'
            label={t(`common.${isBrandCreated ? 'update' : 'continue'}`)}
            fullWidth={false}
            disabled={isProcessing}
          />
        </>
      }
    />
  )
}

export default memo(BrandSetupForm)
