import {
  DOWNLOAD_ACTION,
  MEMBER_DOWNLOAD_ACTIONS,
  TRANSACTION_DOWNLOAD_ACTIONS
} from '../../../constants/actions'
import { mergeToNewObject } from '../../../util/object.helpers'
import { API_PROGRESS_STATE } from '../../../constants/app'

export const downloadProfile = (state = INITIAL_STATE, { type }) => {
  switch (type) {
    case MEMBER_DOWNLOAD_ACTIONS.ON_GET_MEMBERS_FOR_DOWNLOAD:
    case TRANSACTION_DOWNLOAD_ACTIONS.ON_GET_TRANSACTIONS_FOR_DOWNLOAD:
      return mergeToNewObject(state, { isDownloaded: false })

    case MEMBER_DOWNLOAD_ACTIONS.ON_GET_MEMBERS_FOR_DOWNLOAD_SUCCESS:
    case TRANSACTION_DOWNLOAD_ACTIONS.ON_GET_TRANSACTIONS_FOR_DOWNLOAD_SUCCESS:
      return mergeToNewObject(state, { isDownloaded: true })

    case MEMBER_DOWNLOAD_ACTIONS.ON_SEND_REPORT_IN_EMAIL:
    case TRANSACTION_DOWNLOAD_ACTIONS.ON_SEND_REPORT_IN_EMAIL:
      return mergeToNewObject(state, {
        downloadEmailProgress: API_PROGRESS_STATE.IN_PROGRESS
      })

    case DOWNLOAD_ACTION.ON_SENT_REPORT_IN_EMAIL_FAILED:
    case DOWNLOAD_ACTION.ON_SENT_REPORT_IN_EMAIL_SUCCESS:
      return mergeToNewObject(state, {
        downloadEmailProgress: API_PROGRESS_STATE.COMPLETED
      })

    case DOWNLOAD_ACTION.ON_RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  isDownloaded: false,
  downloadEmailProgress: API_PROGRESS_STATE.NONE
}
