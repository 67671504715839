import React, { memo } from 'react'
import { Box } from '@mui/material'

import Text from '../text/Text'
import CustomLinearProgress from './CustomLinearProgress'

const MilestoneProgress = ({
  title,
  description,
  completed,
  count,
  value,
  rootSx
}) => {
  return (
    <Box sx={{ width: '100%', mb: 1, mt: 1, ...rootSx }}>
      <Box display='flex' alignItems='center'>
        <Text
          variant='h6'
          label={title}
          sx={{
            lineHeight: '1.235'
          }}
          fontWeight={500}
        />
        {value && (
          <Box
            sx={{
              ml: 'auto'
            }}
          >
            <Text
              variant='h6'
              label={value}
              sx={{
                lineHeight: '1.235'
              }}
              fontWeight={500}
            />
          </Box>
        )}
      </Box>
      <Box
        display='flex'
        alignItems='center'
        sx={{
          pb: '3px'
        }}
      >
        <Text
          color='textSecondary'
          variant='h6'
          label={description}
          fontWeight={500}
        />
        <Box
          sx={{
            ml: 'auto'
          }}
        >
          <Text
            color='textSecondary'
            variant='h6'
            label={count ? count : `${completed}%`}
          />
        </Box>
      </Box>
      <CustomLinearProgress value={completed} />
    </Box>
  )
}

export default memo(MilestoneProgress)
