import React, { memo } from 'react'
import { Stack } from '@mui/material'

import Link from '../link/Link'
import {
  PRIVACY_POLICY_URL,
  TERMS_COND_URL
} from '../../../constants/externalResources'
import DefaultText from '../text/DefaultText'

const ViewTermsPrivacyLinks = ({ t }) => {
  return (
    <>
      <DefaultText
        label={t('billing.terms1')}
        align='center'
        sx={{ mt: 0, mb: 0 }}
      />
      <DefaultText
        label={t('billing.terms2')}
        align='center'
        sx={{ mt: 0, mb: 0 }}
      />
      <Stack direction='row' spacing={3} justifyContent='center' mt={2}>
        <Link
          isOutside
          to={TERMS_COND_URL}
          label={`${t('common.view')} ${t('common.terms-conditions')}`}
        />
        <Link
          isOutside
          to={PRIVACY_POLICY_URL}
          label={`${t('common.view')} ${t('common.privacy-policy')}`}
        />
      </Stack>
    </>
  )
}

export default memo(ViewTermsPrivacyLinks)
