import React, { memo } from 'react'
import { Dialog, DialogTitle } from '@mui/material'

import Text from '../text/Text'
import CustomDialogContent from './CustomDialogContent'

const CustomDialog = ({
  banner,
  title,
  description,
  additionalDesc,
  open,
  onClose,
  maxWidth,
  paperProps,
  ...restProps
}) => {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      fullWidth
      PaperProps={{ sx: { borderRadius: '20px', ...paperProps } }}
      maxWidth={maxWidth}
    >
      {title && (
        <DialogTitle sx={{ p: 4, pb: 2 }} variant='h4'>
          {title}
        </DialogTitle>
      )}
      {description && (
        <Text
          variant='body1'
          color='textprimary'
          label={description}
          sx={{ pl: 4, pr: 4 }}
        />
      )}
      {additionalDesc}
      {banner}
      <CustomDialogContent onClose={onClose} {...restProps} />
    </Dialog>
  )
}

CustomDialog.defaultProps = {
  isForm: false
}

export default memo(CustomDialog)
