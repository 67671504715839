import React, { memo } from 'react'
import { Grid } from '@mui/material'
import {
  faCoins,
  faPlug,
  faCreditCardFront,
  faMoneyBill1Wave,
  faWallet,
  faGiftCard
  // ,
  // faPlaneEngines,
  // faInfoCircle
} from '@fortawesome/pro-light-svg-icons'
import { Trans } from 'react-i18next'

import PageContainer from '../shared/PageContainer/PageContainer'
import DisplayAlerts from '../setup/common/DisplayAlerts'
import BaseCard from '../shared/BaseCard/BaseCard'
import PanelHeading from '../shared/PanelHeading/PanelHeading'
import TabContent from '../shared/TabContent/TabContent'
import CustomButton from '../shared/buttons/CustomButton'
import LoveBrandCard from '../shared/LoveBrandCard/LoveBrandCard'
import {
  EARN_OPTION_TOGGLE_IDS,
  EXTENSIONS_TAB_TYPE
} from '../../constants/extensions'
import TabDescription from '../shared/TabContent/TabDescription'
import {
  buildLoveBrandCardProps,
  buildThirdPartyToolTypes,
  fromExtensionsTypeToTabId,
  sortOptionsByTitleASC
} from '../../util/extensions/extensions.helpers'
import CardGrid from '../shared/containers/CardGrid'
import { LAD_SURVEY_IDS } from '../../constants/survey'
import Dropdown from '../shared/inputs/Dropdown'
import { EXTENSIONS_PROFILE_DISPATCH } from '../../store/reducers/extensions/extensions'
import DefaultText from '../shared/text/DefaultText'
import CardListGrid from '../shared/containers/CardListGrid'
import { filterEarnOptionsData } from '../../util/survey.helpers'
import EarnOptionCard from './EarnOptionCard'
import {
  BUY_POINTS_HELP_CENTER,
  CASHBACK_HELP_CENTER
} from '../../constants/externalResources'
import Link from '../shared/link/Link'
import EarnOptionCardActivation from '../shared/LoveBrandCard/EarnOptionCardActivation'
import RedemptionShop from './RedemptionShop'
import BookACall from '../shared/buttons/BookACall'
// @TODO: Uncomment when we enable buy points with miles
// import {
// buildExchangeActiveUrl,
// isExchangeWhitelistActive
// } from '../../util/exchange.helpers'
// import FaIcon from '../shared/FaIcon/FaIcon'
// import { TOKEN_WHITELIST_STATUS } from '../../constants/token'
// import TransWithInAppLink from '../shared/translations/TransWithInAppLink'

const Extensions = ({
  t,
  selectedTab,
  onChangeTab,
  isDashboardSetupReady,
  tokenProfile,
  billingProfile,
  onClickSuggestFavorite,
  accountProfile,
  surveyProfile: { surveys, userSurveys },
  onLikeUnlikeSurvey,
  changeToolsFilterState,
  extensionsProfile: {
    toolsFilterType,
    filteredThirdPartyTools,
    userSurveysLikesCount,
    isProcessing
  },
  selectedModule,
  onToggleActivation,
  brandConfig
  // @TODO: Uncomment when we enable buy points with miles
  // ,
  // partnersProfile,
  // onDetailsMMExtension,
  // milesAndMoreTokenId,
  // exchangePartnersStats
}) => {
  const title = t('extensions.extensions')
  const thirdPartyToolsSurvey =
    surveys[LAD_SURVEY_IDS.THIRD_PARTY_TOOLS]?.questions?.en
  const earnOptionsSurvey = surveys[LAD_SURVEY_IDS.EARN_OPTIONS]?.questions?.en
  const buildContent = () => {
    switch (selectedTab) {
      case EXTENSIONS_TAB_TYPE.THIRD_PARTY_TOOLS:
        return (
          <>
            <DefaultText
              label={thirdPartyToolsSurvey?.description}
              component='div'
              sx={{ mb: '5px' }}
            />
            <Grid container sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <Grid item xs={12} md={6}>
                <Dropdown
                  id={EXTENSIONS_PROFILE_DISPATCH.TOOLS_FILTER_TYPE}
                  label={t('common.type')}
                  options={buildThirdPartyToolTypes(t)}
                  onChange={({ target }) =>
                    changeToolsFilterState(target.value)
                  }
                  value={toolsFilterType}
                  size='small'
                  sx={{ width: 224 }}
                />
              </Grid>
              <Grid item xs={12} md={6} display='flex' justifyContent='end'>
                <CustomButton
                  variant='outlined'
                  fullWidth={false}
                  label={t('extensions.suggest-favorite-tool')}
                  onClick={onClickSuggestFavorite}
                />
              </Grid>
            </Grid>
          </>
        )

      case EXTENSIONS_TAB_TYPE.EARN_OPTIONS:
        return (
          <TabDescription
            t={t}
            description={earnOptionsSurvey?.description}
            actions={
              <>
                <BookACall userId={accountProfile.userId} t={t} />
                <CustomButton
                  variant='outlined'
                  fullWidth={false}
                  label={t('extensions.suggest-favorite-option')}
                  onClick={onClickSuggestFavorite}
                />
              </>
            }
          />
        )

      case EXTENSIONS_TAB_TYPE.REDEMPTION_SHOP:
        return <RedemptionShop accountProfile={accountProfile} t={t} />

      default:
        return null
    }
  }

  const buildPartnersList = () => {
    switch (selectedTab) {
      case EXTENSIONS_TAB_TYPE.THIRD_PARTY_TOOLS:
        return sortOptionsByTitleASC(filteredThirdPartyTools).map(
          ({ id, ...question }, index) => (
            <LoveBrandCard
              t={t}
              key={id + index}
              {...buildLoveBrandCardProps(
                LAD_SURVEY_IDS.THIRD_PARTY_TOOLS,
                {
                  userSurveys,
                  userSurveysLikesCount,
                  id
                },
                onLikeUnlikeSurvey
              )}
              {...question}
            />
          )
        )

      case EXTENSIONS_TAB_TYPE.EARN_OPTIONS:
        const earnOptions = filterEarnOptionsData(earnOptionsSurvey?.questions)
        // @TODO: Uncomment when we enable buy points with miles
        // const isBuyPointsWithMilesActivated = isExchangeWhitelistActive(
        //   exchangePartnersStats?.milesAndMoreExchangePair
        //     ?.tokenExchangeWhitelist?.status
        // )
        const customActivationExtensions = [
          {
            id: EARN_OPTION_TOGGLE_IDS.ENABLE_PURCHASE_POINTS,
            helpCenterLink: BUY_POINTS_HELP_CENTER,
            activated: tokenProfile.enablePurchasePoints,
            isProcessing: tokenProfile.isProcessing,
            icon: faMoneyBill1Wave
            // @TODO: Uncomment when we enable buy points with miles
            // , disabledMessage:
            // exchangePartnersStats.isMilesAndMoreConnectionExists &&
            // !tokenProfile.enablePurchasePoints && (
            //   <TransWithInAppLink
            //     labelKey={`extensions.earn-options.${EARN_OPTION_TOGGLE_IDS.ENABLE_PURCHASE_POINTS}.disabled-activation`}
            //     to={buildExchangeActiveUrl()}
            //     linkLabelKey={`extensions.earn-options.${EARN_OPTION_TOGGLE_IDS.ENABLE_PURCHASE_POINTS}.remove-active-miles-and-more`}
            //   />
            // )
          },
          // @TODO: Uncomment when we enable buy points with miles
          // ...(tokenProfile.id !== milesAndMoreTokenId
          //   ? [
          //       {
          //         id: EARN_OPTION_TOGGLE_IDS.ENABLE_PURCHASE_POINTS_WITH_MILES,
          //         helpCenterLink: BUY_POINTS_HELP_CENTER,
          //         activated: isBuyPointsWithMilesActivated,
          //         isProcessing: partnersProfile.isProcessing,
          //         icon: faPlaneEngines,
          //         disabledMessage:
          //           !isBuyPointsWithMilesActivated &&
          //           buildDisabledMessageForMilesExtension(
          //             exchangePartnersStats
          //           ),
          //         additionalActions: isBuyPointsWithMilesActivated && (
          //           <CustomButton
          //             label={t('common.details')}
          //             startIcon={<FaIcon icon={faInfoCircle} />}
          //             onClick={onDetailsMMExtension}
          //             variant='outlined'
          //             fullWidth={false}
          //           />
          //         )
          //       }
          //     ]
          //   : []),
          {
            id: EARN_OPTION_TOGGLE_IDS.CASHBACK_EARN_OPTION,
            helpCenterLink: CASHBACK_HELP_CENTER,
            activated: brandConfig?.cashbackEarnOption,
            isProcessing: isProcessing,
            icon: faWallet
          }
        ]
        return (
          <>
            {customActivationExtensions.map(
              ({
                id,
                activated,
                icon,
                helpCenterLink,
                isProcessing,
                ...restProps
              }) => (
                <EarnOptionCardActivation
                  key={id}
                  id={id}
                  title={t(`extensions.earn-options.${id}.title`)}
                  description={
                    <>
                      <Trans
                        i18nKey={`extensions.earn-options.${id}.description`}
                      />
                      <br /> <br />
                      <Trans
                        i18nKey={
                          'extensions.earn-options.check-the-articles-for-more-information'
                        }
                      >
                        <Link
                          isOutside
                          to={helpCenterLink}
                          label={t('sidebar.help-center')}
                          textTransform='lowercase'
                        />
                      </Trans>
                    </>
                  }
                  icon={icon}
                  activated={activated}
                  onToggleActivation={onToggleActivation}
                  isProcessing={selectedModule?.id === id && isProcessing}
                  t={t}
                  {...restProps}
                />
              )
            )}
            {earnOptions.map((earnOption) => (
              <EarnOptionCard
                key={earnOption.id}
                icon={EARN_OPTIONS_SURVEY_ICONS[earnOption.id]}
                {...buildLoveBrandCardProps(
                  LAD_SURVEY_IDS.EARN_OPTIONS,
                  {
                    userSurveys,
                    userSurveysLikesCount,
                    id: earnOption.id
                  },
                  onLikeUnlikeSurvey
                )}
                {...earnOption}
                t={t}
              />
            ))}
          </>
        )

      default:
        return null
    }
  }

  return (
    <PageContainer title={title}>
      <DisplayAlerts
        isDashboardSetupReady={isDashboardSetupReady}
        tokenStatus={tokenProfile.status}
        subscribedPackage={billingProfile.subscribedPackage}
        t={t}
      />
      <CardGrid>
        <Grid item xs={12}>
          <BaseCard>
            <PanelHeading title={title}>
              <TabContent
                selectedTab={fromExtensionsTypeToTabId(selectedTab)}
                onChangeTab={onChangeTab}
                menuItems={tabMenuItems(
                  thirdPartyToolsSurvey?.title,
                  earnOptionsSurvey?.title,
                  t
                )}
                t={t}
                rootProps={{ mt: 1 }}
                content={buildContent()}
              />
            </PanelHeading>
          </BaseCard>
        </Grid>
        <CardListGrid>{buildPartnersList()}</CardListGrid>
      </CardGrid>
    </PageContainer>
  )
}

export default memo(Extensions)

const tabMenuItems = (thirdPartyToolsTitle, earnOptionsTitle, t) => {
  return [
    {
      value: EXTENSIONS_TAB_TYPE.THIRD_PARTY_TOOLS,
      label: thirdPartyToolsTitle,
      noTranslation: true,
      icon: faPlug
    },
    {
      value: EXTENSIONS_TAB_TYPE.EARN_OPTIONS,
      label: earnOptionsTitle,
      noTranslation: true,
      icon: faCoins
    },
    {
      value: EXTENSIONS_TAB_TYPE.REDEMPTION_SHOP,
      label: t('extensions.redemption-shop.title'),
      noTranslation: true,
      icon: faGiftCard
    }
  ]
}

const EARN_OPTIONS_SURVEY_ICONS = {
  co_branded_credit_card: faCreditCardFront
}

// @TODO: Uncomment when we enable buy points with miles
// const buildDisabledMessageForMilesExtension = (extension) => {
//   if (extension.isCryptoFiatConnectionExists) {
//     return (
//       <TransWithInAppLink
//         labelKey={`extensions.earn-options.${EARN_OPTION_TOGGLE_IDS.ENABLE_PURCHASE_POINTS_WITH_MILES}.disabled-activation`}
//         to={buildExchangeActiveUrl()}
//         linkLabelKey={`extensions.earn-options.${EARN_OPTION_TOGGLE_IDS.ENABLE_PURCHASE_POINTS_WITH_MILES}.remove-active-fiat-crypto`}
//       />
//     )
//   } else if (
//     extension?.milesAndMoreExchangePair?.tokenExchangeWhitelist?.status ===
//     TOKEN_WHITELIST_STATUS.PENDING
//   ) {
//     return <Trans i18nKey={'extensions.earn-options.disable-pending-message'} />
//   }
// }
