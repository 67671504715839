import React, { memo } from 'react'
import { Grid } from '@mui/material'

import { EXCHANGE_BUY_NFT_ACTION_DISPATCH } from '../../../store/reducers/exchange/buyNft'
import CustomDialog from '../../shared/CustomDialog/CustomDialog'
import Input from '../../shared/inputs/Input'
import {
  transWithArgs,
  validNaturalNumberRule
} from '../../../util/validation.helpers'
import { QB_LABEL_TEXT } from '../../../constants/messages'
import { formatTicks } from '../../../util/string.helpers'
import DisplayLabel from '../../shared/DisplayLabel/DisplayLabel'
import { faMoneyBillSimple } from '@fortawesome/pro-light-svg-icons'
import FaIcon from '../../shared/FaIcon/FaIcon'
import { calculatePointValue } from '../../../util/token.helpers'
import { formatCurrencyValue } from '../../../util/number.helpers'

const ConfirmBuyNft = ({
  t,
  onClose,
  onSubmit,
  dispatchAction,
  nft,
  buyNftProfile: { error, isProcessing, amountToPurchase }
}) => {
  const onramp = formatCurrencyValue(nft.onramp)
  return (
    <CustomDialog
      isForm
      title={t('exchange.confirm-nft-purchase')}
      description={t('exchange.confirm-nft-purchase-desc')}
      open
      onClose={onClose}
      cancelProps={{
        label: t(QB_LABEL_TEXT.CANCEL)
      }}
      submitProps={{
        label: t('exchange.buy-nft'),
        disabled: isProcessing
      }}
      maxWidth='md'
      body={
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <Input
              id={EXCHANGE_BUY_NFT_ACTION_DISPATCH.AMOUNT_TO_PURCHASE}
              label={t('exchange.amount-of-purchase')}
              helpText={t('exchange.amount-of-purchase-help-text')}
              value={amountToPurchase}
              errorMessage={transWithArgs(
                t,
                error[EXCHANGE_BUY_NFT_ACTION_DISPATCH.AMOUNT_TO_PURCHASE]
              )}
              onChange={(value) =>
                dispatchAction(
                  EXCHANGE_BUY_NFT_ACTION_DISPATCH.AMOUNT_TO_PURCHASE,
                  value
                )
              }
              validate={[validNaturalNumberRule]}
              size='small'
              bottomLabel={t('exchange.current-supply-on-sale', {
                value: formatTicks(nft?.availableSupply)
              })}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <DisplayLabel
              label={t('exchange.nft-price-in-credits')}
              value={
                <>
                  <FaIcon icon={faMoneyBillSimple} /> &nbsp;
                  {formatTicks(onramp)}
                </>
              }
              helpText={t('exchange.nft-price-in-credits-help-text')}
              mt={2}
              mb={1}
              variant='h6'
              fontWeight={500}
              valueProps={{ variant: 'h6' }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <DisplayLabel
              label={t('exchange.total-price-in-credits')}
              value={
                <>
                  <FaIcon icon={faMoneyBillSimple} /> &nbsp;
                  {formatTicks(calculatePointValue(amountToPurchase, onramp))}
                </>
              }
              helpText={t('exchange.total-price-in-credits-help-text')}
              mt={2}
              mb={1}
              variant='h6'
              fontWeight={500}
              valueProps={{ variant: 'h6', fontWeight: 600 }}
            />
          </Grid>
        </Grid>
      }
      onSubmit={onSubmit}
      t={t}
    />
  )
}

export default memo(ConfirmBuyNft)
