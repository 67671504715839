import {
  EXCHANGE_PARTNER_ACTION,
  EXCHANGE_TERMS_ACTION
} from '../../../constants/actions'
import { mergeToNewObject } from '../../../util/object.helpers'

export const exchangeTermsProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case EXCHANGE_TERMS_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, {
        [payload.property]: payload.value
      })

    case EXCHANGE_TERMS_ACTION.CHANGE_FORM_DATA_STATE:
      return mergeToNewObject(state, {
        formData: {
          ...state.formData,
          [payload.property]: payload.value
        }
      })

    case EXCHANGE_PARTNER_ACTION.ON_CONNECT_EXCHANGE_SUCCESS:
    case EXCHANGE_TERMS_ACTION.RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  formData: {},
  error: {}
}

export const EXCHANGE_TERMS_PROFILE_DISPATCH = {
  FORM_DATA: 'formData',
  ERROR: 'error'
}
