import React, { memo } from 'react'

import UserProfileAvatar from '../UserProfileAvatar/UserProfileAvatar'

const BrandLogo = ({ icon, src, sx, ...restProps }) => {
  return (
    <UserProfileAvatar
      {...restProps}
      sx={{
        border: (theme) => `1px solid ${theme.palette.grey4.main}`,
        ...sx
      }}
      src={src}
    >
      {!src && icon ? icon : <></>}
    </UserProfileAvatar>
  )
}

BrandLogo.defaultProps = {
  size: 110
}

export default memo(BrandLogo)
