import React, { memo } from 'react'

import CustomAlert from '../../shared/CustomAlert/CustomAlert'
import {
  PRODUCTION_URL,
  SANDBOX_URL
} from '../../../constants/externalResources'
import { isSandbox } from '../../../util/app/app.helpers'
import ReminderText from '../../shared/ReminderText'

const ReminderInfo = ({ t }) => {
  const isSandboxEnv = isSandbox()
  return (
    <CustomAlert variant='filled' severity='warning'>
      {!isSandboxEnv && (
        <>
          <ReminderText
            strongText={`${t('setup.important-note')}: `}
            i18nKey='setup.creating-in-prod-mode'
            linkLabel={t('common.sandbox-mode')}
            url={SANDBOX_URL}
          />
          <ReminderText
            strongText={`${t('setup.warning')}: `}
            text={t('setup.warning-text')}
          />
        </>
      )}
      {isSandboxEnv && (
        <ReminderText
          strongText={`${t('setup.important-note')}: `}
          i18nKey='setup.creating-in-sandbox-mode'
          linkLabel={t('common.production-environment')}
          url={PRODUCTION_URL}
        />
      )}
    </CustomAlert>
  )
}

export default memo(ReminderInfo)
