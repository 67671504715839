import React, { memo } from 'react'

import Text from '../text/Text'
import DisplayLabelFormat from './DisplayLabelFormat'
import TitleWithPopover from '../TitleWithPopover/TitleWithPopover'

const DisplayLabelWithPopover = ({ label, helpText, value }) => {
  return (
    <DisplayLabelFormat
      label={
        <TitleWithPopover
          fontWeight={700}
          variant='caption'
          component='div'
          title={label}
          helpText={helpText}
        />
      }
      value={
        <Text
          label={value}
          variant='caption'
          component='div'
          sx={{ maxHeight: 96, overflow: 'auto', lineHeight: 1.3 }}
        />
      }
    />
  )
}

export default memo(DisplayLabelWithPopover)
