import React, { memo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FaIcon = ({ width, icon, ...restProps }) => {
  if (!icon) {
    return null
  }
  const extraProps = width ? { width, height: width } : {}
  return <FontAwesomeIcon icon={icon} {...restProps} style={extraProps} />
}

export default memo(FaIcon)
