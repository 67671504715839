import React, { memo } from 'react'
import { Box } from '@mui/material'

import PageContainer from '../shared/PageContainer/PageContainer'
import Link from '../shared/link/Link'
import Text from '../shared/text/Text'
import { FORGOT_PASSWORD_URL, SIGN_UP_URL } from '../../constants/navigation'
import Input from '../shared/inputs/Input'
import { ERROR_TEXT, LABEL_TEXT } from '../../constants/messages'
import {
  isResendSignupVerifyFailed,
  isResendSignupVerifySuccess
} from '../../util/auth.helpers'
import { LOGIN_PROFILE_DISPATCH } from '../../store/reducers/auth/login'
import CustomForm from '../shared/forms/customForm/CustomForm'
import CustomButton from '../shared/buttons/CustomButton'
import UnauthorizedLayoutContainer from '../../containers/UnauthorizedLayoutContainer'
import { formatValidator, transWithArgs } from '../../util/validation.helpers'
import {
  QB_INPUT_EMAIL,
  QB_INPUT_PASSWORD,
  QB_ACTION_SUBMIT
} from '../../constants/input'
import { isBooleanType } from '../../util/type.helpers'
import SocialMediaButtonsContainer from '../../containers/socialMediaButtons/SocialMediaButtonsContainer'
import TermsAndConditions from '../signup/TermsAndConditions'

const Login = ({
  dispatchAction,
  loginProfile: { error, email, password, isProcessing },
  signupState,
  onResendVerification,
  onLoginSubmit,
  t
}) => {
  const resendVerifyEmailLink = (error) => {
    if (error && error?.message === ERROR_TEXT.EMAIL_VERIFICATION_FAILED) {
      return (
        <>
          {transWithArgs(t, error)}{' '}
          <Link
            isButton
            onClick={onResendVerification}
            variant='caption'
            label={t('common.resend-verification-email')}
          />
        </>
      )
    }
    return isBooleanType(error) ? error : transWithArgs(t, error)
  }
  const verifyEmailSuccess = isResendSignupVerifySuccess(signupState)
  const title = t('log-in.log-in-to-your-account')
  const action = t('log-in.log-in')
  return (
    <PageContainer title={title}>
      <UnauthorizedLayoutContainer title={title}>
        <Box display='flex' alignItems='center'>
          <Text
            variant='h6'
            label={t('log-in.new-to-qiibee')}
            color='textSecondary'
            sx={{ mr: 1 }}
            fontWeight={500}
          />
          <Link
            to={SIGN_UP_URL}
            label={t('log-in.create-an-account')}
            fontWeight={500}
          />
        </Box>
        <SocialMediaButtonsContainer action={action} />

        <CustomForm onFormSubmit={onLoginSubmit}>
          <Input
            id={LOGIN_PROFILE_DISPATCH.EMAIL}
            label={t('log-in.email')}
            type={QB_INPUT_EMAIL}
            errorMessage={
              !verifyEmailSuccess &&
              resendVerifyEmailLink(buildEmailErrors(error, signupState))
            }
            successMsg={
              verifyEmailSuccess && t(LABEL_TEXT.VERIFY_RESENT_SUCCESS)
            }
            value={email}
            onChange={(value) =>
              dispatchAction(LOGIN_PROFILE_DISPATCH.EMAIL, value)
            }
          />

          <Input
            id={LOGIN_PROFILE_DISPATCH.PASSWORD}
            label={t('log-in.password')}
            type={QB_INPUT_PASSWORD}
            errorMessage={transWithArgs(
              t,
              error[LOGIN_PROFILE_DISPATCH.PASSWORD]
            )}
            value={password}
            onChange={(value) =>
              dispatchAction(LOGIN_PROFILE_DISPATCH.PASSWORD, value)
            }
          />

          <Box
            sx={{
              mt: 3,
              display: {
                xs: 'block',
                sm: 'flex',
                lg: 'flex'
              },
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                ml: 'auto'
              }}
            >
              <Link
                to={FORGOT_PASSWORD_URL}
                label={t('log-in.forgot-password')}
              />
            </Box>
          </Box>
          <TermsAndConditions t={t} />
          <CustomButton
            type={QB_ACTION_SUBMIT}
            label={action}
            size='large'
            disabled={isProcessing}
          />
        </CustomForm>
      </UnauthorizedLayoutContainer>
    </PageContainer>
  )
}

export default memo(Login)

const buildEmailErrors = (error, signupState) => {
  const defaultError = error[LOGIN_PROFILE_DISPATCH.EMAIL]
  if (defaultError) {
    return defaultError
  } else if (isResendSignupVerifyFailed(signupState)) {
    return formatValidator(ERROR_TEXT.RESEND_EMAIL_VERIFICATION_FAILED)
  }
}
