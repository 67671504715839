import { mergeToNewObject } from '../../../util/object.helpers'
import {
  EXCHANGE_NFT_ACTION,
  EXCHANGE_PARTNER_ACTION
} from '../../../constants/actions'
import { EXCHANGE_CONNECTION_API_ERRORS } from '../../../constants/apiErrors'
import { DEFAULT_CONTAINER_STATE } from '../../../constants/containerStates'
import {
  EXCHANGE_CONNECTION_ACTION,
  EXCHANGE_CONNECTION_TYPES,
  EXCHANGE_PARTNER_TYPES,
  EXCHANGE_REQUEST_TYPES
} from '../../../constants/exchange'
import {
  filterPartnersByFromOrTo,
  buildPotentialPartnersData,
  buildConnectionPartnersData,
  sortPotentialPartnersData
} from '../../../util/exchange.helpers'
import {
  QB_DEFAULT_COUNTRY_VALUE,
  QB_DEFAULT_REGION_VALUE,
  QB_DEFAULT_TYPE_VALUE,
  QB_DEFAULT_SORTING_VALUE,
  QB_DEFAULT_MEMBERS_VALUE
} from '../../../constants/app'

export const partnersProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case EXCHANGE_PARTNER_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, {
        [payload.property]: payload.value,
        ...getSortedAndFilteredData(payload, state)
      })

    case EXCHANGE_PARTNER_ACTION.ON_RESET_FILTERS:
      return mergeToNewObject(state, {
        sorting: QB_DEFAULT_SORTING_VALUE,
        rewardType: QB_DEFAULT_TYPE_VALUE,
        region: QB_DEFAULT_REGION_VALUE,
        country: QB_DEFAULT_COUNTRY_VALUE,
        members: QB_DEFAULT_MEMBERS_VALUE,
        connectionType: QB_DEFAULT_TYPE_VALUE,
        requestType: QB_DEFAULT_TYPE_VALUE
      })

    case EXCHANGE_PARTNER_ACTION.ON_GET_PARTNERS_SUCCESS:
      return mergeToNewObject(state, buildPotentialPartnersData(payload, state))

    case EXCHANGE_PARTNER_ACTION.ON_GET_WHITELIST_SUCCESS:
      return mergeToNewObject(
        state,
        buildConnectionPartnersData(payload, state)
      )

    case EXCHANGE_PARTNER_ACTION.ON_CONNECT_EXCHANGE:
    case EXCHANGE_PARTNER_ACTION.ON_BULK_CONNECT_EXCHANGE:
    case EXCHANGE_PARTNER_ACTION.ON_UPDATE_EXCHANGE_CONNECTION:
      return mergeToNewObject(state, {
        exchangeConnectionAction: DEFAULT_CONTAINER_STATE.NONE,
        isProcessing: true
      })

    case EXCHANGE_PARTNER_ACTION.ON_CONNECT_EXCHANGE_FAILED:
      return mergeToNewObject(state, {
        exchangeConnectionAction: parseConnectionFailure(payload),
        isProcessing: false
      })

    case EXCHANGE_PARTNER_ACTION.ON_BULK_CONNECT_EXCHANGE_FAILED:
      return mergeToNewObject(state, {
        exchangeConnectionAction: EXCHANGE_CONNECTION_ACTION.ERROR_REJECTED,
        isProcessing: false
      })

    case EXCHANGE_PARTNER_ACTION.ON_UPDATE_EXCHANGE_CONNECTION_FAILED:
      return mergeToNewObject(state, {
        exchangeConnectionAction: DEFAULT_CONTAINER_STATE.NONE,
        isProcessing: false
      })

    case EXCHANGE_PARTNER_ACTION.ON_CONNECT_EXCHANGE_SUCCESS:
    case EXCHANGE_PARTNER_ACTION.ON_BULK_CONNECT_EXCHANGE_SUCCESS:
      return mergeToNewObject(state, {
        exchangeConnectionAction: EXCHANGE_CONNECTION_ACTION.SENT_REQUEST,
        isProcessing: false
      })

    case EXCHANGE_PARTNER_ACTION.ON_UPDATE_EXCHANGE_CONNECTION_SUCCESS:
      const { action } = payload
      return mergeToNewObject(state, {
        exchangeConnectionAction: action,
        isProcessing: false
      })

    case EXCHANGE_NFT_ACTION.ON_BUY_SUCCESS:
      const { nft, supply } = payload
      const availableSupply = nft.availableSupply
      const nftId = nft.id
      return mergeToNewObject(state, {
        ...(supply >= availableSupply
          ? {
              potential: {
                data: filterPartners(state.potential.data, nftId),
                filteredData: filterPartners(
                  state.potential.filteredData,
                  nftId
                )
              }
            }
          : {
              potential: {
                data: state.potential.data.map((data) =>
                  updateNftSupply(data, nftId, supply)
                ),
                filteredData: state.potential.filteredData.map((data) =>
                  updateNftSupply(data, nftId, supply)
                )
              }
            })
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  data: [],
  [EXCHANGE_PARTNER_TYPES.POTENTIAL]: {
    data: [],
    filteredData: []
  },
  [EXCHANGE_PARTNER_TYPES.ACTIVE]: {
    data: [],
    filteredData: []
  },
  [EXCHANGE_PARTNER_TYPES.REQUESTS]: {
    data: [],
    filteredData: []
  },
  exchangeConnectionAction: DEFAULT_CONTAINER_STATE.NONE,
  isProcessing: false,

  rejectedPartners: {},

  sorting: QB_DEFAULT_SORTING_VALUE,
  rewardType: QB_DEFAULT_TYPE_VALUE,
  region: QB_DEFAULT_REGION_VALUE,
  country: QB_DEFAULT_COUNTRY_VALUE,
  members: QB_DEFAULT_MEMBERS_VALUE,
  connectionType: QB_DEFAULT_TYPE_VALUE,
  requestType: QB_DEFAULT_TYPE_VALUE,

  error: {},
  success: {},

  ranking: 0
}

export const EXCHANGE_PARTNER_DISPATCH = {
  EXCHANGE_CONNECTION_ACTION: 'exchangeConnectionAction',
  REWARD_TYPE: 'rewardType',
  REGION: 'region',
  COUNTRY: 'country',
  MEMBERS: 'members',
  SORTING: 'sorting',
  CONNECTION_TYPE: 'connectionType',
  REQUEST_TYPE: 'requestType',
  ERROR: 'error',
  SUCCESS: 'success'
}

const changePartnersSorting = (value, state) => {
  const tabs = [
    EXCHANGE_PARTNER_TYPES.POTENTIAL,
    EXCHANGE_PARTNER_TYPES.ACTIVE,
    EXCHANGE_PARTNER_TYPES.REQUESTS
  ]
  const result = {}
  tabs.forEach((tab) => {
    const dataType = state[tab]
    result[tab] = {
      ...dataType,
      filteredData: sortPotentialPartnersData(dataType?.filteredData, {
        sorting: value
      })
    }
  })
  return result
}

const changePartnersFilter = ({ tab, value, slug }, state) => {
  const dataType = state[tab]
  const partners = dataType?.data
  if (value === QB_DEFAULT_TYPE_VALUE) {
    return {
      [tab]: {
        ...dataType,
        filteredData: sortPotentialPartnersData(partners, state)
      }
    }
  }
  return {
    [tab]: {
      ...dataType,
      filteredData: sortPotentialPartnersData(
        filterPartnersByFromOrTo(partners, value === slug),
        state
      )
    }
  }
}

const parseConnectionFailure = (payload) => {
  const errorMessage = payload?.response?.errors?.detail
  switch (errorMessage) {
    case EXCHANGE_CONNECTION_API_ERRORS.REJECTED:
      return EXCHANGE_CONNECTION_ACTION.ERROR_REJECTED
    case EXCHANGE_CONNECTION_API_ERRORS.CANCELLED:
    case EXCHANGE_CONNECTION_API_ERRORS.STOPPED:
      return EXCHANGE_CONNECTION_ACTION.ERROR_DISCONNECTED
    default:
      return EXCHANGE_CONNECTION_ACTION.ERROR_REJECTED
  }
}

const getSortedAndFilteredData = ({ tab, property, value }, state) => {
  switch (property) {
    case EXCHANGE_PARTNER_DISPATCH.SORTING:
      return changePartnersSorting(value, state)
    case EXCHANGE_PARTNER_DISPATCH.CONNECTION_TYPE:
      return changePartnersFilter(
        { tab, value, slug: EXCHANGE_CONNECTION_TYPES.YOU_CONNECTED_WITH },
        state
      )
    case EXCHANGE_PARTNER_DISPATCH.REQUEST_TYPE:
      return changePartnersFilter(
        { tab, value, slug: EXCHANGE_REQUEST_TYPES.REQUESTS_YOU_SENT },
        state
      )
    case EXCHANGE_PARTNER_DISPATCH.REGION:
      return {
        country: QB_DEFAULT_COUNTRY_VALUE
      }
    default:
      return {}
  }
}

const updateNftSupply = (data, nftId, supply) => {
  return data.id === nftId ? { ...data, supply: data.supply - supply } : data
}

const filterPartners = (data, id) => {
  return data.filter((data) => data.id !== id)
}
