import React, { memo } from 'react'
import { DialogContent, DialogActions, Box } from '@mui/material'

import CustomButton from '../buttons/CustomButton'
import { QB_ACTION_BUTTON, QB_ACTION_SUBMIT } from '../../../constants/input'
import CustomForm from '../forms/customForm/CustomForm'
import CustomTooltip from '../CustomTooltip/CustomTooltip'

const CustomDialogContent = ({
  body,
  onClose,
  onSubmit,
  t,
  cancelProps,
  submitProps,
  additionalActions,
  customCss,
  isForm,
  actionStyles
}) => {
  const { disabledMessage, ...restSubmitProps } = submitProps
  const submitStyleProps = {
    variant: 'contained',
    fullWidth: false,
    ...restSubmitProps
  }

  const dialogContent = (
    <>
      {body && (
        <DialogContent sx={{ pt: 2, pb: 0, pr: 4, pl: 4, ...customCss }}>
          {body}
        </DialogContent>
      )}
      <DialogActions sx={{ p: 4, ...actionStyles }}>
        {onClose && (
          <CustomButton
            label={t('common.close')}
            onClick={onClose}
            color='inherit'
            fullWidth={false}
            variant=''
            {...cancelProps}
          />
        )}
        <Box sx={{ flex: '1 1 auto' }} />
        {Object.keys(submitProps).length ? (
          <>
            {additionalActions}
            <CustomTooltip label={disabledMessage}>
              {isForm ? (
                <CustomButton type={QB_ACTION_SUBMIT} {...submitStyleProps} />
              ) : (
                <CustomButton
                  type={QB_ACTION_BUTTON}
                  onClick={onSubmit}
                  {...submitStyleProps}
                />
              )}
            </CustomTooltip>
          </>
        ) : (
          <></>
        )}
      </DialogActions>
    </>
  )
  return isForm ? (
    <CustomForm onFormSubmit={onSubmit}>{dialogContent}</CustomForm>
  ) : (
    dialogContent
  )
}

CustomDialogContent.defaultProps = {
  submitProps: {}
}

export default memo(CustomDialogContent)
