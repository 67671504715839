import moment from 'moment'
import { ADMIN_TAB_TYPES } from '../constants/admin'

import {
  buildPricingPlans,
  getPricingPlanKeys,
  PACKAGE_DETAILS
} from '../data/pricing'
import {
  formatUnixToShortDate,
  getCleanedUnixStartDate,
  getCleanedUnixEndDate,
  convertUnix
} from './date.helpers'
import { percentageOfCompletion } from './number.helpers'
import { STRIPE_PAYMENT_STATUS } from '../constants/billing'
import { DAY, BILLING_ISO_DATE_FORMAT } from '../constants/date'
import { isTokenStopped } from './token.helpers'

const buildPackageActions = ({ isYearly, subscribedPackage }) => {
  if (subscribedPackage) {
    const actions = {}
    const pricingKeys = getPricingPlanKeys()
    const selectedPlan = subscribedPackage?.details?.name
    const selectedIsYearly = subscribedPackage?.details?.isYearly
    pricingKeys.forEach((plan, index) => {
      const checkYearlyCondition = !selectedIsYearly && isYearly
      actions[plan] = {
        isToUpgrade:
          checkYearlyCondition || index > pricingKeys.indexOf(selectedPlan),
        isSubscribed: checkYearlyCondition
          ? false
          : plan === selectedPlan || false
      }
    })
    return actions
  }
  return {}
}

const isStarterPackagePlan = (plan) => {
  return plan === PACKAGE_DETAILS.STARTER
}

const isPremiumPackagePlan = (plan) => {
  return plan === PACKAGE_DETAILS.PREMIUM
}

const isPackageSubscribed = (billingProfile) => {
  return Boolean(billingProfile?.subscribedPackage?.id)
}

const selectSubscribedPackage = (subscriptions, token) => {
  const stripeCustomerId = token?.stripeCustomerId
  const subscribedPackage = subscriptions?.invoice
  const details = getSubscribedPackageDetails(subscribedPackage)
  if (
    subscribedPackage &&
    subscribedPackage.clientId === stripeCustomerId &&
    details
  ) {
    subscribedPackage.currentPeriodStart = moment(subscribedPackage.date).unix()
    subscribedPackage.currentPeriodEnd = moment(
      convertUnix(subscribedPackage.currentPeriodStart)
        .add(1, 'months')
        .calendar(),
      BILLING_ISO_DATE_FORMAT
    ).unix()
    return {
      ...subscribedPackage,
      details
    }
  }
  return null
}

const getSubscribedPackageDetails = (subscribedPackage) => {
  const subscribedPriceId = subscribedPackage?.lineItems[0]?.productKey
  const packageDetails = buildPricingPlans().filter(({ priceId }) =>
    [priceId].includes(subscribedPriceId)
  )?.[0]
  if (subscribedPriceId && packageDetails) {
    // @TODO: Need to be decided on yearly/monthly
    // packageDetails.isYearly = isStarterPackagePlan(packageDetails?.name)
    //   ? false
    //   : priceId === packageDetails?.yearlyPriceId
    packageDetails.isYearly = isStarterPackagePlan(packageDetails?.name)
      ? false
      : true
    return packageDetails
  }
  return null
}

const displayExchangePeriodDate = (date) => {
  return date ? formatUnixToShortDate(date) : ''
}

const displayExchangeVolume = (subscribedPackage, exchangeVolume) => {
  return isStarterPackagePlan(subscribedPackage?.details?.name)
    ? 100
    : percentageOfCompletion(exchangeVolume, subscribedPackage?.details?.volume)
}

const buildExchangeDateRange = (subscribedPackage) => {
  const currentPeriodStart = subscribedPackage?.currentPeriodStart
  const currentPeriodEnd = subscribedPackage?.currentPeriodEnd
  if (currentPeriodStart && currentPeriodEnd) {
    return {
      from_time: getCleanedUnixStartDate(currentPeriodStart),
      to_time: getCleanedUnixEndDate(currentPeriodEnd)
    }
  }
  return {}
}

const calculateExchangeElapsedDays = (subscribedPackage) => {
  if (subscribedPackage?.id) {
    const { currentPeriodStart, currentPeriodEnd } = subscribedPackage
    const start = convertUnix(currentPeriodStart)
    const end = convertUnix(currentPeriodEnd)
    const totalDays = Math.round(moment.duration(end.diff(start)).asDays())
    const elapsedDays = Math.round(
      moment.duration(moment.utc().endOf(DAY).diff(start.startOf(DAY))).asDays()
    )
    const elapsedPercent = percentageOfCompletion(elapsedDays, totalDays)
    return {
      elapsedDays,
      elapsedPercent
    }
  }
  return {
    elapsedDays: 0,
    elapsedPercent: 0
  }
}

const calculateBuyerFee = (value, subscribedPackage) => {
  return value * (1 + subscribedPackage?.details?.fee)
}

const calculateSellerFee = (value, subscribedPackage) => {
  return calculateSaleFee(value, subscribedPackage?.details?.fee)
}

const calculateSaleFee = (value, fee) => {
  return value * (1 - fee)
}

const isBillingTabAndPackageSubscribed = (selectedTab, billingProfile) => {
  return (
    selectedTab === ADMIN_TAB_TYPES.PACKAGES_AND_BILLING &&
    isPackageSubscribed(billingProfile)
  )
}

const isBillingTabAndPackageSubscribedOrStoppedToken = (
  selectedTab,
  billingProfile,
  tokenProfile
) => {
  return (
    selectedTab === ADMIN_TAB_TYPES.PACKAGES_AND_BILLING &&
    (isPackageSubscribed(billingProfile) || isTokenStopped(tokenProfile.status))
  )
}

const isStripePaymentSuccess = (status) => {
  return status === STRIPE_PAYMENT_STATUS.ACTIVE
}

export {
  buildPackageActions,
  isStarterPackagePlan,
  isPremiumPackagePlan,
  isPackageSubscribed,
  selectSubscribedPackage,
  displayExchangePeriodDate,
  displayExchangeVolume,
  buildExchangeDateRange,
  calculateExchangeElapsedDays,
  calculateBuyerFee,
  calculateSellerFee,
  isBillingTabAndPackageSubscribed,
  getSubscribedPackageDetails,
  isStripePaymentSuccess,
  calculateSaleFee,
  isBillingTabAndPackageSubscribedOrStoppedToken
}
