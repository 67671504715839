import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Loading from '../../components/shared/Loading/Loading'
import DashboardSetupContainer from './DashboardSetupContainer'
import { DASHBOARD_SETUP_STATE } from '../../store/reducers/dashboard/dashboardSetup'
import { getTokens } from '../../store/actions/token'

const SetupContainer = ({
  dashboardSetupState,
  history,
  getTokens,
  billingProfile
}) => {
  useEffect(() => {
    if (!billingProfile.reactivationStarted) {
      getTokens(true)
    }
  }, [billingProfile.reactivationStarted])

  switch (dashboardSetupState) {
    case DASHBOARD_SETUP_STATE.LOADING:
      return <Loading />
    default:
      return <DashboardSetupContainer history={history} />
  }
}

const mapStateToProps = ({ dashboardReducer, mainReducer }) => {
  const { dashboardSetupProfile } = dashboardReducer
  const { billingProfile } = mainReducer
  return {
    dashboardSetupState: dashboardSetupProfile.dashboardSetupState,
    billingProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTokens
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(SetupContainer)
