const DASHBOARD_SETUP_URL_TYPES = {
  BRAND: 'brand',
  LOYALTY_POINT: 'loyalty-point',
  PACKAGE: 'package',
  STATUS: 'status'
}

const MILESTONE_ANIMATION_KEYS = {
  DASHBOARD_SETUP: 'dashboardSetup',
  ACTIVE_EXCHANGE_PARTNERSHIPS_3: 'activeExchangePartnerships-3',
  TOTAL_LIKES_NUMBER_10: 'totalLikesNumber-10',
  RECORD_EXCHANGE_TRANSACTIONS_100: 'recordExchangeTransactions-100',
  ACTIVE_EXCHANGE_PARTNERSHIPS_10: 'activeExchangePartnerships-10',
  TOTAL_VALUE_EXCHANGED_1000: 'totalValueExchanged-1000',
  TOTAL_VALUE_EXCHANGED_5000: 'totalValueExchanged-5000',
  TOTAL_VALUE_EXCHANGED_20000: 'totalValueExchanged-20000',
  TOTAL_NFTS_SUPPLY_100: 'totalNftsSupply-100'
}

const SORTING_OPTIONS = {
  RANKING: 'ranking',
  ALPHABETICAL: 'alphabetical',
  RECENTLY_ADDED: 'recently-added'
}

export { DASHBOARD_SETUP_URL_TYPES, MILESTONE_ANIMATION_KEYS, SORTING_OPTIONS }
