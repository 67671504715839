import { combineReducers } from 'redux'

import { analyticsFilterProfile } from './analyticsFilter'
import { pointsBankProfile } from './pointsBank'
import { pointsExchangeProfile } from './pointsExchange'

export const analyticsReducer = combineReducers({
  analyticsFilterProfile,
  pointsBankProfile,
  pointsExchangeProfile
})
