import {
  catchError,
  map,
  tap,
  mapTo,
  switchMap,
  mergeMap,
  filter
} from 'rxjs/operators'
import { of, combineLatest, forkJoin } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'

import apiService from '../../services/api'

import {
  AUTH_ACTION,
  ACCOUNT_ACTION,
  MAIN_PROFILE_ACTION,
  AUTH_LOGIN_ACTION
} from '../../constants/actions'

import { wrapUserAccessToken } from '../../util/auth.helpers'
import i18n from '../../i18n'
import { formatBrands, formatTokens } from '../../util/epics.helpers'
import { logger } from '../../util/log.helpers'
import {
  formatBrandTokens,
  getSelectedToken,
  getTokensAvailableBrandId,
  sortTokensByStatus
} from '../../util/token.helpers'
import {
  getBrandAndTokenIds,
  setBrandAndTokenIds
} from '../../util/local.helpers'
import { isExistsAtLeastOneBrandToken } from '../../util/brand.helpers'
import {
  buildWhitelistTokenTypesExchange,
  formatPartners
} from '../../util/exchange.helpers'
import { commonParser } from '../../util/apiParser.helpers'
import { ALL_TOKENS_EXCEPT_NFT } from '../../constants/token'
import { formatSurveysToObject } from '../../util/survey.helpers'

// MAIN - AUTH RELATED -> START MAIN LOADING
const handleStartMainLoad = (action$) =>
  action$.pipe(
    ofType(
      AUTH_LOGIN_ACTION.ON_LOG_IN_SUCCESS,
      AUTH_ACTION.ON_GET_CURRENT_USER_SUCCESS
    ),
    mapTo({ type: MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA })
  )

// MAIN PROFILE
const handleMainLoadAfterUserAccount = (action$) =>
  action$.pipe(
    ofType(
      ACCOUNT_ACTION.ON_GET_ACCOUNT_DETAILS_SUCCESS,
      ACCOUNT_ACTION.ON_CREATE_ACCOUNT_SUCCESS
    ),
    tap((userAccountData) => logger(userAccountData)),
    map(({ payload: { language } }) => ({
      language
    })),
    tap((userAccountData) => logger(userAccountData)),
    switchMap(({ language }) =>
      wrapUserAccessToken((accessToken) =>
        combineLatest([
          apiService.getBrands(accessToken),
          apiService.getDashboardConfigs(accessToken),
          apiService.getSurveys(accessToken)
        ]).pipe(
          tap((value) => logger(value)),
          map(([brandsResponse, dashboardConfigsResponse, surveysResponse]) => {
            i18n.changeLanguage(language)
            const brands = formatBrands(brandsResponse.response.data)
            const dashboardConfigs = commonParser(
              dashboardConfigsResponse.response.data
            )
            const surveys = formatSurveysToObject(surveysResponse.response.data)
            if (brands.length > 0) {
              return {
                type: MAIN_PROFILE_ACTION.ON_GET_USER_BRAND_TOKENS,
                payload: {
                  brands,
                  dashboardConfigs,
                  surveys
                }
              }
            }
            return {
              type: MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS,
              payload: {
                brands, // -> GOES TO reducers/main/mainProfile.brands,
                dashboardConfigs,
                surveys,
                brandTokens: {} // -> GOES TO reducers/main/mainProfile.brandTokens
              }
            }
          }),
          catchError((err) =>
            of({
              type: MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_FAILED,
              payload: err
            })
          )
        )
      )
    )
  )

const handleGetBrandTokens = (action$) =>
  action$.pipe(
    ofType(MAIN_PROFILE_ACTION.ON_GET_USER_BRAND_TOKENS),
    mergeMap(({ payload: { brands, ...restProps } }) =>
      wrapUserAccessToken((accessToken) =>
        getAllBrandTokens(brands, accessToken).pipe(
          map((brandTokensData) => {
            const brandTokens = formatBrandTokens(brandTokensData)
            const brandAndTokensIds = getBrandAndTokenIds()
            let currentBrand
            let currentToken
            const brandId = brandAndTokensIds?.brandId
              ? brandAndTokensIds?.brandId
              : getTokensAvailableBrandId(brandTokens)
            if (brandId && brandTokens[brandId]) {
              const { brand, tokens } = brandTokens[brandId]
              currentBrand = brand
              if (tokens && tokens.length > 0) {
                currentToken = getSelectedToken(
                  sortTokensByStatus(tokens),
                  brandAndTokensIds?.tokenId
                )
              }
            }
            if (isExistsAtLeastOneBrandToken(brandTokensData) && currentToken) {
              return {
                type: MAIN_PROFILE_ACTION.ON_GET_PAYMENT_SUBSCRIPTIONS_EXCHANGE_PARTNERS,
                payload: {
                  brands,
                  brandTokens,
                  currentToken,
                  currentBrand,
                  ...restProps
                }
              }
            }
            return {
              type: MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS,
              payload: {
                brands, // -> GOES TO reducers/main/mainProfile.brands
                brandTokens, // -> GOES TO reducers/main/mainProfile.brandTokens
                currentBrand,
                ...restProps
              }
            }
          }),
          catchError((err) =>
            of({
              type: MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_FAILED,
              payload: err
            })
          )
        )
      )
    )
  )

const handleLoadPaymentSubscriptionsAndPartnersEpic = (action$) =>
  action$.pipe(
    ofType(MAIN_PROFILE_ACTION.ON_GET_PAYMENT_SUBSCRIPTIONS_EXCHANGE_PARTNERS),
    filter(
      ({ payload: { currentBrand, currentToken } }) =>
        currentBrand?.id && currentToken?.id
    ),
    switchMap(({ payload: { currentBrand, currentToken, ...restProps } }) =>
      wrapUserAccessToken((accessToken) =>
        combineLatest([
          apiService.getPackageSubscriptions(currentToken.id, accessToken),
          apiService.getExchangeWhitelist(
            currentBrand.id,
            buildWhitelistTokenTypesExchange({ tokenId: currentToken.id }),
            accessToken
          )
        ]).pipe(
          tap((value) => logger(value)),
          map(([subscriptionsResponse, exchangePartnersResponse]) => {
            return {
              type: MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS,
              payload: {
                packageSubscriptions: commonParser(
                  subscriptionsResponse.response // @TODO: Need to change
                ), // -> GOES TO reducers/main/mainProfile.packageSubscriptions
                exchangePartners: formatPartners(
                  exchangePartnersResponse.response.data
                ), // -> GOES TO reducers/exchange/partnersProfile.exchangePartners
                currentBrand,
                currentToken,
                ...restProps
              }
            }
          }),
          catchError((err) => {
            return of({
              type: MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_FAILED,
              payload: err
            })
          })
        )
      )
    )
  )

const handleSwitchBrandAndTokenEpic = (action$) =>
  action$.pipe(
    ofType(MAIN_PROFILE_ACTION.ON_SWITCH_BRAND_AND_TOKEN),
    map(({ payload }) => {
      setBrandAndTokenIds(payload)
      return {
        type: MAIN_PROFILE_ACTION.ON_SWITCH_BRAND_AND_TOKEN_SUCCESS,
        payload
      }
    })
  )

const getAllBrandTokens = (brands, accessToken) => {
  return forkJoin(
    brands.map((brand) =>
      apiService
        .getTokens(brand.id, accessToken, { types: ALL_TOKENS_EXCEPT_NFT })
        .pipe(
          map(({ response }) => ({
            tokens: formatTokens(response.data),
            brand
          }))
        )
    )
  )
}

export const mainEpic = combineEpics(
  // MAIN APP LOAD
  handleStartMainLoad,

  // MAIN PROFILE
  handleMainLoadAfterUserAccount,
  handleGetBrandTokens,
  handleLoadPaymentSubscriptionsAndPartnersEpic,
  handleSwitchBrandAndTokenEpic
)
