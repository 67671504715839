import { NFTS_PROFILE_ACTIONS } from '../../../constants/actions'
import { mergeToNewObject } from '../../../util/object.helpers'
import { SORTING_OPTIONS } from '../../../constants/dashboard'
import {
  isNftListedListed,
  sortDataByDateDESC
} from '../../../util/nft.helpers'
import { sortRecordsByNameASC } from '../../../util/exchange.helpers'
import { NFT_SORTING_OPTIONS, NFT_STATUS_OPTIONS } from '../../../data/nfts'

export const nftsListProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case NFTS_PROFILE_ACTIONS.ON_GET_NFTS_SUCCESS:
      return mergeToNewObject(state, {
        nfts: payload,
        filteredNfts: sortNfts(state.sorting, payload)
      })

    case NFTS_PROFILE_ACTIONS.CHANGE_STATE:
      const { property, value } = payload
      return mergeToNewObject(state, {
        [payload.property]: payload.value,
        ...(property === NFTS_LIST_PROFILE_DISPATCH.SORTING && {
          filteredNfts: sortNfts(value, state.filteredNfts)
        }),
        ...(property === NFTS_LIST_PROFILE_DISPATCH.STATUS && {
          filteredNfts: filterNfts(value, state.nfts)
        })
      })

    case NFTS_PROFILE_ACTIONS.ON_CREATE_NFT_SUCCESS:
      return mergeToNewObject(state, {
        selectedNft: payload
      })

    case NFTS_PROFILE_ACTIONS.ON_UPDATE_NFT_SUCCESS:
      return mergeToNewObject(state, {
        nfts: state.nfts.map((nft) => (nft.id === payload.id ? payload : nft)),
        filteredNfts: state.filteredNfts.map((nft) =>
          nft.id === payload.id ? payload : nft
        ),
        selectedNft: payload
      })

    case NFTS_PROFILE_ACTIONS.ON_SELECT:
      return mergeToNewObject(state, {
        selectedNft: payload
      })

    case NFTS_PROFILE_ACTIONS.ON_RESET_LIST:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  nfts: [],
  filteredNfts: [],
  selectedNft: null,

  sorting: NFT_SORTING_OPTIONS.NEWEST,
  status: NFT_STATUS_OPTIONS.ALL
}

export const NFTS_LIST_PROFILE_DISPATCH = {
  SORTING: 'sorting',
  STATUS: 'status',
  SELECTED_NFT: 'selectedNft'
}

const sortNfts = (sorting, nfts) => {
  switch (sorting) {
    case SORTING_OPTIONS.ALPHABETICAL:
      return sortRecordsByNameASC(nfts)
    default:
      return sortDataByDateDESC(nfts)
  }
}

const filterNfts = (status, nfts) => {
  switch (status) {
    case NFT_STATUS_OPTIONS.CREATED:
      return nfts.filter((nft) => !isNftListedListed(nft))
    case NFT_STATUS_OPTIONS.LISTED:
      return nfts.filter((nft) => isNftListedListed(nft))
    default:
      return nfts
  }
}
