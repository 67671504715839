import React, { memo, useEffect, useState } from 'react'
import { useReward } from 'react-rewards'
import { Box } from '@mui/material'

const ConfettiAnimation = ({ id, onComplete }) => {
  const [rewardAnimation, setRewardAnimation] = useState()
  const { reward, isAnimating } = useReward(id, 'confetti', {
    lifetime: 270,
    decay: 0.9,
    startVelocity: 45,
    elementCount: 90,
    elementSize: 7,
    onAnimationComplete: onComplete
  })

  useEffect(() => {
    if (rewardAnimation && !isAnimating) {
      reward()
    }
  }, [rewardAnimation])

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center' }}
      ref={(ref) => setRewardAnimation(ref)}
      id={id}
    ></Box>
  )
}

export default memo(ConfettiAnimation)
