import { parseJson } from './app/app.helpers'

const getSupportedLanguages = () => {
  const supportedLanguagesEnv = process.env.REACT_APP_SUPPORTED_LANGUAGES
  return supportedLanguagesEnv ? parseJson(supportedLanguagesEnv) : []
}

const isMultiLanguage = () => {
  return getSupportedLanguages().length > 1
}

export { getSupportedLanguages, isMultiLanguage }
