import React, { memo } from 'react'
import { Trans } from 'react-i18next'
import { Grid } from '@mui/material'

import PageContainer from '../../shared/PageContainer/PageContainer'
import BaseForm from '../../shared/BaseForm/BaseForm'
import { BILLING_PROFILE_DISPATCH } from '../../../store/reducers/main/billing'
import MonthlyYearlySwitch from '../../shared/Billing/MonthlyYearlySwitch'
import Link from '../../shared/link/Link'
import {
  BILLING_FAQS_URL,
  YEARLY_MANUAL_BILLING_FORM_URL
} from '../../../constants/externalResources'
import DefaultText from '../../shared/text/DefaultText'
import CustomButton from '../../shared/buttons/CustomButton'
import { QB_LABEL_TEXT } from '../../../constants/messages'
import TestingCardDetailsNote from '../../shared/ImportantNote/TestingCardDetailsNote'
import { isProduction } from '../../../util/app/app.helpers'

const Package = ({ billingProfile, dispatchAction, onClickLeftButton, t }) => {
  const title = t('billing.subscription-plan-packages')
  const isYearly = billingProfile.isYearly
  const isNonProductionEnv = !isProduction()
  return (
    <PageContainer title={title}>
      <BaseForm
        title={title}
        description={
          <>
            {t('billing.billing-subscription-desc')}
            &nbsp;
            <Trans i18nKey='billing.subscription-plan-packages-desc'>
              <Link isOutside label={t('common.faqs')} to={BILLING_FAQS_URL} />
            </Trans>
          </>
        }
        fields={
          <Grid container spacing={2}>
            <Grid item xs={12} md={isNonProductionEnv ? 6 : 12}>
              <MonthlyYearlySwitch
                t={t}
                checked={isYearly}
                onChange={(checked) =>
                  dispatchAction(BILLING_PROFILE_DISPATCH.IS_YEARLY, checked)
                }
              />
              {isYearly && (
                <DefaultText
                  label={
                    <Trans i18nKey='billing.yearly-package-invoice-details'>
                      <Link
                        isOutside
                        label={t('common.here')}
                        to={YEARLY_MANUAL_BILLING_FORM_URL}
                      />
                    </Trans>
                  }
                  sx={{ mb: 0 }}
                />
              )}
            </Grid>
            {isNonProductionEnv && (
              <Grid item xs={12} md={6}>
                <TestingCardDetailsNote />
              </Grid>
            )}
          </Grid>
        }
        actions={
          <CustomButton
            label={t(QB_LABEL_TEXT.BACK)}
            onClick={onClickLeftButton}
            color='inherit'
            fullWidth={false}
            variant=''
          />
        }
      />
    </PageContainer>
  )
}

export default memo(Package)
