import { ADMIN_ACTION } from '../../constants/actions'

const getTeamMembers = () => ({
  type: ADMIN_ACTION.ON_GET_TEAM_MEMBERS
})

const addTeamMember = (email) => ({
  type: ADMIN_ACTION.ON_ADD_TEAM_MEMBER,
  payload: email
})

const removeTeamMember = (accessControlId) => ({
  type: ADMIN_ACTION.ON_REMOVE_TEAM_MEMBER,
  payload: accessControlId
})

const changeTeamMembersInputAndErrorState = (property, value) => ({
  type: ADMIN_ACTION.CHANGE_INPUT_AND_ERROR_STATE,
  payload: {
    property,
    value
  }
})

const changeAdminState = (property, value) => ({
  type: ADMIN_ACTION.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

const resetAdminState = () => {
  return {
    type: ADMIN_ACTION.ON_RESET_STATE
  }
}

export {
  getTeamMembers,
  addTeamMember,
  removeTeamMember,
  changeTeamMembersInputAndErrorState,
  changeAdminState,
  resetAdminState
}
