import React, { useState } from 'react'
import { ErrorBoundary } from '@appsignal/react'
import 'react-perfect-scrollbar/dist/css/styles.css'

import { store } from './store'
import { getAppSignalConfig } from './constants/appConfig'
import Root from './Root'
import packageJson from '../package.json'
import { logAppVersion } from './util/log.helpers'

logAppVersion(packageJson)

const App = () => {
  const [userId, setUserId] = useState('')
  const userIdListener = () => {
    const currentUserId = store.getState().accountReducer.accountProfile.userId
    if (currentUserId && userId !== currentUserId) {
      setUserId(currentUserId)
    }
  }
  store.subscribe(userIdListener)
  return (
    <ErrorBoundary {...getAppSignalConfig(userId)}>
      <Root />
    </ErrorBoundary>
  )
}

export default App
