import { map, mergeMap, catchError, tap, filter } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType, combineEpics } from 'redux-observable'

import { API_KEY_ACTIONS, MAIN_PROFILE_ACTION } from '../../constants/actions'
import apiService from '../../services/api'
import { wrapUserAccessToken } from '../../util/auth.helpers'
import { extractBrandId, formatAPIKeys } from '../../util/epics.helpers'
import { commonParser } from '../../util/apiParser.helpers'
import { logger } from '../../util/log.helpers'
import { trim } from '../../util/string.helpers'
import { isTokenActive } from '../../util/token.helpers'

const handleGetAPIKeysEpic = (action$) =>
  action$.pipe(
    ofType(MAIN_PROFILE_ACTION.ON_LOAD_MAIN_DATA_SUCCESS),
    filter(({ payload: { currentToken } }) =>
      isTokenActive(currentToken?.status)
    ),
    mergeMap(({ payload: { currentBrand } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService.getAPIKeys(currentBrand.id, accessToken).pipe(
          tap((data) => logger(data)),
          map(({ response }) => ({
            type: API_KEY_ACTIONS.ON_GET_API_KEYS_SUCCESS,
            payload: formatAPIKeys(response.data)
          })),
          catchError(() =>
            of({
              type: API_KEY_ACTIONS.ON_GET_API_KEYS_FAILED
            })
          )
        )
      )
    )
  )

const handleCreateAPIKeyEpic = (action$, state$) =>
  action$.pipe(
    ofType(API_KEY_ACTIONS.ON_CREATE_API_KEY),
    mergeMap(() =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .createAPIKey(
            extractBrandId(state$),
            extractAPIKeyCreationData(state$),
            accessToken
          )
          .pipe(
            tap((data) => logger(data)),
            map(({ response }) => {
              const payload = commonParser(response.data)
              return {
                type: API_KEY_ACTIONS.ON_CREATE_API_KEY_SUCCESS,
                payload
              }
            }),
            catchError((error) =>
              of({
                type: API_KEY_ACTIONS.ON_CREATE_API_KEY_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

const handleDeleteAPIKeyEpic = (action$, state$) =>
  action$.pipe(
    ofType(API_KEY_ACTIONS.ON_DELETE_API_KEY),
    mergeMap(({ payload: { apiKeyId } }) =>
      wrapUserAccessToken((accessToken) =>
        apiService
          .deleteAPIKey(extractBrandId(state$), apiKeyId, accessToken)
          .pipe(
            tap((data) => logger(data)),
            map(() => ({
              type: API_KEY_ACTIONS.ON_DELETE_API_KEY_SUCCESS,
              payload: apiKeyId
            })),
            catchError((error) =>
              of({
                type: API_KEY_ACTIONS.ON_DELETE_API_KEY_FAILED,
                payload: error
              })
            )
          )
      )
    )
  )

export const apiKeyEpic = combineEpics(
  handleCreateAPIKeyEpic,
  handleDeleteAPIKeyEpic,
  handleGetAPIKeysEpic
)

const extractAPIKeyCreationData = (state) => {
  const { apiKeyFormProfile } = state.value.apiKeysReducer
  const { nameTemp } = apiKeyFormProfile
  return {
    brand_api_key: {
      name: trim(nameTemp)
    }
  }
}
