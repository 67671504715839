import React, { memo } from 'react'
import { Grid, Box } from '@mui/material'
import {
  faCircleCheck,
  faCircleEnvelope,
  faCirclePlus,
  faCircleXmark,
  // faGears,
  faGlobe,
  faInfoCircle,
  faPlusCircle,
  faQuestionCircle
} from '@fortawesome/pro-light-svg-icons'
import { faRankingStar, faHeart } from '@fortawesome/pro-solid-svg-icons'

import PageContainer from '../shared/PageContainer/PageContainer'
import DisplayAlerts from '../setup/common/DisplayAlerts'
import ImportantNote from '../shared/ImportantNote/ImportantNote'
import BaseCard from '../shared/BaseCard/BaseCard'
import PanelHeading from '../shared/PanelHeading/PanelHeading'
import TabContent from '../shared/TabContent/TabContent'
import ExchangeSettings from './ExchangeSettings'
import TabDescription from '../shared/TabContent/TabDescription'
import ExchangePartnerCard from './ExchangePartnerCard'
import ExchangeNftCard from './ExchangeNftCard'
import SmallButton from '../shared/buttons/SmallButton'
import { EXCHANGE_MARKETPLACE_GUIDE_URL } from '../../util/support.helpers'
import { EXCHANGE_PARTNER_TYPES } from '../../constants/exchange'
import { Trans } from 'react-i18next'
import { POINTS_EXCHANGE_API_URL } from '../../constants/externalResources'
import Link from '../shared/link/Link'
import DefaultText from '../shared/text/DefaultText'
import { EXCHANGE_MARKETPLACE_PROCESS_STEP } from '../../constants/containerStates'
import CustomButton from '../shared/buttons/CustomButton'
import {
  buildConnectionTypesOptions,
  buildRequestTypesOptions,
  fromExchangeTypeToTabId,
  isBrandsYouLoveSelected,
  selectExchangePartner
} from '../../util/exchange.helpers'
import { EXCHANGE_PARTNER_DISPATCH } from '../../store/reducers/exchange/partners'
import { LABEL_TEXT } from '../../constants/messages'
import FaIcon from '../shared/FaIcon/FaIcon'
import Text from '../shared/text/Text'
import ExchangeFilters from './ExchangeFilters'
import FiltersDropdown from '../shared/FiltersDropdown/FiltersDropdown'
import FixedTop from '../shared/FixedTop/FixedTop'
import DashboardCard from '../shared/DashboardCard/DashboardCard'
import ExchangeDisableTooltip from '../shared/CustomTooltip/ExchangeDisableTooltip'
import LoveBrandCard from '../shared/LoveBrandCard/LoveBrandCard'
import CardGrid from '../shared/containers/CardGrid'
import { LAD_SURVEY_IDS } from '../../constants/survey'
import {
  buildLoveBrandCardProps,
  sortOptionsByTitleASC,
  checkBuyPointsConditions
} from '../../util/extensions/extensions.helpers'
import CardListGrid from '../shared/containers/CardListGrid'
import { isTokenTypeNft } from '../../util/token.helpers'
import BrandsYouLoveFilters from './BrandsYouLoveFilters'
import ConnectBulkPartners from './ConnectBulkPartners'
import { buildMarketplaceSurvey } from '../../util/survey.helpers'
import CustomTooltip from '../shared/CustomTooltip/CustomTooltip'

const Exchange = ({
  isDashboardSetupReady,
  onCloseImportantNote,
  hideExchangeNote,
  t,
  selectedTab,
  onChangeTab,
  onExchangeSettingsSubmit,
  exchangeSettingsProfile,
  changeExchangeSettingsState,
  partnersProfile,
  onClickListYourPoint,
  onClickSuggestFavExchangeOption,
  tokenProfile,
  onDetailsClick,
  onBuyNftClick,
  onWebsiteClick,
  onConnectClick,
  onExchangeAction,
  onAcceptRequest,
  onChangeFilters,
  onViewRankInfo,
  billingProfile,
  exchangePartnersStats,
  surveyProfile: { surveys, userSurveys },
  extensionsProfile: { userSurveysLikesCount },
  onLikeUnlikeSurvey,
  exchangeIndex,
  setExchangeIndex,
  loveBrandDataProps,
  onSelectBulkPartners,
  bulkPartnersIds,
  onClearBulkPartners,
  onConnectBulkPartners
}) => {
  const selectedBrandsYouLove = isBrandsYouLoveSelected(selectedTab)
  const showImportantNote = (
    <ImportantNote
      t={t}
      title='exchange.important-note'
      guideUrl={EXCHANGE_MARKETPLACE_GUIDE_URL}
      onClose={onCloseImportantNote}
      closedImportantNote={hideExchangeNote}
    />
  )
  const title = t('exchange.rewards-marketplace')
  const brandsYouLoveSurvey =
    surveys[LAD_SURVEY_IDS.BRANDS_YOU_LOVE]?.questions?.en
  const { connectionType, requestType } = partnersProfile
  const buildContent = () => {
    switch (selectedTab) {
      case EXCHANGE_PARTNER_TYPES.POTENTIAL:
        return (
          <TabDescription
            t={t}
            description={t('exchange.potential-partners-desc')}
            actions={
              <>
                {!isDashboardSetupReady && (
                  <CustomButton
                    variant='contained'
                    fullWidth={false}
                    label={t('exchange.list-your-reward')}
                    onClick={onClickListYourPoint}
                  />
                )}
                <CustomButton
                  variant='outlined'
                  fullWidth={false}
                  label={t('exchange.suggest-favorite-reward')}
                  onClick={onClickSuggestFavExchangeOption}
                />
              </>
            }
          />
        )
      case EXCHANGE_PARTNER_TYPES.ACTIVE:
        return (
          <TabDescription
            t={t}
            description={
              <>
                <DefaultText
                  label={t('exchange.active-partners-desc')}
                  component='div'
                />
                <Trans i18nKey='exchange.active-partners-desc2'>
                  <Link
                    isOutside
                    label={t('exchange.api-documentation')}
                    to={POINTS_EXCHANGE_API_URL}
                  />
                </Trans>
              </>
            }
          />
        )
      case EXCHANGE_PARTNER_TYPES.REQUESTS:
        return (
          <TabDescription
            t={t}
            description={t('exchange.partner-requests-desc')}
          />
        )
      case EXCHANGE_PARTNER_TYPES.BRANDS_YOU_LOVE:
        return (
          <TabDescription
            t={t}
            description={brandsYouLoveSurvey?.description}
            body={<BrandsYouLoveFilters t={t} {...loveBrandDataProps} />}
          />
        )
      case EXCHANGE_PARTNER_TYPES.SETTINGS:
        return (
          <ExchangeSettings
            t={t}
            dispatchAction={changeExchangeSettingsState}
            onFormSubmit={onExchangeSettingsSubmit}
            exchangeSettingsProfile={exchangeSettingsProfile}
          />
        )
      default:
        return null
    }
  }
  const buildFilters = () => {
    switch (selectedTab) {
      case EXCHANGE_PARTNER_TYPES.POTENTIAL:
        return (
          <ExchangeFilters
            onChangeFilters={onChangeFilters}
            partnersProfile={partnersProfile}
            t={t}
          />
        )

      case EXCHANGE_PARTNER_TYPES.ACTIVE:
        return (
          <ExchangeFilters
            onChangeFilters={onChangeFilters}
            partnersProfile={partnersProfile}
            filters={
              <FiltersDropdown
                action={EXCHANGE_PARTNER_DISPATCH.CONNECTION_TYPE}
                label={LABEL_TEXT.CONNECTION_TYPE}
                options={buildConnectionTypesOptions(t)}
                onChangeFilters={({ target }) =>
                  onChangeFilters(
                    EXCHANGE_PARTNER_DISPATCH.CONNECTION_TYPE,
                    target.value
                  )
                }
                value={connectionType}
                t={t}
              />
            }
            t={t}
          />
        )

      case EXCHANGE_PARTNER_TYPES.REQUESTS:
        return (
          <ExchangeFilters
            onChangeFilters={onChangeFilters}
            partnersProfile={partnersProfile}
            filters={
              <FiltersDropdown
                action={EXCHANGE_PARTNER_DISPATCH.REQUEST_TYPE}
                label={LABEL_TEXT.REQUEST_TYPE}
                options={buildRequestTypesOptions(t)}
                onChangeFilters={({ target }) =>
                  onChangeFilters(
                    EXCHANGE_PARTNER_DISPATCH.REQUEST_TYPE,
                    target.value
                  )
                }
                value={requestType}
                t={t}
              />
            }
            t={t}
          />
        )
    }
  }

  const buildPartnersList = () => {
    switch (selectedTab) {
      case EXCHANGE_PARTNER_TYPES.POTENTIAL:
        return partnersProfile.potential.filteredData.map((data, index) => {
          if (isTokenTypeNft(data.type)) {
            return (
              <ExchangeNftCard
                key={index}
                t={t}
                partner={data}
                onDetailsClick={onDetailsClick}
                onBuyNftClick={onBuyNftClick}
              />
            )
          }
          const activeStep = exchangeIndex[data.id] || 0
          const { isCryptoActionsAvailable, partner } = selectExchangePartner(
            data,
            activeStep
          )
          const exchangeTerms = surveys[buildMarketplaceSurvey(partner)]
          const disabledMessage = checkBuyPointsConditions(
            {
              partner,
              tokenProfile
            },
            exchangePartnersStats
          )
          const isDisabled = Boolean(disabledMessage)
          return (
            <ExchangePartnerCard
              key={index}
              id={data.id}
              t={t}
              tokenProfile={tokenProfile}
              partner={partner}
              onSelectBulkPartners={onSelectBulkPartners}
              bulkPartnersIds={bulkPartnersIds}
              setActiveStep={setExchangeIndex}
              activeStep={activeStep}
              isCryptoActionsAvailable={isCryptoActionsAvailable}
              onDetailsClick={onDetailsClick}
              disableMultiSelect={
                partnersProfile.rejectedPartners[partner.id] ||
                Boolean(exchangeTerms) ||
                isDisabled
              }
              actions={
                <>
                  <SmallButton
                    label={t('common.details')}
                    icon={faInfoCircle}
                    onClick={() => onDetailsClick(partner)}
                    variant='outlined'
                  />
                  <SmallButton
                    label={t('common.website')}
                    icon={faGlobe}
                    onClick={() => onWebsiteClick(partner)}
                    variant='outlined'
                  />
                  <CustomTooltip
                    label={disabledMessage}
                    parentProps={{ sx: { width: '100%' } }}
                    showIfLength={isDisabled && undefined}
                  >
                    <SmallButton
                      label={t('common.connect')}
                      icon={faPlusCircle}
                      color='success'
                      onClick={() => onConnectClick(partner)}
                      disabled={Boolean(selectedPartnersLength) || isDisabled}
                    />
                  </CustomTooltip>
                </>
              }
            />
          )
        })
      case EXCHANGE_PARTNER_TYPES.ACTIVE:
        const isDisableRemove = partnersProfile.active.data.length === 1
        return partnersProfile[EXCHANGE_PARTNER_TYPES.ACTIVE].filteredData.map(
          (data, index) => {
            const activeStep = exchangeIndex[data.id] || 0
            const { isCryptoActionsAvailable, partner } = selectExchangePartner(
              data,
              activeStep
            )
            return (
              <ExchangePartnerCard
                key={index}
                id={data.id}
                t={t}
                tokenProfile={tokenProfile}
                onDetailsClick={onDetailsClick}
                partner={partner}
                setActiveStep={setExchangeIndex}
                activeStep={activeStep}
                isCryptoActionsAvailable={isCryptoActionsAvailable}
                actions={
                  <>
                    <SmallButton
                      label={t('common.details')}
                      icon={faInfoCircle}
                      onClick={() => onDetailsClick(partner)}
                    />
                    <SmallButton
                      label={t('common.website')}
                      icon={faGlobe}
                      onClick={() => onWebsiteClick(partner)}
                      variant='outlined'
                    />
                    <ExchangeDisableTooltip disabled={isDisableRemove} t={t}>
                      <SmallButton
                        disabled={isDisableRemove}
                        label={t('exchange.remove')}
                        icon={faCircleXmark}
                        color='error'
                        variant='outlined'
                        onClick={() =>
                          onExchangeAction(
                            partner,
                            EXCHANGE_MARKETPLACE_PROCESS_STEP.REMOVE_EXCHANGE_REQUEST
                          )
                        }
                      />
                    </ExchangeDisableTooltip>
                  </>
                }
              />
            )
          }
        )
      case EXCHANGE_PARTNER_TYPES.REQUESTS:
        return partnersProfile[
          EXCHANGE_PARTNER_TYPES.REQUESTS
        ].filteredData.map((data, index) => {
          const activeStep = exchangeIndex[data.id] || 0
          const { isCryptoActionsAvailable, partner } = selectExchangePartner(
            data,
            activeStep
          )
          return (
            <ExchangePartnerCard
              key={index}
              id={data.id}
              t={t}
              tokenProfile={tokenProfile}
              onDetailsClick={onDetailsClick}
              partner={partner}
              setActiveStep={setExchangeIndex}
              activeStep={activeStep}
              isCryptoActionsAvailable={isCryptoActionsAvailable}
              actions={
                <>
                  {partner?.isFrom ? (
                    <>
                      <SmallButton
                        label={t('common.details')}
                        icon={faInfoCircle}
                        onClick={() => onDetailsClick(partner)}
                      />
                      <SmallButton
                        label={t('common.cancel')}
                        icon={faCircleXmark}
                        color='error'
                        variant='outlined'
                        onClick={() =>
                          onExchangeAction(
                            partner,
                            EXCHANGE_MARKETPLACE_PROCESS_STEP.CANCEL_EXCHANGE_REQUEST
                          )
                        }
                      />
                    </>
                  ) : (
                    <>
                      <SmallButton
                        label={t('common.details')}
                        icon={faInfoCircle}
                        onClick={() => onDetailsClick(partner)}
                        variant='outlined'
                      />
                      <SmallButton
                        label={t('common.accept')}
                        icon={faCircleCheck}
                        onClick={() => onAcceptRequest(partner)}
                        color='success'
                      />
                      <SmallButton
                        label={t('common.reject')}
                        icon={faCircleXmark}
                        color='error'
                        variant='outlined'
                        onClick={() =>
                          onExchangeAction(
                            partner,
                            EXCHANGE_MARKETPLACE_PROCESS_STEP.REJECT_EXCHANGE_REQUEST
                          )
                        }
                      />
                    </>
                  )}
                </>
              }
            />
          )
        })
      case EXCHANGE_PARTNER_TYPES.BRANDS_YOU_LOVE:
        return sortOptionsByTitleASC(
          loveBrandDataProps.loveBrandsFiltersProfile.filteredData
        )?.map(({ id, ...question }, index) => (
          <LoveBrandCard
            t={t}
            key={id + index}
            {...buildLoveBrandCardProps(
              LAD_SURVEY_IDS.BRANDS_YOU_LOVE,
              {
                userSurveys,
                userSurveysLikesCount,
                id
              },
              onLikeUnlikeSurvey
            )}
            {...question}
          />
        ))

      case EXCHANGE_PARTNER_TYPES.SETTINGS:
        return null
      default:
        return null
    }
  }
  const selectedPartnersLength = Object.keys(bulkPartnersIds).length
  return (
    <PageContainer title={title}>
      <DisplayAlerts
        isDashboardSetupReady={isDashboardSetupReady}
        tokenStatus={tokenProfile.status}
        subscribedPackage={billingProfile.subscribedPackage}
        t={t}
      />
      {!hideExchangeNote && showImportantNote}
      <CardGrid>
        <Grid item xs={12}>
          <BaseCard>
            <PanelHeading
              title={title}
              button={
                isDashboardSetupReady && (
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <CustomButton
                      label={<FaIcon icon={faQuestionCircle} />}
                      onClick={onViewRankInfo}
                      variant='text'
                      color='inherit'
                      fullWidth={false}
                      noPadding
                      sx={{ lineHeight: 2, minWidth: '35px' }}
                    />
                    <Text label={`${t('exchange.your-rank')}: `} variant='h4' />
                    <Text
                      label={partnersProfile.ranking}
                      variant='h4'
                      sx={{ ml: '5px', mr: '5px', fontWeight: '500' }}
                    />
                    <FaIcon icon={faRankingStar} />
                  </Box>
                )
              }
            >
              <TabContent
                selectedTab={fromExchangeTypeToTabId(selectedTab)}
                onChangeTab={onChangeTab}
                menuItems={tabMenuItems(
                  isDashboardSetupReady,
                  exchangePartnersStats,
                  brandsYouLoveSurvey?.title
                )}
                t={t}
                rootProps={{ mt: 1 }}
                content={buildContent()}
                tabStyles={customTabStyles(selectedBrandsYouLove)}
              />
            </PanelHeading>
          </BaseCard>
        </Grid>
        {!selectedBrandsYouLove && (
          <Grid item xs={12} sx={{ maxHeight: '145px' }}>
            <FixedTop>
              <DashboardCard
                sx={{ pl: '30px', pt: '30px', pb: 0 }}
                contentProps={{ pt: 2 }}
              >
                {buildFilters()}
              </DashboardCard>
            </FixedTop>
          </Grid>
        )}
        <CardListGrid>{buildPartnersList()}</CardListGrid>
        {selectedPartnersLength > 0 && (
          <ConnectBulkPartners
            t={t}
            selectedPartnersLength={selectedPartnersLength}
            onClear={onClearBulkPartners}
            onConnectAll={onConnectBulkPartners}
            isProcessing={partnersProfile.isProcessing}
          />
        )}
      </CardGrid>
      {hideExchangeNote && showImportantNote}
    </PageContainer>
  )
}

export default memo(Exchange)

const tabMenuItems = (
  isDashboardSetupReady,
  { activeCount, incomingRequests },
  brandsYouLoveTitle
) => {
  const isShowGuidance = isDashboardSetupReady && !activeCount > 0
  return [
    {
      value: 'potential-partners',
      label: 'exchange.potential-partners',
      icon: faCirclePlus,
      isShowGuidance
    },
    {
      value: 'active-partners',
      label: 'exchange.active-partners',
      icon: faCircleCheck
    },
    {
      value: 'partner-requests',
      label: 'exchange.partner-requests',
      icon: faCircleEnvelope,
      isShowGuidance: incomingRequests.length > 0
    },
    {
      value: 'brands-you-love',
      label: brandsYouLoveTitle,
      noTranslation: true,
      icon: faHeart
    }
    // @TODO: Need to enable this when rewards marketplace settings is ready
    // { label: 'exchange.exchange-settings', icon: faGears }
  ]
}

const customTabStyles = (selectedBrandsYouLove) => {
  return selectedBrandsYouLove
    ? {
        sx: {
          '& button.Mui-selected': { color: 'error.main' }
        },
        TabIndicatorProps: {
          sx: {
            backgroundColor: 'error.main'
          }
        }
      }
    : {}
}
