import React, { memo } from 'react'
import { faUsers } from '@fortawesome/pro-light-svg-icons'

import ActivityCard from '../shared/ActivityCard/ActivityCard'
import { COLOR_PALETTE } from '../../constants/theme'
import CustomSkeleton from '../shared/CustomSkeleton/CustomSkeleton'

const UserStatistics = ({ isProcessing, value, ...restProps }) => {
  return (
    <ActivityCard
      heading={isProcessing ? <CustomSkeleton /> : value}
      {...restProps}
      icon={faUsers}
      color={COLOR_PALETTE.ORANGE}
    />
  )
}

export default memo(UserStatistics)
