import { NFT_EXCLUSIVE_CONTENT_MODE } from '../constants/nfts'
import { buildDropdownList } from '../util/app/app.helpers'

const NFT_TYPES_OPTION_DATA = {
  membership: 'membership',
  badge: 'badge',
  status: 'status',
  collectible: 'collectible',
  perk: 'perk',
  redeemable_reward: 'redeemable-reward',
  ticket: 'ticket',
  invitation: 'invitation'
}

export const NFT_SORTING_OPTIONS = {
  NEWEST: 'newest',
  ALPHABETICAL: 'alphabetical'
}

export const NFT_STATUS_OPTIONS = {
  ALL: 'all',
  CREATED: 'created',
  LISTED: 'listed'
}

export const NFT_YES_NO_OPTIONS = {
  YES: 'yes',
  NO: 'no'
}

const buildNftTypeOptions = (t) => {
  return Object.keys(NFT_TYPES_OPTION_DATA).map((key) => ({
    value: key,
    label: t(`nft.nft-types.${NFT_TYPES_OPTION_DATA[key]}`)
  }))
}

const buildNftSortingOptions = (t) => {
  return buildDropdownList(NFT_SORTING_OPTIONS, 'nft.nft-sorting-types', t)
}

const buildNftStatusOptions = (t) => {
  return buildDropdownList(NFT_STATUS_OPTIONS, 'nft.nft-status-types', t)
}

const buildYesNoOptions = (t) => {
  return buildDropdownList(NFT_YES_NO_OPTIONS, 'nft.yes-no-options', t)
}

const buildExclusiveContentModeOptions = (t) => {
  return buildDropdownList(
    NFT_EXCLUSIVE_CONTENT_MODE,
    'nft.exclusive-content-mode-filters',
    t
  )
}

export {
  buildNftTypeOptions,
  buildNftSortingOptions,
  buildNftStatusOptions,
  buildYesNoOptions,
  buildExclusiveContentModeOptions
}
