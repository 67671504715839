import React, { memo } from 'react'
import { faExchange } from '@fortawesome/pro-light-svg-icons'

import { formatTicks } from '../../util/string.helpers'
import ActivityChart from '../shared/ActivityChart/ActivityChart'
import { sumOfArrayElements } from '../../util/app/app.helpers'
import { COLOR_PALETTE } from '../../constants/theme'

const ExchangeActivityChart = ({
  exchangeActivityChart: { data, labels },
  exchangeTxCount,
  t
}) => {
  const sumOfActivity = sumOfArrayElements(data)
  return (
    <ActivityChart
      title={t('home.exchange-activity')}
      tooltipText={t('home.total-exchange-transactions')}
      icon={faExchange}
      heading={formatTicks(exchangeTxCount)}
      hasNoData={sumOfActivity === 0}
      customPaddingColor='128,208,241'
      data={data}
      labels={labels}
      colors={[COLOR_PALETTE.PRIMARY]}
      t={t}
    />
  )
}

export default memo(ExchangeActivityChart)
