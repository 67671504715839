import React, { memo } from 'react'
import { Dialog, IconButton } from '@mui/material'
import { faX } from '@fortawesome/pro-solid-svg-icons'

import FaIcon from '../FaIcon/FaIcon'

const CustomModal = ({ open, onClose, maxWidth, content }) => {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      fullWidth
      PaperProps={{ sx: { borderRadius: '5px' } }}
      maxWidth={maxWidth}
    >
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.common.black,
            backgroundColor: (theme) => theme.palette.common.white,
            border: (theme) => `1px solid ${theme.palette.common.black}`,
            width: 36,
            height: 36
          }}
        >
          <FaIcon icon={faX} width={16} />
        </IconButton>
      ) : null}
      {content}
    </Dialog>
  )
}

export default memo(CustomModal)
