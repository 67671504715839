import { QB_COGNITO_ERRORS } from '../../../constants/apiErrors'
import { ERROR_TEXT, QB_ERROR_TEXT } from '../../../constants/messages'
import {
  mergeToNewObject,
  extractInputValueAndError
} from '../../../util/object.helpers'
import { formatValidator } from '../../../util/validation.helpers'
import { AUTH_RESET_PASSWORD_ACTION } from './../../../constants/actions'

export const resetPasswordProfile = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case AUTH_RESET_PASSWORD_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case AUTH_RESET_PASSWORD_ACTION.CHANGE_INPUT_AND_ERROR_STATE:
      return mergeToNewObject(
        state,
        extractInputValueAndError(payload, state.error)
      )

    case AUTH_RESET_PASSWORD_ACTION.ON_RESET_PASSWORD:
      return mergeToNewObject(state, { isProcessing: true })

    case AUTH_RESET_PASSWORD_ACTION.ON_RESET_PASSWORD_SUCCESS:
      return mergeToNewObject(state, {
        resetSuccess: true,
        isProcessing: false
      })

    case AUTH_RESET_PASSWORD_ACTION.ON_RESET_PASSWORD_FAILED:
      return mergeToNewObject(state, {
        error: extractErrors(payload),
        isProcessing: false
      })

    case AUTH_RESET_PASSWORD_ACTION.RESET_STATE:
      return INITIAL_STATE

    default:
      return state
  }
}

const INITIAL_STATE = {
  newPassword: '',
  resetSuccess: false,
  error: {},
  isProcessing: false
}

const extractErrors = ({ code }) => {
  switch (code) {
    case QB_COGNITO_ERRORS.EXPIRED_CODE_EXCEPTION:
    case QB_COGNITO_ERRORS.CODE_MISMATCH_EXCEPTION:
      return {
        newPassword: formatValidator(ERROR_TEXT.PASSWORD_LINK_EXPIRED)
      }
    default:
      return {
        newPassword: formatValidator(QB_ERROR_TEXT.SOMETHING_WENT_WRONG)
      }
  }
}

export const RESET_PASSWORD_PROFILE_DISPATCH = {
  NEW_PASSWORD: 'newPassword',
  RESET_SUCCESS: 'resetSuccess',
  ERROR: 'error'
}
