import React, { memo } from 'react'

import { buildTokenNameSymbol } from '../../util/token.helpers'
import TitleCaptionText from '../shared/text/TitleCaptionText'

const BrandTokenLabel = ({ details: { brandName, tokenName, symbol } }) => {
  return (
    <TitleCaptionText
      title={buildTokenNameSymbol({ name: tokenName, symbol })}
      caption={brandName}
    />
  )
}

export default memo(BrandTokenLabel)
