import React, { memo } from 'react'

import FaIcon from '../FaIcon/FaIcon'
import CustomButton from './CustomButton'

const CustomIconButton = ({ icon, iconStyles, sx, ...restProps }) => {
  return (
    <CustomButton
      {...restProps}
      sx={{
        width: 'auto',
        height: '40px',
        minWidth: 'auto',
        ...sx
      }}
      label={<FaIcon icon={icon} {...iconStyles} />}
    />
  )
}

export default memo(CustomIconButton)
