import React, { memo } from 'react'
import { Avatar } from '@mui/material'

const UserProfileAvatar = ({
  size,
  children,
  plainBg,
  src,
  sx,
  ...restProps
}) => {
  const bgStyle =
    plainBg && !src
      ? { backgroundColor: ({ palette: { grey } }) => grey[700] }
      : src
      ? { backgroundColor: ({ palette: { common } }) => common.white }
      : {
          backgroundImage: ({ palette: { primary, secondary } }) =>
            `linear-gradient(to bottom, ${primary.main}, ${secondary.main})`
        }
  return (
    <Avatar
      sx={{
        width: size,
        height: size,
        color: 'white',
        ...bgStyle,
        ...sx
      }}
      src={src}
      {...restProps}
    >
      {children}
    </Avatar>
  )
}

export default memo(UserProfileAvatar)
