import React, { memo } from 'react'
import { IconButton } from '@mui/material'
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons'

import FaIcon from '../FaIcon/FaIcon'
import CustomTooltip from '../CustomTooltip/CustomTooltip'

const PopoverHelpText = ({ label }) => {
  return (
    <CustomTooltip label={label}>
      <IconButton
        sx={{
          p: 0.375,
          fontSize: 'inherit',
          '&:hover': { backgroundColor: 'transparent' }
        }}
        disableRipple
      >
        <FaIcon icon={faCircleQuestion} width={14} />
      </IconButton>
    </CustomTooltip>
  )
}

export default memo(PopoverHelpText)
