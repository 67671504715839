import React, { memo } from 'react'
import { Grid } from '@mui/material'

import DisplayLabelFormat from '../../shared/DisplayLabel/DisplayLabelFormat'
import SquareChip from '../../shared/Chip/SquareChip'
import { getPackageDetails } from '../../../data/pricing'
import { toUpper } from '../../../util/string.helpers'
import {
  displayCurrencyValue,
  formatPercentage
} from '../../../util/number.helpers'
import { displayExchangePeriodDate } from '../../../util/billing.helpers'

const ExchangeDetails = ({ t, subscribedPackage }) => {
  const packageName = subscribedPackage?.details?.name
  const packageDetails = getPackageDetails(packageName)
  const fee = formatPercentage(packageDetails?.fee)
  const data = [
    {
      label: 'billing.exchange-volume-included',
      values: [displayCurrencyValue(packageDetails?.volume)],
      color: 'success'
    },
    {
      label: 'billing.sellers-fee',
      values: fee ? [`${fee}%`] : [],
      color: 'primary'
    },
    {
      label: 'billing.your-subscription-plan',
      values: [
        t('billing.package-name', {
          plan: toUpper(packageName)
        })
      ],
      color: 'info'
    }
  ]

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={8}>
        <Grid container spacing={0}>
          {data.map(({ label, ...restProps }, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <DisplayLabelGrid label={t(label)} {...restProps} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <DisplayLabelGrid
          label={t('billing.current-exchange-period')}
          values={[
            `${displayExchangePeriodDate(
              subscribedPackage?.currentPeriodStart
            )} - ${displayExchangePeriodDate(
              subscribedPackage?.currentPeriodEnd
            )}`
          ]}
          color='secondary'
        />
      </Grid>
    </Grid>
  )
}

export default memo(ExchangeDetails)

const DisplayLabelGrid = ({ label, values, color }) => {
  return (
    <DisplayLabelFormat
      label={label}
      value={values.map((value, index) => (
        <SquareChip key={index} label={value} color={color} sx={{ mr: 1 }} />
      ))}
    />
  )
}
